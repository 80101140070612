import React, { useState } from 'react'
import { MDBCol, MDBIcon, MDBRow } from 'mdbreact';

// components
import FormGroupTitle from './FormGroupTitle';
import InputForm from './InputForm';

const FormAdd = ( { emailError, onEmailChange } ) => {


  const [rateList, setRateList] = useState([]);
  const addRateClick = (e) => {
    e.preventDefault();
    setRateList(rateList.concat(<InputForm label='Rate' type='text' id='id' name='name' key={rateList.length} />))
  }

  return (
    <>
      <form id="" className="form-add-influencer">
        <MDBRow>
            <MDBCol xl='12' lg='12' className='form-group-box'>
                <FormGroupTitle label="Influencer Information" />
                <div className='input-group-box'>
                    <InputForm 
                        label='Influencer Name'
                        type='text'
                        id='id'
                        name='name'
                    />
                    <InputForm 
                        label='Email Address'
                        type='email'
                        id='id'
                        name='name'
                        emailError={emailError}
                        onEmailChange={onEmailChange}
                    />
                    <InputForm 
                        label='Phone Number'
                        type='tel'
                        id='id'
                        name='name'
                    />
                    <InputForm 
                        label='Date Contacted'
                        type='date'
                        id='id'
                        name='name'
                    />
                </div>
                <div className="form-group-title w-checkbox">
                    <label>Handler Details</label>
                    <div className='checkbox-group'>
                        <input type='checkbox' name='sameasabover' id='sameasabover' />
                        <label for='sameasabover'>Same as Above</label>
                    </div>
                </div>
                <div className='input-group-box'>
                    <InputForm 
                        label='Handle Name'
                        type='text'
                        id='id'
                        name='name'
                    />
                    <InputForm 
                        label='Email Address'
                        type='email'
                        id='id'
                        name='name'
                    />
                    <InputForm 
                        label='Phone Number'
                        type='tel'
                        id='id'
                        name='name'
                    />
                </div>
                <FormGroupTitle label="Add Rates" />
                <div className='input-group-box'>
                    <InputForm 
                        label='Rate'
                        type='text'
                        id='id'
                        name='name'
                    />
                    {rateList}
                    <div className='field-box add-rates-btn'>
                        <button onClick={addRateClick}><i class="fas fa-plus"></i> Add Rate</button>
                    </div>
                </div>
            </MDBCol>
        </MDBRow>
      </form>
    </>
  );
}

export default FormAdd