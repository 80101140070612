import React from 'react';
import { connect } from "react-redux";
import { projectActions } from '_actions';

import { MDBModal, MDBModalBody, MDBModalHeader, MDBContainer, MDBRow, MDBCol } from 'mdbreact';
import { Button } from "blackbox-react";
import InputForm from '_components/InputForm';

import { FormValidation } from '_components/FormValidation';

import { Loader } from '_components/Loader';

class CreateProjectModal extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      project: {
        projectName: '',
        description: '',
      },
      formErrors: {
        projectName: 'Please enter a project name.'
      },
      projectNameValid: false,
      formValid: false,
      submitted: false,
      modalOpen: false,
      class: 'orange'
    };

    this.handleChange = this.handleChange.bind(this);
    this.handleSubmit = this.handleSubmit.bind(this);
  }

  componentDidMount = () => {
    this.setState({
      modalOpen: this.props.modalOpen
    });
  };

  handleGetProjects = () => {
    return this.props.getProjects();
  };

  handleToggle = (e) => {
    this.setState({
      project: {
        projectName: '',
        description: '',
      },
      formErrors: {
        projectName: 'Please enter a project name.'
      },
      projectNameValid: false,
      formValid: false,
      submitted: false,
    });
  };

  validateField(fieldName, value) {
    let fieldValidationErrors = this.state.formErrors;
    let projectNameValid = this.state.projectNameValid;

    let isAllWhitespace = (value.replace(/(?!^ +$)^.+$/gm, "") !== '');

    switch(fieldName) {
      case 'projectName':
        if (value === "" || isAllWhitespace) { // check if empty
          projectNameValid = false;
          fieldValidationErrors.projectName = 'Please enter a project name.';
        } else {
          projectNameValid = (value.length >= 0); // check if valid
          fieldValidationErrors.projectName = projectNameValid ? '': 'Please enter a project name.';
        }
        break;
      default:
        break;
    }

    this.setState({
      formErrors: fieldValidationErrors,
      projectNameValid: projectNameValid
    }, this.validateForm);
  }

  validateForm() {
    this.setState({
      formValid: this.state.projectNameValid
    });
  }

  handleChange(event) {
    const { name, value } = event.target;
    const { project } = this.state;

    this.setState({
      project: {
        ...project,
        [name]: value
      }
    }, () => {
      this.validateField(name, value);
    });
  };

  handleSubmit(event) {
    event.preventDefault();
    const { project } = this.state;

    this.validateForm();

    this.setState({ submitted: true }, () => {
      if (this.state.formValid) {
        if(this.state.submitted){
          // event.target.style.visibility = 'hidden';

          this.props.createProject({
            name: project.projectName,
            description: project.description,
          });

          let { modalOpen, modalToggle } = this.props;
          if(modalOpen){
            modalToggle();
          }

          this.setState({
            projectNameValid: false,
            formValid: false,
            submitted: false,
            formErrors: {
              projectName: 'Please enter a project name.'
            },
          });

          for (const prop of Object.keys(project)) {
            delete project[prop];
          }

          //this.handleGetProjects();
        }
      }
    });
  };

  errorClass(error) {
    return(error.length === 0 ? 'is-passed' : 'is-failed');
  }

  render() {
    const { creating  } = this.props;
    let { modalOpen, modalToggle } = this.props;
    const { project, submitted, formValid } = this.state;
    return (
      <MDBContainer>
        <form noValidate>
          <MDBModal isOpen={modalOpen} toggle={modalToggle} className='create-form-modal' centered>
            <MDBModalHeader className='padding-left-18 padding-right-18 pb-3 pt-3' toggle={modalToggle} onClick={(e) => this.handleToggle(e)}>
              <p className='mb-0 font-size-12 font-weight-600 letter-spacing-1'>CREATE PROJECT</p>
            </MDBModalHeader>
            <MDBModalBody className='p-t--0 padding-left-18 padding-right-18 padding-bottom-15'>
              <MDBRow>
                <MDBCol className={`form-group m--sm ${submitted && this.errorClass(this.state.formErrors.projectName)}`}>
                    <label className='mb-1 font-size-12 text-black'>Project Name <span className='color-red'>*</span></label>
                    <InputForm
                      onChange={this.handleChange}
                      type='text'
                      name='projectName'
                      value={project.name}
                      id='FormName'
                      hint='Enter project name here...'
                    />
                    <div className={`form-validation__container ${(!formValid && submitted) ? 'display-block' : 'display-none'}`}>
                      <FormValidation fieldName='projectName' formErrors={this.state.formErrors}/>
                    </div>
                </MDBCol>
              </MDBRow>
              <MDBRow>
                <MDBCol>
                  <label className='mb-1 pt-2 font-size-12 text-black'>Description</label>
                  <textarea
                    name='description'
                    value={project.description}
                    id='textarea-description'
                    className='form-control font-size-12 color-none'
                    rows='7'
                    placeholder='Enter description here...'
                    onChange={this.handleChange}
                  />
                </MDBCol>
              </MDBRow>
              <MDBRow className='p-t--md'>
                <MDBCol className='d-flex'>
                  <span
                    className='mb-0 color-gray my-auto text-cursor-pointer border-bottom-gray line-height-15 font-size-12'
                    onClick={modalToggle}
                  >
                    Cancel
									</span>
                </MDBCol>
                <MDBCol
                  className='text-right'
                  id='nextButton'
                >
                  <Button
                    flat='true'
                    type='submit'
                    size={-1}
                    background='orange'
                    onClick={this.handleSubmit}
                  >Create Project {creating &&
                    <Loader />
                  }
                  </Button>
                </MDBCol>
              </MDBRow>
            </MDBModalBody>
          </MDBModal>
        </form>
      </MDBContainer>
    );
  }
}

function mapState(state) {
  const { creating, projects } = state;
  return { creating, projects };
}

const actionCreators = {
  createProject: projectActions.createProject,
  getProjects: projectActions.getProjects
};

const connectedCreateProjectModal = connect(mapState, actionCreators)(CreateProjectModal);
export { connectedCreateProjectModal as CreateProjectModal };
