import React from "react";
import { useSelector } from "react-redux";
import { utilitiesService as utility } from "_services";
import TopBarLoader from "_components/Loader/TopBarLoader";
import { useHistory, useParams } from "react-router-dom";

const TopBar = ({ onHandleProjectDetailsDrawer }) => {
  const params = useParams();

  const history = useHistory();

  const routeChange = (path) => {
    console.log("params: ", params);
    if ("leadId" in params) {
      history.push("/project/" + params.id + "/members");
    }
    if ("influencerId" in params) {
      history.push("/project/" + params.id + "/members");
    }
    history.push(path);
  };

  const projectLoaded = useSelector((state) => {
    const { projects } = state;

    return projects.projectLoaded;
  });

  const projectOwner = useSelector((state) => {
    const { authentication } = state;

    let admin = false;

    // Check if authentication.user and authentication.user.data are defined before accessing nested properties
    if (authentication?.user?.data?.projects?.owned.length > 0) {
      admin = authentication.user.data.projects.owned.some(
        (e) => e.id === parseInt(params.id)
      );
    }

    return admin;
  });

  const project = useSelector((state) => {
    const { projects } = state;
    if (projects.projectLoaded) {
      // console.log('projects data: ', projects.project.data)
      return projects.project.data;
    }
    return {};
  });

  const membersCount = useSelector((state) => {
    const { projects } = state;
    const { project, projectLoaded } = projects;
    if (projectLoaded) {
      const { data } = project;
      return data.members.length;
    }
    return 0;
  });

  const icon = useSelector((state) => {
    const { projects } = state;
    const { project, projectLoaded } = projects;
    if (projectLoaded) {
      const { data } = project;
      let owner = data.owner;
      return utility.decrypt(owner.account.first_name).toUpperCase().charAt(0);
    }
    return "";
  });

  // const renderMembers = () => {
  //     //console.log('mName: ', project.members)
  //     return project.members.length;
  // //     return project.members.map((member) => {
  // //         const mName = utility.decrypt(member.account.first_name).toUpperCase().charAt(0);
  // //         console.log('mName: ', mName);

  // //         return (
  // //             <div className='member' key={member.account.id}>
  // //                 <div className='avatar-box avatar-color-2'>
  // //                     <span>{mName}</span>
  // //                 </div>
  // //             </div>
  // //         );
  // //     });
  //  };

  return (
    <React.Fragment>
      {!projectLoaded && <TopBarLoader />}
      {projectLoaded && (
        <div className="col">
          <div className="top-project-info-box">
            <div className="project-name">
              <h1>{project.info.name}</h1>
            </div>
            {projectOwner && (
              <div className="members-box">
                <div
                  className="members-list"
                  onClick={() => routeChange(`members`)}
                >
                  <div className="member">
                    <div className="avatar-box avatar-color-1">
                      <span>{icon}</span>
                    </div>
                  </div>
                  {project.members.slice(0, 2).map((member) => (
                    <div className="member" key={member.account.id}>
                      <div className="avatar-box avatar-color-1">
                        <span>
                          {utility
                            .decrypt(member.account.first_name)
                            .toUpperCase()
                            .charAt(0)}
                        </span>
                      </div>
                    </div>
                  ))}
                  {membersCount > 3 && (
                    <div className="member">
                      <div className="avatar-box avatar-color-1">
                        <span>+{membersCount - 3}</span>
                      </div>
                    </div>
                  )}

                  <div className="member-count">
                    <p>{membersCount} members</p>
                  </div>
                </div>
              </div>
            )}
          </div>
        </div>
      )}
    </React.Fragment>
  );
};

export default TopBar;
