import React, { useState, useEffect, useRef } from 'react'

const ClientFilter = () => {
    const [isOpen, setIsOpen] = useState(false);
    const filterRef = useRef();

    function handleOpenFilter() {
      setIsOpen(!isOpen);
    }
    function handleCloseFilter() {
      setIsOpen(false);
    }

    useEffect(() => {
      const filterOutsideClick = (e) => {
        if (
          isOpen &&
          filterRef.current &&
          !filterRef.current.contains(e.target)
        ) {
          setIsOpen(false);
        }
      };
      document.addEventListener("click", filterOutsideClick);
      return () => {
        document.removeEventListener("click", filterOutsideClick);
      };
    }, [isOpen]);

  return (
    <div className="filter-box filter-category" ref={filterRef}>
      <div className="filter-btn">
        <button type="button" onClick={() => handleOpenFilter()}>
            <i class="fas fa-plus"></i>Add Client
        </button>
      </div>
      <div className={isOpen ? "filter-dropdown active" : "filter-dropdown"}>
        <div className="filter-title">
          <p>Categories</p>
          <i className="fas fa-times" onClick={() => handleCloseFilter()}></i>
        </div>
        <div className="filter-list">
          <div className="category-group">
            <div className="filter-btn-group">
              <button className="btn-select-all">
                <i className="fas fa-check-square"></i>
                Select All
              </button>
              <button className="btn-unselect-all">
                <i className="fas fa-window-close"></i>
                Unselect All
              </button>
              <button className="btn-apply active">
                <i className="fas fa-check"></i>
                Add
              </button>
            </div>
            <div className='client-lists-box'>
                <div className='client-group'>
                    <div className="checkbox-group">
                    <input type="checkbox" name="client1" id="client1" />
                    <label htmlFor="client1">Propelrr</label>
                    </div>
                    <div className="checkbox-group">
                    <input type="checkbox" name="client2" id="client2" />
                    <label htmlFor="client2">Palawan</label>
                    </div>
                    <div className="checkbox-group">
                    <input type="checkbox" name="client3" id="client3" />
                    <label htmlFor="client3">DMCI</label>
                    </div>
                </div>
                <div className='client-group'>
                    <div className="checkbox-group">
                    <input type="checkbox" name="client4" id="client4" />
                    <label htmlFor="client4">Propelrr</label>
                    </div>
                    <div className="checkbox-group">
                    <input type="checkbox" name="client5" id="client5" />
                    <label htmlFor="client5">Palawan</label>
                    </div>
                    <div className="checkbox-group">
                    <input type="checkbox" name="client6" id="client6" />
                    <label htmlFor="client6">DMCI</label>
                    </div>
                </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

export default ClientFilter