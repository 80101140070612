import React from 'react';
import {MDBIcon} from 'mdbreact';
import {Link} from 'react-router-dom'

class Slider extends React.Component {
	constructor(props) {
		super(props);
		this.state = {
			isOpen: false,
			active: 'Upload CSV',
			dropIcon: 'caret-square-up',
			dropdownDiv: 'expand',
			iconVisibility: 'visible',
			isViewCompleted: true,
			uploadCsv: '',
			settings: '',
			emailCon: '',
			successMess: '',
			integration: '',
			uploadCsvClass: '',
			settingsClass: '',
			emailConClass: '',
			successMessClass: '',
			integrationClass: '',
			finish: false,
			uploadCsvSideNavData: [
				{
					title: 'Upload CSV',
					id: 'uploadCsv',
					path: 'import'
				},
				{
					title: 'Map Fields',
					id: 'mapFields',
					path: 'import/map'
				},
				{
					title: 'Results',
					id: 'importResults',
					path: 'import/results'
				}
			]
		};
	}

	renderSliderIndicator = (data, index, hasLine) => {
		let activeFormView = this.props.activeFormView;
		let stageNum = this.props.stageNum;
		let isVerified = this.props.isVerified;
		let isActiveFormView = false;
		let { title, id, path } = data;
		let icon = '';
		let iconClass = '';
		let titleClass = '';
		let linkClass = '';

		if(id === activeFormView){
			isActiveFormView = true;
		}

		if(isVerified){
			titleClass = 'mt-2 mb-0 title-view--complete';
			linkClass = '';
			icon = 'check';
			iconClass = 'icon-view--check';
		} else {
			if(index + 1 > stageNum){
				titleClass = 'mt-2 mb-0 title-view--default';
				linkClass = 'disabled';
				icon = 'blank';
				iconClass = 'icon-view--blank';
			}

			if(index + 1 < stageNum){
				titleClass = 'mt-2 mb-0 title-view--complete';
				linkClass = '';
				icon = 'check';
				iconClass = 'icon-view--check';
			}

			if(isActiveFormView){
				titleClass = 'mt-2 mb-0 title-view--active';
				linkClass = 'disabled';
				icon = 'cogs';
				iconClass = 'icon-view--cogs color-orange settings-icon';
			}
		}

		return (
			<div className='row float-right height-41 width--full' key={index}>
				<div className='row mr-2'>
					<Link className={linkClass} to={path}>
						<p className={titleClass}>{title}</p>
					</Link>
				</div>
				<div className='icon-view__container'>
					<div className='mx-2 round mb-0'>
						<p className={iconClass}>
							<MDBIcon icon={icon} className='icon-view' />
						</p>
					</div>
					{ hasLine && <p className='mb-0 line' />}
				</div>
			</div>
		);
	};

	render() {

		let uploadCsvSideNavData = this.state.uploadCsvSideNavData;
		let slider = uploadCsvSideNavData.map((data, index) => {
			let hasLine = index < uploadCsvSideNavData.length - 1;
			return this.renderSliderIndicator(data, index, hasLine);
		});
		return <div className='width-145 m--auto'>{slider}</div>;
	}
}

export default Slider;
