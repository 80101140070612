import React, { useEffect, useState, useRef } from "react";
import { useDispatch } from "react-redux";
import { useParams } from "react-router-dom";
import ParameterDrawerLoader from "../../../../_components/Loader/ParameterDrawerLoader";
import { deckActions } from "_actions/deck.actions";

import HandleDeckDeleteModal from './HandleDeckDeleteModal';

const HandleDeckDrawer = ({
  isHandleDecksDrawer,
  onHandleDecksDrawer,
  onHandleCloseDrawer,
  deck,
}) => {
  const [deckName, setDeckName] = useState("");
  const [deckLink, setDeckLink] = useState("");
  const [deckId, setDeckId] = useState(0);
  const [validationErrors, setValidationErrors] = useState({
    deckName: "",
    deckLink: "",
  });
  const [isDeckDelete, setIsDeckDelete] = useState(false);

  const isValidURL = (url) => {
    // Regular expression for a basic URL validation
    const urlRegex = /^(ftp|http|https):\/\/[^ "]+$/;
    return urlRegex.test(url);
  };

  const dispatch = useDispatch();
  const param = useParams();

  useEffect(() => {

    if (isHandleDecksDrawer && deck.id == undefined) {
      setDeckName("");
      setDeckLink("");
      setDeckId(0);
      setValidationErrors({ deckName: "", deckLink: "" });
    }

    if (isHandleDecksDrawer && deck.id !== undefined) {
      setValidationErrors({ deckName: "", deckLink: "" });
      setDeckName(deck.name);
      setDeckLink(deck.link);
      if (deck.id != 0) {
        setDeckId(deck.id);
      }
    }
  }, [deck,isHandleDecksDrawer]);

  const handleDeckName = (value) => {
    setDeckName(value);

    // Reset validation error when the user types
    setValidationErrors({ ...validationErrors, deckName: "" });
  };

  const handleDeckLink = (value) => {
    setDeckLink(value);

    // Reset validation error when the user types
    setValidationErrors({ ...validationErrors, deckLink: "" });
  };

  const handleSaveDeck = () => {
    // Validation
    const errors = {};

    if (!deckName || !deckName.trim()) {
      errors.deckName = "Title is empty";
    }

    if (!deckLink || !deckLink.trim()) {
      errors.deckLink = "Link is empty";
    } else if (!isValidURL(deckLink)) {
      errors.deckLink = "Invalid Link URL";
    }

    // If validation errors, update the state and return
    if (Object.keys(errors).length > 0) {
      setValidationErrors(errors);
      return;
    }

    // If validation passes, reset the errors
    setValidationErrors({ deckName: "", deckLink: "" });

    const request = { id: deckId, name: deckName, link: deckLink };
    // console.log("req: ", request);

    if (deckId == 0) {
      dispatch(deckActions.createDeck(param.id, request));
    } else {
      dispatch(deckActions.updateDeck(param.id, request));
    }

    handleCloseDrawer();
  };

  const handleRemoveDeck = () => {
    // Check if there are validation errors
    if (validationErrors && (validationErrors.deckName || validationErrors.deckLink)) {
      // If there are validation errors, do not proceed with the removal
      return;
    }
  
    // If no validation errors, proceed with the removal
    dispatch(deckActions.deleteDeck(param.id, deckId));
    onHandleCloseDrawer();
  };
  

  const handleCloseDrawer = () => {
    // Clear form data when closing the drawer
    // setDeckName("");
    // setDeckLink("");
    // setDeckId(0);
    // setValidationErrors({ deckName: "", deckLink: "" });

    // Close the drawer
    if (deck.id == undefined) {
      onHandleCloseDrawer();
    } else {
      onHandleCloseDrawer();
    }
  };

  const handleOpenDeleteModal = (id = null) => {
    setIsDeckDelete(true);
  
    if(isDeckDelete) {
      setIsDeckDelete(false);
      handleRemoveDeck();
    }
  }

  const handleCloseDeleteModal = () => {
    setIsDeckDelete(false);
  }

  let activeClass = isHandleDecksDrawer ? "active" : "";

  return (
    <>
      <div className={`handle-deck-drawer ${activeClass}`}>
        <div className="handle-deck-wrapper">
          <div className="drawer-title">
            {
              deckId !== 0 ? <p>Edit Deck</p> : <p>Add Deck</p>
            }
            <span onClick={handleCloseDrawer}>
              <i className="icon-lms icon-close"></i>
            </span>
          </div>
          <div className="drawer-list">
            <div className="form-group-box">
              <div className="input-group-box full-box-">
                <div className="field-box">
                  <label>Presentation Deck Title</label>
                  <input
                    value={deckName}
                    onChange={(e) => handleDeckName(e.target.value)}
                    type={`text`}
                    placeholder=""
                  />
                  {validationErrors.deckName && (
                    <p className="error-field">{validationErrors.deckName}</p>
                  )}
                </div>
              </div>
            </div>
            <div className="form-group-box">
              <div className="input-group-box full-box-">
                <div className="field-box">
                  <label>Link To Output</label>
                  <input
                    value={deckLink}
                    onChange={(e) => handleDeckLink(e.target.value)}
                    placeholder=""
                    type={`text`}
                  />
                  {validationErrors.deckLink && (
                    <p className="error-field">{validationErrors.deckLink}</p>
                  )}
                </div>
              </div>
            </div>
            <div className="button-group">
              {
                deckId !== 0 
                ? 
                <span className="delete-btn" onClick={handleOpenDeleteModal}>Delete</span> 
                : 
                <span className="cancel-btn" onClick={handleCloseDrawer}>
                  Cancel
                </span>
              }
              {
                deckId !== 0 
                ? 
                <span className="save-btn" onClick={handleSaveDeck}>
                  Save Deck
                </span>
                : 
                <span className="save-btn" onClick={handleSaveDeck}>
                  Add Deck
                </span>
              }
            </div>
          </div>
        </div>
      </div>
      <HandleDeckDeleteModal
        isOpen={isDeckDelete}
        handleOpenDeleteModal={() => handleOpenDeleteModal()}
        handleCloseDeleteModal={() => handleCloseDeleteModal()}
      />
    </>
  );
};

export default HandleDeckDrawer;
