import React from 'react';
import {MDBIcon} from 'mdbreact';
import {Link} from 'react-router-dom'

class Slider extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      isOpen: false,
      active: 'Form Builder',
      dropIcon: 'caret-square-up',
      dropdownDiv: 'expand',
      iconVisibility: 'visible',
      isViewCompleted: true,
      formBuilder: '',
      settings: '',
      emailCon: '',
      successMess: '',
      integration: '',
      formBuilderClass: '',
      settingsClass: '',
      emailConClass: '',
      successMessClass: '',
      integrationClass: '',
      finish: false,
      formBuilderSideNavData: [
        {
          title: 'Form Builder',
          id: 'formBuilder',
          path: 'form-builder'
        },
        {
          title: 'Email Connections',
          id: 'emailConnections',
          path: 'email-connection'
        },
        {
          title: 'Success Message',
          id: 'successMessage',
          path: 'success-message'
        },
        {
          title: 'Integration',
          id: 'integration',
          path: 'integration'
        }
      ]
    };
  }

  renderSliderIndicator = (data, index, hasLine) => {
    let activeFormView = this.props.activeFormView;
    let stageNum = this.props.stageNum;
    let isVerified = this.props.isVerified;
    let isActiveFormView = false;
    let { title, id, path } = data;
    let icon = '';
    let iconClass = '';
    let titleClass = '';
    let linkClass = '';

    if(id === activeFormView){
      isActiveFormView = true;
    }

    if(isVerified){
      titleClass = 'mt-2 mb-0 title-view--complete';
      linkClass = '';
      icon = 'check';
      iconClass = 'icon-view--check';
    } else {
      if(index + 1 > stageNum){
        titleClass = 'mt-2 mb-0 title-view--default';
        linkClass = 'disabled';
        icon = 'blank';
        iconClass = 'icon-view--blank';
      }

      if(index + 1 < stageNum){
        titleClass = 'mt-2 mb-0 title-view--complete';
        linkClass = '';
        icon = 'check';
        iconClass = 'icon-view--check';
      }

      if(isActiveFormView){
        titleClass = 'mt-2 mb-0 title-view--active';
        linkClass = 'disabled';
        icon = 'cogs';
        iconClass = 'icon-view--cogs color-orange settings-icon';
      }
    }

    return (
      <div className='row float-right height-41' key={index}>
        <div className='row mr-2'>
          <Link className={linkClass} to={path}>
            <p className={titleClass}>{title}</p>
          </Link>
        </div>
        <div className='icon-view__container'>
          <div className='mx-2 round mb-0'>
            <p className={iconClass}>
              <MDBIcon icon={icon} className='icon-view' />
            </p>
          </div>
          { hasLine && <p className='mb-0 line' />}
        </div>
      </div>
    );
  };

  render() {

    let formBuilderSideNavData = this.state.formBuilderSideNavData;
    let slider = formBuilderSideNavData.map((data, index) => {
      let hasLine = index < formBuilderSideNavData.length - 1;
      return this.renderSliderIndicator(data, index, hasLine);
    });
    return <div className='width-145 m--auto'>{slider}</div>;
  }
}

export default Slider;
