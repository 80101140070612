import React from 'react'


const HoverStatusInfo = (props) => {

  return (
    <React.Fragment>
      <span className='result-title'>
        {props.info}
      </span>
      <span className='result-hover'>
        {props.info}
      </span>
    </React.Fragment>
  )
}


export default HoverStatusInfo