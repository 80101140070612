import React, { Component } from 'react';
import { Link } from 'react-router-dom';
import { connect } from "react-redux";
import { formActions, publicActions } from '_actions';
import { utilitiesService as utility } from '_services';
import { environment } from "_environments/environment";

import { MDBContainer, MDBCol, MDBRow, MDBBtn, MDBIcon } from 'mdbreact';
import Input from '_components/InputForm';
import Slider from '_pages/Forms/FormBuilder/components/Slider';
import { Button, ButtonGroup, Icon } from "blackbox-react";

class FormEmailConnections extends Component {
  constructor(props) {
    super(props);

    this.state = {
      formData: [],
      isConnectedToGmail: false,
      isTrackingReplies: false,
      noReply: '',
      formEmailConnection: {},

      isOpen: false,
      selectedOption: '',
      urlAPI: {},
      options: [
        { value: 'option1', label: 'Select your highest educational attainment' },
        { value: 'option2', label: 'Select your highest educational attainment' },
        { value: 'option3', label: 'Select your highest educational attainment' },
      ]
    };

    // console.log('search check', typeof this.props.location.search);
    // console.log('this.props.location.search', this.props.location);
    if(this.props.location.search !== 'undefined'){
      let projectId = sessionStorage.getItem('projectId');
      let form = JSON.parse(sessionStorage.getItem('form'));
      let formId = form.form_id;
      let googleState = this.props.location.search;
      //let pathName = window.location.pathname;
      this.props.googleConnectAuthorize(projectId, formId, googleState);
    }
    /*if(this.props.location.search !== 'undefined'){
      let googleState = this.props.location.search;

      this.props.googleConnectAuthorize(googleState);

      setTimeout(
          function () {
            let projectId = sessionStorage.getItem('projectId');
            let form = JSON.parse(sessionStorage.getItem('form'));
            let formId = form.form_id;
            let pathName = window.location.pathname;

            if(typeof pathName !== 'undefined'){
              if(pathName.includes('email-connection/success')){
                window.location.href = `${environment.baseUrl}/project/${projectId}/form/${formId}/email-connection`;
              }
            }
          }
              .bind(this),
          2000
      );
    }*/

    this.handleGetForm = this.handleGetForm.bind(this);
  }

  getFormData = () => {
    let formData = JSON.parse(sessionStorage.getItem('form'));
    return formData;
  };

  handleGetForm = () => {
    const projectId = sessionStorage.getItem('projectId');
    const formId = this.props.match.params.id;
    const formData = this.props.getForm(projectId, formId);

    this.setState({
      formData: {
        ...formData
      }
    });
  };

  renderIconIndicator = () => {
    return <MDBIcon icon='caret-down' />
  };

  handleChange = event => {
    this.setState({ [event.target.name]: event.target.value, class: true });
  };

  handleConnectToGmail = (projectId, formId, redirect) => {
    //const redirect = `${environment.baseUrl}/email-connection/success`;
    //console.log('redirect', redirect);
    this.props.googleConnectOauth(projectId, formId, redirect);
  };

  handleDisconnectGmail = (projectId, formId, connectionId) => {
    this.props.disconnectGmail(projectId, formId, connectionId);

    let disconnected = this.props.disconnected;

    if(disconnected){
      this.handleGetForm();
    }
  };

  handleSelectedChange = selectedOption => {
    this.setState({ selectedOption });
  };

  componentDidMount() {

    this.handleGetForm();

  }

  handleMapInput(inputFields, column) {
    return (
      inputFields.map((value) => {
        let col = 12 / column;
        return (
          <MDBCol xl={col} lg={col} md={col} className='px-1 mb-2' key={value.label}>
            <Input
              hint={value.label}
              onChange={this.handleChange.bind(this)}
              type={value.type}
              name={value.label}
            />
          </MDBCol>
        )
      })
    )
  }

  handleToggleUpdate = (event, value, formEmailConnection) => {
    const projectId = JSON.parse(sessionStorage.getItem('projectId'));
    let form = JSON.parse(sessionStorage.getItem('form'));
    let formId = form.form_id;
    let { name } = event.target;

    this.setState({
      formEmailConnection: {
        ...formEmailConnection,
        [name]: !value
      }
    }, () => {
      this.handleGetForm();
      this.props.updateConnection(projectId, formId, this.state.formEmailConnection);
    });
  };

  handleFieldUpdate = (event, formEmailConnection) => {
    const projectId = JSON.parse(sessionStorage.getItem('projectId'));
    let form = JSON.parse(sessionStorage.getItem('form'));
    let formId = form.form_id;
    let { name, value } = event.target;

    this.setState({
      formEmailConnection: {
        ...formEmailConnection,
        [name]: value
      }
    }, () => {
      this.handleGetForm();
      this.props.updateConnection(projectId, formId, this.state.formEmailConnection);
    });
  };

  render() {
    let projectId = JSON.parse(sessionStorage.getItem('projectId'));
    let form = JSON.parse(sessionStorage.getItem('form'));
    let formId = form.form_id;
    let formEmailConnection = form.email_connection;
    //console.log('formEmailConnection', formEmailConnection);
    let hasGmail = formEmailConnection && formEmailConnection.gmail;
    let { formData } = this.props;
    //const params = `${environment.baseUrl}/project/${projectId}/form/${formId}/email-connection/success`;
    const params = `${environment.baseUrl}/email-connection/success`;
    //console.log('params', params);
    //const loaded = this.props.loaded;
    //console.log('loaded', loaded);

    let hasData = false;
    if(typeof formData !== 'undefined'){
      if(typeof formData.info !== 'undefined'){
        hasData = true;
      }
    } else{
      formData = this.getFormData();
      hasData = true;
    }

    return (
      <MDBContainer fluid className='pt-3 pr-3'>
        <MDBRow className='ml-2 mr-4'>
          <MDBCol lg='3' xl='2' md='4'>
            <MDBCol className='p-0 mt-4'>
              <p className='font-size-12 font-weight-600 font-color-gray mb-0'>LEAD MANAGEMENT</p>
              <p className='font-family-crimson font-size-30 line-height-7 color-orange line-height-6'>Forms</p>
            </MDBCol>
            <MDBCol xl='11' lg='9' className='text-center font-size-14 pl-0 mt-5'>
              <Slider activeFormView='emailConnections' stageNum='2'/>
            </MDBCol>
            <ButtonGroup>
              <Link to={`/project/${projectId}/forms`} className='button-end'>
                <Button
                  flat='true'
                  background='transparent' foreground='black'
                  borderWidth={2} borderColor='dark' size={-1}
                  styles={{ 'width': '100%', 'opacity': '0.8' }}>
                  <Icon color='black' name='chevron' rotate={180} />
                  <span className='color-black font-weight-bold'>View All Forms</span>
                </Button>
              </Link>
            </ButtonGroup>
          </MDBCol>
          <MDBCol lg='9' xl='10' md='8' className='px-0'>
            <MDBRow className='d-flex pb-2'>
              <MDBCol lg='7' className='my-auto'>
                <MDBRow className='pl-3'>
                  <p className='font-family-crimson font-size-22 letter-spacing-1 my-auto pr-3'>{ hasData ? formData.info.name : null }</p>
                  <div className='progress col-lg-3 col-xl-3 my-auto'>
                    <span className='progress-value text-white font-weight-600 my-auto'>50% co<span className='font-color-orange'>mplete</span></span>
                    <div className='progress-bar progress-background' style={{ width: '52%' }} />
                  </div>
                </MDBRow>
              </MDBCol>
              <MDBCol lg='5' className='text-right pr-2'>
                <ButtonGroup>
                  <Link to={`/project/${projectId}/form/${formId}/form-builder`}>
                    <Button
                      flat='true'
                      background='transparent' foreground='black'
                      borderWidth={2} borderColor='dark' size={-2}
                      styles={{ 'opacity': '0.5' }}>
                      <Icon color='black' name='chevron' rotate={180} />
                      <span className='color-black font-weight-bold'>Back</span>
                    </Button>
                  </Link>
                  <Link to={`/project/${projectId}/form/${formId}/success-message`}>
                    <Button
                      flat='true'
                      background='orange' foreground='white'
                      borderWidth={2} borderColor='orange' size={-2}>
                      <span className='color-white font-weight-bold'>Next</span>
                    </Button>
                  </Link>
                </ButtonGroup>
              </MDBCol>
            </MDBRow>
            <MDBCol className='background-rectangle mt-2 px-0 mb-3'>
              <MDBCol>
                { hasData ?
                  <MDBRow className='pl-0'>
                    <MDBCol lg='7' xl='7' className='py-2'>
                      <p className='mt-3 mb-0 mx-4 font-size-14 font-weight-600'>EMAIL CONNECTIONS</p>
                      <hr className='my-3' />
                      <MDBCol className=''>
                        <MDBRow className='px-4'>
                          <MDBCol md='12' className='px-0'>
                            <div className='d-flex'>
                              <MDBCol md='4' className='pt-2 px-0'>
                                <p className='mb-0 font-size-11 font-weight-600' >Recipient Email</p>
                              </MDBCol>
                              <MDBCol className='my-auto'>
                                { hasGmail ?
                                  <div className=''>
                                    <Button
                                      flat='true'
                                      background='red' foreground='white'
                                      borderWidth={2} borderColor='red' size={-2}
                                      styles={{ 'min-width': '150px'}}
                                      onClick={(e) => {this.handleDisconnectGmail(projectId, formId, formEmailConnection.id)}}
                                    >
                                      <span className='color-white font-weight-bold'>Disconnect Gmail</span>
                                    </Button>
                                    <p className='font-size-12 font-weight-bold m-t--sm'>Currently connected to &nbsp;
                                      <span className='mb-0 font-color-green line-height-15 font-size-15'>
                                      { formEmailConnection.gmail ? utility.decrypt(formEmailConnection.gmail): '' }
                                    </span>
                                    </p>
                                  </div>
                                  :
                                  <div className='width-170'>
                                    <MDBBtn flat className='m-0 background-color-orange shadow-none color-white py-2 px-4 br-3 font-size-12'
                                            onClick={(event => {event.preventDefault(); this.handleConnectToGmail(projectId, formId, params);})}>Connect to Gmail</MDBBtn>
                                  </div>
                                }
                                <div>
                                  <p className='mb-0 font-size-10 py-2 font-italic'>This will setup your Gmail account to receive submission <br/> notifications from this form</p>
                                </div>
                              </MDBCol>
                            </div>
                            {/*<hr className='mb-3 mt-1' />*/}
                          </MDBCol>
                          {/*<MDBCol md='12' className='px-0'>*/}
                          {/*  <div className='d-flex'>*/}
                          {/*    <MDBCol md='4' className='pt-2 px-0'>*/}
                          {/*      <p className='mb-0 font-size-11 font-weight-600'>Connect to Outlook</p>*/}
                          {/*    </MDBCol>*/}
                          {/*    <MDBCol className='my-auto'>*/}
                          {/*      <div className='width-170'>*/}
                          {/*        <MDBBtn flat className='m-0 background-color-orange font-weight-bold shadow-none color-white py-2 px-3 br-5 font-size-11'>Connect to Outlook</MDBBtn>*/}
                          {/*        <p className='mb-0 font-size-9 py-2 font-italic'>This will setup your Outlook account to receive the submissions from this form</p>*/}
                          {/*      </div>*/}
                          {/*    </MDBCol>*/}
                          {/*  </div>*/}
                          {/*  <hr className='mb-3 mt-1' />*/}
                          {/*</MDBCol>*/}
                          {/*<MDBCol md='12' className='px-0'>*/}
                          {/*  <div className='d-flex'>*/}
                          {/*    <MDBCol md='4' className='pt-2 px-0'>*/}
                          {/*      <p className='mb-0 font-size-12 font-weight-600'>Track Replies</p>*/}
                          {/*    </MDBCol>*/}
                          {/*    <MDBCol className='my-auto'>*/}
                          {/*      <div className='width-170'>*/}
                          {/*        <MDBRow className='ml-1'>*/}
                          {/*          <div className='switch mr-2 my-auto'>*/}
                          {/*            <input*/}
                          {/*              type='checkbox'*/}
                          {/*                   name='track'*/}
                          {/*                   className='switch-checkbox'*/}
                          {/*                   id='track'*/}
                          {/*                   checked={formEmailConnection.track || ''}*/}
                          {/*                   onChange={(event) => {*/}
                          {/*                     this.handleToggleUpdate(event, formEmailConnection.track, formEmailConnection)*/}
                          {/*                   }}*/}
                          {/*            />*/}
                          {/*            <label className='switch-labels' htmlFor='track'>*/}
                          {/*              <span className='switch-text'> </span>*/}
                          {/*              <span className='switch-dot'> </span>*/}
                          {/*            </label>*/}
                          {/*          </div>*/}
                          {/*          <label className='mb-0 font-size-12'>Reply Tracker</label>*/}
                          {/*        </MDBRow>*/}
                          {/*      </div>*/}
                          {/*      <div>*/}
                          {/*        <p className='mb-0 font-size-10 py-0 font-italic'>Get all the replies from the email address you set above</p>*/}
                          {/*      </div>*/}
                          {/*    </MDBCol>*/}
                          {/*  </div>*/}
                          {/*  /!*<hr className='my-3' />*!/*/}
                          {/*</MDBCol>*/}
                          {/*<MDBCol md='12' className='px-0'>*/}
                          {/*  <div className='d-flex'>*/}
                          {/*    <MDBCol md='4' className='pt-2 px-0'>*/}
                          {/*      <p className='mb-0 font-size-12 font-weight-600'>No-Reply</p>*/}
                          {/*    </MDBCol>*/}
                          {/*    <MDBCol className='my-auto pr-0'>*/}
                          {/*      <input*/}
                          {/*        name='noReply'*/}
                          {/*        type='text'*/}
                          {/*        autoFocus={true}*/}
                          {/*        defaultValue={formEmailConnection.noReply || 'no-reply@domain.com'}*/}
                          {/*        className='border-gray mb-2 br-5 padding-3 px-2 font-size-13'*/}
                          {/*        placeholder='no-reply@domain.com'*/}
                          {/*        onChange={(event) => { this.handleFieldUpdate(event, formEmailConnection) }}*/}
                          {/*      />*/}
                          {/*      <div className=''>*/}
                          {/*        <p className='my-1 font-size-10 font-italic'>This email address will show as the `FROM address` for all outgoing emails sent to the users who submitted entries to the form. <br/><br/> The no-reply email <b>will not receive</b> and <b>does not track replies</b> from the users.</p>*/}
                          {/*      </div>*/}
                          {/*    </MDBCol>*/}
                          {/*  </div>*/}
                          {/*</MDBCol>*/}
                        </MDBRow>
                      </MDBCol>
                    </MDBCol>
                    <MDBCol lg='5' xl='5' className='box-right-rectangle px-0'>
                      <div className='generic-message text-center'>
                        <p>
                          Connect your <b>Gmail account</b><br />
                          to receive form entry <br/> notifications
                          on your inbox.
                        </p>
                        {/*<img alt='' src={settings} />*/}
                        <Icon name='mail' className='icon--lg'/>
                        <Icon name='mail' className='icon--md' />
                        <Icon name='mail' className='icon--sm' />
                      </div>
                      {/*<p className='mt-3 mb-0 mx-4 font-size-10 font-weight-600'>EMAIL CONNECTIONS</p>*/}
                      {/*<hr className='my-3' />*/}
                      {/*<MDBCol className=''>*/}
                      {/*  <MDBRow className='px-4'>*/}
                      {/*    <MDBCol md='12' className='px-0'>*/}
                      {/*      <div className='d-flex'>*/}
                      {/*        <MDBCol md='4' className='pt-2 px-0'>*/}
                      {/*          <p className='mb-0 font-size-11 font-weight-600' >Connect to Gmail</p>*/}
                      {/*        </MDBCol>*/}
                      {/*        <MDBCol className='my-auto'>*/}
                      {/*          <div className='width-170'>*/}
                      {/*            <MDBBtn flat className='m-0 background-color-orange font-weight-bold shadow-none color-white py-2 px-4 br-5 font-size-11'*/}
                      {/*            onClick={(event => {event.preventDefault(); this.handleConnectToGmail(projectId, formId, params);})}>Connect to Gmail</MDBBtn>*/}
                      {/*            <p className='mb-0 font-size-9 py-2 font-italic'>This will setup your Gmail account to receive the submissions from this form</p>*/}
                      {/*          </div>*/}
                      {/*        </MDBCol>*/}
                      {/*      </div>*/}
                      {/*      <hr className='mb-3 mt-1' />*/}
                      {/*    </MDBCol>*/}
                      {/*    /!*<MDBCol md='12' className='px-0'>*!/*/}
                      {/*    /!*  <div className='d-flex'>*!/*/}
                      {/*    /!*    <MDBCol md='4' className='pt-2 px-0'>*!/*/}
                      {/*    /!*      <p className='mb-0 font-size-11 font-weight-600'>Connect to Outlook</p>*!/*/}
                      {/*    /!*    </MDBCol>*!/*/}
                      {/*    /!*    <MDBCol className='my-auto'>*!/*/}
                      {/*    /!*      <div className='width-170'>*!/*/}
                      {/*    /!*        <MDBBtn flat className='m-0 background-color-orange font-weight-bold shadow-none color-white py-2 px-3 br-5 font-size-11'>Connect to Outlook</MDBBtn>*!/*/}
                      {/*    /!*        <p className='mb-0 font-size-9 py-2 font-italic'>This will setup your Outlook account to receive the submissions from this form</p>*!/*/}
                      {/*    /!*      </div>*!/*/}
                      {/*    /!*    </MDBCol>*!/*/}
                      {/*    /!*  </div>*!/*/}
                      {/*    /!*  <hr className='mb-3 mt-1' />*!/*/}
                      {/*    /!*</MDBCol>*!/*/}
                      {/*    <MDBCol md='12' className='px-0'>*/}
                      {/*      <div className='d-flex'>*/}
                      {/*        <MDBCol md='4' className='pt-2 px-0'>*/}
                      {/*          <p className='mb-0 font-size-11 font-weight-600'>Track Replies</p>*/}
                      {/*        </MDBCol>*/}
                      {/*        <MDBCol className='my-auto'>*/}
                      {/*          <div className='width-170'>*/}
                      {/*            <MDBRow className='ml-1'>*/}
                      {/*              <div className='switch mr-2 my-auto'>*/}
                      {/*                <input type='checkbox' name='switch' className='switch-checkbox' id='myswitch' onChange={(e) => { }} />*/}
                      {/*                <label className='switch-labels' htmlFor='myswitch'>*/}
                      {/*                  <span className='switch-text'></span>*/}
                      {/*                  <span className='switch-dot'></span>*/}
                      {/*                </label>*/}
                      {/*              </div>*/}
                      {/*              <label className='mb-0 font-size-12'>Reply Tracker ON</label>*/}
                      {/*            </MDBRow>*/}
                      {/*            <p className='mb-0 font-size-9 py-0 font-italic'>Get all the replies from the email address you set above</p>*/}
                      {/*          </div>*/}
                      {/*        </MDBCol>*/}
                      {/*      </div>*/}
                      {/*      <hr className='my-3' />*/}
                      {/*    </MDBCol>*/}
                      {/*    <MDBCol md='12' className='px-0'>*/}
                      {/*      <div className='d-flex'>*/}
                      {/*        <MDBCol md='4' className='pt-2 px-0'>*/}
                      {/*          <p className='mb-0 font-size-11 font-weight-600'>No-Reply</p>*/}
                      {/*        </MDBCol>*/}
                      {/*        <MDBCol className='my-auto pr-0'>*/}
                      {/*          <Input*/}
                      {/*            hint='no-reply@company-name.com'*/}
                      {/*            onChange={this.handleChange.bind(this)}*/}
                      {/*            type='text'*/}
                      {/*            name='tbNoReply'*/}
                      {/*          />*/}
                      {/*          <div className='width-170'>*/}
                      {/*            <p className='my-1 font-size-9 font-italic'>This email address will show as the FROM address for all outgoing emails to the users</p>*/}
                      {/*            <p className='mb-0 opacity-60 font-size-9 font-italic'>This does not track reply via email</p>*/}
                      {/*          </div>*/}
                      {/*        </MDBCol>*/}
                      {/*      </div>*/}
                      {/*    </MDBCol>*/}
                      {/*    <MDBCol md='12' className='px-0'>*/}
                      {/*      <MDBRow className='pt-3'>*/}
                      {/*        <MDBCol className='text-right pt-4'>*/}
                      {/*          <Link to='/settings'>*/}
                      {/*            <span className='mb-0 color-gray my-auto text-cursor-pointer border-bottom-gray line-height-15 font-size-12 mr-2'>Cancel</span>*/}
                      {/*          </Link>*/}
                      {/*          <Link to='/success-message'>*/}
                      {/*            <MDBBtn flat className='background-color-orange font-weight-bold shadow-none color-white py-2 px-4 br-5'>Save Changes</MDBBtn>*/}
                      {/*          </Link>*/}
                      {/*        </MDBCol>*/}
                      {/*      </MDBRow>*/}
                      {/*    </MDBCol>*/}
                      {/*  </MDBRow>*/}
                      {/*</MDBCol>*/}
                    </MDBCol>
                  </MDBRow>
                  :
                  <MDBRow className='mx-0 height--full width--full'>
                    <MDBCol lg='12' className='height--full'>
                      <div className='text-center position-center m--auto height--full'>
                        <div className="loader dark">Loading...</div>
                      </div>
                    </MDBCol>
                  </MDBRow>
                }
              </MDBCol>
            </MDBCol>
          </MDBCol>
        </MDBRow>
      </MDBContainer >
          )
  }
}

function mapState(state) {
  const { creating, forms, email } = state;
  const { loaded, disconnected, authLoaded } = state.publicApi;
  return { creating, forms, email, loaded, disconnected, authLoaded };
}

const actionCreators = {
  getForm: formActions.getForm,
  googleConnectOauth: publicActions.googleConnectOauth,
  googleConnectAuthorize: publicActions.googleConnectAuthorize,
  updateConnection: publicActions.updateConnection,
  disconnectGmail: publicActions.disconnectGmail
};

const connectedEmailConnection = connect(mapState, actionCreators)(FormEmailConnections);
export { connectedEmailConnection as FormEmailConnections};
