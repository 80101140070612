import React from "react";

const StatusAction = ({
  openDropdown,
  handleCloseStatusDropdown,
  leadStatus,
  onSetLeadStatus,
}) => {
  const handleStatusSelection = (selectedStatus) => {
    // Call the callback function to update the parent state
    onSetLeadStatus(selectedStatus);
    // Close the dropdown or perform any other logic
    handleCloseStatusDropdown();
  };

  return (
    <div
      className={openDropdown === 'status' ? "action-dropdown active" : "action-dropdown"}
    >
      <div className="dropdown-title">
        <p>
          <i className="icon-lms icon-check-circle"></i>
          Status
        </p>
        <i
          className="icon-lms icon-close"
          onClick={handleCloseStatusDropdown}
        ></i>
      </div>
      <div className="dropdown-list status-list">
        {leadStatus.map((group, index) => (
          <div key={index} className="filter-group">
            <span className={`filter-name filter-color-${index + 1}`}>
              {group.category}
            </span>
            <div className="filter-name-list">
              {group.items.map((item) => (
                <span
                  key={item.id}
                  onClick={() => handleStatusSelection(item.id)}
                >
                  {" "}
                  {item.label}
                </span>
              ))}
            </div>
          </div>
        ))}
      </div>
      <div className="triangle"></div>
    </div>
  );
};

export default StatusAction;
