
import {getLeadFieldValue, getCampaignData, utilitiesService as utility} from '_services/utilities.service'
import React from "react";
import Moment from "react-moment";

const PropelrrCareersColumns = props => {

    const { lead } = props;

    let type = getLeadFieldValue(lead, 95);
    let property = getLeadFieldValue(lead, 96);
    let campaign = getCampaignData(lead);

    let leadResponsible = lead.responsible ?
        utility.decrypt(lead.responsible.first_name) + ' ' + utility.decrypt(lead.responsible.last_name)
        : 'Unassigned';


    return (
        <React.Fragment>
            <td className={`status-box`}>
                <div> <span style={{
                    textTransform: "capitalize",
                    fontWeight: "bold",
                    lineHeight: 1.2,
                    marginBottom: "3px"}
                } className={`status-text`}>
                                            {type}
                                        </span>
                    {property &&
                    <span className="time-text" style={
                        {
                            color: "#666",
                            fontSize: "0.5rem"
                        }
                    }>
                                         ({property})
                                        </span>}
                </div>
            </td>

            <td>
                <span>{lead.origin}</span>
            </td>

            <td>
                <span>{campaign.source}</span>
            </td>

            <td>
                <span>{campaign.medium}</span>
            </td>

            <td>
                <span>
                    {campaign.campaign}
                </span>
            </td>

            <td>
                <span>
                    {leadResponsible}
                </span>
            </td>

        </React.Fragment>
    );

}

export default PropelrrCareersColumns;