export const publicConstants = {
    GET_PUBLIC_FORM_REQUEST: 'GET_PUBLIC_FORM_REQUEST',
    GET_PUBLIC_FORM_SUCCESS: 'GET_PUBLIC_FORM_SUCCESS',
    GET_PUBLIC_FORM_FAILURE: 'GET_PUBLIC_FORM_FAILURE',

    ADD_LEAD_PUBLIC_FORM_REQUEST: 'ADD_LEAD_PUBLIC_FORM_REQUEST',
    ADD_LEAD_PUBLIC_FORM_SUCCESS: 'ADD_LEAD_PUBLIC_FORM_SUCCESS',
    ADD_LEAD_PUBLIC_FORM_FAILURE: 'ADD_LEAD_PUBLIC_FORM_FAILURE',

    GET_OAUTH_CONNECT_REQUEST: 'GET_OAUTH_CONNECT_REQUEST',
    GET_OAUTH_CONNECT_SUCCESS: 'GET_OAUTH_CONNECT_SUCCESS',
    GET_OAUTH_CONNECT_FAILURE: 'GET_OAUTH_CONNECT_FAILURE',

    GET_OAUTH_AUTHORIZE_REQUEST: 'GET_OAUTH_AUTHORIZE_REQUEST',
    GET_OAUTH_AUTHORIZE_SUCCESS: 'GET_OAUTH_AUTHORIZE_SUCCESS',
    GET_OAUTH_AUTHORIZE_FAILURE: 'GET_OAUTH_AUTHORIZE_FAILURE',

    UPDATE_CONNECTION_REQUEST: 'UPDATE_CONNECTION_REQUEST',
    UPDATE_CONNECTION_SUCCESS: 'UPDATE_CONNECTION_SUCCESS',
    UPDATE_CONNECTION_FAILURE: 'UPDATE_CONNECTION_FAILURE',

    DISCONNECT_GMAIL_REQUEST: 'DISCONNECT_GMAIL_REQUEST',
    DISCONNECT_GMAIL_SUCCESS: 'DISCONNECT_GMAIL_SUCCESS',
    DISCONNECT_GMAIL_FAILURE: 'DISCONNECT_GMAIL_FAILURE',
};
