import { httpManagerService as httpManager } from  '_services';

export const managerService = {
    getManagers,
    getManagerConfig,
    deleteManager,
    updateManagerConfig,
    updateManagerConfigsParam,
    addManagerConfigParam,
    updateManagerConfigParam,
    deleteManagerConfigParam,
    getProjectConfigParam,
    getProjectConfigOperator
}

function getManagers(projectId,  query = null, page = 1, size = 1){

    console.log(query);
    let parts = [];
    if(query !== null && query !== '') {
        parts.push(`query=${query}`);
    }

    if(page != null && page > 0) {
        parts.push(`page=${page}`);
    }

    if(size != null && size > 0) {
        parts.push(`size=${size}`);
    }

    return httpManager.getHttpWithRequestingToken(`/api/v2/project/${projectId}/managers?` + parts.join('&',));
}

function getManagerConfig(projectId, managerId){
    return httpManager.getHttpWithRequestingToken(`/api/v2/project/${projectId}/managers/${managerId}`);
}

function deleteManager(projectId, managerId){
    return httpManager.deleteHTTPOnce(`/api/v2/project/${projectId}/managers/${managerId}`);
}

function updateManagerConfig(projectId, managerId, request){
    return httpManager.putJsonOnce(`/api/v2/project/${projectId}/managers/${managerId}`, request);
}

function addManagerConfigParam(projectId, managerId, request){
    return httpManager.postJsonOnce(`/api/v2/project/${projectId}/managers/${managerId}/config`, request);
}

function updateManagerConfigParam(projectId, managerId, configId, request){
    return httpManager.putJsonOnce(`/api/v2/project/${projectId}/managers/${managerId}/config/${configId}`, request);
}

function updateManagerConfigsParam(projectId, managerId, request){
    return httpManager.putJsonOnce(`/api/v2/project/${projectId}/managers/${managerId}/configs`, request);
}

function deleteManagerConfigParam(projectId, managerId, configId){
    return httpManager.deleteHTTPOnce(`/api/v2/project/${projectId}/managers/${managerId}/config/${configId}`);
}

function getProjectConfigParam(projectId, managerId){
    return httpManager.getHttpOnce(`/api/v2/project/${projectId}/managers/${managerId}/field/parameters`);
}

function getProjectConfigOperator(projectId, managerId){
    return httpManager.getHttpOnce(`/api/v2/project/${projectId}/managers/${managerId}/field/operators`);
}


