import React, { Component } from "react";
import { leadActions } from "_actions";
import { connect } from "react-redux";

class TablePagination extends Component {
  constructor(props) {
    super(props);
    const projectId = props.projectId;

    let page = sessionStorage.getItem(`lead_view_page_${projectId}`);
    let size = sessionStorage.getItem(`lead_view_size_${projectId}`);
    let query = sessionStorage.getItem(`lead_view_query_${projectId}`);

    this.gotoInputRef = React.createRef();

    this.state = {
      currentPage: page ? page : 1,
      currentSize: size ? size : 15,
      currentQuery: query ? query : "",
    };
  }

  // static getDerivedStateFromProps(nextProps, prevState) {
  //     // Update the state only if the size prop from props is different from the current currentSize state
  //     if (nextProps.size !== prevState.currentSize) {
  //         return { currentSize: nextProps.size };
  //     }
  //     return null;
  // }

  handlePageChanged = (value, className) => {
    if (className === "active") {
      this.setState({ currentPage: value });
      this.props.onHandlePageChanged(value);
    }
  };

  handleSizeChanged = (e) => {
    const { number, totalElements, size } = this.props;
    let newSize = parseInt(e.options[e.selectedIndex].value);

    let min = (number + 1) * newSize - (newSize - 1);

    if (min <= totalElements) {
      //  this.setState({ currentSize: size }); // Update the currentSize state
      this.props.onHandleSizeChanged(newSize); // Notify the parent component about the size change
    }

    if (min > totalElements) {
      this.props.onHandleSizeChanged(size);
    }
  };

  handleKeyDown = (event, value, className) => {
    const { currentSize, currentPage } = this.state;
    const { totalElements, size } = this.props;

    console.log('current page: ', currentPage);
    console.log('total el: ', totalElements);
    console.log('size: ', size);

    console.log('change page...', value);

    if (event.key === "Enter") {
      event.preventDefault(); // Prevent form submission
      if (className === "active") {
       
        let min = value * size - (size - 1);
        console.log('min: ' + min)
        if (min <= totalElements) {
          this.setState({ currentPage: value });
          this.props.onHandlePageChanged(value);
        }

        if (min > totalElements) {
            if((currentPage * size) > totalElements ) {
                this.props.onHandlePageChanged(1);
            } else {
                this.props.onHandlePageChanged(currentPage);
            }
          
        }
      }
    }
  };

  render() {
    const { number, first, last, size, totalElements, leadsLoaded } =
      this.props;
    const { currentPage, currentSize, currentQuery } = this.state;

    let page = number + 1;
    let min = page * size - (size - 1);
    let max = page * size;
    let count = totalElements;
    let prevClass = first ? "" : "active";
    let nextClass = last ? "" : "active";
    let nextPage = page + 1;
    let previousPage = page - 1;
    let defaultPage = parseInt(page);
    let activeSize = size;
    let countText = "Loading Leads ...";
    if (page) {
      let limit = max;
      if (count < max) {
        limit = count;
      }
      countText = `${min}-${limit} of ${count}`;
    }

    // console.log('current size: ' +  activeSize + ', current page: ' + page);
    // console.log('leadLoaded' + leadsLoaded + ',current size: ' + activeSize + ',size: ' + size);

    return (
      <div className="table-pagination-box">
        <div className="pagination-box">
          <div className="influencer-counts">
            <span>Show rows: </span>
            <select
              value={activeSize}
              onChange={(e) => this.handleSizeChanged(e.target)}
            >
              {leadsLoaded && (
                <React.Fragment>
                  <option value={15}>15</option>
                  <option value={25}>25</option>
                  <option value={50}>50</option>
                  <option value={100}>100</option>
                </React.Fragment>
              )}
            </select>

            <div className="go-to-page">
              <span>Go to</span>
              <input
                type="number"
                defaultValue={defaultPage}
                key={defaultPage}
                ref={this.gotoInputRef}
                onKeyDown={(e) =>
                  this.handleKeyDown(e, e.target.value, "active")
                }
              />
            </div>

            <span>{countText}</span>
          </div>
          <ul>
            <a
              onClick={() => this.handlePageChanged(previousPage, prevClass)}
              className={prevClass}
            >
              <i className="icon-lms icon-arrow-left"></i>
            </a>
            <a
              onClick={() => this.handlePageChanged(nextPage, nextClass)}
              className={nextClass}
            >
              <i className="icon-lms icon-arrow-right"></i>
            </a>
          </ul>
        </div>
      </div>
    );
  }
}

function mapState(state) {
  const { leadsLoaded } = state.leads;
  if (leadsLoaded) {
    const { number, first, last, size, totalElements, totalPages } =
      state.leads.leads.data;
    return {
      number,
      first,
      last,
      size,
      totalElements,
      totalPages,
      leadsLoaded,
    };
  }

  return { leadsLoaded };
}

const actionCreators = {
  getLeads: leadActions.getLeadsPaginated,
};

const connectedViewTablePagination = connect(
  mapState,
  actionCreators
)(TablePagination);
export { connectedViewTablePagination as TablePagination };
