export const calendlyConstants = {

    GET_CALENDLY_REQUEST: 'GET_CALENDLY_REQUEST',
    GET_CALENDLY_SUCCESS: 'GET_CALENDLY_SUCCESS',
    GET_CALENDLY_FAILURE: 'GET_CALENDLY_FAILURE',

    UPDATE_CALENDLY_REQUEST: 'UPDATE_CALENDLY_REQUEST',
    UPDATE_CALENDLY_SUCCESS: 'UPDATE_CALENDLY_SUCCESS',
    UPDATE_CALENDLY_FAILURE: 'UPDATE_CALENDLY_FAILURE',

    CREATE_CALENDLY_REQUEST: 'CREATE_CALENDLY_REQUEST',
    CREATE_CALENDLY_SUCCESS: 'CREATE_CALENDLY_SUCCESS',
    CREATE_CALENDLY_FAILURE: 'CREATE_CALENDLY_FAILURE',

    DELETE_CALENDLY_REQUEST: 'DELETE_CALENDLY_REQUEST',
    DELETE_CALENDLY_SUCCESS: 'DELETE_CALENDLY_SUCCESS',
    DELETE_CALENDLY_FAILURE: 'DELETE_CALENDLY_FAILURE',
    
}