import React, {Component} from 'react';
import {projectActions} from "_actions";
import {connect} from "react-redux";
import SideBarProjectGroup from './SideBarProjectGroup';

class SideBar extends Component {

    constructor(props) {
        super(props);
        this.state = {
            toggleSideBar: false,
            sideBarOverlay: false,
            openSubMenuIndex: null,
            active: -1
        }
    }

    componentDidMount() {
        const {getProjects} = this.props;
        getProjects();
    }

    handleToggleSideBar = (activeIndex) => {

        this.setState({toggleSideBar: !this.state.toggleSideBar});
        this.setState({sideBarOverlay: !this.state.sideBarOverlay});
        
        if (activeIndex !== undefined) {
            this.setState({active: activeIndex});
        }
       
    }

    handleToggleSubMenu = (index) => {
        this.setState((prevState) => ({
          openSubMenuIndex: prevState.openSubMenuIndex === index ? null : index
        }));
    };

    handleGroupProjects = (data) => {
        return data.reduce((project, arr) => {
            const {name} = arr;
            
            project[name] = project[name] ?? [];
            const found = project[name].some(el => el.info.name === arr.info.name);
            if (!found) {
                project[name].push(arr);
            }

            return project;
        }, []);
    }

    handleAddGroupProjects = (data) => {
        return data.map((item) => {
            // Add a new element to each object based on the name value
            let name = '';
            if (item.info['name'].includes('Primary')) {
                name = 'Primary Homes'
            }

            if (item.info['name'].includes('Maayo')) {
                name = 'Maayo'
            }

            if (item.info['name'].includes('Propelrr')) {
                name = 'Propelrr'
            }

            if (item.info['name'].includes('DCMI')) {
                name = 'DMCI'
            }

            const newItem = { ...item, name: name };
            return newItem;
          });
    }


    render() {
        const {projects, project, match} = this.props;
        const { openSubMenuIndex, active } = this.state;
        let owned = [];
        let shared = [];

        if(projects.loaded) {
            const {data} = projects.items;
            if(data) {
                let ownedProjects = [...data.owned];
                let sharedProjects = [...data.shared];

                ownedProjects = this.handleAddGroupProjects(ownedProjects);
                sharedProjects = this.handleAddGroupProjects(sharedProjects);

                // owned =  this.handleGroupProjects([...data.owned, ...data.shared]);
                owned =  this.handleGroupProjects([...ownedProjects, ...sharedProjects]);
            }

        }

        const isActive = (projectId) => {
            return window.location.pathname === `/project/${projectId}/leads`;
          };

        return(

            <>
            <div className={this.state.toggleSideBar ? 'sidebar-overlay active' : 'sidebar-overlay'} onClick={() => this.handleToggleSideBar()}></div>
            <div className={this.state.toggleSideBar ? 'sidebar-menu-box active' : 'sidebar-menu-box'}>
                <button className='btn-toggle' onClick={() => this.handleToggleSideBar()}><i className="icon-lms icon-arrow-right"></i></button>
                <div className='sidebar-menu-wrapper'>
                    <div className='sidebar-title'>
                        <p>
                            Projects
                        </p>
                    </div>
                    <div className='sidebar-list'>
                        <ul className="sidebar-dropdown">
                            {projects.loaded &&
                                <React.Fragment>
                                    {Object.keys(owned).map((o, index) => {
                                        return <SideBarProjectGroup key={index} name={o} project={owned[o]} active={active} onHandleToggleSideBar={this.handleToggleSideBar} />
                                    })}
                                </React.Fragment>
                            }
                        </ul>
                    </div>
                </div>
            </div>
            </>
        );

    }
}

function mapState(state) {
    const { projects, project } = state;
    return { projects, project };
}

const actionCreators = {
    getProjects: projectActions.getProjects,
    getProject: projectActions.getProject,
};

const connectedViewSideBar = connect(mapState, actionCreators)(SideBar);
export { connectedViewSideBar as SideBar };

