import { userConstants } from "_constants";
import {
  userService,
  leadService,
  utilitiesService as utility,
} from "_services";
import { alertActions } from "./";
import { history } from "_helpers";
import { retrieveToken } from "firebase";

export const userActions = {
  storeUser,
  getUser,
  register,
  login,
  twoFactorEmailVerify,
  twoFactorAppVerify,
  twoFactorResend,
  appKeySend,
  logout,
  updatePassword,
  forgotPassword,
  clearError,
  twoFactorEmailLogin,
  twoFactorAppLogin,
};

function storeUser(user) {
  userService.storeUser(user);
}
function getUser() {
  userService.getUser();
}

function clearError() {
  return { type: userConstants.CLEAR_ERROR };
}

function register(user) {
  return (dispatch) => {
    dispatch(request(user));
    userService.register(user).then(
      (user) => {
        dispatch(success());
        history.push("/login");
        dispatch(alertActions.success("Registration successful"));
      },
      (error) => {
        let errorMessage = "";
        if (error.toString() === "Error: Request failed with status code 500") {
          errorMessage =
            "Registration failed. Please check your credentials and try again.";
        } else {
          errorMessage = error.toString();
        }
        dispatch(failure(errorMessage));
        dispatch(alertActions.error(errorMessage));
      }
    );
  };

  function request(user) {
    return { type: userConstants.REGISTER_REQUEST, user };
  }
  function success(user) {
    return { type: userConstants.REGISTER_SUCCESS, user };
  }
  function failure(error) {
    return { type: userConstants.REGISTER_FAILURE, error };
  }
}

function login(email, password, redirect = null) {
  return (dispatch) => {
    dispatch(request({ email }));
    userService.login(email, password).then(
      (user) => {
        // console.log("user: ", user);
        const { two_factor } = user.data;

        dispatch(success(user));
        retrieveToken(user.data.id);

        if (redirect) {
          history.push(`/two-factor/select?redirect=${redirect}`);
        } else {
          history.push("/two-factor/select");
        }
      },
      (error) => {
        const { response } = error;
        const { data } = response;
        // console.log('error: ', response);

        let errorMessage = "Server Error";

        if ((response.status = 500)) {
          if ("error_description" in data) {
            errorMessage = data.error_description;
            dispatch(failure(errorMessage));
            dispatch(alertActions.error(errorMessage));
          }

          if ("error_message" in data) {
            errorMessage = data.error_message;
            dispatch(failure(errorMessage));
            dispatch(alertActions.error(errorMessage));
          }
        }

        if ((response.status = 400)) {
          if ("error_description" in data) {
            errorMessage = data.error_description;

            if (errorMessage === "username not found") {
              errorMessage = "Email not found. Please check your email.";
            }

            dispatch(failure(errorMessage));
            dispatch(alertActions.error(errorMessage));
          }

          if ("error_message" in data) {
            errorMessage = data.error_message;
            dispatch(failure(errorMessage));
            dispatch(alertActions.error(errorMessage));
          }
        }
      }
    );
  };

  function request(user) {
    return { type: userConstants.LOGIN_REQUEST, user };
  }
  function success(user) {
    return { type: userConstants.LOGIN_SUCCESS, user };
  }
  function failure(error) {
    return { type: userConstants.LOGIN_FAILURE, error };
  }
}

function twoFactorEmailLogin(redirect = null) {
  return (dispatch) => {
    dispatch(request({ "2fa": "email" }));
    userService.twoFactorEmailLogin().then(
      (user) => {
        console.log("user email: ", user);

        dispatch(success(user));
        retrieveToken(user.data.id);

        // console.log('redirect: ', redirect);
        if (redirect) {
          history.push(`/two-factor/email/verify?redirect=${redirect}`);
        } else {
          history.push("/two-factor/email/verify");
        }
      },
      (error) => {
        console.log('error: ', error)
        const { response } = error;
        const { data } = response;
        // console.log('error: ', response);

        let errorMessage = "Server Error";

        if ((response.status = 500)) {
          if ("error_description" in data) {
            errorMessage = data.error_description;
            dispatch(failure(errorMessage));
            dispatch(alertActions.error(errorMessage));
          }

          if ("error_message" in data) {
            errorMessage = data.error_message;
            dispatch(failure(errorMessage));
            dispatch(alertActions.error(errorMessage));
          }
        }

        if ((response.status = 400)) {
          if ("error_description" in data) {
            errorMessage = data.error_description;

            if (errorMessage === "username not found") {
              errorMessage = "Email not found. Please check your email.";
            }

            dispatch(failure(errorMessage));
            dispatch(alertActions.error(errorMessage));
          }

          if ("error_message" in data) {
            errorMessage = data.error_message;
            dispatch(failure(errorMessage));
            dispatch(alertActions.error(errorMessage));
          }
        }
      }
    );
  };

  function request(user) {
    return { type: userConstants.TWO_FACTOR_REQUEST, user };
  }
  function success(account) {
    return { type: userConstants.TWO_FACTOR_SUCCESS, account };
  }
  function failure(error) {
    return { type: userConstants.TWO_FACTOR_FAILURE, error };
  }
}

function twoFactorAppLogin(redirect = null) {
  return (dispatch) => {
    dispatch(request({ "2fa": "app" }));
    userService.twoFactorAppLogin().then(
      (user) => {
        // console.log("user app:", user);

        dispatch(success(user));
        retrieveToken(user.data.id);

        // console.log('redirect: ', redirect);
        if (redirect) {
          history.push(`/two-factor/app/verify?redirect=${redirect}`);
        } else {
          history.push("/two-factor/app/verify");
        }
      },
      (error) => {
        console.log('error: ', error)
        const { response } = error;
        const { data } = response;
        // console.log('error: ', response);

        let errorMessage = "Server Error";

        if ((response.status = 500)) {
          if ("error_description" in data) {
            errorMessage = data.error_description;
            dispatch(failure(errorMessage));
            dispatch(alertActions.error(errorMessage));
          }

          if ("error_message" in data) {
            errorMessage = data.error_message;
            dispatch(failure(errorMessage));
            dispatch(alertActions.error(errorMessage));
          }
        }

        if ((response.status = 400)) {
          if ("error_description" in data) {
            errorMessage = data.error_description;

            if (errorMessage === "username not found") {
              errorMessage = "Email not found. Please check your email.";
            }

            dispatch(failure(errorMessage));
            dispatch(alertActions.error(errorMessage));
          }

          if ("error_message" in data) {
            errorMessage = data.error_message;
            dispatch(failure(errorMessage));
            dispatch(alertActions.error(errorMessage));
          }
        }
      }
    );
  };

  function request(user) {
    return { type: userConstants.TWO_FACTOR_REQUEST , user};
  }
  function success(account) {
    return { type: userConstants.TWO_FACTOR_SUCCESS, account };
  }
  function failure(error) {
    return { type: userConstants.TWO_FACTOR_FAILURE, error };
  }
}

function twoFactorEmailVerify(twoFactorRequest, email = null, redirect = null) {
  return (dispatch) => {
    dispatch(request({ twoFactorRequest }));
    userService.twoFactorEmailVerify(twoFactorRequest).then(
      (user) => {
        dispatch(success(user));
        storeUser(user);

        const { projects } = user.data;
        let project = {};
        if (projects) {
          if (projects.shared.length > 0) {
            project = projects.shared[0];
          }

          if (projects.owned.length > 0) {
            project = projects.owned[0];
          }

          let module = "leads";
          if (project.influencer_enabled) {
            module = "influencers";
          }

          if (redirect) {
            let leadPath = redirect.split("/");
            leadService.find(leadPath[2], leadPath[4]).then(
              (lead) => {
                // console.log("authenticated", lead.data);
                if (lead.data.owner.email === email) {
                  //  console.log("owner redirected..");
                  history.push(redirect);
                } else if (lead.data.responsible) {
                  if (lead.data.responsible.email === email) {
                    //   console.log("responsible redirected..");
                    history.push(redirect);
                  } else {
                    history.push(`/project/${project.id}/${module}`);
                  }
                } else {
                  history.push(`/project/${project.id}/${module}`);
                }
              },
              (error) => {
                const { response } = error;
                const { data } = response;
              }
            );
          } else {
            history.replace("/login");
            history.push(`/project/${project.id}/${module}`);
          }
        }

        // console.log(user.data.id);
        // retrieveToken(user.data.id);
      },
      (error) => {
        const { response } = error;
        const { data } = response;

        let errorMessage = "Server Error";

        if ((response.status = 500)) {
          if ("error_description" in data) {
            errorMessage = data.error_description;
            dispatch(failure(errorMessage));
            dispatch(alertActions.error(errorMessage));
          }

          if ("error_message" in data) {
            errorMessage = data.error_message;
            dispatch(failure(errorMessage));
            dispatch(alertActions.error(errorMessage));
          }
        }

        if ((response.status = 400)) {
          if ("error_description" in data) {
            errorMessage = data.error_description;

            dispatch(failure(errorMessage));
            dispatch(alertActions.error(errorMessage));
          }

          if ("error_message" in data) {
            errorMessage = data.error_message;
            dispatch(failure(errorMessage));
            dispatch(alertActions.error(errorMessage));
          }
        }
      }
    );
  };

  function request(user) {
    return { type: userConstants.TWO_FACTOR_REQUEST, user };
  }
  function success(user) {
    return { type: userConstants.TWO_FACTOR_SUCCESS, user };
  }
  function failure(error) {
    return { type: userConstants.TWO_FACTOR_FAILURE, error };
  }
}

function twoFactorAppVerify(twoFactorRequest, email = null, redirect = null) {
  return (dispatch) => {
    dispatch(request({ twoFactorRequest }));
    userService.twoFactorAppVerify(twoFactorRequest).then(
      (user) => {
        dispatch(success(user));
        storeUser(user);

        const { projects } = user.data;
        let project = {};
        if (projects) {
          if (projects.shared.length > 0) {
            project = projects.shared[0];
          }

          if (projects.owned.length > 0) {
            project = projects.owned[0];
          }

          let module = "leads";
          if (project.influencer_enabled) {
            module = "influencers";
          }

          if (redirect) {
            let leadPath = redirect.split("/");
            leadService.find(leadPath[2], leadPath[4]).then(
              (lead) => {
                // console.log("authenticated", lead.data);
                if (lead.data.owner.email === email) {
                  //  console.log("owner redirected..");
                  history.push(redirect);
                } else if (lead.data.responsible) {
                  if (lead.data.responsible.email === email) {
                    //   console.log("responsible redirected..");
                    history.push(redirect);
                  } else {
                    history.push(`/project/${project.id}/${module}`);
                  }
                } else {
                  history.push(`/project/${project.id}/${module}`);
                }
              },
              (error) => {
                const { response } = error;
                const { data } = response;
              }
            );
          } else {
            history.replace("/login");
            history.push(`/project/${project.id}/${module}`);
          }
        }

        // console.log(user.data.id);
        // retrieveToken(user.data.id);
      },
      (error) => {
        const { response } = error;
        const { data } = response;

        let errorMessage = "Server Error";

        if ((response.status = 500)) {
          if ("error_description" in data) {
            errorMessage = data.error_description;
            dispatch(failure(errorMessage));
            dispatch(alertActions.error(errorMessage));
          }

          if ("error_message" in data) {
            errorMessage = data.error_message;
            dispatch(failure(errorMessage));
            dispatch(alertActions.error(errorMessage));
          }
        }

        if ((response.status = 400)) {
          if ("error_description" in data) {
            errorMessage = data.error_description;

            dispatch(failure(errorMessage));
            dispatch(alertActions.error(errorMessage));
          }

          if ("error_message" in data) {
            errorMessage = data.error_message;
            dispatch(failure(errorMessage));
            dispatch(alertActions.error(errorMessage));
          }
        }
      }
    );
  };

  function request(user) {
    return { type: userConstants.TWO_FACTOR_REQUEST, user };
  }
  function success(user) {
    return { type: userConstants.TWO_FACTOR_SUCCESS, user };
  }
  function failure(error) {
    return { type: userConstants.TWO_FACTOR_FAILURE, error };
  }
}

function twoFactorResend() {
  // console.log('resend called');
  return (dispatch) => {
    dispatch(request());
    userService.twoFactorResend().then(
      (account) => {
        //    console.log('resend code...')
        dispatch(success(account));
        // dispatch(
        //   alertActions.success("Successfully resend verification to your email")
        // );
      },
      (error) => {
        dispatch(failure(error.toString()));
        dispatch(alertActions.error(error.toString()));
      }
    );
  };

  function request() {
    return { type: userConstants.TWO_FACTOR_REQUEST };
  }
  function success(account) {
    return { type: userConstants.TWO_FACTOR_SUCCESS, account };
  }
  function failure(error) {
    return { type: userConstants.TWO_FACTOR_FAILURE, error };
  }
}

function appKeySend() {
  // console.log('resend called');
  return (dispatch) => {
    dispatch(request());
    userService.sendAppKey().then(
      (account) => {
        //    console.log('resend code...')
        dispatch(success(account));
        // dispatch(
        //   alertActions.success("Successfully resend verification to your email")
        // );
      },
      (error) => {
        dispatch(failure(error.toString()));
        dispatch(alertActions.error(error.toString()));
      }
    );
  };

  function request() {
    return { type: userConstants.TWO_FACTOR_REQUEST };
  }
  function success(account) {
    return { type: userConstants.TWO_FACTOR_SUCCESS, account };
  }
  function failure(error) {
    return { type: userConstants.TWO_FACTOR_FAILURE, error };
  }
}

function logout() {
  userService.logout();
  return { type: userConstants.LOGOUT };
}

function updatePassword(account) {
  return (dispatch) => {
    dispatch(request(account));
    userService
      .updatePassword(account)
      //this.props.updatePassword(account)
      .then(
        (account) => {
          dispatch(success(account));
          history.push("/login");
          dispatch(alertActions.success("Successfully updated password."));
        },
        (error) => {
          dispatch(failure(error.toString()));
          dispatch(alertActions.error(error.toString()));
        }
      );
  };

  function request(account) {
    return { type: userConstants.UPDATE_PASSWORD_REQUEST, account };
  }
  function success(account) {
    return { type: userConstants.UPDATE_PASSWORD_SUCCESS, account };
  }
  function failure(error) {
    return { type: userConstants.UPDATE_PASSWORD_FAILURE, error };
  }
}

function forgotPassword(account) {
  return (dispatch) => {
    dispatch(request(account));
    userService
      .forgotPassword(account)
      //this.props.updatePassword(account)
      .then(
        (account) => {
          // console.log("account: ", account.data);
          dispatch(success(account.data));
          // history.push('/login');
          dispatch(
            alertActions.success(
              "Your new password is successfully sent in email provided"
            )
          );
        },
        (error) => {
          const { response } = error;
          const { data } = response;

          let errorMessage = "Server Error";

          if ((response.status = 500)) {
            if ("error_description" in data) {
              errorMessage = data.error_description;
              dispatch(failure(errorMessage));
              dispatch(alertActions.error(errorMessage));
            }

            if ("error_message" in data) {
              errorMessage = data.error_message;
              dispatch(failure(errorMessage));
              dispatch(alertActions.error(errorMessage));
            }
          }

          if ((response.status = 400)) {
            if ("error_description" in data) {
              errorMessage = data.error_description;

              dispatch(failure(errorMessage));
              dispatch(alertActions.error(errorMessage));
            }

            if ("error_message" in data) {
              errorMessage = data.error_message;
              dispatch(failure(errorMessage));
              dispatch(alertActions.error(errorMessage));
            }
          }
        }
      );
  };

  function request(account) {
    return { type: userConstants.UPDATE_PASSWORD_REQUEST, account };
  }
  function success(account) {
    return { type: userConstants.UPDATE_PASSWORD_SUCCESS, account };
  }
  function failure(error) {
    return { type: userConstants.UPDATE_PASSWORD_FAILURE, error };
  }
}
