import React from 'react'
import { MDBContainer, MDBRow, MDBCol, MDBModal, MDBModalHeader, MDBModalBody } from 'mdbreact'
import { InputSelect } from "blackbox-react";

const EditClientModal = ({ open, toggle, close }) => {

  return (
    <MDBContainer className="modal-container modal-form-container">
      <MDBRow>
        <MDBCol>
          <MDBModal isOpen={open} toggle={toggle} centered tabIndex="-1">
            <MDBModalHeader className="confirmation-modal-header text-uppercase p-2">
              <span className="pl-1 mx-2 letter-spacing-1 font-weight-600 font-color-gray font-size-13">
                ADD CLIENT
              </span>
            </MDBModalHeader>
            <MDBModalBody>
              <MDBRow className="form-group-row">
                <MDBCol className="col-12">
                  <div className="client-lists-box">
                    <div className='client-group'>
                      <div className="checkbox-group">
                        <input type="checkbox" name="client1" id="client1" />
                        <label for="client1">Propelrr Company</label>
                      </div>
                      <div className="checkbox-group">
                        <input type="checkbox" name="client2" id="client2" />
                        <label for="client2">Propelrr Company</label>
                      </div>
                      <div className="checkbox-group">
                        <input type="checkbox" name="client3" id="client3" />
                        <label for="client3">Propelrr Company</label>
                      </div>
                    </div>
                    <div className='client-group'>
                      <div className="checkbox-group">
                        <input type="checkbox" name="client4" id="client4" />
                        <label for="client4">Propelrr Company</label>
                      </div>
                      <div className="checkbox-group">
                        <input type="checkbox" name="client5" id="client5" />
                        <label for="client5">Propelrr Company</label>
                      </div>
                      <div className="checkbox-group">
                        <input type="checkbox" name="client6" id="client6" />
                        <label for="client6">Propelrr Company</label>
                      </div>
                    </div>
                    <div className='client-group'>
                      <div className="checkbox-group">
                        <input type="checkbox" name="client7" id="client7" />
                        <label for="client7">Propelrr Company</label>
                      </div>
                      <div className="checkbox-group">
                        <input type="checkbox" name="client8" id="client8" />
                        <label for="client8">Propelrr Company</label>
                      </div>
                      <div className="checkbox-group">
                        <input type="checkbox" name="client9" id="client9" />
                        <label for="client9">Propelrr Company</label>
                      </div>
                    </div>
                  </div>
                </MDBCol>
              </MDBRow>
              <MDBRow>
                <MDBCol className="text-center">
                  <button className="modal-btn save-btn-modal">
                    Save Client
                  </button>
                  <button
                    className="modal-btn cancel-btn-modal"
                    onClick={close}
                  >
                    Cancel
                  </button>
                </MDBCol>
              </MDBRow>
            </MDBModalBody>
          </MDBModal>
        </MDBCol>
      </MDBRow>
    </MDBContainer>
  );
}

export default EditClientModal