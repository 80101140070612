import React, {Component} from 'react';
import ClickStatusInfo from './ClickStatusInfo'
import HoverStatusInfo from './HoverStatusInfo'
import {leadActions} from "_actions";
import {connect} from "react-redux";
import {abbreviateNumber} from '_services/utilities.service';
import { KpiHeaderFilter } from './KpiHeaderFilter';
import { Swiper, SwiperSlide } from 'swiper/react';
import { FreeMode, Navigation } from "swiper";
import KpiLoader from '../../../../_components/Loader/KpiLoader';


class KpiHeader extends Component {

    constructor(props) {
        super(props);

        this.kpiRef = React.createRef();

        this.state = {
            reset: true,
            visibleKpis: [],
            kpisFiltered: false,
            kpiTotalHeight: 0,
        }
    }

    handleKpiHeaderFilter (value, key, color) {
        this.props.onHandleKpiHeaderFilter(value, key, color);
    }

    handleReset () {
        this.props.onHandleReset();
        this.setState({kpisFiltered: false});
    }

    handleKpiHeaderFilterChanged(data) {
        if(this.state.visibleKpis !== data){
            this.setState({visibleKpis : data, kpisFiltered: true});
        }
    }

    componentDidMount() {
        this.kpiTotalHeight = this.kpiRef.current.clientHeight;
    }


    render() {

        const {page, summaryLoaded, isKpiHeaderExpanded} = this.props;
        const {visibleKpis, kpisFiltered} = this.state;

        let grouped = [];
        let categories = [];
        let ungrouped = [];
        let custom = {};
        let total = 0;

        let activeClass = isKpiHeaderExpanded ? 'active' : '';

        if (summaryLoaded) {
            ungrouped = page.data;
            grouped = ungrouped.reduce(function (collection, elem) {
                let key = elem['category'];
                if (!categories.includes(key) && key !== 'Custom') {
                    categories.push(key);
                }

                total = total + elem['count'];
                (collection[key] = collection[key] || []).push(elem);
                return collection;
            }, []);
            

            if(grouped['Custom']) {
                custom.visits = grouped['Custom'].filter(e => e.label === 'Total Number of Visits')[0].value;
                custom.average = parseFloat(grouped['Custom'].filter(e => e.label === 'Average Patient Lead Value')[0].value);
                custom.matched = grouped['Custom'].filter(e => e.label === 'Total Matched Patient')[0].value;
                custom.total = parseFloat(grouped['Custom'].filter(e => e.label === 'Total Patient Lead Value')[0].value);
            }

        }

        return (
            <React.Fragment>
                <div className={`overview-kpi-box leads-kpi ${activeClass}`} ref={this.kpiRef}
                style={
                    isKpiHeaderExpanded
                    ? { maxHeight: this.kpiTotalHeight }
                    : { maxHeight: "0" }
                }>
                    <div className='main-box'>
                        <div className='status-list-box'>
                            <div className='status-list'>
                                <Swiper
                                    watchSlidesProgress = {true}
                                    observer = {true}
                                    observeParents = {true}
                                    observeSlideChildren = {true}
                                    resizeObserver= {true}
                                    slidesPerView={"auto"}
                                    spaceBetween={12}
                                    freeMode={true}
                                    modules={[FreeMode, Navigation]}
                                    className="kpiSwiper"
                                    navigation={{ nextEl: ".nextSlide", prevEl: ".prevSlide" }}
                                    onSlideChange={(swiper) => swiper.update()}
                                >

                                    <SwiperSlide >
                                        <div className='box' onClick={() => this.handleKpiHeaderFilter(ungrouped)}>
                                            <div className='status-title'>
                                                <p className='main-title'>Total Leads</p>
                                                {/* <ClickStatusInfo
                                                    title='Total Leads'
                                                    description='Total Leads.'
                                                /> */}
                                            </div>
                                            <div className='status-result'>
                                                <div className='result-box'>
                                                    <HoverStatusInfo
                                                        info='Total Leads'
                                                    />
                                                    <span className='result-count'>{total}</span>
                                                </div>
                                            </div>
                                        </div>
                                    </SwiperSlide>

                                {grouped['Custom'] &&
                                    <SwiperSlide>
                                        <div className='box custom'>
                                            <div className='status-title'>
                                                <p className='main-title'>Patient Lead Value</p>
                                                {/* <ClickStatusInfo
                                                    title='Patient Lead Value'
                                                    description=''
                                                /> */}
                                            </div>
                                            <div className='status-result'>
                                                <div className='result-box'>
                                                    <HoverStatusInfo
                                                        info='Patient Lead Value'
                                                    />
                                                    {custom.total !== 'null' ?
                                                        <span className='result-count'>₱ {abbreviateNumber(custom.total)}</span>:
                                                        <span className='result-count'>₱ 0.00</span>}
                                                </div>
                                                <div className='result-box'>
                                                    <HoverStatusInfo
                                                        info='Average Patient Lead Value'
                                                    />
                                                    {custom.average !== 'null' ?
                                                        <span className='result-count'>₱ {abbreviateNumber(custom.average)} <small>ave</small></span>:
                                                        <span className='result-count'>₱ 0.00 <small>ave</small></span>}
                                                </div>
                                            </div>
                                        </div>
                                    </SwiperSlide>
                                }
                                {grouped['Custom'] &&
                                    <SwiperSlide>
                                        <div className={'box custom'}>
                                            <div className='status-title'>
                                                <p className='main-title'>Patients</p>
                                                {/* <ClickStatusInfo
                                                    title='Total matched patients'
                                                    description=''
                                                /> */}
                                            </div>
                                            <div className='status-result'>
                                                <div className='result-box'>
                                                    <HoverStatusInfo
                                                        info='Unique Leads'
                                                    />
                                                    <span className='result-count'>{custom.matched} <small>matched</small></span>
                                                </div>
                                                <div className='result-box'>
                                                    <HoverStatusInfo
                                                        info='Total Visits'
                                                    />
                                                    <span className='result-count'>{custom.visits} <small>visits</small></span>
                                                </div>
                                            </div>
                                        </div>
                                    </SwiperSlide>
                                }
                                {categories.map((value,index) => {
                                    let color = index + 1;
                                    return(
                                        <SwiperSlide key={index} className={visibleKpis.includes(value) || !kpisFiltered ? '' : 'not-filtered'}>
                                            <div className={`box kpi-color-${color}`} onClick={() => this.handleKpiHeaderFilter(grouped[value], value, color)}>
                                                <div className='status-title'>
                                                    <p className='main-title'>{value}</p>
                                                    {/* <ClickStatusInfo
                                                        title={value}
                                                        description={value}
                                                    /> */}
                                                </div>
                                                <div className='status-result'>
                                                    {grouped[value].map((item, index) => {
                                                        return(<div className='result-box' key={index}>
                                                            <HoverStatusInfo
                                                                info={item.label}
                                                            />
                                                            <span className='result-count'>{item.count}</span>
                                                        </div>);
                                                    })}
                                                </div>
                                            </div>
                                        </SwiperSlide>
                                    );
                                })}
                                </Swiper>
                                <button className='swiper-custom-btn prevSlide'><i className="icon-lms icon-arrow-left"></i></button>
                                <button className='swiper-custom-btn nextSlide'><i className="icon-lms icon-arrow-right"></i></button>
                            </div>
                        </div>
                    </div>
                    {
                        !summaryLoaded &&
                        <KpiLoader />
                    }
                </div>
            </React.Fragment>
        );
    }
}

function mapState(state) {
    const {page, summaryLoaded}  = state.summary
    return {page, summaryLoaded};
}


const actionCreators = {
    getLeadSummary: leadActions.getLeadSummary,
};

const connectedViewKpiHeader = connect(mapState, actionCreators)(KpiHeader);
export {connectedViewKpiHeader as KpiHeader};

