import React, {Component} from 'react';
import {connect} from "react-redux";
import TopBar from '../../_components/TopBar';
import ProjectDetails from '../../_components/Drawer/ProjectDetails';
import ProjectMembers from '../../_components/Drawer/ProjectMembers';
import {AddInfluencerDrawer} from '_pages/Influencer/components/Drawer/AddInfluencerDrawer';
import {projectActions} from "_actions";
import Button from "_pages/Influencer/components/General/Button";
import {influencerActions} from "_actions/influencer.actions";
import {KpiHeader} from "_pages/Influencer/components/Kpi/KpiHeader";
import {KpiControl} from "_pages/Influencer/components/Kpi/KpiControl";
import {InfluencerTableList} from "_pages/Influencer/components/Table/InfluencerTableList";
import {TablePagination} from '_pages/Influencer/components/Table/TablePagination';
// import {InfluencerTableListNew} from "_pages/Influencer/components/Table/InfluencerTableListNew";
import TopBarLoader from '../../_components/Loader/TopBarLoader';
import ButtonGroupLoader from '../../_components/Loader/ButtonGroupLoader';
import moment from "moment";

class Influencer extends Component {

    constructor(props) {
        super(props);

        this.state = {
            activeBox: '',
            selectedStatus : [],
            selectedPlatform : [],
            selectedCategories : [],
            isKpiHeaderExpanded : true,
            isProjectDetailsDrawer: false,
            isProjectMembersDrawer: false,
            isAddInfluencerDrawer: false,
            query: null,
            totalLeads:0,
            maxPage:0,
            pageSize: 15,
            currentPage: 1,
            sort: 'created',
			direction: 'DESC',
            dates: [],
            resetFilterState: false,
        };


    }

    componentDidMount() {
        const {getProject, getInfluencerList, match} = this.props;
        const projectId = match.params.id;
        getProject(projectId);
        getInfluencerList(projectId);
    }

    handleApplyFilter(status, platform, category) {

        this.setState({
            selectedStatus: status,
            selectedPlatform: platform,
            selectedCategories: category,
            currentPage: 1,
            activeBox: ''
        });

        const {getInfluencerList, match} = this.props;
        const projectId = match.params.id;
        const {currentPage, pageSize, query, selectedStatus, selectedPlatform, selectedCategories} = this.state;
        getInfluencerList(projectId, currentPage, pageSize, query, status, platform, category);

    }

    handleFilterStateReset = () => {
        this.setState({ resetFilterState: false });
      };

      handleClearSelectedDates = () => {
        this.setState({ dates: [] });
      };

    handleFilterKpi(group, value, color) {
    
        const { currentPage, selectedStatus, pageSize, query, selectedPlatform, selectedCategories } = this.state;
        console.log('clicked here...page: ' + currentPage + ' ,page size: ' + pageSize);

        this.setState({
            selectedPlatform: [group],
            selectedStatus: [],
            query: '',
            currentPage: 1,
            activeBox: value,
            resetFilterState: true, 
            dates: []
         });

        if (group === 0) {
            this.handleReset();
        } else {

            const { getInfluencerList, match } = this.props;
            const projectId = match.params.id;

            this.setState({
                currentPage: 1,
              }, () => {
                getInfluencerList(projectId, null, pageSize, query, [], [group], [], null, null, null, null);
              });
        }
    }

    handlePageChange(page) {
        this.setState({
            currentPage: page
        });

        const {getInfluencerList, match} = this.props;
        const projectId = match.params.id;
        const {currentPage, pageSize, query, selectedStatus, selectedPlatform, selectedCategories, dates} = this.state;

        let dateFrom = null;
		let dateTo = null;

		if(Array.isArray(dates) && dates.length > 0) {
			dateFrom = moment(new Date(dates[0])).format('YYYY-MM-DD');
			dateTo = moment(new Date(dates[1])).format('YYYY-MM-DD');
            // console.log(dateFrom)
            // console.log(dateTo)
		}


        getInfluencerList(projectId, page, pageSize, query, selectedStatus, selectedPlatform, selectedCategories, null, null, dateFrom, dateTo);
    }

    handleSearch(updatedQuery) {

        this.setState({
            query: updatedQuery,
            currentPage: 1
        });

        const {getInfluencerList, match, } = this.props;
        const projectId = match.params.id;
        const {currentPage, pageSize, query, selectedStatus, selectedPlatform, selectedCategories, dates} = this.state;

        let dateFrom = null;
		let dateTo = null;

		if(Array.isArray(dates) && dates.length > 0) {
			dateFrom = moment(new Date(dates[0])).format('YYYY-MM-DD');
			dateTo = moment(new Date(dates[1])).format('YYYY-MM-DD');
            // console.log(dateFrom)
            // console.log(dateTo)
		}

        getInfluencerList(projectId, currentPage, pageSize, updatedQuery, selectedStatus, selectedPlatform, selectedCategories, null, null, dateFrom, dateTo);

    }

    handleSizeChange = (size) => {

		const {match, getInfluencerList} = this.props;
		const projectId = match.params.id;
        const {currentPage, pageSize, query, selectedStatus, selectedPlatform, selectedCategories, dates} = this.state;

        this.setState({
            pageSize: size
        });


		let dateFrom = null;
		let dateTo = null;

		if(Array.isArray(dates) && dates.length > 0) {
			dateFrom = moment(new Date(dates[0])).format('YYYY-MM-DD');
			dateTo = moment(new Date(dates[1])).format('YYYY-MM-DD');
		}

		getInfluencerList(projectId, currentPage, size, query, selectedStatus, selectedPlatform, selectedCategories, dateFrom, dateTo);

	}


    handleReset() {

        const {getInfluencerList, match} = this.props;
        const projectId = match.params.id;

        this.setState({
            dates: [],
            selectedStatus: [],
            selectedPlatform: [],
            query: null,
            totalLeads: 0,
            maxPage: 0,
            pageSize: 15,
            currentPage: 1,
            activeBox: '',
          }, () => {
            getInfluencerList(projectId, null, null, null, [], [], [], null, null, null, null);
          });
    }

    handleDateChange = dates => {

		const {match, getInfluencerSummary, getInfluencerList} = this.props;
		const projectId = match.params.id;

        let {pageSize, query, selectedStatus, selectedPlatform, selectedCategories, sort, direction} = this.state;
		this.setState({dates : dates});

		let dateFrom = null;
		let dateTo = null;

		if(Array.isArray(dates) && dates.length > 0) {
			dateFrom = moment(new Date(dates[0])).format('YYYY-MM-DD');
			dateTo = moment(new Date(dates[1])).format('YYYY-MM-DD');
            // console.log(dateFrom)
            // console.log(dateTo)
		}

        getInfluencerList(projectId, 1, pageSize, query, selectedStatus, selectedPlatform, selectedCategories, sort, direction, dateFrom, dateTo);
        // getInfluencerSummary(projectId, dateFrom, dateTo);
	}

    handleRequestList() {
        const {getInfluencerList, match} = this.props;
        const projectId = match.params.id;
        const {currentPage, pageSize, query, selectedStatus, selectedPlatform, selectedCategories} = this.state;
        getInfluencerList(projectId, currentPage, pageSize, query, selectedStatus, selectedPlatform, selectedCategories);
    }

    handleAddInfluencerModalClose () {
        this.setState({isAddModelOpen: false})
    }


    handleKpiHeaderChanged(isExpanded) {
        // console.log(isExpanded);
        this.setState({isKpiHeaderExpanded: isExpanded})
    }

    openAddInfluencerModal () {
        this.setState({isAddModelOpen: true})
    }

    handleProjectDetailsDrawer() {
        this.setState({isProjectDetailsDrawer: !this.state.isProjectDetailsDrawer})
    }

    handleProjectMembersDrawer() {
        this.setState({isProjectMembersDrawer: !this.state.isProjectMembersDrawer})
    }

    handleAddInfluencerDrawer() {
        this.setState({isAddInfluencerDrawer: !this.state.isAddInfluencerDrawer})
    }

    render() {

        const {match} = this.props;
        const projectId = match.params.id;
        const {isKpiHeaderExpanded, activeBox, isProjectDetailsDrawer, isProjectMembersDrawer, isAddInfluencerDrawer} = this.state;
        let {dates} = this.state;
        let kpiTotalHeight = 0;

        return (
            <React.Fragment>
                <div className='topbar-menu'>
                    <div className='row align-items-center'>
                        <TopBar 
                            onHandleProjectDetailsDrawer={(width) => this.handleProjectDetailsDrawer(width)}
                            onHandleProjectMembersDrawer={(width) => this.handleProjectMembersDrawer(width)}
                        />
                        <div className='col'>
                            <div className='top-button-box'>
                                <Button classN="c-btn" text='Add Influencer' icon="icon-lms icon-add-user" onClick={() => this.handleAddInfluencerDrawer()} />
                            </div>
                        </div>
                    </div>
                </div>
                
                <div className='overview-menu'>
                    <KpiControl
                        projectId={projectId}
                        dates={dates}
                        onApplyFilter={(status, platform, category) => this.handleApplyFilter(status, platform, category)}
                        onHandlePageChanged={(page) => this.handlePageChange(page)}
                        onHandleSearch={(query) => this.handleSearch(query)}
                        onHandleDateChanged={this.handleDateChange}
                        onHandleSizeChanged={this.handleSizeChange}
                        onHandleKpiHeaderHeightChanged={(height) => this.handleKpiHeaderChanged(height)}
                        resetFilterState = {this.state.resetFilterState}
                        onHandleResetFilter = {this.handleFilterStateReset}
                    />
                </div>

                <KpiHeader
                    onHandleKpiHeaderHeightChanged={(height) => this.handleKpiHeaderChanged(height)}
                    onHandleKpiHeaderFilter={(group, value, color) => this.handleFilterKpi(group, value, color)}
                    clickedBox={activeBox}
                    onHandleReset={() => this.handleReset()}
                    projectId={projectId}
                    kpiTotalHeight={kpiTotalHeight}
                    onApplyFilter={(status, platform, category) => this.handleApplyFilter(status, platform, category)}
                    isKpiHeaderExpanded={isKpiHeaderExpanded}
                />

                <div className='main-table-section'>
                    <InfluencerTableList
                        isKpiHeaderExpanded={isKpiHeaderExpanded}
                        kpiTotalHeight={kpiTotalHeight}
                        onHandleAddInfluencerDrawer={() => this.handleAddInfluencerDrawer()}
                    />
                    <TablePagination 
                       projectId={projectId}
                       dates={dates}
                       onApplyFilter={(status, platform, category) => this.handleApplyFilter(status, platform, category)}
                       onHandlePageChanged={(page) => this.handlePageChange(page)}
                       onHandleSearch={(query) => this.handleSearch(query)}
                       onHandleDateChanged={this.handleDateChange}
                       onHandleSizeChanged={this.handleSizeChange}
                       onHandleKpiHeaderHeightChanged={(height) => this.handleKpiHeaderChanged(height)}
                    />
                </div>
                
                {/* Drawers */}
                <ProjectDetails 
                    isProjectDetailsDrawer={isProjectDetailsDrawer} 
                    onHandleProjectDetailsDrawer={() => this.handleProjectDetailsDrawer()} />

                <ProjectMembers 
                    isProjectMembersDrawer={isProjectMembersDrawer} 
                    onHandleProjectMembersDrawer={() => this.handleProjectMembersDrawer()} />

                <AddInfluencerDrawer 
                    projectId={projectId}
                    isAddInfluencerDrawer={isAddInfluencerDrawer} 
                    onHandleAddInfluencerDrawer={() => this.handleAddInfluencerDrawer()} />

            </React.Fragment>)
    }
}

function mapState(state) {
    const { projects, influencers } = state;
    return { projects, influencers }
}

const actionCreators = {
    getProject: projectActions.getProject,
    getInfluencerList: influencerActions.getInfluencerList,
    getInfluencerSummary: influencerActions.getInfluencerSummary,
};

const connectedViewInfluencer = connect(mapState, actionCreators)(Influencer);
export { connectedViewInfluencer as Influencer };
