import React from 'react'
import { Link } from 'react-router-dom'

const Button = ({ text, icon, onClick }) => {
  return (
    <>
      <button type='button' onClick={onClick}>
        <i className={`fas ${icon}`}></i>
        {text}
      </button>
    </>
  )
}

export default Button