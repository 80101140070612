
import {getLeadFieldValue, getCampaignData} from '_services/utilities.service'
import React from "react";

const PrimaryHomesLeadColumns = props => {

    const { lead } = props;

    let type = getLeadFieldValue(lead, 57);
    let campaign = getCampaignData(lead);

    return (
        <React.Fragment>
            <td>
                <span>{type}</span>
            </td>

            <td>
                <span>{lead.origin}</span>
            </td>

            <td>
                <span>{campaign.source}</span>
            </td>

            <td>
                <span>{campaign.medium}</span>
            </td>

            <td>
                <span>{campaign.campaign}</span>
            </td>

            <td>
                <span>{campaign.qualification}</span>
            </td>
        </React.Fragment>
    );

}

export default PrimaryHomesLeadColumns;