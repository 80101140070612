import { httpManagerService as httpManager } from  '_services';

export const profileService = {
    getProfile,
    updateProfile,
    updatePassword,
    getTemplates,
    getTemplate,
    createTemplate,
    updateTemplate,
    removeTemplate,
    removeTemplates
}

function getProfile(){
    return httpManager.getHttpWithRequestingToken(`/api/profile`);
}

function updateProfile(request){
    return httpManager.putJsonOnce(`/api/profile`, request);
}

function updatePassword(request){
    return httpManager.putJsonOnce(`/api/profile/password`, request);
}

function getTemplates(){
    return httpManager.getHttpWithRequestingToken(`/api/v2/profile/email/template`);
}

function getTemplate(templateId){
    return httpManager.getHttpWithRequestingToken(`/api/v2/profile/email/template/${templateId}`);
}

function createTemplate(request){
    return httpManager.postJsonOnce(`/api/v2/profile/email/template`, request);
}

function updateTemplate(templateId, request){
    return httpManager.putJsonOnce(`/api/v2/profile/email/template/${templateId}`, request);
}

function removeTemplate(templateId){
    return httpManager.deleteJsonOnce(`/api/v2/profile/email/template/${templateId}`);
}

function removeTemplates(request){
    return httpManager.deleteJsonOnce(`/api/v2/profile/email/templates`, request);
}