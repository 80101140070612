import React, {Component} from 'react'
import { Link } from 'react-router-dom'
import {projectActions, influencerActions} from "_actions";
import {connect} from "react-redux";
import TextLoader from '_components/Loader/TextLoader';

class InfluencerBreadcrumb extends Component {

    render() {


        const {projects, influencers, influencerBdLoaded, influencerBdLoading } = this.props;

        const project = projects.project;
        const influencer = influencers.influencer;


        return (<div className='breadcrumbs'>
            {project &&<Link to={`/project/4/influencers`} className='back'>
                <span className='icon-s'><i className="icon-lms icon-arrow-left"></i></span>
            </Link>}

            {!project &&<Link  className='back'>
                    <span className='icon-s'><i className="icon-lms icon-arrow-left"></i></span>
            </Link>}

            {/* {project && <Link to={`/project/${project.data.id}`} className='link'>
                {project.data.info.name}<i>/</i>
            </Link>} */}

            {project &&<Link to={`/project/${project.data.id}/influencers`} className='link'>
                Influencers{influencer &&<i>/</i>}
            </Link>}

            {!project &&<Link className='link'>
                Influencers<i>/</i>
            </Link>}

            {influencer && influencerBdLoaded && <span>
                {influencer.data.name}
            </span>}

            {
                influencerBdLoading && <TextLoader />
            }
            
        </div>)

    }
}

function mapState(state) {
    const {projects, influencers} = state;
    return {projects, influencers};
}

const actionCreators = {
    getProject: projectActions.getProject,
    getInfluencer: influencerActions.getInfluencer,
};

const connectedViewInfluencerBreadcrumb = connect(mapState, actionCreators)(InfluencerBreadcrumb);
export {connectedViewInfluencerBreadcrumb as InfluencerBreadcrumb};