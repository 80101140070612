import React from 'react'

const TableHeader = () => {
  return (
    <thead>
        <tr>
            <th width='20%' colSpan={'1'}>
                <span>Name</span>
            </th>
            <th width='21%' colSpan={'2'}>
                <span>Platform</span>
            </th>
            <th width='16%' colSpan={'1'}>
                <span>Contact Details</span>
            </th>
            <th width='14%' colSpan={'1'}>
                <span>Client</span>
            </th>
            <th width='12%' colSpan={'1'}>
                <span>Rates</span>
            </th>
            <th width='10%' colSpan={'1'}>
                <span>Status</span>
            </th>
            <th width='12%' colSpan={'1'}>
                <span>Date Contacted</span>
            </th>
            <th width='15%' colSpan={'1'}>
                <span>Category</span>
            </th>
        </tr>
    </thead>
  )
}

export default TableHeader