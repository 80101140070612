import React from 'react';
import { connect } from "react-redux";

import { projectActions } from '_actions';

import { ButtonGroup, Button, Icon } from "blackbox-react";
import { MDBContainer, MDBRow, MDBCol } from 'mdbreact';

import { CreateProjectModal } from '_pages/Projects/components/CreateProjectModal';
import InputForm from '_components/InputForm';
import ProjectCardView from '_pages/Projects/components/ProjectCardView';

import search from '_assets/images/icon-finder.png';


class Projects extends React.Component {
  constructor(props){
    super(props);

    // const projects = this.props.getProjects();

    this.state = {
      isOpen: false,
      createProjectModalOpen: false,
      loadAddTaskModal: false,
      activeFilter: 'all',
      activeSort: 'lastUpdated',
      hasSelected: 'invisible',
      triggerSearch: false,
      searchKeyword: '',
      searchHasResults: true,
      filtered: [],
      // projects: projects,
      filterNav: [
        {
          name: 'All',
          value: 'all'
        },
        {
          name: 'Owned',
          value: 'owned'
        },
        {
          name: 'Shared to me',
          value: 'shared'
        }
      ],
      sortNav: [
        {
          name: 'Last Updated',
          value: 'lastUpdated'
        },
        {
          name: 'Newest',
          value: 'newest'
        },
        {
          name: 'Oldest',
          value: 'oldest'
        },
        {
          name: 'A-Z',
          value: 'az'
        },
        {
          name: 'Z-A',
          value: 'za'
        }
      ]
    };

    this.handleSearch = this.handleSearch.bind(this);
  }

  componentDidMount() {
    sessionStorage.removeItem('project');
    sessionStorage.removeItem('projectId');
    sessionStorage.removeItem('form');
    sessionStorage.removeItem('fieldIndex');
    sessionStorage.removeItem('fieldTotalNum');
    sessionStorage.removeItem('isActiveModule');
    sessionStorage.removeItem('AllLeadImport');
    sessionStorage.setItem('isActiveModule', 'projects');
  }

  toggleCreateProject = () => {
    let { createProjectModalOpen } = this.state;
    this.setState({ createProjectModalOpen: !createProjectModalOpen })
  };

  handleChange = event => {
    this.setState({ [event.target.name]: event.target.value });
  };

  handleSearch = event => {

    this.setState({
      searchKeyword: event.target.value,
      triggerSearch: true
    });
  };

  hasResults = data => {};

  handleFilter = () => {
    let { filterNav } = this.state;
    let sliderClass = '';
    return (
      filterNav.map((value, index) => {
        sliderClass = this.state.activeFilter === value.value ? 'border-right-orange text-right' : 'border-right-gray-slider text-right';
        return (
          <p key={index} className={`${sliderClass} py-2 pr-3 mb-15 pointer-cursor`} onClick={() => { this.setState({ activeFilter: value.value }) }}>{value.name}</p>
        )
      })
    )
  };

  handleSort = () => {
    let { sortNav } = this.state;
    let sliderClass = '';
    return (
      sortNav.map((value, index) => {
        sliderClass = this.state.activeSort === value.value ? 'border-right-orange text-right' : 'border-right-gray-slider text-right';
        return (
          <p key={index} className={`${sliderClass} py-2 pr-3 mb-15 pointer-cursor`} onClick={() => { this.setState({ activeSort: value.value }) }}>{value.name}</p>
        )
      })
    )
  };

  renderSearch = () => {
    let { projects } = this.props;

    if (typeof projects != 'undefined') {
      return (
		
		<MDBCol lg='5' xl='4' md='5' className='text-center'>
			<div className='pos-r'>
				<InputForm
					onChange={this.handleSearch}
					type='text'
					hint='Search'
					name='search'
					inputClass='padding-left-10 pr-4'
				/>
				<img src={search} alt='' className='icon-search' />
			</div>
		</MDBCol>
      )
    }
  };

  renderProjectView = (filter, sort) => {
    const { projects } = this.props;

    return <ProjectCardView
      searchKeyword={this.state.searchKeyword}
      data={projects.items.data}
      filter={filter}
      sort={sort}
      hasResults={this.hasResults}
    />;

  };

  render() {
    const { projects } = this.props;
    const { activeFilter, activeSort } = this.state;
    let hasData = false;
    let totalProjects = 0;
    let searchHasResults = this.state.searchHasResults;

    // // Determine if user has owned and shared projects
    let isLoaded = (typeof projects.items !== 'undefined');
    // if (isLoaded && (typeof projects.items.data.owned !== 'undefined') && (typeof projects.items.data.shared !== 'undefined')){
    //   totalProjects = projects.items.data.owned.length + projects.items.data.shared.length;
    //   if (totalProjects > 0){
    //     hasData = true;
    //   }
    // }

    return (
      <MDBContainer fluid className="p--0">
        <div className='page-title-ctas p-l-md p-r-md'>
			<MDBRow className='m--0 align-items-center'>
				<MDBCol md='auto' className='p--0 m--0'>
					<p className='page-title font-family-kanit font-size-30 line-height-7 font-weight-600 color-orange'>Projects</p>
				</MDBCol>
				
				<MDBCol>
				<MDBRow className='float-right'>
					<ButtonGroup>
					<Button
						flat='true'
						background='orange' foreground='white'
						borderWidth={2} borderColor='orange' size={-1}
						onClick={this.toggleCreateProject}>
						<Icon name='plus' />
						<span className='color-white'>Create Project</span>
					</Button>
					</ButtonGroup>
				</MDBRow>
				</MDBCol>
			</MDBRow>
		</div>
		<div className="page-filter">
			<MDBRow className='text-left'>
				{hasData ? this.renderSearch() : null}
			</MDBRow>
		</div>
		<MDBContainer fluid className='sub-body-container rectangle-background p-l-md p-r-md'>
			<CreateProjectModal
				modalToggle={this.toggleCreateProject}
				modalOpen={this.state.createProjectModalOpen}
			/>
		{ isLoaded ?
			<MDBRow className='sub-body-container height--full p--md m--0'>
			<MDBCol md='auto' className='p--0 text-right font-size-12'>
				{ hasData &&
				<div>
					{this.handleFilter()}
					<br/>
					{this.handleSort()}
				</div>
				}
			</MDBCol>
			<MDBCol className='p-t--0 p-l--md p-r--0 p-b--md'>
				{ hasData ?
				(searchHasResults ? this.renderProjectView(activeFilter, activeSort) : null )
				:
				<div className='create-view text-center'>
					<p className='font-size-32 font-weight-500 font-family-crimson m-b--sm'>Create your first project</p>
					<Button
					flat='true'
					background='orange' foreground='white'
					borderWidth={2} borderColor='orange'
					onClick={this.toggleCreateProject}>
					<Icon name='plus' />
					<span className='color-white'>Create New Project</span>
					</Button>
				</div>
				}
			</MDBCol>
			</MDBRow> :
			<MDBRow className='sub-body-container height--full'>
			<MDBCol md='auto' lg='12' className='sub-body-container height--full'>
				<div className='loader-container'>
				{ hasData ||
				<div className="loader dark">Loading...</div>
				}
				</div>
			</MDBCol>
			</MDBRow>
		}
		</MDBContainer>
	</MDBContainer>
    );
  }
}

function mapState(state) {
  const { projects, project } = state;
  return { projects, project };
}

const actionCreators = {
 getProjects: projectActions.getProjects
};

const connectedViewProjects = connect(mapState, actionCreators)(Projects);
export { connectedViewProjects as Projects };

