import React, { useState, useEffect, useRef } from 'react'
import DateRangeDropdown from './DateRangeDropdown'
import {KpiControlAdvanceFilter} from "_pages/Influencer/components/Kpi/KpiControlAdvanceFilter";

const KpiControl = () => {

  const [isOpen, setIsOpen] = useState(false);
  const filterRef = useRef();

  function handleOpenFilter() {
    setIsOpen(!isOpen);
  }
  function handleCloseFilter() {
    setIsOpen(false);
  }

  useEffect(() => {
    const filterOutsideClick = (e) => {
        if(isOpen && filterRef.current && !filterRef.current.contains(e.target)) {
            setIsOpen(false);
        }
    };
    document.addEventListener('click', filterOutsideClick);
    return () => {
        document.removeEventListener('click', filterOutsideClick);
    }
  }, [isOpen]);

  return (
    <div className='kpi-control-box-propelrr'>
        <div className='control-title'>
            <p>Influencers</p>
        </div>
        <div className='control-groups'>
            {/* <div className='date-range-picker-box'>
                <DateRangeDropdown />
            </div> */}
            <div className='search-box active'>
                <div className='input-search'>
                    <i className="fas fa-times close-btn"></i>
                    <i className="fas fa-arrow-right ok-btn"></i>
                    <input type='text' placeholder='Search Lead'/>
                </div>
                <span className='active'>
                    Search <i className="fas fa-search"></i>
                </span>
            </div>
            <KpiControlAdvanceFilter/>
            <div className='pagination-box'>
                <span className='influencer-counts'>1-15 of 1000</span>
                <ul>
                    <li>
                        <a><i className='fas fa-chevron-left'></i></a>
                    </li>
                    <li className='active'>
                        <a><i className='fas fa-chevron-right'></i></a>
                    </li>
                </ul>
            </div>
        </div>
    </div>
  )
}

export default KpiControl