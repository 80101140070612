import React from 'react';
import { connect } from "react-redux";
import { formActions } from '_actions';

import { MDBCol, MDBIcon, MDBRow } from 'mdbreact';
import InputForm from '_components/InputForm'

import { FormFieldView } from '_pages/Forms/FormFieldView';
import DeleteConfirmationModal from '_components/Modal/DeleteConfirmationModal'

import addIcon from '_assets/images/plus.png';

class FormGroupView extends React.Component {
	constructor(props) {
		super(props);
		this.state = {
			formGroupData: this.props.formGroupData,
			formIndex: {},
			activeFormIndex: '',
			isExpanded: true,
			dropIcon: 'caret-square-up',
			dropdownDiv: true,
			isGroupSelected: false,
			visibleFormGroups: {},
			groupToDelete: '',
			activeFormGroup: this.props.activeFormGroup,
			activeFormField: this.props.activeFormField
		};

		this.handleActiveFormGroup = this.handleActiveFormGroup.bind(this);
	}

	static getDerivedStateFromProps(nextProps, prevState){
		if(nextProps.formGroupData!==prevState.formGroupData){
			return { formGroupData: nextProps.formGroupData};
		}
		else return null;
	}

	getInputIndex = data => {
		let edit = 'edit';
		this.props.inputIndex(data, edit);
		this.setState({ activeFormIndex: data.formIndex });
	};

	getFormFieldButtons = (value) => {
		this.props.getFormFieldButtons(value);
	};

	deleteInputField = data => {
		this.props.getDeleteInputField(data);
	};

	handleAccordionToggle = (formGroupId) => {
		let isExpanded = this.state.isExpanded;

		this.setState({
			isExpanded: !isExpanded,
			dropIcon: isExpanded ? 'caret-square-down' : 'caret-square-up'
		});
	};

	moveUp = (newIndex, oldIndex) => {
		this.props.moveUp(newIndex, oldIndex)
	};

	moveInputUp = (newIndex, oldIndex, formIndex) => {
		this.props.moveInputUp(newIndex, oldIndex, formIndex)
	};

	getFormGroupButtons = (value) => {
		this.props.getFormGroupButtons(value);
	};

	getEditFormField = (data, formGroupId)  => {
		this.setState({
			fieldData: data,
			sideBar: 'editField'
		});
		this.props.getEditFormField(data, formGroupId);
	};

	/*--------------------------------*/

	handleActiveFormGroup = (activeFormGroup) => {
		this.props.handleActiveFormGroup(activeFormGroup);
	};

	renderFormGroups = (value, index, totalNum) => {
		let activeFormGroup = `formGroup${value.id}`;

		return (
			<MDBRow
				key={index} id={activeFormGroup}
			>
				<MDBCol
					xl='12'
					className='preview__container form-group'
					id='inputFieldsArea'
				>
					<MDBRow>
						{ this.renderFormGroupHeader(value) }
					</MDBRow>
					<MDBRow className={ this.state.isExpanded ? '' : 'display-none'}>
						{ value.hide_group || <hr className='mx-2 form-group__divider'/> }
						<MDBCol xl='12' lg='12' className='p-l--0 p-r--0 m--auto'>
							<div id={`formFields${value.id}`}>
								{ this.renderFormFields(value.field) }
							</div>
						</MDBCol>

					</MDBRow>
				</MDBCol>
			</MDBRow>
		);
	};

	handleActiveFormField = (activeFormField) => {
		this.props.handleActiveFormField(activeFormField);
	};

	renderFormFields = (data) => {
		let hasFormFields = false;
		let totalFormFields = data.length || 0;
		let formGroupData = this.props.formGroupData;

		if(typeof data !== 'undefined'){
			if (totalFormFields > 0){
				hasFormFields = true;
				data = data.sort((a, b) => a.form_order > b.form_order ? 1 : -1);
			}
		}

		return (

			// Lead Edit
			<MDBRow className='form-fields'>
				{ hasFormFields ?
					data.map((value, index) => {
						return (
							<FormFieldView
								key={value.id}
								formGroupData={formGroupData}
								formGroupId={this.props.formGroupId}
								formFieldData={value}
								projectId={this.props.projectId}
								formId={this.props.formId}
								leadId={this.props.leadId}
								leadInfo={this.props.leadInfo}
								formFieldKey={index}
								getEditFormField={this.getEditFormField}
								totalFormFields={totalFormFields}
								handleUpdate={this.props.handleUpdate}
								handleActiveFormGroup={this.handleActiveFormGroup}
								handleActiveFormField={this.handleActiveFormField}
								handleFormFieldReorder={this.props.handleFormFieldReorder}
								activeFormField={this.props.activeFormField}
								getFormFieldButtons={this.getFormFieldButtons}
								handleSelectedInput={this.props.handleSelectedInput}
								selectedInput={this.props.selectedInput}
								dropdownSelect={this.props.dropdownSelect}
								isManageLead={this.props.isManageLead}
								APIDropdownSelect={this.props.APIDropdownSelect}
							/>
						);
					})
					:
					<MDBCol xl='12' lg='12' className={ this.state.isExpanded ? 'px-0 m-t--md m-b--md p-t--sm' : 'display-none'}>
						<p className='text-center'>No form fields.</p>
					</MDBCol>
				}
			</MDBRow>
		);
	};


	handleAddFormGroup = (index) => {
		this.setState({
			isSelectFieldExpanded: true,
			selectedFieldIndex: index
		});
	};

	handleCreateFormGroup = (value, index, totalNum) => {
		//let formGroupData = this.props.formGroupData;
		//this.props.handleActiveFormGroup(`formGroup${formGroupData.id}`);
		this.getFormGroupButtons(value);
		sessionStorage.setItem('groupIndex', index);
		sessionStorage.setItem('groupTotalNum', totalNum);
		//
		// console.log('FormGroup handleCreateFormGroup value', value);
		// console.log('FormGroup handleCreateFormGroup index', index);
		// console.log('FormGroup handleCreateFormGroup totalNum', totalNum);
	};

	// handleAddFormGroup = (index, totalNum) => {
	//
	//   let formData = JSON.parse(sessionStorage.getItem('form'));
	//   let formGroupData = formData.group;
	//
	//   this.props.handleActiveFormGroup('');
	//
	//   if(index <= totalNum){
	//     this.props.handleCreateFormGroupInBetween({
	//       name: 'New Form Group',
	//       layout: '1'
	//     }, formGroupData, index, totalNum);
	//   } else {
	//     this.props.handleCreateFormGroup({
	//       name: 'New Form Group',
	//       layout: '1'
	//     });
	//   }
	//
	//
	// };

	renderAddFormGroup = (value, index, totalNum) => {
		let isSelectFieldExpanded = this.state.isSelectFieldExpanded;
		let selectedFieldIndex = this.state.selectedFieldIndex;
		let selectedInput = this.props.selectedInput;
		//
		// console.log('FormGroup renderAddFormGroup value', value);
		// console.log('FormGroup renderAddFormGroup index', index);
		// console.log('FormGroup renderAddFormGroup totalNum', totalNum);

		return (
			<div className={ isSelectFieldExpanded && (selectedFieldIndex === index) ? (selectedInput ? 'display-hover' : 'display-visible') : 'display-hover'}>
				<p
					id='add'
					className='font-size-12 mb-0 color-orange'
					onClick={(e) => {
						e.stopPropagation();
						this.handleActiveFormGroup('');
						this.handleAddFormGroup(index, totalNum);
						this.handleCreateFormGroup(value, index, totalNum);
						this.props.handleSelectedInput(false);
					}}

				>
					<img alt='' src={addIcon} className='add-icon-width-18 mr-1' />
					Add Form Group
				</p>
			</div>
		);
	};

	renderFormGroupHeader = (value) => {
		return (
			  value.hide_group ||
				<MDBCol xl='12' lg='12' className='preview__header'>
					{ value.name !== '' ?
						<label
							htmlFor={`formFields${value.id}`}
							className={`form-group__label ${value.hide_group && 'color-gray'}`}
						>
							{ value.name }
						</label>
						:
						<InputForm
							type='text'
							id=''
							name=''
							inputClass='font-weight-600'
							hint='Enter Form Group label here...'
						/>
					}
				</MDBCol>
		);
	};

	handleFormGroupReorder = (data) => {
		let originFormGroup = data.originFormGroup;
		let targetFormGroup = data.targetFormGroup;

		const formData = JSON.parse(sessionStorage.getItem('form'));
		let formGroups = formData.group;

		let searchTargetFormGroup = formGroups.filter((o) => { return o.form_order === targetFormGroup.form_order });
		targetFormGroup = searchTargetFormGroup[0];

		if(typeof targetFormGroup !== 'undefined'){
			let originOrder = originFormGroup.form_order;
			let targetOrder = targetFormGroup.form_order;

			targetFormGroup = {
				id: targetFormGroup.id,
				form_order: originOrder
			};

			originFormGroup = {
				id: originFormGroup.id,
				form_order: targetOrder
			};

			let reorderRequestData = {
				reorder: [originFormGroup, targetFormGroup]
			};

			this.props.handleFormGroupReorder(reorderRequestData);
		}
	};

	renderFormGroupReorder = (value, index, totalNum) => {
		return (
			<MDBRow className='form-group__reorder'>
				<MDBIcon
					icon='caret-up'
					className={ index !== 0 || index + 1 === totalNum ? 'icon-move--up hand' : 'icon-move--up disabled color-gray'}
					onClick={() => {
						this.handleFormGroupReorder({
							originFormGroup: value,
							targetFormGroup: {
								form_order: value.form_order - 1
							},
							direction: 'up'
						});
					}}
				/>
				<MDBIcon
					icon='caret-down'
					className={index === value.length - 1 || index + 1 === totalNum ? ' icon-move--down disabled color-gray' : ' icon-move--down hand'}
					onClick={() => {
						this.handleFormGroupReorder({
							originFormGroup: value,
							targetFormGroup: {
								form_order: value.form_order + 1
							},
							direction: 'down'
						});
					}}
				/>
			</MDBRow>
		);
	};

	handleToggle = () =>{
		let { modalOpen } = this.state;
		this.setState({modalOpen: !modalOpen})
	};


	handlePassData = (data) => {
		this.setState({
			groupToDelete: data
		})
	};

	approveModal = (groupToDelete) => {
		const projectId = this.props.projectId;
		const formId = this.props.formId;
		const formGroupData = this.props.formGroupData;
		let formGroupId = formGroupData.id;

		const deleteFormGroupData = {
			name: groupToDelete.name,
			layout: groupToDelete.layout
		};

		this.props.deleteFormGroup(projectId, formId, formGroupId, deleteFormGroupData);

		this.setState({
			formGroupData: {},
			groupToDelete: {}
		});
	};

	renderFormGroupActions = (value, index) => {
		let actionIconClass = this.state.dropdownDiv ? 'px-0 action-icons' : 'hide';

		return (
			<MDBRow className='form-group__actions'>
				<MDBCol className={actionIconClass}>
					<MDBCol className='px-0'>
						<MDBIcon
							far
							icon='trash-alt'
							className='icon-delete color-red opacity-5 hand'
							onClick={(event) => { event.preventDefault(); this.handleToggle(); this.handlePassData(value) }}
						/>
					</MDBCol>
				</MDBCol>
			</MDBRow>
		);
	};

	render() {
		let formGroupData = this.state.formGroupData;
		let formGroupKey = this.props.formGroupKey;
		let totalFormGroups = this.props.totalFormGroups;
		let activeFormGroup = this.props.activeFormGroup;
		let hasData = !!formGroupData;
		let formGroupId = `formGroup${formGroupData.id}`;
		let deleted = this.props.deleted;

		//console.log('formGroupData', formGroupData);

		if(deleted){
			this.props.handleGetForm();
		}

		return (
			<div>
				<div  className={`form-groups ${(formGroupId === activeFormGroup) ? 'active' : ' '}`}>
					{ hasData ? this.renderFormGroups(formGroupData, formGroupKey, totalFormGroups) : null }
				</div>
				<DeleteConfirmationModal
					toggle={this.handleToggle}
					modalOpen={this.state.modalOpen}
					statusToDelete={this.state.groupToDelete}
					onApprove={this.approveModal}
					target={formGroupData.label}
				/>
			</div>
		);
	}
}

function mapState(state) {
	const { creating, forms, deleting, deleted } = state.forms;
	return { creating, forms, deleting, deleted };
}

const actionCreators = {
	deleteFormGroup: formActions.deleteFormGroup
};

const connectedFormGroupView = connect(mapState, actionCreators)(FormGroupView);
export { connectedFormGroupView as FormGroupView };
