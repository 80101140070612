import React, { useState } from 'react'
import Button from '../Button'

const RatesSide = ({ classN, ratesModalAdd, ratesModalEdit, confirmationDelete }) => {


  return (
    <>
      <div className={classN}>
        <div className="inner-title-box">
          <p>Influencer Rates</p>
          <Button text="Add Rate" icon="fa-plus" onClick={ratesModalAdd} />
        </div>
        <div className="notes-list">
          <div className="notes">
            <p>
              1. Branded Artcard <br />
              - 1 Unit: PHP 111,111.11 <br />
              - 5+1 Units: PHP 388,888.85 <br />
              2. User-Generated Content <br />
              - 1 UGC Unit: PHP 188,888.88 <br />
              - 1 UGC With Album: PHP 277,777.77 <br />
              3.1. Native Headlined Video <br />
              - First 5-60 Seconds: PHP 133,333.33 <br />
              - Succeeding 1-60S: PHP 55,555.55 <br />
              3.2. Shared Branded Post <br />
              - 1 Unit: PHP 66,666.66 <br />
              ----------- <br />
              ADD-ON: Exclusivity Fee / Non-compete: <br />
              First Month: PHP 77,777.77 <br />
              Succeeding Month: PHP 55,555.55 <br />
              Annual Package: PHP 600,000.00 <br />
              Packages also available (please see rate card)
            </p>
            <button className="edit-record" onClick={ratesModalEdit}>
              <i className="fas fa-pen"></i>Edit
            </button>
            <button className="delete-record" onClick={confirmationDelete}>
              <i className="fas fa-trash-alt"></i>Delete
            </button>
          </div>
        </div>
      </div>
    </>
  );
}

export default RatesSide