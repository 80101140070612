import React, { Component } from 'react';
import { breadCrumbsBtn } from '../../constants/BreadCrumbs'

class LeadInfoBreadCrumbs extends Component {
  state = {
    breadCrumbsBtn,
    // active: false,
  };

  render() {

    let { isInfoBreadCrumbsActive, onHandleDropdown, activeDropdown } = this.props;
    // btnNotQualifyClass = isInfoBreadCrumbsActive === 'LOST LEAD',
    // btnOpacity = isInfoBreadCrumbsActive === 'LOST LEAD' && 'opacity-70';
    //let dropDownClass = onTest === true ? 'active' : '';

    return (
      
      breadCrumbsBtn.map((value, index) => {
        let btnActiveClass = isInfoBreadCrumbsActive === value.name ? value.newClass : value.defaultClass;
        let dropdownActive = value === activeDropdown ? 'active' : ' ';
        
        
        return (
          
          // <button
          //   type='button'
          //   key={index}
          //   disabled={btnNotQualifyClass}
          //   onClick={(e) => {this.props.handleUpdateLeadStatus(value.name)}}
          //   className={`${btnActiveClass} ${btnOpacity} btn-arrow-right padding-breadcrumb-info box-shadow-none font-weight-600`}>
          //   {value.name}
          // </button>
          
          //  ${ dropDownClass }
          
          <button key={value.name} className={`${dropdownActive} ${ btnActiveClass } $ btn-arrow-right padding-breadcrumb-info box-shadow-none font-weight-600 custom-dropdown`}
            >
              <label onClick={() => onHandleDropdown(value)}> {value.name} <i className="fa fa-chevron-down"></i></label>
              <ul className="dropdown-options">
                  { value.dropdownOption.map(doItem =>
                      <li key={doItem.value} onClick={() => {this.props.handleUpdateLeadStatus(doItem.id)}}>{doItem.name}</li>
                    ) 
                  }
              </ul>
            </button>
        )
      })
    )
    // return (
    //   this.renderBreadCrumbFilter()
    // );
  }
}

export default LeadInfoBreadCrumbs;
