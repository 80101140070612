export const chatConstants = {

    GET_ALL_CHATS_REQUEST: 'GET_ALL_CHATS_REQUEST',
    GET_ALL_CHATS_SUCCESS: 'GET_ALL_CHATS_SUCCESS',
    GET_ALL_CHATS_FAILURE: 'GET_ALL_CHATS_FAILURE',

    UPDATE_CHATS_UNREAD_REQUEST: 'UPDATE_CHATS_UNREAD_REQUEST',
    UPDATE_CHATS_UNREAD_SUCCESS: 'UPDATE_CHATS_UNREAD_SUCCESS',
    UPDATE_CHATS_UNREAD_FAILURE: 'UPDATE_CHATS_UNREAD_FAILURE',

    CHAT_REPLY_REQUEST: 'CHAT_REPLY_REQUEST',
    CHAT_REPLY_SUCCESS: 'CHAT_REPLY_SUCCESS',
    CHAT_REPLY_FAILURE: 'CHAT_REPLY_FAILURE',

    CHAT_NOTIFICATION_REQUEST: 'CHAT_NOTIFICATION_REQUEST',

}