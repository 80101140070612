
import {getLeadFieldValue, getCampaignData, utilitiesService as utility} from '_services/utilities.service'
import React from "react";

const PropelrrServiceColumns = props => {

    const { lead } = props;

    let type = getLeadFieldValue(lead, 65);
    let campaign = getCampaignData(lead);

    let leadResponsible = lead.responsible ?
        utility.decrypt(lead.responsible.first_name) + ' ' + utility.decrypt(lead.responsible.last_name)
        : 'Unassigned';

    let types = type.includes(',') ? type.split(',') : [type];



    return (
        <React.Fragment>
            <td>
                {types.map( (t, index) => {
                    return( <span key={index} className="b-color">{t}</span>);
                })}
            </td>

            <td>
                <span className={lead.origin === 'F7i2g9qp33ya/mzdxWFjnQ==' ? 'url-text-break' : ''}>
                    {lead.origin}
                </span>
            </td>

            <td>
                <span>
                    {campaign.source}
                </span>
            </td>

            <td>
                <span>
                    {campaign.medium}
                </span>
            </td>

            <td>
                <span>
                    {campaign.channel}
                </span>
            </td>

            <td>
                <span>
                    {leadResponsible}
                </span>
            </td>

        </React.Fragment>
    );

}

export default PropelrrServiceColumns;