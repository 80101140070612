import React, { Component } from 'react';
import { Link } from 'react-router-dom';
import { utilitiesService as utility } from '_services';
import { projectActions, formActions, leadActions, alertActions } from '_actions';

import { MDBContainer, MDBCol, MDBRow, MDBIcon } from 'mdbreact';
import { InputSelect, Button, ButtonGroup, Icon } from "blackbox-react";

import AddFieldModal from '_components/Modal/AddFieldModal'
import { FormGroupView } from '_pages/Forms/FormGroupView';

import {connect} from "react-redux";

class AddLead extends Component {
  constructor(props) {
    super(props);

    const projectId = this.props.match.params.id;
    const projectData = JSON.parse(sessionStorage.getItem('project'));
    this.props.getAllProjectStatus(this.props.match.params.id);
    this.props.getAllProjectMembers(this.props.match.params.id);
    const projectForms = projectData.forms;
    const basicInfo = projectForms.find(item => item.is_default) || {};
    const formId = basicInfo.id || 0;
    this.props.getForm(projectId, formId);
    this.formRef = React.createRef();

    this.state = {
      addField: false,
      projectData: projectData,
      selectedStatus: '',
      selectedPriority: '',
      selectedOwner: '',
      selectedResponsible: '',
      formGroupData: {},
      formGroupId: 0,
      fieldData: {},
      leadData: [],
      leadUpdated: false,
      requiredFields: []
    }

  }

  getEditFormGroup = data => {
    this.setState({
      formGroupData: data || {}
    });
  };

  getEditFormField = (data, formGroupId) => {
    this.setState({
      fieldData: data || {},
      formGroupId: formGroupId
    });
  };

  handleUpdate = (event) => {

    let updatedKey = event.target.name;
    let updatedValue = event.target.value;
    let formFieldKeys = JSON.parse(sessionStorage.getItem('formFieldKeys'));
    let leadData = this.state.leadData;

    leadData[updatedKey] = null;


    if(updatedValue) {
      leadData[updatedKey] = {
        key: parseInt(updatedKey),
        value: utility.encrypt(updatedValue)
      }
    }

    this.setState({
      leadData: leadData
    });

  };

  handleCreateLead = () => {

    const projectId = this.props.match.params.id;
    const projectData = JSON.parse(sessionStorage.getItem('project'));
    const projectForms = projectData.forms;
    const basicInfo = projectForms.find(item => item.is_default);
    const formId = basicInfo.id;
    let leadData = this.state.leadData;

    let form =this.props.forms.form.data


    let requiredFields = [];

    form.group.forEach((g) => {
      g.field.forEach( (i) => {
        if(i.required) {
          requiredFields.push(i.id);
        }
      });
    }) ;

    let hasError = false;
    requiredFields.forEach((rf) => {
      if(!leadData[rf]) {
        hasError = true;
      }
    });


      let leadInfo = [];

      if(leadData != null) {
          leadData.forEach( (d) => {
            if(d != null) {
              leadInfo.push(d);
            }
          });
        }

      let projectStatus = this.props.projects.status.data;
      projectStatus = projectStatus.sort((a, b) => a.id > b.id);

      const defaultStatus = projectStatus[0].id;
      const defaultPriority = '2';
      const defaultOwner = 'Unassigned';
      const defaultResponsible = 'Unassigned';

      const selectedStatus = this.state.selectedStatus;
      const selectedPriority = this.state.selectedPriority;
      const selectedOwner = this.state.selectedOwner;
      const selectedResponsible = this.state.selectedResponsible;

      let leadDataToAdd = {
        form_lead: leadInfo,
        project_status_id: selectedStatus || defaultStatus,
        priority: selectedPriority || defaultPriority,
        owner: selectedOwner || defaultOwner,
        responsible: selectedResponsible || defaultResponsible
      };

      this.props.createLead(projectId, formId, leadDataToAdd, hasError);



  };

  handleMapInputs = (data) => {

    return data.map((value, index) => {
      return (
        <div key={index} className='w-48'>
          <p className='font-size-16 mb-0'>{value.label}<span className={value.required ? 'color-red' : 'invisible'}>*</span></p>
          <input
            className='border-gray mb-0 br-5 padding-3 px-2 font-size-13'
            placeholder={value.placeholder}
          />
        </div>
      )
    })
  };

  handleModal = () => {
    return this.setState({ addField: !this.state.addField })
  };

  renderLeadPhoto = () => {
    return (
      <div className='box-right-rectangle size-100 br-75 flex-center mx-auto mb-4'>
        <div className='text-center'>
          {/*<MDBIcon icon='camera' className='font-size-25'/>*/}
          <MDBIcon icon='paper-plane' className='font-size-30 color-orange'/>
          {/*<p className='mb-0 font-size-10'>Add photo</p>*/}
        </div>
      </div>
    );
  };

  renderProjectStatus = () => {
    let projectStatus = this.props.projects.status.data;
    projectStatus = projectStatus.sort((a, b) => a.id > b.id);

    let hasOptions = projectStatus.length > 0;
    let selectOptions = [];

    if(hasOptions){
      projectStatus.map(
        (value, index) => {
          selectOptions.push({
            id: value.id,
            value: value.id,
            label: value.name
          });
          return selectOptions;
        })
    }

    return (
      <div>
        <label htmlFor='selectStatus' className='mb-0 font-size-14 font-weight-bold'>Status</label>
        <div className='width-inherit background-color-white'>
          { hasOptions &&
            <InputSelect
              name='selectStatus'
              defaultValue={selectOptions[0].value}
              onChange={(v) => this.setState({ selectedStatus: v })}
              options={selectOptions}
            />
          }
        </div>
      </div>
    );
  };

  renderLeadPriority = () => {
    let selectOptions = [
      {
        id: 1,
        value: '1',
        label: 'Low'
      },
      {
        id: 2,
        value: '2',
        label: 'Medium'
      },
      {
        id: 3,
        value: '3',
        label: 'High'
      },
    ];
    return (
      <div>
        <label htmlFor='selectPriority' className='mb-0 font-size-14 font-weight-bold'>Priority</label>
        <div className='width-inherit background-color-white'>
          <InputSelect
            name='selectedPriority'
            defaultValue={selectOptions[1].value}
            onChange={(v) => this.setState({ selectedPriority: v })}
            options={selectOptions}
          />
        </div>
      </div>
    );
  };

  handleGetProjectOwnerAndMembers = () => {
    let projectData = this.state.projectData;
    let projectOwner = projectData.owner;
    let projectMembers = this.props.projects.members.data;

    projectMembers = projectMembers.sort((a, b) => a.member_id > b.member_id);

    let hasMembers = projectMembers.length > 0;
    let selectOptions = [];

    let projectOwnerName = utility.decrypt(projectOwner.account.first_name) + ' ' + utility.decrypt(projectOwner.account.last_name);

    // Add Unassigned
    selectOptions.push({
      id: 0,
      value: 'unassigned',
      label: 'Unassigned'
    });

    // Add Project Owner
    selectOptions.push({
      id: projectOwner.id,
      value: projectOwner.account.email,
      label: projectOwnerName
    });

    // Add Project Members
    if(hasMembers){
      projectMembers.map(
        (value, index) => {
          let projectMemberName = utility.decrypt(value.account.first_name) + ' ' + utility.decrypt(value.account.last_name);
          selectOptions.push({
            id: value.id,
            value: value.account.email,
            label: projectMemberName
          });
          return selectOptions;
        })
    }

    return selectOptions;
  };

  renderLeadOwner = () => {
    let selectOptions = this.handleGetProjectOwnerAndMembers();
    let hasOptions = selectOptions.length > 0;

    return (
      <div>
        <label htmlFor='selectOwner' className='mb-0 font-size-14 font-weight-bold'>Owner</label>
        <div className='width-inherit background-color-white'>
          { hasOptions &&
            <InputSelect
              name='selectedOwner'
              defaultValue={selectOptions[0].value}
              onChange={(v) => this.setState({ selectedOwner: v })}
              options={selectOptions}
            />
          }
        </div>
      </div>
    );
  };

  renderLeadResponsible = () => {
    let selectOptions = this.handleGetProjectOwnerAndMembers();
    let hasOptions = selectOptions.length > 0;

    return (
      <div>
        <label htmlFor='selectResponsible' className='mb-0 font-size-14 font-weight-bold'>Responsible</label>
        <div className='width-inherit background-color-white'>
          { hasOptions &&
            <InputSelect
              name='selectedResponsible'
              defaultValue={selectOptions[0].value}
              onChange={(v) => this.setState({ selectedResponsible: v })}
              options={selectOptions}
            />
          }
        </div>
      </div>
    );
  };

  renderSideBar = () => {
    return (
      <MDBCol xl='2' lg='2' className='p--md m--0'>
        <div className="p--md">
          { this.renderLeadPhoto() }
          { this.renderProjectStatus() }
          { this.renderLeadPriority() }
          <hr className='width-inherit' />
          { this.renderLeadOwner() }
          { this.renderLeadResponsible() }
        </div>
      </MDBCol>
    );
  };

  renderFormGroups = (data) => {
    let totalFormGroups = data.length || 0;
    let hasFormGroups = totalFormGroups > 0;
    //let formGroupId = this.state.formGroupId;

    if(hasFormGroups){
      data = data.sort((a, b) => a.form_order > b.form_order);
    }

    return (
        <form id='manage-form' ref={ this.formRef }>
      <div
        className='preview__stage'
        onClick={(e) => {
          e.stopPropagation();
          this.setState({
            activeFormGroup: '',
            activeFormField: '',
            activeSideBar: 'select'
          });
        }}
      >
        { hasFormGroups &&
          data.map((value, index) => {
            return (
              <FormGroupView
                key={index}
                formGroupData={value}
                formGroupId={value.id}
                formGroupKey={index}
                getEditFormGroup={this.getEditFormGroup}
                getEditFormField={this.getEditFormField}
                getFormGroupButtons={this.getFormGroupButtons}
                getFormFieldButtons={this.getFormFieldButtons}
                totalFormGroups={totalFormGroups}
                handleUpdate={this.handleUpdate}
                handleActiveFormGroup={this.handleActiveFormGroup}
                handleActiveFormField={this.handleActiveFormField}
                activeFormGroup={this.state.activeFormGroup}
                handleCreateFormGroup={this.handleCreateFormGroup}
                handleCreateFormGroupInBetween={this.handleCreateFormGroupInBetween}
                handleFormGroupReorder={this.handleFormGroupReorder}
                handleFormFieldReorder={this.handleFormFieldReorder}
                activeFormField={this.state.activeFormField}
                projectId={sessionStorage.getItem('projectId')}
                formId={this.props.match.params.id}
                dataToAppend={this.dataAppend}
                handleGetForm={this.handleGetForm}
                handleSelectedInput={this.handleSelectedInput}
                selectedInput={this.state.selectedInput}
              />
            );
          })
        }
      </div>
        </form>
    );
  };

  handleBasicInfo = () => {
    let form = this.props.forms.form.data;
    return this.renderFormGroups(form.group);
  };

  renderBasicInformation = () => {
    return (
      <MDBCol xl='10' lg='10' className='box-right-rectangle p--0 p-t--sm p-b--sm m--0 height--auto'>
        <div className="basic-info p-t--0">
          <h5 className='font-size-20'>
            <MDBIcon far icon='address-card' /> &nbsp; BASIC INFORMATION
          </h5>
          <div className='field-container m-t--sm p--sm border-radius-5'>
            { this.handleBasicInfo() }
            <span className='font-size-10 p-l--md'>Please fill out all fields.</span>
          </div>
        </div>

        {/*<MDBBtn*/}
        {/*  flat*/}
        {/*  className='w-100 text-transform-none dotted add-field-button add-field-background m-0 mb-1 color-orange shadow-none button-presets'*/}
        {/*  onClick={*/}
        {/*    this.handleModal*/}
        {/*  }*/}
        {/*>*/}
        {/*  <img alt='' src={addIcon} className='add-icon-width-18 mr-2 mt-less-3' />Add Field*/}
        {/*</MDBBtn>*/}
        
        <MDBCol xl='12' lg='12'>
        {/* <div className="float-right m-t--md" style={{ position: 'relative', zIndex: '9999'}}>
          <Button
            flat='true'
            background='orange' foreground='white'
            borderWidth={2} borderColor='orange' size={-2}
            onClick={(e) => {
              this.handleCreateLead();
            }}>
            <span className='color-white font-weight-bold'>Save New Lead</span>
          </Button>
          </div> */}
          </MDBCol>

      </MDBCol>
    );
  };

  handleCustomInputs = (data) => {
    return data.map((value, index) => {
      return (
        <div key={index} className='w-48'>
          <p className='font-size-16 mb-0'>{value.label}<span className='invisible'>*</span></p>
          <input
            className='border-gray mb-0 br-5 padding-3 px-2 font-size-13'
            placeholder={value.placeholder}
          />
        </div>
      )
    })
  };

  handleCustomFields = () => {
    let data = this.state.dataToMap;
    return data.map((value, index) => {
      let inputs = value.fields.input;
      return (
        <div key={index} className='row mb-3 justify-content-between px-3'>
          {this.handleCustomInputs(inputs)}
        </div>
      )
    })
  };

  renderCustomFields = () => {
    let projectId = this.props.match.params.id;
    return (
      <React.Fragment>
      <MDBCol xl='2' lg='2' className='p--0 p-t--sm p-b--sm m--0 pull-right'></MDBCol>
      <MDBCol xl='10' lg='10' className='p--0 p-t--sm p-b--sm m--0 pull-right'>
        <div className="custom-fields p-t--0 m-t--0">
          {/*<h5 className='font-size-20'>*/}
          {/*  <MDBIcon icon='i-cursor' /> &nbsp; CUSTOM FIELDS*/}
          {/*</h5>*/}
          <div className="p--md">
            {/*{ this.handleCustomFields() }*/}
            <div className='create-view pos-relative text-center'>
              <p className='font-size-25 font-weight-500 font-family-crimson m-b--sm'>
                Want to Add More Fields?
              </p>
              <p className='p-l--lg p-r--lg'>
                You can add more fields if you need to record more lead information.
              </p>
              <Link to={`/project/${projectId}/leads/manage-fields`} className=''>
                <Button
                  flat='true'
                  background='orange' foreground='white'
                  borderWidth={2} borderColor='orange'
                  size={-2}
                >
                  <MDBIcon icon='cog' className='mr-2'/>
                  <span className='color-white'>Manage Fields</span>
                </Button>
              </Link>
            </div>
          </div>
        </div>

        {/*<p className='pt-4 font-size-20'>*/}
        {/*  <Link to='/manage_field'>*/}
        {/*    <span className='float-right font-size-14 font-weight-600 color-blue'>Manage Fields <span>></span></span>*/}
        {/*  </Link>*/}
        {/*</p>*/}

        {/*<MDBBtn*/}
        {/*  flat*/}
        {/*  className='w-100 text-transform-none dotted add-field-button add-field-background m-0 mb-1 color-orange shadow-none button-presets'*/}
        {/*  onClick={*/}
        {/*    this.handleModal*/}
        {/*  }*/}
        {/*>*/}
        {/*  <img alt='' src={addIcon} className='add-icon-width-18 mr-2 mt-less-3' />Add Field*/}
        {/*</MDBBtn>*/}
        </MDBCol>
        </React.Fragment>
    );
  };

  render() {
    let projectId = this.props.match.params.id;
    const { membersLoaded, statusLoaded, formLoaded } = this.props;

    if(formLoaded){
      let formGroups = this.props.forms.form.data.group;
      let formFields = [];

      formGroups.map(group => (
        group.field.map(field => formFields.push(field.id))
      ));

      sessionStorage.setItem('formFieldKeys', JSON.stringify(formFields));
    }

    return (
		<MDBContainer fluid className='p--0'>
			<div className='page-title-ctas p-l-md p-r-md'>
				<MDBRow className='m--0 align-items-center'>
					<MDBCol md='auto' className='p--0 m--0'>
						<p className='page-title font-family-kanit font-size-30 line-height-7 color-orange'>Add New Lead</p>
					</MDBCol>
					<MDBCol>
						<MDBRow className='float-right'>
							<ButtonGroup>
							<Link to={`/project/${projectId}/leads`}>
								<Button className='bordered-btn'>
                <i className="fas fa-chevron-left"></i>
								Back to All Leads
								</Button>
							</Link>
							<Button
                className='background-btn'
								onClick={(e) => {
								this.handleCreateLead();
								}}>
                
								Save New Lead
							</Button>
							</ButtonGroup>
						</MDBRow>
					</MDBCol>
				</MDBRow>
			</div>
			<MDBContainer fluid className={`${ (statusLoaded && membersLoaded && formLoaded) || ''} p--0 main-body-container container-fluid false p--sm sub-body-container rectangle-background`}>
				<AddFieldModal
					modalToggle={this.handleModal}
					modalOpen={this.state.addField}
				/>
				{ (statusLoaded && membersLoaded && formLoaded) ?
				<MDBRow className='p--0 m--0'>
					<MDBCol className='background-rectangle  height--auto'>
						<MDBRow>
							{ (statusLoaded && membersLoaded) && this.renderSideBar() }
							{ formLoaded && this.renderBasicInformation() }
							{ this.renderCustomFields() }
						</MDBRow>
					</MDBCol>
				</MDBRow> :
				<MDBRow className='sub-body-container height--full p--0 m--0'>
					<MDBCol md='auto' lg='12' className='sub-body-container height--full'>
						<div className='loader-container'>
							<div className="loader dark">Loading...</div>
						</div>
					</MDBCol>
				</MDBRow>
				}
			</MDBContainer>
    	</MDBContainer>
    );
  }
}
//export default AddLead;

function mapState(state) {
  const { projects, project, forms } = state;
  const { loading, projectLoaded, members, activityLogLoaded, membersLoaded, statusLoaded } = state.projects;
  const { formLoaded } = state.forms;
  return { projects, project, members, loading, projectLoaded, activityLogLoaded, statusLoaded, forms, membersLoaded, formLoaded };
}

const actionCreators = {
  getProject: projectActions.getProject,
  getAllProjectMembers: projectActions.getAllProjectMembers,
  getActivityLog: projectActions.getActivityLog,
  getAllProjectStatus: projectActions.getAllProjectStatus,
  getForm: formActions.getForm,
  createLead: leadActions.createLead
};

const connectedViewAddLead = connect(mapState, actionCreators)(AddLead);
export { connectedViewAddLead as AddLead };
