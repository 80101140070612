export const inputs = [
    { id: 'textInput', icon: 'text-width', buttonName: 'Text Input' },
    { id: 'multipleTextInput', icon: 'text-height', buttonName: 'Multiline Text Input' },
    { id: 'singleChoice', icon: 'dot-circle', buttonName: 'Single Choice' },
    { id: 'multipleChoice', icon: 'check-square', buttonName: 'Multiple Choice' },
    { id: 'dropdownSelection', icon: 'chevron-circle-down', buttonName: 'Dropdown Selection' },
    { id: 'switch', icon: 'toggle-on', buttonName: 'Switch' },
    { id: 'slider', icon: 'sliders-h', buttonName: 'Slider Range' },
    { id: 'dateAndTime', icon: 'calendar-alt', buttonName: 'Date and Time' },
    { id: 'apiDropdownSelection', icon: 'chevron-circle-down', buttonName: 'API Dropdown Selection' }
]