import React, { Component } from 'react';
import { MDBContainer, MDBCol, MDBRow, MDBBtn, MDBIcon } from 'mdbreact';
import { formActions } from '_actions';
import Input from '_components/InputForm'
import { Link } from 'react-router-dom'
import Slider from '_pages/Forms/FormBuilder/components/Slider';
import {Button, ButtonGroup, Icon} from "blackbox-react";
import { utilitiesService as utility } from '_services';
import { CopyToClipboard } from 'react-copy-to-clipboard';
import {connect} from "react-redux";

class FormIntegration extends Component {
  constructor(props) {
    super(props);

    let projectId = JSON.parse(sessionStorage.getItem('projectId'));
    let form = JSON.parse(sessionStorage.getItem('form'));
    let formId = form.form_id;

    const formIntegrationData = this.props.getFormIntegration(projectId, formId);
    const formAccessKeyData = this.props.getFormAccessKey(projectId, formId);

    this.state = {
      formIntegration: formIntegrationData || {},
      formAccessKey: formAccessKeyData || {},

      verificationError: false,
      isVerified: false,
      isPublished: false,

      isOpen: false,
      isDomainValid: false,
      active: 'Form Builder',
      dropIcon: 'caret-square-up',

      copiedDiv: false,
      copiedScript: false,
      tbDomain: '',
      tbBody: '<div id="form-controller"></div>'
    };

    this.handleChange = this.handleChange.bind(this);
    this.handleGetFormIntegrationData = this.handleGetFormIntegrationData.bind(this);
  }

  componentDidMount() {
    sessionStorage.removeItem('isActiveModule');
    sessionStorage.setItem('isActiveModule', 'forms');
  }


  getFormData = () => {
    let formData = JSON.parse(sessionStorage.getItem('form'));
    return formData;
  };

  getFormIntegrationData = () => {
    let formIntegrationData = JSON.parse(sessionStorage.getItem('formIntegration'));
    return formIntegrationData || {};
  };

  handleChange = event => {
    this.setState({ [event.target.name]: event.target.value });
  };

  handleGetFormIntegrationData = () => {
    let projectId = JSON.parse(sessionStorage.getItem('projectId'));
    let form = JSON.parse(sessionStorage.getItem('form'));
    let formId = form.form_id;

    this.props.getFormIntegration(projectId, formId);

    return JSON.parse(sessionStorage.getItem('formIntegration'));
  };

  //this function is used to validate domain name
  handleValidateDomain = (e) => {
    let projectId = JSON.parse(sessionStorage.getItem('projectId'));
    let form = JSON.parse(sessionStorage.getItem('form'));
    let formId = form.form_id;
    let url = this.state.tbDomain;
    let formIntegration = this.state.formIntegration;

    // let loadedIntegration = this.props.loadedIntegration;
    // let loadedAccess = this.props.loadedAccess;

    const formAccessKey = JSON.parse(sessionStorage.getItem('formAccessKey'));

    if(formAccessKey){
      let accessKey = formAccessKey.accessKey;
      utility.scrape(url, accessKey);
      let regexp = /^((http|https):\/\/)([a-zA-Z0-9-]+\.){0,5}[a-zA-Z0-9-][a-zA-Z0-9-]+\.[a-zA-Z]{2,64}?$/gi;

      let isValidUrl = regexp.test(url);

      if(isValidUrl && formIntegration){
        let verifyEmbedCode = sessionStorage.getItem('verifyEmbedCode');

        if(verifyEmbedCode){
          this.props.updateFormIntegration(projectId, formId, {
            website_url: url,
            verified: true,
            published: formIntegration.published
          });

          this.setState({
            isVerified: verifyEmbedCode,
            verificationError: false
          }, () =>{
            sessionStorage.removeItem('verifyEmbedCode');
            verifyEmbedCode = sessionStorage.getItem('verifyEmbedCode');
          });
        } else{
          this.setState({
            verificationError: true
          });
        }
      } else{
        this.setState({
          verificationError: true,
          isVerified: false
        });
      }
    }
  };

  handlePublishForm = () => {
    let projectId = JSON.parse(sessionStorage.getItem('projectId'));
    let form = JSON.parse(sessionStorage.getItem('form'));
    let formId = form.form_id;
    const formIntegration = JSON.parse(sessionStorage.getItem('formIntegration'));

    this.props.updateFormIntegration(projectId, formId, {
      website_url: formIntegration.websiteURL,
      verified: formIntegration.verified,
      published: true
    });
  };

  handleUnpublishForm = () => {
    let projectId = JSON.parse(sessionStorage.getItem('projectId'));
    let form = JSON.parse(sessionStorage.getItem('form'));
    let formId = form.form_id;
    const formIntegration = JSON.parse(sessionStorage.getItem('formIntegration'));

    this.props.updateFormIntegration(projectId, formId, {
      website_url: formIntegration.websiteURL,
      verified: formIntegration.verified,
      published: false
    });
  };

  render() {
    let projectId = JSON.parse(sessionStorage.getItem('projectId'));
    let form = JSON.parse(sessionStorage.getItem('form'));
    let formId = form.form_id;
    let tbEndBody = '';

    let isVerified = false;
    let isPublished = false;

    let { formData } = this.props;
    let { formIntegration } = this.state.formIntegration;
    let { loadedIntegration } = this.props;
    let { loadedAccess } = this.props;

    let hasFormData = false;

    if(typeof formData !== 'undefined'){
      hasFormData = true;
    } else{
      formData = this.getFormData();
      hasFormData = true;
    }

    if(loadedIntegration){
      formIntegration = JSON.parse(sessionStorage.getItem('formIntegration'));
      isVerified = (formIntegration.verified === null) ? false : formIntegration.verified;
      isPublished = (formIntegration.published === null) ? false : formIntegration.published;
    }

    if(loadedAccess){
      const formAccessKey = JSON.parse(sessionStorage.getItem('formAccessKey'));
      let accessKey = formAccessKey.accessKey;
      tbEndBody = `<script type="text/javascript" src="https://propelrrlms2019.s3-ap-southeast-1.amazonaws.com/lms.js"></script>
<script type="text/javascript">
  var apiKey = '${encodeURIComponent(accessKey)}';
  var options = { 'target': "form-controller", 'key': apiKey };
  var lms = new forms(options);
</script>`
    }

    let hasVerificationError = this.state.verificationError;

    let { isDomainValid } = this.state,
      validDomain = isVerified ? 'visible' : 'invisible',
      percenttext = isVerified ? '100% complete' : '90% complete',
      percentValue = isVerified ? '100%' : '90%',
      iconDone = isPublished ? 'star' : 'code',
      textLabeldone = '';

      if(isVerified){
        textLabeldone = <p>
          <b>Embed Code Verified!</b> <br/> You have successfully integrated this form to your website. You may proceed to <b>Publish the form</b> to start accepting submissions.
        </p>;
      } else{
        textLabeldone = <p>
          <b>Almost Done...</b> <br/> Embed the form to your web page <br/> and <b>Verify the Code</b> to continue.
        </p>;
      }

      if(isPublished){
        textLabeldone = <p>
          <b>Your work is done!</b> <br/> You've successfully published <br/>{ hasFormData ? <b>{formData.info.name}</b> : 'your form' } <br/> and is now accepting submissions.
        </p>;
      }

    return (
      <MDBContainer fluid className='pt-3 pr-3'>
        <MDBRow className='ml-2 mr-4'>
          <MDBCol lg='3' xl='2' md='4'>
            <MDBCol className='p-0 mt-4'>
              <p className='font-size-12 font-weight-600 font-color-gray mb-0'>LEAD MANAGEMENT</p>
              <p className='font-family-crimson font-size-30 line-height-7 color-orange line-height-6'>Forms</p>
            </MDBCol>
            <MDBCol xl='11' lg='9' className='text-center font-size-14 pl-0 mt-5'>
              <Slider activeFormView='integration' stageNum='4' triggerFinish={isDomainValid} isVerified={isVerified}/>
            </MDBCol>
            { (loadedIntegration || loadedAccess)  &&
              <ButtonGroup>
                <Link to={`/project/${projectId}/forms`} className='button-end'>
                  <Button
                    flat='true'
                    background='transparent' foreground='black'
                    borderWidth={2} borderColor='dark' size={-1}
                    styles={{ 'width': '100%', 'opacity': '0.8' }}>
                    <Icon color='black' name='chevron' rotate={180} />
                    <span className='color-black font-weight-bold'>View All Forms</span>
                  </Button>
                </Link>
              </ButtonGroup>
            }
          </MDBCol>
            <MDBCol lg='9' xl='10' md='8' className='px-0'>
              <MDBRow className='d-flex pb-2'>
                <MDBCol lg='7' className='my-auto'>
                  <MDBRow className='pl-3'>
                    <p className='font-family-crimson font-size-22 letter-spacing-1 my-auto pr-3'>{ hasFormData ? formData.info.name : null }</p>
                    <div className='progress col-lg-3 col-xl-3 my-auto'>
                      <span className='progress-value text-white font-weight-600 my-auto'>{percenttext}</span>
                      <div className='progress-bar progress-background' style={{ width: `${percentValue}` }} />
                    </div>
                  </MDBRow>
                </MDBCol>
                <MDBCol lg='5' className='text-right my-auto'>
                  <ButtonGroup>
                    <Link to={`/project/${projectId}/form/${formId}/success-message`}>
                      <Button
                        flat='true'
                        background='transparent' foreground='black'
                        borderWidth={2} borderColor='dark' size={-2}
                        styles={{ 'opacity': '0.5' }}>
                        <Icon color='black' name='chevron' rotate={180} />
                        <span className='color-black font-weight-bold'>Back</span>
                      </Button>
                    </Link>
                    { isVerified ?
                        isPublished ?
                          <Button
                            flat='true'
                            background='red' foreground='white'
                            borderWidth={2} borderColor='red' size={-2}
                            styles={{ 'min-width': '150px'}}
                            onClick={(e) => {this.handleUnpublishForm(e)}}
                          >
                            <span className='color-white font-weight-bold'>Unpublish Form</span>
                          </Button>
                          :
                          <Button
                            flat='true'
                            background='orange' foreground='white'
                            borderWidth={2} borderColor='orange' size={-2}
                            styles={{ 'min-width': '150px'}}
                            onClick={(e) => {this.handlePublishForm(e)}}
                          >
                            <span className='color-white font-weight-bold'>Publish Form</span>
                          </Button>
                      :
                      <Button
                        flat='true'
                        disabled
                        background='disabled'
                        borderWidth={2} size={-2}
                        styles={{ 'background': 'rgb(227, 232, 233)', 'border-color': 'rgb(227, 232, 233)', 'min-width': '150px'}}
                        onClick={(e) => {this.handlePublishForm(e)}}>
                        <span className='color-gray font-weight-bold'>Publish Form</span>
                      </Button>
                    }
                  </ButtonGroup>
                </MDBCol>
              </MDBRow>
              <MDBCol className='main-body-container background-rectangle mt-2 px-0 mb-3 height-77'>
                <MDBCol className='height--full'>
                  { loadedIntegration && loadedAccess ?
                    <MDBRow className='pl-0'>
                      <MDBCol lg='7' xl='7' className='py-2'>
                        <p className='mt-3 mb-0 mx-4 font-size-14 font-weight-600'>WEBSITE INTEGRATION</p>
                        <hr className='my-3' />
                        <MDBRow>
                          <MDBCol className='pr-4 pl-4 ml-3'>
                            <MDBRow>
                              <MDBCol>
                                <p className='mb-0 font-weight-bold line-height-15 font-size-15'>Embed</p>
                                <label className='mb-1 font-size-12 text-black opacity-60'>{`Insert this code inside the <body> tag of your index page`}</label>
                                <div className='position-relative pointer-cursor'>
                                  <CopyToClipboard
                                    text={this.state.tbBody}
                                    onCopy={(e) => this.setState({copiedDiv: true, copiedScript: false})}>
                                    <div>
                                      <Input
                                        type='text'
                                        name='tbBody'
                                        value={this.state.tbBody}
                                        text={this.state.tbBody}
                                        inputClass='font-weight-bold'
                                      />
                                      <span className='copy-code font-italic pointer-cursor top-6 right-8 background-color-faded-light-blue border-radius-5 color-light-blue font-size-11 position-absolute px-3 py-1'>
                                    {this.state.copiedDiv ? 'Copied!' : 'Copy Code'}
                                  </span>
                                    </div>
                                  </CopyToClipboard>
                                </div>
                              </MDBCol>
                            </MDBRow>
                            <MDBRow>
                              <MDBCol className='py-2'>
                                <label className='mb-1 font-size-12 text-black opacity-60'>{`Insert this code before the closing </body> tag of your index page`}</label>
                                { (loadedIntegration || loadedAccess) &&
                                <div className='position-relative pointer-cursor'>
                                  <CopyToClipboard
                                    text={tbEndBody}
                                    onCopy={(e) => this.setState({copiedScript: true, copiedDiv: false})}>
                                    <div>
                                  <textarea
                                    name='tbEndBody'
                                    id='textarea-description'
                                    className='form-control font-size-12 color-none font-weight-600'
                                    rows='7'
                                    defaultValue={tbEndBody}
                                  />
                                      <span
                                        className='copy-code font-italic pointer-cursor bottom-6 right-8 background-color-faded-light-blue border-radius-5 color-light-blue font-size-11 position-absolute px-3 py-1'>
                                  {this.state.copiedScript ? 'Copied!' : 'Copy Code'}
                                </span>
                                    </div>
                                  </CopyToClipboard>
                                </div>
                                }
                              </MDBCol>
                            </MDBRow>
                            <MDBRow>
                              {(loadedIntegration || loadedAccess) &&
                              <MDBCol className='pb-2'>
                                <label className='mb-1 font-size-12 text-black opacity-60'>After adding the code above to your website, add your website’s URL below</label>
                                <MDBRow className='d-flex'>
                                  <div className='col m-auto'>
                                    <input
                                      onChange={this.handleChange.bind(this)}
                                      type='text'
                                      name='tbDomain'
                                      autoFocus={true}
                                      placeholder='http://'
                                      defaultValue={ formIntegration && (formIntegration.websiteURL || 'http://')}
                                    />
                                  </div>
                                  <div md='3' className='text-right pr-2'>
                                    <MDBBtn
                                      flat className='shadow-none text-white background-color-orange py-2 px-3 br-3' onClick={(e) => { this.handleValidateDomain(e) }}>VERIFY CODE</MDBBtn>
                                  </div>
                                </MDBRow>
                              </MDBCol>
                              }
                            </MDBRow>
                            { hasVerificationError ?
                              <MDBRow>
                                <MDBCol className='m-l--md m-t--md'>
                                  <p className='color-red mb-0 font-weight-bold line-height-15 font-size-15'> <MDBIcon icon='times' /> Verification failed</p>
                                  <label className='mb-1 font-size-12 text-black opacity-60'>Embed code not found on the domain. <br/> Please check your URL or the embed code placement on your website.</label>
                                </MDBCol>
                              </MDBRow>
                              :
                              <MDBRow className={`${validDomain}`}>
                                <MDBCol className='m-l--md m-t--md'>
                                  <p className='font-color-green mb-0 font-weight-bold font-color-green line-height-15 font-size-15'> <MDBIcon icon='check' /> Embed integration verified</p>
                                  <label className='mb-1 font-size-12 text-black opacity-60'>You have successfully integrated this form to your website. <br/>You may proceed to <b>Publish the form</b> to start accepting submissions.</label>
                                </MDBCol>
                              </MDBRow>
                            }

                          </MDBCol>
                        </MDBRow>
                      </MDBCol>
                      <MDBCol lg='5' xl='5' className='box-right-rectangle px-0 d-flex'>
                        <div className='generic-message text-center'>
                          {textLabeldone}
                          <MDBIcon icon={iconDone} className='icon--lg' />
                          <MDBIcon icon={iconDone} className='icon--md' />
                          <MDBIcon icon={iconDone} className='icon--sm' />
                        </div>
                      </MDBCol>
                    </MDBRow>
                  :
                    <MDBRow className='position-center'>
                      <div className='text-center position-center m--auto'>
                        <div className="loader dark">Loading...</div>
                      </div>
                    </MDBRow>
                  }
                </MDBCol>
              </MDBCol>
            </MDBCol>
        </MDBRow>
      </MDBContainer>
    );
  }
}

function mapState(state) {
  const { forms } = state;
  const { loadedIntegration, loadedAccess } = state.forms;
  return { forms, loadedIntegration, loadedAccess };
}

const actionCreators = {
  getFormIntegration: formActions.getFormIntegration,
  updateFormIntegration: formActions.updateFormIntegration,
  getFormAccessKey: formActions.getFormAccessKey
};

const connectedFormIntegration = connect(mapState, actionCreators)(FormIntegration);
export { connectedFormIntegration as FormIntegration};
