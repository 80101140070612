import React, { useState, useEffect, useRef } from 'react'

const CategoryFilter = ({categories, categoryIds, categoryList, onCategoryChanged}) => {

  const [isOpen, setIsOpen] = useState(false);

  const [selected, setSelected] = useState(categoryIds);

  useEffect(() => {
    setSelected(categoryIds);
  }, [categoryIds]);

  const filterRef = useRef();

  function handleOpenFilter() {
    setIsOpen(!isOpen);
  }

  function handleCloseFilter() {
    setIsOpen(false);
  }

  useEffect(() => {
    const filterOutsideClick = (e) => {
      if (
          isOpen &&
          filterRef.current &&
          !filterRef.current.contains(e.target)
      ) {
        setIsOpen(false);
      }
    };
    document.addEventListener("click", filterOutsideClick);
    return () => {
      document.removeEventListener("click", filterOutsideClick);
    };
  }, [isOpen]);

  const handleChange = (e) => {
    const { value, checked } = e.target;

    if (checked) {
      if(!selected.includes(value)){

      }
      setSelected([...selected, parseInt(value)]);
    }

    else {
      setSelected(selected.filter((s) => s !== parseInt(value)));
    }

  }

  const handleUpdate = () => {
    onCategoryChanged(selected);
    setIsOpen(false);
  }

  return (
      <div className="filter-box filter-category" ref={filterRef}>
        <div className="filter-btn category-btn">
        <span className="category-text" onClick={() => handleOpenFilter()}>
          {categories}
        </span>
        <div className='hover-category-text'>
          <span>{categories}</span>  
        </div>
        </div>
        <div className={isOpen ? "filter-dropdown active" : "filter-dropdown"}>
          <div className="filter-title">
            <p>Categories</p>
            <i className="icon-lms icon-close" onClick={() => handleCloseFilter()}></i>
          </div>
          <div className="filter-list category-list">
            <div className="filter-group">
              {categoryList.map((cat, index) => {
                return(<div className="checkbox-group" key={index}>
                  <input checked={selected.includes(cat.id)} type="checkbox" name={cat.code} id={cat.code} value={cat.id} onChange={handleChange}/>
                  <label htmlFor={cat.code}>{cat.name}</label>
                </div>);
              })}
            </div>
          </div>
          <div className="filter-btn-group">
                <span className="background-btn btn-apply" onClick={(()=> handleUpdate())} >Update</span>
          </div>
        </div>
      </div>
  );
}

export default CategoryFilter