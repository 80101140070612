import React from 'react'
import { Link } from 'react-router-dom'

const Breadcrumbs = () => {
  return (
    <div className='breadcrumbs'>
        <Link to='/' className='back'>
            <i className="fas fa-chevron-left"></i>
        </Link>
        <Link to='/' className='link'>
            Projects<i>/</i>
        </Link>
        <Link to='/' className='link'>
            Propelrr Influencer<i>/</i>
        </Link>

        <span>Leads</span>
    </div>
  )
}

export default Breadcrumbs