import React from 'react'

const SideInfoButtonLoader = () => {
  return (
    <div className={`skeleton-loader skeleton-side-info-btns`}>
        <div className="skeleton skeleton-box"></div>
        <div className="skeleton skeleton-box"></div>
        <div className="skeleton skeleton-box"></div>
        <div className="skeleton skeleton-box"></div>
    </div>
  )
}

export default SideInfoButtonLoader