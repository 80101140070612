import React from 'react'
import { MDBContainer, MDBRow, MDBCol, MDBModal, MDBModalHeader, MDBModalBody } from 'mdbreact'
import FormGroupTitle from '../Form/FormGroupTitle';
import InputForm from '../Form/InputForm';

const EditInfluencerModal = ({ open, toggle, close }) => {

  return (
    <MDBContainer className='modal-container modal-form-container modal-form-influencer'>
        <MDBRow>
          <MDBCol>
            <MDBModal isOpen={open} toggle={toggle} centered tabIndex='-1'>
              <MDBModalHeader className='confirmation-modal-header text-uppercase p-2'><span className='pl-1 mx-2 letter-spacing-1 font-weight-600 font-color-gray'>EDIT INFLUENCER</span></MDBModalHeader>
              <MDBModalBody>
                <form id="" className="form-add-influencer">
                    <MDBRow>
                        <MDBCol xl='12' lg='12' className='form-group-box'>
                            <FormGroupTitle label="Social Media Platform" />
                            <div className='checkboxes-groups'>
                                <div className='checkboxs-list'>
                                    <div className='checkbox-group'>
                                        <input type='checkbox' name='facebook1' id='facebook1' />
                                        <label for='facebook1'>Facebook</label>
                                    </div>
                                    <div className='checkbox-group'>
                                        <input type='checkbox' name='tiktokmicroinfluencers' id='tiktokmicroinfluencers' />
                                        <label for='tiktokmicroinfluencers'>Tiktok Microinfluencers</label>
                                    </div>
                                    <div className='checkbox-group'>
                                        <input type='checkbox' name='tiktokcreators' id='tiktokcreators' />
                                        <label for='tiktokcreators'>TikTok Creators</label>
                                    </div>
                                    <div className='checkbox-group'>
                                        <input type='checkbox' name='dancers' id='dancers' />
                                        <label for='dancers'>Dancers</label>
                                    </div>
                                </div>
                            </div>
                            <FormGroupTitle label="Influencer Information" />
                            <div className='input-group-box half-box-'>
                                <InputForm 
                                    label='Influencer Name'
                                    type='text'
                                    id='id'
                                    name='name'
                                />
                                <InputForm 
                                    label='Email Address'
                                    type='email'
                                    id='id'
                                    name='name'
                                />
                                <InputForm 
                                    label='Phone Number'
                                    type='tel'
                                    id='id'
                                    name='name'
                                />
                                <InputForm 
                                    label='Date Contacted'
                                    type='date'
                                    id='id'
                                    name='name'
                                />
                            </div>
                            <div className="form-group-title w-checkbox">
                                <label>Handler Details</label>
                                <div className='checkbox-group'>
                                    <input type='checkbox' name='sameasabover' id='sameasabover' />
                                    <label for='sameasabover'>Same as Above</label>
                                </div>
                            </div>
                            <div className='input-group-box full-box-'>
                                <InputForm 
                                    label='Handler Name'
                                    type='text'
                                    id='id'
                                    name='name'
                                />
                                <InputForm 
                                    label='Email Address'
                                    type='email'
                                    id='id'
                                    name='name'
                                />
                                <InputForm 
                                    label='Phone Number'
                                    type='tel'
                                    id='id'
                                    name='name'
                                />
                            </div>
                        </MDBCol>
                    </MDBRow>
                </form>
                <MDBRow>
                  <MDBCol className='text-center'>
                    <button className='modal-btn save-btn-modal'>
                        Save Influencer
                    </button>
                    <button className='modal-btn cancel-btn-modal' onClick={close}>
                        Cancel
                    </button>
                  </MDBCol>
                </MDBRow>
              </MDBModalBody>
            </MDBModal>
          </MDBCol>
        </MDBRow>
      </MDBContainer>
  )
}

export default EditInfluencerModal