import React, {Component} from 'react';
import {influencerActions} from "_actions";
import {connect} from "react-redux";
import Button from "_pages/Influencer/components/General/Button";
import {utilitiesService} from "_services";
import Moment from "react-moment";
import DOMPurify from "dompurify";

class RateTab extends Component {

    componentDidMount() {
        const {projectId, influencerId, getInfluencerRates} = this.props;
        getInfluencerRates(projectId, influencerId);
    }

    openAddRatesModal= () => {
        this.props.onHandleManageRatesModal('add', null);
    }

    openEditRatesModal= (rates) => {
        this.props.onHandleManageRatesModal('edit', rates);
    }

    openDeleteConfirmationModal = (type, rate) => {
        this.props.onHandleOpenConfirmationModal(type, rate);
    }

    render() {

        const { classN,getInfluencerRateLoaded, influencerPlatformLoading, rates } = this.props;

        let rateList = [];
        let hasRates = false;

        if (getInfluencerRateLoaded) {
            rateList = rates;
            hasRates = rateList.length ? true : false;
        }

        return (
            <div className={classN}>

                <div className='inner-title-box'>
                    <p>
                        Rates
                    </p>
                    {getInfluencerRateLoaded && hasRates &&
                    <Button
                        text='Add Rate'
                        icon='icon-lms icon-add'
                        onClick={ () => this.openAddRatesModal()}
                        classN="plain-btn"
                    />
                    }
                </div>

                {getInfluencerRateLoaded && hasRates &&
                    <div className='rates-list'>
                        {
                            rateList.map((rate, index) =>{

                                let updated = rate.updated;
                                let created = rate.created;

                                let content = {__html: DOMPurify.sanitize(rate.description)}
                                let minRate = {__html: DOMPurify.sanitize(rate.minimum)}
                                let maxRate = {__html: DOMPurify.sanitize(rate.maximum)}
                                let currency = {__html: DOMPurify.sanitize(rate.currency.toUpperCase())}

                                return(<div className='rate-col'><div key={index} className='rate'>
                                    <div className='rate-details'>
                                        <div className='rate-currency'>
                                            <p className='title' dangerouslySetInnerHTML={currency}/>
                                            <span className='description'>
                                                Currency
                                            </span>
                                        </div>
                                        <div className='rate-min'>
                                            <p className='title' dangerouslySetInnerHTML={minRate}/>
                                            <span className='description'>
                                                Minimum Rate
                                            </span>
                                        </div>
                                        <div className='rate-max'>
                                            <p className='title' dangerouslySetInnerHTML={maxRate}/>
                                            <span className='description'>
                                                Maximum Rate
                                            </span>
                                        </div>
                                        <div className='rate-des'>
                                            <span className='description'>
                                                Description
                                            </span>
                                            <p className='title' dangerouslySetInnerHTML={content}/>
                                        </div>
                                        <div className='rate-time'>
                                            <span className='timestamp'>
                                                Rate Added: <Moment className='' date={created} fromNow ago/> ago
                                            </span>
                                            <span>
                                                {updated && <React.Fragment>Last Updated: <Moment className='' date={updated} fromNow ago/> ago</React.Fragment>}
                                            </span>
                                        </div>
                                    </div>
                                    <div className='rate-btns'>
                                        <button className='c-btn c-btn-sm c-btn-light edit-record' onClick={() => this.openEditRatesModal(rate)}><i className="icon-lms icon-edit"></i>Edit</button>
                                        <button className='c-btn c-btn-sm c-btn-light delete-record' onClick={ () => this.openDeleteConfirmationModal('rate', rate)}><i className="icon-lms icon-delete"></i>Delete</button>
                                    </div>
                                </div></div>);
                            })
                        }
                    </div>
                }

                {getInfluencerRateLoaded && !hasRates &&
                <div className='empty-tab-content'>
                    <div className='empty-content'>
                        <h6>Add Your First Rate</h6>
                        <p>Rates will appear here</p>
                        <span onClick={ () => this.openAddRatesModal()}>
                            <i className='icon-lms icon-add'></i>
                            Add rate
                        </span>
                     </div>
                </div>
                }

                {influencerPlatformLoading && 
                    <div className='loading-box'>
                        <div class="lds-ellipsis"><div></div><div></div><div></div><div></div></div>
                    </div>
                }
            </div>

        )
    }


}


function mapState(state) {
    const {influencers} = state;
    const {getInfluencerRateLoaded, influencerPlatformLoading, rates} = influencers;
    return {influencers,getInfluencerRateLoaded,influencerPlatformLoading,rates}
}

const actionCreators = {
    getInfluencerRates: influencerActions.getInfluencerRates,
};

const connectedViewRateTab = connect(mapState, actionCreators)(RateTab);
export {connectedViewRateTab as RateTab};
