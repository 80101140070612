import React from 'react'
import { MDBContainer, MDBRow, MDBCol, MDBModal, MDBModalHeader, MDBModalBody } from 'mdbreact'

const EditSocialMediaModal = ({ open, toggle, close }) => {
  return (
    <MDBContainer className='modal-container modal-form-container'>
        <MDBRow>
          <MDBCol>
            <MDBModal isOpen={open} toggle={toggle} centered tabIndex='-1'>
              <MDBModalHeader className='confirmation-modal-header text-uppercase p-2'><span className='pl-1 mx-2 letter-spacing-1 font-weight-600 font-color-gray font-size-13'>EDIT SOCIAL MEDIA</span></MDBModalHeader>
              <MDBModalBody>
                <MDBRow className='form-group-row'>
                  <MDBCol className='col-12'>
                        <label>Social Media Flatform</label>
                        <input type='text' value='Edit Social Media Flatform' />
                  </MDBCol>
                  <MDBCol className='col-12'>
                        <label>Followers / Subscribers</label>
                        <input type='number'  value='10000000' />
                  </MDBCol>
                </MDBRow>
                <MDBRow>
                  <MDBCol className='text-center'>
                    <button className='modal-btn save-btn-modal'>
                        Save Records
                    </button>
                    <button className='modal-btn cancel-btn-modal' onClick={close}>
                        Cancel
                    </button>
                  </MDBCol>
                </MDBRow>
              </MDBModalBody>
            </MDBModal>
          </MDBCol>
        </MDBRow>
      </MDBContainer>
  )
}

export default EditSocialMediaModal