import React from 'react';
import { connect } from "react-redux";
import { formActions } from '_actions';

import { MDBCol, MDBRow, MDBIcon, MDBBtn } from 'mdbreact';
import { Button } from "blackbox-react";

import { FormValidation } from '_components/FormValidation';
import DeleteConfirmationModal from '_components/Modal/DeleteConfirmationModal';

class EditFormGroup extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      formGroupData: this.props.formGroupData || {
        name: 'New Form Group',
        layout: '1'
      },
      formErrors: {
        name: ''
      },
      formGroupNameValid: true,
      formValid: false,
      submitted: false,
      switchEnabled: true,
      columns: 1,
      groupToDelete: '',
      formUpdated: false
    };

    this.handleFormUpdate = this.handleFormUpdate.bind(this);
  }

  handleToggle = () =>{
    let { modalOpen } = this.state;
    this.setState({modalOpen: !modalOpen})
  };

  handlePassData = (data) => {
    this.setState({
      groupToDelete: data
    })
  };

  approveModal = (groupToDelete) => {
    const projectId = this.props.projectId;
    const formId = this.props.formId;
    const formGroupData = this.props.formGroupData;
    let formGroupId = formGroupData.id;

    const deleteFormGroupData = {
      name: groupToDelete.name,
      layout: groupToDelete.layout
    };

    this.props.deleteFormGroup(projectId, formId, formGroupId, deleteFormGroupData);

    this.setState({
      formGroupData: {},
      groupToDelete: {}
    });
  };

  validateField(fieldName, value) {
    let fieldValidationErrors = this.state.formErrors;
    let formGroupNameValid = this.state.formGroupNameValid;

    let isAllWhitespace = (value.replace(/(?!^ +$)^.+$/gm, "") !== '');

    switch(fieldName) {
      case 'name':
        if (value === "" || isAllWhitespace) { // check if empty
          formGroupNameValid = false;
          fieldValidationErrors.name = 'Please enter a form group name.';
        } else {
          formGroupNameValid = (value.length >= 0); // check if valid
          fieldValidationErrors.name = formGroupNameValid ? '': 'Please enter a form group name.';
        }
        break;
      default:
        break;
    }

    this.setState({
      formErrors: fieldValidationErrors,
      formGroupNameValid: formGroupNameValid
    }, this.validateForm);
  }

  validateForm() {
    this.setState({
      formValid: this.state.formGroupNameValid
    });
  }

  handleFormUpdate = (event) => {
    let { name, value } = event.target;
    const { formGroupData } = this.state;

    if(value === "on"){
      value = true;
    }

    this.setState({
      formGroupData: {
        ...formGroupData,
        [name]: value
      }
    }, () => {
      this.validateField(name, value);

      if(this.state.formValid){
        this.props.handleFormUpdate(this.state.formGroupData);
      }
    });
  };

  handleCheckboxSelect = (event, value) => {
    const { formGroupData } = this.state;

    this.setState({
      formGroupData: {
        ...formGroupData,
        hide_group: !value
      }
    }, () => {
      this.props.handleFormUpdate(this.state.formGroupData);
    });
  };

  handleIdentifier = (event, formGroupId, type) => {
    const projectId = this.props.projectId;
    const formId = this.props.formId;
    const { formGroupData } = this.state;

    let identifier = {
      identifier: formGroupId,
      identifier_type: type
    };


    this.setState({
      formGroupData: {
        ...formGroupData,
        identifier: formGroupId
      }
    }, () => {
      this.props.updateIdentifier(projectId, formId, identifier);
    });

  };

  handleSubIdentifier = (event, formGroupId, type) => {
    const projectId = this.props.projectId;
    const formId = this.props.formId;
    const { formGroupData } = this.state;

    let subidentifier = {
      sub_identifier: formGroupId,
      sub_identifier_type: type
    };


    this.setState({
      formGroupData: {
        ...formGroupData,
        sub_identifier: formGroupId
      }
    }, () => {
      this.props.updateSubIdentifier(projectId, formId, subidentifier);
    });

  };

  handleSwitchSelect = (event, value) => {
    const { formGroupData } = this.state;

    value = (value === 1 ? 0 : 1);

    this.setState({
      formGroupData: {
        ...formGroupData,
        status: value
      }
    }, () => {
      this.props.handleFormUpdate(this.state.formGroupData);
    });
  };

  handleMapColumns = () => {
    let options = this.props.formGroupData;
    return (
      options.map((value, index) => {
        return (
          <option value={index + 1}>{index + 1}-Columns</option>
        )
      })
    )
  };

  handleSubmit = (data) => {
    this.validateForm();

    this.setState({ submitted: true }, () => {
      if (this.state.formValid) {
        if(this.state.submitted){

          // If id exists, UPDATE form group.
          // If not, CREATE form group
          if(data.id){
            //console.log('update form group', data);
            const projectId = this.props.projectId;
            const formId = this.props.formId;
            this.props.updateFormGroup(projectId, formId, data.id, data);

          } else{
            if(Object.keys(data).length){
              //console.log('create form group', data);
              const projectId = this.props.projectId;
              const formId = this.props.formId;
              this.props.createFormGroup(projectId, formId, data);

              this.props.getBlankSelection();
            } else{
              this.setState({
                formGroupNameValid: false,
                formValid: false,
                formErrors: {
                  name: 'Please enter a form group name.'
                },
              });
            }
          }

          this.props.handleFormUpdate();
        }
      }
    });
  };

  errorClass(error) {
    return(error.length === 0 ? 'is-passed' : 'is-failed');
  }


  render() {
    const formGroupData = this.state.formGroupData;
    const { deleted } = this.props;
    const { submitted, formValid } = this.state;
    const formData = this.props.formData;

    if(deleted){
      this.props.getBlankSelection();
    }


    let disabled = this.state.switchEnabled ? '' : 'disabled';
    let text = formGroupData.status ? 'Enabled' : 'Disabled';
    return (
      <div className=''>
        <MDBRow className='justify-content-between m-l--md m-r--md'>
          <p className='font-size-12 font-weight-600 margin-top-20 mb-0'>
            { formGroupData.id ? 'EDIT' : 'CREATE'} FORM GROUP
          </p>
          { formGroupData.id &&
            <MDBRow className='m-r--0'>
              <div className='switch mr-2 mt-3'>
                <input
                  checked={formGroupData.status}
                  type='checkbox'
                  name='switch'
                  className='switch-checkbox'
                  onChange={(event) => { this.handleSwitchSelect(event, formGroupData.status) }}
                  id='myswitch' />
                <label className='switch-labels' htmlFor='myswitch'>
                  <span className='switch-text'> </span>
                  <span className='switch-dot'> </span>
                </label>
              </div>
              <p className='mt-3 color-light-black mb-0 font-size-14 font-weight-600'>{text}</p>
            </MDBRow>
          }
        </MDBRow>
        <hr className='m-t--sm p-t--sm p-b--0 m-b--0' />
        <div className={`form-group m-l--md m-r--md m-t--sm m-b--0 p--0 ${submitted && this.errorClass(this.state.formErrors.name)}`}>
          <label className='font-weight-600 font-size-14 mb-2'>Group Name <span className='color-red'>*</span></label>
          <input
            type='text'
            autoFocus={true}
            placeholder='Enter Form Group name..'
            defaultValue={formGroupData.name || ''}
            name='name'
            onChange={(e) => { this.handleFormUpdate(e) }}
          />
          <div className={`form-validation__container ${(!formValid && submitted) ? 'display-block' : 'display-none'}`}>
            <FormValidation fieldName='name' formErrors={this.state.formErrors}/>
          </div>
        </div>
        { formGroupData.id &&
          <div>
            <div className='m-l--md m-r--md m-t--sm m-b--0 p--0'>
              <label className={`${disabled} checkboxcontainer-edit mx-0 font-size-12`}>
                <input
                  type='checkbox'
                  name='hideGroupName'
                  checked={formGroupData.hide_group || false}
                  onChange={(event) => { this.handleCheckboxSelect(event, formGroupData.hide_group || false) }}
                />
                <span className='checkmark'> </span>
                <span>Hide Group Name</span>
              </label>
            </div>
            <hr className='m-t--sm p-t--sm p-b--0 m-b--0' />
            <div className='m-l--md m-r--md m-t--sm m-b--0 p--0'>
              <label className='font-weight-600 font-size-14 mb-2'>Lead Data </label>
            </div>
            <div className='m-l--md m-r--md m-t--0 m-b--0 p--0'>
              <label className={`${disabled} checkboxcontainer-edit mx-0 font-size-12`}>
                <input
                  type='checkbox'
                  name='setAsPrimary'
                  checked={(formGroupData.id === formData.identifier) || false}
                  onChange={(event) => { this.handleIdentifier(event, formGroupData.id, 'Form Group') }}
                />
                <span className='checkmark'> </span>
                <span>Set as Primary Lead Data</span>
              </label>
            </div>
            <div className='m-l--md m-r--md m-t--0 m-b--0 p--0'>
              <label className={`${disabled} checkboxcontainer-edit mx-0 font-size-12`}>
                <input
                  type='checkbox'
                  name='setAsSecondary'
                  checked={(formGroupData.id === formData.sub_identifier) || false}
                  onChange={(event) => { this.handleSubIdentifier(event, formGroupData.id, 'Form Group') }}
                />
                <span className='checkmark'> </span>
                <span>Set as Secondary Lead Data</span>
              </label>
            </div>
            {/*<div className="m--md">*/}
            {/*  <p className='font-weight-600 font-size-14 mb-2'>Layout</p>*/}
            {/*  <div className='width-inherit'>*/}
            {/*    <select*/}
            {/*      defaultValue={formGroupData.layout}*/}
            {/*      name='layout'*/}
            {/*      onChange={(e) => { this.handleFormUpdate(e) }}*/}
            {/*    >*/}
            {/*      <option value='1'>1-Column</option>*/}
            {/*      <option value='2'>2-Column</option>*/}
            {/*      <option value='3'>3-Column</option>*/}
            {/*    </select>*/}
            {/*  </div>*/}
            {/*</div>*/}
          </div>
        }

        <MDBCol className='position-bottom p-l--md'>
          <MDBRow className='m--0 p--0'>
            { formGroupData.id &&
              <MDBCol lg='5' xl='5' className='text-left m-b--sm p-l--sm m-l--n20'>
                <Button
                  flat='true'
                  type="submit"
                  background='transparent'
                  foreground='red'
                  size={-2}
                  onClick={(event) => {
                    event.preventDefault();
                    this.handleToggle();
                    this.handlePassData(formGroupData)
                  }}>
                  <MDBIcon icon='trash-alt' className='mr-2' />
                  <u>Delete Group</u>
                </Button>
                <DeleteConfirmationModal
                  toggle={this.handleToggle}
                  modalOpen={this.state.modalOpen}
                  statusToDelete={this.state.groupToDelete}
                  onApprove={this.approveModal}
                  target={formGroupData.label}
                />
              </MDBCol>
            }
            <MDBCol className='px-0 pr-3'>
              <MDBRow className='float-right'>
                <p className='font-size-12 color-gray hand m-r--sm m-t--sm'>
                  <u onClick={() => { this.props.getBlankSelection() }}>Cancel</u>
                </p>
                <MDBBtn
                  onClick={() => { this.handleSubmit(formGroupData) }}
                  className='font-size-10 background-color-orange font-weight-bold shadow-none color-white py-2 px-4 br-3'>
                  SAVE CHANGES
                </MDBBtn>
              </MDBRow>
            </MDBCol>
          </MDBRow>
        </MDBCol>
      </div>
    )
  }
}

function mapState(state) {
  const { creating, forms, deleting, deleted } = state.forms;
  return { creating, forms, deleting, deleted };
}

const actionCreators = {
  createFormGroup: formActions.createFormGroup,
  updateFormGroup: formActions.updateFormGroup,
  deleteFormGroup: formActions.deleteFormGroup,
  updateIdentifier: formActions.updateIdentifier,
  updateSubIdentifier: formActions.updateSubIdentifier
};

const connectedEditFormGroup = connect(mapState, actionCreators)(EditFormGroup);
export { connectedEditFormGroup as EditFormGroup };
