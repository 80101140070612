import React, { Component } from 'react'
import { MDBContainer, MDBCol, MDBRow, MDBBtn } from 'mdbreact'
import Input from '_components/Input';
import logo from '_assets/images/lms-logo.png';
import loginBG from '_assets/images/login-bg.png';
import {Link} from 'react-router-dom'
import {userActions} from "_actions";
import {connect} from "react-redux";
import { FormValidation } from '_components/FormValidation';
import {utilitiesService as utility} from '_services';

class ForgotPassword extends Component {
  constructor(props) {
    super(props);

    this.state = {
      email: '',
      class: true,
      submitted: false,
      formErrors: {
        email: 'Please enter your email.',
        register: ''
      },
      emailValid: false,
      formValid: false
    };

    this.handleChange = this.handleChange.bind(this); 
  }

  validateField(fieldName, value) {
    let fieldValidationErrors = this.state.formErrors;
    let emailValid = this.state.emailValid;
    fieldValidationErrors.register = '';

    let isAllWhitespace = (value.replace(/(?!^ +$)^.+$/gm, "") !== '');

    switch (fieldName) {
      case 'email':
        if (value === "" || isAllWhitespace) { // check if empty
          emailValid = false;
          fieldValidationErrors.email = 'Please enter your email.';
        } else { // check if valid
          emailValid = value.match(/^([\w.%+-]+)@([\w-]+\.)+([\w]{2,})$/i);
          fieldValidationErrors.email = emailValid ? '' : 'Please enter your email address in valid format: sample@domain.com';
        }
        break;
      default:
        break;
    }
    this.setState({
      formErrors: fieldValidationErrors,
      emailValid: emailValid,
    }, this.validateForm);
  }

  validateForm() {
    this.setState({
      formValid: this.state.emailValid
    });
  }

  errorClass(error) {
    return (error.length === 0 ? 'is-passed' : 'is-failed');
  }

  handleChange(e) {
    const { name, value } = e.target;

    this.setState({
      [name]: value,
    }, () => {
      this.validateField(name, value);
    });
  }

  handleSend = async e => {
    e.preventDefault();
    this.validateForm();

    const { email } = this.state;
    this.setState({ class: !!email })
    this.setState({ submitted: true }, () => {
      if (this.state.formValid) {
        if (this.state.submitted) {
          this.props.logout();
          this.setState({ class: !!email })
          const request = {email: utility.encrypt(this.state.email)}
          this.props.forgotPassword(request);

        }
      }
    });
    return false;
  };

  handleBackToLogin = () => {
    this.props.clearError(); 
  };

  render() {
   
    let { error, loading } = this.props;
    const { email, submitted, formValid } = this.state;

    let fieldValidationErrors = this.state.formErrors;
    fieldValidationErrors.register = '';

    console.log('email: ', fieldValidationErrors);
    
    if(fieldValidationErrors.email) {
      error = '';
    }

    fieldValidationErrors.register = error ? error : '';  

    return (
      <MDBContainer fluid className='login-background'>
        <div className='login-bg'>
          <img src={loginBG} alt='Login Background Image' />
        </div>
        <div className='login-panel'>
          <MDBRow>
            <MDBCol className='text-center'>
            <img src={logo} alt='Propelrr Platform' className='login-logo' />
            <h1>Lead Management System</h1>
            </MDBCol>
          </MDBRow>
          <MDBRow>
            <MDBCol>
              <form onSubmit={this.handleSend} noValidate>
                <MDBRow>
                  <MDBCol className="text-center">
                    <div className='form-inner-heading'>
                      <h2>Forgot Password?</h2>
                      <p>
                        By entering your email, we’ll send you <br/>your new password
                      </p>
                    </div>
                  </MDBCol>
                </MDBRow>
                <MDBRow>
                  <MDBCol>
                    <div className={`form-group ${submitted && this.errorClass(this.state.formErrors.email)}`}>
                      <label className='l-main'>Email</label>
                      <input
                        id='email-input-forgot'
                        name='email'
                        placeholder='yourgivenemail@company.com'
                        type='email'
                        required={true}
                        value={email}
                        autoFocus={true}
                        onChange={this.handleChange}
                      />
                      <div className={`form-validation__container ${(!formValid && submitted) ? 'display-block' : 'display-none'}`}>
                        <FormValidation fieldName='email' formErrors={fieldValidationErrors} />
                      </div>
                      <div className={`form-validation__container ${error ? 'display-block' : 'display-none'}`}>
                        <FormValidation fieldName='register' formErrors={fieldValidationErrors} />
                      </div>
                    </div>
                    <div className='form-group'>
                      <button type='submit' className={formValid && submitted && !error && loading ? "c-btn c-btn-lg c-btn-disabled" : "c-btn c-btn-lg"}> Send me a new password</button>
                      {formValid && submitted && !error && loading &&
                        <div class="lds-ellipsis"><div></div><div></div><div></div><div></div></div>
                      }
                    </div>
                    <div className='form-group'>
                      <div className='go-login-group'>
                        <p>
                          Go back to <Link to='/login' onClick={this.handleBackToLogin}>Login</Link>
                        </p>
                      </div>
                    </div>
                  </MDBCol>
                </MDBRow>
              </form>
            </MDBCol>
          </MDBRow>
        </div>
      </MDBContainer>
    )
  }
}

//export default ForgotPassword;

function mapState(state) {
  console.log('state: ', state)
  const { changePassword } = state.authentication;
  const { error, loading }  = state.users
  return { changePassword, error, loading };
}

const actionCreators = {
  forgotPassword: userActions.forgotPassword,
  logout: userActions.logout,
  clearError: userActions.clearError,
  // logout: userActions.logout
};

const connectedForgotPasswordPage = connect(mapState, actionCreators)(ForgotPassword);
export { connectedForgotPasswordPage as ForgotPassword };