import React, { Component } from "react";
import {
  defaultConfig,
  phiConfig,
  maayoConfig,
  propelrrConfig,
  demoConfig,
  propelrrServiceConfig,
  propelrrCareerConfig,
  dmciLeasingConfig,
} from "_pages/Leads/constants/TableHeaderConfig";

class DefaultTableHeader extends Component {
  handleSortChange = (field, direction, active) => {
    if (!active) {
      this.props.onHandleSortChange(field, direction);
    }
  };

  renderSortAction(col) {
    const { field, direction } = this.props;

    let isDescSelected = false;
    let isAscSelected = false;

    if (field === col.field) {
      if (direction === "ASC") {
        isAscSelected = true;
      } else {
        isDescSelected = true;
      }
    }

    if (col.sort) {
      return (
        <div className="sort-icons">
          <i
            className={
              isAscSelected ? "fas fa-sort-up active" : "fas fa-sort-up"
            }
            onClick={() =>
              this.handleSortChange(col.field, "ASC", isAscSelected)
            }
          ></i>
          <i
            className={
              isDescSelected ? "fas fa-sort-down active" : "fas fa-sort-down"
            }
            onClick={() =>
              this.handleSortChange(col.field, "DESC", isDescSelected)
            }
          ></i>
        </div>
      );
    } else {
      return null;
    }
  }


  render() {

    const { projectId, onMasterCheckboxChange, headerCheckboxChecked, selectedLeadIds } = this.props;

    let config = defaultConfig;

    switch (parseInt(projectId)) {
      case 1:
        config = phiConfig;
        break;
      case 3:
        config = maayoConfig;
        break;
      case 5:
        config = propelrrServiceConfig;
        break;
      case 6:
        config = propelrrCareerConfig;
        break;
      case 7:
        config = demoConfig;
        break;
      case 8:
        config = dmciLeasingConfig;
        break;
      default:
        config = defaultConfig;
        break;
    }

    return (
      <tr>
        {config.map((col, index) => {
          return (
            <th key={`lead-header-${index}`} width={`${col.width}`}>
              <div className="th-w-sort">
                {index === 0 && (
                  <div className="checkbox-group">
                    <input
                      type="checkbox"
                      name="table-checkbox-heading"
                      id="table-checkbox-heading"
                      checked={headerCheckboxChecked}
                      onChange={onMasterCheckboxChange}
                    />
                    <label htmlFor="table-checkbox-heading"></label>
                  </div>
                )}
                <span> {col.title}</span>
                {this.renderSortAction(col)}
              </div>
              {/*{ col.sort &&*/}
              {/*<div className="sort-icons">*/}
              {/*    <i className="fas fa-sort-up" onClick={() => this.handleSortChange(col.field, "ASC")}></i>*/}
              {/*    <i className="fas fa-sort-down" onClick={() => this.handleSortChange(col.field, "DESC")}></i>*/}
              {/*</div>}*/}
            </th>
          );
        })}
      </tr>
    );
  }
}

export default DefaultTableHeader;
