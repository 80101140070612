
import {alertActions} from "_actions/alert.actions";
import {utmConstants} from "_constants";
import {utmService} from "_services/utm.service";
import {history} from "_helpers";

export const utmActions = {
    getUtms,
    generateUtm,
    getCodex,
    getPresets,
}

function getUtms(projectId,  query = null, page = 1, size = 15) {
    return dispatch => {
        dispatch(request(projectId,  query, page, size));
        utmService.getUtms(projectId,  query, page, size)
            .then(
                data => {
                    dispatch(success(data));
                },
                error => {
                    const {response} = error;
                    const {data} = response;
                    dispatch(failure(data));
                    dispatch(alertActions.error(data));
                }
            );
    };

    function request(projectId,  query = null, page = 1, size = 15) {
        return {type: utmConstants.GET_UTMS_REQUEST, projectId, query, page, size}
    }

    function success(data) {
        return {type: utmConstants.GET_UTMS_SUCCESS, data}
    }

    function failure(error) {
        return {type: utmConstants.GET_UTMS_FAILURE, error}
    }
}

function generateUtm(projectId,  req) {
    return dispatch => {
        dispatch(request(projectId, req));
        utmService.generateUtm(projectId,  req)
            .then(
                res => {
                    dispatch(success(res));
                    history.push(`/project/${projectId}/utm`);
                    dispatch(alertActions.success("successfully generated utm"));
                    dispatch(getUtms(projectId));
                },
                error => {
                    const {response} = error;
                    const {data} = response;
                    dispatch(failure(data));
                    dispatch(alertActions.error(data));
                }
            );
    };

    function request(projectId, req)   {
        return {type: utmConstants.GENERATE_UTM_REQUEST, projectId, req}
    }

    function success(res) {
        return {type: utmConstants.GENERATE_UTM_SUCCESS, res}
    }

    function failure(error) {
        return {type: utmConstants.GENERATE_UTM_FAILURE, error}
    }
}

function getCodex(project) {
    return dispatch => {
        dispatch(request(project));
        utmService.getCodex(project)
            .then(
                codex => {
                    dispatch(success(codex));
                },
                error => {
                    const {response} = error;
                    const {data} = response;
                    dispatch(failure(data));
                    dispatch(alertActions.error(data));
                }
            );
    };

    function request(project) {
        return {type: utmConstants.GET_UTM_CODEX_REQUEST, project}
    }

    function success(codex) {
        return {type: utmConstants.GET_UTM_CODEX_SUCCESS, codex}
    }

    function failure(error) {
        return {type: utmConstants.GET_UTM_CODEX_FAILURE, error}
    }
}

function getPresets(projectId) {
    return dispatch => {
        dispatch(request(projectId));
        utmService.getPresets(projectId)
            .then(
                presets => {
                    dispatch(success(presets));
                },
                error => {
                    const {response} = error;
                    const {data} = response;
                    dispatch(failure(data));
                    dispatch(alertActions.error(data));
                }
            );
    };

    function request(projectId) {
        return {type: utmConstants.GET_PROJECT_PRESETS_REQUEST, projectId}
    }

    function success(presets) {
        return {type: utmConstants.GET_PROJECT_PRESETS_SUCCESS, presets}
    }

    function failure(error) {
        return {type: utmConstants.GET_PROJECT_PRESETS_FAILURE, error}
    }
}
