import React, {useEffect, useState } from "react";
import { MDBIcon } from "mdbreact";
import { activitySideButton } from "../../constants/BreadCrumbs";
import { Swiper, SwiperSlide } from "swiper/react";
import { FreeMode, Navigation } from "swiper";

const ActivitySideButton = ({
  isActiveSideBtn,
  sideBtnClick,
  leadDetailsInfo,
  unreadMessages,
  getChatsLoaded,
  hasLeadChat,
  unreadEmails,
  getEmailLoaded,
  hasLeadEmail,
  projectId
}) => {
  const [swiper, setSwiper] = useState(null);
  let activeSideBtn = "";

  let activitySideButtonCopy = [...activitySideButton];

  useEffect(() => {

    if(hasLeadEmail && activeSideBtn && swiper) {
        const index = activitySideButtonCopy.findIndex((item) => item.name === "Email");
        swiper.slideTo(index)
    }

    if(hasLeadChat && activeSideBtn && swiper) {
        const index = activitySideButtonCopy.findIndex((item) => item.name === "Chat");
        swiper.slideTo(index)
    }

  },[hasLeadEmail,hasLeadChat]);


  const renderActivitySideButton = () => {

    //const {projectId} = this.props
    console.log('props activity: ', projectId);
    

    // hiding chat if has no leadchat
    if (!hasLeadChat && getChatsLoaded) {
      activitySideButtonCopy.splice(
        activitySideButtonCopy.findIndex((item) => item.name === "Chat"), 1); }

    // hiding email if no lead email
    if (!hasLeadEmail && getEmailLoaded) {
      activitySideButtonCopy.splice(
        activitySideButtonCopy.findIndex((item) => item.name === "Email"), 1); }

    // Conditionally remove "Document" item based on the project condition
    if (projectId == 5) {
      activitySideButtonCopy.splice(
        activitySideButtonCopy.findIndex((item) => item.name === "Document"), 1); }

    return activitySideButtonCopy.map((value, index) => {
      activeSideBtn = isActiveSideBtn === value.name && "active";
      
      return (
        <SwiperSlide key={index}>
          <div
            className={`${activeSideBtn} activity-btns`}
            onClick={() => {
              sideBtnClick(value.name);
            }}
          >
            <p>
              <span>{value.name}</span>
              {value.name == "Chat" && (
                <span
                  className={
                    unreadMessages == 0 ? "notif-count empty" : "notif-count"
                  }
                >

                  {
                    getChatsLoaded &&

                    unreadMessages > 9 ? "9+" : unreadMessages
                  }
                  {!getChatsLoaded && (
                            <div className="loader-sm">
                              <div></div>
                              <div></div>
                              <div></div>
                              <div></div>
                            </div>
                  )}
                </span>
              )}
              {value.name == "Email" && (
                <span
                  className={
                    unreadEmails == 0 ? "notif-count empty" : "notif-count"
                  }
                >
                  {getEmailLoaded &&
                  unreadEmails > 9 ? "9+" : unreadEmails
                  }
                  {!getEmailLoaded && (
                            <div className="loader-sm">
                              <div></div>
                              <div></div>
                              <div></div>
                              <div></div>
                            </div>
                          )}
                </span>
              )}
            </p>
          </div>
        </SwiperSlide>
      );
    });
  };


    return (
      <div className="activity-control-box">
        <Swiper
          slidesPerView={4}
          spaceBetween={0}
          modules={[Navigation]}
          className="tabSwiper"
          navigation={{ nextEl: ".nextSlide", prevEl: ".prevSlide" }}
          //speed={200}
          onSwiper={setSwiper}
        >
          {renderActivitySideButton()}
        </Swiper>
        <button className='swiper-custom-btn prevSlide'><i className="icon-lms icon-arrow-left"></i></button>
        <button className='swiper-custom-btn nextSlide'><i className="icon-lms icon-arrow-right"></i></button>
        {/* <div className='activity-side-btns'>
          <div onClick={this.props.handleDeleteLead} >
            <p>
                <MDBIcon far icon='trash-alt' className='font-size-22 color-red' />
                <span>
                  DELETE <br/>LEADS
                </span>
              </p>
          </div>
        </div> */}
      </div>
    );
  
};

export default ActivitySideButton;
