import React, { useState, useRef } from "react";
import {ranges} from '../constants/DateRangeConfig';
import {DateRangePicker} from "rsuite";

const TableControl = ({ isListView, isGroupView, onHandleTableListView, onHandleTableGroupView }) => {
  const [search, setSearch] = useState(false);
  const searchRef = useRef();

  const handleOpenSearch = () => {
    setSearch(true);
  };
  const handleCloseSearch = () => {
    setSearch(false);
  };

  return (
    <>
      <div className="left-part">
        <div className="control-title">
          <p>All UTM</p>
        </div>
        <div className="control-btns-group">
         {/*<div className='date-range-picker-box'>*/}
         {/*     <DateRangePicker */}
         {/*       ranges={ranges}*/}
         {/*       appearance="subtle"*/}
         {/*       format="MMM-dd-yyyy"*/}
         {/*       size="lg"*/}
         {/*       placeholder="All Time"*/}
         {/*       placement="autoVerticalStart"*/}
         {/*       cleanable={true}*/}
         {/*       // defaultValue={currentDate}*/}
         {/*     />*/}
         {/* </div>*/}
          <div className={search ? "search-box active" : "search-box"} ref={searchRef}>
            {search && (
              <div className="input-search">
                <i
                  className="fas fa-times close-btn"
                  onClick={() => handleCloseSearch()}
                ></i>
                <i className="fas fa-arrow-right ok-btn"></i>
                <input type="text" placeholder="Search UTM" />
              </div>
            )}
            {!search && (
              <span className="search-btn" onClick={() => handleOpenSearch()}>
                <i className="fas fa-search"></i>
                Search
              </span>
            )}
          </div>
        </div>
      </div>
      <div className="right-part">
        <div className="table-view-control-box">
            <button onClick={onHandleTableGroupView} className={isGroupView ? "active" : ""}><i className="fas fa-layer-group"></i>Group View</button>
            <button onClick={onHandleTableListView} className={isListView ? "active": ""}><i className="fas fa-bars"></i>List View</button>
        </div>
        <div className="pagination-box">
          <div className="influencer-counts">
            <span>
              1 - 15 of 10
            </span>
          </div>
          <ul>
            <a className="disabled">
              <i className="fas fa-chevron-left"></i>
            </a>
            <a className="active">
              <i className="fas fa-chevron-right"></i>
            </a>
          </ul>
        </div>
      </div>
    </>
  );
};

export default TableControl;
