import { templateConstants } from "_constants";
import { templateService } from "_services";
import { alertActions } from "_actions/alert.actions";

export const templateActions = {
  getTemplates,
  createTemplate,
  updateTemplate,
  removeTemplate,
  removeTemplates,
  shareTemplate,
  unshareTemplate,
  duplicateTemplate,
  getSharedTemplates,
  getSharedTemplatePaginated,
  getTemplatesDropdown,
  getTemplateAccountCategories,
  getTemplateSharedCategories
};

function getTemplates(projectId,  query = null, page = 1, size = 15, cat = '') {
  return (dispatch) => {
    dispatch(request());

    templateService.getTemplates(projectId,  query, page, size, cat).then(
      (data) => {
        dispatch(success(data));
      },
      (error) => {
        // error: "Internal Server Error"
        const { response } = error;
        const { data } = response;
       // console.log("error: ", data);
        let errResponse = data.error;

        dispatch(failure(data));
        dispatch(alertActions.error(data));
      }
    );
  };

  function request() {
    return { type: templateConstants.GET_TEMPLATES_REQUEST };
  }

  function success(template) {
    return { type: templateConstants.GET_TEMPLATES_SUCCESS, template };
  }

  function failure(error) {
    return { type: templateConstants.GET_TEMPLATES_FAILURE, error };
  }
}

function getTemplatesDropdown(projectId) {
  return (dispatch) => {
    dispatch(request());

    templateService.getTemplatesDropdown(projectId).then(
      (data) => {
        dispatch(success(data));
      },
      (error) => {
        // error: "Internal Server Error"
        const { response } = error;
        const { data } = response;
       // console.log("error: ", data);
        let errResponse = data.error;

        dispatch(failure(data));
        dispatch(alertActions.error(data));
      }
    );
  };

  function request() {
    return { type: templateConstants.GET_TEMPLATES_REQUEST };
  }

  function success(template) {
    return { type: templateConstants.GET_TEMPLATES_DROPDOWN_SUCCESS, template };
  }

  function failure(error) {
    return { type: templateConstants.GET_TEMPLATES_FAILURE, error };
  }
}

function createTemplate(projectId, templateRequest) {
  return (dispatch) => {
    dispatch(request());

    templateService.createTemplate(projectId, templateRequest).then(
      (data) => {
        dispatch(success(data));
        dispatch(alertActions.success("Template is created successfully."));
      },
      (error) => {
        const { response } = error;
        const { data } = response;
       // console.log("error: ", data);
        let errResponse = data.error;

        dispatch(failure(errResponse));
        dispatch(alertActions.error(errResponse));
      }
    );
  };

  function request() {
    return { type: templateConstants.GET_TEMPLATES_REQUEST };
  }

  function success(template) {
    return { type: templateConstants.GET_TEMPLATES_SUCCESS, template };
  }

  function failure(error) {
    return { type: templateConstants.GET_TEMPLATES_FAILURE, error };
  }
}

function updateTemplate(projectId, templateId, templateRequest) {
  return (dispatch) => {
    dispatch(request());

    templateService.updateTemplate(projectId, templateId, templateRequest).then(
      (data) => {
        dispatch(success(data));
        dispatch(alertActions.success("Template is updated successfully."));
      },
      (error) => {
        const { response } = error;
        const { data } = response;
       // console.log("error: ", data);
        let errResponse = data.error;

        dispatch(failure(errResponse));
        dispatch(alertActions.error(errResponse));
      }
    );
  };

  function request() {
    return { type: templateConstants.GET_TEMPLATES_REQUEST };
  }

  function success(template) {
    return { type: templateConstants.GET_TEMPLATES_SUCCESS, template };
  }

  function failure(error) {
    return { type: templateConstants.GET_TEMPLATES_FAILURE, error };
  }
}

function removeTemplate(projectId, templateId) {
  return (dispatch) => {
    dispatch(request());

    templateService.removeTemplate(projectId, templateId).then(
      (data) => {
        dispatch(success(data));
        dispatch(alertActions.success("Template is deleted successfully."));
      },
      (error) => {
        const { response } = error;
        const { data } = response;
       // console.log("error: ", data);
        let errResponse = data.error;

        dispatch(failure(errResponse));
        dispatch(alertActions.error(errResponse));
      }
    );
  };

  function request() {
    return { type: templateConstants.GET_TEMPLATES_REQUEST };
  }

  function success(template) {
    return { type: templateConstants.GET_TEMPLATES_SUCCESS, template };
  }

  function failure(error) {
    return { type: templateConstants.GET_TEMPLATES_FAILURE, error };
  }
}

function removeTemplates(projectId, templateRequest) {
  return (dispatch) => {
    dispatch(request());

    templateService.removeTemplates(projectId, templateRequest).then(
      (data) => {
        dispatch(success(data));
        //// console.log('deletes: ', templateRequest);
        dispatch(alertActions.success(templateRequest.templates.length + " template/s deleted successfully."));
      },
      (error) => {
        const { response } = error;
        const { data } = response;
        //// console.log("error: ", data);
        let errResponse = data.error_message;

        dispatch(failure(errResponse));
        dispatch(alertActions.error(errResponse));
      }
    );
  };

  function request() {
    return { type: templateConstants.GET_TEMPLATES_REQUEST };
  }

  function success(template) {
    return { type: templateConstants.GET_TEMPLATES_SUCCESS, template };
  }

  function failure(error) {
    return { type: templateConstants.GET_TEMPLATES_FAILURE, error };
  }
}

function shareTemplate(projectId, templateId) {
  return (dispatch) => {
    dispatch(request());

    templateService.shareTemplate(projectId, templateId).then(
      (data) => {
        dispatch(success(data));
        dispatch(alertActions.success("Template is shared successfully."));
      },
      (error) => {
        const { response } = error;
        const { data } = response;
        //// console.log("error: ", data);
        let errResponse = data.error;

        dispatch(failure(errResponse));
        dispatch(alertActions.error(errResponse));
      }
    );
  };

  function request() {
    return { type: templateConstants.GET_TEMPLATES_REQUEST };
  }

  function success(template) {
    return { type: templateConstants.GET_TEMPLATES_SUCCESS, template };
  }

  function failure(error) {
    return { type: templateConstants.GET_TEMPLATES_FAILURE, error };
  }
}

function unshareTemplate(projectId, templateId) {
  return (dispatch) => {
    dispatch(request());

    templateService.unshareTemplate(projectId, templateId).then(
      (data) => {
        dispatch(success(data));
        dispatch(alertActions.success("Template sharing is removed successfully."));
      },
      (error) => {
        const { response } = error;
        const { data } = response;
       // console.log("error: ", data);
        let errResponse = data.error;

        dispatch(failure(errResponse));
        dispatch(alertActions.error(errResponse));
      }
    );
  };

  function request() {
    return { type: templateConstants.GET_TEMPLATES_REQUEST };
  }

  function success(template) {
    return { type: templateConstants.GET_TEMPLATES_SUCCESS, template };
  }

  function failure(error) {
    return { type: templateConstants.GET_TEMPLATES_FAILURE, error };
  }
}

function duplicateTemplate(projectId, templateId, dupRequest) {
  return (dispatch) => {
    dispatch(request());

    templateService.duplicateTemplate(projectId, templateId, dupRequest).then(
      (data) => {
        dispatch(success(data));
        dispatch(alertActions.success("Template is duplicated successfully."));
      },
      (error) => {
        const { response } = error;
        const { data } = response;
       // console.log("error: ", data);
        let errResponse = data.error;

        dispatch(failure(errResponse));
        dispatch(alertActions.error(errResponse));
      }
    );
  };

  function request() {
    return { type: templateConstants.GET_TEMPLATES_REQUEST };
  }

  function success(template) {
    return { type: templateConstants.GET_TEMPLATES_SUCCESS, template };
  }

  function failure(error) {
    return { type: templateConstants.GET_TEMPLATES_FAILURE, error };
  }
}

function getSharedTemplates(projectId) {
  return (dispatch) => {
    dispatch(request());

    templateService.getSharedTemplates(projectId).then(
      (data) => {
       // console.log('data: ', data);
        dispatch(success(data));
      },
      (error) => {
        const { response } = error;
        const { data } = response;
       // console.log("error: ", data);
        let errResponse = data.error;

        dispatch(failure(errResponse));
        dispatch(alertActions.error(errResponse));
      }
    );
  };

  function request() {
    return { type: templateConstants.GET_SHARED_TEMPLATES_REQUEST };
  }

  function success(shared) {
    return { type: templateConstants.GET_SHARED_TEMPLATES_SUCCESS, shared };
  }

  function failure(error) {
    return { type: templateConstants.GET_SHARED_TEMPLATES_FAILURE, error };
  }
}

function getSharedTemplatePaginated(projectId,  query = null, page = 1, size = 15, cat = '') {
  return dispatch => {
      dispatch(request(projectId,  query, page, size));
      templateService.getSharedTemplatePaginated(projectId,  query, page, size, cat)
          .then(
              (data) => {
                  //// console.log('paginated templates: ', data);
                  dispatch(success(data));
              },
              error => {
                  const {response} = error;
                  const {data} = response;
                  dispatch(failure(data));
              }
          );
  };

  function request() {
    return { type: templateConstants.GET_SHARED_TEMPLATES_REQUEST };
  }

  function success(shared) {
    return { type: templateConstants.GET_SHARED_TEMPLATES_SUCCESS, shared };
  }

  function failure(error) {
    return { type: templateConstants.GET_SHARED_TEMPLATES_FAILURE, error };
  }
}

function getTemplateAccountCategories(projectId) {
    return dispatch => {
        dispatch(request(projectId));
        templateService.getAccountCategories(projectId)
            .then(
                (data) => {
                 // console.log('category templates: ', data);
                    dispatch(success(data));
                },
                error => {
                    const {response} = error;
                    const {data} = response;
                    dispatch(failure(data));
                }
            );
    };
  
    function request() {
      return { type: templateConstants.GET_TEMPLATES_CATEGORY_REQUEST };
    }
  
    function success(template) {
      return { type: templateConstants.GET_TEMPLATES_CATEGORY_SUCCESS, template };
    }
  
    function failure(error) {
      return { type: templateConstants.GET_TEMPLATES_CATEGORY_FAILURE, error };
    }
}

function getTemplateSharedCategories(projectId) {
  return dispatch => {
      dispatch(request(projectId));
      templateService.getSharedCategories(projectId)
          .then(
              (data) => {
                 // console.log('category templates: ', data);
                  dispatch(success(data));
              },
              error => {
                  const {response} = error;
                  const {data} = response;
                  dispatch(failure(data));
              }
          );
  };

  function request() {
    return { type: templateConstants.GET_TEMPLATES_CATEGORY_REQUEST };
  }

  function success(template) {
    return { type: templateConstants.GET_TEMPLATES_CATEGORY_SUCCESS, template };
  }

  function failure(error) {
    return { type: templateConstants.GET_TEMPLATES_CATEGORY_FAILURE, error };
  }
}

