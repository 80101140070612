import React from 'react';
import { MDBIcon } from 'mdbreact'
import { Button } from "blackbox-react";

class PresetsButton extends React.Component {
  constructor(props){
    super(props);
    this.state = {
      selectedInput: ''
    };

    this.handleCreateFormGroup = this.handleCreateFormGroup.bind(this);
  }

  handleSelectedInput = (inputState) => {
    this.props.handleSelectedInput(inputState);
  };

  handleCreateFormGroup = (type, groupIndex, groupTotalNum) => {

    let formData = JSON.parse(sessionStorage.getItem('form'));
    let formGroupData = formData.group;

    this.props.handleActiveFormGroup('');

    if(type === 0){
      if(groupIndex <= groupTotalNum){
        this.props.handleCreateFormGroupInBetween({
          name: 'New Form Group',
          layout: '1'
        }, formGroupData, groupIndex, groupTotalNum);
      } else {
        this.props.handleCreateFormGroup({
          name: 'New Form Group',
          layout: '1'
        });
      }
    } else{
      if(groupIndex <= groupTotalNum){
        this.props.handleCreateFormGroupFromPresetInBetween(type, groupIndex, groupTotalNum);
      } else {
        this.props.handleCreateFormFromPresetGroup(type);
      }
    }
  };

  render() {
    let { quickPresets } = this.props;
    let groupIndex = sessionStorage.getItem('groupIndex');
    let groupTotalNum = sessionStorage.getItem('groupTotalNum');

    return (
      quickPresets.map((value) => {
        return (
          <div key={value.buttonName} className='m--0 m-b--md text-center width--full'>
            <Button
              flat='true'
              className='dotted p--sm width--full background-transparent border-radius-5'
              onClick={() => {
                this.handleCreateFormGroup(value.id, groupIndex, groupTotalNum);
                this.props.handleSelectedInput(true);
              }}>
              <MDBIcon icon={value.icon} className='font-size-22 color-black' /> <br/>
              <span className='text--uppercase font-weight-bold font-size-12'>{value.buttonName}</span>
            </Button>
          </div>
        )
      })
    )
  }
}

export default PresetsButton;
