import React, { useState } from 'react'
import { Link } from 'react-router-dom'
import { MDBCol, MDBRow } from 'mdbreact';

// components
import Breadcrumbs from './components/Breadcrumbs'
import Button from './components/Button'
import BasicInfo from './components/View/BasicInfo';
import ClientInfo from './components/View/ClientInfo';
import StatusFilter from './components/View/StatusFilter';
import CategoryFilter from './components/View/CategoryFilter';
import ActivitySide from './components/View/ActivitySide';
import DeleteInfluencerModal from './components/Modals/DeleteInfluencerModal';
import AddSocialMediaModal from './components/Modals/AddSocialMediaModal';
import EditSocialMediaModal from './components/Modals/EditSocialMediaModal';
import AddRecordsModal from './components/Modals/AddRecordsModal';
import EditRecordsModal from './components/Modals/EditRecordsModal';
import AddNotesModal from './components/Modals/AddNotesModal';
import EditNotesModal from './components/Modals/EditNotesModal';
import ConfirmationDelete from './components/Modals/ConfirmationDelete';
import AddClientModal from './components/Modals/AddClientModal';
import EditClientModal from './components/Modals/EditClientModal';
import AddRateModal from './components/Modals/AddRateModal';
import EditRateModal from './components/Modals/EditRateModal';
import EditInfluencer from './components/Modals/EditInfluencerModal';

const ViewInfluencer = () => {
  
  // edit influencer modal
  const [isEditInfluencer, setIsEditInfluencer] = useState(false);
  const openEditInfluencer = () => {
    setIsEditInfluencer(!isEditInfluencer);
  }
  const closeEditInfluencer = () => setIsEditInfluencer(false);

  // influencer delete modal 
  const [isModalOpen, setIsModalOpen] = useState(false);
  const openDeleteModal = () => {
    setIsModalOpen(!isModalOpen);
    setIsAddRecords(false);
    setIsAddNotes(false);
    setIsEditRecords(false);
    setIsEditNotes(false);
    setIsConfirmDelete(false);
  }
  const closeDeleteModal = () => setIsModalOpen(false); 

  // add client modal
  const [isAddClient, setIsAddClient] = useState(false);
  const openAddClient = () => {
    setIsAddClient(!isAddClient);
  }
  const closeAddClient = () => setIsAddClient(false);
  // edit client modal
  const [isEditClient, setIsEditClient] = useState(false);
  const openEditClient = () => {
    setIsEditClient(!isEditClient);
  }
  const closeEditClient = () => setIsEditClient(false);


  // add rate modal
  const [isAddRate, setIsAddRate] = useState(false);
  const openAddRate = () => {
    setIsAddRate(!isAddRate);
  }
  const closeAddRate = () => setIsAddRate(false);
  // edit rate modal
  const [isEditRate, setIsEditRate] = useState(false);
  const openEditRate = () => {
    setIsEditRate(!isEditRate);
  }
  const closeEditRate = () => setIsEditRate(false);


  // add social media modal
  const [isAddSocialMedia, setIsAddSocialMedia] = useState(false);
  const openAddSocialMedia = () => {
    setIsAddSocialMedia(!isAddSocialMedia);
  }
  const closeAddSocialMedia = () => setIsAddSocialMedia(false);

  // edit social media modal
  const [isEditSocialMedia, setIsEditSocialMedia] = useState(false);
  const openEditSocialMedia = () => {
    setIsEditSocialMedia(!isEditSocialMedia);
  }
  const closeEditSocialMedia = () => setIsEditSocialMedia(false);


  // add records modal
  const [isAddRecords, setIsAddRecords] = useState(false);
  const openAddRecords = () => {
    setIsAddRecords(!isAddRecords);
  }
  const closeAddRecords = () => setIsAddRecords(false);

  // edit records modal
  const [isEditRecords, setIsEditRecords] = useState(false);
  const openEditRecords = () => {
    setIsEditRecords(!isEditRecords);
  }
  const closeEditRecords = () => setIsEditRecords(false);


  // add notes modal
  const [isAddNotes, setIsAddNotes] = useState(false);
  const openAddNotes = () => {
    setIsAddNotes(!isAddNotes);
  }
  const closeAddNotes = () => setIsAddNotes(false);

  // edit notes modal
  const [isEditNotes, setIsEditNotes] = useState(false);
  const openEditNotes = () => {
    setIsEditNotes(!isEditNotes);
  }
  const closeEditNotes = () => setIsEditNotes(false);

  // confirmation delete modal 
  const [isConfirmDelete, setIsConfirmDelete] = useState(false);
  const openConfirmDelete = () => {
    setIsConfirmDelete(!isModalOpen);
  }
  const closeConfirmDelete = () => setIsConfirmDelete(false);

  return (
    <>
        <div className='page-title-ctas'>
            <div className='row align-items-center'>
                <div className='col'>
                    <Breadcrumbs />
                </div>
                <div className='col'>   
                    <div className='button-group'>
                        <Button text='Edit Influencer' icon='fa-pen' onClick={() => openEditInfluencer()} />
                        <span className='custom-line'></span>
                        <Button text='Delete Influencer' icon='fa-trash-alt' onClick={() => openDeleteModal()} />
                    </div>
                </div>
            </div>
            <div className='row top-info-box'>
                <div className='col-box'>
                    <div className='avatar-text-box'>
                        <div className='avatar-custom avatar--color-1'>
                            <span>M</span>
                        </div>
                        <div className='text'>
                            <label>Created By</label>
                            <span>Marketing</span>
                        </div>
                    </div>
                </div>
                <div className='col-box'>
                    <div className='avatar-text-box'>
                        <div className='avatar-custom avatar--color-1'>
                            <span>M</span>
                        </div>
                        <div className='text'>
                            <label>Handle By</label>
                            <span>Marketing</span>
                        </div>
                    </div>
                </div>
                <div className='col-box'>
                    <div className='avatar-text-box'>
                        <div className='text'>
                            <label>Category</label>
                            <CategoryFilter />
                        </div>
                    </div>
                </div>
                <div className='col-box'>
                    <div className='avatar-text-box'>
                        <div className='text'>
                            <label>Status</label>
                            <StatusFilter />
                        </div>
                    </div>
                </div>
            </div>
        </div>
        <div className='influencer-container view-lead-main-content'>
            <MDBRow>
                <MDBCol md='5'>
                    <BasicInfo 
                        name='Cong TV'
                        dateContact='August 1, 2022'
                        rates='500'
                        email='congtv@gmail.com'
                        phone='0909123456'
                        rateModalAdd={() => openAddRate()}
                        rateModalEdit={() => openEditRate()}
                        confirmationDelete={() => openConfirmDelete()}
                    />
                </MDBCol>
                <MDBCol md='3'>
                    <ClientInfo 
                        clientModalAdd={() => openAddClient()}
                        clientModalEdit={() => openEditClient()}
                        confirmationDelete={() => openConfirmDelete()}
                    />
                </MDBCol>
                <MDBCol md='4'>
                    <ActivitySide 
                        socialMediaModalAdd={() => openAddSocialMedia()}
                        socialMediaModalEdit={() => openEditSocialMedia()}
                        recordsModalAdd={() => openAddRecords()}
                        recordsModalEdit={() => openEditRecords()}
                        notesModalAdd={() => openAddNotes()}
                        notesModalEdit={() => openEditNotes()}
                        ratesModalAdd={() => openAddRate()}
                        ratesModalEdit={() => openEditRate()}
                        confirmationDelete={() => openConfirmDelete()}
                    />
                </MDBCol>
            </MDBRow>
        </div>
        
        {/* Edit Influencer */}
        <EditInfluencer open={isEditInfluencer} toggle={setIsEditInfluencer} close={closeEditInfluencer} />
        {/* Delete Influencer Modal */}
        <DeleteInfluencerModal open={isModalOpen} toggle={setIsModalOpen} close={closeDeleteModal} />
        {/* Add Social Meadi Followers */}
        <AddSocialMediaModal open={isAddSocialMedia} toggle={setIsAddSocialMedia} close={closeAddSocialMedia} />
        {/* Edit Social Meadi Followers */}
        <EditSocialMediaModal open={isEditSocialMedia} toggle={setIsEditSocialMedia} close={closeEditSocialMedia} />
        {/* Add Records Modal */}
        <AddRecordsModal open={isAddRecords} toggle={setIsAddRecords} close={closeAddRecords} />
        {/* Edit Records Modal */}
        <EditRecordsModal open={isEditRecords} toggle={setIsEditRecords} close={closeEditRecords} />
        {/* Add Notes Modal */}
        <AddNotesModal open={isAddNotes} toggle={setIsAddNotes} close={closeAddNotes} />
        {/* Edit Notes Modal */}
        <EditNotesModal open={isEditNotes} toggle={setIsEditNotes} close={closeEditNotes} />
        {/* Confirm Delete Tabs */}
        <ConfirmationDelete open={isConfirmDelete} toggle={setIsConfirmDelete} close={closeConfirmDelete} />
        {/* Add Client Info */}
        <AddClientModal open={isAddClient} toggle={setIsAddClient} close={closeAddClient} />
        {/* Edit Client Info */}
        <EditClientModal open={isEditClient} toggle={setIsEditClient} close={closeEditClient} />
        {/* Add Rate */}
        <AddRateModal open={isAddRate} toggle={setIsAddRate} close={closeAddRate} />
        {/* Edit Rate */}
        <EditRateModal open={isEditRate} toggle={setIsEditRate} close={closeEditRate} />
    </>
  )
}

export default ViewInfluencer