import React from "react";

const TopBarLoader = () => {
  return (
    <div className="skeleton-loader skeleton-topbar-loader">
        <div className="col-b">
            <div className="skeleton skeleton-box"></div>
            <div className="skeleton skeleton-box circle-skeleton"></div>
            <div className="skeleton skeleton-box"></div>
        </div>
    </div>
  );
};

export default TopBarLoader;
