import {influencerConstants} from '_constants';
import {influencerService} from '_services';
import {alertActions} from './';
import {history} from '_helpers';

export const influencerActions = {
    getInfluencerSummary,
    getInfluencerList,
    createInfluencer,
    getInfluencer,
    updateInfluencer,
    updateInfluencerStatus,
    deleteInfluencer,
    addInfluencerPlatform,
    getInfluencerPlatform,
    updateInfluencerPlatform,
    deleteInfluencerPlatform,
    updateInfluencerClients,
    getInfluencerClients,
    updateInfluencerCategories,
    addInfluencerDocument,
    getInfluencerDocument,
    updateInfluencerDocument,
    deleteInfluencerDocument,
    addInfluencerRate,
    getInfluencerRates,
    updateInfluencerRate,
    deleteInfluencerRate,
    addInfluencerNote,
    getInfluencerNotes,
    updateInfluencerNote,
    deleteInfluencerNote,
    getInfluencerActivities,
    getClients,
    getPlatformTypes,
    getCategories,
    updateInfluencerResponsible,
}

function getInfluencerSummary(project_id, dateFrom = null, dateTo = null) {
    return dispatch => {
        dispatch(request(project_id));
        influencerService.getInfluencerSummary(project_id, dateFrom, dateTo)
            .then(
                summary => {
                    dispatch(success(summary));
                },
                error => {
                    dispatch(failure(error));
                    dispatch(alertActions.error(error));
                }
            );
    };

    function request(project_id) {
        return {type: influencerConstants.SUMMARY_REQUEST, project_id}
    }

    function success(summary) {
        return {type: influencerConstants.SUMMARY_SUCCESS, summary}
    }

    function failure(error) {
        return {type: influencerConstants.SUMMARY_FAILURE, error}
    }
}

function getInfluencerList(projectId, page = null, size = null, query= null, status = [], platform = [], category = [], sort = null, direction = null, dateFrom = null, dateTo = null) {
    return dispatch => {
        dispatch(request(projectId));
        influencerService.getInfluencerList(projectId, page, size, query, status, platform, category, sort , direction, dateFrom, dateTo)
            .then(
                influencers => {
                    dispatch(success(influencers));
                },
                error => {
                    dispatch(failure(error.toString()));
                    dispatch(alertActions.error(error.toString()));
                }
            );
    };

    function request(project_id) {
        return {type: influencerConstants.GET_ALL_REQUEST, project_id}
    }

    function success(influencers) {
        return {type: influencerConstants.GET_ALL_SUCCESS, influencers}
    }

    function failure(error) {
        return {type: influencerConstants.GET_ALL_FAILURE, error}
    }
}

function createInfluencer(project_id, influencer) {
    return dispatch => {
        dispatch(request(project_id, influencer));
        influencerService.createInfluencer(project_id, influencer)
            .then(
                influencer => {
                    history.push(`/project/${project_id}/influencers/${influencer.data.id}`);
                    dispatch(success(influencer));
                    dispatch(alertActions.success(`Successfully created influencer.`))
                },
                error => {
                    dispatch(failure(error));
                    dispatch(alertActions.error(error));
                }
            );
    };

    function request(project_id, influencer) {
        return {type: influencerConstants.CREATE_REQUEST, project_id, influencer}
    }

    function success(influencer) {
        return {type: influencerConstants.CREATE_SUCCESS, influencer}
    }

    function failure(error) {
        return {type: influencerConstants.CREATE_FAILURE, error}
    }
}

function getInfluencer(project_id, influencerId) {
    return dispatch => {
        dispatch(request(project_id, influencerId));
        influencerService.getInfluencer(project_id, influencerId)
            .then(
                influencer => {
                    dispatch(success(influencer));
                },
                error => {
                    dispatch(failure(error.toString()));
                    dispatch(alertActions.error(error.toString()));
                }
            );
    }

    function request(project_id, influencerId) {
        return {type: influencerConstants.GET_REQUEST, project_id, influencerId}
    }

    function success(influencer) {
        return {type: influencerConstants.GET_SUCCESS, influencer}
    }

    function failure(error) {
        return {type: influencerConstants.GET_FAILURE, error}
    }
}

function updateInfluencer(project_id, influencerId, influencer) {
    return dispatch => {
        dispatch(request(project_id, influencerId, influencer));
        influencerService.updateInfluencer(project_id, influencerId,  influencer)
            .then(
                influencer => {
                    dispatch(getInfluencerActivities(project_id, influencerId));
                    dispatch(success(influencer));
                    dispatch(alertActions.success(`Successfully updated influencer.`))
                },
                error => {
                    dispatch(failure(error));
                    dispatch(alertActions.error(error));
                }
            );
    };

    function request(project_id, influencerId, influencer) {
        return {type: influencerConstants.UPDATE_REQUEST, project_id, influencerId, influencer}
    }

    function success(influencer) {
        return {type: influencerConstants.UPDATE_SUCCESS, influencer}
    }

    function failure(error) {
        return {type: influencerConstants.UPDATE_FAILURE, error}
    }
}

function updateInfluencerStatus(project_id, influencerId, status) {
    return dispatch => {
        dispatch(request(project_id, influencerId, status));
        influencerService.updateInfluencerStatus(project_id, influencerId, status)
            .then(
                influencer => {
                    dispatch(getInfluencerActivities(project_id, influencerId));
                    dispatch(success(influencer));
                    dispatch(alertActions.success(`Successfully updated influencer status.`))
                },
                error => {
                    dispatch(failure(error));
                    dispatch(alertActions.error(error));
                }
            );
    };

    function request(project_id, influencerId, status) {
        return {type: influencerConstants.UPDATE_STATUS_REQUEST, project_id, influencerId, status}
    }

    function success(influencer) {
        return {type: influencerConstants.UPDATE_STATUS_SUCCESS, influencer}
    }

    function failure(error) {
        return {type: influencerConstants.UPDATE_STATUS_FAILURE, error}
    }
}

function deleteInfluencer(project_id, influencerId) {
    return dispatch => {
        dispatch(request(project_id, influencerId));
        influencerService.deleteInfluencer(project_id, influencerId)
            .then(
                influencers => {
                    dispatch(success(influencers));
                    dispatch(alertActions.success(`Successfully deleted influencer.`));
                    dispatch(getInfluencerList(project_id));
                    history.push(`/project/${project_id}/influencers`);
                },
                error => {
                    dispatch(failure(error));
                    dispatch(alertActions.error(error));
                }
            );
    };

    function request(project_id, influencerId) {
        return {type: influencerConstants.DELETE_REQUEST, project_id, influencerId}
    }

    function success(influencer) {
        return {type: influencerConstants.DELETE_SUCCESS, influencer}
    }

    function failure(error) {
        return {type: influencerConstants.DELETE_FAILURE, error}
    }
}

function addInfluencerPlatform(project_id, influencerId, platform) {
    return dispatch => {
        dispatch(request(project_id, influencerId, platform));
        influencerService.addInfluencerPlatform(project_id, influencerId,  platform)
            .then(
                platforms => {
                    dispatch(getInfluencerActivities(project_id, influencerId));
                    dispatch(success(platforms));
                    dispatch(alertActions.success(`Successfully added influencer platform.`))
                },
                error => {
                    dispatch(failure(error));
                    dispatch(alertActions.error(error));
                }
            );
    };

    function request(project_id, influencerId, platform) {
        return {type: influencerConstants.ADD_PLATFORM_REQUEST, project_id, influencerId, platform}
    }

    function success(platforms) {
        return {type: influencerConstants.ADD_PLATFORM_SUCCESS, platforms}
    }

    function failure(error) {
        return {type: influencerConstants.ADD_PLATFORM_FAILURE, error}
    }
}

function getInfluencerPlatform(project_id, influencerId) {
    return dispatch => {
        dispatch(request(project_id, influencerId));
        influencerService.getInfluencerPlatform(project_id, influencerId)
            .then(
                platforms => {
                    dispatch(success(platforms));
                },
                error => {
                    dispatch(failure(error));
                    dispatch(alertActions.error(error));
                }
            );
    };

    function request(project_id, influencerId) {
        return {type: influencerConstants.GET_ALL_PLATFORM_REQUEST, project_id, influencerId}
    }

    function success(platforms) {
        return {type: influencerConstants.GET_ALL_PLATFORM_SUCCESS, platforms}
    }

    function failure(error) {
        return {type: influencerConstants.GET_ALL_PLATFORM_FAILURE, error}
    }
}

function updateInfluencerPlatform(project_id, influencerId, platformId, platform) {
    return dispatch => {
        dispatch(request(project_id, influencerId, platformId, platform));
        influencerService.updateInfluencerPlatform(project_id, influencerId, platformId,  platform)
            .then(
                platforms => {
                    dispatch(getInfluencerActivities(project_id, influencerId));
                    dispatch(success(platforms));
                    dispatch(alertActions.success(`Successfully updated influencer platform.`))
                },
                error => {
                    dispatch(failure(error));
                    dispatch(alertActions.error(error));
                }
            );
    };

    function request(project_id, influencerId, platformId,  platform) {
        return {type: influencerConstants.UPDATE_PLATFORM_REQUEST, project_id, influencerId, platformId,  platform}
    }

    function success(platforms) {
        return {type: influencerConstants.UPDATE_PLATFORM_SUCCESS, platforms}
    }

    function failure(error) {
        return {type: influencerConstants.UPDATE_PLATFORM_FAILURE, error}
    }
}

function deleteInfluencerPlatform(project_id, influencerId, platformId) {
    return dispatch => {
        dispatch(request(project_id, influencerId, platformId));
        influencerService.deleteInfluencerPlatform(project_id, influencerId, platformId)
            .then(
                platforms => {
                    dispatch(getInfluencerActivities(project_id, influencerId));
                    dispatch(success(platforms));
                    dispatch(alertActions.success(`Successfully deleted influencer platform.`))
                },
                error => {
                    dispatch(failure(error));
                    dispatch(alertActions.error(error));
                }
            );
    };

    function request(project_id, influencerId, platformId) {
        return {type: influencerConstants.DELETE_PLATFORM_REQUEST, project_id, influencerId, platformId}
    }

    function success(platforms) {
        return {type: influencerConstants.DELETE_PLATFORM_SUCCESS, platforms}
    }

    function failure(error) {
        return {type: influencerConstants.DELETE_PLATFORM_FAILURE, error}
    }
}

function updateInfluencerClients(project_id, influencerId, clients) {
    return dispatch => {
        dispatch(request(project_id, influencerId, clients));
        influencerService.updateInfluencerClients(project_id, influencerId,  clients)
            .then(
                clients => {
                    dispatch(getInfluencerActivities(project_id, influencerId));
                    dispatch(success(clients));
                    dispatch(alertActions.success(`Successfully updated influencer clients.`))
                },
                error => {
                    dispatch(failure(error));
                    dispatch(alertActions.error(error));
                }
            );
    };

    function request(project_id, influencerId, client) {
        return {type: influencerConstants.UPDATE_CLIENT_REQUEST, project_id, influencerId, clients}
    }

    function success(clients) {
        return {type: influencerConstants.UPDATE_CLIENT_SUCCESS, clients}
    }

    function failure(error) {
        return {type: influencerConstants.UPDATE_CLIENT_FAILURE, error}
    }
}

function getInfluencerClients(project_id, influencerId) {
    return dispatch => {
        dispatch(request(project_id, influencerId));
        influencerService.getInfluencerClients(project_id, influencerId)
            .then(
                clients => {
                    dispatch(success(clients));
                },
                error => {
                    dispatch(failure(error));
                    dispatch(alertActions.error(error));
                }
            );
    };

    function request(project_id, influencerId) {
        return {type: influencerConstants.GET_ALL_CLIENT_REQUEST, project_id, influencerId}
    }

    function success(clients) {
        return {type: influencerConstants.GET_ALL_CLIENT_SUCCESS, clients}
    }

    function failure(error) {
        return {type: influencerConstants.GET_ALL_CLIENT_FAILURE, error}
    }
}

function updateInfluencerCategories(project_id, influencerId, categories) {
    return dispatch => {
        dispatch(request(project_id, influencerId, categories));
        influencerService.updateInfluencerCategories(project_id, influencerId,  categories)
            .then(
                categories => {
                    dispatch(getInfluencerActivities(project_id, influencerId));
                    dispatch(getInfluencer(project_id, influencerId));
                    dispatch(success(categories));
                    dispatch(alertActions.success(`Successfully updated influencer categories.`))
                },
                error => {
                    dispatch(failure(error));
                    dispatch(alertActions.error(error));
                }
            );
    };

    function request(project_id, influencerId, categories) {
        return {type: influencerConstants.UPDATE_CATEGORIES_REQUEST, project_id, influencerId, categories}
    }

    function success(categories) {
        return {type: influencerConstants.UPDATE_CATEGORIES_SUCCESS, categories}
    }

    function failure(error) {
        return {type: influencerConstants.UPDATE_CATEGORIES_FAILURE, error}
    }
}

function getInfluencerCategories(project_id, influencerId) {
    return dispatch => {
        dispatch(request(project_id, influencerId));
        influencerService.getInfluencerCategories(project_id, influencerId)
            .then(
                categories => {
                    dispatch(success(categories));
                },
                error => {
                    dispatch(failure(error));
                    dispatch(alertActions.error(error));
                }
            );
    };

    function request(project_id, influencerId) {
        return {type: influencerConstants.GET_ALL_CATEGORIES_REQUEST, project_id, influencerId}
    }

    function success(categories) {
        return {type: influencerConstants.GET_ALL_CATEGORIES_SUCCESS, categories}
    }

    function failure(error) {
        return {type: influencerConstants.GET_ALL_CATEGORIES_FAILURE, error}
    }
}

function addInfluencerDocument(project_id, influencerId, document) {
    return dispatch => {
        dispatch(request(project_id, influencerId, document));
        influencerService.addInfluencerDocument(project_id, influencerId,  document)
            .then(
                documents => {
                    dispatch(getInfluencerActivities(project_id, influencerId));
                    dispatch(success(documents));
                    dispatch(alertActions.success(`Successfully added influencer document.`))
                },
                error => {
                    dispatch(failure(error));
                    dispatch(alertActions.error(error));
                }
            );
    };

    function request(project_id, influencerId, document) {
        return {type: influencerConstants.ADD_DOCUMENT_REQUEST, project_id, influencerId, document}
    }

    function success(documents) {
        return {type: influencerConstants.ADD_DOCUMENT_SUCCESS, documents}
    }

    function failure(error) {
        return {type: influencerConstants.ADD_DOCUMENT_FAILURE, error}
    }
}

function getInfluencerDocument(project_id, influencerId) {
    return dispatch => {
        dispatch(request(project_id, influencerId));
        influencerService.getInfluencerDocument(project_id, influencerId)
            .then(
                documents => {
                    dispatch(success(documents));
                },
                error => {
                    dispatch(failure(error));
                    dispatch(alertActions.error(error));
                }
            );
    };

    function request(project_id, influencerId) {
        return {type: influencerConstants.GET_ALL_DOCUMENTS_REQUEST, project_id, influencerId}
    }

    function success(documents) {
        return {type: influencerConstants.GET_ALL_DOCUMENTS_SUCCESS, documents}
    }

    function failure(error) {
        return {type: influencerConstants.GET_ALL_DOCUMENTS_FAILURE, error}
    }
}

function updateInfluencerDocument(project_id, influencerId, documentId, document) {
    return dispatch => {
        dispatch(request(project_id, influencerId, documentId, document));
        influencerService.updateInfluencerDocument(project_id, influencerId, documentId,  document)
            .then(
                documents => {
                    dispatch(getInfluencerActivities(project_id, influencerId));
                    dispatch(success(documents));
                    dispatch(alertActions.success(`Successfully updated influencer document.`))
                },
                error => {
                    dispatch(failure(error));
                    dispatch(alertActions.error(error));
                }
            );
    };

    function request(project_id, influencerId, platformId, document) {
        return {type: influencerConstants.UPDATE_DOCUMENT_REQUEST, project_id, influencerId, platformId, document}
    }

    function success(documents) {
        return {type: influencerConstants.UPDATE_DOCUMENT_SUCCESS, documents}
    }

    function failure(error) {
        return {type: influencerConstants.UPDATE_DOCUMENT_FAILURE, error}
    }
}

function deleteInfluencerDocument(project_id, influencerId, documentId) {
    return dispatch => {
        dispatch(request(project_id, influencerId, documentId));
        influencerService.deleteInfluencerDocument(project_id, influencerId, documentId)
            .then(
                documents => {
                    dispatch(getInfluencerActivities(project_id, influencerId));
                    dispatch(alertActions.success(`Successfully deleted influencer document.`))
                    dispatch(success(documents));
                },
                error => {
                    dispatch(failure(error));
                    dispatch(alertActions.error(error));
                }
            );
    };

    function request(project_id, influencerId, documentId) {
        return {type: influencerConstants.DELETE_DOCUMENT_REQUEST, project_id, influencerId, documentId}
    }

    function success(documents) {
        return {type: influencerConstants.DELETE_DOCUMENT_SUCCESS, documents}
    }

    function failure(error) {
        return {type: influencerConstants.DELETE_DOCUMENT_FAILURE, error}
    }
}

function addInfluencerRate(project_id, influencerId, rate) {
    return dispatch => {
        dispatch(request(project_id, influencerId, rate));
        influencerService.addInfluencerRate(project_id, influencerId,  rate)
            .then(
                rates => {
                    dispatch(success(rates));
                    dispatch(alertActions.success(`Successfully added influencer rate.`))
                },
                error => {
                    dispatch(failure(error));
                    dispatch(alertActions.error(error));
                }
            );
    };

    function request(project_id, influencerId, rate) {
        return {type: influencerConstants.ADD_RATE_REQUEST, project_id, influencerId, rate}
    }

    function success(rates) {
        return {type: influencerConstants.ADD_RATE_SUCCESS, rates}
    }

    function failure(error) {
        return {type: influencerConstants.ADD_RATE_FAILURE, error}
    }
}

function getInfluencerRates(project_id, influencerId, rateId) {
    return dispatch => {
        dispatch(request(project_id, influencerId, rateId));
        influencerService.getInfluencerRates(project_id, influencerId, rateId)
            .then(
                rates => {
                    dispatch(success(rates));
                },
                error => {
                    dispatch(failure(error));
                    dispatch(alertActions.error(error));
                }
            );
    };

    function request(project_id, influencerId, rateId) {
        return {type: influencerConstants.GET_ALL_RATE_REQUEST, project_id, influencerId, rateId}
    }

    function success(rates) {
        return {type: influencerConstants.GET_ALL_RATE_SUCCESS, rates}
    }

    function failure(error) {
        return {type: influencerConstants.GET_ALL_RATE_FAILURE, error}
    }
}

function updateInfluencerRate(project_id, influencerId, rateId, rate) {
    return dispatch => {
        dispatch(request(project_id, influencerId, rateId, rate));
        influencerService.updateInfluencerRate(project_id, influencerId, rateId,  rate)
            .then(
                rates => {
                    dispatch(getInfluencerActivities(project_id, influencerId));
                    dispatch(success(rates));
                    dispatch(alertActions.success(`Successfully updated influencer rate.`))
                },
                error => {
                    dispatch(failure(error));
                    dispatch(alertActions.error(error));
                }
            );
    };

    function request(project_id, influencerId, rateId, rate) {
        return {type: influencerConstants.UPDATE_RATE_REQUEST, project_id, influencerId, rateId, rate}
    }

    function success(rates) {
        return {type: influencerConstants.UPDATE_RATE_SUCCESS, rates}
    }

    function failure(error) {
        return {type: influencerConstants.UPDATE_RATE_FAILURE, error}
    }
}

function deleteInfluencerRate(project_id, influencerId, rateId) {
    return dispatch => {
        dispatch(request(project_id, influencerId, rateId));
        influencerService.deleteInfluencerRate(project_id, influencerId, rateId)
            .then(
                rates => {
                    dispatch(getInfluencerActivities(project_id, influencerId));
                    dispatch(success(rates));
                    dispatch(alertActions.success(`Successfully deleted influencer rate.`))
                },
                error => {
                    dispatch(failure(error));
                    dispatch(alertActions.error(error));
                }
            );
    };

    function request(project_id, influencerId) {
        return {type: influencerConstants.DELETE_RATE_REQUEST, project_id, influencerId, rateId}
    }

    function success(rates) {
        return {type: influencerConstants.DELETE_RATE_SUCCESS, rates}
    }

    function failure(error) {
        return {type: influencerConstants.DELETE_RATE_FAILURE, error}
    }
}

function addInfluencerNote(project_id, influencerId, note) {
    return dispatch => {
        dispatch(request(project_id, influencerId, note));
        influencerService.addInfluencerNote(project_id, influencerId,  note)
            .then(
                rates => {
                    dispatch(success(rates));
                    dispatch(getInfluencerActivities(project_id, influencerId));
                    dispatch(alertActions.success(`Successfully added influencer note.`))
                },
                error => {
                    dispatch(failure(error));
                    dispatch(alertActions.error(error));
                }
            );
    };

    function request(project_id, influencerId, note) {
        return {type: influencerConstants.ADD_NOTE_REQUEST, project_id, influencerId, note}
    }

    function success(notes) {
        return {type: influencerConstants.ADD_NOTE_SUCCESS, notes}
    }

    function failure(error) {
        return {type: influencerConstants.ADD_NOTE_FAILURE, error}
    }
}

function getInfluencerNotes(project_id, influencerId) {
    return dispatch => {
        dispatch(request(project_id, influencerId));
        influencerService.getInfluencerNotes(project_id, influencerId)
            .then(
                notes => {
                    dispatch(success(notes));
                },
                error => {
                    dispatch(failure(error));
                    dispatch(alertActions.error(error));
                }
            );
    };

    function request(project_id, influencerId) {
        return {type: influencerConstants.GET_ALL_NOTES_REQUEST, project_id, influencerId}
    }

    function success(notes) {
        return {type: influencerConstants.GET_ALL_NOTES_SUCCESS, notes}
    }

    function failure(error) {
        return {type: influencerConstants.GET_ALL_NOTES_FAILURE, error}
    }
}

function updateInfluencerNote(project_id, influencerId, noteId, note) {
    return dispatch => {
        dispatch(request(project_id, influencerId, noteId, note));
        influencerService.updateInfluencerNote(project_id, influencerId, noteId,  note)
            .then(
                notes => {
                    dispatch(success(notes));
                    dispatch(getInfluencerActivities(project_id, influencerId));
                    dispatch(alertActions.success(`Successfully updated influencer note.`))
                },
                error => {
                    dispatch(failure(error));
                    dispatch(alertActions.error(error));
                }
            );
    };

    function request(project_id, influencerId, noteId, note) {
        return {type: influencerConstants.UPDATE_NOTE_REQUEST, project_id, influencerId, noteId, note}
    }

    function success(notes) {
        return {type: influencerConstants.UPDATE_NOTE_SUCCESS, notes}
    }

    function failure(error) {
        return {type: influencerConstants.UPDATE_NOTE_FAILURE, error}
    }
}

function deleteInfluencerNote(project_id, influencerId, noteId) {
    return dispatch => {
        dispatch(request(project_id, influencerId, noteId));
        influencerService.deleteInfluencerNote(project_id, influencerId, noteId)
            .then(
                notes => {
                    dispatch(success(notes));
                    dispatch(getInfluencerActivities(project_id, influencerId));
                    dispatch(alertActions.success(`Successfully deleted influencer note.`))
                },
                error => {
                    dispatch(failure(error));
                    dispatch(alertActions.error(error));
                }
            );
    };

    function request(project_id, influencerId, noteId) {
        return {type: influencerConstants.DELETE_NOTE_REQUEST,project_id, influencerId, noteId}
    }

    function success(notes) {
        return {type: influencerConstants.DELETE_NOTE_SUCCESS, notes}
    }

    function failure(error) {
        return {type: influencerConstants.DELETE_NOTE_FAILURE, error}
    }
}

function getInfluencerActivities(project_id, influencerId) {
    console.log('get activities');
    return dispatch => {
        dispatch(request(project_id, influencerId));
        influencerService.getInfluencerActivities(project_id, influencerId)
            .then(
                activities => {
                    dispatch(success(activities));
                },
                error => {
                    dispatch(failure(error));
                    dispatch(alertActions.error(error));
                }
            );
    };

    function request(project_id, influencerId) {
        return {type: influencerConstants.GET_ALL_ACTIVITIES_REQUEST, project_id, influencerId}
    }

    function success(activities) {
        return {type: influencerConstants.GET_ALL_ACTIVITIES_SUCCESS, activities}
    }

    function failure(error) {
        return {type: influencerConstants.GET_ALL_ACTIVITIES_FAILURE, error}
    }
}

function getClients(project_id) {
    return dispatch => {
        dispatch(request(project_id));
        influencerService.getClients(project_id)
            .then(
                clients => {
                    dispatch(success(clients));
                },
                error => {
                    dispatch(failure(error));
                    dispatch(alertActions.error(error));
                }
            );
    };

    function request(project_id) {
        return {type: influencerConstants.GET_CLIENT_LIST_REQUEST, project_id}
    }

    function success(clients) {
        return {type: influencerConstants.GET_CLIENT_LIST_SUCCESS, clients}
    }

    function failure(error) {
        return {type: influencerConstants.GET_CLIENT_LIST_FAILURE, error}
    }
}

function getPlatformTypes(project_id) {
    return dispatch => {
        dispatch(request(project_id));
        influencerService.getPlatformTypes(project_id)
            .then(
                platforms => {
                    dispatch(success(platforms));
                },
                error => {
                    dispatch(failure(error));
                    dispatch(alertActions.error(error));
                }
            );
    };

    function request(project_id) {
        return {type: influencerConstants.GET_PLATFORM_TYPE_REQUEST, project_id}
    }

    function success(platforms) {
        return {type: influencerConstants.GET_PLATFORM_TYPE_SUCCESS, platforms}
    }

    function failure(error) {
        return {type: influencerConstants.GET_PLATFORM_TYPE_FAILURE, error}
    }
}

function getCategories(project_id) {
    return dispatch => {
        dispatch(request(project_id));
        influencerService.getCategories(project_id)
            .then(
                categories => {
                    dispatch(success(categories));
                },
                error => {
                    dispatch(failure(error));
                    dispatch(alertActions.error(error));
                }
            );
    };

    function request(project_id) {
        return {type: influencerConstants.CATEGORY_LIST_REQUEST, project_id}
    }

    function success(categories) {
        return {type: influencerConstants.CATEGORY_LIST_SUCCESS, categories}
    }

    function failure(error) {
        return {type: influencerConstants.CATEGORY_LIST_FAILURE, error}
    }
}

function updateInfluencerResponsible(project_id, influencerId, influencer) {
    return dispatch => {
        dispatch(request(project_id, influencerId, influencer));
        influencerService.updateInfluencerResponsible(project_id, influencerId, influencer)
            .then(
                influencer => {
                    dispatch(getInfluencerActivities(project_id, influencerId));
                    dispatch(success(influencer));
                    dispatch(alertActions.success(`Successfully updated responsible.`))
                },
                error => {
                    dispatch(failure(error));
                    dispatch(alertActions.error(error));
                }
            );
    };

    function request(project_id, influencerId, influencer) {
        return {type: influencerConstants.UPDATE_REQUEST, project_id, influencerId, influencer}
    }

    function success(influencer) {
        return {type: influencerConstants.UPDATE_SUCCESS, influencer}
    }

    function failure(error) {
        return {type: influencerConstants.UPDATE_FAILURE, error}
    }
}








