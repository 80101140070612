import React from "react";
import DestinationInfo from "./DestinationInfo";

export let TableHeaderGroupMain = [
  {
    id: "expander", // Make sure it has an ID
    Header: "GROUP",
    className: "normal-item toggle-item",
    width: 100,
    minWidth: 100,
    Cell: ({ row }) => (
      // Use Cell to render an expander for each row.
      // We can use the getToggleRowExpandedProps prop-getter
      // to build the expander.
      <div className={row.isExpanded ? "toggle-group active" : "toggle-group"} {...row.getToggleRowExpandedProps()}>
        <div className="toggle-icon">{row.isExpanded ? <i className="fas fa-chevron-down"></i> : <i className="fas fa-chevron-right"></i> }</div>
        <span>{row.original.group}</span>
      </div>
    )
  },
  {
    Header: 'DATES',
    className: 'normal-item',
    width: 100,
    minWidth: 100,
    Cell: ({ row }) => {
      let dates = row.original.data.map((d) => d.created);
      let unique = [...new Set(dates)];
      return(
        <span>{unique.join(',')}</span>
      );
    }
  },
  {
    Header: 'LABELS',
    className: 'normal-item',
    width: 300,
    minWidth: 300,
    Cell: ({ row }) => {
      let raw = row.original.data.map((d) => d.label);
      let labels = [...new Set(raw)];

      return(
        <div className="labels-box">
          {
            labels.map((l, index) => {
              return(
                <span key={index}>{l}</span>
              );
            })
          }
        </div>
      );
    }
  },
];

export let TableHeaderGroupInner = [
  {
    Header: "DESTINATION URL/S",
    className: "destination-box",
    // sticky: 'left',
    minWidth: 400,
    width: 400,
    Cell: ({ row }) => {
      let destinationInfo = row.original; 
      return (
        <DestinationInfo destinationInfo={destinationInfo} />
      );
    },
  },
  {
    Header: 'GROUP',
    className: 'normal-item',
    minWidth: 100,
    width: 100,
    Cell: ({ row }) => {

      return(
          <span >{row.original.group? row.original.group.name : 'ungrouped'}</span>
      );
    }
  },
  {
    Header: 'STATUS',
    className: 'normal-item status-box',
    minWidth: 100,
    width: 100,
    Cell: ({ row }) => {
      "status-inner status-color-1"
      return(
        <div className={row.original.status === "used" ? "status-inner status-color-1" :  "status-inner status-color-2"}>
            <span className={`status-text`}>
              {row.original.status}
            </span>
      </div>
      );
    }
  },
  {
    Header: 'LABEL',
    className: 'normal-item',
    minWidth: 200,
    width: 200,
    Cell: ({ row }) => {
      let raw = row.original.label
      console.log('inner', row);
      let labels = raw ? raw.split(',') : [];
      return(
          <div className="labels-box">
            {
              labels.map((l, index) => {
                return(
                    <span key={index}>{l}</span>
                );
              })
            }
          </div>
      );
    }
  },
  {
    Header: 'CAMPAIGN',
    className: 'normal-item',
    minWidth: 200,
    width: 200,
    Cell: ({ row }) => {

      return(
          <span className="w-break">{row.original.campaign}</span>
      );
    }
  },
  {
    Header: 'MEDIUM',
    className: 'normal-item',
    minWidth: 120,
    width: 120,
    Cell: ({ row }) => {

      return(
          <span >{row.original.medium}</span>
      );
    }
  },
  {
    Header: 'SOURCE',
    className: 'normal-item',
    minWidth: 120,
    width: 120,
    Cell: ({ row }) => {

      return(
          <span >{row.original.source}</span>
      );
    }
  },
  {
    Header: 'CONTENT',
    className: 'normal-item',
    minWidth: 120,
    width: 120,
    Cell: ({ row }) => {

      return(
          <span >{row.original.content}</span>
      );
    }
  },
  {
    Header: 'TERM',
    className: 'normal-item',
    minWidth: 150,
    width: 150,
    Cell: ({ row }) => {

      return(
          <span className="w-break">{row.original.keyword}</span>
      );
    }
  },
  {
    Header: 'NOTES',
    className: 'normal-item',
    minWidth: 200,
    width: 200,
    Cell: ({ row }) => {
      return(
          <span >{row.original.notes}</span>
      );
    }
  },
];