import React, {Component} from 'react'
import {influencerActions} from "_actions";
import {connect} from "react-redux";


class KpiControlAdvanceFilter extends Component {

    constructor(props) {
        super(props);
        this.filterRef = React.createRef();

        this.state = {
            filterToggled: false,
            checkedStatus: [],
            checkedPlatforms: [],
            checkedCategories: [],
            items: [],
            openFilterGroupIndex: null,
            filterGroupHeight: 0,
        }

        this.handleStatusChange = this.handleStatusChange.bind(this);
        this.handlePlatformChange = this.handlePlatformChange.bind(this);
        this.handleCategoryChange = this.handleCategoryChange.bind(this);
    }

    handleFilterToggle() {
        this.setState({filterToggled: !this.state.filterToggled})
    }

    handleOpenFilter() {
        this.setState({filterToggled: true});
    }

    handleCloseFilter() {
        this.setState({filterToggled: false})
    }

    handleDropdown = value => {
        if (!this.filterRef.current.contains(value.target)) {
            this.setState({filterToggled: false})
        }
    }

    handleStatusChange(e) {
        const {value, checked} = e.target;
        let checkedItems = [...this.state.checkedStatus, parseInt(value)];
        if (!checked) {
            checkedItems = checkedItems.filter(x => x !== parseInt(value));
        }

        this.setState({checkedStatus: checkedItems});
    }

    handlePlatformChange(e) {
        const {value, checked} = e.target;
        let checkedItems = [...this.state.checkedPlatforms, parseInt(value)];
        if (!checked) {
            checkedItems = checkedItems.filter(x => x !== parseInt(value));
        }

        this.setState({checkedPlatforms: checkedItems});
    }

    handleCategoryChange(e) {
        const {value, checked} = e.target;
        let checkedItems = [...this.state.checkedCategories, parseInt(value)];
        if (!checked) {
            checkedItems = checkedItems.filter(x => x !== parseInt(value));
        }

        this.setState({checkedCategories: checkedItems});
    }

    handleApplyFilter = () => {
        const {checkedStatus, checkedPlatforms, checkedCategories} = this.state;
        this.props.onApplyFilter(checkedStatus, checkedPlatforms, checkedCategories);
        this.handleCloseFilter();
    }

    handleSelectAllFilter = (items) => {
        let {checkedItems} = this.state;
        items.forEach(i => {
            if (!checkedItems.includes(i.id)) {
                checkedItems.push(i.id);
            }
        });
        this.setState({checkedItems: checkedItems});
    }

    handleUnSelectAllFilter = () => {
        this.setState({checkedItems: []});
    }

    handleReset() {
        this.setState({
            checkedStatus: [],
            checkedPlatforms: [],
            checkedCategories: []
        });

        this.props.onApplyFilter([], [], []);
    }

    handleToggleFilterGroup = (index) => {
        this.setState((prevState) => ({
            openFilterGroupIndex: prevState.openFilterGroupIndex === index ? null : index
        }));
    };

    componentDidUpdate(prevProps) {
        if (prevProps.resetFilterState !== this.props.resetFilterState && this.props.resetFilterState) {
          // Perform the necessary state reset 
          console.log('called here')
          this.setState({
            checkedStatus: [],
            checkedPlatforms: [],
            checkedCategories: []
        });

         this.props.onHandleResetFilter();
        }
      }

    componentDidMount() {
        document.addEventListener('click', this.handleDropdown, true);

        const {projectId, getCategories, getPlatformTypes} = this.props;
        getCategories(projectId);
        getPlatformTypes(projectId);
    }

    componentWillUnmount() {
        document.removeEventListener('click', this.handleDropdown, true);
    }

    render() {


        const {influencers, getPlatFormTypeLoaded, getCategoryListLoaded, influencerListLoaded} = this.props;
        const { checkedCategories, checkedStatus, checkedPlatforms, openFilterGroupIndex } = this.state;
        const statusList = [
            {
                id: 1,
                name: "Onboarded"
            },
            {
                id: 2,
                name: "Inquired"
            },
            {
                id: 3,
                name: "Blacklisted"
            },
            {
                id: 4,
                name: "Collaborated"
            },
            {
                id: 5,
                name: "Ongoing Collaboration"
            },
            {
                id: 6,
                name: "Received Information"
            },
        ];

        let categoryList = [];
        let platformTypes = [];
        
        if(getPlatFormTypeLoaded) {
            platformTypes = influencers.platformTypes;
        }

        if(getCategoryListLoaded) {
            categoryList = influencers.categoryList;
        }

        let applyClass = 'show';

        if(influencerListLoaded) {
            applyClass = checkedCategories.length > 0 || checkedStatus.length > 0  || checkedPlatforms.length > 0  ? 'show' : 'hide';
        }

            return(
                <div className='filter-box influencers-filter'  ref={this.filterRef}>
                    <div className='filter-btn' onClick={() => this.handleOpenFilter()}>
                        <i className='icon-lms icon-filter'></i>
                        Filters
                        <span>{checkedCategories.length + checkedStatus.length + checkedPlatforms.length}</span>
                    </div>
                    <div className={this.state.filterToggled ? 'filter-dropdown active' : 'filter-dropdown'}>
                        <div className='filter-title'>
                            <p>Filters</p>
                            <i className='icon-lms icon-close' onClick={() => this.handleCloseFilter()}></i>
                        </div>

                        <div className='filter-list'>

                            <div className={openFilterGroupIndex === 0 ? 'filter-group active' : 'filter-group'}>
                                <div className='filter-name' onClick={() => this.handleToggleFilterGroup(0)}>
                                    <span>Category ({categoryList.length})</span>
                                    <i className='icon-lms icon-arrow-down'></i>
                                    <span className={checkedCategories.length > 0 ? "count active" : "count"}>{checkedCategories.length}</span>
                                </div>
                                <div className='filter-check-list'
                                style={
                                    openFilterGroupIndex === 0
                                    ? { maxHeight: this.filterGroupHeight }
                                    : { maxHeight: "0" }
                                }>
                                    {categoryList.map((category, index)=> {
                                        return( 
                                        <div className='check-col' key={index}>
                                            <div className='checkbox-group' >
                                                <input type='checkbox'  checked={checkedCategories.includes(category.id)} name={category.code} id={category.code} value={category.id} onChange={this.handleCategoryChange} />
                                                <label htmlFor={category.code} >{category.name}</label>
                                            </div>
                                        </div>
                                        );
                                    })}
                                </div>
                            </div>


                            <div className={openFilterGroupIndex === 1 ? 'filter-group active' : 'filter-group'}>
                                <div className='filter-name' onClick={() => this.handleToggleFilterGroup(1)}>
                                    <span>Platform ({platformTypes.length})</span>
                                    <i className='icon-lms icon-arrow-down'></i>
                                    <span className={checkedPlatforms.length > 0 ? "count active" : "count"}>{checkedPlatforms.length}</span>
                                </div>
                                <div className='filter-check-list'
                                style={
                                    openFilterGroupIndex === 1
                                    ? { maxHeight: this.filterGroupHeight }
                                    : { maxHeight: "0" }
                                }>
                                    {platformTypes.map((platform, index) => {
                                        return(
                                            <div className='check-col' key={index}>
                                                <div className='checkbox-group'>
                                                    <input type='checkbox' checked={checkedPlatforms.includes(platform.id)} name={platform.name} id={platform.name} value={platform.id} onChange={this.handlePlatformChange} />        
                                                    <label htmlFor={platform.name}>{platform.name}</label>
                                                </div>
                                            </div>
                                        );
                                    })}
                                </div>
                            </div>

                            <div className={openFilterGroupIndex === 2 ? 'filter-group active' : 'filter-group'}>
                                <div className='filter-name' onClick={() => this.handleToggleFilterGroup(2)}>
                                    <span>Status ({statusList.length})</span>
                                    <i className='icon-lms icon-arrow-down'></i>
                                    <span className={checkedStatus.length > 0 ? "count active" : "count"}>{checkedStatus.length}</span>
                                </div>
                                <div className='filter-check-list'
                                style={
                                    openFilterGroupIndex === 2
                                    ? { maxHeight: this.filterGroupHeight }
                                    : { maxHeight: "0" }
                                }>
                                    {statusList.map((status, index) => {
                                        return(
                                            <div className='check-col' key={index}>
                                                <div className='checkbox-group'>
                                                    <input type='checkbox' checked={checkedStatus.includes(status.id)} name={status.name} id={status.name} value={status.id} onChange={this.handleStatusChange} />
                                                    <label htmlFor={status.name}>{status.name}</label>
                                                </div>
                                            </div>
                                        );
                                    })}
                                </div>
                            </div>
                        </div>

                        <div className='filter-control-box'>
                            <span className='btn-reset' onClick={() => this.handleReset()}>
                                Clear Filter
                            </span>

                            <span className='btn-apply' onClick={() => this.handleApplyFilter()}>
                                Apply Filter
                            </span>
                        </div>
                    </div>
                </div>);
    }
}


function mapState(state) {
    const {influencers} = state;
    const {influencerListLoaded} = state.influencers;
    const {getPlatFormTypeLoaded, getCategoryListLoaded} = influencers;
    return {influencers, getPlatFormTypeLoaded, getCategoryListLoaded, influencerListLoaded};
}

const actionCreators = {
    getPlatformTypes: influencerActions.getPlatformTypes,
    getCategories: influencerActions.getCategories,
};

const connectedViewKpiControlAdvanceFilter = connect(mapState, actionCreators)(KpiControlAdvanceFilter);
export {connectedViewKpiControlAdvanceFilter as KpiControlAdvanceFilter};