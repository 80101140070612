import React, {Component, useRef } from 'react';
import {connect} from "react-redux";
import {leadActions, projectActions} from "_actions";
import {influencerActions} from "_actions/influencer.actions";
import KpiStatusInfo from "_pages/Propelrr/components/Kpi/KpiStatusInfo";
import KpiResultInfo from "_pages/Propelrr/components/Kpi/KpiResultInfo";
import ClickStatusInfo from "_pages/Leads/components/Kpi/ClickStatusInfo";
import HoverStatusInfo from "_pages/Leads/components/Kpi/HoverStatusInfo";
import {abbreviateNumber, getInfluencerTypeClassIcon} from "_services";
import { KpiHeaderFilter } from './KpiHeaderFilter';
import { Swiper, SwiperSlide } from 'swiper/react';
import { FreeMode, Navigation } from "swiper";
import KpiLoader from '../../../../_components/Loader/KpiLoader';

class KpiHeader extends Component {
    
    constructor(props) {
        super(props);

        this.kpiRef = React.createRef();
        this.filterKpiRef = React.createRef();

        this.state = {
            toggleKpi: true,
            reset: true,
            kpiTotalHeight: 0

        }

    }

    componentDidMount() {

        const {projectId, getInfluencerSummary} = this.props;
        getInfluencerSummary(projectId);

        this.kpiTotalHeight = this.kpiRef.current.clientHeight;

    }

    componentWillUnmount() {
    }

    handleToggleKpi () {
        this.setState({toggleKpi: !this.state.toggleKpi});
        this.props.onHandleKpiHeaderHeightChanged(!this.state.toggleKpi);
    }

    handleReset() {
        this.props.onHandleReset();
    }

    handleKpiHeaderFilter(group, value, color) {
        this.props.onHandleKpiHeaderFilter(group, value, color);
    }
    


    render() {

        const {summary, isSummaryLoaded, clickedBox, isKpiHeaderExpanded}= this.props;

        let grouped = [];
        let ids = [];
        let categories = [];
        let ungrouped = [];
        let total = 0;

        let activeClass = isKpiHeaderExpanded ? 'active' : '';

        if(isSummaryLoaded){
            ungrouped = summary;

            grouped = ungrouped.reduce(function (collection, elem) {
                let key = elem['category'];
                if(!categories.includes(key)){
                    categories.push(key);
                    ids[key] = elem.id;
                }

                (collection[key] = collection[key] || []).push(elem);
                return collection;
            }, []);

            total = 0;
            ids['Total Summary'] = 0;
            grouped['Total Summary'].forEach(c => {
                total = total + c.count;
            })
        }


        return (



                <React.Fragment>
                    <div className={`overview-kpi-box influencer-kpi ${activeClass}`} ref={this.kpiRef}
                    style={
                        isKpiHeaderExpanded
                        ? { maxHeight: this.kpiTotalHeight }
                        : { maxHeight: "0" }
                    }>
                        <div className='main-box'>
                            <div className='status-list-box'>
                                <div className='status-list'>
                                    <Swiper
                                        watchSlidesProgress = {true}
                                        observer = {true}
                                        observeParents = {true}
                                        observeSlideChildren = {true}
                                        resizeObserver= {true}
                                        slidesPerView={"auto"}
                                        spaceBetween={12}
                                        freeMode={true}
                                        modules={[FreeMode, Navigation]}
                                        className="kpiSwiper"
                                        navigation={{ nextEl: ".nextSlide", prevEl: ".prevSlide" }}
                                        onSlideChange={(swiper) => swiper.update()}
                                    >
                                        <SwiperSlide>
                                            <div className='box'  onClick={() => this.handleReset()}>
                                                    <KpiStatusInfo
                                                        title='Total Influencers'
                                                        description='Lorem ipsum dolor sit amet consectetur adipisicing elit. Culpa, autem.'
                                                    />
                                                    <div className='status-result'>
                                                        <KpiResultInfo
                                                            total={total}
                                                            info='Total Influencers'
                                                        />
                                                    </div>
                                            </div>
                                        </SwiperSlide>
                                            {categories.map((value,index) => {
                                                let color = index + 1;
                                                let platform = ["total", "facebook", "instagram", "twitter", "youtube", "tiktok", "kumu", "others"];
                                                let platformClass = platform[index % platform.length];

                                                return(
                                                    <SwiperSlide key={index}>
                                                        <div className={clickedBox === value ?  `box kpi-color-${platformClass} active` : `box kpi-color-${platformClass}`} onClick={() => this.handleKpiHeaderFilter(ids[value], value, color)}>
                                                                <KpiStatusInfo
                                                                    title={value}
                                                                    description={value}
                                                                />
                                                                <div className='status-result'>
                                                                    {grouped[value].map((item, index) => {
                                                                        return(
                                                                            <KpiResultInfo key={index}
                                                                                total={item.count}
                                                                                info={item.label}
                                                                            />);
                                                                    })}
                                                                </div>
                                                        </div>
                                                    </SwiperSlide>
                                                );
                                            })}
                                    </Swiper>
                                    <button className='swiper-custom-btn prevSlide'><i className="icon-lms icon-arrow-left"></i></button>
                                    <button className='swiper-custom-btn nextSlide'><i className="icon-lms icon-arrow-right"></i></button>
                                </div>
                            </div>
                        </div>
                        {
                            !isSummaryLoaded &&
                            <KpiLoader />
                        }
                    </div>
                </React.Fragment>  
            )
    }
}

function mapState(state) {
    const {summary, isSummaryLoaded}  = state.influencers
    return {summary, isSummaryLoaded};
}


const actionCreators = {
    getInfluencerSummary: influencerActions.getInfluencerSummary,
};

const connectedViewKpiHeader = connect(mapState, actionCreators)(KpiHeader);
export { connectedViewKpiHeader as KpiHeader };
