import React, {Component} from 'react'
import {influencerActions} from "_actions";
import {connect} from "react-redux";


class KpiHeaderFilter extends Component {

    constructor(props) {
        super(props);
        this.filterHeaderRef = React.createRef();

        this.state = {
            filterHeaderToggled: false,
            checkedStatus: [],
            checkedPlatforms: [],
            checkedCategories: [],
            items: [],
        }

        this.handlePlatformChange = this.handlePlatformChange.bind(this);
    }

    handleOpenFilterHeader() {
        this.setState({filterHeaderToggled: !this.state.filterHeaderToggled});
    }

    handleCloseFilterHEader() {
        this.setState({filterHeaderToggled: false})
    }

    handleDropdown = value => {
        if (!this.filterHeaderRef.current.contains(value.target)) {
            this.setState({filterHeaderToggled: false})
        }
    }

    handlePlatformChange(e) {
        const {value, checked} = e.target;
        let checkedItems = [...this.state.checkedPlatforms, parseInt(value)];
        if (!checked) {
            checkedItems = checkedItems.filter(x => x !== parseInt(value));
        }

        this.setState({checkedPlatforms: checkedItems});
    }

    handleApplyFilter = () => {
        const {checkedStatus, checkedPlatforms, checkedCategories} = this.state;
        this.props.onApplyFilter(checkedStatus, checkedPlatforms, checkedCategories);
        this.handleCloseFilterHEader();
    }

    handleReset() {
        this.setState({
            checkedStatus: [],
            checkedPlatforms: [],
            checkedCategories: []
        });

        this.props.onApplyFilter([], [], []);
    }

    componentDidMount() {
        document.addEventListener('click', this.handleDropdown, true);
    }

    componentWillUnmount() {
        document.removeEventListener('click', this.handleDropdown, true);
    }

    render() {

        const {influencers, disableButtonControls, getPlatFormTypeLoaded, influencerListLoaded} = this.props;
        const { checkedCategories, checkedStatus, checkedPlatforms } = this.state;

        let platformTypes = [];

        if(getPlatFormTypeLoaded) {
            platformTypes = influencers.platformTypes;
        }

        let applyClass = 'show';

        if(influencerListLoaded) {
            applyClass = checkedPlatforms.length > 0  ? 'show' : 'hide';
        }

            return(
                <div className='filter-box'  ref={this.filterHeaderRef}>
                    <div className='filter-btn'>
                        <button
                            onClick={() => this.handleOpenFilterHeader()}
                            className={disableButtonControls ? 'icon-text-btn disabled' : 'icon-text-btn'}>
                            <i className="fas fa-sliders-h"></i>
                            <span>Filters</span>
                        </button>
                    </div>
                    <div className={this.state.filterHeaderToggled ? 'filter-dropdown active' : 'filter-dropdown'}>
                        <div className='filter-title'>
                            <p>Filters</p>
                            <i className='fas fa-times' onClick={() => this.handleCloseFilterHEader()}></i>
                        </div>

                        <div className='filter-list'>
                            {platformTypes.map((platform, index) => {
                                return(
                                    <div className='checkbox-group' key={index}>
                                        <input type='checkbox' checked={checkedPlatforms.includes(platform.id)} name={'k-' + platform.name} id={'k-' + platform.name} value={platform.id} onChange={this.handlePlatformChange} />        
                                        <label htmlFor={'k-' + platform.name}>{platform.name}</label>
                                    </div>
                                );
                             })}
                            {/* <div className='checkbox-group'>
                                <input type='checkbox' name='facebook' id='facebook' value={1} onChange={this.handlePlatformChange} />
                                <label htmlFor='facebook' >Facebook</label>
                            </div>
                            <div className='checkbox-group'>
                                <input type='checkbox' name='instagram' id='instagram' value={2} onChange={this.handlePlatformChange} />
                                <label htmlFor='instagram' >Instagram</label>
                            </div>
                            <div className='checkbox-group'>
                                <input type='checkbox' name='twitter' id='twitter' value={3} onChange={this.handlePlatformChange} />
                                <label htmlFor='twitter' >Twitter</label>
                            </div>
                            <div className='checkbox-group'>
                                <input type='checkbox' name='youtube' id='youtube' value={4} onChange={this.handlePlatformChange} />
                                <label htmlFor='youtube' >YouTube</label>
                            </div>
                            <div className='checkbox-group'>
                                <input type='checkbox' name='tiktok' id='tiktok' value={5} onChange={this.handlePlatformChange} />
                                <label htmlFor='tiktok' >TikTok</label>
                            </div>
                            <div className='checkbox-group'>
                                <input type='checkbox' name='kumu' id='kumu' value={6} onChange={this.handlePlatformChange} />
                                <label htmlFor='kumu' >Kumu</label>
                            </div>
                            <div className='checkbox-group'>
                                <input type='checkbox' name='others' id='others' value={7} onChange={this.handlePlatformChange} />
                                <label htmlFor='others' >Others</label>
                            </div> */}
                        </div>

                        <div className='filter-btn-group'>

                            <button className='bordered-btn btn-reset' onClick={() => this.handleReset()}>
                                <i className="fas fa-redo"></i>
                                Reset
                            </button>

                            <button className='background-btn btn-apply' onClick={() => this.handleApplyFilter()}>
                                <i className="fas fa-check"></i>
                                Apply
                            </button>


                        </div>
                    </div>
                </div>);
    }
}


function mapState(state) {
    const {influencers} = state;
    const {influencerListLoaded} = state.influencers;
    const {getPlatFormTypeLoaded, getCategoryListLoaded} = influencers;
    return {influencers, getPlatFormTypeLoaded, getCategoryListLoaded, influencerListLoaded};
}

const actionCreators = {
    getPlatformTypes: influencerActions.getPlatformTypes,
    getCategories: influencerActions.getCategories,
};

const connectedViewKpiHeaderFilter = connect(mapState, actionCreators)(KpiHeaderFilter);
export {connectedViewKpiHeaderFilter as KpiHeaderFilter};