export const addButtons = [
  {
    icon: 'user', buttonName: 'Full Name',
    fields: {
      id: 'fullname',
      formName: 'Full Name',
      inputFields: [
        { label: 'First Name', type: 'Text Input', placeholder: 'Enter your first name', instructions: '' },
        { label: 'Middle Name', type: 'Text Input', placeholder: 'Enter your middle name' },
        { label: 'Last Name', type: 'Text Input', placeholder: 'Enter your last name' },
      ]
    },
  },
  {
    icon: 'envelope', buttonName: 'Email Address',
    fields: {
      id: 'email',
      formName: 'Email Address',
      inputFields: [
        { label: 'Email Address', type: 'Text Input', placeholder: 'Enter your email address' },
      ]
    },
  },
  {
    icon: 'phone', buttonName: 'Phone',
    fields: {
      id: 'phone',
      formName: 'Phone Number',
      inputFields: [
        { label: 'Telephone Number', type: 'Text Input', placeholder: 'Enter your telephone number' },
      ]
    },
  },
  {
    icon: 'mobile-alt', buttonName: 'Mobile',
    fields: {
      id: 'mobile',
      formName: 'Mobile Number',
      inputFields: [
        { label: 'Mobile Number', type: 'Text Input', placeholder: 'Enter your mobile number' },
      ]
    },
  },
  {
    icon: 'home', buttonName: 'Address',
    fields: {
      id: 'address',
      formName: 'Address',
      inputFields: [
        { label: 'Address', type: 'Text Input', placeholder: 'Enter your address', dropdownItems: [{ value: 'sample1' }, { value: 'sample1' }, { value: 'sample1' }] },
      ]
    },
  },
  { id: 'textInput', icon: 'text-width', buttonName: 'Text' },
  { id: 'multipleTextInput', icon: 'text-height', buttonName: 'Multiline Text Input' },
  { id: 'singleChoice', icon: 'dot-circle', buttonName: 'Single Choice' },
  { id: 'multipleChoice', icon: 'check-square', buttonName: 'Multiple Choice' },
  { id: 'dropdownSelection', icon: 'chevron-circle-down', buttonName: 'Dropdown Selection' },
  { id: 'switch', icon: 'toggle-on', buttonName: 'Switch' },
  { id: 'slider', icon: 'sliders-h', buttonName: 'Slider Range' },
  { id: 'dateAndTime', icon: 'calendar-alt', buttonName: 'Date and Time' },
  { id: 'apiDropdownSelection', icon: 'chevron-circle-down', buttonName: 'API Dropdown Selection' }
];

