import React from 'react'
import Moment from "react-moment";
import {utilitiesService} from "_services";
import MainInfoLoader from '../../../../_components/Loader/MainInfoLoader';

const BasicInfo = (
    { 
        name, contacted, email, contact,handler, validIdType, validIdNo, address, influencerBasicInfoLoaded,
        influencerBasicInfoLoading
    }
) => {

    let handlerName = '';
    let handlerEmail = '';
    let handlerContact = '';

    if(handler) {
        handlerName = utilitiesService.decrypt(handler.name);
        handlerEmail = utilitiesService.decrypt(handler.email);
        handlerContact = utilitiesService.decrypt(handler.contact);
    }


  return (
    <>
        <div className='basic-info-box box-white-custom'>
            <div className='title-box'>
                <i className="icon-lms icon-user"></i>
                 <span>
                    Influencer Information
                 </span>
            </div>

            <div className='custom-scroll-box'>

                {influencerBasicInfoLoaded ? <React.Fragment>
                <div className='row info-group'>
                    <div className='col-md-12'>
                    <label>Influencer Name</label>
                                <span>
                                    { name }
                                </span>
                    </div>
                    <div className='col-md-12'>
                    <label>Email</label>
                                <span>
                                    { email }
                                </span>
                    </div>
                    <div className='col-md-12'>
                    <label>Phone Number</label>
                                <span>
                                    {contact}
                                </span>
                    </div>
                    <div className='col-md-12'>
                    <label>Date Contacted</label>
                                <span>
                                    {contacted &&<Moment date={contacted} format='LL'/>}
                                </span>
                    </div>
                    <div className='col-md-12'>
                        <label>Valid Id Provided</label>
                        <span>
                            { validIdType }
                        </span>
                    </div>
                    <div className='col-md-12'>
                        <label>Valid Id Number</label>
                        <span>
                            { validIdNo }
                        </span>
                    </div>

                    <div className='col-md-12'>
                        <label>Address</label>
                        <span>
                            { address }
                        </span>
                    </div>
                </div>
                </React.Fragment>
                : <MainInfoLoader />
                }

                {influencerBasicInfoLoading && <div className="loading-box">
                        <div className='loader-box'>
                            <div className="loader-custom">
                                <span></span>
                                <span></span>
                                <span></span>
                                <span></span>
                                <span></span>
                            </div>
                        </div>
                </div>}
            </div>
        </div>
        <div className='basic-info-box box-white-custom'>
            <div className='title-box'>
                <i className="icon-lms icon-user"></i>
                 <span>
                    Handler Information
                 </span>
            </div>

            <div className='custom-scroll-box'>

                {influencerBasicInfoLoaded ? <React.Fragment>
                
                {handler &&
                    <div className='row info-group'>
                        <div className='col-md-12'>
                        <label>Handler Name</label>
                                    <span>
                                    {handlerName}
                                </span>
                        </div>
                        <div className='col-md-12'>
                        <label>Handler Email</label>
                                    <span>
                                   {handlerEmail}
                                </span>
                        </div>
                        <div className='col-md-12'>
                        <label>Handler Phone</label>
                                    <span>
                                    {handlerContact}
                                </span>
                        </div>
                    </div>
                }
                </React.Fragment>
                : <MainInfoLoader />
                }

                {influencerBasicInfoLoading && <div className="loading-box">
                        <div className='loader-box'>
                            <div className="loader-custom">
                                <span></span>
                                <span></span>
                                <span></span>
                                <span></span>
                                <span></span>
                            </div>
                        </div>
                </div>}
            </div>
        </div>
    </>
  )
}

export default BasicInfo