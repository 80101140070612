import React, {Component} from 'react'
import {Link} from 'react-router-dom'
import {leadActions, projectActions} from "_actions";
import {connect} from "react-redux";
import {projects} from "_reducers/projects.reducer";
import {getLeadIdentifier} from "_services/utilities.service";
import TextLoader from '_components/Loader/TextLoader';

class BreadcrumbPage extends Component {


    render() {

        const {projects, leads } = this.props;
        const {leadLoaded} = leads;

        const project = projects.project;
        const lead = leads.lead;
        let leadIdentifier = '';
        if(lead) {
            leadIdentifier = getLeadIdentifier(lead.data.identifier, lead.data.identifier_type, lead.data.basic_info);
        }

        return (
            <div className='breadcrumbs'>
                {project &&<Link to={`/project/${project.data.id}/leads`} className='back'>
                    <span className='icon-s'><i className="icon-lms icon-arrow-left"></i></span>
                </Link>}

                {!project &&<Link  className='back'>
                    <span className='icon-s'><i className="icon-lms icon-arrow-left"></i></span>
                </Link>}

                {/* <Link to='/'>
                    Projects<i>/</i>
                </Link>

                {project && <Link to={`/project/${project.data.id}`} className='link'>
                    {project.data.info.name}<i>/</i>
                </Link>} */}

                {project &&<Link to={`/project/${project.data.id}/leads`} className='link'>
                    Leads<i>/</i>
                </Link>}


                {!project &&<Link className='link'>
                    Leads<i>/</i>
                </Link>}

                {lead && leadLoaded && <span>
                    {leadIdentifier}
                </span>}
                {
                    !leadLoaded && <TextLoader />
                }
            </div>
        )
    }
}

function mapState(state) {
    const {projects, leads} = state
    return {projects, leads};
}

const actionCreators = {
    getProject: projectActions.getProject,
    getLead: projectActions.getLead,
};

const connectedViewBreadcrumbPage = connect(mapState, actionCreators)(BreadcrumbPage);
export {connectedViewBreadcrumbPage as BreadcrumbPage};