import React from 'react'

const InputForm = ({ defaultValue, required, label, type, id, name, error, onChange, readOnly, placeholder}) => {
  return (
    <div className='field-box'>
      <label>{label} {required && <small>*</small>}</label>
      <input  hidden={label==='Call Link'} defaultValue={defaultValue} value={defaultValue} type={type} id={id} name={name} className='c-input' onChange={onChange} readOnly={readOnly} placeholder={placeholder} />
      <textarea hidden={label!=='Call Link'}  defaultValue={defaultValue} value={defaultValue} id={id} name={name} className='c-input' onChange={onChange} placeholder={placeholder} />
      <p className='error-field'>{error}</p>
    </div>
  )
}

export default InputForm