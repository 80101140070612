import React, { useState, useRef } from "react";

const DestinationInfo = ({ destinationInfo }) => {

  const [clicked, setClicked] = useState(false);
  const contentEl = useRef();
  const handleToggle = (e) => {
    e.stopPropagation();
    setClicked((prev) => !prev);
  };

  return (
    <div className="destination-box">
      <div className="destination-summary">
        <div className="destination-col">
            <div className="main-info">
                <span>{destinationInfo.bitly}</span>
            </div>
            <div className="other-info">
                <span className="url-w-hover">
                <i className="far fa-clone"></i> {destinationInfo.utm_url.split("?")[0]}
                <span>{destinationInfo.utm_url}</span>
                </span>
                <span>{destinationInfo.created}</span>
            </div>
        </div>
        <div className="destination-col">
            <button onClick={handleToggle}>
            {
                clicked 
                ?
                <>
                    <span>Hide Info</span>
                    <i className="fas fa-chevron-up"></i>
                </>
                :
                <>
                    <span>View Info</span>
                    <i className="fas fa-chevron-down"></i>
                </>
            } 
            </button>
        </div>
      </div>
      <div className={clicked ? "destination-others active" : "destination-others"} ref={contentEl}
        style={
            clicked
            ? { height: contentEl.current.scrollHeight }
            : { height: "0px" }
        }>
        <div className="other-info">
            <div className="other-info-details">
              <div>
                <span>Ad Version</span>
              </div>
              <div>
                <span>{destinationInfo.ad_version}</span>
              </div>
            </div>
            <div className="other-info-details">
              <div>
                <span>Campaign Objective</span>
              </div>
              <div>
                <span>{destinationInfo.campaign_objective}</span>
              </div>
            </div>
            <div className="other-info-details">
              <div>
                <span>Campaign Audience Description</span>
              </div>
              <div>
                <span>{destinationInfo.campaign_audience_desc}</span>
              </div>
            </div>
            <div className="other-info-details">
              <div>
                <span>Campaign Audience Type</span>
              </div>
              <div>
                <span>{destinationInfo.campaign_audience_type}</span>
              </div>
            </div>
            <div className="other-info-details">
              <div>
                <span>Country</span>
              </div>
              <div>
                <span>{destinationInfo.country}</span>
              </div>
            </div>
            <div className="other-info-details">
              <div>
                <span>Created</span>
              </div>
              <div>
                <span>{destinationInfo.created}</span>
              </div>
            </div>
        </div>
      </div>
    </div>
  );
};

export default DestinationInfo;
