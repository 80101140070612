import React, { Component } from "react";
import { influencerActions, projectActions } from "_actions";
import { connect } from "react-redux";
import {
  MDBContainer,
  MDBRow,
  MDBCol,
  MDBModal,
  MDBModalHeader,
  MDBModalBody,
} from "mdbreact";
import FormGroupTitle from "_pages/Propelrr/components/Form/FormGroupTitle";
import InputForm from "_pages/Propelrr/components/Form/InputForm";
import { utilitiesService } from "_services";

class AddInfluencerDrawer extends Component {
  constructor(props) {
    super(props);

    this.state = {
      name: "",
      email: "",
      contact: "",
      contacted: "",
      handlerName: "",
      handlerEmail: "",
      handlerContact: "",
      toggleTabState: 1,
      isModalOpen: true,
      isSubmitting: false,
      nameError: "",
      emailError: "",
    };
  }

  setName(value) {
    this.setState({ name: value });
  }

  setEmail(value) {
    this.setState({ email: value });
  }

  setContact(value) {
    this.setState({ contact: value });
  }

  setContacted(value) {
    this.setState({ contacted: value });
  }

  setHandlerName(value) {
    this.setState({ handlerName: value });
  }

  setHandlerEmail(value) {
    this.setState({ handlerEmail: value });
  }

  setHandlerContact(value) {
    this.setState({ handlerContact: value });
  }

  handleClose() {
    const { onHandleClose } = this.props;
    onHandleClose();
  }

  handleValidEmail = (email) => {
    const emailRegex = /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;

    return emailRegex.test(email) === false;

  }

  handleAddLead() {
    const { projects, createInfluencer } = this.props;
    const {
      name,
      email,
      contact,
      contacted,
      handlerName,
      handlerContact,
      handlerEmail,
    } = this.state;

    // Reset the error messages
    this.setState({
      nameError: "",
      emailError: ""
    });

    let isValid = true;

    // Validate name field
    if (name.trim() === '') {
      this.setState({
        nameError: 'Name is required.'
      });
      isValid = false;
    }
  
    // Validate email field
    if (email.trim() === '') {
      this.setState({
        emailError: 'Email is required.'
      });
      isValid = false;
    } else if (this.handleValidEmail(email)) {
      this.setState({
        emailError: 'Invalid email format.'
      });
      isValid = false;
    }

    if (isValid) {
        const influencer = {
          name: name,
          email: utilitiesService.encrypt(email),
          contacted: contacted,
          contact: utilitiesService.encrypt(contact),
          handler_name: utilitiesService.encrypt(handlerName),
          handler_contact: utilitiesService.encrypt(handlerContact),
          handler_email: utilitiesService.encrypt(handlerEmail)
        };
        createInfluencer(projects.project.data.id, influencer);
        this.setState({ isSubmitting: true });
      }
  }

  toggleTab = (index) => {
    this.setState({ toggleTabState: index });
  };

  handleCloseModal = () => {
    this.setState({ isModalOpen: false });
  };

  render() {
    const { open, toggle, isAddInfluencerDrawer, onHandleAddInfluencerDrawer } =
      this.props;

    let activeClass = isAddInfluencerDrawer ? "active" : "";

    return (
      <React.Fragment>
        <div
          className={`drawer-overlay ${activeClass}`}
          onClick={onHandleAddInfluencerDrawer}
        ></div>
        <div className={`side-drawer-main ${activeClass}`}>
          <div className="side-drawer-wrapper">
            <div className="side-drawer-title">
              <h3>Add New Influencer</h3>
              <span
                className="side-drawer-close"
                onClick={onHandleAddInfluencerDrawer}
              >
                <i className="icon-lms icon-close"></i>
              </span>
            </div>
            <div className="side-drawer-tabs">
              <div className="side-drawer-tabs-wrapper">
                <div className="control-tabs-box">
                  <div
                    className={
                      this.state.toggleTabState === 1
                        ? "tabs-btn active"
                        : "tabs-btn"
                    }
                    onClick={() => this.toggleTab(1)}
                  >
                    <p>Influencer Information</p>
                  </div>
                </div>
                <div className="content-tabs-box">
                  {
                    this.state.isSubmitting
                    ?
                    <div className='loading-box'>
                      <div class="lds-ellipsis"><div></div><div></div><div></div><div></div></div>
                    </div>
                    :
                    <div
                      className={
                        this.state.toggleTabState === 1
                          ? "tabs-content active"
                          : "tabs-content"
                      }
                    >
                      <div className="forms-tab">
                        <MDBRow>
                          <MDBCol xl="12" lg="12" className="form-group-box">
                            <FormGroupTitle label="Influencer" />
                            <div className="input-group-box full-box-">
                              <InputForm
                                required={true}
                                label="Name"
                                type="text"
                                id="name"
                                name="name"
                                onChange={(e) => this.setName(e.target.value)}
                                error={this.state.nameError}
                              />
                            </div>
                            <FormGroupTitle label="Contact Details" />
                            <div className="input-group-box full-box-">
                              <InputForm
                                required={true}
                                label="Email Address"
                                type="email"
                                id="email"
                                name="email"
                                onChange={(e) => this.setEmail(e.target.value)}
                                error={this.state.emailError}
                              />
                              <InputForm
                                label="Phone Number"
                                type="tel"
                                id="contact"
                                name="contact"
                                onChange={(e) => this.setContact(e.target.value)}
                              />
                              <InputForm
                                label="Date Contacted"
                                type="date"
                                id="contacted"
                                name="contacted"
                                onChange={(e) =>
                                  this.setContacted(e.target.value)
                                }
                              />
                            </div>
                            <FormGroupTitle label="Handler Details" />
                            <div className="input-group-box full-box-">
                              <InputForm
                                label="Handler Name"
                                type="text"
                                id="handler_name"
                                name="handler_name"
                                onChange={(e) =>
                                  this.setHandlerName(e.target.value)
                                }
                              />
                              <InputForm
                                label="Email Address"
                                type="email"
                                id="handler_email"
                                name="handler_email"
                                onChange={(e) =>
                                  this.setHandlerEmail(e.target.value)
                                }
                              />
                              <InputForm
                                label="Phone Number"
                                type="tel"
                                id="handler_contact"
                                name="handler_contact"
                                onChange={(e) =>
                                  this.setHandlerContact(e.target.value)
                                }
                              />
                            </div>
                          </MDBCol>
                        </MDBRow>
                      </div>
                    </div>
                  }
                </div>
                <div className="tab-form-btn-group">
                  <MDBRow>
                    <MDBCol className="text-right">
                      <span
                        className="drawer-btn cancel-btn"
                        onClick={onHandleAddInfluencerDrawer}
                      >
                        Cancel
                      </span>
                      {
                        this.state.isSubmitting
                        ?
                        <span
                          className="drawer-btn cancel-btn c-btn-disabled"
                          onClick={() => this.handleAddLead()}
                          disabled={true}
                        >
                          Adding Influencer ...
                        </span>
                        :
                        <span
                          className="drawer-btn save-btn"
                          onClick={() => this.handleAddLead()}
                        >
                          Add Influencer
                        </span>
                      }
                    </MDBCol>
                  </MDBRow>
                </div>
              </div>
            </div>
          </div>
        </div>
        <MDBContainer className="modal-container confirmation-modal-container">
          <MDBRow>
            <MDBCol>
              <MDBModal
                className="confirmation-modal"
                isOpen={this.isModalOpen}
                centered
                tabIndex="-1"
              >
                {/* <MDBModalHeader className='confirmation-modal-header text-uppercase p-2'><span className='pl-1 mx-2 letter-spacing-1 font-weight-600 font-color-gray font-size-13'>CONFIRM DELETE {type.toUpperCase()}</span></MDBModalHeader> */}
                <MDBModalBody>
                  <MDBRow className="form-group-row">
                    <MDBCol className="col-12">
                      <h3>Influencer Exist Already</h3>
                      <p>
                        Email: Test@gmail.com <br />
                        Name: Test Account
                      </p>
                    </MDBCol>
                  </MDBRow>
                  <MDBRow>
                    <MDBCol className="text-center">
                      <button
                        className="modal-btn delete-btn-modal"
                        onClick={this.handleCloseModal}
                      >
                        Close
                      </button>
                    </MDBCol>
                  </MDBRow>
                </MDBModalBody>
              </MDBModal>
            </MDBCol>
          </MDBRow>
        </MDBContainer>
      </React.Fragment>
    );
  }
}

function mapState(state) {
  const { projects } = state;
  return { projects };
}

const actionCreators = {
  getProject: projectActions.getProject,
  createInfluencer: influencerActions.createInfluencer,
};

const connectedViewAddInfluencerDrawer = connect(
  mapState,
  actionCreators
)(AddInfluencerDrawer);
export { connectedViewAddInfluencerDrawer as AddInfluencerDrawer };
