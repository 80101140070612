import { httpManagerService as httpManager } from  '_services';

export const templateService = {
    getTemplates,
    getTemplate,
    createTemplate,
    updateTemplate,
    removeTemplate,
    removeTemplates,
    shareTemplate,
    unshareTemplate,
    duplicateTemplate,
    getSharedTemplates,
    getSharedTemplatePaginated,
    getTemplatesDropdown,
    getAccountCategories,
    getSharedCategories
}

function getTemplates(projectId, query = null, page = 1, size = 15, cat=''){

    let parts = [];
    if(query !== null && query !== '') {
        parts.push(`query=${query}`);
    }

    if(page != null && page > 0) {
        parts.push(`page=${page}`);
    }

    if(size != null && size > 0) {
        parts.push(`size=${size}`);
    }

    if(cat != null && cat !== '') {
        parts.push(`cat=${cat}`);
    }


    return httpManager.getHttpWithRequestingToken(`/api/v2/project/${projectId}/template?` + parts.join('&',));
}

function getTemplate(projectId, templateId){
    return httpManager.getHttpWithRequestingToken(`/api/v2/project/${projectId}/template/${templateId}`);
}

function getTemplatesDropdown(projectId, templateId){
    return httpManager.getHttpWithRequestingToken(`/api/v2/project/${projectId}/template/dropdown`);
}


function createTemplate(projectId, request){
    return httpManager.postJsonOnce(`/api/v2/project/${projectId}/template`, request);
}

function updateTemplate(projectId, templateId, request){
    return httpManager.putJsonOnce(`/api/v2/project/${projectId}/template/${templateId}`, request);
}

function removeTemplate(projectId, templateId){
    return httpManager.deleteJsonOnce(`/api/v2/project/${projectId}/template/${templateId}`);
}

function removeTemplates(projectId, request){
    return httpManager.deleteJsonOnce(`/api/v2/project/${projectId}/templates`, request);
}

function shareTemplate(projectId, templateId){
    return httpManager.putJsonOnce(`/api/v2/project/${projectId}/template/${templateId}/shared`);
}

function unshareTemplate(projectId, templateId){
    return httpManager.putJsonOnce(`/api/v2/project/${projectId}/template/${templateId}/unshared`);
}

function duplicateTemplate(projectId, templateId, request){
    return httpManager.postJsonOnce(`/api/v2/project/${projectId}/template/${templateId}`, request);
}

function getSharedTemplates(projectId){
    return httpManager.getHttpWithRequestingToken(`/api/v2/project/${projectId}/shared/template`);
}

function getSharedTemplatePaginated(projectId, query = null, page = 1, size = 15, cat=''){

    // console.log(query);
    let parts = [];
    if(query !== null && query !== '') {
        parts.push(`query=${query}`);
    }

    if(page != null && page > 0) {
        parts.push(`page=${page}`);
    }

    if(size != null && size > 0) {
        parts.push(`size=${size}`);
    }

    if(cat != null && cat !== '') {
        parts.push(`cat=${cat}`);
    }

    return httpManager.getHttpWithRequestingToken(`/api/v2/project/${projectId}/shared/template?` + parts.join('&',));
}


function getAccountCategories(projectId){
    return httpManager.getHttpWithRequestingToken(`/api/v2/project/${projectId}/template/category/account`);
}

function getSharedCategories(projectId){
    return httpManager.getHttpWithRequestingToken(`/api/v2/project/${projectId}/template/category/shared`);
}