export const deckConstants = {

    GET_ALL_DECK_REQUEST: 'GET_ALL_DECK_REQUEST',
    GET_ALL_DECK_SUCCESS: 'GET_ALL_DECK_SUCCESS',
    GET_ALL_DECK_FAILURE: 'GET_ALL_DECK_FAILURE',

    UPDATE_DECK_REQUEST: 'UPDATE_DECK_REQUEST',
    UPDATE_DECK_SUCCESS: 'UPDATE_DECK_SUCCESS',
    UPDATE_DECK_FAILURE: 'UPDATE_DECK_FAILURE',

    CREATE_DECK_REQUEST: 'CREATE_DECK_REQUEST',
    CREATE_DECK_SUCCESS: 'CREATE_DECK_SUCCESS',
    CREATE_DECK_FAILURE: 'CREATE_DECK_FAILURE',

    DELETE_DECK_REQUEST: 'DELETE_DECK_REQUEST',
    DELETE_DECK_SUCCESS: 'DELETE_DECK_SUCCESS',
    DELETE_DECK_FAILURE: 'DELETE_DECK_FAILURE',



}