import { httpManagerService as httpManager } from  '_services';

export const formService = {
  getForms,
  getForm,
  getDefaultForm,
  createForm,
  updateForm,
  deleteForm,
  deleteMultipleForm,
  updateFormStatus,
  getFormMessage,
  updateFormMessage,
  sendTestEmailMessage,
  findEmailTemplate,
  selectEmailTemplate,
  getAllFormGroup,
  createFormGroup,
  findFormGroup,
  updateFormGroup,
  deleteFormGroup,
  enableFormGroup,
  reorderGroup,
  getAllField,
  getAllFields,
  createField,
  findField,
  updateField,
  deleteField,
  enableField,
  reorderField,
  getAllCustomField,
  createCustomField,
  findCustomField,
  updateCustomField,
  deleteCustomField,
  enableCustomField,
  reorderCustomField,
  findAllField,
  updateIdentifier,
  updateSubIdentifier,
  getAllFieldOption,
  createFieldOption,
  findFieldOption,
  updateFieldOption,
  deleteFieldOption,
  getAllPreset,
  createPreset,
  findPreset,
  updatePreset,
  getAllTemplate,
  createTemplate,
  findTemplate,
  updateTemplate,
  getFormAccessKey,
  getFormIntegration,
  updateFormIntegration
};

function getForms(project_id) {
  return httpManager.getHttpWithRequestingToken(`/api/project/${project_id}/form`);
}

function getForm(project_id, form_id) {
  return httpManager.getHttpWithRequestingToken(`/api/project/${project_id}/form/${form_id}`);
}

function getDefaultForm(project_id) {
  return httpManager.getHttpWithRequestingToken(`/api/project/${project_id}/form/default`);
}

function createForm(form, project_id) { //create --
  return httpManager.postJsonOnce(`/api/project/${project_id}/form`, form);
}

function updateForm(form, form_id, project_id) { //update --
  return httpManager.putJsonOnce(`/api/project/${project_id}/form/${form_id}`, form);
}

function deleteForm(form, project_id, form_id) { //delete --
  return httpManager.deleteJsonWithRequestingToken(`/api/project/${project_id}/form/${form_id}`, form);
}

function deleteMultipleForm(form, project_id) {
  return httpManager.deleteJsonWithRequestingToken(`/api/project/${project_id}/form/delete-selected`, form);
}

function updateFormStatus(project_id, form_id, status) { // added to update the status of the form if it is enabled or disable --
  return httpManager.putJsonOnce(`/api/project/${project_id}/form/${form_id}/status/${status}`);
}

function getFormMessage(project_id, form_id) {
  return httpManager.getHttpWithRequestingToken(`/api/project/${project_id}/form/${form_id}/message`);
}

function updateFormMessage(project_id, form_id, message) { // added to update form message in the form builder
  return httpManager.putJsonOnce(`/api/project/${project_id}/form/${form_id}/message`, message);
}

function sendTestEmailMessage(project_id, form_id) { //added to send a test email message to the provided email address in updateFormMessage
  return httpManager.putJsonOnce(`/api/project/${project_id}/form/${form_id}/message/send-email`);
}

function findEmailTemplate(project_id, form_id, layout_id) { //added to find message layout available -- might change when things get finalized in the backend
  return httpManager.getHttpWithRequestingToken(`/api/project/${project_id}/form/${form_id}/message/layout/${layout_id}`);
}

function selectEmailTemplate(project_id, form_id, layout_id){ //added to select message layout available -- might change when things get finalized in the backend
  return httpManager.getHttpWithRequestingToken(`/api/project/${project_id}/form/${form_id}/message/layout/${layout_id}`);
}

function getAllFormGroup(project_id, form_id) { //added to find all group in a form --
  return httpManager.getHttpWithRequestingToken(`/api/project/${project_id}/form/${form_id}/group`);
}

function createFormGroup(project_id, form_id, group) { //added to create a group in a form --
  return httpManager.postJsonOnce(`/api/project/${project_id}/form/${form_id}/group`, group);
}

function findFormGroup(project_id, form_id, group_id) { //added to find a group in a form --
  return httpManager.getHttpWithRequestingToken(`/api/project/${project_id}/form/${form_id}/group/${group_id}`);
}

function updateFormGroup(project_id, form_id, group_id, group) { //added to update a group in a form --
  return httpManager.putJsonOnce(`/api/project/${project_id}/form/${form_id}/group/${group_id}`, group);
}

function deleteFormGroup(project_id, form_id, group_id, group) { //added to delete a group in a form --
  return httpManager.deleteJsonWithRequestingToken(`/api/project/${project_id}/form/${form_id}/group/${group_id}`, group);
}

function enableFormGroup(project_id, form_id, group_id, status) { //added to update the status of the form group if it is enable or disable --
  return httpManager.putJsonOnce(`/api/project/${project_id}/form/${form_id}/group/${group_id}/status/${status}`)
}

function reorderGroup(project_id, form_id, reorder) { //added to update the order of the form group within the form builder --
  return httpManager.putJsonOnce(`/api/project/${project_id}/form/${form_id}/group/reorder`, reorder)
}

function getAllField(project_id, form_id, group_id) { //added to find all field in a group
  return httpManager.getHttpWithRequestingToken(`/api/project/${project_id}/form/${form_id}/group/${group_id}/field`);
}

function getAllFields(project_id, form_id) { //added to find all field in a group
  return httpManager.getHttpWithRequestingToken(`/api/project/${project_id}/form/${form_id}/field`);
}

function createField(project_id, form_id, group_id, field) { //added to create a field in a group
  return httpManager.postJsonOnce(`/api/project/${project_id}/form/${form_id}/group/${group_id}/field`, field);
}

function findField(project_id, form_id, group_id, field_id) { //added to find a field in a group
  return httpManager.getHttpWithRequestingToken(`/api/project/${project_id}/form/${form_id}/group/${group_id}/field/${field_id}`);
}

function updateField(project_id, form_id, group_id, field_id, field) { //added to update a field in a group
  return httpManager.putJsonOnce(`/api/project/${project_id}/form/${form_id}/group/${group_id}/field/${field_id}`, field);
}

function deleteField(project_id, form_id, group_id, field_id, field) { //added to delete a field in a group
  return httpManager.deleteJsonWithRequestingToken(`/api/project/${project_id}/form/${form_id}/group/${group_id}/field/${field_id}`, field);
}

function enableField(project_id, form_id, group_id, field_id, status) { //added to update the status of the group field if it is enable or disable
  return httpManager.putJsonOnce(`/api/project/${project_id}/form/${form_id}/group/${group_id}/field/${field_id}/status/${status}`);
}

function reorderField(project_id, form_id, group_id, reorder) { //added to update the order of the group field within the form builder
  return httpManager.putJsonOnce(`/api/project/${project_id}/form/${form_id}/group/${group_id}/field/reorder`, reorder);
}

function getAllCustomField(project_id, form_id) { //added to get all custom field within the form
  return httpManager.getHttpWithRequestingToken(`/api/project/${project_id}/form/${form_id}/custom-field`);
}

function createCustomField(project_id, form_id, field) { //added to create a custom field within the form
  return httpManager.postJsonOnce(`/api/project/${project_id}/form/${form_id}/custom-field`, field);
}

function findCustomField(project_id, form_id, field_id) { //added to find a specific custom field within the form
  return httpManager.getHttpWithRequestingToken(`/api/project/${project_id}/form/${form_id}/custom-field/${field_id}`);
}

function updateCustomField(project_id, form_id, field_id, field) { //added to create a custom field within the form
  return httpManager.putJsonOnce(`/api/project/${project_id}/form/${form_id}/custom-field/${field_id}`, field);
}

function deleteCustomField(project_id, form_id, field_id, field) { //added to delete a field in a group
  return httpManager.deleteJsonWithRequestingToken(`/api/project/${project_id}/form/${form_id}/custom-field/${field_id}`, field);
}

function enableCustomField(project_id, form_id, group_id, field_id, status) { //added to update the status of custom field in a form
  return httpManager.putJsonOnce(`/api/project/${project_id}/form/${form_id}/custom-field/${field_id}/status/${status}`);
}

function reorderCustomField(project_id, form_id, reorder) { //added to update the order of the custom field within the form
  return httpManager.putJsonOnce(`/api/project/${project_id}/form/${form_id}/custom-field/reorder`, reorder);
}

function findAllField(project_id, form_id) { //added to find all fields with the form excluding the group
  return httpManager.getHttpWithRequestingToken(`/api/project/${project_id}/form/${form_id}/field`);
}

// function createFieldIdentifier(project_id, form_id, group_id, field_id){ //added to create a field identifier
//   return httpManager.putJsonOnce(`/api/project/${project_id}/form/${form_id}/identifier-field/${group_id}`, field_id);
// }
//
// function createFieldSubIdentifier(project_id, form_id, group_id, field_id){ //added to create a field sub identifier
//   return httpManager.putJsonOnce(`/api/project/${project_id}/form/${form_id}/sub-identifier-field/${group_id}`, field_id);
// }

function updateIdentifier(project_id, form_id, identifier){ //added to create a field identifier
  return httpManager.putJsonOnce(`/api/project/${project_id}/form/${form_id}/identifier/${identifier.identifier}`, identifier);
}

function updateSubIdentifier(project_id, form_id, subidentifier){ //added to create a field sub identifier
  return httpManager.putJsonOnce(`/api/project/${project_id}/form/${form_id}/sub-identifier/${subidentifier.sub_identifier}`, subidentifier);
}

function getAllFieldOption(project_id, form_id, group_id, field_id){ //added to get all options created within the field
  return httpManager.getHttpWithRequestingToken(`/api/project/${project_id}/form/${form_id}/group/${group_id}/field/${field_id}/option`);
}

function createFieldOption(project_id, form_id, group_id, field_id, option) { //added to create a field option
  return httpManager.postJsonOnce(`/api/project/${project_id}/form/${form_id}/group/${group_id}/field/${field_id}/option`, option);
}

function findFieldOption(project_id, form_id, group_id, field_id, option_id) { //added to find a specific option
  return httpManager.getHttpWithRequestingToken(`/api/project/${project_id}/form/${form_id}/group/${group_id}/field/${field_id}/option/${option_id}`);
}

function updateFieldOption(project_id, form_id, group_id, field_id, option_id, option) { //added to update a field option
  return httpManager.putJsonOnce(`/api/project/${project_id}/form/${form_id}/group/${group_id}/field/${field_id}/option/${option_id}`, option);
}

function deleteFieldOption(project_id, form_id, group_id, field_id, option_id, option) { //added to delete a field option
  return httpManager.deleteJsonWithRequestingToken(`/api/project/${project_id}/form/${form_id}/group/${group_id}/field/${field_id}/option/${option_id}`, option);
}

function getAllPreset(project_id) { //added to get all presets
  return httpManager.getHttpWithRequestingToken(`/api/project/${project_id}/form/preset`);
}

function createPreset(project_id, form_id, preset_id) { //added to create a form group from preset
  return httpManager.postJsonOnce(`/api/project/${project_id}/form/${form_id}/preset/${preset_id}`);
}

function findPreset(project_id, preset_id) { //added to find specific preset
  return httpManager.getHttpWithRequestingToken(`/api/project/${project_id}/form/preset/${preset_id}`);
}

function updatePreset(project_id, preset_id, preset) { //added to update an existing preset
  return httpManager.putJsonOnce(`/api/project/${project_id}/form/preset/${preset_id}`, preset);
}

function getAllTemplate(project_id) { //added to get all templates
  return httpManager.getHttpWithRequestingToken(`/api/project/${project_id}/form/template`);
}

function createTemplate(project_id, template_id){ //added to create form from template
  return httpManager.postJsonOnce(`/api/project/${project_id}/form/template/${template_id}`);
}

function findTemplate(project_id, template_id) { //added to find a specific template
  return httpManager.getHttpWithRequestingToken(`/api/project/${project_id}/form/template/${template_id}`)
}

function updateTemplate(project_id, template_id, template) { // added to update an existing template
  return httpManager.putJsonOnce(`/api/project/${project_id}/form/template/${template_id}`, template);
}

function getFormAccessKey(project_id, form_id) {
  return httpManager.getHttpWithRequestingToken(`/api/project/${project_id}/form/${form_id}/accesskey`);
}

function getFormIntegration(project_id, form_id) {
  return httpManager.getHttpWithRequestingToken(`/api/project/${project_id}/form/${form_id}/integration`);
}

function updateFormIntegration(project_id, form_id, integration) {
  return httpManager.putJsonOnce(`/api/project/${project_id}/form/${form_id}/integration`, integration);
}
