import { httpManagerService as httpManager } from  '_services';

export const projectService = {
  getProjects,
  getProject,
  createProject,
  updateProject,
  deleteProject,
  updateStatus,
  getAllProjectMembers,
  addProjectMember,
  deleteProjectMember,
  getAllProjectStatus,
  createLeadStatus,
  findProjectStatus,
  findProjectStatusCount,
  updateLeadStatus,
  deleteLeadStatus,
  enableProjectStatus,
  createProjectDefaultStatus,
  createProjectConversionStatus,
  getActivityLog
};

function getProjects() {
  return httpManager.getHttpWithRequestingToken('/api/project');
}

function getProject(id) { //find
  return httpManager.getHttpWithRequestingToken(`/api/project/${id}`);
}

function createProject(project) { //create
  return httpManager.postJsonOnce('/api/project', project);
}

function updateProject(project, id) { //update
  return httpManager.putJsonOnce(`/api/project/${id}`, project);
}

function deleteProject(project, id) { //delete
  return httpManager.deleteJsonWithRequestingToken(`/api/project/${id}`, project);
}

function updateStatus(project_id, status) { //added to update the status of the project if it is enable or disable
  return httpManager.putJsonOnce(`/api/project/${project_id}/status/${status}`)
}

function getAllProjectMembers(id) { //added to get all project members within the project
  return httpManager.getHttpWithRequestingToken(`/api/project/${id}/member`)
}

function addProjectMember(member, id) {
  return httpManager.putJsonOnce(`/api/project/${id}/member`, member);
}

function deleteProjectMember(member, project_id, member_id) {
  return httpManager.deleteJsonWithRequestingToken(`/api/project/${project_id}/member`, member);
}

function getAllProjectStatus(id) { //added to get all project status within the project
  return httpManager.getHttpWithRequestingToken(`/api/project/${id}/project-status`);
}

function createLeadStatus(status, id) { //createProjectStatus
  return httpManager.postJsonOnce(`/api/project/${id}/project-status`, status);
}

function findProjectStatus(project_id, status_id) { //added to find specific project status within the project
  return httpManager.getHttpWithRequestingToken(`/api/project/${project_id}/project-status/${status_id}`)
}

function findProjectStatusCount(project_id) {
  return httpManager.getHttpWithRequestingToken(`/api/project/${project_id}/project-status/count`)
}

function updateLeadStatus(lead_status, project_id, status_id) { //updateProjectStatus
  return httpManager.putJsonOnce(`/api/project/${project_id}/project-status/${status_id}`, lead_status);
}

function deleteLeadStatus(lead_status, project_id, status_id) { //deleteProjectStatus
  return httpManager.deleteJsonWithRequestingToken(`/api/project/${project_id}/project-status/${status_id}`, lead_status);
}

function enableProjectStatus(project_id, status_id, status) { //added to update the status of the project_status if it is enable or disable
  return httpManager.putJsonOnce(`/api/project/${project_id}/project-status/${status_id}/status/${status}`)
}

function createProjectDefaultStatus(project_id, default_status_id, status) { //added to create a default Project status of the project
  return httpManager.putJsonOnce(`/api/project/${project_id}/default-status/${default_status_id}`, status)
}

function createProjectConversionStatus(project_id, conversion_status_id, status) {
  return httpManager.putJsonOnce(`/api/project/${project_id}/conversion-status/${conversion_status_id}`, status)
}

function getActivityLog(id) {
  return httpManager.getHttpWithRequestingToken(`/api/project/${id}/activities`);
}
