import Axios from  'axios-observable';
import decode from "jwt-decode";

import { environment } from "_environments";

const API_URL = environment.apiUrl;
const API_CLIENT_ID = environment.clientId;
const API_CLIENT_SECRET = environment.clientSecret;
const API_GENERIC_USERNAME = environment.genericUsername;
const API_GENERIC_PASSWORD = environment.genericPassword;

export const httpManagerService = {
  getBasicAuthorization,
  getBasicHttpOptions,
  getJsonBearerHttpOptions,
  getMultiPartBearerHttpOptions,
  getJsonBearerHttpOptionsWithBody,

  storeUser,
  getUser,
  getEmail,
  getPassword,
  isLoginWithUserCredentials,
  isAuthenticated,
  setUserCredentials,
  removeCredentials,

  setToken,
  setRefreshToken,
  getToken,
  getRefreshToken,
  removeTokens,
  isTokenExpired,

  requestAccessToken,
  refreshAccessToken,

  getHttpOnce,
  fetchHttpOnce,
  getObservableHttp,
  getHttpWithRequestingToken,
  getObservableHttpWithRequestingToken,
  getHttpWithRefreshingToken,
  getObservableHttpWithRefreshingToken,

  postJsonOnce,
  postFormDataOnce,
  postObservableJsonOnce,
  postJsonDataWithRequestingToken,
  postObservableJsonDataWithRequestingToken,
  postJsonDataWithRefreshingToken,
  postObservableJsonDataWithRefreshingToken,

  putJsonOnce,
  putFormDataOnce,
  putHttpOnce,
  putObservableJsonOnce,
  putObservableJsonDataWithRefreshingToken,
  putJsonDataWithRequestingToken,
  putJsonDataWithRefreshingToken,

  deleteHTTPOnce,
  deleteObservableHttp,
  deleteHttpWithRequestingToken,
  deleteObservableHttpWithRequestingToken,
  deleteHttpWithRefreshingToken,
  deleteObservableHttpWithRefreshingToken,
  deleteJsonOnce,
  deleteJsonWithRequestingToken,
  deleteJsonWithRefreshingToken
};

/*
* HEADERS
* */

function getBasicAuthorization(): string {
  return btoa(API_CLIENT_ID + ':' + API_CLIENT_SECRET);
}

function getBasicHttpOptions() {
  return {
    headers: {
      'Content-Type': 'application/x-www-form-urlencoded',
      'Authorization': `Basic ${httpManagerService.getBasicAuthorization()}`
    },
    withCredentials: true
  };
}

function getJsonBearerHttpOptions() {
  return {
    headers: {
      'Content-Type': 'application/json',
      'Authorization': `Bearer ${httpManagerService.getToken()}`
    },
    withCredentials: true
  };
}

function getMultiPartBearerHttpOptions() {
  return {
    headers: {
      'Content-Type': 'multipart/form-data',
      'Authorization': `Bearer ${httpManagerService.getToken()}`
    },
    withCredentials: true
  };
}

function getJsonBearerHttpOptionsWithBody(body: string) {
  return {
    headers: {
      'Content-Type': 'application/json',
      'Authorization': `Bearer ${httpManagerService.getToken()}`
    },
    body: body,
    withCredentials: true
  };
}


/*
* USER
* */

function storeUser(user) {
  sessionStorage.setItem('user', JSON.stringify(user));
}

function getUser() {
  let user = JSON.parse(sessionStorage.getItem('user'));
  if(user) {
    return user.data;
  }
  return null;
}

function getEmail() {
  return sessionStorage.getItem('email');
}

function getPassword() {
  return sessionStorage.getItem('password');
}

function isLoginWithUserCredentials(): boolean {
  return (httpManagerService.getPassword() != null && httpManagerService.getEmail() != null);
}

function isAuthenticated(): boolean {
  const token = httpManagerService.getToken();
  const helper = httpManagerService.isTokenExpired(token);
  return (!helper && httpManagerService.isLoginWithUserCredentials());
}

function setUserCredentials(email: string, password: string) {
  sessionStorage.setItem('email', email);
  sessionStorage.setItem('password', password);
}

function removeCredentials() {
  sessionStorage.removeItem('email');
  sessionStorage.removeItem('password');
}


/*
* TOKENS
* */

function setToken(access_token: string) {
  sessionStorage.setItem('access_token', access_token);
}

function setRefreshToken(access_token: string) {
  sessionStorage.setItem('refresh_token', access_token);
}

function getToken() {
  return sessionStorage.getItem('access_token');
}

function getRefreshToken() {
  return sessionStorage.getItem('refresh_token');
}

function removeTokens() {
  sessionStorage.removeItem('access_token');
  sessionStorage.removeItem('refresh_token');
}

function isTokenExpired(token): boolean {
  try {
    const date = new Date(0);
    const decoded = decode(token);
    date.setUTCSeconds(decoded.exp);
    return date.valueOf() > new Date().valueOf();
  } catch (err) {
    return false;
  }
};

/* Request OAUTH Token */
function requestAccessToken() {
    return new Promise((resolve, reject) => {

      const data = new URLSearchParams();
      const username =
        httpManagerService.isLoginWithUserCredentials() ?
          httpManagerService.getEmail() : API_GENERIC_USERNAME;

      const password = httpManagerService.isLoginWithUserCredentials() ?
        httpManagerService.getPassword() : API_GENERIC_PASSWORD;

      data.append('username', username);
      data.append('password', password);
      data.append('grant_type', 'password');

      Axios.post(API_URL + '/oauth/token', data.toString(), httpManagerService.getBasicHttpOptions())
        .subscribe(
          response => {
            httpManagerService.setToken(response.data['access_token']);
            httpManagerService.setRefreshToken(response.data['refresh_token']);

            resolve(response.data);
          },
          error => reject(error)
        );

    });
  }

/* Refresh OAUTH Token */
function refreshAccessToken() {
    return new Promise((resolve, reject) => {

      const data = new URLSearchParams();
      data.append('refresh_token', httpManagerService.getRefreshToken());
      data.append('grant_type', 'refresh_token');

      Axios.post(API_URL + '/oauth/token', data.toString(), httpManagerService.getBasicHttpOptions())
        .subscribe(
          response => {
            httpManagerService.setToken(response['access_token']);
            // httpManagerService.setTokenType(response['token_type']);
            httpManagerService.setRefreshToken(response['refresh_token']);
            resolve(response);
          },
          error => {
            httpManagerService.removeCredentials();
            httpManagerService.removeTokens();
            reject(error);
          });
    });
  }


/* HTTP GET METHOD */
function getHttpOnce(url: string) {
    return new Promise((resolve, reject) => {
      Axios.get(API_URL + url, httpManagerService.getJsonBearerHttpOptions())
        .subscribe(
          data => { resolve(data); },
          error => { reject(error); }
        );
    });
  }

/* HTTP GET METHOD */
function fetchHttpOnce(url: string) {
  return fetch(API_URL + url, {
    headers: {'Authorization': `Bearer ${httpManagerService.getToken()}`}
  });
}

function getObservableHttp(url: string)  : Observable<any>  {
    return Axios.get(API_URL + url, httpManagerService.getJsonBearerHttpOptions());
  }

function getHttpWithRequestingToken(url: string){
    return httpManagerService.requestAccessToken().then(
      () => {
        return httpManagerService.getHttpOnce(url);
      }
    );
  }

function getObservableHttpWithRequestingToken(url: string){
    httpManagerService.requestAccessToken().then(
      () => {
        return httpManagerService.getObservableHttp(url);
      }
    );
  }

function getHttpWithRefreshingToken(url: string) {
    if (!httpManagerService.isAuthenticated()) {
      return httpManagerService.refreshAccessToken().then(
        (data) => {
          return httpManagerService.getHttpOnce(url);
        }
      );
    } else {
      return httpManagerService.getHttpOnce(url);
    }
  }

function getObservableHttpWithRefreshingToken(url: string) {
    if (!httpManagerService.isAuthenticated()) {
      httpManagerService.refreshAccessToken().then(
        (data) => {
          return httpManagerService.getObservableHttp(url);
        }
      );
    } else {
      return httpManagerService.getObservableHttp(url);
    }
  }


/* HTTP POST METHOD */
function postJsonOnce(url: string, object: Object) {
  return new Promise((resolve, reject) => {

    let params;
    if (object) {
      params = JSON.stringify(object);
    }
    Axios.post(API_URL + url, params, httpManagerService.getJsonBearerHttpOptions())
      .subscribe(
        data => {
          resolve(data);
        },
        error => {
          reject(error);
        }
      );
  });
}

function postFormDataOnce(url: string, formData: FormData) {
  return new Promise((resolve, reject) => {

    Axios.post(API_URL + url, formData, httpManagerService.getMultiPartBearerHttpOptions())
      .subscribe(
        data => {
          resolve(data);
        },
        error => {
          reject(error);
        }
      );
  });
}

function postObservableJsonOnce(url: string,  object: Object) : Observable<any> {
  let params;
  if (object) {
    params = JSON.stringify([...object.entries()]);
  }

  return Axios.post(API_URL + url, params, httpManagerService.getJsonBearerHttpOptions());
}

function postJsonDataWithRequestingToken(url: string, object: Object) {
  return httpManagerService.requestAccessToken().then(
    () => {
      return httpManagerService.postJsonOnce(url, object);
    }
  );
}

function postObservableJsonDataWithRequestingToken(url: string, object: Object) {
  httpManagerService.requestAccessToken().then(
    () => {
      return httpManagerService.postObservableJsonOnce(url, object);
    }
  );
}

function postJsonDataWithRefreshingToken(url: string, object: Object) {
  if (!httpManagerService.isAuthenticated()) {
    return httpManagerService.refreshAccessToken().then(
      (data) => {
        return httpManagerService.postJsonOnce(url, object);
      }
    );
  } else {
    return httpManagerService.postJsonOnce(url, object);
  }
}

function postObservableJsonDataWithRefreshingToken(url: string, object: Object) {
  if (!httpManagerService.isAuthenticated()) {
    httpManagerService.refreshAccessToken().then(
      (data) => {
        return httpManagerService.postObservableJsonOnce(url, object);
      }
    );
  } else {
    return httpManagerService.postObservableJsonOnce(url, object);
  }
}


/* HTTP PUT METHOD */
function putJsonOnce(url: string, object: Object) {
  return new Promise((resolve, reject) => {

    let params;
    if (object) {
      params = JSON.stringify(object);
    }

    Axios.put(API_URL + url, params, httpManagerService.getJsonBearerHttpOptions())
      .subscribe(
        data => {
          resolve(data);
        },
        error => {
          reject(error);
        }
      );
  });
}

function putFormDataOnce(url: string, formData: FormData) {
  return new Promise((resolve, reject) => {


    Axios.put(API_URL + url, formData, httpManagerService.getJsonBearerHttpOptions())
      .subscribe(
        data => {
          resolve(data);
        },
        error => {
          reject(error);
        }
      );
  });
}

/* HTTP PUT METHOD */
function putHttpOnce(url: string) {
  return new Promise((resolve, reject) => {
    Axios.put(API_URL + url, null, httpManagerService.getJsonBearerHttpOptions())
        .subscribe(
            data => {
              resolve(data);
            },
            error => {
              reject(error);
            }
        );
  });
}

function putObservableJsonOnce(url: string,  object: Object) : Observable<any> {
  let params;
  if (object) {
    params = JSON.stringify([...object.entries()]);
  }

  return Axios.put(API_URL + url, params, httpManagerService.getJsonBearerHttpOptions());
}

function putObservableJsonDataWithRefreshingToken(url: string, object: Object) {
  if (!httpManagerService.isAuthenticated()) {
    httpManagerService.refreshAccessToken().then(
      (data) => {
        return httpManagerService.putObservableJsonOnce(url, object);
      }
    );
  } else {
    return httpManagerService.putObservableJsonOnce(url, object);
  }
}

function putJsonDataWithRequestingToken(url: string, object: Object) {
  return httpManagerService.requestAccessToken().then(
    () => {
      return httpManagerService.putJsonOnce(url, object);
    }
  );
}

function putJsonDataWithRefreshingToken(url: string, object: Object) {
  if (!httpManagerService.isAuthenticated()) {
    return httpManagerService.refreshAccessToken().then(
      (data) => {
        return httpManagerService.putJsonOnce(url, object);
      }
    );
  } else {
    return httpManagerService.putJsonOnce(url, object);
  }
}


/* HTTP DELETE METHOD */
function deleteHTTPOnce(url: string) {
  return new Promise((resolve, reject) => {
    Axios.delete(API_URL + url, httpManagerService.getJsonBearerHttpOptions())
      .subscribe(
        data => { resolve(data); },
        error => { reject(error); }
      );
  });
}

function deleteObservableHttp(url: string)  : Observable<any>  {
  return Axios.delete(API_URL + url, httpManagerService.getJsonBearerHttpOptions());
}

function deleteHttpWithRequestingToken(url: string) {
  return httpManagerService.requestAccessToken().then(
    () => {
      return httpManagerService.deleteHTTPOnce(url);
    }
  );
}

function deleteObservableHttpWithRequestingToken(url: string){
  httpManagerService.requestAccessToken().then(
    () => {
      return httpManagerService.deleteObservableHttp(url);
    }
  );
}

function deleteHttpWithRefreshingToken(url: string) {
  if (!httpManagerService.isAuthenticated()) {
    return httpManagerService.refreshAccessToken().then(
      (data) => {
        return httpManagerService.deleteHTTPOnce(url);
      }
    );
  } else {
    return httpManagerService.deleteHTTPOnce(url);
  }
}

function deleteObservableHttpWithRefreshingToken(url: string) {
  if (!httpManagerService.isAuthenticated()) {
    httpManagerService.refreshAccessToken().then(
      (data) => {
        return httpManagerService.deleteObservableHttp(url);
      }
    );
  } else {
    return httpManagerService.deleteObservableHttp(url);
  }
}

function deleteJsonOnce(url: string, object: Object) {
  return new Promise((resolve, reject) => {
    Axios.delete(API_URL + url,
      {
        headers: {
          'Content-Type': 'application/json',
          'Authorization': `Bearer ${httpManagerService.getToken()}`
        },
        data: object
      })
      .subscribe(
        data => { resolve(data); },
        error => { reject(error); }
      );
  });
}

function deleteJsonWithRequestingToken(url: string, object) {
  return httpManagerService.requestAccessToken().then(
    () => {
      return httpManagerService.deleteJsonOnce(url, object);
    }
  );
}

function deleteJsonWithRefreshingToken(url: string, object) {
  if (!httpManagerService.isAuthenticated()) {
    return httpManagerService.refreshAccessToken().then(
      (data) => {
        return httpManagerService.deleteJsonOnce(url, object);
      }
    );
  } else {
    return httpManagerService.deleteHTTPOnce(url);
  }
}

