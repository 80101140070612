import React, {Component, useEffect, useRef} from "react";
import UtmForm from './components/UtmForm';
import TopBar from "_components/TopBar";
import UtmFormLoader from "_components/Loader/UtmFormLoader";
import {projectActions, utmActions} from "_actions";
import {useDispatch, useSelector} from "react-redux";
import {Link, useParams} from "react-router-dom";
import {MDBRow} from "mdbreact";

const ManageUtm = () => {

  const dispatch = useDispatch();
  const params = useParams();

  useEffect(() => {
    dispatch(projectActions.getProject(params.id));
    dispatch(utmActions.getCodex(params.id));
  }, [params.id]);

  const codexLoaded = useSelector(state => {
    const {utm} = state;

    return utm.getCodexLoaded;
  });

  const generateUtmLoading = useSelector(state => {
    const {utm} = state;

    return utm.generateUtmLoading;
  });



  return (
    <>
      <div className="page-title-ctas ">
        <MDBRow className="row align-items-center">
          <TopBar
              onHandleProjectDetailsDrawer={(width, project) => this.handleProjectDetailsDrawer(width, project)}
              onHandleProjectMembersDrawer={(width, owner, member) => this.handleProjectMembersDrawer(width, owner, member)}
          />
          <div className='col'>
            <div className='button-group'>
              <button className="bordered-btn hidden-btn">Hidden</button>
            </div>
          </div>
        </MDBRow>
      </div>
      <div className="page-title-ctas page-cta-inner">
        <div className="row align-items-center">
          <div className="col">
            <div className="breadcrumbs">
              <Link to={`/project/${params.id}/utm`} className="back" >
                <span className="icon-s">
                  <i className="fas fa-chevron-left"></i>
                </span>
              </Link>
              <span style={{marginLeft: "10px" }}>Building Tracking URL</span>
            </div>
          </div>
          <div className="col">
          </div>
        </div>
      </div>
      <div className="view-info-main-content leads-container">
        {(codexLoaded && !generateUtmLoading) && <UtmForm />}
        {(!codexLoaded || generateUtmLoading) && <UtmFormLoader />}
      </div>
    </>
  );
};

export default ManageUtm;
