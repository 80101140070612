import React, { useState, useEffect, useRef } from 'react'

const StatusFilter = ({status, statusColor, onStatusChange}) => {

    const [isOpen, setIsOpen] = useState(false);
    const filterRef = useRef();

    function handleOpenFilter() {
      setIsOpen(!isOpen);
    }
    function handleCloseFilter() {
      setIsOpen(false);
    }

    function handleStatusChange(status) {
        onStatusChange(status);
        setIsOpen(false);
    }

    useEffect(() => {
      const filterOutsideClick = (e) => {
        if (
          isOpen &&
          filterRef.current &&
          !filterRef.current.contains(e.target)
        ) {
          setIsOpen(false);
        }
      };
      document.addEventListener("click", filterOutsideClick);
      return () => {
        document.removeEventListener("click", filterOutsideClick);
      };
    }, [isOpen]);

  return (
    <div className="filter-box filter-status" ref={filterRef}>
      <div className="filter-btn status-btn">
        <span className={`status-color-${statusColor}`} onClick={() => handleOpenFilter()}>{status}</span>
      </div>
      <div className={isOpen ? 'filter-dropdown active' : 'filter-dropdown'}>
        <div className="filter-title">
          <p>Status</p>
          <i className="icon-lms icon-close" onClick={() => handleCloseFilter()}></i>
        </div>
        <div className="filter-list status-list">
          <div className="filter-group">
            <span className='filter-color1' onClick={() => handleStatusChange('ONBOARDED')}>Onboarded</span>
            <span className='filter-color2' onClick={() => handleStatusChange('PENDING')}>Inquired</span>
            <span className='filter-color3' onClick={() => handleStatusChange('BLACKLISTED')}>Blacklisted</span>
            <span className='filter-color4' onClick={() => handleStatusChange('COLLABORATED')}>Collaborated</span>
            <span className='filter-color5' onClick={() => handleStatusChange('ONGOING_COLLABORATION')}>Ongoing Collaboration</span>
            <span className='filter-color6' onClick={() => handleStatusChange('RECEIVED_INFORMATION')}>Received Information</span>
          </div>
        </div>
      </div>
    </div>
  );
}

export default StatusFilter