import React from 'react';
import { connect } from "react-redux";
import { Link } from 'react-router-dom';
import { projectActions } from '_actions';
import { utilitiesService as utility } from '_services';

import { MDBContainer, MDBRow, MDBCol, MDBIcon } from 'mdbreact';
import { Button, ButtonGroup, Icon } from "blackbox-react";

// import { leadInfoBtn } from '_pages/Leads/constants/BreadCrumbs';
import { priorityColor, priorityIcon, priorityText, colorRoundStatus } from '_pages/Leads/constants/BreadCrumbs';
import { ProjectViewRecentActivities } from '_pages/Projects/ProjectViewRecentActivities';

import avatar from '_assets/images/avatar-fem.png'

class ProjectInfo extends React.Component {
  constructor(props){
    super(props);

    const project = this.props.getProject(this.props.match.params.id);
    const projectMembers = this.props.getAllProjectMembers(this.props.match.params.id);
    this.props.getActivityLog(this.props.match.params.id);
    this.props.findProjectStatusCount(this.props.match.params.id);

    this.state = {
      project: {},
      projectData: project,
      projectMembers: projectMembers,
      //leadInfoBtn,
      isActiveSideBtn: 'ACTIVITY',
      priorityColor,
      priorityIcon,
      priorityText,
      colorRoundStatus,
      statusColor:  ['teal', 'blue', 'yellow', 'orange', 'red', 'green', 'dark-gray', 'lime', 'cyan', 'indigo', 'brown', 'purple']
    };
  }

  componentDidMount() {
    sessionStorage.setItem('isActiveModule', 'projects');
  }

  handleDisplayFormsOverview = (project, forms) => {
    //console.log('handleDisplayFormsOverview forms', forms);

    let projectForms = forms.filter(function( obj ) {
      return obj.is_default !== true;
    });

    //console.log('handleDisplayFormsOverview projectForms', projectForms);

    let hasData = false;
    if(projectForms.length > 0)  {
      hasData = true;
    }

    return (
      
      <MDBCol lg='12' xl='12' md='12' className='project-info__overview forms-overview'>
		<div className="project-info__icon">
			<Link to={`/project/${project.id}/forms`}>
			<div className="project__link link-to--forms">
				<div className="icon">
					<MDBIcon far icon='clipboard' />
					<div className="stats">
						<div className="label count">
							<span>{project.forms.length - 1}</span>
						</div>
						<div className="label">
							<p>
								{ (project.forms.length - 1) > 1 ? 'FORMS' :
								(project.forms.length - 1) === 0 ? 'FORMS' : 'FORM'}
							</p>
						</div>
					</div>
				</div>
			</div>
			</Link>
		</div>
		<ul className='form-list list--unstyled py-4'>
			{ hasData ?
			projectForms.slice(0, 10).map((value, index) => {
				return (
				<li key={index} className='display-block'>
					<div className="icon-overview icon-status icon-done">
						<MDBIcon icon='check' className='' />
					</div>
					<div className="details">
						<span className="title">
						{value.name}
						</span>
					</div>
				</li>
				)
			}) : null }
		</ul>
      </MDBCol>
    );
  };

  handleDisplayLeadsOverview = (project, status) => {
    let leadCount = 0;

    status.map((value, index) => {
      if(value.count !== null){
        leadCount = leadCount + value.count;
      }
      return leadCount;
    });

    const projectLeads = status.sort((a, b) => a.id > b.id ? 1 : -1);
    const statusColor = this.state.statusColor;

    let hasData = false;
    if(projectLeads.length > 0)  {
      hasData = true;
    }

    return (
      	<MDBCol lg='12' xl='12' md='12' className='project-info__overview leads-overview'>
			<div className="project-info__icon">
				<Link to={`/project/${project.id}/leads`}>
					<div className="project__link link-to--leads">
						<div className="icon">
							<MDBIcon far icon='paper-plane' />
							<div className="stats">
								<div className="label count">
									<span>{leadCount}</span>
								</div>
								<div className='label'>
									<p>{ leadCount > 1 ? 'LEADS' :
									leadCount === 0 ? 'LEADS' : 'LEAD'}
									</p>
								</div>
							</div>
						</div>
					</div>
				</Link>
			</div>
			<ul className='form-list list--unstyled py-4'>
			{ hasData ?
				projectLeads.slice(0, 10).map((value, index) => {
				return (
					<li key={index} className='lead-status display-block'>
						<div className={`icon-overview icon-leads bg--${statusColor[index]}`}>
							<span className={`fg--${statusColor[index]}`}>{value.count}</span>
						</div>
						<div className="details">
							<span className="title">
							{value.status}
							</span>
						</div>
					</li>
				)
				}) : null }
			</ul>
      </MDBCol>

    );
  };

  handleDisplayMembersOverview = (project, members) => {
    //const projectMembers = project.members;

    let hasData = false;
    if(members.length > 0)  {
      hasData = true;
    }

    return (
      <MDBCol lg='12' xl='12' md='12' className='project-info__overview agents-overview'>
        <div className="project-info__icon">
          <Link to={`/project/${project.id}/settings`}>
            <div className="project__link link-to--agents">
              <div className="icon">
                <MDBIcon fas="true" icon='users' />
                <div className="stats">
                  <div className="label count">
                    <span>{members.length}</span>
                  </div>
                  <div className="label">
                    <p>
                      { members.length > 1 ? 'MEMBERS' :
                        members.length === 0 ? 'MEMBERS' : 'MEMBER'}
                    </p>
                  </div>
                </div>
              </div>
            </div>
          </Link>
        </div>
        <ul className='form-list list--unstyled py-4'>
          { hasData ?
            members.slice(0, 10).map((value, index) => {
              return (
                <li key={index} className='display-block'>
                  <div className="icon-overview icon-agents">
                    <img src={avatar} alt=''/>
                  </div>
                  <div className="details">
                <span className="title">
                  {utility.decrypt(value.account.first_name)} &nbsp;
                  {utility.decrypt(value.account.last_name)}
                </span>
                  </div>
                </li>
              )
            }) : null }
        </ul>
      </MDBCol>
    );
  };

  handleDisplayActivity = (project,  activityLog) => {
    return <ProjectViewRecentActivities leadRecentDetail={[]} projectData={project} activityLog={activityLog} />;
  };

  isOwner = (project) => {
    let email = sessionStorage.getItem('email');
    let owner = project.owner.account.email;

    return email === owner;
  };

  render() {
    let project, status = {};
    let forms, members, activityLog = [];
    const { projectLoaded, activityLogLoaded, membersLoaded, statusLoaded } = this.props;

    if(this.props.projects.project !== undefined)  {
      project = this.props.projects.project.data;
      forms = project.forms;
      // console.log('ProjectInfo project', project);
      // console.log('ProjectInfo forms', forms);
    }

    if(this.props.projects.status !== undefined)  {
      status = this.props.projects.status.data;
      //console.log('ProjectInfo status', status);
    }

    if(this.props.projects.members !== undefined)  {
      members = this.props.projects.members.data;
      //console.log('ProjectInfo members', members);
    }

    if(this.props.projects.activityLog !== undefined)  {
      activityLog = this.props.projects.activityLog.data;
      //console.log('ProjectInfo activityLog', activityLog);
    }

    // if(this.props.projects.status !== undefined)  {
    //   status = this.props.projects.status.data;
    //   console.log('ProjectInfo status', status);
    // }

    // if(this.props.forms.items !== undefined)  {
    //   forms = this.props.forms.items.data;
    //   console.log('ProjectInfo forms', forms);
    // }
    //
    // if(this.props.forms.items !== undefined)  {
    //   forms = this.props.forms.items.data;
    //   console.log('ProjectInfo forms', forms);
    // }

    return (
		<MDBContainer fluid className=' p--0'>
			<div className='page-title-ctas p-l-md p-r-md'>
				{projectLoaded &&
				<MDBRow className='m--0 align-items-center'>
					<MDBCol className='p--0 m--0'>
						<p className='page-title font-family-kanit  font-size-30 line-height-7 color-gray line-height-6'>{project.info.name}</p>
					</MDBCol>
				
					
					<MDBCol>
						{this.isOwner(project) ?
							<MDBRow className='float-right'>
								<ButtonGroup>
									<Link to={`/project/${project.id}/settings`}>
										<Button
											flat='true'
											background='orange' foreground='white'
											borderWidth={2} borderColor='orange' size={-1}
											styles={{ 'width': '100%' }}>
											<MDBIcon icon='cog' className='mr-2' />
											<span className='color-white font-weight-bold'>Project Settings</span>
										</Button>
									</Link>
									<Link to={`/project/${project.id}/settings`}>
										<Button
											flat='true'
											background='orange' foreground='white'
											borderWidth={2} borderColor='orange' size={-1}
											styles={{ 'width': '100%' }}>
											<MDBIcon icon='users' className='mr-2' />
											<span className='color-white font-weight-bold'>Manage Members</span>
										</Button>
									</Link>
									{/* <Link to={`/project/${project.id}/manage-lead-status`}>
										<Button
											flat='true'
											background='orange' foreground='white'
											borderWidth={2} borderColor='orange' size={-1}
											styles={{ 'width': '100%' }}>
											<MDBIcon icon='paper-plane' className='mr-2' />
											<span className='color-white font-weight-bold'>Manage Lead Status</span>
										</Button>
									</Link> */}
								</ButtonGroup>
							</MDBRow> : null
						}
					</MDBCol>
					
				</MDBRow>
				}
			</div>
			
			<div className="page-filter">
				<MDBRow className='text-left project-setting-wrap'>
					{projectLoaded &&
						<React.Fragment>
							<MDBCol className='project-info__description'>
								<label className="mb-0 font-size-13 font-weight-600 mb-1">DESCRIPTION</label>
								<p className='m-0 font-weight-600'>{project.info.description}</p>
							</MDBCol>
							<MDBCol>
								<div className='border-radius-7 background-color-white'>
									<div className='px-3  text-left '>
										<label className='mb-0 font-size-13 font-weight-600 mb-1'>OWNER</label>
									<div>
									{/* <img src={avatar} alt='' className='width-25 border-radius-50 mr-2'/> */}
									<span className='font-weight-600 font-size-14'>{utility.decrypt(project.owner.account.first_name)} {utility.decrypt(project.owner.account.last_name)}</span>
										</div>
									</div>
								</div>
							</MDBCol>
						</React.Fragment>
  					}
				</MDBRow>
			</div>
			
			<MDBRow className='m--0'>
				
				{ projectLoaded ?
				<MDBCol lg='12' xl='12' md='12' className='p--0 p--0 main-body-container container-fluid false p--sm  sub-body-container rectangle-background'>
					<MDBRow className='main-body-container background-color-white p--0 m--0'>
						<MDBCol lg='9' xl='9' md='8' className='project-info__overview p--md'>
							<MDBRow>
							{/* { this.handleDisplayFormsOverview(project, forms) } */}
							{ statusLoaded && this.handleDisplayLeadsOverview(project, status) }
							{ membersLoaded && this.handleDisplayMembersOverview(project, members) }
							</MDBRow>
						</MDBCol>
						<MDBCol lg='3' xl='3' md='4' className='background-color-lead border-right'>
							{ activityLogLoaded && this.handleDisplayActivity(project, activityLog) }
						</MDBCol>
					</MDBRow>
				</MDBCol> :
				<MDBCol lg='12' xl='12' md='12' className='p--0 p--0 main-body-container container-fluid false p--sm  sub-body-container rectangle-background'>
					<MDBContainer fluid className='main-body-container background-color-white width--full'>
						<MDBCol lg='12' className='sub-body-container height--full'>
							<div className='loader-container'>
							<div className="loader dark">Loading...</div>
							</div>
						</MDBCol>
					</MDBContainer>
				</MDBCol>

				}
			</MDBRow>
		</MDBContainer>
    );
  }
}

function mapState(state) {
  const { projects, project, forms } = state;
  const { loading, projectLoaded, members, activityLogLoaded, membersLoaded, statusLoaded } = state.projects;
  return { projects, project, members, loading, projectLoaded, activityLogLoaded, statusLoaded, forms, membersLoaded };
}

const actionCreators = {
  getProject: projectActions.getProject,
  getAllProjectMembers: projectActions.getAllProjectMembers,
  getActivityLog: projectActions.getActivityLog,
  findProjectStatusCount: projectActions.findProjectStatusCount
};

const connectedViewProjects = connect(mapState, actionCreators)(ProjectInfo);
export { connectedViewProjects as ProjectInfo };
