import React, { Component } from 'react';
import { MDBContainer, MDBRow, MDBCol, MDBIcon } from 'mdbreact';
import check from '_assets/images/check.png';
import { Link } from 'react-router-dom';
import Slider from '_pages/Leads/components/Slider';
import {Button, ButtonGroup, Icon} from "blackbox-react";
import {connect} from "react-redux";

class ImportResult extends Component {
  constructor(props) {
    super(props);

    this.state = {
      tableData: [
        {
          fileName: 'Lead_Import_Sample.csv',
          importedon: `September 30, 2019 10:19 PM`,
          created: '10',
          updated: '2',
          skipped: '0',
          errored: '0',
          importedBy: 'Sophie O.',
          status: 'Completed'
        },
        {
          fileName: 'Lead_Import_Sample.csv',
          importedon: `September 30, 2019 10:19 PM`,
          created: '10',
          updated: '2',
          skipped: '0',
          errored: '0',
          importedBy: 'Sophie O.',
          status: 'Completed'
        },
        {
          fileName: 'Lead_Import_Sample.csv',
          importedon: `September 30, 2019 10:19 PM`,
          created: '10',
          updated: '2',
          skipped: '0',
          errored: '0',
          importedBy: 'Sophie O.',
          status: 'Completed'
        },
        {
          fileName: 'Lead_Import_Sample.csv',
          importedon: `September 30, 2019 10:19 PM`,
          created: '10',
          updated: '2',
          skipped: '0',
          errored: '0',
          importedBy: 'Sophie O.',
          status: 'Completed'
        },
        {
          fileName: 'Lead_Import_Sample.csv',
          importedon: `September 30, 2019 10:19 PM`,
          created: '10',
          updated: '2',
          skipped: '0',
          errored: '0',
          importedBy: 'Sophie O.',
          status: 'Completed'
        },
        {
          fileName: 'Lead_Import_Sample.csv',
          importedon: `September 30, 2019 10:19 PM`,
          created: '10',
          updated: '2',
          skipped: '0',
          errored: '0',
          importedBy: 'Sophie O.',
          status: 'Completed'
        },
        {
          fileName: 'Lead_Import_Sample.csv',
          importedon: `September 30, 2019 10:19 PM`,
          created: '10',
          updated: '2',
          skipped: '0',
          errored: '0',
          importedBy: 'Sophie O.',
          status: 'Completed'
        },
      ]
    }
  }

  renderSlider = () => {
    return (
      <div className='width-135'>
        <div className='row float-right height-41 ml-1'>
          <div className='row mr-2'>
            <p className='notActive mt-2 mb-0'>Upload CSV</p>
            <p className='border-right-gray mb-0 pr-3'> </p>
          </div>
          <div className='mt-2'>
            <p className='mx-2 round mb-0 background-color-dark-orange'><img alt='' src={check} className='check-icon filter-white' /></p>
            <p className='mb-0 line' />
          </div>
        </div>
        <div className='row float-right height-41 ml-1'>
          <div className='row mr-2'>
            <p className='notActive mt-2 mb-0'>Map Fields</p>
            <p className='border-right-gray mb-0 pr-3'> </p>
          </div>
          <div className='mt-2'>
            {/* <p className='mx-2 round mb-0 background-slider-color d-flex'><MDBIcon icon='cogs' className='font-size-9 font-color-dark-orange m-auto' /></p> */}
            <p className='mx-2 round mb-0 background-color-dark-orange'><img alt='' src={check} className='check-icon filter-white' /></p>
            <p className='mb-0 line' />
          </div>
        </div>
        <div className='row float-right height-41'>
          <div className='row mr-2'>
            <p className='active mt-2 mb-0'>Import</p>
            <p className='border-right-orange mb-0 pr-3'> </p>
          </div>
          <div className='mt-2'>
            <p className='mx-2 round mb-0 background-color-dark-orange'><img alt='' src={check} className='check-icon filter-white' /></p>
          </div>
        </div>
      </div>
    )
  }

  render() {
    const projectId = JSON.parse(sessionStorage.getItem('projectId'));
    let { importedLeads, imported } = this.props;


    return (
      <MDBContainer fluid className='p--md lead-import'>
        <MDBRow className='m--0'>
          <MDBCol xl='2' lg='4' md='4' className='p-t--0 p-l--0 p-r--sm p-b--sm'>
            <MDBRow className='p--0 m--0'>
              <MDBCol className='p--0'>
                <p className='font-size-12 font-weight-600 font-color-gray mb-0'>LEAD MANAGEMENT</p>
                <p className='font-family-crimson font-size-30 line-height-7 color-orange line-height-6'>Import Leads</p>
              </MDBCol>
            </MDBRow>
            <MDBRow className='m-t--lg p--0'>
              <MDBCol xl='11' lg='9' className='text-center font-size-14 p--0 m--0 m-l--sm'>
                <Slider stageNum='3' activeFormView='importResults' isVerified='true'/>
              </MDBCol>
            </MDBRow>
            <MDBRow className='p-t--md p-l--md p-r--md p-b--0 back-button'>
              <MDBCol>
                <ButtonGroup>
                  <Link to={`/project/${projectId}/leads`} className=''>
                    <Button
                      flat='true'
                      background='transparent' foreground='black'
                      borderWidth={2} borderColor='dark' size={-1}
                      styles={{ 'width': '100%', 'opacity': '0.8' }}>
                      <Icon color='black' name='chevron' rotate={180} />
                      <span className='color-black font-weight-bold'>View All Leads</span>
                    </Button>
                  </Link>
                </ButtonGroup>
              </MDBCol>
            </MDBRow>
          </MDBCol>
          <MDBCol lg='9' xl='10' md='8' className='px-0'>
            <MDBRow className='d-flex'>
              <MDBCol lg='6' className='my-auto'>
                <MDBRow className='pl-3'>
                  <p className='mb-0 font-family-crimson font-size-22 my-auto pr-3'>Import Results</p>
                  <div className='progress col-lg-5 col-xl-5 my-auto'>
                    <span className='progress-value text-white font-weight-600 my-auto'>100% complete</span>
                    <div className='progress-bar progress-background' style={{ width: '100%' }} />
                  </div>
                </MDBRow>
              </MDBCol>
              <MDBCol className='text-right'>
                <ButtonGroup>
                  {/*<Link to={`/project/${projectId}/leads/import?=download`}>*/}
                  {/*  <Button*/}
                  {/*    flat='true'*/}
                  {/*    background='transparent' foreground='dark'*/}
                  {/*    borderWidth={2} borderColor='dark' size={-2}*/}
                  {/*    onClick={(e) => {}}*/}
                  {/*  >*/}
                  {/*    <MDBIcon icon='download' className='mr-2' />*/}
                  {/*    <span className='color-dark font-weight-bold'>Download Template</span>*/}
                  {/*  </Button>*/}
                  {/*</Link>*/}
                  <Link to={`/project/${projectId}/leads/import/history`}>
                    <Button
                      flat='true'
                      background='transparent' foreground='dark'
                      borderWidth={2} borderColor='dark' size={-2}
                      onClick={(e) => {}}
                    >
                      <MDBIcon icon='columns' className='mr-2' />
                      <span className='color-dark font-weight-bold'>Import History</span>
                    </Button>
                  </Link>
                  <Link to={`/project/${projectId}/leads/import`}>
                    <Button
                      flat='true'
                      background='orange' foreground='white'
                      borderWidth={2} borderColor='orange' size={-2}
                      onClick={(e) => {}}
                    >
                      <Icon name='upload' />
                      <span className='color-white'>Import Again</span>
                    </Button>
                  </Link>
                </ButtonGroup>
              </MDBCol>
            </MDBRow>
            { imported ?
            <MDBCol className='sub-body-container background-color-white background-rectangle m-t--md p--0'>
              <MDBRow className='p--md m--0 height--full'>
                <MDBCol lg='3' xl='3' md='3' className='import-info__overview forms-overview'>
                  <div className="import-info__icon">
                    <div className="project__link import-result--created">
                      <div className="icon">
                        <MDBIcon fas icon='check-double' />
                        <div className="stats">
                          <div className="label count">
                            <span> {importedLeads.data.length} </span>
                          </div>
                          <div className="label">
                            <p>
                              Created
                            </p>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </MDBCol>
                <MDBCol lg='3' xl='3' md='3' className='import-info__overview forms-overview'>
                  <div className="import-info__icon">
                    <div className="project__link import-result--updated">
                      <div className="icon">
                        <MDBIcon fas icon='sync' />
                        <div className="stats">
                          <div className="label count">
                            <span>0</span>
                          </div>
                          <div className="label">
                            <p>
                              Updated
                            </p>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </MDBCol>
                <MDBCol lg='3' xl='3' md='3' className='import-info__overview forms-overview'>
                  <div className="import-info__icon">
                    <div className="project__link import-result--skipped">
                      <div className="icon">
                        <MDBIcon fas icon='sign-out-alt' />
                        <div className="stats">
                          <div className="label count">
                            <span>0</span>
                          </div>
                          <div className="label">
                            <p>
                              Skipped
                            </p>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </MDBCol>
                <MDBCol lg='3' xl='3' md='3' className='import-info__overview forms-overview'>
                  <div className="import-info__icon">
                    <div className="project__link import-result--errored">
                      <div className="icon">
                        <MDBIcon fas icon='exclamation-triangle' />
                        <div className="stats">
                          <div className="label count">
                            <span>0</span>
                          </div>
                          <div className="label">
                            <p>
                              Errored
                            </p>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </MDBCol>
              </MDBRow>
              <MDBRow className='m-t--md m-b--md p-b--lg height--full import-result-actions'>
                <MDBCol xl='6' lg='6' className='p--0 p-t--sm p-b--sm m--0 border-right'>
                  <div className="p-t--0 m-t--0 height--full">
                    <div className="p--md">
                      <div className='create-view text-center'>
                        <p className='font-size-25 font-weight-500 font-family-crimson m-b--sm'>
                          Import Leads Successful
                        </p>
                        <p className='p-l--lg p-r--lg'>
                          You've successfully imported new leads.
                        </p>
                        {/*<Link to={`/project/${projectId}/leads/import/${importedLeads.data[0].import_id}`} className=''>*/}
                        {/*  <Button*/}
                        {/*    flat='true'*/}
                        {/*    background='orange' foreground='white'*/}
                        {/*    borderWidth={2} borderColor='orange'*/}
                        {/*    size={-2}*/}
                        {/*  >*/}
                        {/*    <MDBIcon icon='paper-plane' className='mr-2'/>*/}
                        {/*    <span className='color-white'>View Records</span>*/}
                        {/*  </Button>*/}
                        {/*</Link>*/}
                        <Link to={`/project/${projectId}/leads`} className=''>
                          <Button
                            flat='true'
                            background='orange' foreground='white'
                            borderWidth={2} borderColor='orange'
                            size={-2}
                          >
                            <MDBIcon icon='paper-plane' className='mr-2'/>
                            <span className='color-white'>View Leads</span>
                          </Button>
                        </Link>
                      </div>
                    </div>
                  </div>
                </MDBCol>
                <MDBCol xl='6' lg='6' className='p--0 p-t--sm p-b--sm m--0'>
                  <div className="p-t--0 m-t--0 height--full">
                    <div className="p--md">
                      <div className='create-view text-center'>
                        <p className='font-size-25 font-weight-500 font-family-crimson m-b--sm'>
                          Want to Add More Leads?
                        </p>
                        <p className='p-l--lg p-r--lg'>
                          Upload another CSV file.
                        </p>
                        <Link to={`/project/${projectId}/leads/import`} className=''>
                          <Button
                            flat='true'
                            background='orange' foreground='white'
                            borderWidth={2} borderColor='orange'
                            size={-2}
                          >
                            <Icon name='upload' />
                            <span className='color-white'>Import Again</span>
                          </Button>
                        </Link>
                      </div>
                    </div>
                  </div>
                </MDBCol>
              </MDBRow>
            </MDBCol> :
              <MDBCol className='sub-body-container background-color-white background-rectangle m-t--md p--0'>
                <MDBRow className='sub-body-container height--full'>
                  <MDBCol md='auto' lg='12' className='sub-body-container'>
                    <div className='loader-container'>
                      <div className="loader dark">Loading...</div>
                    </div>
                  </MDBCol>
                </MDBRow>
              </MDBCol>
            }
          </MDBCol>
        </MDBRow>
      </MDBContainer>
    );
  }
}

function mapState(state) {
  const { leads } = state;
  const { importedLeads, imported } = state.leads;
  return { leads, importedLeads, imported };
}

const actionCreators = {
};

const connectedViewImportResult = connect(mapState, actionCreators)(ImportResult);
export { connectedViewImportResult as ImportResult };
