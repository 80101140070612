import React from 'react';
import { connect } from "react-redux";

import { leadActions } from '_actions';
import { utilitiesService as utility } from '_services';

import { MDBIcon } from 'mdbreact';

import avatar from '_assets/images/avatar-fem.png';

import { Link } from "react-router-dom";
import Moment from "react-moment";




class LeadViewRecentActivities extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      projects: []
    };
  }

  renderActivityLog = (activity, isLastLog) => {

    let origin = '';

    let icon = '';
    let logLabel = '';
    let logLabelLast = '';
    let hasLink = false;
    let link = '';
    let hasAvatar = false;
    let activityLog = '';
    let needDecrypt = false;

    if(this.props.leads.leadLoaded) {
      origin = this.props.leads.lead.data.origin;
    }

    switch (activity.name) {
      case 'LEAD_CREATED': {
        logLabel = `Lead generated from `;
        activityLog = origin;
        icon = 'user';
        hasLink = false;
        link = '';
        hasAvatar = false;
        break;
      }
      case 'LEAD_IMPORTED': {
        logLabel = 'Lead Imported to ';
        activityLog = activity.action.replace('Lead imported to ',''); // Project Name
        icon = 'user';
        hasLink = false;
        link = '';
        hasAvatar = false;
        break;
      }
      case 'LEAD_SUBMITTED': {
        logLabel = 'Lead Submitted to ';
        activityLog = activity.action.replace('Lead submitted to ',''); // Form Name
        icon = 'user';
        hasLink = false;
        link = '';
        hasAvatar = false;
        break;
      }
      case 'BECAME_LEAD': {
        logLabel = 'Became ';
        activityLog = activity.action.replace('Became','').replace('Lead','');
        logLabelLast = ' Lead';
        icon = 'user';
        hasLink = false;
        link = '';
        hasAvatar = false;
        break;
      }
      case 'OWNER_ASSIGNED': {
        logLabel = 'Owner assigned to ';
        needDecrypt = true;
        activityLog = activity.action.replace('Owner assigned to ','');
        icon = 'user';
        hasLink = false;
        link ='';
        hasAvatar = false;
        break;
      }
      case 'RESPONSIBLE_ASSIGNED': {
        logLabel = 'Assigned to ';
        needDecrypt = true;
        activityLog = activity.action.replace('Responsible assigned to ','');
        icon = 'user';
        hasLink = false;
        link ='';
        hasAvatar = false;
        break;
      }
      case 'LEAD_INFO_EDITED': {
        logLabel = 'Edited Lead Info:';
        activityLog = activity.action.replace('Edited Lead info: ','');
        icon = 'edit';
        hasLink = false;
        link ='';
        hasAvatar = false;
        break;
      }
      case 'PRIORITY_EDITED': {
        logLabel = 'Changed Priority to ';
        activityLog = activity.action.replace('Changed Priority to','');
        icon = 'edit';
        hasLink = false;
        link ='';
        hasAvatar = false;
        break;
      }
      case 'STATUS_CHANGED': {
        logLabel = 'Changed Status to ';
        activityLog = activity.action.replace('Changed Status to ','');
        icon = 'user';
        hasLink = false;
        link ='';
        hasAvatar = false;
        break;
      }
      case 'NOTE_CREATED': {
        // logLabel = 'New Note: ';
        activityLog = 'New note added';
        icon = 'clipboard';
        hasLink = false;
        link ='';
        hasAvatar = false;
        break;
      }
      case 'NOTE_DELETED': {
        logLabel = 'Deleted Note: ';
        activityLog = activity.action.replace('Deleted note: ','');
        icon = 'clipboard';
        hasLink = false;
        link ='';
        hasAvatar = false;
        break;
      }

      case 'FILE_CREATED': {
        // logLabel = 'New Note: ';
        activityLog = 'New file added';
        icon = 'clipboard';
        hasLink = false;
        link ='';
        hasAvatar = false;
        break;
      }

      case 'FILE_EDITED': {
        // logLabel = 'New Note: ';
        activityLog = 'File edited';
        icon = 'clipboard';
        hasLink = false;
        link ='';
        hasAvatar = false;
        break;
      }

      case 'FILE_DELETED': {
        // logLabel = 'New Note: ';
        activityLog = 'Removed file';
        icon = 'clipboard';
        hasLink = false;
        link ='';
        hasAvatar = false;
        break;
      }

      case 'LEAD_SCHEDULE_INVITE': {
        logLabel = '';
        activityLog = activity.action;
        icon = 'calendar';
        hasLink = false;
        link ='';
        hasAvatar = false;
        break;
      }

      case 'LEAD_SCHEDULED': {
        logLabel = 'Lead set an event schedule for';
        activityLog = activity.action.split(';')[0].replace('Lead set an event schedule for','');
        icon = 'calendar';
        hasLink = true;
        link =activity.action.split(';')[1].split('=')[1];
        hasAvatar = false;
        break;
      }

      case 'LEAD_CANCELED': {
        logLabel = 'Lead scheduled for ';
        activityLog = activity.action.split(';')[0].replace('Lead scheduled for ','');
        icon = 'calendar';
        hasLink = false;
        link =''
        hasAvatar = false;
        break;
      }

      case 'LEAD_RESCHEDULED': {
        logLabel = 'Lead scheduled for';
        activityLog = activity.action.split(';')[0].replace('Lead scheduled for','');
        icon = 'calendar';
        hasLink = true;
        link =activity.action.split(';')[1].split('=')[1];
        hasAvatar = false;
        break;
      }

      case null: case '': {
        logLabel = '';
        activityLog = activity.action.replace('Sellers Portal: ','');
        icon = 'clipboard';
        hasLink = false;
        link ='';
        hasAvatar = false;
        break;
      }
      default: {
        return null;
      }
    }

    return (
      <div className='activity-box'>
        <div className='activity-icon-group'>
          <div className='icon-box'>
            <MDBIcon far icon={icon} />
          </div>
          { isLastLog || <div className='line-box' /> }
        </div>
        <div className='activity-detail-group'>
          <div className='main-details'>
            <span>{logLabel} </span>
            {/* { hasAvatar ?
              // TODO: Replace avatar once it is available on the member response
              <img src={avatar} alt='' className='width-20 border-radius-50 mr-1' /> : null
            } */}
            { hasLink ?
              <a href={link} target="_blank" >
                {needDecrypt ? utility.decrypt(activityLog) : activityLog}
              </a>
              :
              <span className='highlight-text'>{needDecrypt ? utility.decrypt(activityLog) : activityLog}</span>
            }
            { logLabelLast &&
            <span className='highlight-text'>{logLabelLast} </span>
            }
          </div>
          <div className='sub-details'>
            <span>
              { activity.sender ?
                <span>
                  {utility.decrypt(activity.sender.first_name)} {utility.decrypt(activity.sender.last_name)} &nbsp;
                </span>
                : 'Propelrr LMS System'
              }
              <MDBIcon icon='circle' />
            </span>
            <span>
              {/* <Moment className='' date={activity.created} fromNow ago/> ago */}
              <Moment date={activity.created} format="MMM D, YYYY h:mm A"/>
            </span>
          </div>
        </div>
      </div>
    );
  };

  render() {
    const {activityLogLoaded, lead, leads, activityLog } = this.props;
    let list = [];

    if(activityLogLoaded) {
      list = activityLog.data;
    }

    //let project = {};
    // if(activityLog !== undefined){
    //   if(this.props.projects.items !== undefined){
    //     if(this.props.projects.items.data){
    //       project = this.props.projects.items.data;
    //     }
    //   }
    // }

    //let activities = project.activity;
    let isLastLog = false;

    let hasData = false;
    if(list !== undefined){
      if(list.length > 0)  {
        hasData = true;
        list = list.map((val, ind) => {
          return { ind, val }
        }).sort((a, b) => {
          return a.val < b.val ? -1 : a.val === b.val ? 0 : 1
        }).map((obj) => obj.val);
      }
    }

    return (
      <div className='recent-activity-box'>
        {!activityLogLoaded && 
        <div className='loading-box'>
          <div class="lds-ellipsis"><div></div><div></div><div></div><div></div></div>
        </div>
        }

        {activityLogLoaded && 
          <ul className='list-unstyled'>
          { hasData ?
            list.reverse().map((value, index) => {
              // if(index + 1 < 10){
              //   if(index + 1 === activityLog.length){
              //     isLastLog = true;
              //   }
              // } else {
              //   if(index + 1 === 10){
              //     isLastLog = true;
              //   }
              // }

              return (
                <li key={index}>
                  { this.renderActivityLog(value, isLastLog) }
                </li>
              )
            }) : null }
        </ul>
        }
        
        
      </div>
    );
  };
}


function mapState(state) {
  const { leads,  activities } = state;
  const {activityLogLoaded, lead, activityLog} = leads;
  return { leads,  activities, activityLogLoaded, lead, activityLog };
}

const actionCreators = {
  getActivityLog: leadActions.getActivityLog
};

const connectedViewProjects = connect(mapState, actionCreators)(LeadViewRecentActivities);
export { connectedViewProjects as LeadViewRecentActivities };
