import React, {Component} from "react";
import {influencerActions, projectActions} from "_actions";
import {connect} from "react-redux";
import {MDBCol, MDBContainer, MDBModal, MDBModalBody, MDBModalHeader, MDBRow} from "mdbreact";
import FormGroupTitle from "_pages/Propelrr/components/Form/FormGroupTitle";
import InputForm from "_pages/Propelrr/components/Form/InputForm";
import {utilitiesService} from "_services";

class EditInfluencerModal extends Component {
    constructor(props) {
        super(props);

        this.state = {
            name: '',
            email: '',
            contact: '',
            contacted: '',
            handlerName: '',
            handlerEmail: '',
            handlerContact: '',
        }
    }

    setName(value) {
        this.setState({name: value});
    }

    setEmail(value) {
        this.setState({email: value});
    }

    setContact(value) {
        this.setState({contact: value});
    }

    setContacted(value) {
        this.setState({contacted: value});
    }

    setHandlerName(value) {
        this.setState({handlerName: value});
    }

    setHandlerEmail(value) {
        this.setState({handlerEmail: value});
    }

    setHandlerContact(value) {
        this.setState({handlerContact: value});
    }

    handleCloseEditInfluencerModal () {
        const {onHandleCloseEditInfluencerModal} = this.props;
        onHandleCloseEditInfluencerModal();
    }

    handleUpdateInfluencer() {

        const {projectId, influencerId, updateInfluencer, data} = this.props;
        const {name, email, contact, contacted, handlerName, handlerContact, handlerEmail} = this.state;

        const influencer = {
            name: name !== '' ? name : data.name,
            contacted: contacted !== '' ? contacted : data.contacted,
            email:  email !== '' ? utilitiesService.encrypt(email) : data.email,
            contact: contact !== '' ? utilitiesService.encrypt(contact) : data.contact,
            handler_name:  handlerName !== '' ? utilitiesService.encrypt(handlerName) : data.handler.name,
            handler_contact: handlerContact !== '' ?  utilitiesService.encrypt(handlerContact) : data.handler.contact,
            handler_email: handlerEmail !== '' ?  utilitiesService.encrypt(handlerEmail) : data.handler.email,
        }

        updateInfluencer(projectId, influencerId,  influencer);

        this.handleCloseEditInfluencerModal();

        this.setState({
            name: '',
            email: '',
            contact: '',
            contacted: '',
            handlerName: '',
            handlerEmail: '',
            handlerContact: '',
        });

    }

    render() {

        const {isOpen, data, influencerLoaded} = this.props;

        let name = '';
        let email = '';
        let contact = '';
        let contacted = '';
        let handlerName = '';
        let handlerContact = '';
        let handlerEmail = '';
        handlerName = '';
        handlerContact = '';
        handlerEmail = '';
        let defaultDate = '';

        if(influencerLoaded) {

             name = data.name;
             email = utilitiesService.decrypt(data.email);
             contact = utilitiesService.decrypt(data.contact);
             contacted = data.contacted;

             if(contacted) {
                defaultDate = new Date(contacted);
                defaultDate = defaultDate.toISOString().substring(0, 10);
             }


            if(data.handler){
                handlerName =  utilitiesService.decrypt(data.handler.name);
                handlerContact = utilitiesService.decrypt(data.handler.contact);
                handlerEmail = utilitiesService.decrypt(data.handler.email);
            }

        }


        return (

            <MDBContainer className='modal-container modal-form-container modal-form-influencer'>
                <MDBRow>
                    <MDBCol>
                        <MDBModal isOpen={isOpen} centered tabIndex='-1'>
                            <MDBModalHeader className='confirmation-modal-header text-uppercase p-2'><span className='pl-1 mx-2 letter-spacing-1 font-weight-600 font-color-gray'>EDIT INFLUENCER</span></MDBModalHeader>
                            <MDBModalBody>
                                <form id="" className="form-add-influencer">
                                    <MDBRow>
                                        <MDBCol xl='12' lg='12' className='form-group-box'>
                                            <FormGroupTitle label="Influencer Information" />
                                            <div className='input-group-box half-box-'>
                                                <InputForm
                                                    defaultValue={name}
                                                    label='Influencer Name'
                                                    type='text'
                                                    id='name'
                                                    name='name'
                                                    onChange={(e) => this.setName(e.target.value)}
                                                />
                                                <InputForm
                                                    defaultValue={email}
                                                    label='Email Address'
                                                    type='email'
                                                    id='email'
                                                    name='email'
                                                    onChange={(e) => this.setEmail(e.target.value)}
                                                />
                                                <InputForm
                                                    defaultValue={contact}
                                                    label='Phone Number'
                                                    type='tel'
                                                    id='contact'
                                                    name='contact'
                                                    onChange={(e) => this.setContact(e.target.value)}
                                                />
                                                <InputForm
                                                    defaultValue={defaultDate}
                                                    label='Date Contacted'
                                                    type='date'
                                                    id='contacted'
                                                    name='contacted'
                                                    onChange={(e) => this.setContacted(e.target.value)}
                                                />
                                            </div>
                                            <div className='input-group-box full-box-'>
                                                <InputForm
                                                    defaultValue={handlerName}
                                                    label='Handler Name'
                                                    type='text'
                                                    id='handler_name'
                                                    name='handler_name'
                                                    onChange={(e) => this.setHandlerName(e.target.value)}
                                                />
                                                <InputForm
                                                    defaultValue={handlerEmail}
                                                    label='Email Address'
                                                    type='email'
                                                    id='handler_email'
                                                    name='handler_email'
                                                    onChange={(e) => this.setHandlerEmail(e.target.value)}
                                                />
                                                <InputForm
                                                    defaultValue={handlerContact}
                                                    label='Phone Number'
                                                    type='tel'
                                                    id='handler_contact'
                                                    name='handler_contact'
                                                    onChange={(e) => this.setHandlerContact(e.target.value)}
                                                />
                                            </div>
                                        </MDBCol>
                                    </MDBRow>
                                </form>
                                <MDBRow>
                                    <MDBCol className='text-center'>
                                        <button className='modal-btn save-btn-modal'  onClick={() => this.handleUpdateInfluencer() }>
                                            Save Influencer
                                        </button>
                                        <button className='modal-btn cancel-btn-modal' onClick={() => this.handleCloseEditInfluencerModal() }>
                                            Cancel
                                        </button>
                                    </MDBCol>
                                </MDBRow>
                            </MDBModalBody>
                        </MDBModal>
                    </MDBCol>
                </MDBRow>
            </MDBContainer>
        );
    }


}

function mapState(state) {
    const { influencers } = state;
    const {influencerLoaded } = influencers;
    return { influencers,  influencerLoaded}
}

const actionCreators = {
    getProject: projectActions.getProject,
    updateInfluencer : influencerActions.updateInfluencer
};

const connectedViewEditInfluencerModal = connect(mapState, actionCreators)(EditInfluencerModal);
export { connectedViewEditInfluencerModal as EditInfluencerModal };