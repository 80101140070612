import { chatConstants } from "_constants";

export function chat(state = {}, action) {
  switch (action.type) {
    case chatConstants.GET_ALL_CHATS_REQUEST:
      return {
        ...state,
        getChatsLoading: true,
        getChatsLoaded: false,
      };
    case chatConstants.GET_ALL_CHATS_SUCCESS:
      return {
        ...state,
        getChatsLoading: false,
        getChatsLoaded: true,
        getChatNotification: false,
        chats: action.chats.data,
      };
    case chatConstants.GET_ALL_CHATS_FAILURE:
      return {
        ...state,
        getChatsLoading: false,
        getChatsLoaded: true,
        error: action.error,
      };

    case chatConstants.CHAT_NOTIFICATION_REQUEST:
      return {
        ...state,
        getChatNotification: true,
      };

    case chatConstants.UPDATE_CHATS_UNREAD_REQUEST:
      return {
        ...state,
        getChatsLoading: true,
        getChatsLoaded: false,
      };
    case chatConstants.UPDATE_CHATS_UNREAD_SUCCESS:
      return {
        ...state,
        getChatsLoading: false,
        getChatsLoaded: true,
        chats: action.chats.data,
      };
    case chatConstants.UPDATE_CHATS_UNREAD_FAILURE:
      return {
        ...state,
        getChatsLoading: false,
        getChatsLoaded: true,
        error: action.error,
      };

    case chatConstants.CHAT_REPLY_REQUEST:
      return {
        ...state,
        getChatsLoading: true,
        getChatsLoaded: false,
      };
    case chatConstants.CHAT_REPLY_SUCCESS:
      return {
        ...state,
        //  getChatsLoading: false,
        getChatsLoaded: true,
        chats: action.chats.data,
      };
    case chatConstants.CHAT_REPLY_FAILURE:
      return {
        ...state,
        getChatsLoading: false,
        getChatsLoaded: true,
        error: action.error,
      };
    default:
      return {
        ...state,
      };
  }
}
