import React, {Component} from 'react'
import {leadActions} from "_actions";
import {connect} from "react-redux";


class LeadStatusDropdown extends Component {

    constructor(props) {
        super(props);
        this.filterRef = React.createRef();

        this.state = {
            filterToggled: false,
        }

    }

    componentDidUpdate = () => {
        if(this.state.checkedItems !== this.props.selectedStatus && this.state.checkedItems === []) {
            this.setState({checkedItems: this.props.selectedStatus});
        }
    }

    handleOpenFilter() {
        this.setState({filterToggled: true})
    }

    handleCloseFilter() {
        this.setState({filterToggled: false})
    }

    handleDropdown = value => {
        if (!this.filterRef.current.contains(value.target)) {
            this.setState({filterToggled: false})
        }
    }

    handleUpdateLeadStatus = value => {
        this.props.onHandleUpdateLeadStatus(value);
        this.setState({filterToggled: false})
    }


    componentDidMount() {
        document.addEventListener('click', this.handleDropdown, true);
    }

    componentWillUnmount() {
        document.removeEventListener('click', this.handleDropdown, true);
    }

    render() {

        const {page, summaryLoaded, lead} = this.props;

        let items = [];
        let categories = [];
        if (summaryLoaded) {
            items = page.data.reduce(function (collection, elem) {
                let key = elem['category'];
                if (!categories.includes(key)) {
                    categories.push(key);
                }

                (collection[key] = collection[key] || []).push(elem);
                return collection;
            }, []);
        }

        let status = lead.project_status;
        let category = status.category;
        let statusColor = categories.indexOf(category) + 1;

        return (
            <div className='filter-box' ref={this.filterRef}>
                <div className='filter-btn status-btn'>
                    <span className={`status-color-${statusColor}`} onClick={() => this.handleOpenFilter()}>
                        {lead.project_status.name.replace(/\b\w/g, l => l.toUpperCase())}
                    </span>
                </div>
                <div className={this.state.filterToggled ? 'filter-dropdown active' : 'filter-dropdown'}>
                    <div className='filter-title'>
                        <p>Status</p>
                        <i className='icon-lms icon-close' onClick={() => this.handleCloseFilter()}></i>
                    </div>
                    <div className='filter-list status-list'>
                        {categories.map((value, index) => {
                                let color = index + 1;
                                return (
                                    <div className='filter-group' key={index}>
                                        <span  className={`filter-name filter-color-${color}`}>{value}</span>
                                        {items[value].map((item, index) => {

                                            return (
                                                <div key={`filter-dropdown-${index}`} className='filter-name-list'
                                                    onClick={() => this.handleUpdateLeadStatus(item.id)}>
                                                        <span>{item.label}</span>
                                                </div>
                                            );

                                        })}
                                    </div>)
                        })}
                    </div>
                </div>
            </div>
        );
    }
}


function mapState(state) {
    const {page, summaryLoaded} = state.summary
    return {page, summaryLoaded};
}

const actionCreators = {
    getLeadSummary: leadActions.getLeadSummary,
};

const connectedViewFilterDropdown = connect(mapState, actionCreators)(LeadStatusDropdown);
export {connectedViewFilterDropdown as LeadStatusDropdown};