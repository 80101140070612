import React from 'react'
import { MDBContainer, MDBCol, MDBRow, MDBBtn } from 'mdbreact'
import Input from '_components/Input';
import logo from '_assets/images/logo.png'
import {Link} from 'react-router-dom'
import {projectActions, userActions} from "_actions";
import {connect} from "react-redux";
import { utilitiesService as utility } from '_services';
import {FormValidation} from "_components/FormValidation";
import {Button, Label} from "blackbox-react";

class UpdatePassword extends React.Component {
    constructor(props) {
        super(props);

        this.state = {
            accountData: {},
            user: {
                email: sessionStorage.getItem('email'),
                oldPassword: '',
                newPassword: '',
                confirmPassword: ''
            },
            //emailValid: false,
            oldPasswordValid: false,
            newPasswordValid: false,
            confirmPasswordValid: false,
            formValid: false,
            submitted: false,
            formErrors: {
                oldPassword: 'Please enter your current password.',
                newPassword: 'Please enter your new password.',
                confirmPassword: 'Please enter your new password again.'
            },
        }

        this.handleChange = this.handleChange.bind(this);
        this.handleUpdate = this.handleUpdate.bind(this);
    }


    handleChange(e) {
        e.preventDefault()
        const { name, value } = e.target;
        const { user } = this.state;
        let valueToSubmit = utility.encrypt(value);

        this.setState({
            user: {
                ...user,
                [name]: valueToSubmit
            }

        }, () => {
            this.validateField(name, valueToSubmit);
        });
    }

    validateField(fieldName, value) {
        value = utility.decrypt(value);
        let fieldValidationErrors = this.state.formErrors;

        let oldPasswordValid = this.state.oldPasswordValid;
        let newPasswordValid = this.state.newPasswordValid;
        let confirmPasswordValid = this.state.confirmPasswordValid;

        let isAllWhitespace = (value.replace(/(?!^ +$)^.+$/gm, "") !== '');

        switch(fieldName) {
            case 'oldPassword':
                if (value === "" || isAllWhitespace) { // check if empty
                    oldPasswordValid = false;
                    fieldValidationErrors.oldPassword = 'Please enter your old password.';
                }
                else {
                    oldPasswordValid = (value.length >= 6); // check if valid
                    fieldValidationErrors.oldPassword = oldPasswordValid ? '': 'Your password is incorrect. Try again.';
                }
                break;
            case 'newPassword':
                if (value === "" || isAllWhitespace) { // check if empty
                    newPasswordValid = false;
                    fieldValidationErrors.newPassword = 'Please enter your new password.';
                }
                if (value === '^(?=.*[a-z])(?=.*[A-Z])(?=.*\d)(?=.*[@$!%*?&])[A-Za-z\d@$!%*?&]{8,}$') {
                    newPasswordValid = false;
                    fieldValidationErrors.newPassword = 'Your password should have the following: 8 to 15 characters which contain at least one lowercase letter, one uppercase letter, one numeric digit and one special character'
                }
                else {
                    newPasswordValid = (this.state.user.oldPassword !== this.state.user.newPassword); // check if matches
                    fieldValidationErrors.newPassword = newPasswordValid ? '': 'Your new password is the same as your previous password. Please create a new password.' ;
                }
                break;
            case 'confirmPassword':
                if (value === "" || isAllWhitespace) { // check if empty
                    confirmPasswordValid = false;
                    fieldValidationErrors.confirmPassword = 'Please enter your new password again.';
                }
                if(value === '^(?=.*[a-z])(?=.*[A-Z])(?=.*\d)(?=.*[@$!%*?&])[A-Za-z\d@$!%*?&]{8,}$') {
                    confirmPasswordValid = false;
                    fieldValidationErrors.confirmPassword = 'Your password should have the following: 8 to 15 characters which contain at least one lowercase letter, one uppercase letter, one numeric digit and one special character'
                }
                else {
                    confirmPasswordValid = (this.state.user.newPassword === this.state.user.confirmPassword); // check if matches
                    fieldValidationErrors.confirmPassword = confirmPasswordValid ? '': 'Your passwords do not match, please enter the same password you entered before.';
                }
                break;
            default:
                break;
        }

        this.setState({
            formErrors: fieldValidationErrors,
            oldPasswordValid: oldPasswordValid,
            newPasswordValid: newPasswordValid,
            confirmPasswordValid: confirmPasswordValid,
        }, this.validateForm);
    }

    validateForm() {
        this.setState({
            formValid: this.state.oldPasswordValid && this.state.newPasswordValid && this.state.confirmPasswordValid
        }, () => {
        });
    }

    handleUpdate(e) {
        e.preventDefault();

        const { user } = this.state;
        const {updatePassword} = this.props;

        this.validateForm();
        this.setState(
            { submitted: true }, () => {
                if (this.state.formValid) {
                    if (this.state.submitted) {
                        let account = {
                            email: user.email,
                            old_password: user.oldPassword,
                            new_password: user.newPassword,
                            confirm_password: user.confirmPassword
                        }
                        //userActions.updatePassword(account);
                        updatePassword(account);
                    }
                }
            }

        )

    }

    errorClass(error) {
        return(error.length === 0 ? 'is-passed' : 'is-failed');
    }

    render() {
        const { user, submitted, formValid } = this.state;

        return (
            <MDBContainer fluid className='update-password-background'>
                <div className='update-password-panel'>
                    <MDBRow>
                        <MDBCol>
                            <img src={logo} alt='Propelrr Platform' className='login-logo' style={{width:'250'}}/>
                        </MDBCol>
                    </MDBRow>
                    <MDBRow>
                        <MDBCol>
                            <form onSubmit={this.handleUpdate}>
                                {/*<MDBRow>
                                    <MDBCol>
                                        <div className={`form-group ${submitted && this.errorClass(this.state.formErrors.email)}`}>
                                        <Input
                                            id='email-input-change'
                                            inputClass={this.state.class? 'custom-form-control': 'custom-form-control'}
                                            hint='Enter Email Address'
                                            name='email'
                                            type='email'
                                            required={true}
                                            value={user.email}
                                            onChange={this.handleChange.bind(this)}
                                        />
                                        <div className={`form-validation__container ${(!formValid && submitted) ? 'display-block' : 'display-none'}`}>
                                            <FormValidation fieldName='email' formErrors={this.state.formErrors}/>
                                        </div>
                                        </div>
                                    </MDBCol>
                                </MDBRow>*/}
                                <MDBRow>
                                    <MDBCol>
                                        <div className={`form-group ${submitted && this.errorClass(this.state.formErrors.oldPassword)}`}>
                                        <Input
                                            id='old-password-input-forgot'
                                            inputClass={this.state.class? 'custom-form-control': 'custom-form-control'}
                                            hint='Enter Old Password'
                                            name='oldPassword'
                                            type='password'
                                            required={true}
                                            value={user.oldPassword}
                                            onChange={this.handleChange.bind(this)}
                                        />
                                        <div className={`form-validation__container ${(!formValid && submitted) ? 'display-block' : 'display-none'}`}>
                                            <FormValidation fieldName='oldPassword' formErrors={this.state.formErrors}/>
                                        </div>
                                        </div>
                                    </MDBCol>
                                </MDBRow>
                                <MDBRow>
                                    <MDBCol>
                                        <div className={`form-group ${submitted && this.errorClass(this.state.formErrors.newPassword)}`}>
                                        <Input
                                            id='new-password-input-forgot'
                                            inputClass={this.state.class? 'custom-form-control': 'custom-form-control'}
                                            hint='Enter New Password'
                                            name='newPassword'
                                            type='password'
                                            required={true}
                                            value={user.newPassword}
                                            onChange={this.handleChange.bind(this)}
                                        />
                                        <div className={`form-validation__container ${(!formValid && submitted) ? 'display-block' : 'display-none'}`}>
                                            <FormValidation fieldName='newPassword' formErrors={this.state.formErrors}/>
                                        </div>
                                        </div>
                                    </MDBCol>
                                </MDBRow>
                                <MDBRow>
                                    <MDBCol>
                                        <div className={`form-group ${submitted && this.errorClass(this.state.formErrors.confirmPassword)}`}>
                                        <Input
                                            id='confirm-password-input-forgot'
                                            inputClass={this.state.class? 'custom-form-control': 'custom-form-control'}
                                            hint='Enter your password again'
                                            name='confirmPassword'
                                            type='password'
                                            value={user.confirmPassword}
                                            required={true}
                                            onChange={this.handleChange.bind(this)}
                                        />
                                        <div className={`form-validation__container ${(!formValid && submitted) ? 'display-block' : 'display-none'}`}>
                                            <FormValidation fieldName='confirmPassword' formErrors={this.state.formErrors}/>
                                        </div>
                                        </div>
                                    </MDBCol>
                                </MDBRow>
                                <MDBRow>
                                    <MDBCol>
                                        {/*<MDBBtn flat type='submit' className='send-btn p-2 font-weight-600' > SUBMIT  </MDBBtn>*/}
                                        <div className="form-group">
                                            <Button
                                                type='submit'
                                                className='background-btn'
                                            > 
                                            Submit
                                            </Button>
                                            <Link to='/' className='bordered-btn'>Cancel</Link>
                                        </div>
                                    </MDBCol>
                                </MDBRow>
                                {/*<MDBRow>
                                    <MDBCol className='text-center mt-2'>
                                        <hr className='mt-2 mb-2 forgot-hr'/>
                                        <label className='font-smal text-decoration-none signup-here-text'>Go back to</label><Link to='/login'><span className='text-login-here'> Login here</span></Link>
                                    </MDBCol>
                                </MDBRow>*/}
                            </form>
                        </MDBCol>
                    </MDBRow>
                </div>
            </MDBContainer>
        )
    }

}

function mapState(state) {
    const { updatingPassword } = state.users;
    return { updatingPassword };
}

const actionCreators = {
    updatePassword: userActions.updatePassword
};

const connectedUpdatePasswordPage = connect(mapState, actionCreators)(UpdatePassword);
export { connectedUpdatePasswordPage as UpdatePassword };