import React from "react";

const BreadcrumbLoader = () => {
  return (
    <div className="skeleton-loader skeleton-breadcrumb">
        <div className="col-b">
            <div className="skeleton skeleton-box"></div>
        </div>
        <div className="col-b">
            <div className="skeleton skeleton-box btn-skeleton"></div>
            <div className="skeleton skeleton-box btn-skeleton"></div>
            <div className="skeleton skeleton-box btn-skeleton"></div>
        </div>
    </div>
  );
};

export default BreadcrumbLoader;
