

export const influencerConfig = [
    {
        title : 'Influencer',
        width : '8%',
    },
    {
        title : 'Status',
        width : '5%',
    },
    {
        title : 'Platform',
        width : '8%',
    },
    {
        title : 'Category',
        width : '6%',
    },
    {
        title : 'Date Contacted',
        width : '5%',
    },
    {
        title : 'Client',
        width : '5%',
    },
    {
        title : 'Created',
        width : '5%',
    },
];

// export const defaultConfig = [
//     "Influencer",
//     "Status",
//     "Platform",
//     "Category",
//     "Date Contacted",
//     "Client",
//     "Created"
// ];