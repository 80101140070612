import {leadConstants} from "_constants";

export function summary(state = {}, action) {
    switch (action.type) {
        /*
       GET LEAD SUMMARY
        */
        case leadConstants.GET_SUMMARY_REQUEST:
            return {
                ...state,
                loadingHeader: true,
                summaryLoaded: false
            };
        case leadConstants.GET_SUMMARY_SUCCESS:
            return {
                ...state,
                loadingHeader: false,
                page: action.summary,
                summaryLoaded: true
            };
        case leadConstants.GET_SUMMARY_FAILURE:
            return {
                error: action.error
            };

        case leadConstants.GET_ORIGIN_REQUEST:
            return {
                ...state,
                originLoading: true,
                originLoaded: false
            };
        case leadConstants.GET_ORIGIN_SUCCESS:
            return {
                ...state,
                originLoading: false,
                originLoaded: true,
                origin: action.origin.data
            };
        case leadConstants.GET_ORIGIN_FAILURE:
            return {
                ...state,
                originLoading: false,
                originLoaded: true,
                error: action.error
            };

        default:
            return state

    }
}