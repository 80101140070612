import React from 'react';

// export const FormValidation = ({formErrors}) =>
//   <div className='form-errors'>
//     {Object.keys(formErrors).map((fieldName, i) => {
//       if(formErrors[fieldName].length > 0){
//         return (
//           <p key={i}>{fieldName.replace(/^\w/, c => c.toUpperCase())} {formErrors[fieldName]}.</p>
//         )
//       } else {
//         return '';
//       }
//     })}
//   </div>;

export const FormValidation = ({formErrors, fieldName}) =>
  <div className='form-errors'>
    { Object.keys(formErrors).map((data, i) => {
      if((formErrors[data].length > 0) && (data === fieldName)){
        return (
          <p key={i}>{formErrors[data]}</p>
        )
      } else {
        return '';
      }
    })}
  </div>;
