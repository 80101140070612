import React, {Component} from 'react';
import {fileActions} from "_actions";
import {connect} from "react-redux";
import Button from "_pages/Influencer/components/General/Button";
import {getInfluencerTypeClassColor, getInfluencerTypeClassIcon} from "_services";

class LeadFile extends Component {

    componentDidMount() {
        const {projectId, lead, leadFiles} = this.props;
    }


    openAddRecordModal = () => {
        this.props.onHandleManageFileModal('add', null);
    }

    openEditRecordModal = (records) => {
        this.props.onHandleManageFileModal('edit', records);
    }

    openDeleteConfirmationModal = (type, platform) => {
        this.props.onHandleOpenConfirmationModal(type, platform);
    }

    render() {

        const {
            classN,
            leadFiles,
            getFileLoaded,
            getFileLoading,
            files
        } = this.props;

        let records = [];
        let hasRecords = false;

        if (getFileLoaded) {
            records = files.data;
            hasRecords = records.length ? true : false;
        }

    
        return (
            <div className={classN}>
                <div className='inner-title-box'>
                    <p>
                        Document Links
                    </p>
                    {
                        getFileLoaded && hasRecords && 
                        <Button
                            text='Add Record'
                            icon='icon-lms icon-add'
                            onClick={ () => this.openAddRecordModal()}
                            classN="plain-btn"
                        />
                    }
                </div>

                {getFileLoaded && hasRecords &&
                <div className='records-list'>
                    {
                        records.map((record, index)=> {
                        return(
                            <div className='record-col'><div key={index} className='record'>
                                <div className='record-details'>
                                    <div className='record-info'>
                                        <span className='description'>Title</span>
                                        <p className='title'>{record.name}</p>
                                    </div>
                                    <div className='record-info'>
                                        <span className='description'>Description</span>
                                        <p className='title'>{record.description}</p>
                                    </div>
                                    <div className='record-info'>
                                        <span className='description'>Document Link</span>
                                        <p className='title'>
                                            <a href={record.link} target={'new'}>
                                                {record.link}
                                            </a>
                                        </p>
                                    </div>
                                </div>
                                <div className='record-btns'>
                                    <button
                                        className='c-btn c-btn-sm c-btn-light edit-record'
                                        onClick={() => this.openEditRecordModal(record)}>
                                        <i className="icon-lms icon-edit"></i>Edit
                                    </button>
                                    <button
                                        className='c-btn c-btn-sm c-btn-light delete-record'
                                        onClick={() => this.openDeleteConfirmationModal('file', record)}>
                                        <i className="icon-lms icon-delete"></i>Delete
                                    </button>
                                </div>
                            </div></div>);
                        })
                    }
                </div>
                }

                {getFileLoaded && !hasRecords && 
                    <div className='empty-tab-content'>
                        <div className='empty-content'>
                            <h7>Add Applicant File Here</h7>
                            <p>Files will appear here</p>
                            <span onClick={ () => this.openAddRecordModal()}>
                                <i className='icon-lms icon-add'></i>
                                Add File
                            </span>
                        </div>
                    </div>
                }

                {getFileLoading &&
                    <div className='loading-box'>
                        <div class="lds-ellipsis"><div></div><div></div><div></div><div></div></div>
                    </div>
                }
            </div>)
    }


}


function mapState(state) {
   // console.log('file state: ', state)
    const {file} = state;
    const {getFileLoading, getFileLoaded, files} = file;
    return {file, getFileLoading, getFileLoaded, files}
}

const actionCreators = {
    getLeadFiles: fileActions.getFiles,
};

const connectedViewLeadFile= connect(mapState, actionCreators)(LeadFile);
export {connectedViewLeadFile as LeadFile};
