import React from 'react'
import { MDBContainer, MDBRow, MDBIcon, MDBModal, MDBModalHeader, MDBModalBody, MDBBtn, MDBModalFooter } from 'mdbreact'
import { addButtons } from '_pages/Forms/constants/addFieldButtons'

export default class AddFieldModal extends React.Component {
  constructor(props) {
    super(props)
    this.state = {
      modalOpen: false,
      presets: addButtons,
      addInput: false,
      activeBtn: -1,
      viewText: 'VIEW MORE'
    }
  }

  componentDidMount = () => {
    this.setState({ modalOpen: this.props.modalOpen })
  }

  handleCancel = () => {
    this.props.modalToggle()
  }

  handleButtons = () => {
    return (
      this.state.presets.map((value, index) => {
        return (
          <MDBBtn key={value.buttonName} flat className={`${this.state.activeBtn === index ? 'dotted-red' : 'dotted'} font-size-10 text-left color-black px-3 py-2 font-weight-bold shadow-none`} onClick={() => { this.setState({ addInput: true, activeBtn: index }) }}>
            <MDBIcon icon={value.icon} className='mr-2' />
            {value.buttonName}
          </MDBBtn>
        )
      })
    )
  }

  scrollDown() {
    let { viewText } = this.state
    this.setState({ viewText: this.state.viewText === 'VIEW MORE' ? 'VIEW LESS' : 'VIEW MORE' })
    if (viewText === 'VIEW MORE') {
      document.getElementById('divButtons').scrollTop = document.getElementById('divButtons').scrollHeight
    } else {
      document.getElementById('divButtons').scrollTop = 0
    }
  }

  render() {
    let { modalOpen, modalToggle } = this.props,
      { viewText, addInput } = this.state
    return (
      <MDBContainer className='confirmation-modal-container'>
        <MDBModal className='confirmation-modal' toggle={modalToggle} isOpen={modalOpen} centered>
          <MDBModalHeader className='confirmation-modal-header text-uppercase p-2' toggle={modalToggle}>
            <span className='pl-1 mx-2 letter-spacing-1 font-weight-600 font-color-gray font-size-13'>SELECT FIELD</span>
          </MDBModalHeader>
          <MDBModalBody className='confirmation-modal-body pb-2 mb-2 mt-1'>
            <div id='divButtons' className={addInput ? 'height-89' : 'view-less'}>
              {this.handleButtons()}
            </div>
            <p onClick={() => { this.scrollDown() }} className={addInput ? 'mb-0 font-size-8 text-right position-absolute view-more-pos hand' : 'display-none'}>{viewText}<MDBIcon icon='angle-down' /></p>
            <div className={addInput ? '' : 'display-none'}>
              <div className='mx-2 mt-3'>
                <p className='font-size-16 mb-0'>Field Label<span className='color-red'>*</span></p>
                <input
                  className='border-gray mb-0 br-5 px-2 py-2 font-size-13'
                />
              </div>
              <div className='mx-2 mt-3'>
                <p className='font-size-16 mb-0'>Placeholder</p>
                <input
                  className='border-gray mb-0 br-5 px-2 py-2 font-size-13'
                />
              </div>
            </div>
          </MDBModalBody>
          <MDBModalFooter>
            <label className={addInput ? 'checkboxcontainer-checkLead ml-0 mt-less-3 float-left' : 'display-none'}>
              <input
                type='checkbox'
                name='check-box'
                onClick={() => {
                }}
              />
              <span id='addLeadCheck' className='checkmark' />
              Required
						</label>
            <MDBRow className='justify-content-between mx-1 font-size-10'>
              <MDBBtn onClick={() => this.handleCancel()} className='height-41 px-5 background-transparent shadow-none border-grey color-black py-2 br-5'>CANCEL</MDBBtn>
              <MDBBtn onClick={() => this.handleCancel()} className='disabled height-41 px-5 background-color-orange shadow-none border-grey color-white py-2 br-5'>ADD FIELD</MDBBtn>
            </MDBRow>
          </MDBModalFooter>
        </MDBModal>
      </MDBContainer>
    )
  }
}
