import React, { useState } from "react";
import {
  MDBContainer,
  MDBRow,
  MDBCol,
  MDBModal,
  MDBModalHeader,
  MDBModalBody,
} from "mdbreact";

const EmailModal = ({isOpen, closeSaveDraftModal, emailSaveDraft}) => {
  return (
    <MDBContainer className="modal-container confirmation-modal-container modal-save-draft">
      <MDBRow>
        <MDBCol>
          <MDBModal
            className="confirmation-modal"
            isOpen={isOpen}
            centered
            tabIndex="-1"
          >
            <MDBModalBody>
              <MDBRow className="form-group-row">
                <MDBCol className="col-12">
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    width="24"
                    height="24"
                    viewBox="0 0 24 24"
                    fill="none"
                    stroke="currentColor"
                    stroke-width="2"
                    stroke-linecap="round"
                    stroke-linejoin="round"
                    class="feather feather-alert-circle"
                  >
                    <circle cx="12" cy="12" r="10" />
                    <line x1="12" y1="8" x2="12" y2="12" />
                    <line x1="12" y1="16" x2="12.01" y2="16" />
                  </svg>
                  <h4>Saving this email to draft?</h4>
                  {/* <p>Closing the email will automatically save it to your drafts</p> */}
                </MDBCol>
              </MDBRow>
              <MDBRow>
                <MDBCol className="modal-btns text-center">
                  <button
                    className="c-btn c-btn-light"
                    onClick={closeSaveDraftModal}
                  >
                    No, discard email
                  </button>
                  <button
                    className="c-btn c-btn-blue"
                    onClick={emailSaveDraft}
                  >
                    Yes, save email to draft
                  </button>
                </MDBCol>
              </MDBRow>
            </MDBModalBody>
          </MDBModal>
        </MDBCol>
      </MDBRow>
    </MDBContainer>
  )
}

export default EmailModal