import React, { useState, useEffect, useRef } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useParams } from "react-router-dom";
import { MDBCol, MDBIcon, MDBRow } from "mdbreact";
import { calendlyActions } from "_actions/calendly.actions";
import { utilitiesService } from "_services";
import { managerActions } from "_actions";

const CalendlyConfigDrawer = ({ onHandleCloseAddInterval, autoConfigId, toggleCalendly }) => {
  const [addCalendly, setAddCalendly] = useState(false);
  const [email, setEmail] = useState("");
  const [link, setLink] = useState("");
  const [id, setId] = useState("");
  const [isUpdate, setIsUpdate] = useState(false);
  const [validationErrors, setValidationErrors] = useState({
    email: "",
    link: "",
  });

  const dispatch = useDispatch();
  const params = useParams();
  const prevIdRef = useRef(null);

  let data = useSelector((state) => state.calendly);

  // console.log("calendly: ", data);
  // console.log("email: ", email);
  // console.log("link: ", link);
  // console.log("addCalendly: ", addCalendly);
  // console.log('toggle: ', toggleCalendly);

  useEffect(() => {
    if (autoConfigId !== prevIdRef.current) {
      dispatch(calendlyActions.getCalendly(params.id, autoConfigId));

      // Update the ref with the current id
      prevIdRef.current = autoConfigId;
    }
  }, [autoConfigId, dispatch]);


  useEffect(() => {
    if(toggleCalendly == 2) {
        if (data.calendly && data.getcalendlyLoaded == true) {
            setEmail(data.calendly.email == null ? "" : utilitiesService.decrypt(data.calendly.email));
            setLink(data.calendly.link == null ? "" : utilitiesService.decrypt(data.calendly.link));
            setId(data.calendly.id == 0 ? "" : data.calendly.id);
            setAddCalendly(data.calendly.id ? true : false);
            setIsUpdate(data.calendly.id ? true : false);
            setValidationErrors({ deckName: "", deckLink: "" });
          }
      
          if (!data.calendly && data.getcalendlyLoaded == true) {
            setEmail("");
            setLink("");
            setId("");
            setAddCalendly(false);
            setIsUpdate(false);
            setValidationErrors({ deckName: "", deckLink: "" });
          }
    }
  }, [data.calendly, toggleCalendly]);

  const isValidURL = (url) => {
    // Regular expression for a basic URL validation
    const urlRegex = /^(https):\/\/[^ "]+$/;
    return urlRegex.test(url);
  };

  const handleEmail = (value) => {
    setEmail(value);

    // Reset validation error when the user types
    setValidationErrors({ ...validationErrors, email: "" });
  };

  const handleLink = (value) => {
    setLink(value);

    // Reset validation error when the user types
    setValidationErrors({ ...validationErrors, link: "" });
  };

  const openAddCalendly = () => {
    setAddCalendly(true);
  };
  const closeAddCalendly = () => {
    onHandleCloseAddInterval();
    setAddCalendly(false);
  };

  const handleRemoveCalendly = () => {

    if (id) {
        console.log("delete entry...");
        dispatch(calendlyActions.deleteCalendly(params.id, id));
        dispatch(managerActions.getManagers(params.id, null, 1, 15));
    }

  }

  const handleSaveCalendly = () => {
    // Validation
    const errors = {};

    if (!email || !email.trim()) {
      errors.email = "Email is empty";
    } else if (!/\S+@\S+\.\S+/.test(email)) {
      errors.email = "Invalid email format";
    }

    if (!link || !link.trim()) {
      errors.link = "Link is empty";
    } else if (!isValidURL(link)) {
      errors.link = "Invalid Link URL";
    }

    // If validation errors, update the state and return
    if (Object.keys(errors).length > 0) {
      setValidationErrors(errors);
      return;
    }

    // If validation passes, reset the errors
    setValidationErrors({ email: "", link: "" });

    console.log("id: ", id);

    if (id) {
      console.log("update entry...");
      const request = { id: id, email: utilitiesService.encrypt(email), link: utilitiesService.encrypt(link)};
      console.log("request: ", request);
      dispatch(calendlyActions.updateCalendly(params.id, id, request));
    } else {
      console.log("new entry...");
      const request = { email: utilitiesService.encrypt(email), link: utilitiesService.encrypt(link)};
      console.log("request: ", request);
      dispatch(
        calendlyActions.createCalendly(params.id, autoConfigId, request)
      );
      dispatch(managerActions.getManagers(params.id, null, 1, 15));
    }
  };

  return (
    <>
      {!data.getcalendlyLoading && addCalendly && (
        <React.Fragment>
          <div className="forms-tab">
            <div className="row">
              <div className="col-lg-12 form-group-box">
                <div className="form-group-title">
                  <label>Calendly Details</label>
                </div>
                <div className="input-group-box full-box-">
                  <div className="field-box">
                    <label htmlFor="fname">Email</label>
                    <div>
                      <input
                        type="email"
                        value={email}
                        onChange={(e) => handleEmail(e.target.value)}
                      />
                      {validationErrors.email && (
                        <p className="error-field">{validationErrors.email}</p>
                      )}
                    </div>
                  </div>
                </div>
                <div className="input-group-box full-box-">
                  <div className="field-box">
                    <label htmlFor="link">Calendly Invite Link</label>
                    <div>
                      <input
                        type="text"
                        value={link}
                        onChange={(e) => handleLink(e.target.value)}
                      />
                      {validationErrors.link && (
                        <p className="error-field">{validationErrors.link}</p>
                      )}
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div className="tab-form-btn-group">
            <MDBRow>
              <MDBCol className="text-right">
                {!isUpdate && (
                  <span
                    className="drawer-btn cancel-btn"
                    onClick={() => closeAddCalendly()}
                  >
                    Cancel
                  </span>
                )}
                {isUpdate && (
                  <span
                    className="drawer-btn delete-btn"
                    onClick={() => handleRemoveCalendly()}
                  >
                    Delete
                  </span>
                )}
                <span
                  className="drawer-btn save-btn"
                  onClick={handleSaveCalendly}
                >
                  Save Calendly
                </span>
              </MDBCol>
            </MDBRow>
          </div>
        </React.Fragment>
      )}
      {!data.getcalendlyLoading && !addCalendly && (
        <div className="empty-box">
          <h6>You don’t have a calendly invite link yet</h6>
          <p>Click the button below to add one.</p>
          <span className="plain-btn" onClick={() => openAddCalendly()}>
            <i className="icon-lms icon-add"></i>Add Calendly Invite
          </span>
        </div>
      )}
      {data.getcalendlyLoading && (
        <div className="loading-box">
          <div class="lds-ellipsis">
            <div></div>
            <div></div>
            <div></div>
            <div></div>
          </div>
        </div>
      )}
    </>
  );
};
export default CalendlyConfigDrawer;
