import React, { useRef, useState } from "react";
import DOMPurify from "dompurify";
import { utilitiesService, utilitiesService as utility } from "_services";
import Moment from "react-moment";
import NoteEditor from "_pages/Leads/components/LeadInfo/NoteEditor";
import { useParams } from "react-router-dom";
import { leadActions } from "_actions";
import { useDispatch } from "react-redux";

const ReplyItem = ({ reply, onHandleToggleDeleteNoteAction }) => {
  const params = useParams();
  const dispatch = useDispatch();

  let description = reply.description.replace(/(?:\r\n|\r|\n)/g, "<br>");
  let content = { __html: DOMPurify.sanitize(description) };
  let author =
    utility.decrypt(reply.owner.first_name) +
    " " +
    utility.decrypt(reply.owner.last_name);
  let initial = author.charAt(0).toUpperCase();
  let created = reply.created;
  let noteId = reply.id;

  let currentUser = utility.getSessionUser();
  let canManage = parseInt(reply.owner.id) === parseInt(currentUser.id);

  console.log(parseInt(reply.owner.id), parseInt(currentUser.id), canManage);

  const [editReplyClicked, setEditReplyClicked] = useState(false);

  const replyBtnActionRef = useRef();
  const replyRef = useRef();

  const [btnActionReplyClicked, setBtnActionReplyClicked] = useState(false);

  const [isEditorEmpty, setIsEditorEmpty] = useState(true); // Initialize with the appropriate initial value

  // Function to update isEditorEmpty state
  const setEditorEmpty = (isEmpty) => {
    setIsEditorEmpty(isEmpty);
  };

  const handleToggleReplyAction = () => {
    setBtnActionReplyClicked(!btnActionReplyClicked);
  };

  const handleToggleEditReply = () => {
    setEditReplyClicked(!editReplyClicked);
    setBtnActionReplyClicked(!btnActionReplyClicked);
  };

  const handleCancelUpdate = () => {
    setEditReplyClicked(false);
  };

  const handleReplyUpdate = (content, mentions) => {
    const projectId = params.id;
    const leadId = params.leadId;

    let noteData = {
      name: "Note",
      description: content,
      mentions: mentions,
    };

    let noteType = "note";
    dispatch(
      leadActions.updateLeadNote(projectId, leadId, noteType, noteId, noteData)
    );
    setEditReplyClicked(false);
  };

  const handleToggleDeleteNoteAction = () => {
    onHandleToggleDeleteNoteAction(reply);
  };

  return (
    <div className="thread-reply">
      <div className="thread-creator">
        <div className="avatar-box avatar-color-2">
          <span>{initial}</span>
          <p>{author}</p>
        </div>
        <div className="timestamp">
          {canManage && (
            <div className="edit-delete-box" ref={replyBtnActionRef}>
              <button
                className="fas fa-ellipsis-v btn-ellipsis"
                onClick={() => handleToggleReplyAction()}
              >
                <div
                  className={
                    btnActionReplyClicked ? "btn-groups active" : "btn-groups"
                  }
                >
                  <button onClick={() => handleToggleEditReply()}>
                    <i className="icon-lms icon-edit " />
                    Edit
                  </button>
                  <button onClick={() => handleToggleDeleteNoteAction()}>
                    <i className="icon-lms icon-delete" />
                    Delete
                  </button>
                </div>
              </button>
            </div>
          )}
        </div>
      </div>
      <div className="reply-box">
        {!editReplyClicked && (
          <div
            className="editor-display-text"
            dangerouslySetInnerHTML={content}
          ></div>
        )}

        {editReplyClicked && (
          <NoteEditor
            confirmButtonName={"Update Reply"}
            ref={replyRef}
            editorContent={description}
            onConfirmUpdate={(content, mentions) =>
              handleReplyUpdate(content, mentions)
            }
            onCancelUpdate={() => handleCancelUpdate()}
            setEditorEmpty={setEditorEmpty} // Pass the setEditorEmpty function as a prop
            isEditorEmpty={isEditorEmpty} // Pass the isEditorEmpty state as a prop
          />
        )}
      </div>
    </div>
  );
};

export default ReplyItem;
