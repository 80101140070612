import React, {Component} from 'react';
import {connect} from "react-redux";
import {Link} from 'react-router-dom';
import {formActions} from '_actions';

import {MDBCol, MDBContainer, MDBIcon, MDBRow} from 'mdbreact';
import {Button, ButtonGroup, Icon} from "blackbox-react";
//import Slider from '_pages/Forms/FormBuilder/components/Slider';
import InputButtons from '_pages/Forms/components/InputButtons';
import PresetsButton from '_pages/Forms/components/PresetsButton';

import {FormGroupManage} from '_pages/Forms/FormGroupManage';
import {quickPresets} from '_pages/Forms/constants/quickPresets';
import {inputsManage} from '_pages/Forms/constants/inputsManage';
import {EditFormModal} from '_pages/Forms/components/EditFormModal'
import {EditFormGroupUpdate} from '_pages/Forms/FormBuilder/components/EditFormGroupUpdate';
import {EditFormFieldUpdate} from '_pages/Forms/FormBuilder/components/EditFormFieldUpdate';
import CreateFormGroup from '_pages/Forms/FormBuilder/components/CreateFormGroup';

import settings from '_assets/images/cogs.svg';

class ManageFields extends Component {
  constructor(props) {
    super(props);

    const projectId = this.props.match.params.id;
    const projectData = JSON.parse(sessionStorage.getItem('project'));
    const projectForms = projectData.forms;
    const basicInfo = projectForms.find(item => item.is_default) || {};
    const formId = basicInfo.id || 0;
    this.props.getForm(projectId, formId);

    this.state = {
      formData: projectForms,
      projectId: '',
      formGroupData: [],
      fieldData: [],
      formsToMap: [],
      formGroupId: 0,

      activeFormGroup: '',
      activeFormField: '',
      active: 'Form Builder',
      activeFormIndex: '',
      activeSideBar: 'select',
      editFormModalOpen: false,
      selectedInput: false,

      dropIcon: 'caret-square-up',
      dropdownDiv: 'expand',

      quickPresets: quickPresets,
      inputs: inputsManage
    };

    this.renderSelection = this.renderSelection.bind(this);
    this.handleFormUpdate = this.handleFormUpdate.bind(this);
  }

  // componentDidMount() {
    // const projectId = sessionStorage.getItem('projectId');
    // const formId = this.props.match.params.id;
    // let form = this.props.getForm(projectId, formId) || {};
    // let formData = form.data;
    // this.setState({
    //   formData: {
    //     ...formData
    //   }
    // });
    // sessionStorage.removeItem('isActiveModule');
    // sessionStorage.setItem('isActiveModule', 'forms');
  // };

  toggleEditForm = (e) => {
    let { editFormModalOpen } = this.state;
    this.setState({ editFormModalOpen: !editFormModalOpen })
  };

  handleGetForm = () => {
    const projectId = sessionStorage.getItem('projectId');
    const formId = sessionStorage.getItem('formId');
    const formData = this.props.getForm(projectId, formId);

    this.setState({
      formData: {
        ...formData
      }
    });

  };

  handleSelectedInput = (inputState) => {
    this.setState({
      selectedInput: inputState
    });
  };

  handleGetField = (formGroupId, fieldId) => {
    const projectId = sessionStorage.getItem('projectId');
    //let formData = sessionStorage.getItem('form');
    const formId = sessionStorage.getItem('formId');

    this.props.findField(projectId, formId, formGroupId, fieldId);
    let activeField = JSON.parse(sessionStorage.getItem('field'));

    this.setState({
      activeSideBar: ''
    }, () => {
      this.setState({
        fieldData: activeField,
        activeSideBar: 'editField'
      }, () => {
        this.renderSelection();
      });
    });
  };

  dataAppend = (index) => {
    let appendFormData = {
      group: {
        id: '',
        form_order: index,
        hide_group: false,
        layout: "1",
        name: '',
        field: []
      }
    };

    let formData = JSON.parse(sessionStorage.getItem('form'));
    let formGroups = formData.group || [];
    formGroups.splice(index, 0, appendFormData);
  };

  getProjectId = () => {
    return sessionStorage.getItem('projectId');
  };

  getFormData = () => {
    return JSON.parse(sessionStorage.getItem('form'));
  };

  getBlankSelection = () => {
    this.setState({
      activeSideBar: ''
    }, () => {
      this.setState({
        activeFormGroup: '',
        activeSideBar: 'select'
      }, () => {
        this.renderSelection();
        this.handleGetForm();
      });
    });
  };

  getEditFormGroup = data => {
    this.setState({
      activeSideBar: ''
    }, () => {
      this.setState({
        formGroupData: data || {},
        activeSideBar: 'editFormGroup'
      }, () => {
        this.renderSelection();
      });
    });
  };

  getEditFormField = (data, formGroupId) => {
    this.setState({
      activeSideBar: ''
    }, () => {
      this.setState({
        fieldData: data || {},
        formGroupId: formGroupId,
        activeSideBar: 'editField'
      }, () => {
        this.renderSelection();
      });
    });
  };

  getFormFieldButtons = (data) => {
    this.setState((prevState, props) => {
      return {
        activeSideBar: 'addFormFields'
      }
    }, () => {
      this.renderSelection();
    });
  };

  getFormGroupButtons = (data) => {
    this.setState((prevState, props) => {
      return {
        activeSideBar: 'addFormGroups'
      }
    }, () => {
      this.renderSelection();
    });
  };

  handleFormUpdate = (data) => {
    if(data){
      this.setState({
        formData: data
      }, () => {
        this.renderFormGroups(data);
      });
    } else{
      this.handleGetForm();
    }
  };

  handleFieldsUpdate = (data) => {
    if(data){
      this.setState({
        fieldData: data
      }, () => {
        this.renderFormGroups(data);
      });
    } else{
      this.handleGetForm();
    }
  };

  triggerUpdate = (data) => {
    this.setState({
      activeSideBar: ''
    }, () => {
      this.setState({
        fieldData: data || {},
        activeSideBar: 'editField'
      }, () => {
        this.renderSelection();
      });
    });
  };

  renderSelection = () => {
    let activeSideBar = this.state.activeSideBar;
    let activeFormGroup = this.state.activeFormGroup;
    //let activeFormField = this.state.activeFormField;
    let formGroupId = this.state.formGroupId;
    //let formData = sessionStorage.getItem('form');
    const formId = sessionStorage.getItem('formId');

    if(activeFormGroup !== ''){
      activeFormGroup = activeFormGroup.replace('formGroup','');
    }

    // if(activeFormField !== ''){
    //   activeFormField = activeFormField.replace('formField','');
    // }

    switch (activeSideBar) {
      case 'select':
        return (
          <div className='form-builder__select text-center'>
            <p>
              Select a <b>Form Group</b> <br />
              or <b>Field</b> to edit
            </p>
            <img alt='' src={settings} />
          </div>
        );
      case 'editFormGroup':
        return (
          <EditFormGroupUpdate
            projectId={sessionStorage.getItem('projectId')}
            formId={formId}
            handleFormUpdate={this.handleFormUpdate}
            formGroupData={this.state.formGroupData}
            getBlankSelection={this.getBlankSelection}
          />
        );
      case 'editField':
        return (
          <EditFormFieldUpdate
            projectId={sessionStorage.getItem('projectId')}
            formId={formId}
            formGroupId={formGroupId}
            activeFormField={this.state.activeFormField}
            handleFormUpdate={this.handleFormUpdate}
            handleFieldsUpdate={this.handleFieldsUpdate}
            fieldData={this.state.fieldData}
            renderSelection={this.renderSelection}
            getEditFormField={this.getEditFormField}
            handleGetField={this.handleGetField}
            getBlankSelection={this.getBlankSelection}
          />
        );
      case 'addFormGroups':
        return (
          <div>
            <div className='form-builder__select-fields text-center'>
              <p>
                Select a <b>Form Group</b> to add
              </p>
              <MDBRow className=''>
                <PresetsButton
                  quickPresets={this.state.quickPresets}
                  buttonData={this.getButtonData}
                  formGroupId={activeFormGroup}
                  activeFormGroup={this.state.activeFormGroup}
                  handleActiveFormGroup={this.handleActiveFormGroup}
                  handleCreateFormGroup={this.handleCreateFormGroup}
                  handleCreateFormGroupInBetween={this.handleCreateFormGroupInBetween}
                  handleSelectedInput={this.handleSelectedInput}
                  handleCreateFormFromPresetGroup={this.handleCreateFormFromPresetGroup}
                  handleCreateFormGroupFromPresetInBetween={this.handleCreateFormGroupFromPresetInBetween}
                />
              </MDBRow>
              <img alt='' src={settings} />
            </div>
          </div>
        );
      case 'addFormFields':
        return (
          <div>
            <div className='form-builder__select-fields text-center'>
              <p>
                Select a <b>Field</b> to add
              </p>
              <MDBRow className='ml-4 mt-3'>
                <InputButtons
                  inputs={this.state.inputs}
                  formGroupId={activeFormGroup}
                  handleCreateFormField={this.handleCreateFormField}
                  handleSelectedInput={this.handleSelectedInput}
                />
              </MDBRow>
              <img alt='' src={settings} />
            </div>
          </div>
        );
      default:
        break;
    }
  };

  handleActiveFormGroup = (activeFormGroup) => {
    this.setState({
      activeFormGroup: activeFormGroup,
      activeFormField: ''
    });
  };

  handleActiveFormField = (activeFormField) => {
    this.setState({
      activeFormField: activeFormField,
      activeFormGroup: '',
    });
  };

  handleCreateFormGroup = (data) => {
    const projectId = sessionStorage.getItem('projectId');
    //let formData = sessionStorage.getItem('form');
    const formId = sessionStorage.getItem('formId');

    this.props.createFormGroup(projectId, formId, data);
    this.handleGetForm();

    this.setState({
      activeSideBar: 'select',
      activeFormGroup: '',
      activeFormField: '',
    });
  };

  handleCreateFormGroupInBetween = (newGroupData, formGroupData, index) => {
    const projectId = sessionStorage.getItem('projectId');
    //let formData = sessionStorage.getItem('form');
    const formId = sessionStorage.getItem('formId');

    this.props.createFormGroupInBetween(projectId, formId, newGroupData, index);
    this.handleGetForm();

    this.setState({
      activeSideBar: 'select',
      activeFormGroup: '',
      activeFormField: '',
    });
  };

  handleCreateFormFromPresetGroup = (type) => {
    const projectId = sessionStorage.getItem('projectId');
    //let formData = sessionStorage.getItem('form');
    const formId = sessionStorage.getItem('formId');

    this.props.createPreset(projectId, formId, type);
    this.handleGetForm();

    this.setState({
      activeSideBar: 'select',
      activeFormGroup: '',
      activeFormField: '',
    });
  };

  handleCreateFormGroupFromPresetInBetween = (type, formGroupData, index) => {
    const projectId = sessionStorage.getItem('projectId');
    //let formData = sessionStorage.getItem('form');
    const formId = sessionStorage.getItem('formId');

    this.props.createPresetInBetween(projectId, formId, type, index);
    this.handleGetForm();

    this.setState({
      activeSideBar: 'select',
      activeFormGroup: '',
      activeFormField: '',
    });
  };

  handleCreateFormField = (formGroupId, type) => {
    const projectId = sessionStorage.getItem('projectId');
    //let formData = sessionStorage.getItem('form');
    const formId = sessionStorage.getItem('formId');
    let typeId = 0;
    let fieldLabel ='';

    switch (type) {
      case 'textInput':
        typeId = 1;
        fieldLabel = 'New Text Input Field';
        break;
      case 'multipleTextInput':
        typeId = 2;
        fieldLabel = 'New Multiline Text Input Field';
        break;
      case 'singleChoice':
        typeId = 3;
        fieldLabel = 'New Single Choice Field';
        break;
      case 'multipleChoice':
        typeId = 4;
        fieldLabel = 'New Multiple Choice Field';
        break;
      case 'dropdownSelection':
        typeId = 5;
        fieldLabel = 'New Dropdown Selection Field';
        break;
      case 'switch':
        typeId = 6;
        fieldLabel = 'New Switch Field';
        break;
      case 'slider':
        typeId = 7;
        fieldLabel = 'New Slider Range Field';
        break;
      case 'dateAndTime':
        typeId = 8;
        fieldLabel = 'New Date and Time Field';
        break;
      case 'apiDropdownSelection':
        typeId = 9;
        fieldLabel = 'New API Dropdown Selection Field';
        break;
      default: return null;
    }

    let blankField = {
      label: fieldLabel,
      placeholder: '',
      typeId: typeId
    };

    let fieldIndex = sessionStorage.getItem('fieldIndex');
    let fieldTotalNum = sessionStorage.getItem('fieldTotalNum');

    if((fieldIndex <= fieldTotalNum) && (fieldTotalNum !== 0)){
      this.props.createFieldInBetween(projectId, formId, formGroupId, blankField, fieldIndex, fieldTotalNum);
    } else{
      this.props.createField(projectId, formId, formGroupId, blankField);
    }

    this.handleGetForm();
    let newField = JSON.parse(sessionStorage.getItem('field'));

    if(newField){
      this.handleActiveFormField(`formField${newField.id}`);
    }

    this.setState({
      activeSideBar: 'select',
      activeFormGroup: '',
      activeFormField: '',
    });
  };

  handleFormGroupReorder = (data) => {
    const projectId = sessionStorage.getItem('projectId');
    //let formData = sessionStorage.getItem('form');
    const formId = sessionStorage.getItem('formId');

    this.props.reorderGroup(projectId, formId, data);
    this.handleGetForm();
  };

  handleFormFieldReorder = (data, formGroupId) => {
    const projectId = sessionStorage.getItem('projectId');
    //let formData = sessionStorage.getItem('form');
    const formId = sessionStorage.getItem('formId');

    this.props.reorderField(projectId, formId, formGroupId, data);
    this.handleGetForm();
  };

  renderFormGroups = (data) => {
    let totalFormGroups = data.length || 0;
    let hasFormGroups = totalFormGroups > 0;
    // let formGroupId = this.state.formGroupId;
    // let formData = sessionStorage.getItem('form');
    const formId = sessionStorage.getItem('formId');

    if(hasFormGroups){
      data = data.sort((a, b) => a.form_order > b.form_order ? 1 : -1);
    }

    return (
      <div
        className='form-builder__stage'
        onClick={(e) => {
          e.stopPropagation();
          this.setState({
            activeFormGroup: '',
            activeFormField: '',
            activeSideBar: 'select'
          });
        }}
      >
        { hasFormGroups ?
          data.map((value, index) => {
            return (
              <FormGroupManage
                key={index}
                formGroupData={value}
                formGroupId={value.id}
                formGroupKey={index}
                getEditFormGroup={this.getEditFormGroup}
                getEditFormField={this.getEditFormField}
                getFormGroupButtons={this.getFormGroupButtons}
                getFormFieldButtons={this.getFormFieldButtons}
                totalFormGroups={totalFormGroups}
                handleActiveFormGroup={this.handleActiveFormGroup}
                handleActiveFormField={this.handleActiveFormField}
                activeFormGroup={this.state.activeFormGroup}
                handleCreateFormGroup={this.handleCreateFormGroup}
                handleCreateFormGroupInBetween={this.handleCreateFormGroupInBetween}
                handleFormGroupReorder={this.handleFormGroupReorder}
                handleFormFieldReorder={this.handleFormFieldReorder}
                activeFormField={this.state.activeFormField}
                projectId={sessionStorage.getItem('projectId')}
                formId={formId}
                dataToAppend={this.dataAppend}
                handleGetForm={this.handleGetForm}
                handleSelectedInput={this.handleSelectedInput}
                selectedInput={this.state.selectedInput}
              />
            );
          })
          :
          <div  className={`form-groups 
          ${(this.state.activeFormGroup === 'formGroup0') ? 'active' : ' '}`}>
            <CreateFormGroup
              handleActiveFormGroup={this.handleActiveFormGroup}
              activeFormGroup={this.state.activeFormGroup}
              getFormFieldButtons={this.getFormFieldButtons}
              getEditFormGroup={this.getEditFormGroup}
              handleCreateFormGroup={this.handleCreateFormGroup}
              handleCreateFormGroupInBetween={this.handleCreateFormGroupInBetween}
            />
          </div>

        }
      </div>
    );
  };

  render() {
    const projectId = sessionStorage.getItem('projectId');
    let { formData } = this.props;

    //formData = formLoaded && form.data;


    let hasData = false;
    if(typeof formData !== 'undefined' && formData !== null){
      if(typeof formData.info !== 'undefined'){
        hasData = true;
      }
    } else{
      formData = this.getFormData();
      hasData = true;

    }

    return (
		<MDBContainer fluid className='p--0'>
			<div className='page-title-ctas p-l-md p-r-md'>
				<MDBRow className='m--0 align-items-center'>
					<MDBCol className='p-0'>
						<p className='page-title font-family-kanit font-size-30 line-height-7 color-gray line-height-6'>Manage Fields</p>
					</MDBCol>
					{/* <MDBCol>
						<MDBRow className='float-right'>
							<ButtonGroup>
								
							</ButtonGroup>
						</MDBRow>
					</MDBCol> */}
					<MDBCol>
						<MDBRow className='float-right'>
							<ButtonGroup>
								<Link>
									<Button
										flat='true'
										background='orange' foreground='white'
										borderWidth={2} borderColor='orange' size={-1}
										styles={{ 'width': '100%', 'margin': '0' }}
										onClick={(e) => {this.toggleEditForm(e)}}>
										<MDBIcon icon='cog' className='mr-2' />
										<span className='color-white font-weight-bold'>Update Form</span>
									</Button>
								</Link>
								<Link to={`/project/${projectId}/leads`}>
									<Button
										flat='true'
										background='transparent' foreground='black'
										borderWidth={2} borderColor='dark' size={-1}
										styles={{ 'width': '100%' }}>
										<Icon color='black' name='chevron' rotate={180} />
										<span className='color-black font-weight-bold'>View All Leads</span>
									</Button>
								</Link>
							</ButtonGroup>
						</MDBRow>
					</MDBCol>
				</MDBRow>
			</div>
			<div className="page-filter">
				<MDBRow className='text-left render-sidebar'>
					<MDBCol>
						<label className="mb-0 font-size-13 font-weight-600 mb-1">FORM NAME</label>
						<p className='m-0 font-weight-600'>{formData && formData.info.name}</p>
					</MDBCol>
					<MDBCol className='project-info__description'>
						<label className="mb-0 font-size-13 font-weight-600 mb-1">DESCRIPTION</label>
						<p className='m-0 font-weight-600'>{ formData && formData.info.description}</p>
					</MDBCol>
				</MDBRow>
			</div>
			<MDBRow className='m--0'>
				
				<MDBCol lg='12' xl='12' md='12' className='p--0 main-body-container container-fluid false p--sm  sub-body-container rectangle-background'>
					{/* <MDBRow className='d-flex'>  
						<MDBCol lg='7' className='my-auto'>  
							<MDBRow className='pl-3'>  
								<p className='font-family-crimson font-size-22 letter-spacing-1 my-auto pr-3'>{ hasData ? formData.info.name : 'Loading...' }</p>  
							</MDBRow>  
						</MDBCol>  
					    <MDBCol lg='5' className='text-right'>  
					        <ButtonGroup>  
					          <Link to={`/project/${projectId}/form/${formId}/email-connection`}>  
					            <Button  
					              flat='true'  
					              background='orange' foreground='white'  
					              borderWidth={2} borderColor='orange' size={-2}>  
					              <span className='color-white font-weight-bold'>Next</span>  
					            </Button>  
					          </Link>  
					        </ButtonGroup>  
					      </MDBCol>  
					</MDBRow> */}
					<MDBCol className='main-body-container background-color-white'>
						{ formData ?
						<MDBRow className='main-body-container height--full'>
							<MDBCol lg='7' xl='7' id='child' onClick={() => { }} className='p-r--0 p-l--0'>
								{ this.renderFormGroups(formData.group) }
							</MDBCol>
							<MDBCol lg='5' xl='5' className='background-color-lead right-rectangle px-0'>
								{this.renderSelection()}
							</MDBCol>
						</MDBRow> :
						<MDBRow className='position-center'>
							<div className='text-center position-center m--auto'>
							<div className="loader dark">Loading...</div>
							</div>
						</MDBRow>
						}
					</MDBCol>
				</MDBCol>
			</MDBRow>
			{ (hasData && formData) &&
			<EditFormModal
			formData={formData}
			modalToggle={this.toggleEditForm}
			modalOpen={this.state.editFormModalOpen}
			projectId={projectId}
			handleGetForm={this.handleGetForm}
			/>
			}
      </MDBContainer>
    );
  }
}

function mapState(state) {
  const { creating, forms } = state;
  const { created, formLoaded, form } = state.forms;
  return { creating, forms, created, formLoaded, form };
}

const actionCreators = {
  updateForm: formActions.updateForm,
  getForm: formActions.getForm,
  createFormGroup: formActions.createFormGroup,
  createFormGroupInBetween: formActions.createFormGroupInBetween,
  createPreset: formActions.createPreset,
  createPresetInBetween: formActions.createPresetInBetween,
  reorderGroup: formActions.reorderGroup,
  reorderField: formActions.reorderField,
  createField: formActions.createField,
  createFieldInBetween: formActions.createFieldInBetween,
  findAllField: formActions.findAllField
};

const connectedManageFields = connect(mapState, actionCreators)(ManageFields);
export { connectedManageFields as ManageFields };
