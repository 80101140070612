import React, { useState, useRef, useEffect } from "react";

import { EditorContent, useEditor } from "@tiptap/react";
import StarterKit from "@tiptap/starter-kit";

import { useDispatch } from "react-redux";
import { utilitiesService } from "_services";
import { profileActions, templateActions } from "_actions";
import { useSelector } from "react-redux";
import DropDownLists from "_pages/Influencer/constants/DropDownLists";
import { useParams } from "react-router-dom";
import { isValid } from "rsuite/esm/utils/dateUtils";

const ViewEmailTemplateDrawer = ({
  isViewTemplateDrawer,
  closeViewTemplateDrawer,
  template,
  toggleDuplicateModal,
}) => {
  const [toggleTabState, setToggleTabState] = useState(1);
  const [editorContent, setEditorContent] = useState("");
  const [category, setCategory] = useState("");
  const [title, setTitle] = useState("");
  const [message, setMessage] = useState("");
  const [templateId, setTemplateId] = useState(0);

  const editor = useEditor({
    extensions: [StarterKit],
    autofocus: true,
    content: editorContent,
    readOnly: true, // Set readOnly to true to disable editing
    onUpdate({ editor }) {
      setEditorContent(editor.getHTML());
    },
  });

  useEffect(() => {
    setCategory("");
    if (isViewTemplateDrawer && template == undefined) {
      editor.commands.setContent("");
      setTemplateId(0);
      setTitle("");
    }

    if (isViewTemplateDrawer && template !== undefined) {
      editor.commands.setContent("");
      setTitle(utilitiesService.decrypt(template.title));
      setCategory(utilitiesService.decrypt(template.category));
      editor.commands.setContent(utilitiesService.decrypt(template.template));
      editor.setOptions({editable: false})
      if (template.id != 0) {
        setTemplateId(template.id);
      }
    }
  }, [template, isViewTemplateDrawer]);

 // console.log("template id: ", templateId);

  let activeClass = isViewTemplateDrawer ? "active" : "";

  return (
    <>
      <div
        className={`drawer-overlay ${activeClass}`}
        onClick={closeViewTemplateDrawer}
      ></div>
      <div className={`side-drawer-main email-template-drawer ${activeClass}`}>
        <div className="side-drawer-wrapper">
          <div className="side-drawer-title">
            <div>
              <h3>{title}</h3>
              <span className="et-label">Shared Template</span>
            </div>
            <span
              className="side-drawer-close"
              onClick={closeViewTemplateDrawer}
            >
              <i className="icon-lms icon-close"></i>
            </span>
          </div>
          <div className="side-drawer-tabs">
            <div className="side-drawer-tabs-wrapper">
              {
                <div className="content-tabs-box">
                  <div className={`create-email-template`}>
                    <div className="email-subject">
                      <span>Email Template Title:</span>
                      <input readOnly={true} value={title} type={`text`} />
                    </div>
                    <div className="email-other-details">
                      <div className="email-category">
                        <span className="label">Email Category:</span>
                        <span className="title">{category}</span>
                      </div>
                      {/* <div className="email-service">
                            <span className="label">Email Services:</span>
                            <div className={openDropdown === "service" ? "et-dropdown active" : "et-dropdown"} onClick={() => handleOpenDropdown("service")}>
                                <span className="title">Select one service <i className="icon-lms icon-arrow-down"></i></span>
                                <div className="list">
                                    <span>Service 1</span>
                                    <span>Service 2</span>
                                    <span>Service 3</span>
                                </div>
                            </div>
                        </div> */}
                    </div>
                    <div className="email-text">
                      <div className="custom-basic-editor email-editor">
                        <MenuBar editor={editor} />
                        <EditorContent
                          editor={editor}
                          className="thread-editor-field"
                        />
                      </div>
                    </div>
                  </div>
                </div>
              }
              <div className="tab-form-btn-group">
                <div className="row">
                  <div className="col text-right">
                    <span
                      className="drawer-btn cancel-btn"
                      onClick={closeViewTemplateDrawer}
                    >
                      Close
                    </span>
                    <span
                      className="drawer-btn save-btn"
                      onClick={() => toggleDuplicateModal(template)}
                    >
                      Duplicate Template
                    </span>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

const MenuBar = ({ editor }) => {
  if (!editor) {
    return null;
  }

  return (
    <>
      <div className="thread-editor-controls">
        <button
          onClick={() => editor.chain().focus().setParagraph().run()}
          className={editor.isActive("paragraph") ? "is-active" : ""}
        >
          <i className="fas fa-paragraph"></i>
        </button>
        <button
          onClick={() =>
            editor.chain().focus().toggleHeading({ level: 4 }).run()
          }
          className={
            editor.isActive("heading", { level: 4 }) ? "is-active" : ""
          }
        >
          <i className="fas fa-heading"></i>
        </button>
        <button
          onClick={() => editor.chain().focus().toggleBold().run()}
          disabled={!editor.can().chain().focus().toggleBold().run()}
          className={editor.isActive("bold") ? "is-active" : ""}
        >
          <i className="fas fa-bold"></i>
        </button>
        <button
          onClick={() => editor.chain().focus().toggleItalic().run()}
          disabled={!editor.can().chain().focus().toggleItalic().run()}
          className={editor.isActive("italic") ? "is-active" : ""}
        >
          <i className="fas fa-italic"></i>
        </button>
        <button
          onClick={() => editor.chain().focus().toggleStrike().run()}
          disabled={!editor.can().chain().focus().toggleStrike().run()}
          className={editor.isActive("strike") ? "is-active" : ""}
        >
          <i className="fas fa-strikethrough"></i>
        </button>
        <button
          onClick={() => editor.chain().focus().toggleBulletList().run()}
          className={editor.isActive("bulletList") ? "is-active" : ""}
        >
          <i className="fas fa-list-ul"></i>
        </button>
        <button
          onClick={() => editor.chain().focus().toggleOrderedList().run()}
          className={editor.isActive("orderedList") ? "is-active" : ""}
        >
          <i className="fas fa-list-ol"></i>
        </button>
        <button
          onClick={() => editor.chain().focus().undo().run()}
          disabled={!editor.can().chain().focus().undo().run()}
        >
          <i className="fas fa-undo"></i>
        </button>
        <button
          onClick={() => editor.chain().focus().redo().run()}
          disabled={!editor.can().chain().focus().redo().run()}
        >
          <i className="fas fa-redo"></i>
        </button>
      </div>
    </>
  );
};

export default ViewEmailTemplateDrawer;
