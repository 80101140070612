import React from 'react'
import { MDBCol, MDBRow } from 'mdbreact';

// components
import FormGroupTitle from './FormGroupTitle';
import InputForm from './InputForm';

const FormEdit = ({emailError, onEmailChange}) => {
  return (
    <>
      <form id="" className="form-edit-influencer">
        <MDBRow>
            <MDBCol xl='12' lg='12' className='form-group-box'>
                <FormGroupTitle label="Influencer Information" />
                <div className='input-group-box'>
                    <InputForm 
                        label='Influencer Name'
                        type='text'
                        id='id'
                        name='name'
                    />
                    <InputForm 
                        label='Email Address'
                        type='email'
                        id='id'
                        name='name'
                        emailError={emailError}
                        onEmailChange={onEmailChange}
                    />
                    <InputForm 
                        label='Phone Number'
                        type='tel'
                        id='id'
                        name='name'
                    />
                    <InputForm 
                        label='Date Contacted'
                        type='date'
                        id='id'
                        name='name'
                    />
                    <InputForm 
                        label='Rates'
                        type='text'
                        id='id'
                        name='name'
                    />
                </div>
                <FormGroupTitle label="Handler Details" />
                <div className='input-group-box'>
                    <InputForm 
                        label='Handle Name'
                        type='text'
                        id='id'
                        name='name'
                    />
                    <InputForm 
                        label='Email Address'
                        type='email'
                        id='id'
                        name='name'
                    />
                    <InputForm 
                        label='Phone Number'
                        type='tel'
                        id='id'
                        name='name'
                    />
                </div>
            </MDBCol>
        </MDBRow>
      </form>
    </>
  );
}

export default FormEdit