import React from 'react'

const SideInfoLoader = () => {
  return (
    <div className={`skeleton-loader skeleton-side-info active`}>
        <div className='skel-grp'>
            <div className='skel-col'>
                <div className="skeleton skeleton-box"></div>
            </div>
            <div className='skel-col'>
                <div className="skeleton skeleton-box"></div>
                <div className="skeleton skeleton-box"></div>
            </div>
        </div>
        <div className='skel-grp'>
            <div className='skel-col'>
                <div className="skeleton skeleton-box"></div>
            </div>
            <div className='skel-col'>
                <div className="skeleton skeleton-box"></div>
                <div className="skeleton skeleton-box"></div>
            </div>
        </div>
        <div className='skel-grp'>
            <div className='skel-col'>
                <div className="skeleton skeleton-box"></div>
            </div>
            <div className='skel-col'>
                <div className="skeleton skeleton-box"></div>
                <div className="skeleton skeleton-box"></div>
            </div>
        </div>
        <div className='skel-grp'>
            <div className='skel-col'>
                <div className="skeleton skeleton-box"></div>
            </div>
            <div className='skel-col'>
                <div className="skeleton skeleton-box"></div>
                <div className="skeleton skeleton-box"></div>
            </div>
        </div>
    </div>
  )
}

export default SideInfoLoader