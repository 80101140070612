import React from "react";
import avatar from "../../../../../_assets/images/avatar-sm.png";
import { utilitiesService as utility } from "_services";

function ChatAgentReply({ message, timestamp, status, name }) {
  const date = new Date(timestamp).toLocaleString("en-US", {
    timeZone: "Asia/Manila",
  });

  const wrapUrlsInLinks = (text) => {
    const urlRegex = /https:\/\/\S+\b/g;

    return text.replace(urlRegex, (url) => {
      return `<a href="${url}" target="_blank">${url}</a>`;
    });
  };

  const messageWithLinks = wrapUrlsInLinks(utility.decrypt(message));

  return (
    <React.Fragment>
    
      <div className="chat-date-time">
        <span>{date}</span>
      </div>
      <div className="chat-reply">
        <div className="reply-box">
          <div className="name-box">
            <p>{utility.decrypt(name)}</p>
          </div>
          <div className="text-box">
            {/* <p>{utility.decrypt(message)}</p> */}
            <p dangerouslySetInnerHTML={{ __html: messageWithLinks }} />
          </div>
        </div>
        <div className="reply-status">
          {status === "read" ? (
            <span className="status seen">
              <img src={avatar} />
            </span>
          ) : (
            <span className="status sent">
              <i className="far fa-check-circle"></i>
            </span>
          )}
        </div>
      </div>
    </React.Fragment>
  );
}

export default ChatAgentReply;
