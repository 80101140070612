import React, { useState, useEffect, useRef } from 'react'

const CategoryFilter = () => {
    const [isOpen, setIsOpen] = useState(false);
    const filterRef = useRef();

    function handleOpenFilter() {
      setIsOpen(!isOpen);
    }
    function handleCloseFilter() {
      setIsOpen(false);
    }

    useEffect(() => {
      const filterOutsideClick = (e) => {
        if (
          isOpen &&
          filterRef.current &&
          !filterRef.current.contains(e.target)
        ) {
          setIsOpen(false);
        }
      };
      document.addEventListener("click", filterOutsideClick);
      return () => {
        document.removeEventListener("click", filterOutsideClick);
      };
    }, [isOpen]);

  return (
    <div className="filter-box filter-category" ref={filterRef}>
      <div className="filter-btn">
        <span className="category-text" onClick={() => handleOpenFilter()}>
          Select Category
        </span>
      </div>
      <div className={isOpen ? "filter-dropdown active" : "filter-dropdown"}>
        <div className="filter-title">
          <p>Categories</p>
          <i className="fas fa-times" onClick={() => handleCloseFilter()}></i>
        </div>
        <div className="category-list">
          <div className="category-group">
            <div className="filter-btn-group">
              <button className="btn-select-all">
                <i className="fas fa-check-square"></i>
                Select All
              </button>
              <button className="btn-unselect-all">
                <i className="fas fa-window-close"></i>
                Unselect All
              </button>
              <button className="btn-apply active">
                <i className="fas fa-check"></i>
                Update
              </button>
            </div>
            <div className="checkbox-group">
              <input type="checkbox" name="facebook" id="facebook" />
              <label htmlFor="facebook">Faceook</label>
            </div>
            <div className="checkbox-group">
              <input type="checkbox" name="instagram" id="instagram" />
              <label htmlFor="instagram">Instagram</label>
            </div>
            <div className="checkbox-group">
              <input type="checkbox" name="twitter" id="twitter" />
              <label htmlFor="twitter">Twitter</label>
            </div>
            <div className="checkbox-group">
              <input type="checkbox" name="youtube" id="youtube" />
              <label htmlFor="youtube">Youtube</label>
            </div>
            <div className="checkbox-group">
              <input type="checkbox" name="tiktok" id="tiktok" />
              <label htmlFor="tiktok">Tiktok</label>
            </div>
            <div className="checkbox-group">
              <input type="checkbox" name="kumu" id="kumu" />
              <label htmlFor="kumu">Kumu</label>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

export default CategoryFilter