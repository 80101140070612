

export const defaultConfig = [
    {
        title : 'Leads',
        width : '9%',
    },
    {
        title : 'Status',
        width : '9%',
    },
    {
        title : 'Priority',
        width : '4%',
    },
    {
        title : 'Owner',
        width : '4%',
    },
    {
        title : 'Responsible',
        width : '4%',
    },
    {
        title : 'Added',
        width : '4%',
    },
    {
        title : 'Updated',
        width : '4%',
    },
];

export const phiConfig = [
    {

        title : 'Leads',
        field: 'lead',
        width : '13%',
        sort: false,
    },
    {
        title : 'Status',
        field: 'status',
        width : '7%',
        sort: true,
    },
    {
        title : 'Lead Type',
        field: 'type',
        width : '7%',
        sort: false,
    },
    {
        title : 'Point of Conversion',
        field: 'origin',
        width : '10%',
        sort: true,
    },
    {
        title : 'Source',
        field: 'source',
        width : '7%',
        sort: true,
    },
    {
        title : 'Medium',
        field: 'medium',
        width : '7%',
        sort: true,
    },
    {
        title : 'Campaign',
        field: 'campaign',
        width : '13%',
        sort: true,
    },
    {
        title : 'Qualification',
        field: 'qualification',
        width : '7%',
        sort: true,
    },
    {
        title : 'Added',
        field: 'created',
        width : '7%',
        sort: true,
    }
];


export const propelrrConfig = [
    {
        title : 'Leads',
        field: 'lead',
        width : '9%',
        sort: false,
    },
    {
        title : 'Status',
        field: 'status',
        width : '4%',
        sort: true,
    },
    {
        title : 'Lead Type',
        field: 'type',
        width : '4%',
        sort: false,
    },
    {
        title : 'Point Of Conversion',
        field: 'origin',
        width : '4%',
        sort: true,
    },
    {
        title : 'Source',
        field: 'source',
        width : '6%',
        sort: true,
    },
    {
        title : 'Medium',
        field: 'medium',
        width : '4%',
        sort: true,
    },
    {
        title : 'Campaign',
        field: 'campaign',
        width : '8%',
        sort: true,
    },
    {
        title : 'Added',
        field: 'created',
        width : '4%',
        sort: true,
    }
];

export const maayoConfig = [
    {
        title : 'Leads',
        field: 'lead',
        width : '13%',
        sort: false,
    },
    {
        title : 'Status',
        field: 'status',
        width : '7%',
        sort: true,
    },
    {
        title : 'Service',
        field: 'service',
        width : '7%',
        sort: false,
    },
    {
        title : 'Point Of Conversion',
        field: 'origin',
        width : '10%',
        sort: true,
    },
    {
        title : 'Source',
        field: 'source',
        width : '7%',
        sort: false,
    },
    {
        title : 'Medium',
        field: 'medium',
        width : '7%',
        sort: false,
    },
    {
        title : 'Campaign',
        field: 'campaign',
        width : '13%',
        sort: false,
    },
    {
        title : 'Added',
        field: 'created',
        width : '7%',
        sort: true,
    },
];

export const propelrrServiceConfig = [
    {

        title : 'Leads',
        field: 'lead',
        width : '13%',
        sort: false,
    },
    {
        title : 'Status',
        field: 'status',
        width : '7%',
        sort: true,
    },
    {
        title : 'Service',
        field: 'type',
        width : '7%',
        sort: false,
    },
    {
        title : 'Point Of Conversion',
        field: 'origin',
        width : '10%',
        sort: true,
    },
    {
        title : 'Source',
        field: 'source',
        width : '7%',
        sort: true,
    },
    {
        title : 'Medium',
        field: 'medium',
        width : '7%',
        sort: true,
    },
    {
        title : 'Channel',
        field: 'channel',
        width : '7%',
        sort: false,
    },
    {
        title : 'Assigned',
        field: 'responsible',
        width : '7%',
        sort: true,
    },
    {
        title : 'Added',
        field: 'created',
        width : '7%',
        sort: true,
    }
];


export const propelrrCareerConfig = [
    {

        title : 'Leads',
        field: 'lead',
        width : '2%',
        sort: false,
    },
    {
        title : 'Status',
        field: 'status',
        width : '2%',
        sort: true,
    },
    {
        title : 'Position',
        field: 'type',
        width : '2%',
        sort: false,
    },
    {
        title : 'Point Of Conversion',
        field: 'origin',
        width : '2%',
        sort: true,
    },
    // {
    //     title : 'Source',
    //     field: 'source',
    //     width : '7%',
    //     sort: true,
    // },
    // {
    //     title : 'Medium',
    //     field: 'medium',
    //     width : '7%',
    //     sort: true,
    // },
    // {
    //     title : 'Campaign',
    //     field: 'campaign',
    //     width : '13%',
    //     sort: false,
    // },
    {
        title : 'Assigned',
        field: 'responsible',
        width : '2%',
        sort: true,
    },
    {
        title : 'Added',
        field: 'created',
        width : '4%',
        sort: true,
    }
];


export const dmciLeasingConfig = [
    {

        title : 'Leads',
        field: 'lead',
        width : '9%',
        sort: false,
    },
    {
        title : 'Status',
        field: 'status',
        width : '4%',
        sort: true,
    },
    {
        title : 'Inquiry',
        field: 'type',
        width : '4%',
        sort: false,
    },
    {
        title : 'Point Of Conversion',
        field: 'origin',
        width : '4%',
        sort: true,
    },
    {
        title : 'Source',
        field: 'source',
        width : '6%',
        sort: true,
    },
    {
        title : 'Medium',
        field: 'medium',
        width : '4%',
        sort: true,
    },
    {
        title : 'Campaign',
        field: 'campaign',
        width : '7%',
        sort: false,
    },
    {
        title : 'Assigned',
        field: 'responsible',
        width : '4%',
        sort: true,
    },
    {
        title : 'Added',
        field: 'created',
        width : '4%',
        sort: true,
    }
];

export const demoConfig = [
    {
        title : 'Leads',
        field: 'lead',
        width : '9%',
        sort: false,
    },
    {
        title : 'Status',
        field: 'status',
        width : '4%',
        sort: true,
    },
    {
        title : 'Inquiry Type',
        field: 'type',
        width : '4%',
        sort: false,
    },
    {
        title : 'Point Of Conversion',
        field: 'origin',
        width : '4%',
        sort: true,
    },
    {
        title : 'Source',
        field: 'source',
        width : '6%',
        sort: true,
    },
    {
        title : 'Medium',
        field: 'medium',
        width : '4%',
        sort: true,
    },
    {
        title : 'Campaign',
        field: 'campaign',
        width : '7%',
        sort: false,
    },
    {
        title : 'Assigned',
        field: 'responsible',
        width : '4%',
        sort: true,
    },
    {
        title : 'Added',
        field: 'created',
        width : '4%',
        sort: true,
    }
];