import React, {useState } from 'react'

// components
import KpiStatusInfo from './KpiStatusInfo'
import KpiResultInfo from './KpiResultInfo'


const KpiHeader = () => {
  
  const [isClick, setClick] = useState(false);
  function handleOpenStatusList() {
    setClick(!isClick);
  }

  const [isLoader, setIsLoader] = useState(false);
  function handleRefreshBtn() {
    setIsLoader(true);
    setTimeout(() => setIsLoader(false), 1000);
  }

  return (
    <div className='kpi-header-box-propelrr'>
        <div className='left-box'>
            <div className={isClick ? 'status-list active' : 'status-list'}>
                <div className='box'>
                    <KpiStatusInfo 
                        title='Total Influencers'
                        description='Lorem ipsum dolor sit amet consectetur adipisicing elit. Culpa, autem.'
                    />
                    <div className='status-result'>
                        <KpiResultInfo 
                            total='1000'
                            info='Done'
                        />
                        <KpiResultInfo 
                            total='20'
                            info='Pending'
                        />
                        <KpiResultInfo 
                            total='10'
                            info='No Reply'
                        />
                    </div>
                </div>
                <div className='box'>
                    <KpiStatusInfo 
                        title='Facebook'
                        description='Lorem ipsum dolor sit amet consectetur adipisicing elit. Culpa, autem.'
                    />
                    <div className='status-result'>
                        <KpiResultInfo 
                            total='50'
                            info='Done'
                        />
                        <KpiResultInfo 
                            total='20'
                            info='Pending'
                        />
                        <KpiResultInfo 
                            total='08'
                            info='No Reply'
                        />
                    </div>
                </div>
                <div className='box'>
                    <KpiStatusInfo 
                        title='Tiktok Microinfluencers'
                        description='Lorem ipsum dolor sit amet consectetur adipisicing elit. Culpa, autem.'
                    />
                    <div className='status-result'>
                        <KpiResultInfo 
                            total='50'
                            info='Done'
                        />
                        <KpiResultInfo 
                            total='50'
                            info='Pending'
                        />
                        <KpiResultInfo 
                            total='50'
                            info='No Reply'
                        />
                    </div>
                </div>
                <div className='box'>
                    <KpiStatusInfo 
                        title='TikTok Creators'
                        description='Lorem ipsum dolor sit amet consectetur adipisicing elit. Culpa, autem.'
                    />
                    <div className='status-result'>
                        <KpiResultInfo 
                            total='50'
                            info='Done'
                        />
                        <KpiResultInfo 
                            total='50'
                            info='Pending'
                        />
                        <KpiResultInfo 
                            total='50'
                            info='No Reply'
                        />
                    </div>
                </div>
                <div className='box'>
                    <KpiStatusInfo 
                        title='Dancers'
                        description='Lorem ipsum dolor sit amet consectetur adipisicing elit. Culpa, autem.'
                    />
                    <div className='status-result'>
                        <KpiResultInfo 
                            total='50'
                            info='Done'
                        />
                        <KpiResultInfo 
                            total='50'
                            info='Pending'
                        />
                        <KpiResultInfo 
                            total='50'
                            info='No Reply'
                        />
                    </div>
                </div>
            </div>
        </div>
        <div className='right-box'>
            <div className='button-groups'>
                <button className={isClick ? 'active' : ''} onClick={() => handleOpenStatusList()}>
                    <i className="fas fa-chevron-down"></i>
                </button>
                <button onClick={() => handleRefreshBtn()}>
                    <i className="fas fa-redo"></i>
                </button>
            </div>
        </div>
        <div className='full-toggle-box'>
            <button className={this.state.toggleKpi ? 'toggle-status-btn active' : 'toggle-status-btn'}
                onClick={() => this.handleToggleKpi()}>
                <i className="fas fa-chevron-down"></i>
            </button>
        </div>
        <div className={isLoader ? 'loading-container refresh-loader active' : 'loading-container refresh-loader'} >
            <h3>Loading Leads...</h3>
        </div>
    </div>
  )
}

export default KpiHeader