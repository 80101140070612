export const emailConstants = {

    GET_ALL_EMAIL_REQUEST: 'GET_ALL_EMAIL_REQUEST',
    GET_ALL_EMAIL_SUCCESS: 'GET_ALL_EMAIL_SUCCESS',
    GET_ALL_EMAIL_FAILURE: 'GET_ALL_EMAIL_FAILURE',

    UPDATE_EMAIL_UNREAD_REQUEST: 'UPDATE_EMAIL_UNREAD_REQUEST',
    UPDATE_EMAIL_UNREAD_SUCCESS: 'UPDATE_EMAIL_UNREAD_SUCCESS',
    UPDATE_EMAIL_UNREAD_FAILURE: 'UPDATE_EMAIL_UNREAD_FAILURE',

    EMAIL_REPLY_REQUEST: 'EMAIL_REPLY_REQUEST',
    EMAIL_REPLY_SUCCESS: 'EMAIL_REPLY_SUCCESS',
    EMAIL_REPLY_FAILURE: 'EMAIL_REPLY_FAILURE',

    EMAIL_LOAD_MESSAGES: 'EMAIL_LOAD_MESSAGES',

    EMAIL_NOTIFICATION_REQUEST: 'EMAIL_NOTIFICATION_REQUEST',

    EMAIL_DRAFT_REQUEST: 'EMAIL_DRAFT_REQUEST',
    EMAIL_DRAFT_SUCCESS: 'EMAIL_DRAFT_SUCCESS',
    EMAIL_DRAFT_FAILURE: 'EMAIL_DRAFT_FAILURE',

    EMAIL_ALL_DRAFT_REQUEST: 'EMAIL_ALL_DRAFT_REQUEST',
    EMAIL_ALL_DRAFT_SUCCESS: 'EMAIL_ALL_DRAFT_SUCCESS',
    EMAIL_ALL_DRAFT_FAILURE: 'EMAIL_ALL_DRAFT_FAILURE',

    


}