import { formConstants } from '_constants';
import { formService } from '_services';
import { alertActions } from './';
import { history } from '_helpers';

export const formActions = {
    storeForm,
    getForms,
    getForm,
    createForm,
    updateForm,
    deleteForm,
    deleteMultipleForm,
    updateFormStatus,
    getFormMessage,
    updateFormMessage,
    sendTestEmailMessage,
    findEmailTemplate,
    selectEmailTemplate,
    getAllFormGroup,
    createFormGroup,
    createFormGroupInBetween,
    findFormGroup,
    updateFormGroup,
    deleteFormGroup,
    enableFormGroup,
    reorderGroup,
    getAllFields,
    createField,
    createFieldInBetween,
    findField,
    updateField,
    deleteField,
    enableField,
    reorderField,
    getAllCustomField,
    createCustomField,
    findCustomField,
    updateCustomField,
    deleteCustomField,
    enableCustomField,
    reorderCustomField,
    findAllField,
    updateIdentifier,
    updateSubIdentifier,
    getAllFieldOption,
    createFieldOption,
    findFieldOption,
    updateFieldOption,
    deleteFieldOption,
    getAllPreset,
    createPreset,
    createPresetInBetween,
    findPreset,
    updatePreset,
    getAllTemplate,
    createTemplate,
    findTemplate,
    updateTemplate,
    getFormIntegration,
    updateFormIntegration,
    getFormAccessKey,
    getDefaultForm
};

function storeForm(form) {
  formService.storeForm(form);
}

function getForms(project_id){
  return dispatch => {
    dispatch(request(project_id));
    formService.getForms(project_id)
      .then(
        forms => {
          dispatch(success(forms));
        },
        error => {
          dispatch(failure(error.toString()));
          dispatch(alertActions.error(error.toString()));
        }
      );
  };

  function request(project_id) { return { type: formConstants.GET_ALL_REQUEST, project_id } }
  function success(forms) {
  
    // ============================= //
    // let formsResponse = JSON.parse(sessionStorage.getItem('forms'));
    // console.log('LOCAL => forms', formsResponse);
    // ============================= //
    return { type: formConstants.GET_ALL_SUCCESS, forms }
  }
  function failure(error) { return { type: formConstants.GET_ALL_FAILURE, error } }
}

function createForm(form, project_id) {
  return dispatch => {
    dispatch(request(form, project_id));
    formService.createForm(form, project_id)
      .then(
        form => {
          dispatch(success(form, project_id));
          dispatch(alertActions.success('Created form successfully'));
        },
        error => {
          dispatch(failure(error.toString()));
          //dispatch(alertActions.error(error.toString()));
        }
      );
  };

  function request(form) { return { type: formConstants.CREATE_REQUEST, form } }
  function success(form, project_id) {

    // ============================= //
    // let formResponse = JSON.parse(sessionStorage.getItem('form'));
    // console.log('LOCAL => form', formResponse);
    // let blankFormGroup = {
    //   name: "New Form Group",
    //   layout: "1"
    // };
    // ============================= //
    //formActions.createFormGroup(project_id, form.data.form_id, blankFormGroup);
    history.push(`/project/${project_id}/form/${form.data.form_id}/form-builder`);
    return { type: formConstants.CREATE_SUCCESS, form }
  }
  function failure(error) { return { type: formConstants.CREATE_FAILURE, error } }
}

function getForm(project_id, form_id){
    return dispatch => {
        dispatch(request(project_id, form_id));
        formService.getForm(project_id, form_id)
            .then(
                form => {
                    dispatch(success(form));
                },
                error => {
                    dispatch(failure(error.toString()));
                    //dispatch(alertActions.error(form_id, error.toString()));
                }
            );
    };

    function request(form_id) { return { type: formConstants.GET_REQUEST, form_id } }
    function success(form) {
        // ============================= //
        // let formResponse = JSON.parse(sessionStorage.getItem('form'));
        // console.log('LOCAL => form', formResponse);
        // ============================= //
        return { type: formConstants.GET_SUCCESS, form }
    }
    function failure(error) { return { type: formConstants.GET_FAILURE, error } }
}

function getDefaultForm(project_id){
    return dispatch => {
        dispatch(request(project_id));
        formService.getDefaultForm(project_id)
            .then(
                form => {
                    dispatch(success(form));
                },
                error => {
                    dispatch(failure(error.toString()));
                }
            );
    };

    function request(project_id) { return { type: formConstants.GET_REQUEST, project_id } }
    function success(form) {
        return { type: formConstants.GET_SUCCESS, form }
    }
    function failure(error) { return { type: formConstants.GET_FAILURE, error } }
}

function updateForm(form, form_id, project_id) {
  return dispatch => {
    dispatch(request(form, form_id, project_id));
    formService.updateForm(form, form_id, project_id)
      .then(
        form => {
          dispatch(success(form));
          dispatch(alertActions.success('Form updated successfully'));
        },
        error => {
          dispatch(failure(error.toString()));
          dispatch(alertActions.error(error.toString()));
        }
      );
  };

  function request(form) { return { type: formConstants.UPDATE_REQUEST, form } }
  function success(form) {
  
    // ============================= //
    // let formResponse = JSON.parse(sessionStorage.getItem('form'));
    // console.log('LOCAL => form', formResponse);
    return { type: formConstants.UPDATE_SUCCESS, form }
  }
  function failure(error) { return { type: formConstants.UPDATE_FAILURE, error } }
}

function deleteForm(form, project_id, form_id){
  return dispatch => {
    dispatch(request(form, project_id, form_id));
    formService.deleteForm(form, project_id, form_id)
      .then(
        form => {
            // console.log('project_id actions', project_id);
          dispatch(success());
          //dispatch(alertActions.success(`Form deleted`));
          window.location.reload();
          //history.push('/project/' + project_id + '/forms');
        },
        error => {
          dispatch(failure(error.toString()));
          dispatch(alertActions.error(error.toString()));
        }
      );
  };

  function request(form) { return { type: formConstants.DELETE_REQUEST, form } }
  function success() {
      /*sessionStorage.setItem('form', JSON.stringify(form.items.data));
      let formResponse = JSON.parse(sessionStorage.getItem('form.items.data'));
      // console.log('form response', formResponse);*/
      return { type: formConstants.DELETE_SUCCESS } }
  function failure(error) { return { type: formConstants.DELETE_FAILURE, error } }
}

function deleteMultipleForm(form, project_id){
    return dispatch => {
        dispatch(request(form, project_id));
        formService.deleteMultipleForm(form, project_id)
            .then(
                form => {
                    dispatch(success());
                    dispatch(alertActions.success(`Forms deleted`));
                },
                error => {
                    dispatch(failure(error.toString()));
                    dispatch(alertActions.error(error.toString()));
                }
            );
    };

    function request(form) { return { type: formConstants.DELETE_MULTIPLE_REQUEST, form } }
    function success(form) { return { type: formConstants.DELETE_MULTIPLE_SUCCESS, form } }
    function failure(error) { return { type: formConstants.DELETE_MULTIPLE_FAILURE, error } }
}

function updateFormStatus(form_id, status){
    return dispatch => {
        dispatch(request(form_id, status));
        formService.updateFormStatus(form_id, status)
            .then(
                status => {
                    dispatch(success());
                    dispatch(alertActions.success(`Successfully changed status.`))
                },
                error => {
                    dispatch(failure(error.toString()));
                    dispatch(alertActions.error(error.toString()))
                }
            );
    };

    function request(status) { return {type: formConstants.UPDATE_STATUS_REQUEST,status}}
    function success(status) { return {type: formConstants.UPDATE_STATUS_SUCCESS,status}}
    function failure(error) { return {type: formConstants.UPDATE_STATUS_FAILURE, error}}
}

function getFormMessage(project_id, form_id) {
  return dispatch => {
    dispatch(request(project_id, form_id));
    formService.getFormMessage(project_id, form_id)
      .then(
        message => {
          dispatch(success(message));
        },
        error => {
          dispatch(failure(error.toString()));
          dispatch(alertActions.error(error.toString()))
        }
      );
  };

  function request(message) { return {type: formConstants.GET_MESSAGE_REQUEST, message}}
  function success(message) {
    
    // ============================= //
    //  let formMessageResponse = JSON.parse(sessionStorage.getItem('formMessage'));
    //  console.log('LOCAL => formMessage', formMessageResponse);
    // ============================= //
    return {type: formConstants.GET_MESSAGE_SUCCESS, message}
  }
  function failure(error) { return {type: formConstants.GET_MESSAGE_FAILURE, error}}
}

function updateFormMessage(project_id, form_id, message) {
    return dispatch => {
        dispatch(request(project_id, form_id, message));
        //sessionStorage.removeItem('formMessage');
        formService.updateFormMessage(project_id, form_id, message)
            .then(
                message => {
                    dispatch(success(message));
                    //dispatch(alertActions.success(`Successfully updated message.`))
                },
                error => {
                    dispatch(failure(error.toString()));
                    //dispatch(alertActions.error(error.toString()))
                }
            );
    };

    function request(message) { return {type: formConstants.UPDATE_MESSAGE_REQUEST, message}}
    function success(message) {
      
      // ============================= //
      // let formMessageResponse = JSON.parse(sessionStorage.getItem('formMessage'));
      // console.log('LOCAL => formMessage', formMessageResponse);
      // ============================= //
      return {type: formConstants.UPDATE_MESSAGE_SUCCESS, message}
    }
    function failure(error) { return {type: formConstants.UPDATE_MESSAGE_FAILURE, error}}
}

function sendTestEmailMessage(project_id, form_id){
    return dispatch => {
        dispatch(request(project_id, form_id));
        formService.sendTestEmailMessage(project_id, form_id)
            .then(
                sendTest =>{
                    dispatch(success(sendTest));
                    dispatch(alertActions.success('Message successfully sent.'));
                },
                error => {
                  let errorMessage = '';
                  if(error.toString() === 'Error: Request failed with status code 500'){
                    errorMessage = 'Please connect your Gmail account first and try again.'
                  } else {
                    errorMessage = error.toString();
                  }
                  dispatch(failure(errorMessage));
                  dispatch(alertActions.error(errorMessage));
                }
            );
    };

    function request(project_id, form_id) { return {type: formConstants.SEND_MESSAGE_REQUEST, project_id, form_id}}
    function success(sendTest) { return {type: formConstants.SEND_MESSAGE_SUCCESS, sendTest}}
    function failure(error) { return {type: formConstants.SEND_MESSAGE_FAILURE, error}}
}

function findEmailTemplate(project_id, form_id, layout_id){
    return dispatch => {
        dispatch(request(project_id, form_id, layout_id));
        formService.findEmailTemplate(project_id, form_id, layout_id)
            .then(
                form => {
                    dispatch(success());
                    dispatch(alertActions.success(form))
                },
                error => {
                    dispatch(failure(error.toString()));
                    dispatch(alertActions.error(error.toString()))
                }
            );
    };

    function request(form) { return {type: formConstants.GET_LAYOUT_REQUEST, form}}
    function success(form) { return {type: formConstants.GET_LAYOUT_SUCCESS, form}}
    function failure(error) { return {type: formConstants.GET_LAYOUT_FAILURE, error}}
}

function selectEmailTemplate(project_id, form_id, layout_id){
    return dispatch => {
        dispatch(request(project_id, form_id, layout_id));
        formService.selectEmailTemplate(project_id, form_id, layout_id)
            .then(
                form => {
                    dispatch(success());
                    dispatch(alertActions.success(form))
                },
                error => {
                    dispatch(failure(error.toString()));
                    dispatch(alertActions.error(error.toString()))
                }
            );
    };

    function request(form) { return {type: formConstants.GET_LAYOUT_REQUEST, form}}
    function success(form) { return {type: formConstants.GET_LAYOUT_SUCCESS, form}}
    function failure(error) { return {type: formConstants.GET_LAYOUT_FAILURE, error}}
}

function getAllFormGroup(project_id, form_id){
    return dispatch => {
        dispatch(request(project_id, form_id));
        formService.getAllFormGroup(project_id, form_id)
            .then(
                group => {
                    dispatch(success());
                    dispatch(alertActions.success(group))
                },
                error => {
                    dispatch(failure(error.toString()));
                    dispatch(alertActions.error(error.toString()))
                }
            );
    };

    function request(group) {return {type: formConstants.GET_ALL_GROUP_REQUEST, group}}
    function success(group) {return {type: formConstants.GET_ALL_GROUP_SUCCESS, group}}
    function failure(error) {return {type: formConstants.GET_ALL_GROUP_FAILURE, error}}
}

function createFormGroup(project_id, form_id, group){
    return dispatch => {
        dispatch(request(project_id, form_id, group));
        formService.createFormGroup(project_id, form_id, group)
            .then(
                group => {
                    dispatch(success(group));
                    //dispatch(alertActions.success(`Successfully created form group.`))
                },
                error => {
                    dispatch(failure(error.toString()));
                    dispatch(alertActions.error(error.toString()))
                }
            );
    };

    function request(group) { return {type: formConstants.CREATE_FORM_GROUP_REQUEST, group}}
    function success(group) {return {type: formConstants.CREATE_FORM_GROUP_SUCCESS, group}}
    function failure(error) { return {type: formConstants.CREATE_FORM_GROUP_FAILURE, error}}
}

function createFormGroupInBetween(project_id, form_id, group, index){
  return dispatch => {
    dispatch(request(project_id, form_id, group));
    formService.createFormGroup(project_id, form_id, group)
      .then(
        group => {
          dispatch(success(group));
          // REORDER AFTER CREATING NEW GROUP
          let formData = JSON.parse(sessionStorage.getItem('form'));
          let formGroupData = formData.group;
          formGroupData = formGroupData.sort((a, b) => a.form_order > b.form_order);
          let newFormGroup = group.data;
          let reorder = [];

          for(let i = index - 1; i <= formGroupData.length; i++){
            let formGroupDataAllProperties = formGroupData[i];

            if(typeof formGroupDataAllProperties !== 'undefined'){
              let formGroupDataTrimmedProperties = {
                id: formGroupDataAllProperties.id,
                form_order: formGroupDataAllProperties.form_order + 1
              };
              reorder.push(formGroupDataTrimmedProperties);
            }
          }

          let newFormGroupTrimmed = {
            id: newFormGroup.id,
            form_order: parseInt(index)
          };

          reorder.push(newFormGroupTrimmed);

          let reorderData = {
            reorder: reorder
          };

          dispatch(reorderGroup(project_id, form_id, reorderData));
        },
        error => {
          dispatch(failure(error.toString()));
          //dispatch(alertActions.error(error.toString()))
        }
      );
  };

  function request(group) { return {type: formConstants.CREATE_FORM_GROUP_REQUEST, group}}
  function success(group) {return {type: formConstants.CREATE_FORM_GROUP_SUCCESS, group}}
  function failure(error) { return {type: formConstants.CREATE_FORM_GROUP_FAILURE, error}}
}

function findFormGroup(project_id, form_id, group_id) {
    return dispatch => {
        dispatch(request(project_id, form_id));
        formService.findFormGroup(project_id, form_id, group_id)
            .then(
                group => {
                    dispatch(success());
                    dispatch(alertActions.success(group))
                },
                error => {
                    dispatch(failure(error.toString()));
                    dispatch(alertActions.error(error.toString()))
                }
            );
    };

    function request(group) { return {type: formConstants.GET_GROUP_REQUEST, group}}
    function success(group) { return {type: formConstants.GET_GROUP_SUCCESS, group}}
    function failure(error) { return {type: formConstants.GET_GROUP_FAILURE, error}}
}

function updateFormGroup(project_id, form_id, group_id, group){
    return dispatch => {
        dispatch(request(project_id, form_id, group_id, group));
        formService.updateFormGroup(project_id, form_id, group_id, group)
            .then(
                group => {
                    dispatch(success(group))
                    dispatch(alertActions.success(`Successfully updated group.`))
                },
                error => {
                    dispatch(failure(error.toString()));
                    //dispatch(alertActions.error(error.toString()))
                }
            );
    };

    function request(group) {return {type: formConstants.UPDATE_GROUP_REQUEST, group}}
    function success(group) {return {type: formConstants.UPDATE_GROUP_SUCCESS, group}}
    function failure(error) {return {type: formConstants.UPDATE_GROUP_FAILURE, error}}
}

function deleteFormGroup(project_id, form_id, group_id, group) {
    return dispatch => {
        dispatch(request(project_id, form_id, group_id, group))
        formService.deleteFormGroup(project_id, form_id, group_id, group)
            .then(
                group => {
                    dispatch(success());
                    dispatch(alertActions.success(`Successfully deleted group`))
                },
                error => {
                    dispatch(failure(error.toString()));
                    dispatch(alertActions.error(error.toString()))
                }
            );
    };

    function request(group) {return {type: formConstants.DELETE_GROUP_REQUEST, group}}
    function success() {return {type: formConstants.DELETE_GROUP_SUCCESS}}
    function failure(error) {return {type: formConstants.DELETE_GROUP_FAILURE, error}}
}

function enableFormGroup(project_id, form_id, group_id, status){
    return dispatch => {
        dispatch(request(project_id, form_id, group_id, status));
        formService.enableFormGroup(project_id, form_id, group_id, status)
            .then(
                status => {
                    dispatch(success());
                    dispatch(alertActions.success(`Successfully changed status.`))
                },
                error => {
                    dispatch(failure(error.toString()));
                    dispatch(alertActions.error(error.toString()))
                }
            );
    };

    function request(status) { return {type: formConstants.UPDATE_GROUP_STATUS_REQUEST, status}}
    function success(status) { return {type: formConstants.UPDATE_GROUP_STATUS_SUCCESS, status}}
    function failure(error) { return {type: formConstants.UPDATE_GROUP_STATUS_FAILURE, error}}
}

function reorderGroup(project_id, form_id, reorder){
    return dispatch => {
        dispatch(request(project_id, form_id, reorder));
        formService.reorderGroup(project_id, form_id, reorder)
            .then(
                reorder => {
                    dispatch(success(reorder));
                    dispatch(formActions.getForm(project_id, form_id));
                    //dispatch(alertActions.success('Successfully reordered form groups.'))
                },
                error => {
                    dispatch(failure(error.toString()));
                    dispatch(alertActions.error(error.toString()))
                }
            );
    };

    function request(project_id, form_id, reorder) { return {type: formConstants.REORDER_GROUP_REQUEST, reorder}}
    function success(reorder) { return {type: formConstants.REORDER_GROUP_SUCCESS, reorder}}
    function failure(error) { return {type: formConstants.REORDER_GROUP_FAILURE, error}}
}

// function getAllField(project_id, form_id, group_id){
//     return dispatch => {
//         dispatch(request(project_id, form_id, group_id));
//         formService.getAllField(project_id, form_id, group_id)
//             .then(
//                 field => {
//                     dispatch(success());
//                     dispatch(alertActions.success(field))
//                 },
//                 error => {
//                     dispatch(failure(error.toString()));
//                     dispatch(alertActions.error(error.toString()))
//                 }
//             );
//     };
//
//     function request(field) {return {type: formConstants.GET_ALL_FIELD_REQUEST, field}}
//     function success(field) {return {type: formConstants.GET_ALL_FIELD_SUCCESS, field}}
//     function failure(error) {return {type: formConstants.GET_ALL_FIELD_FAILURE, error}}
// }

function createField(project_id, form_id, group_id, field){
  return dispatch => {
    dispatch(request(project_id, form_id, group_id, field));
    formService.createField(project_id, form_id, group_id, field)
      .then(
        field => {
          dispatch(success(field));
          //dispatch(alertActions.success(`Successfully created form field.`))
        },
        error => {
          dispatch(failure(error.toString()));
          dispatch(alertActions.error(error.toString()))
        }
      );
  };

  function request(field) { return {type: formConstants.CREATE_GROUP_FIELD_REQUEST, field}}
  function success(field) {
    // sessionStorage.setItem('field', JSON.stringify(field.data));
    // // ============================= //
    // let fieldResponse = JSON.parse(sessionStorage.getItem('field'));
    // console.log('LOCAL => field', fieldResponse);
    // // ============================= //
    return {type: formConstants.CREATE_GROUP_FIELD_REQUEST, field}
  }
  function failure(error) { return {type: formConstants.CREATE_GROUP_FIELD_REQUEST, error}}
}

function createFieldInBetween(project_id, form_id, group_id, field, index, totalNum){
  return dispatch => {
    dispatch(request(project_id, form_id, group_id, field));
    formService.createField(project_id, form_id, group_id, field)
      .then(
        field => {
          dispatch(success(field));

          // REORDER AFTER CREATING NEW FIELD
          let formData = JSON.parse(sessionStorage.getItem('form'));
          let formGroupData = formData.group;
          formGroupData = formGroupData.sort((a, b) => a.form_order > b.form_order);
          let formGroup = formGroupData.find(x => x.id === parseInt(group_id));
          let formFieldData = formGroup.field;
          formFieldData = formFieldData.sort((a, b) => a.form_order > b.form_order);
          let newFormField = field.data;
          let reorder = [];

          for(let i = index - 1; i <= formFieldData.length; i++){
            let formFieldDataAllProperties = formFieldData[i];

            if(typeof formFieldDataAllProperties !== 'undefined'){
              let formFieldDataTrimmedProperties = {
                id: formFieldDataAllProperties.id,
                form_order: formFieldDataAllProperties.form_order + 1
              };
              reorder.push(formFieldDataTrimmedProperties);
            }
          }

          let newFormFieldTrimmed = {
            id: newFormField.id,
            form_order: parseInt(index)
          };

          reorder.push(newFormFieldTrimmed);

          let reorderData = {
            reorder: reorder
          };

          dispatch(reorderField(project_id, form_id, group_id, reorderData));
          //dispatch(alertActions.success(`Successfully created form field.`))
        },
        error => {
          dispatch(failure(error.toString()));
          //dispatch(alertActions.error(error.toString()))
        }
      );
  };

  function request(field) { return {type: formConstants.CREATE_GROUP_FIELD_REQUEST, field}}
  function success(field) {
    // sessionStorage.setItem('field', JSON.stringify(field.data));
    // // ============================= //
    // let fieldResponse = JSON.parse(sessionStorage.getItem('field'));
    // console.log('LOCAL => field', fieldResponse);
    // // ============================= //
    return {type: formConstants.CREATE_GROUP_FIELD_REQUEST, field}
  }
  function failure(error) { return {type: formConstants.CREATE_GROUP_FIELD_REQUEST, error}}
}

function findField(project_id, form_id, group_id, field_id) {
    return dispatch => {
        dispatch(request(project_id, form_id, group_id, field_id));
        formService.findField(project_id, form_id, group_id, field_id)
          .then(
            field => {
              dispatch(success(field));
              //dispatch(alertActions.success(field))
            },
            error => {
              dispatch(failure(error.toString()));
              dispatch(alertActions.error(error.toString()))
            }
          );
    };

    function request(field) { return {type: formConstants.GET_FIELD_REQUEST, field}}
    function success(field) {
      // sessionStorage.setItem('field', JSON.stringify(field.data));
      // // ============================= //
      // let fieldResponse = JSON.parse(sessionStorage.getItem('field'));
      // console.log('LOCAL => field', fieldResponse);
      // // ============================= //
      // return {type: formConstants.GET_FIELD_SUCCESS, field}
    }
    function failure(error) { return {type: formConstants.GET_FIELD_FAILURE, error}}
}

function updateField(project_id, form_id, group_id, field_id, field){
    return dispatch => {
        dispatch(request(project_id, form_id, group_id, field_id, field));
        formService.updateField(project_id, form_id, group_id, field_id, field)
            .then(
                field => {
                    dispatch(success(field));
                    //dispatch(alertActions.success(`Successfully updated field.`))
                },
                error => {
                    dispatch(failure(error.toString()));
                    //dispatch(alertActions.error(error.toString()))
                }
            );
    };

    function request(field) {return {type: formConstants.UPDATE_FIELD_REQUEST, field}}
    function success(field) {return {type: formConstants.UPDATE_FIELD_SUCCESS, field}}
    function failure(error) {return {type: formConstants.UPDATE_FIELD_FAILURE, error}}
}

function deleteField(project_id, form_id, group_id, field_id, field){
    return dispatch => {
        dispatch(request(project_id, form_id, group_id, field_id, field))
        formService.deleteField(project_id, form_id, group_id, field_id, field)
            .then(
                field => {
                    dispatch(success());
                    dispatch(alertActions.success(`Successfully deleted field`))
                },
                error => {
                    dispatch(failure(error.toString()))
                    dispatch(alertActions.error(error.toString()))
                }
            );
    };

    function request(field) {return {type: formConstants.DELETE_FIELD_REQUEST, field}}
    function success(field) {return {type: formConstants.DELETE_FIELD_SUCCESS, field}}
    function failure(error) {return {type: formConstants.DELETE_FIELD_FAILURE, error}}
}

function enableField(project_id, form_id, group_id, field_id, status){
    return dispatch => {
        dispatch(request(project_id, form_id, group_id, field_id, status));
        formService.enableField(project_id, form_id, group_id, field_id, status)
            .then(
                status => {
                    dispatch(success(status));
                    dispatch(alertActions.success(`Successfully changed status.`))
                },
                error => {
                    dispatch(failure(error.toString()));
                    dispatch(alertActions.error(error.toString()))
                }
            );
    };

    function request(status) { return {type: formService.UPDATE_FIELD_STATUS_REQUEST, status}}
    function success(status) { return {type: formService.UPDATE_FIELD_STATUS_SUCCESS, status}}
    function failure(error) { return {type: formService.UPDATE_FIELD_STATUS_FAILURE, error}}
}

function reorderField(project_id, form_id, group_id, reorder){
  return dispatch => {
    dispatch(request(project_id, form_id, group_id, reorder));
    formService.reorderField(project_id, form_id, group_id, reorder)
      .then(
        reorder => {
          dispatch(success(reorder));
          dispatch(formActions.getForm(project_id, form_id));
          //dispatch(alertActions.success('Successfully reordered form fields.'))
        },
        error => {
          dispatch(failure(error.toString()));
          //dispatch(alertActions.error(error.toString()))
        }
      );
  };

  function request(project_id, form_id, group_id, reorder) { return {type: formConstants.REORDER_FIELD_REQUEST, reorder}}
  function success(reorder) { return {type: formConstants.REORDER_FIELD_SUCCESS, reorder}}
  function failure(error) { return {type: formConstants.REORDER_FIELD_FAILURE, error}}
}

function getAllCustomField(project_id, form_id){
    return dispatch => {
        dispatch(request(project_id, form_id));
        formService.getAllCustomField(project_id, form_id)
            .then(
                field => {
                    dispatch(success());
                    dispatch(alertActions.success(field))
                },
                error => {
                    dispatch(failure(error.toString()));
                    dispatch(alertActions.error(error.toString()))
                }
            );
    };

    function request(field) {return {type: formConstants.GET_ALL_FIELD_REQUEST, field}}
    function success(field) {return {type: formConstants.GET_ALL_FIELD_SUCCESS, field}}
    function failure(error) {return {type: formConstants.GET_ALL_FIELD_FAILURE, error}}
}

function createCustomField(project_id, form_id, field){
    return dispatch => {
        dispatch(request(project_id, form_id, field));
        formService.createCustomField(project_id, form_id,  field)
            .then(
                field => {
                    dispatch(success(field));
                    dispatch(alertActions.success(`Successfully created group field.`))
                },
                error => {
                    dispatch(failure(error.toString()));
                    dispatch(alertActions.error(error.toString()))
                }
            );
    };

    function request(field) { return {type: formConstants.CREATE_GROUP_FIELD_REQUEST, field}}
    function success(field) {return {type: formConstants.CREATE_GROUP_FIELD_SUCCESS, field}}
    function failure(error) { return {type: formConstants.CREATE_GROUP_FIELD_FAILURE, error}}
}

function findCustomField(project_id, form_id, field_id){
    return dispatch => {
        dispatch(request(project_id, form_id, field_id));
        formService.findCustomField(project_id, form_id, field_id)
            .then(
                field => {
                    dispatch(success());
                    dispatch(alertActions.success(field))
                },
                error => {
                    dispatch(failure(error.toString()));
                    dispatch(alertActions.error(error.toString()))
                }
            );
    };

    function request(field) { return {type: formConstants.GET_GROUP_REQUEST, field}}
    function success(field) { return {type: formConstants.GET_GROUP_SUCCESS, field}}
    function failure(error) { return {type: formConstants.GET_GROUP_FAILURE, error}}
}

function updateCustomField(project_id, form_id, field_id, field) {
    return dispatch => {
        dispatch(request(project_id, form_id, field_id, field));
        formService.updateCustomField(project_id, form_id, field_id, field)
            .then(
                field => {
                    dispatch(success(field))
                    dispatch(alertActions.success(`Successfully updated field.`))
                },
                error => {
                    dispatch(failure(error.toString()));
                    dispatch(alertActions.error(error.toString()))
                }
            );
    };

    function request(field) {return {type: formConstants.UPDATE_FIELD_REQUEST, field}}
    function success(field) {return {type: formConstants.UPDATE_FIELD_SUCCESS, field}}
    function failure(error) {return {type: formConstants.UPDATE_FIELD_FAILURE, error}}
}

function deleteCustomField(project_id, form_id, field_id, field){
    return dispatch => {
        dispatch(request(project_id, form_id, field_id, field))
        formService.deleteCustomField(project_id, form_id, field_id, field)
            .then(
                field => {
                    dispatch(success());
                    dispatch(alertActions.success(`Successfully deleted field`))
                },
                error => {
                    dispatch(failure(error.toString()))
                    dispatch(alertActions.error(error.toString()))
                }
            );
    };

    function request(field) {return {type: formConstants.DELETE_FIELD_REQUEST, field}}
    function success(field) {return {type: formConstants.DELETE_FIELD_SUCCESS, field}}
    function failure(error) {return {type: formConstants.DELETE_GROUP_FAILURE, error}}
}

function enableCustomField(project_id, form_id, group_id, field_id, status){
    return dispatch => {
        dispatch(request(project_id, form_id, group_id, field_id, status));
        formService.enableCustomField(project_id, form_id, group_id, field_id, status)
            .then(
                status => {
                    dispatch(success(status));
                    dispatch(alertActions.success(`Successfully changed status.`))
                },
                error => {
                    dispatch(failure(error.toString()));
                    dispatch(alertActions.error(error.toString()))
                }
            );
    };

    function request(status) { return {type: formService.UPDATE_FIELD_STATUS_REQUEST, status}}
    function success(status) { return {type: formService.UPDATE_FIELD_STATUS_SUCCESS, status}}
    function failure(error) { return {type: formService.UPDATE_FIELD_STATUS_FAILURE, error}}
}

function reorderCustomField(project_id, form_id, reorder){
    return dispatch => {
        dispatch(request(project_id, form_id, reorder));
        formService.reorderCustomField(project_id, form_id, reorder)
            .then(
                reorder => {
                    dispatch(success(reorder));
                    dispatch(formActions.getForm(project_id, form_id));
                    dispatch(alertActions.success(reorder))
                },
                error => {
                    dispatch(failure(error.toString()));
                    dispatch(alertActions.error(error.toString()))
                }
            );
    };

    function request(reorder) { return {type: formService.REORDER_GROUP_FIELD_REQUEST, reorder}}
    function success(reorder) { return {type: formService.REORDER_GROUP_FIELD_SUCCESS, reorder}}
    function failure(error) { return {type: formService.REORDER_GROUP_FIELD_FAILURE, error}}
}

function findAllField(project_id, form_id){
    return dispatch => {
        dispatch(request(project_id, form_id));
        formService.findAllField(project_id, form_id)
            .then(
                fields => {
                    dispatch(success());
                    dispatch(alertActions.success(fields))
                },
                error => {
                    dispatch(failure(error.toString()));
                    dispatch(alertActions.error(error.toString()))
                }
            );
    };

    function request(project_id, form_id) {return {type: formConstants.GET_ALL_FIELD_REQUEST, project_id, form_id}}
    function success(fields) {return {type: formConstants.GET_ALL_FIELD_SUCCESS, fields}}
    function failure(error) {return {type: formConstants.GET_ALL_FIELD_FAILURE, error}}
}

function getAllFields(project_id, form_id){
  return dispatch => {
    dispatch(request(project_id, form_id));
    formService.getAllFields(project_id, form_id)
      .then(
        fieldData => {
          dispatch(success(fieldData));
          dispatch(alertActions.success())
        },
        error => {
          dispatch(failure(error.toString()));
          dispatch(alertActions.error(error.toString()))
        }
      );
  };

  function request(project_id, form_id) {return {type: formConstants.GET_ALL_FIELD_REQUEST, project_id, form_id}}
  function success(fieldData) {return {type: formConstants.GET_ALL_FIELD_SUCCESS, fieldData}}
  function failure(error) {return {type: formConstants.GET_ALL_FIELD_FAILURE, error}}
}

function updateIdentifier(project_id, form_id, identifier){
    return dispatch => {
        dispatch(request(project_id, form_id, identifier));
        formService.updateIdentifier(project_id, form_id, identifier)
            .then(
                form => {
                    dispatch(success(form));
                    dispatch(formActions.getForm(project_id, form_id));
                    dispatch(alertActions.success('Successfully set primary lead field.'));
                },
                error => {
                    dispatch(failure(error.toString()));
                    dispatch(alertActions.error(error.toString()))
                }
            );
    };

    function request(project_id, form_id, identifier) {return {type: formConstants.UPDATE_FIELD_IDENTIFIER_REQUEST, project_id, form_id, identifier}}
    function success(form) {return {type: formConstants.UPDATE_FIELD_IDENTIFIER_SUCCESS, form}}
    function failure(error) {return {type: formConstants.UPDATE_FIELD_IDENTIFIER_FAILURE, error}}
}

function updateSubIdentifier(project_id, form_id, subidentifier){
    return dispatch => {
        dispatch(request(project_id, form_id, subidentifier));
        formService.updateSubIdentifier(project_id, form_id, subidentifier)
            .then(
              form => {
                    dispatch(success(form));
                    dispatch(formActions.getForm(project_id, form_id));
                    dispatch(alertActions.success('Successfully set secondary lead field.'));
                },
                error => {
                    dispatch(failure(error.toString()));
                    dispatch(alertActions.error(error.toString()))
                }
            );
    };

    function request(project_id, form_id, subidentifier) {return {type: formConstants.UPDATE_FIELD_SUB_IDENTIFIER_REQUEST, project_id, form_id, subidentifier}}
    function success(form) {return {type: formConstants.UPDATE_FIELD_SUB_IDENTIFIER_SUCCESS, form}}
    function failure(error) {return {type: formConstants.UPDATE_FIELD_SUB_IDENTIFIER_FAILURE, error}}
}

function getAllFieldOption(project_id, form_id, group_id, field_id){
    return dispatch => {
        dispatch(request(project_id, form_id, group_id, field_id));
        formService.getAllFieldOption(project_id, form_id, group_id, field_id)
            .then(
                field_option => {
                    dispatch(success(field_option));
                    //dispatch(alertActions.success(field_option))
                },
                error => {
                    dispatch(failure(error.toString()));
                    dispatch(alertActions.error(error.toString()))
                }
            );
    };

    function request(field_option) {return {type: formConstants.GET_ALL_OPTION_REQUEST, field_option}}
    function success(field_option) {
      // sessionStorage.setItem('options', JSON.stringify(field_option.data));
      // // ============================= //
      // let optionResponse = JSON.parse(sessionStorage.getItem('options'));
      // console.log('LOCAL => options', optionResponse);
      // // ============================= //
      return {type: formConstants.GET_ALL_OPTION_SUCCESS, field_option}
    }
    function failure(error) {return {type: formConstants.GET_ALL_OPTION_FAILURE, error}}
}

function createFieldOption(project_id, form_id, group_id, field_id, option){
    return dispatch => {
        dispatch(request(project_id, form_id, group_id, field_id, option));
        formService.createFieldOption(project_id, form_id, group_id, field_id, option)
            .then(
                field_option => {
                    dispatch(success(field_option));
                    dispatch(formActions.getForm(project_id, form_id));
                    //dispatch(alertActions.success(`Successfully created field option.`))
                },
                error => {
                    dispatch(failure(error.toString()));
                    dispatch(alertActions.error(error.toString()))
                }
            );
    };


    function request(field_option) { return {type: formConstants.CREATE_FIELD_OPTION_REQUEST, field_option}}
    function success(field_option) {
      sessionStorage.setItem('option', JSON.stringify(field_option.data));
      // ============================= //
      // let optionResponse = JSON.parse(sessionStorage.getItem('option'));
      // console.log('LOCAL => option', optionResponse);
      // ============================= //
      return {type: formConstants.CREATE_FIELD_OPTION_SUCCESS, field_option}
    }
    function failure(error) { return {type: formConstants.CREATE_FIELD_OPTION_FAILURE, error}}
}

function findFieldOption(project_id, form_id, group_id, field_id, option_id){
    return dispatch => {
        dispatch(request(project_id, form_id, group_id, field_id, option_id));
        formService.findFieldOption(project_id, form_id, group_id, field_id, option_id)
            .then(
                field_option => {
                    dispatch(success(field_option));
                    dispatch(alertActions.success());
                },
                error => {
                    dispatch(failure(error.toString()));
                    //dispatch(alertActions.error(error.toString()))
                }
            );
    };

    function request(project_id, form_id, group_id, field_id, option_id) { return {type: formConstants.GET_OPTION_REQUEST, project_id, form_id, group_id, field_id, option_id}}
    function success(field_option) {
      
      // ============================= //
      // let optionResponse = JSON.parse(sessionStorage.getItem('option'));
      // console.log('LOCAL => option', optionResponse);
      // ============================= //
      return {type: formConstants.GET_OPTION_SUCCESS, field_option}
    }
    function failure(error) { return {type: formConstants.GET_OPTION_FAILURE, error}}
}

function updateFieldOption(project_id, form_id, group_id, field_id, option_id, option){
    return dispatch => {
        dispatch(request(project_id, form_id, group_id, field_id, option_id, option));
        formService.updateFieldOption(project_id, form_id, group_id, field_id, option_id, option)
            .then(
                field_option => {
                    dispatch(success(field_option));
                    //dispatch(alertActions.success(`Successfully updated option.`))
                },
                error => {
                    dispatch(failure(error.toString()));
                    //dispatch(alertActions.error(error.toString()))
                }
            );
    };

    function request(field_option) {return {type: formConstants.UPDATE_OPTION_REQUEST, field_option}}
    function success(field_option) {
      sessionStorage.setItem('option', JSON.stringify(field_option.data));
      // ============================= //
      // let optionResponse = JSON.parse(sessionStorage.getItem('option'));
      // console.log('LOCAL => option', optionResponse);
      // ============================= //
      return {type: formConstants.UPDATE_OPTION_SUCCESS, field_option}
    }
    function failure(error) {return {type: formConstants.UPDATE_OPTION_FAILURE, error}}
}

function deleteFieldOption(project_id, form_id, group_id, field_id, option_id, option){
    return dispatch => {
        dispatch(request(project_id, form_id, group_id, field_id, option_id, option));
        formService.deleteFieldOption(project_id, form_id, group_id, field_id, option_id, option)
            .then(
                field_option => {
                    dispatch(success());
                    dispatch(formActions.getForm(project_id, form_id));
                    dispatch(alertActions.success(`Successfully deleted option`))
                },
                error => {
                    let errorMessage = '';
                    if(error.toString() === 'Error: Request failed with status code 500'){
                      errorMessage = 'Deletion failed. Please reload the page and try again.'
                    } else {
                      errorMessage = error.toString();
                    }
                    dispatch(failure(errorMessage));
                    dispatch(alertActions.error(errorMessage));
                }
            );
    };

    function request(field_option) {return {type: formConstants.DELETE_OPTION_REQUEST, field_option}}
    function success() {return {type: formConstants.DELETE_OPTION_SUCCESS }}
    function failure(error) {return {type: formConstants.DELETE_OPTION_FAILURE, error}}
}

function getAllPreset(project_id){
    return dispatch => {
        dispatch(request(project_id));
        formService.getAllPreset(project_id)
            .then(
                presets => {
                    dispatch(success());
                    dispatch(alertActions.success(presets))
                },
                error => {
                    dispatch(failure(error.toString()));
                    dispatch(alertActions.error(error.toString()))
                }
            );
    };

    function request(presets) {return {type: formConstants.GET_ALL_PRESET_REQUEST, presets}}
    function success(presets) {return {type: formConstants.GET_ALL_PRESET_SUCCESS, presets}}
    function failure(error) {return {type: formConstants.GET_ALL_PRESET_FAILURE, error}}
}

function createPreset(project_id, form_id, preset_id){
    return dispatch => {
        dispatch(request(project_id, form_id, preset_id));
        formService.createPreset(project_id, form_id, preset_id)
            .then(
                presets => {
                    dispatch(success(presets));
                    dispatch(alertActions.success(`Successfully created form group.`))
                },
                error => {
                    dispatch(failure(error.toString()));
                    dispatch(alertActions.error(error.toString()))
                }
            );
    };

    function request(presets) { return {type: formConstants.CREATE_PRESET_REQUEST, presets}}
    function success(presets) {return {type: formConstants.CREATE_PRESET_SUCCESS, presets}}
    function failure(error) { return {type: formConstants.CREATE_PRESET_FAILURE, error}}
}

function createPresetInBetween(project_id, form_id, preset_id, index){
  return dispatch => {
    dispatch(request(project_id, form_id, preset_id));
    formService.createPreset(project_id, form_id, preset_id)
      .then(
        presets => {
          dispatch(success(presets));
          // REORDER AFTER CREATING NEW GROUP
          let formData = JSON.parse(sessionStorage.getItem('form'));
          let formGroupData = formData.group;
          formGroupData = formGroupData.sort((a, b) => a.form_order > b.form_order);
          let newFormGroup = presets.data;
          let reorder = [];

          for(let i = index - 1; i <= formGroupData.length; i++){
            let formGroupDataAllProperties = formGroupData[i];

            if(typeof formGroupDataAllProperties !== 'undefined'){
              let formGroupDataTrimmedProperties = {
                id: formGroupDataAllProperties.id,
                form_order: formGroupDataAllProperties.form_order + 1
              };
              reorder.push(formGroupDataTrimmedProperties);
            }
          }

          let newFormGroupTrimmed = {
            id: newFormGroup.id,
            form_order: parseInt(index)
          };

          reorder.push(newFormGroupTrimmed);

          let reorderData = {
            reorder: reorder
          };

          dispatch(reorderGroup(project_id, form_id, reorderData));
        },
        error => {
          dispatch(failure(error.toString()));
          dispatch(alertActions.error(error.toString()))
        }
      );
  };

  function request(presets) { return {type: formConstants.CREATE_PRESET_REQUEST, presets}}
  function success(presets) {return {type: formConstants.CREATE_PRESET_SUCCESS, presets}}
  function failure(error) { return {type: formConstants.CREATE_PRESET_FAILURE, error}}
}

function findPreset(project_id, preset_id) {
    return dispatch => {
        dispatch(request(project_id, preset_id));
        formService.findPreset(project_id, preset_id)
            .then(
                presets => {
                    dispatch(success());
                    dispatch(alertActions.success(presets))
                },
                error => {
                    dispatch(failure(error.toString()));
                    dispatch(alertActions.error(error.toString()))
                }
            );
    };

    function request(presets) { return {type: formConstants.GET_PRESET_REQUEST, presets}}
    function success(presets) { return {type: formConstants.GET_PRESET_SUCCESS, presets}}
    function failure(error) { return {type: formConstants.GET_PRESET_FAILURE, error}}
}

function updatePreset(project_id, preset_id, preset) {
    return dispatch => {
        dispatch(request(project_id, preset_id, preset));
        formService.updatePreset(project_id, preset_id, preset)
            .then(
                presets => {
                    dispatch(success(preset));
                    dispatch(alertActions.success(`Successfully updated preset.`))
                },
                error => {
                    dispatch(failure(error.toString()));
                    dispatch(alertActions.error(error.toString()))
                }
            );
    };

    function request(presets) {return {type: formConstants.UPDATE_PRESET_REQUEST, presets}}
    function success(presets) {return {type: formConstants.UPDATE_PRESET_SUCCESS, presets}}
    function failure(error) {return {type: formConstants.UPDATE_PRESET_FAILURE, error}}
}

function getAllTemplate(project_id){
    return dispatch => {
        dispatch(request(project_id));
        formService.getAllTemplate(project_id)
            .then(
                templates => {
                    dispatch(success());
                    dispatch(alertActions.success(templates))
                },
                error => {
                    dispatch(failure(error.toString()));
                    dispatch(alertActions.error(error.toString()))
                }
            );
    };

    function request(templates) {return {type: formConstants.GET_ALL_TEMPLATE_REQUEST, templates}}
    function success(templates) {return {type: formConstants.GET_ALL_TEMPLATE_SUCCESS, templates}}
    function failure(error) {return {type: formConstants.GET_ALL_TEMPLATE_FAILURE, error}}
}

function createTemplate(project_id, template_id){
    return dispatch => {
        dispatch(request(project_id, template_id));
        formService.createTemplate(project_id, template_id)
            .then(
                form => {
                    dispatch(success(form, project_id));
                    dispatch(alertActions.success(`Successfully created form.`))
                },
                error => {
                    dispatch(failure(error.toString()));
                    dispatch(alertActions.error(error.toString()))
                }
            );
    };

    function request(project_id, template_id) { return {type: formConstants.CREATE_TEMPLATE_REQUEST, project_id, template_id}}
    function success(form, project_id) {
      
      // ============================= //
      let formResponse = JSON.parse(sessionStorage.getItem('form'));
      // ============================= //
      history.push(`/project/${project_id}/form/${form.data.form_id}/form-builder`);
      return {type: formConstants.CREATE_TEMPLATE_SUCCESS, form}
    }
    function failure(error) { return {type: formConstants.CREATE_TEMPLATE_FAILURE, error}}
}

function findTemplate(project_id, template_id){
    return dispatch => {
        dispatch(request(project_id, template_id));
        formService.findTemplate(project_id, template_id)
            .then(
                templates => {
                    dispatch(success());
                    dispatch(alertActions.success(templates))
                },
                error => {
                    dispatch(failure(error.toString()));
                    dispatch(alertActions.error(error.toString()))
                }
            );
    };

    function request(templates) { return {type: formConstants.GET_TEMPLATE_REQUEST, templates}}
    function success(templates) { return {type: formConstants.GET_TEMPLATE_SUCCESS, templates}}
    function failure(error) { return {type: formConstants.GET_TEMPLATE_FAILURE, error}}
}

function updateTemplate(project_id, template_id, template){
    return dispatch => {
        dispatch(request(project_id, template_id, template));
        formService.updateTemplate(project_id, template_id, template)
            .then(
                templates => {
                    dispatch(success(templates))
                    dispatch(alertActions.success(`Successfully updated preset.`))
                },
                error => {
                    dispatch(failure(error.toString()));
                    dispatch(alertActions.error(error.toString()))
                }
            );
    };

    function request(templates) {return {type: formConstants.UPDATE_TEMPLATE_REQUEST, templates}}
    function success(templates) {return {type: formConstants.UPDATE_TEMPLATE_SUCCESS, templates}}
    function failure(error) {return {type: formConstants.UPDATE_TEMPLATE_FAILURE, error}}
}

function getFormAccessKey(project_id, form_id) {
  return dispatch => {
    dispatch(request(project_id, form_id));
    formService.getFormAccessKey(project_id, form_id)
      .then(
        accessKey => {
          dispatch(success(accessKey));
        },
        error => {
          dispatch(failure(error.toString()));
          dispatch(alertActions.error(error.toString()))
        }
      );
  };

  function request(project_id, form_id) { return {type: formConstants.GET_ACCESS_KEY_REQUEST, project_id, form_id}}
  function success(accessKey) {
    
    // ============================= //
    // let formAccessKeyResponse = JSON.parse(sessionStorage.getItem('formAccessKey'));
    // console.log('LOCAL => formAccessKey', formAccessKeyResponse);
    // ============================= //
    return {type: formConstants.GET_ACCESS_KEY_SUCCESS, accessKey}
  }
  function failure(error) { return {type: formConstants.GET_ACCESS_KEY_FAILURE, error}}
}

function getFormIntegration(project_id, form_id) {
  return dispatch => {
    dispatch(request(project_id, form_id));
    formService.getFormIntegration(project_id, form_id)
      .then(
        integration => {
          dispatch(success(integration));
        },
        error => {
          dispatch(failure(error.toString()));
          dispatch(alertActions.error(error.toString()))
        }
      );
  };

  function request(project_id, form_id) { return {type: formConstants.GET_INTEGRATION_REQUEST, project_id, form_id}}
  function success(integration) {
    
    // ============================= //
    // let formIntegrationResponse = JSON.parse(sessionStorage.getItem('formIntegration'));
    // console.log('LOCAL => formIntegration', formIntegrationResponse);
    // ============================= //
    return {type: formConstants.GET_INTEGRATION_SUCCESS, integration}
  }
  function failure(error) { return {type: formConstants.GET_INTEGRATION_FAILURE, error}}
}

function updateFormIntegration(project_id, form_id, integration) {
  return dispatch => {
    dispatch(request(project_id, form_id, integration));
    sessionStorage.removeItem('formIntegration');
    formService.updateFormIntegration(project_id, form_id, integration)
      .then(
        integration => {
          dispatch(success(integration));
          window.location.reload();
          //dispatch(alertActions.success(`Successfully updated integration.`))
        },
        error => {
          dispatch(failure(error.toString()));
          //dispatch(alertActions.error(error.toString()))
        }
      );
  };

  function request(project_id, form_id, integration) { return {type: formConstants.UPDATE_INTEGRATION_REQUEST, integration}}
  function success(integration) {
    
    // ============================= //
    // let formIntegrationResponse = JSON.parse(sessionStorage.getItem('formIntegration'));
    // console.log('LOCAL => formIntegration', formIntegrationResponse);
    // ============================= //
    return {type: formConstants.UPDATE_INTEGRATION_SUCCESS, integration}
  }
  function failure(error) { return {type: formConstants.UPDATE_INTEGRATION_FAILURE, error}}
}
