export const userConstants = {
    REGISTER_REQUEST: 'USERS_REGISTER_REQUEST',
    REGISTER_SUCCESS: 'USERS_REGISTER_SUCCESS',
    REGISTER_FAILURE: 'USERS_REGISTER_FAILURE',

    LOGIN_REQUEST: 'USERS_LOGIN_REQUEST',
    LOGIN_SUCCESS: 'USERS_LOGIN_SUCCESS',
    LOGIN_FAILURE: 'USERS_LOGIN_FAILURE',

    TWO_FACTOR_REQUEST: 'TWO_FACTOR_REQUEST',
    TWO_FACTOR_SUCCESS: 'TWO_FACTOR_SUCCESS',
    TWO_FACTOR_FAILURE: 'TWO_FACTOR_FAILURE',
    
    LOGOUT: 'USERS_LOGOUT',

    GETALL_REQUEST: 'USERS_GETALL_REQUEST',
    GETALL_SUCCESS: 'USERS_GETALL_SUCCESS',
    GETALL_FAILURE: 'USERS_GETALL_FAILURE',

    UPDATE_PASSWORD_REQUEST: 'USERS_UPDATE_PASSWORD_REQUEST',
    UPDATE_PASSWORD_SUCCESS: 'USERS_UPDATE_PASSWORD_SUCCESS',
    UPDATE_PASSWORD_FAILURE: 'USERS_UPDATE_PASSWORD_FAILURE',

    DELETE_REQUEST: 'USERS_DELETE_REQUEST',
    DELETE_SUCCESS: 'USERS_DELETE_SUCCESS',
    DELETE_FAILURE: 'USERS_DELETE_FAILURE',

    CLEAR_ERROR: 'CLEAR_ERROR',

};
