import { profileConstants } from "_constants";

export function profile(state = {}, action) {
  
  switch (action.type) {
    case profileConstants.GET_PROFILE_REQUEST:
      return {
        ...state,
        getProfileLoading: true,
        getProfileLoaded: false,
      };
    case profileConstants.GET_PROFILE_SUCCESS:
      return {
        ...state,
        getProfileLoading: false,
        getProfileLoaded: true,
        data: action.profile.data,
      };
    case profileConstants.GET_PROFILE_FAILURE:
      return {
        ...state,
        getProfileLoading: false,
        getProfileLoaded: true,
        error: action.error,
      };

    case profileConstants.UPDATE_PROFILE_REQUEST:
      return {
        ...state,
        getProfileLoading: true,
        getProfileLoaded: false,
      };
    case profileConstants.UPDATE_PROFILE_SUCCESS:
      return {
        ...state,
        getProfileLoading: false,
        getProfileLoaded: true,
        data: action.profile.data,
      };
    case profileConstants.UPDATE_PROFILE_FAILURE:
      return {
        ...state,
        getProfileLoading: false,
        getProfileLoaded: true,

        error: action.error,
      };

    default:
      return {
        ...state,
      };
  }
}
