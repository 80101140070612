import React, {useEffect, useRef, useState} from 'react';
import Button from "_pages/Influencer/components/General/Button";
import {EditorContent, useEditor} from "@tiptap/react";
import StarterKit from "@tiptap/starter-kit";
import Mention from '@tiptap/extension-mention';
import Placeholder from '@tiptap/extension-placeholder';
import Focus from '@tiptap/extension-focus';
import suggestion from '../../../Influencer/components/Info/NoteSuggestion';
import {useDispatch, useSelector} from "react-redux";
import DOMPurify from "dompurify";
import {utilitiesService as utility} from "_services";
import Moment from "react-moment";
import {influencerActions} from "_actions";
import NoteEditorBar from "_pages/Leads/components/LeadInfo/NoteEditorBar";
import NoteItem from './NoteItem';



const parseMentions = data => {
    const mentions = (data.content || []).flatMap(parseMentions)
    if (data.type === 'mention') {
        mentions.push(parseInt(data.attrs.id))
    }
    return mentions
}


const NewNote = ({ projectId, influencerId, classN }) => {

    const dispatch = useDispatch();

    const notesLoaded = useSelector((state) => state.influencers.influencerLoaded);
    const notesLoading = useSelector((state) => state.influencers.getInfluencerNoteLoading);
    const createLoading = useSelector((state) => state.influencers.addInfluencerNoteLoading);
    const updateLoading = useSelector((state) => state.influencers.updateInfluencerNoteLoading);
    const deleteLoading = useSelector((state) => state.influencers.deleteInfluencerNoteLoading);

    let notes = useSelector((state) => {

        const {influencers} = state;
        if (influencers.influencerLoaded && influencers.notes) {
            return influencers.notes; 
        } else {
            return [];
        }
    });


    // const mentions = useSelector((state) => {

    //     const {projects} = state;
    //     const {project, projectLoaded} = projects;

    //     if(projectLoaded) {

    //         project.data.owner.account.id = project.data.owner.id.toString();
    //         let members = [project.data.owner.account];

    //         project.data.members.forEach( m =>
    //             members.push(m.account)
    //         );

    //         return members.map(m => {
    //             return {id: m.id, label: utility.decrypt(m.first_name) + ' ' + utility.decrypt(m.last_name)}
    //         });

    //     } else {
    //         return [];
    //     }
    // });


    const [noteClicked, setNoteClicked] = useState(false);
    const noteRef = useRef();
    const addNoteRef = useRef(null);

    // suggestion.items = ({query}) => {
    //     return mentions.filter(item => item.label.toLowerCase().startsWith(query.toLowerCase())).slice(0, 5);
    // }

    const [isEditorEmpty, setEditorEmpty] = useState(true);

    const editorCreate = useEditor({
        extensions: [
            StarterKit,
            Mention.configure({
                HTMLAttributes: {
                  class: 'mention',
                },
                suggestion,
            }),
            Placeholder.configure({
              placeholder: 'Create Note Thread...',
            }),
        ],
        autofocus: true,
        content: ``,
    });

    useEffect(() => {
        if (editorCreate) {
          setEditorEmpty(editorCreate.isEmpty);
    
          // Add event listener to editor's content
          editorCreate.on('update', () => {
            setEditorEmpty(editorCreate.isEmpty);
          });
        }
    }, [editorCreate]);


    const handleToggleNote = () => {
        setNoteClicked(!noteClicked);
        editorCreate.commands.focus('end');
    };


    const sortNotes = (notes) => {
        console.log('sorting: ', notes)
        return [...notes].sort((a, b) => {
            return new Date(b.updated) - new Date(a.updated); });

    };

    const handleCreate = () => {

        const mentions = parseMentions(editorCreate.getJSON());
        const content = editorCreate.getHTML();

        // let noteData = {
        //     name: 'Note',
        //     description: content,
        //     mentions: mentions
        // };

        let noteType = 'note';

        const note = {
            title: noteType,
            content: content,
          }

        dispatch(influencerActions.addInfluencerNote(projectId, influencerId, note));

        setNoteClicked(!noteClicked);
        editorCreate.commands.setContent('');
        editorCreate.commands.focus('end');
    }

    const handleDeleteNote = (deleteNote) => {

        dispatch(influencerActions.deleteInfluencerNote(projectId, influencerId, deleteNote.id));

       // setNoteClicked(!noteClicked);
       // editorCreate.commands.focus('end');
    }


    const mentionClickCreate = () => {
        editorCreate.commands.insertContent(' @');
    }

    const handleToggleDeleteNote = (deleteNote) => {
        handleDeleteNote(deleteNote);
    }

    return(
        <div className={`${classN} notes-box tab-content-box`}>
            <div className='note-group'>
                <div className='inner-title-box' ref={addNoteRef}>
                    <p>
                        Notes
                    </p>
                    { notesLoaded && notes.length > 0 &&
                    <Button
                        text={noteClicked ? 'Close Note' : 'Add Note'}
                        icon={noteClicked ? 'icon-lms icon-close' : 'icon-lms icon-add'}
                        onClick={ () => handleToggleNote()}
                        classN="plain-btn"
                    />
                    }
                    <div className='note-create-box' ref={noteRef}
                        style={
                            noteClicked
                                ? { height: noteRef.current.scrollHeight }
                                : { height: "0" }
                        }
                        >
                            <div className='note-wrapper'>
                                <div className='custom-basic-editor note-editor'>
                                    <NoteEditorBar editor={editorCreate} />
                                    <EditorContent editor={editorCreate} className='thread-editor-field' />
                                    <div className='editor-btns-group note-editor-btns-group'>
                                        <div className="btn-left">
                                            {/* <span onClick={() => mentionClickCreate()}>
                                                <i className="fas fa-at"></i>Mention
                                            </span> */}
                                        </div>
                                        <div className="btn-right">
                                            <button className={isEditorEmpty ? 'plain-btn disabled' : 'plain-btn'} onClick={() => handleCreate()}>Create</button>
                                            
                                        </div>
                                    </div>
                                </div>
                            </div>
                    </div>
                </div>
            </div>
            <div className='notes-list'>
                {sortNotes(notes).map((note, index)=> {
                    return(<NoteItem
                        key={index}
                        note={note}
                        onHandleToggleDeleteNoteAction={(note)=> handleToggleDeleteNote(note)}
                    />);
                })}
            </div>

            { notesLoaded && notes.length == 0 &&
                <div className='empty-tab-content'>
                    <div className='empty-content'>
                        <h6>No Available Note</h6>
                        <p>
                            Notes and replies will appear here
                        </p>
                        <span onClick={ () => handleToggleNote()}>
                            <i className='icon-lms icon-add'></i>
                            Add Note
                        </span>
                    </div>
                </div>
            }

            {
                (notesLoading || deleteLoading || createLoading || updateLoading) &&
                <div className='loading-box'>
                    <div class="lds-ellipsis"><div></div><div></div><div></div><div></div></div>
                </div>
            }
        </div>
    )
}

export default NewNote;



