import { projectConstants } from '_constants';

export function projects(state = {}, action) {
  switch (action.type) {
    /*
    * PROJECTS
    * */

    // CREATE PROJECT
    case projectConstants.CREATE_REQUEST:
      return {
        creating: true
      };
    case projectConstants.CREATE_SUCCESS:
      return {};
    case projectConstants.CREATE_FAILURE:
      return {};

    // GET ALL PROJECTS
    case projectConstants.GETALL_REQUEST:
      return {
        ...state,
        loading: true,
        loaded: false,
        items: {}
      };
    case projectConstants.GETALL_SUCCESS:
      return {
        ...state,
        loading: false,
        loaded: true,
        items: action.projects
      };
    case projectConstants.GETALL_FAILURE:
      return {
        ...state,
        loading: false,
        loaded: true,
        error: action.error
      };

    // GET SPECIFIC PROJECT
    case projectConstants.GET_REQUEST:
      return {
        ...state,
        loading: true
      };
    case projectConstants.GET_SUCCESS:
      return {
        ...state,
        loading: false,
        project: action.project,
        projectLoaded: true
      };
    case projectConstants.GET_FAILURE:
      return {
        ...state,
        error: action.error
      };

    // UPDATE PROJECT
    case projectConstants.UPDATE_REQUEST:
      return {
        ...state,
        loading: true
      };
    case projectConstants.UPDATE_SUCCESS:
      return {
        ...state,
        loading: false,
        project: action.project,
        projectLoaded: true
      };
    case projectConstants.UPDATE_FAILURE:
      return {
        ...state,
        error: action.error
      };

    // DELETE PROJECT
    case projectConstants.DELETE_REQUEST:
      return {
        deleting: true
      };
    case projectConstants.DELETE_SUCCESS:
      return {
        deleted: true
      };
    case projectConstants.DELETE_FAILURE:
      return {
        error: action.error
      };

    // UPDATE STATUS
    case projectConstants.UPDATE_STATUS_REQUEST:
      return {
        ...state,
        loading: true
      };
    case projectConstants.UPDATE_STATUS_SUCCESS:
      return {
        ...state,
        items: action.project
      };
    case projectConstants.UPDATE_STATUS_FAILURE:
      return {
        ...state,
        error: action.error
      };

    /*
    * PROJECT MEMBERS
    * */

      // GET ALL PROJECT MEMBER
    case projectConstants.GET_ALL_MEMBER_REQUEST:
      return {
        ...state,
        loading: true
      };
    case projectConstants.GET_ALL_MEMBER_SUCCESS:
      return {
        ...state,
        loading: false,
        members: action.members,
        membersLoaded: true
      };
    case projectConstants.GET_ALL_MEMBER_FAILURE:
      return {
        error: action.error
      };

    // ADD PROJECT MEMBER
    case projectConstants.ADD_MEMBER_REQUEST:
      return {
        ...state,
        adding: true,
        addMemberLoading: true,
        addMembersSuccess: false,
      };
    case projectConstants.ADD_MEMBER_SUCCESS:
      return {
        ...state,
        adding: false,
        member: action.member,
        added: true,
        membersLoaded: true,
        addMemberLoading: false,
        addMembersSuccess: true,
      };
    case projectConstants.ADD_MEMBER_FAILURE:
      return {
        ...state,
        loaded: true,
        membersLoaded: true,
        addMemberLoading: false,
        addMembersSuccess: false,
      };

    // DELETE MEMBER
    case projectConstants.DELETE_MEMBER_REQUEST:
      return {
        deleting: true
      };
    case projectConstants.DELETE_MEMBER_SUCCESS:
      return {
        ...state,
        deleting: false,
        deleted: true,
        membersLoaded: true
      };
    case projectConstants.DELETE_MEMBER_FAILURE:
      return {
        error: action.error,
        loaded: true
      };

    /*
    * PROJECT LEAD STATUS
    * */

      // GET ALL LEAD STATUS
    case projectConstants.GET_ALL_STATUS_REQUEST:
      return {
        ...state,
        loading: true
      };
    case projectConstants.GET_ALL_STATUS_SUCCESS:
      return {
        ...state,
        loading: false,
        status: action.status,
        statusLoaded: true
      };
    case projectConstants.GET_ALL_STATUS_FAILURE:
      return {
        error: action.error
      };

    // CREATE LEAD STATUS
    case projectConstants.CREATE_STATUS_REQUEST:
      return {
        adding: true
      };
    case projectConstants.CREATE_STATUS_SUCCESS:
      return {
        items: action.status
      };
    case projectConstants.CREATE_STATUS_FAILURE:
      return {};

      // GET LEAD STATUS
    case projectConstants.GET_STATUS_REQUEST:
      return {
        ...state,
        loading: true
      };
    case projectConstants.GET_STATUS_SUCCESS:
      return {
        ...state,
        items: action.status,
        loaded: true
      };
    case projectConstants.GET_STATUS_FAILURE:
      return {
        error: action.error
      };

      // GET LEAD STATUS COUNT
    case projectConstants.GET_STATUS_COUNT_REQUEST:
      return {
        loading: true
      };
    case projectConstants.GET_STATUS_COUNT_SUCCESS:
      return {
        ...state,
        status: action.status_count,
        statusLoaded: true
      };
    case projectConstants.GET_STATUS_COUNT_FAILURE:
      return {
        error: action.error
      };

    // UPDATE LEAD STATUS
    case projectConstants.UPDATE_PROJECT_STATUS_REQUEST:
      return {
        updating: true
      };
    case projectConstants.UPDATE_PROJECT_STATUS_SUCCESS:
      return {
        items: action.status,
        loaded: true
      };
    case projectConstants.UPDATE_PROJECT_STATUS_FAILURE:
      return {};

    // DELETE LEAD STATUS
    case projectConstants.DELETE_STATUS_REQUEST:
      return {
        deleting: true
      };
    case projectConstants.DELETE_STATUS_SUCCESS:
      return {
        loaded: true
      };
    case projectConstants.DELETE_STATUS_FAILURE:
      return {};

      // UPDATE STATUS
    case projectConstants.ENABLE_STATUS_REQUEST:
      return {
        loading: true
      };
    case projectConstants.ENABLE_STATUS_SUCCESS:
      return {
        items: action.status
      };
    case projectConstants.ENABLE_STATUS_FAILURE:
      return {
        error: action.error
      };

      /*
      CREATE DEFAULT PROJECT STATUS
       */
    case projectConstants.CREATE_DEFAULT_STATUS_REQUEST:
      return {
        loading: true
      };
    case projectConstants.CREATE_DEFAULT_STATUS_SUCCESS:
      return {};
    case projectConstants.CREATE_DEFAULT_STATUS_FAILURE:
      return {};

      /*
     CREATE DEFAULT PROJECT STATUS
      */
    case projectConstants.CREATE_CONVERSION_STATUS_REQUEST:
      return {
        loading: true
      };
    case projectConstants.CREATE_CONVERSION_STATUS_SUCCESS:
      return {};
    case projectConstants.CREATE_CONVERSION_STATUS_FAILURE:
      return {};

      /*
      GET PROJECT ACTIVITY LOGS
       */
    case projectConstants.GET_LOG_REQUEST:
      return {
        loading: true
      };
    case projectConstants.GET_LOG_SUCCESS:
      return {
        ...state,
        loading: false,
        activityLog: action.activity_log,
        activityLogLoaded: true
      };
    case projectConstants.GET_LOG_FAILURE:
      return {
        error: action.error
      };

    default:
      return state
  }
  }
