import { deckConstants } from "_constants";

export function deck(state = {}, action) {
  switch (action.type) {
    case deckConstants.GET_ALL_DECK_REQUEST:
      return {
        ...state,
        getdeckLoading: true,
        getdeckLoaded: false,
      };
    case deckConstants.GET_ALL_DECK_SUCCESS:
      return {
        ...state,
        getdeckLoading: false,
        getdeckLoaded: true,
        decks: action.decks,
      };
    case deckConstants.GET_ALL_DECK_FAILURE:
      return {
        ...state,
        getdeckLoading: false,
        getdeckLoaded: true,
        error: action.error,
      };

    case deckConstants.UPDATE_DECK_REQUEST:
      return {
        ...state,
        getdeckLoading: true,
        getdeckLoaded: false,
      };

    case deckConstants.UPDATE_DECK_SUCCESS:
      return {
        ...state,
        getdeckLoading: false,
        getdeckLoaded: true,
        decks: action.decks,
      };
    case deckConstants.UPDATE_DECK_FAILURE:
      return {
        ...state,
        getdeckLoading: false,
        getdeckLoaded: true,
        error: action.error,
      };
    case deckConstants.CREATE_DECK_REQUEST:
      return {
        ...state,
        getdeckLoading: true,
        getdeckLoaded: false,
      };

    case deckConstants.CREATE_DECK_SUCCESS:
      return {
        ...state,
        getdeckLoading: false,
        getdeckLoaded: true,
        decks: action.decks,
      };
    case deckConstants.CREATE_DECK_FAILURE:
      return {
        ...state,
        getdeckLoading: false,
        getdeckLoaded: true,
        error: action.error,
      };
    case deckConstants.DELETE_DECK_REQUEST:
      return {
        ...state,
        getdeckLoading: true,
        getdeckLoaded: false,
      };
    case deckConstants.DELETE_DECK_SUCCESS:
      return {
        ...state,
        getdeckLoading: false,
        getdeckLoaded: true,
        decks: action.decks,
      };
    case deckConstants.DELETE_DECK_FAILURE:
      return {
        ...state,
        getdeckLoading: false,
        getdeckLoaded: true,
        error: action.error,
      };
    default:
      return {
        ...state,
      };
  }
}
