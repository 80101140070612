import React, {Component} from 'react';
import {influencerActions} from "_actions";
import {connect} from "react-redux";
import Button from "_pages/Influencer/components/General/Button";
import {
    abbreviateNumber,
    getInfluencerTypeClassColor,
    getInfluencerTypeClassIcon,
    getInfluencerTypeLabel
} from "_services";
import Moment from "react-moment";

class PlatformTab extends Component {

    componentDidMount() {
        const {getPlatformTypes, projectId, influencerId, getInfluencerPlatform} = this.props;
        getPlatformTypes(projectId);
        getInfluencerPlatform(projectId, influencerId);
    }

    openAddPlatformModal = () => {
        this.props.onHandleManagePlatformModal('add', null);
    }

    openEditPlatformModal = (platform) => {
        this.props.onHandleManagePlatformModal('edit', platform);
    }

    openDeleteConfirmationModal = (type, platform) => {
        this.props.onHandleOpenConfirmationModal(type, platform);
    }

    render() {

        const {
            classN,
            influencers,
            influencerPlatformLoaded,
            influencerPlatformLoading
        } = this.props;

        let platforms = [];
        let hasPlatforms = false;

        if (influencerPlatformLoaded) {
            platforms = influencers.platforms;
            hasPlatforms = platforms.length ? true : false;
        }


        return (
            <div className={classN}>
            
                <React.Fragment>
                    <div className='inner-title-box'>
                        <p>
                            Social Media Followers
                        </p>
                        {influencerPlatformLoaded && hasPlatforms &&
                        <Button
                            text='Add Platform'
                            icon='icon-lms icon-add'
                            onClick={ () => this.openAddPlatformModal()}
                            classN="plain-btn"
                        />
                        }
                    </div>

                    {influencerPlatformLoaded && 
                    <div className='platform-list-box'>
                        {hasPlatforms  && <div className={'platform'}>
                            
                                {platforms.map((platform, index) => {

                                    const type = platform.type.code;
                                    const name = platform.type.name;
                                    const value = abbreviateNumber(platform.value);
                                    const link = platform.link;
                                    const icon = getInfluencerTypeClassIcon(type);
                                    const color = getInfluencerTypeClassColor(type);
                                    const updated = platform.updated;


                                    return(<div className={`platform-col ${icon}`} key={index}>
                                        <div className='platform-info'>
                                            <div className='platform-icon'>
                                                <a href={link} target={'new'}><img src={`/${icon}.svg`} alt="logo" /></a>
                                            </div>
                                            <div className='platform-details'>
                                                <div>
                                                    <p>{name}</p>
                                                    <span>Platform</span>
                                                </div>
                                                <div>
                                                    <p>{value}</p>
                                                    <span>{getInfluencerTypeLabel(type)}</span>
                                                </div>
                                            </div>
                                            <div className='platform-btns'>
                                            <button className='c-btn c-btn-sm c-btn-light edit-record' onClick={() => this.openEditPlatformModal(platform)}><i
                                                className="icon-lms icon-edit"></i>Edit
                                            </button>
                                            <button className='c-btn c-btn-sm c-btn-light delete-record' onClick={() => this.openDeleteConfirmationModal('platform', platform)}><i
                                                className="icon-lms icon-delete"></i>Delete
                                            </button>
                                            </div>
                                        </div>
                                    </div>);
                                })}
                            
                        </div>}
                    </div>}

                    {influencerPlatformLoaded && !hasPlatforms && 
                        <div className='empty-tab-content'>
                            <div className='empty-content'>
                                <h6>Add Your First Platform</h6>
                                <p>
                                    Platforms will appear here
                                </p>
                                <span onClick={ () => this.openAddPlatformModal()}>
                                    <i className='icon-lms icon-add'></i>
                                    Add Platform
                                </span>
                            </div>
                        </div>
                    }

                    {influencerPlatformLoading && 
                        <div className='loading-box'>
                            <div class="lds-ellipsis"><div></div><div></div><div></div><div></div></div>
                        </div>
                    }
                </React.Fragment>
            </div>)
    }


}


function mapState(state) {
    const {influencers} = state;
    const {influencerPlatformLoading, influencerPlatformLoaded} = influencers;
    return {influencers, influencerPlatformLoading, influencerPlatformLoaded}
}

const actionCreators = {
    getInfluencerPlatform: influencerActions.getInfluencerPlatform,
    getPlatformTypes: influencerActions.getPlatformTypes,
};

const connectedViewPlatformTab = connect(mapState, actionCreators)(PlatformTab);
export {connectedViewPlatformTab as PlatformTab};
