import React from 'react';
import { connect } from "react-redux";

import { formActions } from '_actions';

import { MDBModal, MDBModalBody, MDBModalHeader, MDBContainer, MDBRow, MDBCol } from 'mdbreact';
import { Button } from "blackbox-react";

import { FormValidation } from '_components/FormValidation';
import { Loader } from '_components/Loader';

class EditFormModal extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      form: {
        name: this.props.formData.info.name || '',
        description: this.props.formData.info.description || ''
      },
      formErrors: {
        name: ''
      },
      formNameValid: false,
      formValid: false,
      submitted: false,
      modalOpen: false,
      class: 'disabled'
    };

    this.handleChange = this.handleChange.bind(this);
    this.handleSubmit = this.handleSubmit.bind(this);
  }

  componentDidMount = () => {
    this.setState({
      modalOpen: this.props.modalOpen
    });
  };

  validateField(fieldName, value) {
    let fieldValidationErrors = this.state.formErrors;
    let formNameValid = this.state.formNameValid;

    let isAllWhitespace = (value.replace(/(?!^ +$)^.+$/gm, "") !== '');

    switch(fieldName) {
      case 'name':
        if (value === "" || isAllWhitespace) { // check if empty
          formNameValid = false;
          fieldValidationErrors.name = 'Please enter a form name.';
        } else {
          formNameValid = (value.length >= 0); // check if valid
          fieldValidationErrors.name = formNameValid ? '': 'Please enter a form name.';
        }
        break;
      default:
        break;
    }

    this.setState({
      formErrors: fieldValidationErrors,
      formNameValid: formNameValid
    }, this.validateForm);
  }

  validateForm() {
    this.setState({
      formValid: this.state.formNameValid
    });
  }

  handleChange(event) {
    const { name, value } = event.target;
    const { form } = this.state;

    // Add current value of fields to form state
    this.setState({
      form: {
        ...form,
        [name]: value
      },
      class: 'orange'
    }, () => {
      if(name === 'name'){
        this.validateField(name, value);
      }
      if(name === 'description'){
        this.setState({
          formValid: true
        });
      }
    });
  };

  handleSubmit(event) {
    event.preventDefault();

    const { form } = this.state;
    const projectId = this.props.projectId;
    const formId = this.props.formData.form_id;

    this.setState({ submitted: true }, () => {
      if (this.state.formValid) {
        if(this.state.submitted){

          this.props.updateForm(form, formId, projectId);

          let { modalOpen, modalToggle } = this.props;
          if(modalOpen){
            modalToggle();
          }

          this.setState({
            formNameValid: false,
            formValid: false,
            submitted: false,
            formErrors: {
              name: ''
            },
            class: 'disabled'
          });
        }
      }
    });
  };

  errorClass(error) {
    return(error.length === 0 ? 'is-passed' : 'is-failed');
  }

  render() {
    const { creating  } = this.props;
    let { modalOpen, modalToggle } = this.props;
    const { form, submitted, formValid } = this.state;

    return (
      <MDBContainer>
        <form noValidate>
          <MDBModal isOpen={modalOpen} toggle={modalToggle} id='create-form-modal' centered>
            <MDBModalHeader className='padding-left-18 padding-right-18 pb-3 pt-3' toggle={modalToggle}>
              <p className='mb-0 font-size-12 font-weight-600 letter-spacing-1'>UPDATE FORM</p>
            </MDBModalHeader>
            <MDBModalBody className='pt-2 padding-left-18 padding-right-18 padding-bottom-15'>
              <MDBRow>
                <MDBCol className={`form-group m--sm ${submitted && this.errorClass(this.state.formErrors.name)}`}>
                  <label className='mb-1 font-size-12 text-black'>Form Name <span className='color-red'>*</span></label>
                  <input
                    onChange={(e) => {this.handleChange(e)}}
                    name='name'
                    type='text'
                    autoFocus={true}
                    defaultValue={form ? form.name : ''}
                    id='FormName'
                    placeholder='Enter form name here...'
                  />
                  <div className={`form-validation__container ${(!formValid && submitted) ? 'display-block' : 'display-none'}`}>
                    <FormValidation fieldName='name' formErrors={this.state.formErrors}/>
                  </div>
                </MDBCol>
              </MDBRow>
              <MDBRow>
                <MDBCol>
                  <label className='mb-1 pt-2 font-size-12 text-black'>Description</label>
                  <textarea
                    onChange={(e) => {this.handleChange(e)}}
                    name='description'
                    defaultValue={form ? form.description : ''}
                    id='textarea-description'
                    className='form-control font-size-12 color-none'
                    rows='7'
                    placeholder='Enter description here...'
                  />
                </MDBCol>
              </MDBRow>
              <MDBRow className='pt-2 pb-0'>
                <MDBCol className='d-flex'>
                  <span
                    className='mb-0 font-color-orange my-auto text-cursor-pointer border-bottom-orange line-height-15 font-size-12'
                    onClick={modalToggle}
                  >
                    Cancel
									</span>
                </MDBCol>
                <MDBCol
                  className='text-right'
                  id='nextButton'
                >
                  <Button
                    flat='true'
                    type='submit'
                    size={-1}
                    background={this.state.class}
                    disabled={this.state.class === 'disabled' ? 'disabled' : ''}
                    onClick={(e) => {this.handleSubmit(e)}}
                  >UPDATE FORM {creating &&
                  <Loader />
                  }
                  </Button>
                </MDBCol>
              </MDBRow>
            </MDBModalBody>
          </MDBModal>
        </form>
      </MDBContainer>
    );
  }
}

function mapState(state) {
  const { creating, forms } = state;
  return { creating, forms };
}

const actionCreators = {
  updateForm: formActions.updateForm
};

const connectedEditFormModal = connect(mapState, actionCreators)(EditFormModal);
export { connectedEditFormModal as EditFormModal };
