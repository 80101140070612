import React, {useCallback, useEffect, useState} from 'react';
import WordCloud from 'react-d3-cloud';
import {useDispatch, useSelector} from "react-redux";
import { scaleOrdinal } from 'd3-scale';
import { schemeCategory10 } from 'd3-scale-chromatic';


const LeadInterest = () => {

    const dispatch = useDispatch();
    const [isCloudView, setCloudView] = useState(true);
    const [isListView, setListView] = useState(false);
    const [isViewMore, setIsViewMore] = useState(false);
    const schemeCategory10ScaleOrdinal = scaleOrdinal(schemeCategory10);
    const fontTextSize = useCallback((word) =>   ((word.value + totalValue)/totalValue) * Math.sqrt(word.value) * 10 , []);
    const fillColor = useCallback((d,i)=> schemeCategory10ScaleOrdinal(i), []);
    const rotate = useCallback((word)=> 0, []);


    const handleOpenCloudView = () => {
      setCloudView(true);
      setListView(false);
    };

    const handleOpenListView = () => {
      setListView(true);
      setCloudView(false);
    };

    const handleViewMore = () => {
      setIsViewMore(!isViewMore);
    };

    const interestLoaded = useSelector((state) => {
        const {leads} = state;
        return leads.interestLoaded;
    });

    const interests = useSelector((state) => {
        const {leads} = state;

        if(leads.interestLoaded) {
            let list = leads.interest.interest
            return list.sort((a, b) => a.value - b.value).reverse();
        }
        return [];
    });

    const interestsList = useSelector((state) => {
        const {leads} = state;

        if(leads.interestLoaded) {
            let list = leads.interest.interest;
            return list.sort((a, b) => a.value - b.value).reverse();
        }
        return [];
    });

    const totalValue = useSelector((state) => {
        const {leads} = state;

        if(leads.interestLoaded) {
            let list = leads.interest.interest
            return list.reduce((sum, keyword) => sum + keyword.value || 0,0);

        }
        return 0;

    });

    const category = useSelector((state) => {
        const {leads} = state;

        if(leads.interestLoaded) {
            return leads.interest.category;
        }
        return [];

    });


    return (
        <div className='interest-box tab-content-box'>
            { !interestLoaded && <div className="loading-box">
                <div className='loader-box'>
                    <div className="loader-custom">
                        <span></span>
                        <span></span>
                        <span></span>
                        <span></span>
                        <span></span>
                    </div>
                </div>
            </div>
            }
            {
                interestLoaded && interestsList.length < 1 &&
                <div className='empty-tab-content'>
                    <div className='empty-content'>
                        <h6>No Available Interest</h6>
                    </div>
                </div>
            }

            { interestLoaded && interestsList.length > 0 &&
            
                <div className='interest-group'>
                    <div className='interest-list-box tab-content-list-box'>
                        <div className='interest-btn-group'>
                            <p>PAGES VISITED</p>
                            <div className='int-btns'>
                                <button onClick={() => handleOpenCloudView()} className={isCloudView ? 'active' : ''}>
                                    <i className="fas fa-cloud"></i>
                                </button>
                                <button onClick={() => handleOpenListView()} className={isListView ? 'active' : ''}>
                                    <i className="fas fa-list-ul"></i>
                                </button>
                            </div>
                        </div>
                        <div className='word-cloud-group' style={{border: 'none'}}>
                            <div className={isCloudView ? 'int-view active' : 'int-view'}>
                                <WordCloud
                                    rotate={0}
                                    data={interests}
                                    font='Open Sans, sans-serif'
                                    fontWeight="500"
                                    fontSize={fontTextSize}
                                    fill={fillColor}
                                />
                            </div>
                            <div className={isListView ? 'int-view active' : 'int-view'}>
                                <div className='int-list-view'>
                                    <ol>
                                        {
                                            interestsList.map((item, index) => {

                                                let percentage = ((item.value / totalValue) * 100).toFixed(2);
                                                let padding = percentage;
                                                let isSmall = percentage < totalValue * 0.35;
                                                let progressStyle =  {
                                                    width: `${percentage}%`,
                                                    backgroundColor: `${schemeCategory10ScaleOrdinal(index)}`
                                                };
                                                return(
                                                    <li key={index}>
                                                        <div className='int-box'>
                                                            <div className='bx'>
                                                                <span>{item.text}</span>
                                                            </div>
                                                            <div className='bx'>
                                                                <div className='int-bar'>
                                                                    <div className='int-progress' style={progressStyle}></div>
                                                                    {isSmall && <span className={'sm-t'} style={{paddingLeft: `${padding}%`}}>
                                                                        {item.value} ({percentage}%)
                                                                    </span>}

                                                                    {!isSmall && <span>
                                                                        {item.value} ({percentage}%)
                                                                    </span>}
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </li>
                                                );
                                            })
                                        }
                                        {/*<button className='background-btn' type="button" onClick={() => handleViewMore()}>*/}
                                        {/*  {isViewMore ? 'Show Less' : 'Show More'}*/}
                                        {/*</button>*/}
                                    </ol>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            
            }
        </div>
    );

}

export default LeadInterest;
