import { httpManagerService as httpManager } from  '_services';

export const chatService = {
    getLeadChatConversations,
    updateUnreadChats,
    agentMessageReply

}

function getLeadChatConversations(projectId, leadId){
    return httpManager.getHttpWithRequestingToken(`/api/v2/project/${projectId}/lead/${leadId}/chat`);
}

function updateUnreadChats(projectId, leadId, request){
    return httpManager.putJsonOnce(`/api/v2/project/${projectId}/lead/${leadId}/chat/unread`, request);
}

function agentMessageReply(projectId, leadId, request){
    return httpManager.postJsonOnce(`/api/v2/project/${projectId}/lead/${leadId}/chat`, request);
}