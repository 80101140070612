export const leadConstants = {
    GET_ALL_REQUEST: 'LEAD_GET_ALL_REQUEST',
    GET_ALL_SUCCESS: 'LEAD_GET_ALL_SUCCESS',
    GET_ALL_FAILURE: 'LEAD_GET_ALL_FAILURE',

    CREATE_REQUEST: 'LEAD_CREATE_REQUEST',
    CREATE_SUCCESS: 'LEAD_CREATE_SUCCESS',
    CREATE_FAILURE: 'LEAD_CREATE_FAILURE',

    CREATE_LEAD_IMPORT_REQUEST: 'LEAD_CREATE_IMPORT_REQUEST',
    CREATE_LEAD_IMPORT_SUCCESS: 'LEAD_CREATE_IMPORT_SUCCESS',
    CREATE_LEAD_IMPORT_FAILURE: 'LEAD_CREATE_IMPORT_FAILURE',

    GET_REQUEST: 'LEAD_GET_REQUEST',
    GET_SUCCESS: 'LEAD_GET_SUCCESS',
    GET_FAILURE: 'LEAD_GET_FAILURE',

    UPDATE_REQUEST: 'LEAD_UPDATE_REQUEST',
    UPDATE_SUCCESS: 'LEAD_UPDATE_SUCCESS',
    UPDATE_FAILURE: 'LEAD_UPDATE_FAILURE',

    DELETE_REQUEST: 'LEAD_DELETE_REQUEST',
    DELETE_SUCCESS: 'LEAD_DELETE_SUCCESS',
    DELETE_FAILURE: 'LEAD_DELETE_FAILURE',

    CREATE_NOTE_REQUEST: 'LEAD_CREATE_NOTE_REQUEST',
    CREATE_NOTE_SUCCESS: 'LEAD_CREATE_NOTE_SUCCESS',
    CREATE_NOTE_FAILURE: 'LEAD_CREATE_NOTE_FAILURE',

    UPDATE_STATUS_REQUEST: 'LEAD_UPDATE_STATUS_REQUEST',
    UPDATE_STATUS_SUCCESS: 'LEAD_UPDATE_STATUS_SUCCESS',
    UPDATE_STATUS_FAILURE: 'LEAD_UPDATE_STATUS_FAILURE',

    GET_ALL_CONTENT_REQUEST: 'GET_ALL_CONTENT_REQUEST',
    GET_ALL_CONTENT_SUCCESS: 'GET_ALL_CONTENT_SUCCESS',
    GET_ALL_CONTENT_FAILURE: 'GET_ALL_CONTENT_FAILURE',

    CREATE_CONTENT_REQUEST: 'LEAD_CONTENT_CREATE_REQUEST',
    CREATE_CONTENT_SUCCESS: 'LEAD_CONTENT_CREATE_SUCCESS',
    CREATE_CONTENT_FAILURE: 'LEAD_CONTENT_CREATE_FAILURE',

    GET_CONTENT_REQUEST: 'LEAD_CONTENT_GET_REQUEST',
    GET_CONTENT_SUCCESS: 'LEAD_CONTENT_GET_SUCCESS',
    GET_CONTENT_FAILURE: 'LEAD_CONTENT_GET_FAILURE',

    UPDATE_CONTENT_REQUEST: 'LEAD_CONTENT_UPDATE_REQUEST',
    UPDATE_CONTENT_SUCCESS: 'LEAD_CONTENT_UPDATE_SUCCESS',
    UPDATE_CONTENT_FAILURE: 'LEAD_CONTENT_UPDATE_FAILURE',

    DELETE_CONTENT_REQUEST: 'LEAD_CONTENT_DELETE_REQUEST',
    DELETE_CONTENT_SUCCESS: 'LEAD_CONTENT_DELETE_SUCCESS',
    DELETE_CONTENT_FAILURE: 'LEAD_CONTENT_DELETE_FAILURE',

    UPDATE_CONTENT_STATUS_REQUEST: 'LEAD_CONTENT_UPDATE_STATUS_REQUEST',
    UPDATE_CONTENT_STATUS_SUCCESS: 'LEAD_CONTENT_UPDATE_STATUS_SUCCESS',
    UPDATE_CONTENT_STATUS_FAILURE: 'LEAD_CONTENT_UPDATE_STATUS_FAILURE',

    GET_ALL_NOTES_REQUEST: 'GET_ALL_NOTES_REQUEST',
    GET_ALL_NOTES_SUCCESS: 'GET_ALL_NOTES_SUCCESS',
    GET_ALL_NOTES_FAILURE: 'GET_ALL_NOTES_FAILURE',

    UPDATE_NOTE_REQUEST: 'UPDATE_NOTE_REQUEST',
    UPDATE_NOTE_SUCCESS: 'UPDATE_NOTE_SUCCESS',
    UPDATE_NOTE_FAILURE: 'UPDATE_NOTE_FAILURE',

    DELETE_NOTE_REQUEST: 'DELETE_NOTE_REQUEST',
    DELETE_NOTE_SUCCESS: 'DELETE_NOTE_SUCCESS',
    DELETE_NOTE_FAILURE: 'DELETE_NOTE_FAILURE',

    GET_ALL_IMPORT_DATA_REQUEST: 'GET_ALL_LEAD_IMPORT_DATA_REQUEST',
    GET_ALL_IMPORT_DATA_SUCCESS: 'GET_ALL_LEAD_IMPORT_DATA_SUCCESS',
    GET_ALL_IMPORT_DATA_FAILURE: 'GET_ALL_LEAD_IMPORT_DATA_FAILURE',

    GET_IMPORT_DATA_REQUEST: 'GET_LEAD_IMPORT_DATA_REQUEST',
    GET_IMPORT_DATA_SUCCESS: 'GET_LEAD_IMPORT_DATA_SUCCESS',
    GET_IMPORT_DATA_FAILURE: 'GET_LEAD_IMPORT_DATA_FAILURE',

    GET_LOG_REQUEST: 'LEAD_ACTIVITIES_REQUEST',
    GET_LOG_SUCCESS: 'LEAD_ACTIVITIES_SUCCESS',
    GET_LOG_FAILURE: 'LEAD_ACTIVITIES_FAILURE',

    SYNC_PATIENTS_REQUEST: 'SYNC_PATIENTS_REQUEST',
    SYNC_PATIENTS_SUCCESS: 'SYNC_PATIENTS_SUCCESS',
    SYNC_PATIENTS_ERROR: 'SYNC_PATIENTS_ERROR',

    GET_SUMMARY_REQUEST: 'GET_SUMMARY_REQUEST',
    GET_SUMMARY_SUCCESS: 'GET_SUMMARY_SUCCESS',
    GET_SUMMARY_FAILURE: 'GET_SUMMARY_FAILURE',

    GET_LEAD_REPORT_REQUEST: 'GET_LEAD_REPORT_REQUEST',
    GET_LEAD_REPORT_SUCCESS: 'GET_LEAD_REPORT_SUCCESS',
    GET_LEAD_REPORT_FAILURE: 'GET_LEAD_REPORT_FAILURE',
    
    GET_PATIENT_REPORT_REQUEST: 'GET_PATIENT_REPORT_REQUEST',
    GET_PATIENT_REPORT_SUCCESS: 'GET_PATIENT_REPORT_SUCCESS',
    GET_PATIENT_REPORT_FAILURE: 'GET_PATIENT_REPORT_FAILURE',

    GET_ORIGIN_REQUEST: 'GET_ORIGIN_REQUEST',
    GET_ORIGIN_SUCCESS: 'GET_ORIGIN_SUCCESS',
    GET_ORIGIN_FAILURE: 'GET_ORIGIN_FAILURE',

    CREATE_NOTE_REPLY_REQUEST: 'CREATE_NOTE_REPLY_REQUEST',
    CREATE_NOTE_REPLY_SUCCESS: 'CREATE_NOTE_REPLY_SUCCESS',
    CREATE_NOTE_REPLY_FAILURE: 'CREATE_NOTE_REPLY_FAILURE',

    CALENDLY_INVITE_REQUEST: 'CALENDLY_INVITE_REQUEST',
    CALENDLY_INVITE_SUCCESS: 'CALENDLY_INVITE_SUCCESS',
    CALENDLY_INVITE_FAILURE: 'CALENDLY_INVITE_FAILURE',

    GET_ALL_INTEREST_REQUEST: 'GET_ALL_INTEREST_REQUEST',
    GET_AL_INTEREST_SUCCESS: 'GET_AL_INTEREST_SUCCESS',
    GET_ALL_INTEREST_FAILURE: 'GET_ALL_INTEREST_FAILURE',

    GET_ALL_SESSION_REQUEST: 'GET_ALL_SESSION_REQUEST',
    GET_AL_SESSION_SUCCESS: 'GET_AL_SESSION_SUCCESS',
    GET_ALL_SESSION_FAILURE: 'GET_ALL_SESSION_FAILURE',
    
    BULK_UPDATE_REQUEST: 'BULK_UPDATE_REQUEST',
    BULK_UPDATE_SUCCESS: 'BULK_UPDATE_SUCCESS',
    BULK_UPDATE_FAILURE: 'BULK_UPDATE_FAILURE',

    SELECT_LEAD: 'SELECT_LEAD',
    DESELECT_LEAD: 'DESELECT_LEAD',

    BULK_UPLOAD_REQUEST: 'BULK_UPLOAD_REQUEST',
    BULK_UPLOAD_SUCCESS: 'BULK_UPLOAD_SUCCESS',
    BULK_UPLOAD_FAILURE: 'BULK_UPLOAD_FAILURE',

};
