import React from 'react';
import { connect } from "react-redux";
import { formActions } from '_actions';

import { MDBModal, MDBModalBody, MDBModalHeader, MDBIcon, MDBContainer, MDBRow, MDBCol } from 'mdbreact';
import { Button } from "blackbox-react";


class LoadFormModal extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      modalOpen: false,
      selectedForm: 'I want this!',
      selectedFormContainer: '',
      identifierForm: '',
      identifierId: 0,
      submitted: false
    };

    this.handleBtnSelected = this.handleBtnSelected.bind(this);
    this.handleSubmit = this.handleSubmit.bind(this);
  }

  componentDidMount = () => {
    this.setState({
      modalOpen: this.props.modalOpen
    })
  };

  handleBtnSelected = (form) => {
    let identifierForm = this.state.identifierForm;

    switch (form) {
      case 'contact-us': this.setState({
        selectedForm: identifierForm === 'contact-us' ? '' : 'Selected',
        identifierForm: identifierForm === 'contact-us' ? '': 'contact-us',
        identifierId: identifierForm === 'contact-us' ? '0': '1'

      });
        break;
      case 'reservation-form': this.setState({
        selectedForm: identifierForm === 'reservation-form' ? '' : 'Selected',
        identifierForm: identifierForm === 'reservation-form' ? '': 'reservation-form',
        identifierId: identifierForm === 'reservation-form' ? '0': '2'
      });
        break;
      case 'sample-form': this.setState({
        selectedForm: identifierForm === 'sample-form' ? '' : 'Selected',
        identifierForm: identifierForm === 'sample-form' ? '': 'sample-form',
        identifierId: identifierForm === 'sample-form' ? '0': '3'
      });
        break;
      default:
        break;
    }
  };

  //this function is used to render the rectangle shapes
  renderRectangleContainer = () => {
    return (
      <MDBContainer className='load-background-rectangle'>
        <div className='px-2 py-2'>
          <div className='rectangle-1 margin-2-0' />
          <div className='rectangle-2 margin-2-0' />
          <div className='rectangle-3 margin-2-0' />
          <div className='rectangle-4 margin-2-0' />
          <div className='rectangle-5 margin-2-0' />
          <div className='rectangle-5 margin-2-0' />
          <div className='rectangle-6 margin-2-0' />
        </div>
      </MDBContainer>
    )
  };

  handleSubmit(event) {
    event.preventDefault();

    const projectId = this.props.projectId;
    let identifierForm = this.state.identifierForm;
    let identifierId = this.state.identifierId;

    if(identifierForm && identifierId){

      this.props.createTemplate(projectId, identifierId);

      let { modalOpen, modalToggle } = this.props;
      if(modalOpen){
        modalToggle();
      }

      this.setState({
        modalOpen: false,
        selectedForm: 'I want this!',
        selectedFormContainer: '',
        identifierForm: ''
      });
    }

  };

  render() {
    let { modalOpen, modalToggle } = this.props,
      { identifierForm } = this.state,
      contactContainerClass = identifierForm === 'contact-us' && 'border-dark-orange',
      btnContactClass = identifierForm === 'contact-us' ? 'background-dark-orange border-dark-orange padding-0-16' : 'color-black clear-background border-grey padding-0-20',
      reservationContainerClass = identifierForm === 'reservation-form' ? 'border-dark-orange' : 'border-white ',
      btnReservationClass = identifierForm === 'reservation-form' ? 'background-dark-orange border-dark-orange padding-0-16' : 'color-black clear-background border-grey padding-0-20',
      sampleContainerClass = identifierForm === 'sample-form' && 'border-dark-orange',
      btnSampleClass = identifierForm === 'sample-form' ? 'background-dark-orange border-dark-orange padding-0-16' : 'color-black clear-background border-grey padding-0-20';

    return (
      <MDBContainer>
        <form noValidate>
          <MDBModal isOpen={modalOpen} toggle={modalToggle} id='create-form-modal' centered>
            <MDBModalHeader className='padding-left-18 padding-right-18 pb-3 pt-3' toggle={modalToggle}><p className='mb-0 font-size-12 font-bold-black letter-spacing-1'>SELECT FROM OUR AVAILABLE TEMPLATES</p></MDBModalHeader>
            <MDBModalBody className='pt-1 padding-left-18 padding-right-18 padding-bottom-15'>
              <MDBRow>
                <MDBCol>
                  <label className='py-2 mb-1 font-size-13 text-black line-height-15'>Choose from any of our starting templates to get familiar with our awesome form builder</label>
                </MDBCol>
              </MDBRow>
              <MDBRow className='pb-1'>
                <MDBContainer className={`color-light-pink border-light-pink border-radius-7 container mx-3 px-2 py-1 ${contactContainerClass}`}>
                  <MDBCol>
                    <MDBRow>
                      <MDBCol md='4' className='p-2'>
                        {this.renderRectangleContainer()}
                      </MDBCol>
                      <MDBCol md='8' className='pl-5 pr-4 py-2'>
                        <p className='font-size-13 mb-0 font-bold-black'>Contact Us Form</p>
                        <p className='m-0 font-size-12 opacity-80 line-height-15'>Accepts basic information, contact details, and inquiry</p>
                        <Button
                          flat='true'
                          className={`${btnContactClass} py-1 shadow-none text-transform-none m-0 py-1 my-2 border-radius-3 font-size-12`}
                          onClick={() => {
                            this.handleBtnSelected('contact-us')
                          }}>
                          { this.state.identifierForm === 'contact-us' ?
                            <span className='text-white font-weight-600'>
                            <MDBIcon icon='check' className='mr-2' />{this.state.selectedForm}
                          </span> :
                            <span className='opacity-60'>I want this!</span> }
                        </Button>
                      </MDBCol>
                    </MDBRow>
                  </MDBCol>
                </MDBContainer>
              </MDBRow>
              <MDBRow className='py-1'>
                <MDBContainer className={`border-radius-7 container mx-3 px-2 py-1 ${reservationContainerClass}`}>
                  <MDBCol>
                    <MDBRow>
                      <MDBCol md='4' className='p-2'>
                        {this.renderRectangleContainer()}
                      </MDBCol>
                      <MDBCol md='8' className='pl-5 pr-4 py-2'>
                        <p className='font-size-13 mb-0 font-bold-black'>Reservation Form</p>
                        <p className='m-0 font-size-12 opacity-80 line-height-15'>Accepts basic information, contact details, and reservation details</p>
                        <Button
                          flat='true'
                          className={`${btnReservationClass} py-1 shadow-none  text-transform-none m-0 py-1 my-2 border-radius-3 font-size-12`}
                          onClick={() => {
                            this.handleBtnSelected('reservation-form')
                          }}>
                          { this.state.identifierForm === 'reservation-form' ?
                            <span className='text-white font-weight-600'>
                            <MDBIcon icon='check' className='mr-2' />{this.state.selectedForm}
                          </span> :
                            <span className='opacity-60'>I want this!</span> }
                        </Button>
                      </MDBCol>
                    </MDBRow>
                  </MDBCol>
                </MDBContainer>
              </MDBRow>
              <MDBRow className='py-1'>
                <MDBContainer className={`color-light-pink border-light-pink border-radius-7 container mx-3 px-2 py-1 ${sampleContainerClass}`}>
                  <MDBCol>
                    <MDBRow>
                      <MDBCol md='4' className='p-2'>
                        {this.renderRectangleContainer()}
                      </MDBCol>
                      <MDBCol md='8' className='pl-5 pr-4 py-2'>
                        <p className='font-size-13 mb-0 font-bold-black'>Registration Form</p>
                        <p className='m-0 font-size-12 opacity-80 line-height-15'>Accepts basic and personal information, contact details, as well as educational and work background</p>
                        <Button
                          flat='true'
                          className={`${btnSampleClass} py-1 shadow-none  text-transform-none m-0 py-1 my-2 border-radius-3 font-size-12`}
                          onClick={() => {
                            this.handleBtnSelected('sample-form')
                          }}>
                          { this.state.identifierForm === 'sample-form' ?
                            <span className='text-white font-weight-600'>
                            <MDBIcon icon='check' className='mr-2' />{this.state.selectedForm}
                          </span> :
                            <span className='opacity-60'>I want this!</span> }
                        </Button>
                      </MDBCol>
                    </MDBRow>
                  </MDBCol>
                </MDBContainer>
              </MDBRow>
              <MDBRow className='pt-2 pb-0'>
                <MDBCol className='d-flex'>
                <span
                  className='mb-0 color-gray my-auto text-cursor-pointer border-bottom-gray line-height-15 font-size-12'
                  onClick={modalToggle}>
                  Cancel
                </span>
                </MDBCol>
                <MDBCol className='text-right'>
                  <Button
                    flat='true' size={-2}
                    type='submit'
                    background={identifierForm ? 'orange' : 'disabled'}
                    disabled={!identifierForm}
                    onClick={(e) => {this.handleSubmit(e)}}>
                    PROCEED
                  </Button>
                </MDBCol>
              </MDBRow>
            </MDBModalBody>
          </MDBModal>
        </form>
      </MDBContainer>
    )
  }
}

function mapState(state) {
  const { creating, forms } = state;
  return { creating, forms };
}

const actionCreators = {
  createTemplate: formActions.createTemplate
};

const connectedLoadFormModal = connect(mapState, actionCreators)(LoadFormModal);
export { connectedLoadFormModal as LoadFormModal };
