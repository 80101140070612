import { show } from 'blackbox-react';
import React, { useState, useEffect } from 'react';
import logo from '_assets/images/lms-logo.png';

const PushNotification = ({notification, shown, onHandleCloseNotification}) => {

  useEffect(() => {
    if(shown) {
      setTimeout(function () {
        handleCloseNotification()
      }, 5000);
    }
  }, [shown]);

  function handleCloseNotification() {
    onHandleCloseNotification(false);
  }

  return (
    <>
        {
          shown ?
            <div className={`push-notification${window.location.pathname.includes('/login') ? ' d-none' : ''}`}>
                <div className='box1'>
                    <i className="fas fa-bell"></i>
                </div>
                <div className='box2'>
                    <span>{notification.body}</span>
                </div>
                <i className='fas fa-times close-' onClick={() => handleCloseNotification()}></i>
            </div>
            : <></>
        }
    </>
  )
}

export default PushNotification