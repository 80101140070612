import { httpManagerService as httpManager } from  '_services';

export const leadService = {
    getLeads,
    getLeadsPaginated,
    getLeadToPatientConversion,
    getLeadSummary,
    getLeadReport,
    createLead,
    find,
    updateLead,
    deleteLead,
    getLeadNotes,
    getLeadSession,
    getLeadInterest,
    addLeadNote,
    updateLeadNote,
    deleteLeadNote,
    addNoteReply,
    updateNoteReply,
    deleteNoteReply,
    updateLeadStatus,
    getAllContent,
    createContent,
    findContent,
    updateContent,
    deleteContent,
    enableContent,
    importLeads,
    getAllImportData,
    findImportData,
    getActivityLog,
    getPatientReport,
    getOrigin,
    sendCalendlyInvite,
    batchSelectLeads,
    uploadKalibrrLeads
};

function getLeads(project_id){
    return httpManager.getHttpWithRequestingToken(`/api/project/${project_id}/lead`);
}

function getLeadsPaginated(project_id, page = 1, size = 15, query = null, status = [], origin = [], assigned = [], dateFrom = null, dateTo = null, sort = null, direction = null){
    let parts = [];


    if(query != null) {
        parts.push(`query=${query}`);
    }

    if(page != null) {
        parts.push(`page=${page}`);
    }

    if(size != null) {
        parts.push(`size=${size}`);
    }

    if(dateFrom != null) {
        parts.push(`date_from=${dateFrom}`);
    }

    if(dateTo != null) {
        parts.push(`date_to=${dateTo}`);
    }

    if(status) {
        if(status.length > 0) {
            if(status != '') {
                status = Array.isArray(status) ? status : [status];
                parts.push(`status=${status.join(',')}`);
            }
        }
    }

    if(origin){
        if(origin.length > 0) {
            if(origin != '') {
                origin = Array.isArray(origin) ? origin : [origin];
                parts.push(`origin=${origin.join(',')}`);
            }
        }
    }

    if(assigned){
        if(assigned.length > 0) {
            if(assigned != '') {
                assigned = Array.isArray(assigned) ? assigned : [assigned];
                parts.push(`assigned=${assigned.join(',')}`);
            }
        }
    }

    if(sort != null) {
        parts.push(`sort=${sort}`);
    }

    if(direction != null) {
        parts.push(`direction=${direction}`);
    }

    let uri = `/api/v2/project/${project_id}/lead?` + parts.join('&');
    return httpManager.getHttpWithRequestingToken(uri);
}

function getLeadSummary(project_id, dateFrom, dateTo) {

    let parts = [];
    if(dateFrom != null) {
        parts.push(`date_from=${dateFrom}`);
    }

    if(dateTo != null) {
        parts.push(`date_from=${dateTo}`);
    }

    let uri = `/api/v2/project/${project_id}/summary?` + parts.join('&');
    return httpManager.getHttpWithRequestingToken(uri);
}

function getLeadReport(project_id, status, dateFrom, dateTo) {
    let parts = [];

    if(status.length > 0) {
        parts.push(`status=${status.join(',')}`)
    }

    if(dateFrom != null) {
        parts.push(`date_from=${dateFrom}`);
    }

    if(dateTo != null) {
        parts.push(`date_from=${dateTo}`);
    }

    let uri = `/api/v2/project/${project_id}/report?` + parts.join('&');
    return httpManager.fetchHttpOnce(uri);
}

function getPatientReport() {
    let uri = `/custom/tham/leads-to-patient/data/download`;
    return httpManager.fetchHttpOnce(uri);
}

function getLeadToPatientConversion() {
    let uri = `/custom/tham/leads-to-patient/data/generate/background`;
    return httpManager.getHttpWithRequestingToken(uri);
}

function createLead(project_id, lead){ //create lead from leads
    return httpManager.postJsonOnce(`/api/v2/project/${project_id}/lead`, lead);
}

function find(project_id, lead_id){
    return httpManager.getHttpWithRequestingToken(`/api/project/${project_id}/lead/${lead_id}`);
}

function updateLead(project_id, lead_id, lead){
    return httpManager.putJsonOnce(`/api/v2/project/${project_id}/lead/${lead_id}`, lead);
}

function deleteLead(project_id, lead_id, lead){
    return httpManager.deleteJsonWithRequestingToken(`/api/project/${project_id}/lead/${lead_id}`, lead);
}

function getLeadNotes(project_id, lead_id, type_name){
    return httpManager.getHttpWithRequestingToken(`/api/project/${project_id}/lead/${lead_id}/content/${type_name}`);
}

function getLeadSession(project_id, lead_id){
    return httpManager.getHttpWithRequestingToken(`/api/v2/project/${project_id}/lead/${lead_id}/user-activity`);
}

function getLeadInterest(project_id, lead_id){
    return httpManager.getHttpWithRequestingToken(`/api/v2/project/${project_id}/lead/${lead_id}/interest`);
}

function addLeadNote(project_id, lead_id, note_type, note_data){
    return httpManager.postJsonOnce(`/api/project/${project_id}/lead/${lead_id}/content/${note_type}`, note_data);
}

function updateLeadNote(project_id, lead_id, type_name, content_id, note_data){
    return httpManager.putJsonOnce(`/api/project/${project_id}/lead/${lead_id}/content/${type_name}/${content_id}`, note_data);
}

function deleteLeadNote(project_id, lead_id, type_name, content_id, note){
    return httpManager.deleteJsonWithRequestingToken(`/api/project/${project_id}/lead/${lead_id}/content/${type_name}/${content_id}`, note);
}

function addNoteReply(project_id, lead_id, content_id,  content){
    return httpManager.postJsonOnce(`/api/v2/project/${project_id}/lead/${lead_id}/content/${content_id}/reply`,  content);
}

function updateNoteReply(project_id, lead_id, content_id, reply_id,content){
    return httpManager.putJsonOnce(`/api/v2/project/${project_id}/lead/${lead_id}/content/${content_id}/reply/${reply_id}`,  content);
}

function deleteNoteReply(project_id, lead_id, content_id, reply_id){
    return httpManager.deleteHTTPOnce(`/api/v2/project/${project_id}/lead/${lead_id}/content/${content_id}/reply/${reply_id}`);
}

function updateLeadStatus(project_id, lead_id, status){
    return httpManager.putJsonOnce(`/api/project/${project_id}/lead/${lead_id}/status/${status}`);
}

function getAllContent(project_id, lead_id){
    return httpManager.getHttpWithRequestingToken(`/api/project/${project_id}/lead/${lead_id}/content`);
}

function createContent(project_id, lead_id, type, content){ //type is either task / note
    return httpManager.postJsonOnce(`/api/project/${project_id}/lead/${lead_id}/content/${type}`, content);
}

function findContent(project_id, lead_id, type, type_id){
    return httpManager.getHttpWithRequestingToken(`/api/project/${project_id}/lead/${lead_id}/content/${type}/${type_id}`);
}

function updateContent(project_id, lead_id, type, type_id, content){
    return httpManager.putJsonOnce(`/api/project/${project_id}/lead/${lead_id}/content/${type}/${type_id}`, content);
}

function deleteContent(project_id, lead_id, type, type_id, content){
    return httpManager.deleteJsonWithRequestingToken(`/api/project/${project_id}/lead/${lead_id}/content/${type}/${type_id}`, content);
}

function enableContent(project_id, lead_id, type, type_id, status){
    return httpManager.putJsonOnce(`/api/project/${project_id}/lead/${lead_id}/content/${type}/${type_id}/status/${status}`);
}

function importLeads(project_id, form_id, leads){
    return httpManager.postJsonOnce(`/api/project/${project_id}/form/${form_id}/lead/import`, leads)
}

function getAllImportData(project_id){
    return httpManager.getHttpWithRequestingToken(`/api/project/${project_id}/lead/import`);
}

function findImportData(project_id, import_id){
    return httpManager.getHttpWithRequestingToken(`/api/project/${project_id}/lead/import/${import_id}`);
}

function getActivityLog(project_id, lead_id) {
    return httpManager.getHttpWithRequestingToken(`/api/project/${project_id}/lead/${lead_id}/activities`)
}

function getOrigin(project_id) {
    let uri = `/api/v2/project/${project_id}/origins`;
    return httpManager.getHttpWithRequestingToken(uri);
}

function sendCalendlyInvite(projectId, leadId, calendlyRequest) {
    let uri = `/api/project/${projectId}/lead/${leadId}/calendly/schedule/invite`;
    return httpManager.postJsonOnce(uri, calendlyRequest);
}

function batchSelectLeads(projectId, batchRequest) {
    let uri = `/api/v2/project/${projectId}/lead`;
    return httpManager.putJsonOnce(uri, batchRequest);
}

function uploadKalibrrLeads(project_id, formdata){
    return httpManager.postFormDataOnce(`/api/v2/project/${project_id}/leads/kalibrr`, formdata);
}