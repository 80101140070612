import { templateActions } from '_actions';
import React, {useState, useRef, useEffect} from 'react';
import TableMainLoader from '../../../../_components/Loader/TableMainLoader';
import { useDispatch, useSelector } from 'react-redux';
import { useParams } from 'react-router-dom';
import { utilitiesService } from '_services';
import DOMPurify from "dompurify";
import TablePagination from './TablePagination';

const SharedEmailTemplateTable = ({
  openViewTemplateDrawer,
  toggleDuplicateModal,
}) => {

  const [sharedTemplates, setSharedTemplates] = useState([]);
  const [sharedLoading, setSharedLoading] = useState(false);
  const [isTemplateEmpty, setIsTemplateEmpty] = useState(true);
  const [isFirstPage, setIsFirstPage] = useState(false);
  const [isLastPage, setIsLastPage] = useState(false);
  const [pageNumber, setPageNumber] = useState(0);
  const [size, setSize] = useState(0);
  const [totalElements, setTotalElements] = useState(0);
  const [totalPages, setTotalPages] = useState(0);
  const [isEmpty, setIsEmpty] = useState(true);

  const [isAlert, setIsAlert] = useState(true);


  const options = {
    year: "numeric",
    month: "numeric",
    day: "numeric",
    hour: "numeric",
    minute: "numeric",
  };

  const template = useSelector((state) => state.template);
  
  const dispatch = useDispatch();
  const params = useParams();


  useEffect(() => {
    // dispatch(templateActions.getSharedTemplates(params.id));
    dispatch(templateActions.getSharedTemplatePaginated(params.id));
  }, []);


  useEffect(() => {

    setSharedLoading(template.getSharedTemplateLoaded);
    
    if (template.getTemplateLoaded && template.shared) { 
      setIsTemplateEmpty(true);
      if (!template.shared.empty) {
        setIsTemplateEmpty(false);
      }

      setIsFirstPage(template.shared.first);
      setIsLastPage(template.shared.last);
      setPageNumber(template.shared.number);
      setSize(template.shared.size);
      setTotalElements(template.shared.totalElements);
      setTotalPages(template.shared.totalPages);
      setIsEmpty(template.shared.empty);

      const sortedTemplates = template.shared.content.sort(
        (a, b) => new Date(b.updated) - new Date(a.updated)
      );
      setSharedTemplates(sortedTemplates);
    }
  }, [template.getSharedTemplateLoaded]);

  const sanitizeAndParseHTML = (htmlString) => {
    const sanitizedHTML = DOMPurify.sanitize(htmlString);
    const doc = new DOMParser().parseFromString(sanitizedHTML, "text/html");
    return doc.body.textContent || "";
  };

  const handleSizeChangePagination = (value) => {
    dispatch(
      templateActions.getSharedTemplatePaginated(
        params.id,
        null,
        pageNumber,
        value
      )
    );
  }

  const handlePageChangePagination = (value) => {
    dispatch(
      templateActions.getSharedTemplatePaginated(
        params.id,
        null,
        value,
        size
      )
    );
    
  }

  const handleCloseAlert = () => {
    setIsAlert(false);
  };

  return (
    <div className='main-table-section manage-email-table-section'>
      <div className='table-view table-email-template'>
        <div className='table-scroll'>

        {sharedLoading && (
            <>
              {isTemplateEmpty && (
                <div className="table-empty-box">
                  <div>
                    <h3>No shared templates yet</h3>
                    {/* <p>
                      Start creating email templates by clicking the button
                      below
                    </p>
                    <span onClick={() => openCreateTemplateDrawer(undefined)}>
                      <i className="icon-lms icon-add"></i>
                      Create Email Template
                    </span> */}
                  </div>
                </div>
              )}
              {!isTemplateEmpty && (
                <table>
                  <thead>
                    <tr>
                      <th width="25%">
                        <div className="th-w-sort">
                          {/* <div className="checkbox-group">
                            <input
                              type="text"
                              name="own-table-checkbox-heading"
                              id="own-table-checkbox-heading"
                            />
                            <label htmlFor="own-table-checkbox-heading"></label>
                          </div> */}
                          <span>Template Title</span>
                        </div>
                      </th>
                      <th width="15%">
                        <span>Owner</span>
                      </th>
                      <th width="15%">
                        <span>Category</span>
                      </th>
                      <th width="45%">
                        <span>Content</span>
                      </th>
                    </tr>
                  </thead>
                  <tbody>
                    {sharedTemplates.map((template) => (
                      <tr key={template.id}>
                        <td>
                          <div className="name-td">
                            {/* <div className="checkbox-group" >
                              <input
                                type="checkbox"
                                name={`own-table-checkbox-${template.id}`}
                                id={`own-table-checkbox-${template.id}`}
                              />
                              <label
                                htmlFor={`own-table-checkbox-${template.id}`}
                              ></label>
                            </div> */}
                            <div className="name-info">
                              <div className="main-name">
                                <span className="name">
                                  {utilitiesService.decrypt(template.title)}
                                </span>
                              </div>
                              <div className="others-info">
                                <span className="time-text">
                                  Last Updated:{" "}
                                  {new Date(template.updated).toLocaleString()}
                                </span>
                              </div>
                            </div>
                          </div>
                        </td>
                        <td>
                          <span>{utilitiesService.decrypt(template.first_name) + ' ' + utilitiesService.decrypt(template.last_name)}</span>
                        </td>
                        <td>
                          <span>{template.category ? utilitiesService.decrypt(template.category) : "N/A"}</span>
                        </td>
                        <td>
                          <span
                            className="text-ellipsis"
                            dangerouslySetInnerHTML={{
                              __html: sanitizeAndParseHTML(
                                utilitiesService.decrypt(template.template)
                              ),
                            }}
                          />
                        </td>
                        <td className="et-controls">
                        <span onClick={() => openViewTemplateDrawer(template)}>
                            <i className="icon-lms icon-visibility-on"></i>
                          </span>
                          <span onClick={() => toggleDuplicateModal(template)}>
                            <i className="icon-lms icon-copy"></i>
                          </span>
                        </td>
                      </tr>
                    ))}
                  </tbody>
                </table>
              )}
            </>
          )}
          {!sharedLoading && (
            <TableMainLoader />
          )}


          {/* <div
            className={
              isAlert
                ? "alert alert-success alert-dismissible"
                : "alert alert-success alert-dismissible fade"
            }
          >
            Error Message for duplicate, share, update
            <button type="button" className="close" aria-label="Close" onClick={handleCloseAlert}>
              <span aria-hidden="true">&times;</span>
            </button>
          </div> */}

        </div>
      </div>
      <TablePagination
      templateLoaded={sharedLoading}
      isEmpty={isEmpty}
      isFirstPage={isFirstPage}
      isLastPage={isLastPage}
      size={size}
      pageNumber={pageNumber}
      totalElements={totalElements}
      totalPages={totalPages}
      onHandleSizeChange={handleSizeChangePagination}
      onHandlePageChange={handlePageChangePagination}
      ></TablePagination>
    </div>
  )
}

export default SharedEmailTemplateTable