import { httpManagerService as httpManager } from  '_services';
export const influencerService = {
    getInfluencerSummary,
    getInfluencerList,
    createInfluencer,
    getInfluencer,
    updateInfluencer,
    deleteInfluencer,
    updateInfluencerStatus,
    addInfluencerPlatform,
    getInfluencerPlatform,
    updateInfluencerPlatform,
    deleteInfluencerPlatform,
    updateInfluencerClients,
    getInfluencerClients,
    updateInfluencerCategories,
    getInfluencerCategories,
    addInfluencerDocument,
    getInfluencerDocument,
    updateInfluencerDocument,
    deleteInfluencerDocument,
    addInfluencerRate,
    getInfluencerRates,
    updateInfluencerRate,
    deleteInfluencerRate,
    addInfluencerNote,
    getInfluencerNotes,
    updateInfluencerNote,
    deleteInfluencerNote,
    getClients,
    getPlatformTypes,
    getCategories,
    getInfluencerActivities,
    updateInfluencerResponsible
}

function getInfluencerSummary(projectId){
    let uri = `/api/project/${projectId}/influencer/summary`;
    return httpManager.getHttpOnce(uri);
}

function getInfluencerList(projectId, page = null, size = null, query= null, status = [], platform = [], category = [], sort = null, direction = null, dateFrom = null, dateTo = null){

    let parts = [];

    if(query != null) {
        parts.push(`query=${query}`);
    }

    if(page != null) {
        parts.push(`page=${page}`);
    }

    if(size != null) {
        parts.push(`size=${size}`);
    }

    if(status.length > 0) {
        parts.push(`status=${status.join(',')}`);
    }

    if(platform.length > 0) {
        parts.push(`platform=${platform.join(',')}`);
    }

    if(category.length > 0) {
        parts.push(`category=${category.join(',')}`);
    }

    if(sort != null) {
        parts.push(`sort=${sort}`);
    }

    if(direction != null) {
        parts.push(`direction=${direction}`);
    }

    if(dateFrom != null) {
        parts.push(`dateFrom=${dateFrom}`);
    }

    if(dateTo != null) {
        parts.push(`dateTo=${dateTo}`);
    }

    let uri = `/api/project/${projectId}/influencer?` + parts.join('&');

    return httpManager.getHttpOnce(uri);
}

function createInfluencer(projectId, influencer){ //create lead from leads
    return httpManager.postJsonOnce(`/api/project/${projectId}/influencer`, influencer);
}

function getInfluencer(projectId, influencerId,){
    return httpManager.getHttpOnce(`/api/project/${projectId}/influencer/${influencerId}`);
}

function updateInfluencer(projectId, influencerId, influencer){ //create lead from leads
    return httpManager.putJsonOnce(`/api/project/${projectId}/influencer/${influencerId}`, influencer);
}

function updateInfluencerStatus(projectId, influencerId, status) {
    return httpManager.putJsonOnce(`/api/project/${projectId}/influencer/${influencerId}/status/${status}`);
}

function deleteInfluencer(projectId, influencerId) {
    return httpManager.deleteHTTPOnce(`/api/project/${projectId}/influencer/${influencerId}`);
}

function addInfluencerPlatform(projectId, influencerId, platform) {
    return httpManager.postJsonOnce(`/api/project/${projectId}/influencer/${influencerId}/platform`, platform);
}

function getInfluencerPlatform(projectId, influencerId) {
    return httpManager.getHttpOnce(`/api/project/${projectId}/influencer/${influencerId}/platform`);
}

function updateInfluencerPlatform(projectId, influencerId, platformId, platform) {
    return httpManager.putJsonOnce(`/api/project/${projectId}/influencer/${influencerId}/platform/${platformId}`, platform);
}

function deleteInfluencerPlatform(projectId, influencerId, platformId) {
    return httpManager.deleteHTTPOnce(`/api/project/${projectId}/influencer/${influencerId}/platform/${platformId}`);
}

function updateInfluencerClients(projectId, influencerId, clients) {
    let parts = [];
    if(clients.length > 0) {
        parts.push(`ids=${clients.join(',')}`)
    }
    return httpManager.putHttpOnce(`/api/project/${projectId}/influencer/${influencerId}/client?${parts.join('&')}`);
}

function getInfluencerClients(projectId, influencerId) {
    return httpManager.getHttpOnce(`/api/project/${projectId}/influencer/${influencerId}/client`);
}

function updateInfluencerCategories(projectId, influencerId, categories) {
    let parts = [];
    if(categories.length > 0) {
        parts.push(`ids=${categories.join(',')}`)
    }
    return httpManager.putHttpOnce(`/api/project/${projectId}/influencer/${influencerId}/category?${parts.join('&')}`);
}

function getInfluencerCategories(projectId, influencerId) {
    return httpManager.getHttpOnce(`/api/project/${projectId}/influencer/${influencerId}/category`);
}

function addInfluencerDocument(projectId, influencerId, document) {
    return httpManager.postJsonOnce(`/api/project/${projectId}/influencer/${influencerId}/document`, document);
}

function getInfluencerDocument(projectId, influencerId) {
    return httpManager.getHttpOnce(`/api/project/${projectId}/influencer/${influencerId}/document`);
}

function updateInfluencerDocument(projectId, influencerId, documentId, document) {
    return httpManager.putJsonOnce(`/api/project/${projectId}/influencer/${influencerId}/document/${documentId}`, document);
}

function deleteInfluencerDocument(projectId, influencerId, documentId) {
    return httpManager.deleteHTTPOnce(`/api/project/${projectId}/influencer/${influencerId}/document/${documentId}`);
}

function addInfluencerRate(projectId, influencerId, rate) {
    return httpManager.postJsonOnce(`/api/project/${projectId}/influencer/${influencerId}/rate`, rate);
}

function getInfluencerRates(projectId, influencerId) {
    return httpManager.getHttpOnce(`/api/project/${projectId}/influencer/${influencerId}/rate`);
}

function updateInfluencerRate(projectId, influencerId, rateId, rate) {
    return httpManager.putJsonOnce(`/api/project/${projectId}/influencer/${influencerId}/rate/${rateId}`, rate);
}

function deleteInfluencerRate(projectId, influencerId, rateId) {
    return httpManager.deleteHTTPOnce(`/api/project/${projectId}/influencer/${influencerId}/rate/${rateId}`);
}

function addInfluencerNote(projectId, influencerId, note) {
    return httpManager.postJsonOnce(`/api/project/${projectId}/influencer/${influencerId}/note`, note);
}

function getInfluencerNotes(projectId, influencerId) {
    return httpManager.getHttpOnce(`/api/project/${projectId}/influencer/${influencerId}/note`);
}

function updateInfluencerNote(projectId, influencerId, noteId, note) {
    return httpManager.putJsonOnce(`/api/project/${projectId}/influencer/${influencerId}/note/${noteId}`, note);
}

function deleteInfluencerNote(projectId, influencerId, noteId) {
    return httpManager.deleteHTTPOnce(`/api/project/${projectId}/influencer/${influencerId}/note/${noteId}`);
}

function getInfluencerActivities(project_id, influencerId) {
    return httpManager.getHttpOnce(`/api/project/${project_id}/influencer/${influencerId}/activities`);
}

function getClients(projectId) {
    return httpManager.getHttpOnce(`/api/project/${projectId}/influencer/list/clients`);
}

function getPlatformTypes(projectId) {
    return httpManager.getHttpOnce(`/api/project/${projectId}/influencer/list/platforms`);
}

function getCategories(projectId) {
    return httpManager.getHttpOnce(`/api/project/${projectId}/influencer/list/categories`);
}

function updateInfluencerResponsible(projectId, influencerId, influencer){ 
    return httpManager.putJsonOnce(`/api/project/${projectId}/influencer/${influencerId}/responsible`, influencer);
}









