export const fileConstants = {

    GET_ALL_FILE_REQUEST: 'GET_ALL_FILE_REQUEST',
    GET_ALL_FILE_SUCCESS: 'GET_ALL_FILE_SUCCESS',
    GET_ALL_FILE_FAILURE: 'GET_ALL_FILE_FAILURE',

    UPDATE_FILE_REQUEST: 'UPDATE_FILE_REQUEST',
    UPDATE_FILE_SUCCESS: 'UPDATE_FILE_SUCCESS',
    UPDATE_FILE_FAILURE: 'UPDATE_FILE_FAILURE',

    ADD_FILE_REQUEST: 'ADD_FILE_REQUEST',
    ADD_FILE_SUCCESS: 'ADD_FILE_SUCCESS',
    ADD_FILE_FAILURE: 'ADD_FILE_FAILURE',

    DELETE_FILE_REQUEST: 'DELETE_FILE_REQUEST',
    DELETE_FILE_SUCCESS: 'DELETE_FILE_SUCCESS',
    DELETE_FILE_FAILURE: 'DELETE_FILE_FAILURE',

}