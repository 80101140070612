import React from 'react'
import { InputSelect, Button, ButtonGroup, Icon } from "blackbox-react";

const SidebarForm = () => {

  const selectOptionsCategory = [
    { value: 'Facebook', label: 'Facebook' },
    { value: 'Tiktok Microinfluencers', label: 'Tiktok Microinfluencers' },
    { value: 'TikTok Creators', label: 'TikTok Creators' },
    { value: 'Dancers', label: 'Dancers' }
  ];

  const selectOptionsStatus = [
    { value: 'Done', label: 'Done' },
    { value: 'Pending', label: 'Pending' },
    { value: 'No Reply', label: 'No Reply' }
  ];

  const selectOptionsHandleBy = [
    { value: 'Marketing 1', label: 'Marketing 1' },
    { value: 'Marketing 2', label: 'Marketing 2' },
    { value: 'Marketing 3', label: 'Marketing 3' }
  ];

  return (
    <>
        <div className='form-group'>
            <InputSelect 
              label='Category'
              name='category'
              options={selectOptionsCategory}
            />
        </div>
        <div className='form-group'>
            <InputSelect 
              label='Status'
              name='status'
              options={selectOptionsStatus}
            />
        </div>
    </>
  )
}

export default SidebarForm