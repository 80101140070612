import { templateActions } from "_actions";
import React, { useState, useRef, useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useParams } from "react-router-dom";
import TableMainLoader from "../../../../_components/Loader/TableMainLoader";
import { utilitiesService } from "_services";
import DOMPurify from "dompurify";
import TablePagination from "./TablePagination";

const OwnEmailTemplateTable = ({
  openCreateTemplateDrawer,
  toggleDuplicateModal,
  toggleShareModal,
  toggleDeleteModal,
  handleSelectTemplates
}) => {
  const [toggleTabState, setToggleTabState] = useState(1);
  const [editorContent, setEditorContent] = useState("");
  const [emailTitle, setEmailTitle] = useState("");
  const [emailTemplates, setEmailTemplates] = useState([]);
  const [id, setId] = useState(0);

  const [isCreateEmailTemplate, setIsCreateEmailTemplate] = useState(false);
  const [isUpdateEmailTemplate, setIsUpdateEmailTemplate] = useState(false);
  const [isEmailTemplateDelete, setIsEmailTemplateDelete] = useState(false);
  const [isEmailTemplateSearch, setIsEmailTemplateSearch] = useState(false);
  const [templateLoading, setTemplateLoading] = useState(false);
  const [searchQuery, setSearchQuery] = useState("");
  const [selectedTemplates, setSelectedTemplates] = useState([]);
  const [error, setError] = useState(null);

  const [batchDelete, setBatchDelete] = useState(false);
  const [isTemplateEmpty, setIsTemplateEmpty] = useState(true);

  const [sharedTemplates, setSharedTemplates] = useState([]);
  const [sharedLoading, setSharedLoading] = useState(false);
  const [isFirstPage, setIsFirstPage] = useState(false);
  const [isLastPage, setIsLastPage] = useState(false);
  const [pageNumber, setPageNumber] = useState(0);
  const [size, setSize] = useState(0);
  const [totalElements, setTotalElements] = useState(0);
  const [totalPages, setTotalPages] = useState(0);
  const [isEmpty, setIsEmpty] = useState(true);
  const [isAlert, setIsAlert] = useState(true);

  const options = {
    year: "numeric",
    month: "numeric",
    day: "numeric",
    hour: "numeric",
    minute: "numeric",
  };

  const emailTemplateSearchRef = useRef();
  const emailTemplateSearchInputRef = useRef();

  const template = useSelector((state) => state.template);

  const dispatch = useDispatch();
  const params = useParams();

  useEffect(() => {
   // console.log("call own templates");
    dispatch(templateActions.getTemplates(params.id));
  }, []);

  useEffect(() => {
    setTemplateLoading(template.getTemplateLoaded);

    if (template.getTemplateLoaded && template.data) {
      setIsTemplateEmpty(true);
      if (!template.data.empty) {
        setIsTemplateEmpty(false);
      }

      setIsFirstPage(template.data.first);
      setIsLastPage(template.data.last);
      setPageNumber(template.data.number);
      setSize(template.data.size);
      setTotalElements(template.data.totalElements);
      setTotalPages(template.data.totalPages);
      setIsEmpty(template.data.empty);
      setSelectedTemplates([]);

      const sortedTemplates = template.data.content.sort(
        (a, b) => new Date(b.updated) - new Date(a.updated)
      );
      setEmailTemplates(sortedTemplates);
    }
  }, [template.getTemplateLoaded]);

  const sanitizeAndParseHTML = (htmlString) => {
    const sanitizedHTML = DOMPurify.sanitize(htmlString);
    const doc = new DOMParser().parseFromString(sanitizedHTML, "text/html");
    return doc.body.textContent || "";
  };

  const handleDeleteSelectedTemplates = () => {
    const requests = createBatchDeleteRequests();

    const batchDelete = { templates: requests };

    dispatch(templateActions.removeTemplates(5, batchDelete));

    // Clear selected templates
    setSelectedTemplates([]);
    setIsEmailTemplateDelete(false);
    setBatchDelete(false);
  };

  const createBatchDeleteRequests = () => {
    return selectedTemplates.map((templateId) => {
      const template = emailTemplates.find((t) => t.id === templateId);
      return {
        id: templateId,
        title: utilitiesService.encrypt(template.title),
        template: utilitiesService.encrypt(template.template),
      };
    });
  };

  const handleSelectAll = () => {
    if (selectedTemplates.length === emailTemplates.length) {
      setSelectedTemplates([]);
    } else {
      setSelectedTemplates([...emailTemplates]);
    }
  };

  const handleSelectRow = (templateId) => {
    setSelectedTemplates((prevSelectedTemplates) => {
      if (
        prevSelectedTemplates.some((template) => template.id === templateId)
      ) {
        return prevSelectedTemplates.filter(
          (template) => template.id !== templateId
        );
      } else {
        const selectedTemplate = emailTemplates.find(
          (template) => template.id === templateId
        );
        return [...prevSelectedTemplates, selectedTemplate];
      }
    });
  };

  // Use useEffect to listen for changes in selectedTemplates
  useEffect(() => {
    handleSelectTemplates(selectedTemplates);
  }, [selectedTemplates]);


  const handleSizeChangePagination = (value) => {
    dispatch(
      templateActions.getTemplates(
        params.id,
        null,
        pageNumber,
        value
      )
    );
  }

  const handlePageChangePagination = (value) => {
    dispatch(
      templateActions.getTemplates(
        params.id,
        null,
        value,
        size
      )
    );
    
  }

  const handleCloseAlert = () => {
    setIsAlert(false);
  };

  return (
    <div className="main-table-section manage-email-table-section">
      <div className="table-view table-email-template">
        <div className="table-scroll">
          {templateLoading && (
            <>
              {isTemplateEmpty && (
                <div className="table-empty-box">
                  <div>
                    <h3>You don't have email templates yet</h3>
                    <p>
                      Start creating email templates by clicking the button
                      below
                    </p>
                    <span onClick={() => openCreateTemplateDrawer(undefined)}>
                      <i className="icon-lms icon-add"></i>
                      Create Email Template
                    </span>
                  </div>
                </div>
              )}
              {!isTemplateEmpty && (
                <table>
                  <thead>
                    <tr>
                      <th width="25%">
                        <div className="th-w-sort">
                          <div className="checkbox-group">
                            <input
                              type="checkbox"
                              name="own-table-checkbox-heading"
                              id="own-table-checkbox-heading"
                              checked={
                                selectedTemplates.length ===
                                emailTemplates.length
                              }
                              onChange={handleSelectAll}
                            />
                            <label htmlFor="own-table-checkbox-heading"></label>
                          </div>
                          <span>Template Title</span>
                        </div>
                      </th>
                      <th width="15%">
                        <span>Category</span>
                      </th>
                      <th width="45%">
                        <span>Content</span>
                      </th>
                    </tr>
                  </thead>
                  <tbody>
                    {emailTemplates.map((template) => (
                      <tr key={template.id} className={template.private ?  '' : 'shared'}>
                        <td>
                          <div className="name-td">
                            <div className="checkbox-group">
                              <input
                                type="checkbox"
                                name={`own-table-checkbox-${template.id}`}
                                id={`own-table-checkbox-${template.id}`}
                                checked={selectedTemplates.some(
                                  (selectedTemplate) =>
                                    selectedTemplate.id === template.id
                                )}
                                onChange={() => handleSelectRow(template.id)}
                              />
                              <label
                                htmlFor={`own-table-checkbox-${template.id}`}
                              ></label>
                            </div>
                            <div className="name-info">
                              <div className="main-name">
                                <span className="name">
                                  {utilitiesService.decrypt(template.title)}
                                </span>
                              </div>
                              <div className="others-info">
                                <span className="time-text">
                                  Last Updated:{" "}
                                  {new Date(template.updated).toLocaleString()}
                                </span>
                              </div>
                            </div>
                          </div>
                        </td>
                        <td>
                          <span>
                            {template.category
                              ? utilitiesService.decrypt(template.category)
                              : "N/A"}
                          </span>
                        </td>
                        <td>
                          <span
                            className="text-ellipsis"
                            dangerouslySetInnerHTML={{
                              __html: sanitizeAndParseHTML(
                                utilitiesService.decrypt(template.template)
                              ),
                            }}
                          />
                        </td>
                        <td className="et-controls">
                          <span onClick={() => toggleDuplicateModal(template)}>
                            <i className="icon-lms icon-copy"></i>
                          </span>
                          <span className={template.private ? "share" : "share off"} onClick={() => toggleShareModal(template)}>
                            {
                              template.private ?  <i className="icon-lms icon-share"></i> : <i className="icon-lms icon-share-off"></i>
                            }
                          </span>
                          <span
                            onClick={() => openCreateTemplateDrawer(template)}
                          >
                            <i className="icon-lms icon-edit"></i>
                          </span>
                          {selectedTemplates.length == 0 && (
                            <span onClick={() => toggleDeleteModal(template)}>
                              <i className="icon-lms icon-delete"></i>
                            </span>
                          )}
                        </td>
                      </tr>
                    ))}
                  </tbody>
                </table>
              )}
            </>
          )}
          {!templateLoading && <TableMainLoader />}

          {/* <div
            className={
              isAlert
                ? "alert alert-success alert-dismissible"
                : "alert alert-success alert-dismissible fade"
            }
          >
            Error Message for duplicate, share, update
            <button type="button" className="close" aria-label="Close" onClick={handleCloseAlert}>
              <span aria-hidden="true">&times;</span>
            </button>
          </div> */}
          
        </div>
      </div>
      <TablePagination
        templateLoaded={templateLoading}
        isEmpty={isEmpty}
        isFirstPage={isFirstPage}
        isLastPage={isLastPage}
        size={size}
        pageNumber={pageNumber}
        totalElements={totalElements}
        totalPages={totalPages}
        onHandleSizeChange={handleSizeChangePagination}
        onHandlePageChange={handlePageChangePagination}
      ></TablePagination>
    </div>
  );
};

export default OwnEmailTemplateTable;
