import React from 'react';

class Input extends React.Component {
  state = {
    text: ''
  };

  //Change value of text input
  handleChange = e => {
    this.setState({ text: e.target.value });
    this.props.onChange(e);
  };

  render() {
    let { id,required, name, type, hint, text, onChange, inputClass, marginTop } =  this.props;
    return (
      <div className={marginTop}>
        <input
          id = {id}
          required={required}
          name={name}
          onChange={this.handleChange.bind(onChange)}
          type={type}
          className={`${inputClass}  form-control-sm font-size-13 py-3`}
          placeholder={hint}
          value={text}
        />
      </div>
    );
  }
}

export default Input;
