
import {getLeadFieldValue, getCampaignData, utilitiesService as utility} from '_services/utilities.service'
import React from "react";

const PropelrrCareersColumns = props => {

    const { lead } = props;

    let type = getLeadFieldValue(lead, 76);
    let campaign = getCampaignData(lead);

    let leadResponsible = lead.responsible ?
        utility.decrypt(lead.responsible.first_name) + ' ' + utility.decrypt(lead.responsible.last_name)
        : 'Unassigned';


    return (
        <React.Fragment>
            <td>
                <span>{type}</span>
            </td>

            <td>
                <span>{lead.origin}</span>
            </td>

            {/* <td>
                <span>{campaign.source}</span>
            </td>

            <td>
                <span>{campaign.medium}</span>
            </td>

            <td>
                <span>
                    {campaign.channel}
                </span>
            </td> */}

            <td>
                <span>
                    {leadResponsible}
                </span>
            </td>

        </React.Fragment>
    );

}

export default PropelrrCareersColumns;