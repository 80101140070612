import React, { useState, useEffect, useRef } from 'react'

const ClientFilter = ({clientList, clientIds, onClientChanged, isClientFilterOpen, onHandleClientFilterOpen, onHandleClientFilterClose, getInfluencerClientLoaded, hasData}) => {
    const [isOpen, setIsOpen] = useState(false);
    const [selected, setSelected] = useState([]);
    const [toggleTab, setToggleTab] = useState(1);
    const filterRef = useRef();

    function handleOpenFilter() {
        setIsOpen(!isOpen);
    }

    function handleCloseFilter() {
        setIsOpen(false);
    }

    useEffect(() => {
        setSelected(clientIds);
    }, [clientIds]);

    // useEffect(() => {
    //     const filterOutsideClick = (e) => {
    //         if (
    //             isOpen &&
    //             filterRef.current &&
    //             !filterRef.current.contains(e.target)
    //         ) {
    //             setIsOpen(false);
    //         }
    //     };
    //     document.addEventListener("click", filterOutsideClick);
    //     return () => {
    //         document.removeEventListener("click", filterOutsideClick);
    //     };
    // }, [isOpen]);

    const handleChange = (e) => {
        const { value, checked } = e.target;

        if (checked) {
            if(!selected.includes(value)){

            }
            setSelected([...selected, parseInt(value)]);
        }

        else {
            setSelected(selected.filter((s) => s !== parseInt(value)));
        }

        setIsOpen(true);

    }


    const handleUpdate = () => {
        onClientChanged(selected);
        onHandleClientFilterClose();
    }

    let activeClass = isClientFilterOpen ? 'active' : '';

    return (
        
        <React.Fragment>
            {getInfluencerClientLoaded && hasData && 
            <button className='plain-btn' onClick={onHandleClientFilterOpen}>
                <i className='icon-lms icon-add'></i>
                Manage Client
            </button>
            }       

            <div className={`drawer-overlay ${activeClass}`} onClick={onHandleClientFilterClose}></div>
                <div className={`side-drawer-main ${activeClass}`}>
                    <div className='side-drawer-wrapper'>
                        <div className='side-drawer-title'>
                            <h3>Manage Client</h3>
                            <span className='side-drawer-close' onClick={onHandleClientFilterClose}><i className="icon-lms icon-close"></i></span>
                        </div>
                        <div className='side-drawer-tabs'>
                            <div className='side-drawer-tabs-wrapper'>
                                <div className='control-tabs-box'>
                                    <div className={toggleTab === 1 ? 'tabs-btn active' : 'tabs-btn'} onClick={() => setToggleTab(1)}>
                                        <p>
                                            Client List
                                        </p>
                                    </div>
                                </div>
                                <div className='content-tabs-box'>
                                    <div className={toggleTab === 1 ? 'tabs-content active' : 'tabs-content'}>
                                        <div className='forms-tab'>
                                            <div className='row'>
                                                <div className='col-12 form-group-box'>
                                                    <div className='input-group-box full-box- checkbox-group-list'>
                                                        {clientList.map((client, index) => {
                                                            return(<div className='check-col'><div className="checkbox-group" key={index}>
                                                                <input type="checkbox" checked={selected.includes(client.id)} name={client.code} id={client.code} value={client.id} onChange={handleChange}/>
                                                                <label htmlFor={client.code}>{client.name}</label>
                                                            </div></div>);
                                                        })}
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <div className='tab-form-btn-group'>
                                    <div className='row'>
                                        <div className='col-12 text-right'>
                                            <span className='drawer-btn cancel-btn' onClick={onHandleClientFilterClose}>
                                                Cancel
                                            </span>
                                            <span className='drawer-btn save-btn' onClick={(()=> handleUpdate())}>
                                                Save Client
                                            </span>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>

            {/* <div className="filter-box filter-category" ref={filterRef}>
                <div className="filter-btn">
                    <button type="button" onClick={() => handleOpenFilter()}>
                        <i className="fas fa-plus"></i>Update Client
                    </button>
                </div>
                <div className={isOpen ? "filter-dropdown active" : "filter-dropdown"}>
                    <div className="filter-title">
                        <p>Clients</p>
                        <i className="fas fa-times" onClick={() => handleCloseFilter()}></i>
                    </div>
                    <div className="filter-list">
                        <div className='client-lists-box'>
                                <div className='client-group'>
                                    {clientList.map((client, index) => {
                                        return(<div className="checkbox-group" key={index}>
                                            <input type="checkbox" checked={selected.includes(client.id)} name={client.code} id={client.code} value={client.id} onChange={handleChange}/>
                                            <label htmlFor={client.code}>{client.name}</label>
                                        </div>);
                                    })}
                                </div>
                            </div>
                            <div className="filter-btn-group">
                                <button className="background-btn btn-apply" onClick={(()=> handleUpdate())} >
                                    <i className="fas fa-check"></i>
                                    Update
                                </button>
                            </div>
                    </div>
                </div>
            </div> */}
        </React.Fragment>
    );

}

export default ClientFilter