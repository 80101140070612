export const utmConstants = {
    GET_UTMS_REQUEST: 'GET_UTMS_REQUEST',
    GET_UTMS_SUCCESS: 'GET_UTMS_SUCCESS',
    GET_UTMS_FAILURE: 'GET_UTMS_FAILURE',

    GENERATE_UTM_REQUEST: 'GENERATE_UTM_REQUEST',
    GENERATE_UTM_SUCCESS: 'GENERATE_UTM_SUCCESS',
    GENERATE_UTM_FAILURE: 'GENERATE_UTM_FAILURE',

    GET_UTM_CODEX_REQUEST: 'GET_UTM_CODEX_REQUEST',
    GET_UTM_CODEX_SUCCESS: 'GET_UTM_CODEX_SUCCESS',
    GET_UTM_CODEX_FAILURE: 'GET_UTM_CODEX_FAILURE',


    GET_UTM_PARAMS_REQUEST: 'GET_UTM_PARAMS_REQUEST',
    GET_UTM_PARAMS_SUCCESS: 'GET_UTM_PARAMS_SUCCESS',
    GET_UTM_PARAMS_FAILURE: 'GET_UTM_PARAMS_FAILURE',

    DELETE_PRESET_REQUEST: 'DELETE_PRESET_REQUEST',
    DELETE_PRESET_SUCCESS: 'DELETE_PRESET_SUCCESS',
    DELETE_PRESET_FAILURE: 'DELETE_PRESET_FAILURE',

    UPDATE_PRESET_REQUEST: 'UPDATE_PRESET_REQUEST',
    UPDATE_PRESET_SUCCESS: 'UPDATE_PRESET_SUCCESS',
    UPDATE_PRESET_FAILURE: 'UPDATE_PRESET_FAILURE',

    ADD_PRESET_REQUEST: 'ADD_PRESET_REQUEST',
    ADD_PRESET_SUCCESS: 'ADD_PRESET_SUCCESS',
    ADD_PRESET_FAILURE: 'ADD_PRESET_FAILURE',

    ADD_UTMS_REQUEST: 'ADD_UTMS_REQUEST',
    ADD_UTMS_SUCCESS: 'ADD_UTMS_SUCCESS',
    ADD_UTMS_FAILURE: 'ADD_UTMS_FAILURE',

    UPDATE_UTM_GROUP_REQUEST: 'UPDATE_UTM_GROUP_REQUEST',
    UPDATE_UTM_GROUP_SUCCESS: 'UPDATE_UTM_GROUP_SUCCESS',
    UPDATE_UTM_GROUP_FAILURE: 'UPDATE_UTM_GROUP_FAILURE',

    GET_PROJECT_PRESETS_REQUEST: 'GET_PROJECT_PRESETS_REQUEST',
    GET_PROJECT_PRESETS_SUCCESS: 'GET_PROJECT_PRESETS_SUCCESS',
    GET_PROJECT_PRESETS_FAILURE: 'GET_PROJECT_PRESETS_FAILURE',

}