import React, {Component} from 'react'
import {influencerActions} from "_actions";
import {connect} from "react-redux";


class KpiHeaderFilter extends Component {

    constructor(props) {
        super(props);
        this.filterHeaderRef = React.createRef();

        this.state = {
            filterHeaderToggled: false,
            checkedFilters: [],
        }

        this.handleFilterItemChange = this.handleFilterItemChange.bind(this);
    }

    handleOpenFilterHeader() {
        this.setState({filterHeaderToggled: !this.state.filterHeaderToggled});
    }

    handleCloseFilterHeader() {
        this.setState({filterHeaderToggled: false})
    }

    handleDropdown = value => {
        if (!this.filterHeaderRef.current.contains(value.target)) {
            this.setState({filterHeaderToggled: false});
        }
    }

    componentDidMount() {
        document.addEventListener('click', this.handleDropdown, true);
    }

    componentWillUnmount() {
        document.removeEventListener('click', this.handleDropdown, true);
    }

    componentDidUpdate(prevProps: Readonly<P>, prevState: Readonly<S>, snapshot: SS) {
        if(!this.arrayEquals(prevProps.data,this.props.data)) {
            this.setState((e,v) => { return ({checkedFilters: this.props.data}) });
        }
    }

    arrayEquals(a, b) {
        return Array.isArray(a) &&
            Array.isArray(b) &&
            a.length === b.length &&
            a.every((val, index) => val === b[index]);
    }


    handleFilterItemChange(e) {

        const {onKpiHeaderFilterChanged} = this.props;
        const {value, checked} = e.target;

        let checkedFilters = [...this.state.checkedFilters, value];
        if (!checked) {
            checkedFilters = checkedFilters.filter(x => x !== value);
        }

        this.setState({checkedFilters: checkedFilters});

        onKpiHeaderFilterChanged(checkedFilters);
    }

    render() {
        const {data} = this.props;
        const {checkedFilters} = this.state;

            return(
                <div className='filter-box'  ref={this.filterHeaderRef}>
                    <div className='filter-btn'>
                        <button
                            onClick={() => this.handleOpenFilterHeader()}
                            className='icon-text-btn'>
                            <i className="fas fa-sliders-h"></i>
                            <span>Filters</span>
                        </button>
                    </div>
                    <div className={this.state.filterHeaderToggled ? 'filter-dropdown active' : 'filter-dropdown'}>
                        <div className='filter-title'>
                            <p>Filters</p>
                            <i className='fas fa-times' onClick={() => this.handleCloseFilterHeader()}></i>
                        </div>

                        <div className='filter-list'>
                            {data.map( (d, index) => {
                                let color = index + 1;
                                return (<div key={index} className='checkbox-group'>
                                    <input value={d} type='checkbox' onChange={this.handleFilterItemChange} checked={checkedFilters.includes(d)} name='selectedCategories' id={`checkbox-${index}`}/>
                                    <label htmlFor={`checkbox-${index}`} >{d}</label>
                                </div>);
                            })}
                        </div>
                    </div>
                </div>);
    }
}


function mapState(state) {
    const {influencers} = state;
    const {getPlatFormTypeLoaded, getCategoryListLoaded} = influencers;
    return {influencers, getPlatFormTypeLoaded, getCategoryListLoaded};
}

const actionCreators = {
    getPlatformTypes: influencerActions.getPlatformTypes,
    getCategories: influencerActions.getCategories,
};

const connectedViewKpiHeaderFilter = connect(mapState, actionCreators)(KpiHeaderFilter);
export {connectedViewKpiHeaderFilter as KpiHeaderFilter};