import React from "react";

const PriorityAction = ({
  openDropdown,
  handleClosePriorityDropdown,
  onSetLeadPriority,
  priorities,
}) => {
  const handlePrioritySelection = (selectedPriority) => {
    // Call the callback function to update the parent state
    onSetLeadPriority(selectedPriority);
    // Close the dropdown or perform any other logic
    handleClosePriorityDropdown();
  };

  return (
    <div
      className={openDropdown === 'priority' ? "action-dropdown active" : "action-dropdown"}
    >
      <div className="dropdown-title">
        <p>
          <i className="icon-lms icon-list-numbered"></i>
          Priority
        </p>
        <i
          className="icon-lms icon-close"
          onClick={handleClosePriorityDropdown}
        ></i>
      </div>
      <div className="dropdown-list priority-list">
        <div className="filter-group">
          {priorities.map((priority) => (
            <span
              key={priority.id}
              className={`filter-name font-color-${priority.label.toLowerCase()}`}
              onClick={() => handlePrioritySelection(priority.id)}
            >
              {priority.label}
            </span>
          ))}
        </div>
      </div>
      <div className="triangle"></div>
    </div>
  );
};

export default PriorityAction;
