import React from 'react'
import { Link } from 'react-router-dom'


const TableBody = () => {
  return (
    <>
        <tbody>
            <tr>
                <td>
                    <Link to='/propelrr/viewinfluencer'>
                    <div className='name-td'>
                        <div className='custom-avatar avatar--color-1'>
                            <span>I</span>
                        </div>
                        <div className='name-info'>
                            <span>Influencer Name</span>
                            <span className='handler-name'>Influencer Handler</span>
                        </div>
                    </div>
                    </Link>
                </td>
                <td colSpan='2'>
                    <div className='platform-summary'>
                        <div className='social-icons-list-box'>
                            <Link>
                                <span className='social-flatform'><i className="fab fa-facebook-f color-icon1"></i></span>
                                <span className='info-text'>100M</span>
                            </Link>
                            <Link>
                                <span className='social-flatform'><i className="fab fa-instagram color-icon2"></i></span>
                                <span className='info-text'>2M</span>
                            </Link>
                            <Link>
                                <span className='social-flatform'><i className="fab fa-twitter color-icon3"></i></span>
                                <span className='info-text'>3M</span>
                            </Link>
                            <Link>
                                <span className='social-flatform'><i className="fab fa-youtube color-icon4"></i></span>
                                <span className='info-text'>4M</span>
                            </Link>
                            <Link>
                                <span className='social-flatform'><i className="fab fa-tiktok color-icon5"></i></span>
                                <span className='info-text'>5M</span>
                            </Link>
                            <Link>
                                <span className='social-flatform'><i className="fab fa-kumu color-icon6"></i></span>
                                <span className='info-text'>1M</span>
                            </Link>
                        </div>
                        <div>
                            <button>View Info</button>
                        </div>
                    </div>
                    <div className='platform-info active'>
                        <div className="platform-group">
                            <div>
                                <Link>
                                    <span className='social-flatform'><i className="fab fa-facebook-f color-icon1"></i>Facebook</span>
                                </Link>
                            </div>
                            <div><span>100M</span></div>
                        </div>
                        <div className="platform-group">
                            <div>
                                <Link>
                                    <span className='social-flatform'><i className="fab fa-instagram color-icon2"></i>Instagram</span>
                                </Link>
                            </div>
                            <div><span>2M</span></div>
                        </div>
                        <div className="platform-group">
                            <div>
                                <Link>
                                    <span className='social-flatform'><i className="fab fa-twitter color-icon3"></i>Twitter</span>
                                </Link>
                            </div>
                            <div><span>3M</span></div>
                        </div>
                        <div className="platform-group">
                            <div>
                                <Link>
                                    <span className='social-flatform'><i className="fab fa-youtube color-icon4"></i>Youtube</span>
                                </Link> 
                            </div>
                            <div><span>4M</span></div>
                        </div>
                        <div className="platform-group">
                            <div>
                                <Link>
                                    <span className='social-flatform'><i className="fab fa-tiktok color-icon5"></i>Tiktok</span>
                                </Link>
                            </div>
                            <div><span>5M</span></div>
                        </div>
                        <div className="platform-group">
                            <div>
                                <Link>
                                    <span className='social-flatform'><i className="fab fa-kumu color-icon6"></i>Kumu</span>
                                </Link>
                            </div>
                            <div><span>1M</span></div>
                        </div>
                    </div>
                </td>
                <td>
                    <span>influencer@gmail.com</span>
                    <span>09091234567</span>
                </td>
                <td>
                    <span>Propelrr</span>
                    <span>Palawan</span>
                </td>
                <td>
                    <span>₱ 5000</span>
                </td>
                <td className='status--box g-status--color-1'>
                    <span className='status-text'>Done</span>
                    <span className='time-text'>20 hours ago</span>
                </td>
                <td>
                    <span>July 18, 2022</span>
                </td>
                <td>
                    <span>Memes, Movies</span>
                </td>
            </tr>
            <tr>
                <td>
                    <Link to='/propelrr/viewinfluencer'>
                    <div className='name-td'>
                        <div className='custom-avatar avatar--color-2'>
                            <span>I</span>
                        </div>
                        <div className='name-info'>
                            <span>Influencer Name</span>
                            <span className='handler-name'>Influencer Handler</span>
                        </div>
                    </div>
                    </Link>
                </td>
                <td colSpan='2'>
                    <div className='platform-summary'>
                        <div>
                            <Link>
                                <span className='social-flatform'><i className="fab fa-facebook-f color-icon1"></i></span>
                                <span className='info-text'>100M</span>
                            </Link>
                            <Link>
                                <span className='social-flatform'><i className="fab fa-instagram color-icon2"></i></span>
                                <span className='info-text'>2M</span>
                            </Link>
                        </div>
                        <div>
                            <button>View All Info</button>
                        </div>
                    </div>
                    <div className='platform-info'>
                        <div className="platform-group">
                            <div>
                                <Link>
                                    <span className='social-flatform'><i className="fab fa-facebook-f color-icon1"></i>Facebook</span>
                                </Link>
                            </div>
                            <div><span>100M</span></div>
                        </div>
                        <div className="platform-group">
                            <div>
                                <Link>
                                    <span className='social-flatform'><i className="fab fa-instagram color-icon2"></i>Instagram</span>
                                </Link>
                            </div>
                            <div><span>2M</span></div>
                        </div>
                    </div>
                </td>
                <td>
                    <span>influencer@gmail.com</span>
                    <span>09091234567</span>
                </td>
                <td>
                    <span>Propelrr</span>
                    <span>Palawan</span>
                </td>
                <td>
                    <span>₱ 5000</span>
                </td>
                <td className='status--box g-status--color-2'>
                    <span className='status-text'>Pending</span>
                    <span className='time-text'>10 hours ago</span>
                </td>
                <td>
                    <span>July 18, 2022</span>
                </td>
                <td>
                    <span>Memes, Movies</span>
                </td>
            </tr>
            <tr>
                <td>
                    <Link to='/propelrr/viewinfluencer'>
                    <div className='name-td'>
                        <div className='custom-avatar avatar--color-3'>
                            <span>I</span>
                        </div>
                        <div className='name-info'>
                            <span>Influencer Name</span>
                            <span className='handler-name'>Influencer Handler</span>
                        </div>
                    </div>
                    </Link>
                </td>
                <td colSpan='2'>
                    <div className='platform-summary'>
                        <div>
                            <Link>
                                <span className='social-flatform'><i className="fab fa-facebook-f color-icon1"></i></span>
                                <span className='info-text'>100M</span>
                            </Link>
                            <Link>
                                <span className='social-flatform'><i className="fab fa-instagram color-icon2"></i></span>
                                <span className='info-text'>2M</span>
                            </Link>
                        </div>
                        <div>
                            <button>View All Info</button>
                        </div>
                    </div>
                    <div className='platform-info'>
                        <div className="platform-group">
                            <div>
                                <Link>
                                    <span className='social-flatform'><i className="fab fa-facebook-f color-icon1"></i>Facebook</span>
                                </Link>
                            </div>
                            <div><span>100M</span></div>
                        </div>
                        <div className="platform-group">
                            <div>
                                <Link>
                                    <span className='social-flatform'><i className="fab fa-instagram color-icon2"></i>Instagram</span>
                                </Link>
                            </div>
                            <div><span>2M</span></div>
                        </div>
                    </div>
                </td>
                <td>
                    <span>influencer@gmail.com</span>
                    <span>09091234567</span>
                </td>
                <td>
                    <span>Propelrr</span>
                    <span>Palawan</span>
                </td>
                <td>
                    <span>₱ 5000</span>
                </td>
                <td className='status--box g-status--color-3'>
                    <span className='status-text'>No Reply</span>
                    <span className='time-text'>10 hours ago</span>
                </td>
                <td>
                    <span>July 18, 2022</span>
                </td>
                <td>
                    <span>Memes, Movies</span>
                </td>
            </tr>
            <tr>
                <td>
                    <Link to='/propelrr/viewinfluencer'>
                    <div className='name-td'>
                        <div className='custom-avatar avatar--color-2'>
                            <span>I</span>
                        </div>
                        <div className='name-info'>
                            <span>Influencer Name</span>
                            <span className='handler-name'>Influencer Handler</span>
                        </div>
                    </div>
                    </Link>
                </td>
                <td colSpan='2'>
                    <div className='platform-summary'>
                        <div>
                            <Link>
                                <span className='social-flatform'><i className="fab fa-facebook-f color-icon1"></i></span>
                                <span className='info-text'>100M</span>
                            </Link>
                            <Link>
                                <span className='social-flatform'><i className="fab fa-instagram color-icon2"></i></span>
                                <span className='info-text'>2M</span>
                            </Link>
                        </div>
                        <div>
                            <button>View All Info</button>
                        </div>
                    </div>
                    <div className='platform-info'>
                        <div className="platform-group">
                            <div>
                                <Link>
                                    <span className='social-flatform'><i className="fab fa-facebook-f color-icon1"></i>Facebook</span>
                                </Link>
                            </div>
                            <div><span>100M</span></div>
                        </div>
                        <div className="platform-group">
                            <div>
                                <Link>
                                    <span className='social-flatform'><i className="fab fa-instagram color-icon2"></i>Instagram</span>
                                </Link>
                            </div>
                            <div><span>2M</span></div>
                        </div>
                    </div>
                </td>
                <td>
                    <span>influencer@gmail.com</span>
                    <span>09091234567</span>
                </td>
                <td>
                    <span>Propelrr</span>
                    <span>Palawan</span>
                </td>
                <td>
                    <span>₱ 5000</span>
                </td>
                <td className='status--box g-status--color-2'>
                    <span className='status-text'>Pending</span>
                    <span className='time-text'>10 hours ago</span>
                </td>
                <td>
                    <span>July 18, 2022</span>
                </td>
                <td>
                    <span>Memes, Movies</span>
                </td>
            </tr>
            <tr>
                <td>
                    <Link to='/propelrr/viewinfluencer'>
                    <div className='name-td'>
                        <div className='custom-avatar avatar--color-1'>
                            <span>I</span>
                        </div>
                        <div className='name-info'>
                            <span>Influencer Name</span>
                            <span className='handler-name'>Influencer Handler</span>
                        </div>
                    </div>
                    </Link>
                </td>
                <td colSpan='2'>
                    <div className='platform-summary'>
                        <div>
                            <Link>
                                <span className='social-flatform'><i className="fab fa-facebook-f color-icon1"></i></span>
                                <span className='info-text'>100M</span>
                            </Link>
                            <Link>
                                <span className='social-flatform'><i className="fab fa-instagram color-icon2"></i></span>
                                <span className='info-text'>2M</span>
                            </Link>
                        </div>
                        <div>
                            <button>View All Info</button>
                        </div>
                    </div>
                    <div className='platform-info'>
                        <div className="platform-group">
                            <div>
                                <Link>
                                    <span className='social-flatform'><i className="fab fa-facebook-f color-icon1"></i>Facebook</span>
                                </Link>
                            </div>
                            <div><span>100M</span></div>
                        </div>
                        <div className="platform-group">
                            <div>
                                <Link>
                                    <span className='social-flatform'><i className="fab fa-instagram color-icon2"></i>Instagram</span>
                                </Link>
                            </div>
                            <div><span>2M</span></div>
                        </div>
                    </div>
                </td>
                <td>
                    <span>influencer@gmail.com</span>
                    <span>09091234567</span>
                </td>
                <td>
                    <span>Propelrr</span>
                    <span>Palawan</span>
                </td>
                <td>
                    <span>₱ 5000</span>
                </td>
                <td className='status--box g-status--color-1'>
                    <span className='status-text'>Done</span>
                    <span className='time-text'>10 hours ago</span>
                </td>
                <td>
                    <span>July 18, 2022</span>
                </td>
                <td>
                    <span>Memes, Movies</span>
                </td>
            </tr>
            <tr>
                <td>
                    <Link to='/propelrr/viewinfluencer'>
                    <div className='name-td'>
                        <div className='custom-avatar avatar--color-3'>
                            <span>I</span>
                        </div>
                        <div className='name-info'>
                            <span>Influencer Name</span>
                            <span className='handler-name'>Influencer Handler</span>
                        </div>
                    </div>
                    </Link>
                </td>
                <td colSpan='2'>
                    <div className='platform-summary'>
                        <div>
                            <Link>
                                <span className='social-flatform'><i className="fab fa-facebook-f color-icon1"></i></span>
                                <span className='info-text'>100M</span>
                            </Link>
                            <Link>
                                <span className='social-flatform'><i className="fab fa-instagram color-icon2"></i></span>
                                <span className='info-text'>2M</span>
                            </Link>
                        </div>
                        <div>
                            <button>View All Info</button>
                        </div>
                    </div>
                    <div className='platform-info'>
                        <div className="platform-group">
                            <div>
                                <Link>
                                    <span className='social-flatform'><i className="fab fa-facebook-f color-icon1"></i>Facebook</span>
                                </Link>
                            </div>
                            <div><span>100M</span></div>
                        </div>
                        <div className="platform-group">
                            <div>
                                <Link>
                                    <span className='social-flatform'><i className="fab fa-instagram color-icon2"></i>Instagram</span>
                                </Link>
                            </div>
                            <div><span>2M</span></div>
                        </div>
                    </div>
                </td>
                <td>
                    <span>influencer@gmail.com</span>
                    <span>09091234567</span>
                </td>
                <td>
                    <span>Propelrr</span>
                    <span>Palawan</span>
                </td>
                <td>
                    <span>₱ 5000</span>
                </td>
                <td className='status--box g-status--color-3'>
                    <span className='status-text'>No Reply</span>
                    <span className='time-text'>10 hours ago</span>
                </td>
                <td>
                    <span>July 18, 2022</span>
                </td>
                <td>
                    <span>Memes, Movies</span>
                </td>
            </tr>
            <tr>
                <td>
                    <Link to='/propelrr/viewinfluencer'>
                    <div className='name-td'>
                        <div className='custom-avatar avatar--color-2'>
                            <span>I</span>
                        </div>
                        <div className='name-info'>
                            <span>Influencer Name</span>
                            <span className='handler-name'>Influencer Handler</span>
                        </div>
                    </div>
                    </Link>
                </td>
                <td colSpan='2'>
                    <div className='platform-summary'>
                        <div>
                            <Link>
                                <span className='social-flatform'><i className="fab fa-facebook-f color-icon1"></i></span>
                                <span className='info-text'>100M</span>
                            </Link>
                            <Link>
                                <span className='social-flatform'><i className="fab fa-instagram color-icon2"></i></span>
                                <span className='info-text'>2M</span>
                            </Link>
                        </div>
                        <div>
                            <button>View All Info</button>
                        </div>
                    </div>
                    <div className='platform-info'>
                        <div className="platform-group">
                            <div>
                                <Link>
                                    <span className='social-flatform'><i className="fab fa-facebook-f color-icon1"></i>Facebook</span>
                                </Link>
                            </div>
                            <div><span>100M</span></div>
                        </div>
                        <div className="platform-group">
                            <div>
                                <Link>
                                    <span className='social-flatform'><i className="fab fa-instagram color-icon2"></i>Instagram</span>
                                </Link>
                            </div>
                            <div><span>2M</span></div>
                        </div>
                    </div>
                </td>
                <td>
                    <span>influencer@gmail.com</span>
                    <span>09091234567</span>
                </td>
                <td>
                    <span>Propelrr</span>
                    <span>Palawan</span>
                </td>
                <td>
                    <span>₱ 5000</span>
                </td>
                <td className='status--box g-status--color-2'>
                    <span className='status-text'>Pending</span>
                    <span className='time-text'>10 hours ago</span>
                </td>
                <td>
                    <span>July 18, 2022</span>
                </td>
                <td>
                    <span>Memes, Movies</span>
                </td>
            </tr>
            <tr>
                <td>
                    <Link to='/propelrr/viewinfluencer'>
                    <div className='name-td'>
                        <div className='custom-avatar avatar--color-1'>
                            <span>I</span>
                        </div>
                        <div className='name-info'>
                            <span>Influencer Name</span>
                            <span className='handler-name'>Influencer Handler</span>
                        </div>
                    </div>
                    </Link>
                </td>
                <td colSpan='2'>
                    <div className='platform-summary'>
                        <div>
                            <Link>
                                <span className='social-flatform'><i className="fab fa-facebook-f color-icon1"></i></span>
                                <span className='info-text'>100M</span>
                            </Link>
                            <Link>
                                <span className='social-flatform'><i className="fab fa-instagram color-icon2"></i></span>
                                <span className='info-text'>2M</span>
                            </Link>
                        </div>
                        <div>
                            <button>View All Info</button>
                        </div>
                    </div>
                    <div className='platform-info'>
                        <div className="platform-group">
                            <div>
                                <Link>
                                    <span className='social-flatform'><i className="fab fa-facebook-f color-icon1"></i>Facebook</span>
                                </Link>
                            </div>
                            <div><span>100M</span></div>
                        </div>
                        <div className="platform-group">
                            <div>
                                <Link>
                                    <span className='social-flatform'><i className="fab fa-instagram color-icon2"></i>Instagram</span>
                                </Link>
                            </div>
                            <div><span>2M</span></div>
                        </div>
                    </div>
                </td>
                <td>
                    <span>influencer@gmail.com</span>
                    <span>09091234567</span>
                </td>
                <td>
                    <span>Propelrr</span>
                    <span>Palawan</span>
                </td>
                <td>
                    <span>₱ 5000</span>
                </td>
                <td className='status--box g-status--color-1'>
                    <span className='status-text'>Done</span>
                    <span className='time-text'>10 hours ago</span>
                </td>
                <td>
                    <span>July 18, 2022</span>
                </td>
                <td>
                    <span>Memes, Movies</span>
                </td>
            </tr>
            <tr>
                <td>
                    <Link to='/propelrr/viewinfluencer'>
                    <div className='name-td'>
                        <div className='custom-avatar avatar--color-3'>
                            <span>I</span>
                        </div>
                        <div className='name-info'>
                            <span>Influencer Name</span>
                            <span className='handler-name'>Influencer Handler</span>
                        </div>
                    </div>
                    </Link>
                </td>
                <td colSpan='2'>
                    <div className='platform-summary'>
                        <div>
                            <Link>
                                <span className='social-flatform'><i className="fab fa-facebook-f color-icon1"></i></span>
                                <span className='info-text'>100M</span>
                            </Link>
                            <Link>
                                <span className='social-flatform'><i className="fab fa-instagram color-icon2"></i></span>
                                <span className='info-text'>2M</span>
                            </Link>
                        </div>
                        <div>
                            <button>View All Info</button>
                        </div>
                    </div>
                    <div className='platform-info'>
                        <div className="platform-group">
                            <div>
                                <Link>
                                    <span className='social-flatform'><i className="fab fa-facebook-f color-icon1"></i>Facebook</span>
                                </Link>
                            </div>
                            <div><span>100M</span></div>
                        </div>
                        <div className="platform-group">
                            <div>
                                <Link>
                                    <span className='social-flatform'><i className="fab fa-instagram color-icon2"></i>Instagram</span>
                                </Link>
                            </div>
                            <div><span>2M</span></div>
                        </div>
                    </div>
                </td>
                <td>
                    <span>influencer@gmail.com</span>
                    <span>09091234567</span>
                </td>
                <td>
                    <span>Propelrr</span>
                    <span>Palawan</span>
                </td>
                <td>
                    <span>₱ 5000</span>
                </td>
                <td className='status--box g-status--color-3'>
                    <span className='status-text'>No Reply</span>
                    <span className='time-text'>10 hours ago</span>
                </td>
                <td>
                    <span>July 18, 2022</span>
                </td>
                <td>
                    <span>Memes, Movies</span>
                </td>
            </tr>
        </tbody>
    </>
  )
}

export default TableBody