import React, {Component} from "react";
// import {defaultConfig} from "_pages/Influencer/components/Table/InfluencerTableHeaderConfig";

class InfluencerTableHeader extends Component {

    constructor(props) {
        super(props);

        this.addColumnTableRef = React.createRef();

        this.state = {
            addColumnTable: false
        }
    }

    render() {
        return (
            <thead>
                 <tr>
                    {this.props.defaultConfig.map((c, index) => {
                        return  (
                            <th colSpan={c.span} key={index} width={`${c.width}%`}>
                                <div className="th-sort">
                                    <span>{c.title}</span>
                                    {/* <span>{c.remove}</span> */}
                                    <span className="sort-btns">
                                        <i className="fas fa-sort-up"></i>
                                        <i className="fas fa-sort-down"></i>
                                    </span>
                                </div>
                            </th>
                       );
                    })}
                 </tr>
            </thead>);
    }
}

export default InfluencerTableHeader;