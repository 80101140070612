import React, {useState} from 'react';
import InputForm from "_components/InputForm";
import {useDispatch, useSelector} from "react-redux";
import {useParams} from "react-router-dom";
import { projectActions} from "_actions";
import {utilitiesService} from "_services";

const ProjectMembers = ({onHandleProjectMembersDrawer, isProjectMembersDrawer, members}) => {

    const [toggleTabState, setToggleTabState] = useState(1);
    const [email, setEmail] = useState('');
    const [firstName, setFirstName] = useState('');
    const [lastName, setLastName] = useState('');
    const dispatch = useDispatch();
    const params = useParams();
    const emailRegex = /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
    const [errEmail, setErrEmail] = useState(false);
    const [errFirstName, setErrFirstName] = useState(false);
    const [errLastName, setErrLastName] = useState(false);


    const addMemberLoading = useSelector((state) => state.projects.addMemberLoading ? state.projects.addMemberLoading : false);

    const handleCreate = () => {

        if (email.trim().length !== 0 && emailRegex.test(email) !== false && firstName.trim().length !== 0 && lastName.trim().length !== 0) {
            setErrEmail(false);
            setErrFirstName(false);
            setErrLastName(false);

            const projectId = params.id;
                const member = {
                email: utilitiesService.encrypt(email),
                first_name: utilitiesService.encrypt(firstName),
                last_name: utilitiesService.encrypt(lastName)
            };

            dispatch(projectActions.addProjectMember(member, projectId));
            setTimeout(() => {
                setEmail('');
                setFirstName('');
                setLastName('');
            }, 1000);

        } else {
            if (email.trim().length === 0 || emailRegex.test(email) === false) {
                setErrEmail(true);  
            } else {
                setErrEmail(false);
            }
            if (firstName.trim().length === 0) {
                setErrFirstName(true);
            } else {
                setErrFirstName(false);
            }
            if (lastName.trim().length === 0) {
                setErrLastName(true);
            } else {
                setErrLastName(false);
            }
        }
        
    }

    const toggleTab = (index) => {
        setToggleTabState(index);
    }

    let activeClass = isProjectMembersDrawer ? 'active' : '';

    return (
        <>
            <div className={`drawer-overlay ${activeClass}`} onClick={onHandleProjectMembersDrawer}></div>
            <div className={`side-drawer-main ${activeClass}`}>
                <div className='side-drawer-wrapper'>
                    <div className='side-drawer-title'>
                        <h3>Project Members</h3>
                        <span className='side-drawer-close' onClick={onHandleProjectMembersDrawer}>
                            <i className="icon-lms icon-close"></i>
                        </span>
                    </div>
                    <div className='side-drawer-tabs'>
                        <div className='side-drawer-tabs-wrapper'>
                            <div className='control-tabs-box'>
                                <div className={toggleTabState === 1 ? 'tabs-btn active' : 'tabs-btn'}
                                     onClick={() => toggleTab(1)}>
                                    <p>
                                        New
                                    </p>
                                </div>
                                {/*<div className={toggleTabState === 2 ? 'tabs-btn active' : 'tabs-btn'} onClick={() => toggleTab(2)}>*/}
                                {/*    <p>*/}
                                {/*        Existing*/}
                                {/*    </p>*/}
                                {/*</div>*/}
                            </div>
                            <div className='content-tabs-box'>
                                {
                                    !addMemberLoading &&
                                    <React.Fragment>
                                        <div className={toggleTabState === 1 ? 'tabs-content active' : 'tabs-content'}>
                                            <div className='forms-tab'>
                                                <div className='row'>
                                                    <div className='col-lg-12 form-group-box'>
                                                        <div className="form-group-title">
                                                            <label>Add New Member</label>
                                                        </div>
                                                        <div className='input-group-box full-box-'>
                                                            <div className="field-box">
                                                                <label htmlFor='fname'>Email Address <small>*</small></label>
                                                                <InputForm
                                                                    onChange={(e) => setEmail(e.target.value)}
                                                                    id={`email`}
                                                                    name={`email`}
                                                                    value={email}
                                                                />
                                                                {
                                                                    errEmail ?
                                                                    <p className='error-field'>Invalid Email Address</p>
                                                                    :
                                                                    <></>
                                                                }
                                                            </div>
                                                        </div>
                                                        <div className='input-group-box full-box-'>
                                                            <div className="field-box">
                                                                <label htmlFor='first_name'>First Name <small>*</small></label>
                                                                <InputForm
                                                                    onChange={(e) => setFirstName(e.target.value)}
                                                                    id={`first_name`}
                                                                    name={`first_name`}
                                                                    value={firstName}
                                                                />
                                                                {
                                                                    errFirstName ?
                                                                    <p className='error-field'>This field is required</p>
                                                                    :
                                                                    <></>
                                                                }
                                                            </div>
                                                        </div>
                                                        <div className='input-group-box full-box-'>
                                                            <div className="field-box">
                                                                <label htmlFor='last_name'>Last Name <small>*</small></label>
                                                                <InputForm
                                                                    onChange={(e) => setLastName(e.target.value)}
                                                                    id={`last_name`}
                                                                    name={`last_name`}
                                                                    value={lastName}
                                                                />
                                                                {
                                                                    errLastName ?
                                                                    <p className='error-field'>This field is required</p>
                                                                    :
                                                                    <></>
                                                                }
                                                            </div>
                                                        </div>

                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                        <div className={toggleTabState === 2 ? 'tabs-content active' : 'tabs-content'}>
                                            <div className='members-tab'>
                                                <div className='members-box'>
                                                    <div className='user-info-box'>
                                                        <div className='user'>
                                                            <div className='avatar-box avatar-color-1'>
                                                                <span>P</span>
                                                            </div>
                                                        </div>
                                                        <div className='info'>
                                                            <p className='name if-admin'>Lorem Ipsum </p>
                                                            <span className='email'>
                                                        lorem.ipsum@propelrr.com
                                                    </span>
                                                        </div>
                                                    </div>
                                                    {/* <div className='btns-group'>
                                                <button className='btn-edit'><i className="fas fa-pen"></i></button>
                                                <button className='btn-delete'><i className="fas fa-trash"></i></button>
                                            </div> */}
                                                </div>
                                                <div className='members-box'>
                                                    <div className='user-info-box'>
                                                        <div className='user'>
                                                            <div className='avatar-box avatar-color-2'>
                                                                <span>M</span>
                                                            </div>
                                                        </div>
                                                        <div className='info'>
                                                            <p className='name'>Lorem Ipsum</p>
                                                            <span className='email'>
                                                        lorem.ipsum@propelrr.com
                                                    </span>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </React.Fragment>
                                }
                                {
                                    addMemberLoading &&
                                    <div className='loading-box'>
                                        <div class="lds-ellipsis"><div></div><div></div><div></div><div></div></div>
                                    </div>
                                }
                            </div>
                            <div className='tab-form-btn-group'>
                                <div className='row'>
                                    <div className='col text-right'>
                                        <span className='drawer-btn cancel-btn'
                                                onClick={onHandleProjectMembersDrawer}>
                                            Cancel
                                        </span>
                                        {!addMemberLoading &&  <span className='drawer-btn save-btn'  onClick={handleCreate}>
                                           Add Member
                                        </span>}

                                        {addMemberLoading &&<span className='drawer-btn cancel-btn c-btn-disabled' disabled={true}  onClick={handleCreate}>
                                            Adding Member ...
                                        </span>}
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </>
    )
}

export default ProjectMembers

