import React, {Component} from 'react'
import {projectActions} from "_actions";
import {connect} from "react-redux";
import {MDBIcon} from "mdbreact";
import {priorityColor, priorityIcon} from '_pages/Leads/constants/BreadCrumbs'


class LeadPriorityDropdown extends Component {

    constructor(props) {
        super(props);
        this.filterRef = React.createRef();

        this.state = {
            filterToggled: false,
        }

    }

    componentDidUpdate = () => {

    }

    handleOpenFilter() {
        this.setState({filterToggled: true})
    }

    handleCloseFilter() {
        this.setState({filterToggled: false})
    }

    handleDropdown = value => {
        if (!this.filterRef.current.contains(value.target)) {
            this.setState({filterToggled: false})
        }
    }

    handleUpdateLeadPriority = value => {
        const {onHandleUpdateLeadPriority} = this.props;
        onHandleUpdateLeadPriority(value);
        this.setState({filterToggled: false})
    }


    componentDidMount() {
        document.addEventListener('click', this.handleDropdown, true);
    }

    componentWillUnmount() {
        document.removeEventListener('click', this.handleDropdown, true);
    }

    render() {

        const {lead} = this.props;

        let items = [
            {
                id: 1,
                value: '1',
                label: 'Low'
            },
            {
                id: 2,
                value: '2',
                label: 'Medium'
            },
            {
                id: 3,
                value: '3',
                label: 'High'
            },
        ];

        const priority = lead.priority;

        return (

            <div className='filter-box' ref={this.filterRef}>
                <div className='filter-btn priority-btn'>
                    <span className={`${priorityColor[priority.name]}`} onClick={() => this.handleOpenFilter()}>
                        <i className={`icon-lms icon-${priorityIcon[priority.name]}`}></i>
                        {lead.priority.name}
                    </span>
                </div>
                <div className={this.state.filterToggled ? 'filter-dropdown active' : 'filter-dropdown'}>
                    <div className='filter-title'>
                        <p>Priority</p>
                        <i className='icon-lms icon-close' onClick={() => this.handleCloseFilter()}></i>
                    </div>
                    <div className='filter-list priority-list'>
                        <div className='filter-group'>

                                {items.map((item, index) => {
                                    return (
                                        <span key={`filter-dropdown-${index}`} className={`filter-name ${priorityColor[item.label]}`}
                                              onClick={() => this.handleUpdateLeadPriority(item.id)}>
                                            
                                                <i className={`icon-lms icon-${priorityIcon[item.label]}`}></i>
                                                {item.label}
                                            
                                        </span>
                                    );
                                })}

                        </div>
                    </div>
                </div>
            </div>

        );
    }
}


function mapState(state) {
    const {project, members, membersLoaded, projectLoaded} = state.projects
    return {project, members, membersLoaded, projectLoaded};
}

const actionCreators = {
    getProject: projectActions.getProject,
};

const connectedViewLeadPriorityDropdown = connect(mapState, actionCreators)(LeadPriorityDropdown);
export {connectedViewLeadPriorityDropdown as LeadPriorityDropdown};