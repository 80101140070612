import React, {Component} from 'react';
import {connect} from "react-redux";
import {influencerActions} from "_actions/influencer.actions";
import {KpiControlAdvanceFilter} from "_pages/Influencer/components/Kpi/KpiControlAdvanceFilter";
import TableControlLoader from '../../../../_components/Loader/TableControlLoader';
import {DateRangePicker} from "rsuite";
import {ranges} from '../../constants/DateRangeConfig'
import Moment from "react-moment";

class KpiControl extends Component {

    constructor(props) {
        super(props);

        this.searchRef = React.createRef();
        this.searchInputRef = React.createRef();

        this.state = {
            searchToggled: false,
            filteredToggled: false,
            toggleKpi: true,
            selectedDates: props.dates
        }
    }

    handleToggleKpi () {
        this.setState({toggleKpi: !this.state.toggleKpi});
        this.props.onHandleKpiHeaderHeightChanged(!this.state.toggleKpi);
    }

    handlePageChanged = (value, className) => {
        if(className === 'active') {
            this.props.onHandlePageChanged(value);
        }
    }

    handleSizeChanged = e => {
        let size = parseInt(e.options[e.selectedIndex].value);
        this.props.onHandleSizeChanged(size);
    }


    handleOpenFilter () {
        this.setState({filteredToggled: true})
    }

    handleCloseFilter () {
        this.setState({filteredToggled: false})
    }

    handleOpenSearch () {
        this.setState({searchToggled: true})
    }

    handleSearch() {
        if(this.searchInputRef.current.value !== '') {
            this.props.onHandleSearch(this.searchInputRef.current.value);
        }
    }

    handleClearSearch() {
        if(this.searchInputRef.current.value !== '') {
            this.searchInputRef.current.value = '';
            this.props.onHandleSearch(null);
        }

        this.setState({searchToggled: false})
    }

    handleDateRangeChange = value => {
        this.setState({ selectedDates: value });
        this.props.onHandleDateChanged(value);
    }

    handleKeyPress = e => {
        if (e.key === 'Enter') {
            this.handleSearch();
        }

        if (e.key === "Escape") {
            this.handleClearSearch();
        }
    }

    componentDidUpdate(prevProps) {
        if (prevProps.selectedDates !== this.props.selectedDates) {
          this.setState({ selectedDates: this.props.selectedDates });
        }
      }

    componentDidMount() {
        document.addEventListener('keypress', this.handleKeyPress, true);
    }

    componentWillUnmount() {
        document.removeEventListener('keypress', this.handleKeyPress, true);
    }

    render() {

        const {number,first,last,size,totalElements,dates} = this.props;
        const {currentPage, currentSize, currentQuery, currentDate} = this.state;

        let page = number + 1;
        let min = (page * size) - (size - 1);
        let max = page * size;
        let count = totalElements;
        let prevClass =  first ? '' : 'active';
        let nextClass =  last ? '' : 'active';
        let nextPage = page + 1;
        let previousPage = page- 1;
        let defaultPage = parseInt(currentPage);
        let activeSize = size ? size : currentSize;

        let countText = 'Loading Influencers ...';
        if(page) {
            let limit = max;
            if(count < max){
                limit = count;
            }
            countText = `${min}-${limit} of ${count}`;
        }

        return (
            <div className='overview-control-box'>
                {/* <TableControlLoader /> */}
                <div className='col-left'>
                    <div className='button-group'>
                        <KpiControlAdvanceFilter
                            projectId={this.props.projectId}
                            onApplyFilter={this.props.onApplyFilter}
                            resetFilterState={this.props.resetFilterState}
                            onHandleResetFilter = {this.props.onHandleResetFilter}

                        />

                        <div className='date-range-picker-box'>
                            <DateRangePicker
                                value={dates}
                                ranges={ranges}
                                appearance="subtle"
                                format="MMM-dd-yyyy"
                                size="lg"
                                placeholder="All Time"
                                placement="autoVerticalStart"
                                cleanable={true}
                                onChange={this.handleDateRangeChange}
                            />
                        </div>

                        <div className={this.state.searchToggled ? 'search-box active' : 'search-box'}  ref={this.searchRef}>
                            <div className='input-search'>
                                <i className="icon-lms icon-search"></i>
                                <input  key={currentQuery} defaultValue={currentQuery}  type='text' placeholder='Search Lead' ref={this.searchInputRef} onClick={() => this.handleOpenSearch()}/>
                                <div className='controls'>
                                <i className="icon-lms icon-close" onClick={() => this.handleClearSearch()}></i>
                                <i className="icon-lms icon-arrow-right" onClick={() => this.handleSearch()}></i>
                                </div>
                            </div>
                        </div>
                        
                    </div>
                </div>
                
                <div className='col-right'>
                    <div className='button-toggle'>
                        <p>Data Overview</p>
                        <div className={this.state.toggleKpi ? "switch-tggle active" : "switch-tggle"} onClick={() => this.handleToggleKpi()}>
                            <span className="slider">
                            </span>
                            <span className='tggle-txt'></span>
                        </div>
                    </div>
                </div>
            </div>
        );
    }
}

function mapState(state) {
    const { influencerListLoaded } = state.influencers

    if(influencerListLoaded) {
        const {influencers} = state;
        const {list} = influencers;
        const {number, first, last, size, totalElements, totalPages } = list.data;
        return {number, first, last, size, totalElements, totalPages , influencerListLoaded};
    }

    return { influencerListLoaded }
}

const actionCreators = {
    getInfluencerList: influencerActions.getInfluencerList
};

const connectedViewKpiControl = connect(mapState, actionCreators)(KpiControl);
export { connectedViewKpiControl as KpiControl };
