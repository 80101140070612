import React from 'react';
import { MDBIcon, MDBCol, MDBRow } from 'mdbreact'

class CreateFormGroup extends React.Component {
  constructor(props){
    super(props);

    this.state = {
      isExpanded: true,
      dropIcon: 'caret-square-up',
      dropdownDiv: true,
      activeFormGroup: this.props.activeFormGroup,
    };
  }
  
  handleAccordionToggle = () => {
    let isExpanded = this.state.isExpanded;

    isExpanded ?
      this.setState({ isExpanded: false, dropIcon: 'caret-square-down' }) :
      this.setState({ isExpanded: true, dropIcon: 'caret-square-up' })

  };

  getEditFormGroup = data => {
    this.props.getEditFormGroup();
  };

  getFormFieldButtons = data => {
    this.props.getFormFieldButtons();
  };

  render() {
    let activeFormGroup = 'formGroup0';

    return(
      <MDBRow
        id={activeFormGroup}
        onClick={(e) => {
          activeFormGroup = this.state.activeFormGroup !== activeFormGroup ||  this.state.activeFormGroup !== '' ? 'formGroup0' : '';
          this.setState({
            activeFormGroup: activeFormGroup
          }, () => {
            this.props.handleActiveFormGroup(activeFormGroup);
            this.getEditFormGroup();
          });
        }}
        className='m-t--sm'
      >
        <MDBCol xl='12' className='form-builder__container form-group' id='inputFieldsArea'>
          <MDBRow>
            <MDBCol xl='12' lg='12' className='form-group__header'>
              <label className='form-group__label'>
                New Form Group
              </label>
              <MDBIcon
                far
                icon={this.state.dropIcon}
                className='icon-accordion ml-5 hand'
                onClick={(event) => {
                  event.preventDefault();
                  this.handleAccordionToggle();
                }}
              />
            </MDBCol>
          </MDBRow>
          <MDBRow className={ this.state.isExpanded ? '' : 'display-none'}>
            <hr className='mx-2 form-group__divider'/>
            <MDBCol xl='12' lg='12' className='p-l--0 p-r--0'>
              <div className='dotted add-field-background m-l--md m-r--md m-b--md m-t--0'>
                <p className='p--md color-gray'>
                  This is your new form group. Form groups will serve as a container to your form fields. You can have as many form groups you want. Select this box to update the form group's name.
                </p>
              </div>
            </MDBCol>
            <MDBCol xl='1' lg='1' className='text-center pt-0'>
            </MDBCol>
          </MDBRow>
        </MDBCol>
      </MDBRow>
    )
  }
}

export default CreateFormGroup;
