import React, {useState, useRef, useEffect} from 'react';
import {useDispatch, useSelector} from "react-redux";
import {useParams} from "react-router-dom";
import {managerActions} from "_actions";

const IntervalControls = ({onHandleSearchMember}) => {
    const [isSearch, setIsSearch] = useState(false);
    const [isSearchValue, setIsSearchValue] = useState('');
    const searchRef = useRef();
    const searchInputRef = useRef();
    const [query, setQuery] = useState(null);

    const dispatch = useDispatch();
    const params = useParams();

    const handleOpenSearch = () => {
        setIsSearch(true);
    };

    const handleClearSearch = () => {
        setQuery(null);
        setIsSearch(false);
        setIsSearchValue('');
        dispatch(managerActions.getManagers(params.id));
    };
    const handleCloseSearch = () => {
        setIsSearch(false);
        setIsSearchValue('');
    };

    const page = useSelector((state) => {
        const {managers} = state;
        if (managers.getManagersLoaded) {
            return managers.managers.number + 1;
        }
        return 0;
    });

    const size = useSelector((state) => {
        const {managers} = state;

        if (managers.getManagersLoaded) {
            return managers.managers.size;
        }
        return 0;
    });

    const total = useSelector((state) => {
        const {managers} = state;

        if (managers.getManagersLoaded) {
            return managers.managers.totalElements;
        }
        return 0;
    });

    const isFirst = useSelector((state) => {
        const {managers} = state;
        if (managers.getManagersLoaded) {
            return managers.managers.first;
        }
        return true;
    });

    const isLast = useSelector((state) => {
        const {managers} = state;
        if (managers.getManagersLoaded) {
            return managers.managers.last;
        }
        return true;
    });

    const handleChangeSearch = (event) => {
        setIsSearchValue(event.target.value);
    };


    function handlePreviousPage() {
        fetchMembers(query, page - 1);
    }

    function handleNextPage() {
        fetchMembers(query, page + 1);
    }

    function handleSearch() {
        setQuery(searchInputRef.current.value);
        fetchMembers(searchInputRef.current.value);
    }

    function fetchMembers(val) {
        dispatch(managerActions.getManagers(params.id, val, page));
    }

    const handleKeyPress = e => {
        if (e.key === 'Enter') {
            handleSearch();
        }

        if (e.key === "Escape") {
            handleClearSearch();
        }
    }
    useEffect(() => {
        // Add event listener for key press on window
        window.addEventListener('keydown', handleKeyPress);
    
        // Clean up the event listener when the component unmounts
        return () => {
          window.removeEventListener('keydown', handleKeyPress);
        };
    }, []);

    return (
        <div className="overview-control-box">
            <div className="col-left">
                <div className='button-group'>
                    <div className={isSearch ? "search-box active" : "search-box"} ref={searchRef}>
                        <div className="input-search">
                            <i className="icon-lms icon-search"></i>
                            <input type="text" placeholder="Search Member" ref={searchInputRef} value={isSearchValue} onChange={handleChangeSearch} onClick={() => handleOpenSearch()} />
                            <div className='controls'>
                                <i className="icon-lms icon-close" onClick={() => handleClearSearch()}></i>
                                <i className="icon-lms icon-arrow-right" onClick={() => handleSearch()}></i>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
};

export default IntervalControls;
