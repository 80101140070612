import { publicConstants } from '_constants';
import { publicService } from '_services';
import { alertActions } from './';
import { environment } from "_environments/environment";

export const publicActions = {
    googleConnectOauth,
    googleConnectAuthorize,
    updateConnection,
    disconnectGmail,
    generateForm,
    addLead
};

function googleConnectOauth(project_id, form_id, redirect){
    //const redirect = googleConnectAuthorize(project_id, form_id);
    return dispatch => {
        dispatch(request(project_id, form_id, redirect));
        publicService.googleConnectOauth(project_id, form_id, redirect)
            .then(
                connect_oauth => {
                    window.location.href = connect_oauth.data.url;
                  dispatch(success(connect_oauth));
                },
                error => {
                    dispatch( failure(error.toString()));
                }
            );
    };

    function request(connect_oauth) {
        return { type: publicConstants.GET_OAUTH_CONNECT_REQUEST, connect_oauth } }
    function success(project_id, form_id, connect_oauth) {
      return { type: publicConstants.GET_OAUTH_CONNECT_SUCCESS, connect_oauth }
    }
    function failure(error) { return { type: publicConstants.GET_OAUTH_CONNECT_FAILURE, error } }
}

/*function googleConnectAuthorize( state){
    return dispatch => {
        dispatch(request( state));
        publicService.googleConnectAuthorize( state)
            .then(
                connect_oauth => {
                    dispatch(success(connect_oauth));
                    //gmailConnectWindow.close();
                },
                error => {
                    dispatch(failure(error.toString()));
                    //dispatch(alertActions.error(error.toString()));
                }
            );
    };

    function request(connect_oauth) { return { type: publicConstants.GET_OAUTH_AUTHORIZE_REQUEST, connect_oauth } }
    function success(connect_oauth) { return { type: publicConstants.GET_OAUTH_AUTHORIZE_SUCCESS, connect_oauth } }
    function failure(error) { return { type: publicConstants.GET_OAUTH_AUTHORIZE_FAILURE, error } }
}*/

function googleConnectAuthorize(project_id, form_id, state) {
    return dispatch => {
        dispatch(request(project_id, form_id, state));
        publicService.googleConnectAuthorize(project_id, form_id, state)
            .then(
                connect_oauth => {
                    dispatch(success(connect_oauth));
                    let projectId = sessionStorage.getItem('projectId');
                    let form = JSON.parse(sessionStorage.getItem('form'));
                    let formId = form.form_id;
                    let pathName = window.location.pathname;
                    if (pathName.includes('email-connection/success')) {
                        window.location.href = `${environment.baseUrl}/project/${projectId}/form/${formId}/email-connection`;
                    }
                },
                error => {
                    dispatch(failure(error.toString()));
                    //dispatch(alertActions.error(error.toString()));
                }
            );
    };

    function request(connect_oauth) {return {type: publicConstants.GET_OAUTH_AUTHORIZE_REQUEST, connect_oauth}}
    function success(connect_oauth) {return {type: publicConstants.GET_OAUTH_AUTHORIZE_SUCCESS, connect_oauth}}
    function failure(error) {return {type: publicConstants.GET_OAUTH_AUTHORIZE_FAILURE, error}}
}

function disconnectGmail(project_id, form_id, connectionId){
  return dispatch => {
    dispatch(request(project_id, form_id, connectionId));
    publicService.disconnectGmail(project_id, form_id, connectionId)
      .then(
        connection => {
          dispatch(success());
        },
        error => {
          dispatch(failure(error.toString()));
          //dispatch(alertActions.error(error.toString()));
        }
      );
  };

  function request(connectionId) { return { type: publicConstants.DISCONNECT_GMAIL_REQUEST, connectionId } }
  function success() { return { type: publicConstants.DISCONNECT_GMAIL_SUCCESS } }
  function failure(error) { return { type: publicConstants.DISCONNECT_GMAIL_FAILURE, error } }
}

function updateConnection(project_id, form_id, connection){
    return dispatch => {
        dispatch(request(project_id, form_id, connection));
        publicService.updateConnection(project_id, form_id, connection)
            .then(
              connection => {
                    dispatch(success(connection));
                },
                error => {
                    dispatch(failure(error.toString()));
                    dispatch(alertActions.error(error.toString()));
                }
            );
    };

    function request(connection) { return { type: publicConstants.UPDATE_CONNECTION_REQUEST, connection } }
    function success(connection) { return { type: publicConstants.UPDATE_CONNECTION_SUCCESS, connection } }
    function failure(error) { return { type: publicConstants.UPDATE_CONNECTION_FAILURE, error } }
}

function generateForm(access_key){
    return dispatch => {
        dispatch(request(access_key));
        publicService.generateForm(access_key)
            .then(
                generate_form => {
                    dispatch(success(generate_form));
                },
                error => {
                    dispatch(failure(error.toString()));
                    dispatch(alertActions.error(error.toString()));
                }
            );
    };

    function request(generate_form) { return { type: publicConstants.GET_PUBLIC_FORM_REQUEST, generate_form } }
    function success(generate_form) { return { type: publicConstants.GET_PUBLIC_FORM_SUCCESS, generate_form } }
    function failure(error) { return { type: publicConstants.GET_PUBLIC_FORM_FAILURE, error } }
}

function addLead(access_key){
    return dispatch => {
        dispatch(request(access_key));
        publicService.addLead(access_key)
            .then(
                add_lead => {
                    dispatch(success(add_lead));
                },
                error => {
                    dispatch(failure(error.toString()));
                    dispatch(alertActions.error(error.toString()));
                }
            );
    };

    function request(add_lead) { return { type: publicConstants.ADD_LEAD_PUBLIC_FORM_REQUEST, add_lead } }
    function success(add_lead) { return { type: publicConstants.ADD_LEAD_PUBLIC_FORM_SUCCESS, add_lead } }
    function failure(error) { return { type: publicConstants.ADD_LEAD_PUBLIC_FORM_FAILURE, error } }
}
