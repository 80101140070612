import React, {Component} from 'react';
import {influencerActions} from "_actions";
import {connect} from "react-redux";
import ClientFilter from "_pages/Influencer/components/Info/ClientFilter";

class ClientInfo extends Component {

    constructor(props) {
		super(props);

        this.state = {
			isClientFilterOpen: false
        }
    }

    handleClientFilterOpen() {
        this.setState({isClientFilterOpen: true})
    }
    handleClientFilterClose() {
        this.setState({isClientFilterOpen: false})
    }

    componentDidMount() {
        const {projectId, influencerId, getInfluencerClients, getClients} = this.props;
        getInfluencerClients(projectId, influencerId);
        getClients(projectId)
    }

    handleClientChange(clients) {
        const {projectId, influencerId,updateInfluencerClients} = this.props;
        updateInfluencerClients(projectId, influencerId, clients)
    }

    render() {

        const {influencers, clientList, getClientListLoaded, clients, getInfluencerClientLoaded, getInfluencerClientLoading, classN} = this.props;

        let {isClientFilterOpen} = this.state;

        let list = [];
        let data = [];
        let ids = []
        let hasData = false;
        if(getClientListLoaded) {
            list = clientList;

        }

        if(getInfluencerClientLoaded) {
            data = clients;
            hasData = !!data.length;
            ids = data.map(d=> d.id);
        }

        return (
            <div className={classN}>
                <div className='inner-title-box'>
                    <p>
                        Clients
                    </p>
                    <ClientFilter
                        clientIds={ids}
                        clientList={list}
                        onClientChanged={(client) => this.handleClientChange(client)}
                        getInfluencerClientLoaded={getInfluencerClientLoaded}
                        hasData={hasData}
                        isClientFilterOpen={isClientFilterOpen}
                        onHandleClientFilterOpen={() => this.handleClientFilterOpen()}
                        onHandleClientFilterClose={() => this.handleClientFilterClose()}
                    />
                </div>   
                {getInfluencerClientLoaded && hasData &&
                <div className='client-box-list'>
                    {
                        data.map((client, index) => {
                            return (<div key={index}  className='client-box'>
                                <div className='client'>
                                <p>{client.name}</p>
                                <span>Client Name</span>
                                </div>
                            </div>)
                        })
                    }
                </div>
                }

                {getInfluencerClientLoaded && !hasData && 
                    <div className='empty-tab-content'>
                        <div className='empty-content'>
                            <h6>Add Your First Client</h6>
                            <p>Clients will appear here</p>
                            <span onClick={() => this.handleClientFilterOpen()}>
                                <i className='icon-lms icon-add'></i>
                                Add Client
                            </span>
                        </div>
                    </div>
                }
                
                {getInfluencerClientLoading && 
                    <div className='loading-box'>
                        <div class="lds-ellipsis"><div></div><div></div><div></div><div></div></div>
                    </div>
                }   
            </div>
        );
    }
}


function mapState(state) {
    const {influencers} = state;
    const {clientList, getClientListLoaded, clients, getInfluencerClientLoaded, getInfluencerClientLoading} = influencers;
    return {influencers, clientList, getClientListLoaded, clients, getInfluencerClientLoaded, getInfluencerClientLoading};
}

const actionCreators = {
    getInfluencerClients: influencerActions.getInfluencerClients,
    getClients: influencerActions.getClients,
    updateInfluencerClients: influencerActions.updateInfluencerClients
};

const connectedViewClientInfo = connect(mapState, actionCreators)(ClientInfo);
export {connectedViewClientInfo as ClientInfo};
