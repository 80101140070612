import React, { useEffect, useRef, useState } from "react";
import { EditorContent, useEditor } from "@tiptap/react";
import StarterKit from "@tiptap/starter-kit";
import Mention from "@tiptap/extension-mention";
import Placeholder from "@tiptap/extension-placeholder";
import suggestion from "../../../Influencer/components/Info/NoteSuggestion";
import NoteEditorBar from "_pages/Leads/components/LeadInfo/NoteEditorBar";
import {useSelector} from "react-redux";
import {utilitiesService as utility} from "_services";

const NoteEditor = ({ confirmButtonName ,editorContent, onCancelUpdate, onConfirmUpdate, setEditorEmpty, isEditorEmpty =false }) => {

  const mentions = useSelector((state) => {

    const {projects} = state;
    const {project, projectLoaded} = projects;

    if(projectLoaded) {

      project.data.owner.account.id = project.data.owner.id.toString();
      let owner =  project.data.owner.account;
      owner.id = project.data.owner.id.toString();

      let members = [owner];

      project.data.members.forEach( m =>
          members.push(m.account)
      );

      return members.map(m => {
        return {id: m.id, label: utility.decrypt(m.first_name) + ' ' + utility.decrypt(m.last_name)}
      });

    } else {
      return [];
    }
  });

  suggestion.items = ({query}) => {
    return mentions.filter(item => item.label.toLowerCase().startsWith(query.toLowerCase())).slice(0, 5);
  }

  let editor = useEditor({
    extensions: [
      StarterKit,
      Mention.configure({
        HTMLAttributes: {
          class: "mention",
        },
        suggestion,
      }),
      Placeholder.configure({
        placeholder: "Create Note Thread...",
      }),
    ],
    autofocus: true,
    content: `${editorContent}`,
  });


  useEffect(() => {
    if (editor) {
      setEditorEmpty(editor.isEmpty);

      // Add event listener to editor's content
      editor.on('update', () => {
        setEditorEmpty(editor.isEmpty);
      });
    }
  }, [editor]);

  const parseMentions = data => {
    const mentions = (data.content || []).flatMap(parseMentions)
    if (data.type === 'mention') {
      mentions.push(parseInt(data.attrs.id))
    }
    return mentions
  }

  const handleCancelUpdate = () => {
    onCancelUpdate(true);
  }

  const mentionClickCreate = () => {
    editor.commands.insertContent(' @');
  }

  const handleConfirmUpdate = () => {
    let mentions = parseMentions(editor.getJSON());
    let content = editor.getHTML();
    onConfirmUpdate(content, mentions);
  }

  const mentionClickReply = () => {
    editor.commands.insertContent(' @');
  }

  return (
    <div className="note-create-box">
      <div className="note-wrapper">
        <div className="custom-basic-editor note-editor">
          <NoteEditorBar editor={editor} />
          <EditorContent editor={editor} className="thread-editor-field" />
          <div className="editor-btns-group note-editor-btns-group">
            <div className="btn-left">
              <span editor={editor} onClick={() => mentionClickCreate()}>
                <i className="fas fa-at"></i>Mention
              </span>
            </div>
            <div className="btn-right">
              <button className="plain-btn cancel-btn" onClick={()=>handleCancelUpdate()}>
                Cancel
              </button>
              <button className={isEditorEmpty ? 'plain-btn disabled' : 'plain-btn'} onClick={()=>handleConfirmUpdate()}>{confirmButtonName}</button>
            </div>
          </div>
        </div>
      </div>
    </div>
  );


};

export default NoteEditor;