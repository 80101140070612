import React from 'react';
import Button from '../Button';
import ClientFilter from './ClientFilter';

const ClientInfo = ({name, email, phone, clientModalAdd, clientModalEdit, confirmationDelete}) => {
    return (
        <>
            <div className='client-info-box box-white-custom'>
                <div className='title-box'>
                 <span>
                    <i className='far fa-list-alt'></i>
                    Client
                 </span>
                 <ClientFilter />
                </div>
                <div className='custom-scroll-box'>
                    <div className='client-box-list'>
                        <div className='clients'>
                            <div className='client'>
                                <label>Client Name:</label>
                                <p>Palawan Pawnshop</p>
                                <button className='delete-record' onClick={confirmationDelete}><i className="fas fa-trash-alt"></i>Remove</button>
                            </div>
                        </div>
                    </div>
                    {/* loader */}
                    <div className="loading-box">
                        <div className='loader-box'>
                            <div class="loader-custom">
                                <span></span>
                                <span></span>
                                <span></span>
                                <span></span>
                                <span></span>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </>
    )
}

export default ClientInfo