import React, { useState } from "react";
import { utilitiesService as utility } from "_services";
import { useDispatch } from "react-redux";
import { useParams } from "react-router-dom";

const LeadEmailThread = ({onHandleToggleComposeEmailDrawer, onHandleToggleEmail, threads}) => {
  // console.log(threads);
  const dispatch = useDispatch();
  const params = useParams();

  // handle open email
  const handleLoadThreadMessages = (thread) => {

    
   // 
    return onHandleToggleEmail(thread)
  };

  const capitalizeName = (name) => {
    const words = name.split(" ");

    for (let i = 0; i < words.length; i++) {
      words[i] = words[i][0].toUpperCase() + words[i].substr(1);
    }

    return words.join(" ");
  };

  const sortedThreads = (threads) => threads .sort (({updated: a}, {updated: b}) => a > b ? -1 : a < b ? 1 : 0)

  const renderEmailThread = () => {
    
    return sortedThreads(threads).map((thread) => {
      const date = new Date(thread.updated).toLocaleString("en-US", {
        timeZone: "Asia/Manila",
      });

      return (
        <div
          key={thread.thread_id}
          className={
            thread.status ? "email-content-box" : "email-content-box read"
          }
          onClick={(event) => handleLoadThreadMessages(thread)}
        >
          <div className="email-name">
            <p>
              {capitalizeName(
                utility
                  .decrypt(thread.email_from)
                  .split("@")[0]
                  .replace(".", " ")
              )}
            </p>
          </div>
          <div className="email-details">
            <p className="email-subject">{utility.decrypt(thread.subject)}</p>
            {/* <span>Hi support, Im wondering if you can help</span> */}
          </div>
          <div className="email-date">
            <p>{date}</p>
          </div>
        </div>
      );
    });
  };

  return (
    <>
      <div className="email-list">{renderEmailThread()}</div>

      {/* <LeadEmailSingle onHadleToggleEmail={hadleToggleEmail} /> */}
      <div className="compose-email-control">
        <button
          className="plain-btn"
          onClick={onHandleToggleComposeEmailDrawer}
        >
          <i className="icon-lms icon-edit"></i>
          Compose Email
        </button>
      </div>
    </>
  );

  // return (
  //   <>
  //     <div className="email-list">
  //       <div className="email-content-box read" onClick={onHadleToggleEmail}>
  //         <div className="email-name">
  //           <p>Abby Mayers</p>
  //         </div>
  //         <div className="email-details">
  //           <p className="email-subject">I forgot my Account</p>
  //           <span>Hi support, Im wondering if you can help</span>
  //         </div>
  //         <div className="email-date">
  //           <p>Jan 3, 2023</p>
  //         </div>
  //       </div>
  //       <div className="email-content-box" onClick={onHadleToggleEmail}>
  //         <div className="email-name">
  //           <p>Abby Mayers</p>
  //         </div>
  //         <div className="email-details">
  //           <p className="email-subject">I forgot my Account</p>
  //           <span>Hi support, Im wondering if you can help</span>
  //         </div>
  //         <div className="email-date">
  //           <p>Jan 3, 2023</p>
  //         </div>
  //       </div>
  //     </div>
  //     <div className="compose-email-control">
  //       <button
  //         className="background-btn"
  //         onClick={onHandleToggleComposeEmailDrawer}
  //       >
  //         <i className="fas fa-pen"></i>
  //         Compose Email
  //       </button>
  //     </div>
  //   </>
  // );
};

export default LeadEmailThread;
