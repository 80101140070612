import { profileConstants } from "_constants";
import { profileService } from "_services";
import { alertActions } from "_actions/alert.actions";
import { userActions } from "./user.actions";
import { history } from "_helpers";

export const profileActions = {
  getProfile,
  updateProfile,
  updatePassword,
  getTemplates,
  createTemplate,
  updateTemplate,
  removeTemplate,
  removeTemplates,
};

function getProfile() {
  return (dispatch) => {
    // dispatch(request());
    profileService.getProfile().then(
      (profile) => {
        dispatch(success(profile));
      },
      (error) => {
        const { response } = error;
        const { data } = response;
        console.log("error: ", error);
        dispatch(
          failure({
            error_code: "PR001",
            error_message: "Profile Not Found",
          })
        );
        dispatch(
          alertActions.error({
            error_code: "PR001",
            error_message: "Profile Not Found",
          })
        );
      }
    );
  };

  function request() {
    return { type: profileConstants.GET_PROFILE_REQUEST };
  }

  function success(profile) {
    return { type: profileConstants.GET_PROFILE_SUCCESS, profile };
  }

  function failure(error) {
    return { type: profileConstants.GET_PROFILE_FAILURE, error };
  }
}

function updateProfile(updateRequest, hasError, toggleError) {
  return (dispatch) => {
    if (!hasError) {
      dispatch(request(updateRequest));

      profileService.updateProfile(updateRequest).then(
        (data) => {
          dispatch(success(data));
          dispatch(alertActions.success("Profile Successfully Updated"));
        },
        (error) => {
          const { response } = error;
          const { data } = response;
          dispatch(
            failure({
              error_code: "PR001",
              error_message: "Profile Not Found",
            })
          );
          dispatch(
            alertActions.error({
              error_code: "PR001",
              error_message: "Profile Not Found",
            })
          );
        }
      );
    } else {
      let data = {};
      console.log("alert error: ", toggleError);
      data.error_message = toggleError;
      dispatch(alertActions.error(data));
    }
  };

  function request() {
    return { type: profileConstants.UPDATE_PROFILE_REQUEST };
  }

  function success(profile) {
    return { type: profileConstants.UPDATE_PROFILE_SUCCESS, profile };
  }

  function failure(error) {
    return { type: profileConstants.UPDATE_PROFILE_FAILURE, error };
  }
}

function updatePassword(passwordRequest, hasError, toggleError) {
  return (dispatch) => {
    if (!hasError) {
      dispatch(request(passwordRequest));

      profileService.updatePassword(passwordRequest).then(
        (data) => {
          dispatch(success(data));
          dispatch(
            alertActions.success(
              "Password successfully updated, you will be redirected to Login page."
            )
          );
          setTimeout(() => {
            dispatch(userActions.logout());
            history.push("/logout");
          }, 2000);
        },
        (error) => {
          // error: "Internal Server Error"
          const { response } = error;
          const { data } = response;
          console.log("error: ", data);
          let errResponse = {};
          if (data.error) {
            errResponse = {
              error_code: "PR002",
              error_message: "Password Not Match",
            };
          } else {
            errResponse = data;
          }

          dispatch(failure(data));
          dispatch(alertActions.error(data));
        }
      );
    } else {
      let data = {};
      console.log("alert error: ", toggleError);
      data.error_message = toggleError;
      dispatch(alertActions.error(data));
    }
  };

  function request() {
    return { type: profileConstants.UPDATE_PROFILE_REQUEST };
  }

  function success(profile) {
    return { type: profileConstants.UPDATE_PROFILE_SUCCESS, profile };
  }

  function failure(error) {
    return { type: profileConstants.UPDATE_PROFILE_FAILURE, error };
  }
}

function getTemplates() {
  return (dispatch) => {
    dispatch(request());

    profileService.getTemplates().then(
      (data) => {
        dispatch(success(data));
      },
      (error) => {
        // error: "Internal Server Error"
        const { response } = error;
        const { data } = response;
        console.log("error: ", data);
        let errResponse = data.error;

        dispatch(failure(data));
        dispatch(alertActions.error(data));
      }
    );
  };

  function request() {
    return { type: profileConstants.GET_TEMPLATES_REQUEST };
  }

  function success(template) {
    return { type: profileConstants.GET_TEMPLATES_SUCCESS, template };
  }

  function failure(error) {
    return { type: profileConstants.GET_TEMPLATES_FAILURE, error };
  }
}

function createTemplate(templateRequest) {
  return (dispatch) => {
    dispatch(request());

      profileService.createTemplate(templateRequest).then(
        (data) => {
          dispatch(success(data));
        },
        (error) => {
          const { response } = error;
          const { data } = response;
          console.log("error: ", data);
          let errResponse = data.error;

          dispatch(failure(errResponse));
          dispatch(alertActions.error(errResponse));
        }
      );
  };

  function request() {
    return { type: profileConstants.GET_TEMPLATES_REQUEST };
  }

  function success(template) {
    return { type: profileConstants.GET_TEMPLATES_SUCCESS, template };
  }

  function failure(error) {
    return { type: profileConstants.GET_TEMPLATES_FAILURE, error };
  }
}

function updateTemplate(templateId, templateRequest) {
  return (dispatch) => {
    dispatch(request());

    profileService.updateTemplate(templateId, templateRequest).then(
      (data) => {
        dispatch(success(data));
      },
      (error) => {
        const { response } = error;
        const { data } = response;
        console.log("error: ", data);
        let errResponse = data.error;

        dispatch(failure(errResponse));
        dispatch(alertActions.error(errResponse));
      }
    );
  };

  function request() {
    return { type: profileConstants.GET_TEMPLATES_REQUEST };
  }

  function success(template) {
    return { type: profileConstants.GET_TEMPLATES_SUCCESS, template };
  }

  function failure(error) {
    return { type: profileConstants.GET_TEMPLATES_FAILURE, error };
  }
}

function removeTemplate(templateId) {
  return (dispatch) => {
    dispatch(request());

    profileService.removeTemplate(templateId).then(
      (data) => {
        dispatch(success(data));
      },
      (error) => {
        const { response } = error;
        const { data } = response;
        console.log("error: ", data);
        let errResponse = data.error;

        dispatch(failure(errResponse));
        dispatch(alertActions.error(errResponse));
      }
    );
  };

  function request() {
    return { type: profileConstants.GET_TEMPLATES_REQUEST };
  }

  function success(template) {
    return { type: profileConstants.GET_TEMPLATES_SUCCESS, template };
  }

  function failure(error) {
    return { type: profileConstants.GET_TEMPLATES_FAILURE, error };
  }
}

function removeTemplates(templateRequest) {
  return (dispatch) => {
    dispatch(request());

    profileService.removeTemplates(templateRequest).then(
      (data) => {
        dispatch(success(data));
      },
      (error) => {
        const { response } = error;
        const { data } = response;
        console.log("error: ", data);
        let errResponse = data.error;

        dispatch(failure(errResponse));
        dispatch(alertActions.error(errResponse));
      }
    );
  };

  function request() {
    return { type: profileConstants.GET_TEMPLATES_REQUEST };
  }

  function success(template) {
    return { type: profileConstants.GET_TEMPLATES_SUCCESS, template };
  }

  function failure(error) {
    return { type: profileConstants.GET_TEMPLATES_FAILURE, error };
  }
}
