import React from "react";
import { connect } from "react-redux";
import { emailActions, formActions } from "_actions";

import { MDBCol, MDBIcon, MDBRow } from "mdbreact";
import InputForm from "_components/InputForm";

import { FormFieldUpdate } from "_pages/Forms/FormFieldUpdate";
import DeleteConfirmationModal from "_components/Modal/DeleteConfirmationModal";

import addIcon from "_assets/images/plus.png";
import TextLoader from "../../_components/Loader/TextLoader";
import LeadComposeEmailDrawer from "../Leads/components/LeadInfo/Email/LeadComposeEmailDrawer";
import { utilitiesService } from "_services";
import EmailModal from "../Leads/components/LeadInfo/Email/EmailModal";

class FormGroupUpdate extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      formGroupData: this.props.formGroupData,
      formIndex: {},
      activeFormIndex: "",
      isExpanded: true,
      dropIcon: "caret-square-up",
      dropdownDiv: true,
      isGroupSelected: false,
      visibleFormGroups: {},
      groupToDelete: "",
      openEmail: false,
      sender:
        this.props.projectId === "5"
          ? "hello@propelrr.com"
          : "careers@propelrr.com",
      //  sender: "rg.lms.stage@gmail.com",
      receiver: "",
      other: false,
      projectId: this.props.projectId,
      activeFormGroup: this.props.activeFormGroup,
      activeFormField: this.props.activeFormField,
      copiedIndex: -1,
      draftEmail: {},
      openSaveDraftModal: false,
    };

    this.handleActiveFormGroup = this.handleActiveFormGroup.bind(this);
  }

  static getDerivedStateFromProps(nextProps, prevState) {
    if (nextProps.formGroupData !== prevState.formGroupData) {
      return { formGroupData: nextProps.formGroupData };
    } else return null;
  }

  getInputIndex = (data) => {
    let edit = "edit";
    this.props.inputIndex(data, edit);
    this.setState({ activeFormIndex: data.formIndex });
  };

  getFormFieldButtons = (value) => {
    this.props.getFormFieldButtons(value);
  };

  deleteInputField = (data) => {
    this.props.getDeleteInputField(data);
  };

  handleAccordionToggle = (formGroupId) => {
    let isExpanded = this.state.isExpanded;

    this.setState({
      isExpanded: !isExpanded,
      dropIcon: isExpanded ? "caret-square-down" : "caret-square-up",
    });
  };

  moveUp = (newIndex, oldIndex) => {
    this.props.moveUp(newIndex, oldIndex);
  };

  moveInputUp = (newIndex, oldIndex, formIndex) => {
    this.props.moveInputUp(newIndex, oldIndex, formIndex);
  };

  getFormGroupButtons = (value) => {
    this.props.getFormGroupButtons(value);
  };

  getEditFormField = (data, formGroupId) => {
    /*console.log('form group edit field', formGroupId)
		console.log('edit field', data)*/
    this.setState({
      fieldData: data,
      sideBar: "editField",
    });
    //this.props.getEditFormField(data, formGroupId);
  };

  /*--------------------------------*/

  handleActiveFormGroup = (activeFormGroup) => {
    this.props.handleActiveFormGroup(activeFormGroup);
  };

  renderFormGroups = (value, index, totalNum) => {
    return this.renderFormFields(value.field);
  };

  handleActiveFormField = (activeFormField) => {
    this.props.handleActiveFormField(activeFormField);
  };

  handleOpenEmail = () => {
    // Your function to handle the click when "email" is present in the content
    // You can replace this with your actual implementation
    if (this.state.projectId == 5 || this.state.projectId == 6) {
      this.setState({ openEmail: true, other: true });
      console.log("Email content clicked");
    }
  };

  renderFormFields = (data) => {
    let hasFormFields = false;
    let totalFormFields = data.length || 0;
    let formGroupData = this.props.formGroupData;

    let isSelectFieldExpanded = this.state.isSelectFieldExpanded;
    let selectedFieldIndex = this.state.selectedFieldIndex;
    let selectedInput = this.props.selectedInput;

    /*console.log('is select field expanded', isSelectFieldExpanded)
		console.log('selected field index', selectedFieldIndex)
		console.log('data', data)*/

    if (typeof data !== "undefined") {
      if (totalFormFields > 0) {
        hasFormFields = true;
        data = data.sort((a, b) => (a.field_id > b.field_id ? 1 : -1));
      }
    }

    return (
      <React.Fragment>
        {hasFormFields ? (
          data.map((value, index) => {
            let website_value = "";

            if (value.sub_type === "website") {
              website_value = value.value;

              if (website_value.includes("https://")) {
                website_value = website_value.slice(8);
              } else if (website_value.includes("http://")) {
                website_value = website_value.slice(7);
              }
            }

            return (
              <div key={index} className="col-md-6">
                <label>
                  {value.label}

                  {value.value && (
                    <button
                      className="copy-btn"
                      onClick={() => {
                        navigator.clipboard.writeText(value.value);
                        this.setState({ copiedIndex: index });
                        setTimeout(() => {
                          this.setState({ copiedIndex: -1 });
                        }, 1000);
                      }}
                    >
                      <svg
                        xmlns="http://www.w3.org/2000/svg"
                        height="13"
                        viewBox="0 -960 960 960"
                        width="13"
                      >
                        <path d="M360-240q-33 0-56.5-23.5T280-320v-480q0-33 23.5-56.5T360-880h360q33 0 56.5 23.5T800-800v480q0 33-23.5 56.5T720-240H360Zm0-80h360v-480H360v480ZM200-80q-33 0-56.5-23.5T120-160v-560h80v560h440v80H200Zm160-240v-480 480Z" />
                      </svg>
                      {this.state.copiedIndex === index && (
                        <span className="toast-text">Copied</span>
                      )}
                    </button>
                  )}

                  {value.verified && <i className="fas fa-check-circle"></i>}
                </label>

                {value.sub_type === null && <span>{value.value}</span>}

                {value.sub_type === "email" && (
                  <span
                    className="link-span"
                    onClick={this.handleOpenEmail}
                    style={{ cursor: "pointer" }}
                  >
                    {value.value}
                  </span>
                )}
                {value.sub_type === "website" && (
                  <a target={`_blank`} href={`//${website_value}`}>
                    {value.value}
                  </a>
                )}
                {value.sub_type === "phone" && (
                  <a target={`_blank`} href={`tel:${value.value}`}>
                    {value.value}
                  </a>
                )}
              </div>
            );
          })
        ) : (
          <MDBCol
            xl="12"
            lg="12"
            className={
              this.state.isExpanded
                ? "px-0 m-t--md m-b--md p-t--sm"
                : "display-none"
            }
          >
            <p className="text-center">No form fields.</p>
          </MDBCol>
        )}
      </React.Fragment>
    );
  };

  handleUpdateLeadField = (index) => {
    this.setState({
      isSelectFieldExpanded: !this.state.isSelectFieldExpanded,
      selectedFieldIndex: index,
    });
  };

  handleCreateFormGroup = (value, index, totalNum) => {
    //let formGroupData = this.props.formGroupData;
    //this.props.handleActiveFormGroup(`formGroup${formGroupData.id}`);
    this.getFormGroupButtons(value);
    sessionStorage.setItem("groupIndex", index);
    sessionStorage.setItem("groupTotalNum", totalNum);
    //
    // console.log('FormGroup handleCreateFormGroup value', value);
    // console.log('FormGroup handleCreateFormGroup index', index);
    // console.log('FormGroup handleCreateFormGroup totalNum', totalNum);
  };

  // handleUpdateLeadField = (index, totalNum) => {
  //
  //   let formData = JSON.parse(sessionStorage.getItem('form'));
  //   let formGroupData = formData.group;
  //
  //   this.props.handleActiveFormGroup('');
  //
  //   if(index <= totalNum){
  //     this.props.handleCreateFormGroupInBetween({
  //       name: 'New Form Group',
  //       layout: '1'
  //     }, formGroupData, index, totalNum);
  //   } else {
  //     this.props.handleCreateFormGroup({
  //       name: 'New Form Group',
  //       layout: '1'
  //     });
  //   }
  //
  //
  // };

  componentDidMount() {
    for (const field of this.props.formGroupData.field) {
      if (field.sub_type != null && field.sub_type === "email") {
        this.setState({ receiver: field.value });
        break; // Exit the loop once we find the desired field
      }
    }
  }

  componentDidUpdate(prevProps) {
    // console.log('previous: ', prevProps);
    if (prevProps.getEmailLoaded != this.props.getEmailLoaded) {
      let getEmailLoaded = this.props.getEmailLoaded;
      if (!getEmailLoaded) {
        this.handleToggleComposeEmailDrawer();
      }
    }
  }

  renderAddFormGroup = (value, index, totalNum) => {
    let isSelectFieldExpanded = this.state.isSelectFieldExpanded;
    let selectedFieldIndex = this.state.selectedFieldIndex;
    let selectedInput = this.props.selectedInput;
    //
    // console.log('FormGroup renderAddFormGroup value', value);
    // console.log('FormGroup renderAddFormGroup index', index);
    // console.log('FormGroup renderAddFormGroup totalNum', totalNum);

    return (
      <div
        className={
          isSelectFieldExpanded && selectedFieldIndex === index
            ? selectedInput
              ? "display-hover"
              : "display-visible"
            : "display-hover"
        }
      >
        <p
          id="add"
          className="font-size-12 mb-0 color-orange"
          onClick={(e) => {
            e.stopPropagation();
            this.handleUpdateLeadField(index, totalNum);
            this.props.handleSelectedInput(false);
          }}
        >
          <img alt="" src={addIcon} className="add-icon-width-18 mr-1" />
          Add Form Group
        </p>
      </div>
    );
  };

  renderFormGroupHeader = (value) => {
    return (
      value.hide_group || (
        <MDBCol xl="12" lg="12" className="preview__header">
          {value.name !== "" ? (
            <label
              htmlFor={`formFields${value.id}`}
              className={`form-group__label ${
                value.hide_group && "color-gray"
              }`}
            >
              {value.name}
            </label>
          ) : (
            <InputForm
              type="text"
              id=""
              name=""
              inputClass="font-weight-600"
              hint="Enter Form Group label here..."
            />
          )}
        </MDBCol>
      )
    );
  };

  handleFormGroupReorder = (data) => {
    let originFormGroup = data.originFormGroup;
    let targetFormGroup = data.targetFormGroup;

    const formData = JSON.parse(sessionStorage.getItem("form"));
    let formGroups = formData.group;

    let searchTargetFormGroup = formGroups.filter((o) => {
      return o.form_order === targetFormGroup.form_order;
    });
    targetFormGroup = searchTargetFormGroup[0];

    if (typeof targetFormGroup !== "undefined") {
      let originOrder = originFormGroup.form_order;
      let targetOrder = targetFormGroup.form_order;

      targetFormGroup = {
        id: targetFormGroup.id,
        form_order: originOrder,
      };

      originFormGroup = {
        id: originFormGroup.id,
        form_order: targetOrder,
      };

      let reorderRequestData = {
        reorder: [originFormGroup, targetFormGroup],
      };

      this.props.handleFormGroupReorder(reorderRequestData);
    }
  };

  renderFormGroupReorder = (value, index, totalNum) => {
    return (
      <MDBRow className="form-group__reorder">
        <MDBIcon
          icon="caret-up"
          className={
            index !== 0 || index + 1 === totalNum
              ? "icon-move--up hand"
              : "icon-move--up disabled color-gray"
          }
          onClick={() => {
            this.handleFormGroupReorder({
              originFormGroup: value,
              targetFormGroup: {
                form_order: value.form_order - 1,
              },
              direction: "up",
            });
          }}
        />
        <MDBIcon
          icon="caret-down"
          className={
            index === value.length - 1 || index + 1 === totalNum
              ? " icon-move--down disabled color-gray"
              : " icon-move--down hand"
          }
          onClick={() => {
            this.handleFormGroupReorder({
              originFormGroup: value,
              targetFormGroup: {
                form_order: value.form_order + 1,
              },
              direction: "down",
            });
          }}
        />
      </MDBRow>
    );
  };

  handleToggle = () => {
    let { modalOpen } = this.state;
    this.setState({ modalOpen: !modalOpen });
  };

  handlePassData = (data) => {
    this.setState({
      groupToDelete: data,
    });
  };

  approveModal = (groupToDelete) => {
    const projectId = this.props.projectId;
    const formId = this.props.formId;
    const formGroupData = this.props.formGroupData;
    let formGroupId = formGroupData.id;

    const deleteFormGroupData = {
      name: groupToDelete.name,
      layout: groupToDelete.layout,
    };

    this.props.deleteFormGroup(
      projectId,
      formId,
      formGroupId,
      deleteFormGroupData
    );

    this.setState({
      formGroupData: {},
      groupToDelete: {},
    });
  };

  renderFormGroupActions = (value, index) => {
    let actionIconClass = this.state.dropdownDiv ? "px-0 action-icons" : "hide";

    return (
      <MDBRow className="form-group__actions">
        <MDBCol className={actionIconClass}>
          <MDBCol className="px-0">
            <MDBIcon
              far
              icon="trash-alt"
              className="icon-delete color-red opacity-5 hand"
              onClick={(event) => {
                event.preventDefault();
                this.handleToggle();
                this.handlePassData(value);
              }}
            />
          </MDBCol>
        </MDBCol>
      </MDBRow>
    );
  };

  handleToggleComposeEmailDrawer = () => {
    this.setState({ openEmail: false, other: false });
  };

  handleSaveDraftEmail = (draftData) => {
    this.setState({ draftEmail: draftData });
  };

  openSaveDraftModal = () => {
    this.setState({ openSaveDraftModal: true, openEmail: false, other: false });
  };

  emailSaveDraft = () => {
    const leadId = this.props.lead.data.id;
    const projectId = this.props.projectId;
    // setIsDraftAlert(true);
    let draftEmail = this.state.draftEmail;

    draftEmail.other = true;

    console.log('request other: ', draftEmail);

    if(draftEmail.id == 0) {
      this.props.saveDraft(projectId, leadId, draftEmail);
    } else {
      this.props.updateDraft(projectId, leadId, draftEmail);
    }

    this.setState({ draftEmail: {}, openSaveDraftModal: false });
  }


  closeSaveDraftModal = () => {
    this.setState({ draftEmail: {}, openSaveDraftModal: false });
  }

  render() {
    let formGroupData = this.state.formGroupData;
    let formGroupKey = this.props.formGroupKey;
    let totalFormGroups = this.props.totalFormGroups;
    let activeFormGroup = this.props.activeFormGroup;
    let hasData = !!formGroupData;
    let formGroupId = `formGroup${formGroupData.id}`;
    let deleted = this.props.deleted;
    let openEmail = this.state.openEmail;
    let isSaveDraftModal = this.state.openSaveDraftModal;
    let draftEmail = this.state.draftEmail;
    let leadEmailDetails = {
      lead_email: utilitiesService.encrypt(this.state.receiver),
      project_email: utilitiesService.encrypt(this.state.sender),
    };

    if (deleted) {
      this.props.handleGetForm();
    }

    return (
      <React.Fragment>
        {hasData
          ? this.renderFormGroups(formGroupData, formGroupKey, totalFormGroups)
          : null}
        <LeadComposeEmailDrawer
          isComposeEmailDrawer={openEmail}
          onHandleToggleComposeEmailDrawer={() =>
            this.handleToggleComposeEmailDrawer()
          }
          leadEmailDetails={leadEmailDetails}
          other={this.state.other}
          saveDraftEmail={this.handleSaveDraftEmail}
          openSaveDraftModal={() => this.openSaveDraftModal()}
          openDraft={draftEmail}
        />
        <EmailModal
          isOpen={isSaveDraftModal}
          emailSaveDraft={() => this.emailSaveDraft()}
          closeSaveDraftModal={() => this.closeSaveDraftModal()}
        />
      </React.Fragment>
    );
  }
}

function mapState(state) {
  const { lead } = state.leads;
  const { email, getEmailLoaded } = state.email;
  const { creating, forms, deleting, deleted } = state.forms;
  return { creating, forms, deleting, deleted, email, getEmailLoaded, lead };
}

const actionCreators = {
  deleteFormGroup: formActions.deleteFormGroup,
  getemails: emailActions.getemails,
  saveDraft: emailActions.saveDraftOther,
  updateDraft: emailActions.updateDraft,
};

const connectedFormGroupUpdate = connect(
  mapState,
  actionCreators
)(FormGroupUpdate);
export { connectedFormGroupUpdate as FormGroupUpdate };
