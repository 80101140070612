import React, {Component} from 'react';
import {influencerActions} from "_actions";
import {connect} from "react-redux";
import Button from "_pages/Influencer/components/General/Button";
import {utilitiesService} from "_services";
import Moment from "react-moment";

class NoteTab extends Component {

    componentDidMount() {
        const {projectId, influencerId, getInfluencerNotes} = this.props;
        getInfluencerNotes(projectId, influencerId);
    }

    openAddNotesModal= () => {
        this.props.onHandleManageNotesModal('add', null);
    }

    openEditNotesModal= (notes) => {
        this.props.onHandleManageNotesModal('edit', notes);
    }

    openDeleteConfirmationModal = (type, platform) => {
        this.props.onHandleOpenConfirmationModal(type, platform);
    }

    render() {

        const { classN,getInfluencerNoteLoaded, getInfluencerNoteLoading, notes } = this.props;

        let noteList = [];
        let hasNoteList = false;

        if (getInfluencerNoteLoaded) {
            noteList = notes;
            hasNoteList = noteList.length ? true : false;
        }



        return (

            <div className={classN}>
                <div className='inner-title-box'>
                    <p>
                        Notes
                    </p>
                    <Button
                        text='Add Note'
                        icon='icon-lms icon-add'
                        onClick={ () => this.openAddNotesModal()}
                        classN="plain-btn"
                    />
                </div>

                {getInfluencerNoteLoaded && hasNoteList &&
                <div className='notes-list'>
                    {
                        noteList.map((note, index) =>{

                            let title = note.title;
                            let content = note.content;
                            let creator = `${utilitiesService.decrypt(note.creator.firstName)} ${utilitiesService.decrypt(note.creator.lastName)}`;
                            let updated = note.updated;
                            let created = note.created;

                            return(<div className='notes'>
                                <div className='note-details'>
                                    <p className='title'>
                                        {title}
                                    </p>
                                    <p className='author'>
                                        {creator}
                                    </p>
                                    <p className='notes-description'>
                                        {content}
                                    </p>
                                    <span>
                                        <React.Fragment>Note added:    <strong><Moment className='' date={created} fromNow ago/> ago</strong></React.Fragment>
                                        {updated && <React.Fragment>• Last Updated:  <strong>  <Moment className='' date={updated} fromNow ago/> ago</strong></React.Fragment>}
                                    </span>
                                </div>
                                <div className='note-btns'>
                                    <button className='c-btn c-btn-sm c-btn-light edit-record' onClick={ () => this.openEditNotesModal(note)}><i className="icon-lms icon-edit"></i>Edit</button>
                                    <button className='c-btn c-btn-sm c-btn-light delete-record' onClick={ () => this.openDeleteConfirmationModal('note', note)}><i className="icon-lms icon-delete"></i>Delete</button>
                                </div>
                            </div>);
                    })}
                </div>
                }

                {getInfluencerNoteLoaded && !hasNoteList &&
                    <div className='empty-tab-content'>
                        <div className='empty-content'>
                            <h6>Add Your First Note</h6>
                        </div>
                    </div>
                }

                {getInfluencerNoteLoading && 
                    <div className='loading-box'>
                        <div class="lds-ellipsis"><div></div><div></div><div></div><div></div></div>
                    </div>
                }
            </div>

        )
    }


}


function mapState(state) {
    const {influencers} = state;
    const {getInfluencerNoteLoaded, getInfluencerNoteLoading, notes} = influencers;
    return {influencers,getInfluencerNoteLoaded,getInfluencerNoteLoading,notes}
}

const actionCreators = {
    getInfluencerNotes: influencerActions.getInfluencerNotes,
};

const connectedViewNoteTab = connect(mapState, actionCreators)(NoteTab);
export {connectedViewNoteTab as NoteTab};
