import { httpManagerService as httpManager } from  '_services';

export const emailService = {
    getLeadEmailConversations,
    updateUnreadEmails,
    agentEmailReply,
    updateThreadMessages,
    agentNewThreadEmail,
    getEmailDrafts,
    saveDraft,
    updateDraft,
    deleteDraft

}


function getLeadEmailConversations(projectId, leadId){
    return httpManager.getHttpWithRequestingToken(`/api/v2/project/${projectId}/lead/${leadId}/email`);
}

function updateUnreadEmails(projectId, leadId, request){
    return httpManager.putJsonOnce(`/api/v2/project/${projectId}/lead/${leadId}/email/unread`, request);
}

function agentEmailReply(projectId, leadId, request){
    return httpManager.postJsonOnce(`/api/v2/project/${projectId}/lead/${leadId}/email/reply`, request);
}

function agentNewThreadEmail(projectId, leadId, request){
    return httpManager.postJsonOnce(`/api/v2/project/${projectId}/lead/${leadId}/email/new`, request);
}

function updateThreadMessages(projectId, leadId, thread){
    return httpManager.putJsonOnce(`/api/v2/project/${projectId}/lead/${leadId}/email/thread?thread=${thread}`);
}

function getEmailDrafts(projectId, leadId){
    return httpManager.getHttpWithRequestingToken(`/api/v2/project/${projectId}/lead/${leadId}/email/draft`);
}

function saveDraft(projectId, leadId, request){
    return httpManager.postJsonOnce(`/api/v2/project/${projectId}/lead/${leadId}/email/draft`, request);
}

function updateDraft(projectId, leadId, request){
    return httpManager.putJsonOnce(`/api/v2/project/${projectId}/lead/${leadId}/email/draft`, request);
}

function deleteDraft(projectId, leadId, draftId){
    return httpManager.deleteJsonOnce(`/api/v2/project/${projectId}/lead/${leadId}/email/draft/${draftId}`);
}

