import React, { Component } from 'react';
import { MDBContainer, MDBRow, MDBCol, MDBIcon } from 'mdbreact';
import check from '_assets/images/check.png'
import { Link } from 'react-router-dom'
import Select from 'react-select';
import { connect } from "react-redux";
import Slider from '_pages/Leads/components/Slider';
import {utilitiesService as utility} from '_services';

import { projectActions, formActions, leadActions } from '_actions';
import { Button, ButtonGroup, Icon } from "blackbox-react";

class LeadMapField extends Component {
  constructor(props) {
    super(props);

    const projectId = this.props.match.params.id;
    const projectData = JSON.parse(sessionStorage.getItem('project'));
    const projectForms = projectData.forms;
    const basicInfo = projectForms.find(item => item.is_default) || {};
    const formId = basicInfo.id || 0;
    this.props.getAllFields(projectId, formId);
    const importData = JSON.parse(sessionStorage.getItem('importData'));
    const fileName = sessionStorage.getItem('fileName');

    this.state = {
      importData: importData,
      options: [
        {
          label: 'First Name',
          value: 'First Name'
        },
        {
          label: 'Last Name',
          value: 'Last Name'
        },
        {
          label: 'Email Address',
          value: 'Email Address'
        },
        {
          label: 'Mobile Number',
          value: 'Mobile Number'
        },
        {
          label: 'Phone Number',
          value: 'Phone Number'
        },
        {
          label: 'Location',
          value: 'Location'
        },
        {
          label: 'Timezone',
          value: 'Timezone'
        },
        {
          label: 'Company',
          value: 'Company'
        },
        {
          label: 'Job',
          value: 'Job'
        }
      ],
      isClearable: true,
      tableData: [
        {
          header: 'First Name',
          fromImport: 'Jane Michelle'
        },
        {
          header: 'Last Name',
          fromImport: 'Rosario'
        },
        {
          header: 'Email Address',
          fromImport: 'jane.michelle.rosario@gmail.com'
        },
        {
          header: 'Mobile Number',
          fromImport: '+639123456789'
        },
        {
          header: 'Phone Number',
          fromImport: '+6321234567'
        },
        {
          header: 'Location',
          fromImport: 'Makati City, Philippines'
        },
        {
          header: 'Timezone',
          fromImport: 'empty'
        },
        {
          header: 'Company',
          fromImport: 'Search Opt Media, Inc.'
        },
        {
          header: 'Job',
          fromImport: 'VP for Finance'
        },
      ],
      mappingComplete: false,
      totalFieldCount: 0,

      importedData: importData,
      fileName: fileName,
      mappedFields: [],
      dataToImport: {},
      isSelected: [],
      basicInfoOptions: [],
      basicInfoOptionsRequired: [],
      basicInfoOptionsTrash: [],
      basicInfoOptionsRequiredSelected: []
    }
  }


  renderSlider = () => {
    return (
      <div>
        <div className='row float-right height-41 ml-1'>
          <div className='row mr-2'>
            <p className='notActive mt-2 mb-0'>Upload CSV</p>
            <p className='border-right-gray mb-0 pr-3'></p>
          </div>
          <div className='mt-2'>
            <p className='mx-2 round mb-0 background-color-dark-orange'><img alt='' src={check} className='check-icon filter-white' /></p>
            <p className='mb-0 line' />
          </div>
        </div>
        <div className='row float-right height-41 ml-1'>
          <div className='row mr-2'>
            <p className='active mt-2 mb-0'>Map Fields</p>
            <p className='border-right-orange mb-0 pr-3'></p>
          </div>
          <div className='mt-2'>
            {/* <p className='mx-2 round mb-0 background-slider-color d-flex'><MDBIcon icon='cogs' className='font-size-9 font-color-dark-orange m-auto' /></p> */}
            <p className='mx-2 round mb-0 background-color-dark-orange'><img alt='' src={check} className='check-icon filter-white' /></p>
            <p className='mb-0 line' />
          </div>
        </div>
        <div className='row float-right height-41'>
          <div className='row mr-2'>
            <p className='notActive mt-2 mb-0'>Import</p>
            <p className='border-right-gray mb-0 pr-3'></p>
          </div>
          <div className='mt-2'>
            <p className='mx-2 round mb-0 background-slider-color'></p>
          </div>
        </div>
      </div>
    )
  };

  handleImportLeads = () => {
    const projectId = this.props.match.params.id;
    const projectData = JSON.parse(sessionStorage.getItem('project'));
    const projectForms = projectData.forms;
    const basicInfo = projectForms.find(item => item.is_default) || {};
    const formId = basicInfo.id || 0;
    const dataToImport = this.state.dataToImport;


    this.props.importLeads(projectId, formId, dataToImport);
  };

  render() {
    const projectId = JSON.parse(sessionStorage.getItem('projectId'));
    let importData = this.state.importData;
    let basicInfo = {};
    let basicInfoOptions = this.state.basicInfoOptions;
    let basicInfoOptionsRequired = this.state.basicInfoOptionsRequired;
    let basicInfoOptionsBackup = [];
    let basicInfoOptionsTrash = this.state.basicInfoOptionsTrash;
    let basicInfoOptionsRequiredSelected = this.state.basicInfoOptionsRequiredSelected;
    let isSelected = this.state.isSelected;

    let { fields } = this.props;
    let formLoaded = this.props.formLoaded;
    // let loading = this.props.loading;
    // let isLoaded = false;

    let csvHeaders = importData[0];
    let previewData = importData[1];
    let mappedFields = this.state.mappedFields;
    // let currentOption = {};
    //
    // let totalFieldCount = this.state.totalFieldCount;
    let mappingComplete = this.state.mappingComplete;

    if(formLoaded && fields.data){
      basicInfo = fields.data;
      basicInfo = basicInfo.sort((a, b) => a.id > b.id);
      basicInfo.map((val, index) => {
        if(index < basicInfo.length){

          let isExistingOptionVal = false;
          basicInfoOptions.map((optionsVal, optionsIndex) => {
            if(val.id === optionsVal.id){
              isExistingOptionVal = true;
            }
            return null;
          });

          if(!isExistingOptionVal){
            basicInfoOptions.push({
              id: val.id,
              value: val.label,
              label: val.label,
              required: val.required
            });
          }

          if(val.required === true){
            let isExistingRequiredVal = false;

            basicInfoOptionsRequired.map((requiredVal, requiredIndex) => {
              if(val.id === requiredVal.id){
                isExistingRequiredVal = true;
              }
              return null;
            });

            if(!isExistingRequiredVal){
              basicInfoOptionsRequired.push({
                id: val.id,
                value: val.label,
                label: val.label,
                required: val.required
              });
            }
          }

          mappedFields.push({
            data_index: index,
            field_id: 0
          });
          mappedFields.length = Math.min(mappedFields.length, basicInfo.length);
          //mappedFields = mappedFields.splice(0, basicInfo.length);
          basicInfoOptionsBackup.push(val);
          isSelected.push(false);
        }

        // if(index+1 === basicInfo.length){
        //   isLoaded = true;

        // }

        return null;
      });

      basicInfoOptions.length = Math.min(basicInfoOptions.length, (basicInfo.length - basicInfoOptionsTrash.length));


    }

    return (
      <MDBContainer fluid className='p--md lead-import'>
        <MDBRow className='m--0'>
          <MDBCol lg='3' xl='2' md='4' className='p-t--0 p-l--0 p-r--sm p-b--sm'>
            <MDBRow className='p--0 m--0'>
              <MDBCol className='p--0'>
                <p className='font-size-12 font-weight-600 font-color-gray mb-0'>LEAD MANAGEMENT</p>
                <p className='font-family-crimson font-size-30 line-height-7 color-orange line-height-6'>Import Leads</p>
              </MDBCol>
            </MDBRow>
            <MDBRow className='m-t--lg p--0'>
              <MDBCol xl='11' lg='9' className='text-center font-size-14 p--0 m--0 m-l--sm'>
                {/*{this.renderSlider()}*/}
                <Slider stageNum='2' activeFormView='mapFields'/>
              </MDBCol>
            </MDBRow>
            <MDBRow className='p-t--md p-l--md p-r--md p-b--0 back-button'>
              <MDBCol>
                <ButtonGroup>
                  <Link to={`/project/${projectId}/leads/import`} className='display-block'>
                    <Button
                      flat='true'
                      background='transparent' foreground='black'
                      borderWidth={2} borderColor='dark' size={-1}
                      styles={{ 'width': '100%', 'opacity': '0.8' }}>
                      <Icon color='black' name='chevron' rotate={180} />
                      <span className='color-black font-weight-bold'>Upload CSV</span>
                    </Button>
                  </Link>
                  <Link to={`/project/${projectId}/leads`} className=''>
                    <Button
                      flat='true'
                      background='transparent' foreground='black'
                      borderWidth={2} borderColor='dark' size={-1}
                      styles={{ 'width': '100%', 'opacity': '0.8' }}>
                      <Icon color='black' name='chevron' rotate={180} />
                      <span className='color-black font-weight-bold'>View All Leads</span>
                    </Button>
                  </Link>
                </ButtonGroup>
              </MDBCol>
            </MDBRow>
          </MDBCol>
          <MDBCol lg='9' xl='10' md='8' className='px-0'>
            <MDBRow className='d-flex'>
              <MDBCol md='3' className='my-auto'>
                <MDBRow className='pl-3'>
                  <p className='mb-0 font-family-crimson font-size-22 my-auto pr-3'>Map Fields</p>
                </MDBRow>
              </MDBCol>
              <MDBCol md='9' className='text-right'>
                <ButtonGroup>
                  {/*<Link to={`/project/${projectId}/leads/import?=download`}>*/}
                  {/*  <Button*/}
                  {/*    flat='true'*/}
                  {/*    background='transparent' foreground='dark'*/}
                  {/*    borderWidth={2} borderColor='dark' size={-2}*/}
                  {/*    onClick={(e) => {}}*/}
                  {/*  >*/}
                  {/*    <MDBIcon icon='download' className='mr-2' />*/}
                  {/*    <span className='color-dark font-weight-bold'>Download Template</span>*/}
                  {/*  </Button>*/}
                  {/*</Link>*/}
                  <Link to={`/project/${projectId}/leads/import/history`}>
                    <Button
                      flat='true'
                      background='transparent' foreground='dark'
                      borderWidth={2} borderColor='dark' size={-2}
                      onClick={(e) => {}}
                    >
                      <MDBIcon icon='columns' className='mr-2' />
                      <span className='color-dark font-weight-bold'>Import History</span>
                    </Button>
                  </Link>
                  <Link to={`/project/${projectId}/leads/import/map`}>
                    <Button
                      flat='true'
                      background='transparent' foreground='dark'
                      borderWidth={2} borderColor='dark' size={-2}
                      onClick={(e) => {window.location.reload()}}
                    >
                      <MDBIcon icon='redo' className='mr-2' />
                      <span className='color-dark font-weight-bold'>Reset Mapping</span>
                    </Button>
                  </Link>
                  { mappingComplete ?
                      <Button
                        flat='true'
                        background='orange' foreground='white'
                        borderWidth={2} borderColor='orange' size={-2}
                        styles={{ 'min-width': '150px'}}
                        onClick={(e) => this.handleImportLeads(e)}
                      >
                        <span className='color-white font-weight-bold'>Import Leads</span>
                      </Button>
                    :
                    <Button
                      flat='true'
                      disabled
                      background='disabled'
                      borderWidth={2} size={-2}
                      styles={{ 'background': 'rgb(227, 232, 233)', 'border-color': 'rgb(227, 232, 233)', 'min-width': '150px'}}
                    >
                      <span className='color-gray font-weight-bold'>Import Leads</span>
                    </Button>
                  }

                </ButtonGroup>
              </MDBCol>
            </MDBRow>
            <MDBCol className='sub-body-container background-color-white background-rectangle m-t--md'>
              <MDBRow className='sub-body-container map-field'>
                <MDBCol md='12' className='px-0 background-color-white border-radius-3'>
                  <MDBRow className='background-color-white list--unstyled list-inline csv__header p-r--0'>
                    <MDBCol md='3' className='font-size-12 p--0 opacity-70 border-table-right'>
                      <div className='p--md'>
                        <span className='font-weight-600'>CSV HEADERS</span>
                      </div>
                    </MDBCol>
                    <MDBCol md='4' className='font-size-12 p--0 opacity-70 border-table-right'>
                      <div className='p--md'>
                        <span className='font-weight-600'>PREVIEW FROM IMPORT</span>
                      </div>
                    </MDBCol>
                    <MDBCol md='4' className='font-size-12 p--0 opacity-70 border-table-right'>
                      <div className='p--md'>
                        <span className='font-weight-600'>LEAD FIELDS</span>
                      </div>
                    </MDBCol>
                    <MDBCol md='1' className='font-size-12 p--0 opacity-70'>
                      <div className='col'>

                      </div>
                    </MDBCol>
                  </MDBRow>
                  <div className="map-field-container overflow-y--scroll">
                    { csvHeaders.map((value, defaultIndex) => {
                      //let currentOption = basicInfo[defaultIndex];

                      return (
                        <MDBRow key={defaultIndex} className={`csv__row ${defaultIndex % 2 === 0 && 'color-light-white'}`}>
                          <MDBCol md='3' className='p--0 border-table-right'>
                            <div className='p--md'>
                              <span className='font-weight-600 font-size-12'>{value}</span>
                            </div>
                          </MDBCol>
                          <MDBCol md='4' className='p--0 border-table-right'>
                            <div className='p--md'>
                              <span className='font-size-12'>{previewData[defaultIndex]}</span>
                            </div>
                          </MDBCol>
                          <MDBCol md='4' className='p--0 border-table-right'>
                            <div className='p--md'>

                                <Select
                                  id='leadMaps'
                                  className='font-size-12'
                                  placeholder=' Select a lead field'
                                  isClearable={true}
                                  options={basicInfoOptions}
                                  //isDisabled={isSelected[defaultIndex]}
                                  onChange={(v) => {
                                    let isRequired = false;

                                    basicInfoOptions.map((basicVal, basicIndex) => {


                                         if(basicVal.id === v.id){
                                        mappedFields[defaultIndex].field_id = v.id;

                                        if(v.required){
                                          isRequired = true;
                                        }
                                      }
                                      return null;
                                    });
                                    mappedFields.map((mappedVal, mappedIndex) => {


                                      return null;
                                    });

                                    if(basicInfoOptionsRequired.length === basicInfoOptionsRequiredSelected.length + 1){
                                      let importData = this.state.importData

                                      let dataToImport = {
                                        file_name: this.state.fileName,
                                        data: utility.encrypt(JSON.stringify(importData)),
                                        mapping: mappedFields
                                      };
                                      this.setState({
                                        mappingComplete: true,
                                        dataToImport: dataToImport
                                      });

                                    }

                                    if(basicInfoOptions.length === 1){
                                      let importData = this.state.importData

                                      let dataToImport = {
                                        file_name: this.state.fileName,
                                        data: utility.encrypt(JSON.stringify(importData)),
                                        mapping: mappedFields
                                      };
                                      this.setState({
                                        mappingComplete: true,
                                        dataToImport: dataToImport
                                      });

                                    }
                                    if(basicInfoOptions.length > 0){
                                      isSelected[defaultIndex] = !isSelected[defaultIndex];
                                      // Remove option from Select options
                                      let basicInfoOptionsTrashed = basicInfoOptions.splice(
                                        basicInfoOptions.findIndex(item =>
                                          item.id === v.id), 1);
                                      let basicInfoOptionsRequiredTemp;
                                      if(isRequired){
                                        // Remove option from Required list
                                        basicInfoOptionsRequiredTemp = basicInfoOptionsRequired.splice(
                                          basicInfoOptionsRequired.findIndex(item =>
                                            item.id === v.id), 1);
                                      }

                                      basicInfoOptionsTrash.push(basicInfoOptionsTrashed);
                                      if(typeof basicInfoOptionsRequiredTemp !== 'undefined'){
                                        basicInfoOptionsRequiredSelected.push(basicInfoOptionsRequiredTemp[0]);
                                      }

                                      basicInfoOptions.length = Math.min(basicInfoOptions.length, (basicInfo.length - basicInfoOptionsTrash.length));

                                      this.setState({
                                        isSelected: isSelected,
                                        mappedFields: mappedFields,
                                        basicInfoOptions: basicInfoOptions,
                                        basicInfoOptionsRequired: basicInfoOptionsRequired,
                                        basicInfoOptionsRequiredSelected: basicInfoOptionsRequiredSelected,
                                        basicInfoOptionsTrash: basicInfoOptionsTrash
                                      });

                                    }


                                    // isSelected[defaultIndex] = !isSelected[defaultIndex];
                                    // this.setState({
                                    //   isSelected: isSelected
                                    // });

                                  }}
                                />

                            </div>
                          </MDBCol>
                          <MDBCol md='1' className='p--0'>
                            { isSelected[defaultIndex] &&
                              <div className='p--md'>
                                <p className='background-color-light-qualified border-default mb-0 round icon-mapped'>
                                  <img alt='' src={check} className='check-icon filter-white' />
                                </p>
                              </div>
                            }
                          </MDBCol>
                        </MDBRow>
                      );
                    }) }
                  </div>
                </MDBCol>
              </MDBRow>
            </MDBCol>
          </MDBCol>
        </MDBRow>
      </MDBContainer>
    );
  }
}

function mapState(state) {
  const { projects, project, forms } = state;
  const { projectLoaded, members, activityLogLoaded, membersLoaded, statusLoaded } = state.projects;
  const { formLoaded, form, fields, loading } = state.forms;
  const { leadLoaded } = state.leads;
  return { projects, project, members, loading, projectLoaded, activityLogLoaded, statusLoaded, forms, membersLoaded, formLoaded, form, leadLoaded, fields };
}

const actionCreators = {
  getProject: projectActions.getProject,
  getForm: formActions.getForm,
  getLeads: leadActions.getLeads,
  getLead: leadActions.getLead,
  importLeads: leadActions.importLeads,
  getAllFields: formActions.getAllFields,
};

const connectedViewLeadMapField = connect(mapState, actionCreators)(LeadMapField);
export { connectedViewLeadMapField as LeadMapField }
