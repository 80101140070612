import React, { Component } from "react";
import ReactDOM from "react-dom";
import { connect } from "react-redux";
import { Link } from "react-router-dom";
import { history } from "_helpers";
import {
  leadActions,
  projectActions,
  chatActions,
  emailActions,
  fileActions,
} from "_actions";
import { utilitiesService as utility } from "_services";
import DeleteConfirmationModal from "_components/Modal/DeleteConfirmationModal";
import Moment from "react-moment";
import TopBar from "../../_components/TopBar";
import ProjectDetails from "../../_components/Drawer/ProjectDetails";
import ProjectMembers from "../../_components/Drawer/ProjectMembers";
import AddLeadDrawer from "./components/Drawer/AddLeadDrawer";
import EditLeadDrawer from "./components/Drawer/EditLeadDrawer";
import { AddLeadNoteModal } from "_pages/Leads/components/AddLeadNoteModal";
import { EditLeadNoteModal } from "_pages/Leads/components/EditLeadNoteModal";
import DeleteLeadNoteModal from "_pages/Leads/components/DeleteLeadNoteModal";
import { LeadStatusDropdown } from "_pages/Leads/components/LeadInfo/LeadStatusDropdown";
import { environment as env } from "_environments/environment";
import loadingGif from "_assets/loading.gif";
import { BreadcrumbPage } from "../../_components/BreadcrumbPage";
import Calendly from "../../_components/Drawer/Calendly";
import TopBarLoader from "../../_components/Loader/TopBarLoader";
import { isTokenFound, onMessageListener, retrieveToken } from "firebase";

import axios from "axios";

import { MDBCol, MDBIcon, MDBRow } from "mdbreact";
import { Button, InputSelect } from "blackbox-react";

import {
  colorRoundStatus,
  leadInfoBtn,
  priorityColor,
  priorityIcon,
  priorityText,
} from "_pages/Leads/constants/BreadCrumbs";

import { FormGroupUpdate } from "_pages/Forms/FormGroupUpdate";

import { LeadViewRecentActivities } from "_pages/Leads/components/LeadInfo/LeadViewRecentActivities";
import ActivitySideButton from "_pages/Leads/components/LeadInfo/ActivitySideButton";
import LeadInfoBreadCrumbs from "_pages/Leads/components/LeadInfo/LeadInfoBreadCrumbs";
import LeadViewInfoForms from "_pages/Leads/components/LeadInfo/LeadViewInfoForms";
import { LeadViewNotes } from "_pages/Leads/components/LeadInfo/LeadViewNotes";
import LeadViewInfoTask from "_pages/Leads/components/LeadInfo/LeadViewInfoTask";
import avatar from "_assets/images/avatar-lg.png";
import LeadSession from "_pages/Leads/components/LeadInfo/LeadSession";
import LeadInterest from "_pages/Leads/components/LeadInfo/LeadInterest";
import LeadChat from "_pages/Leads/components/LeadInfo/LeadChat";
import LeadEmail from "_pages/Leads/components/LeadInfo/LeadEmail";
import { LeadResponsibleDropdown } from "_pages/Leads/components/LeadInfo/LeadResponsibleDropdown";
import { LeadPriorityDropdown } from "_pages/Leads/components/LeadInfo/LeadPriorityDropdown";
import LeadViewNotesNew from "_pages/Leads/components/LeadInfo/LeadViewNotesNew";
import TextLoader from "../../_components/Loader/TextLoader";
import SideInfoLoader from "../../_components/Loader/SideInfoLoader";
import SideInfoButtonLoader from "../../_components/Loader/SideInfoButtonLoader";
import MainInfoLoader from "../../_components/Loader/MainInfoLoader";
import AgentInfoLoader from "../../_components/Loader/AgentInfoLoader";
import ButtonGroupLoader from "../../_components/Loader/ButtonGroupLoader";
import { connectableObservableDescriptor } from "rxjs/internal/observable/ConnectableObservable";
import { LeadFile } from "./components/LeadInfo/LeadFile";
import { LeadFileManageModal } from "./components/LeadInfo/LeadFileManageModal";
import { ConfirmationDeleteModal } from "../Influencer/components/Modals/ConfirmationDeleteModal";
import { DeleteLeadFileModal } from "./components/DeleteLeadFileModal";

class LeadInfo extends Component {
  constructor(props) {
    super(props);

    const { match } = this.props;
    const projectId = match.params.id;
    const leadId = match.params.leadId;


    this.state = {
      leadInfoBtn,
      isActiveSideBtn: "Activity",
      projectData: {},
      priorityColor,
      priorityIcon,
      leadData: {},
      priorityText,
      colorRoundStatus,
      leadDetails: [],
      statusToDelete: {},
      activeDropdown: "",
      deleteLeadModalOpen: false,
      deleteLeadNoteModalOpen: false,
      addLeadNoteModalOpen: false,
      editLeadNoteModalOpen: false,
      isSelectFieldExpanded: false,
      isAgentChanged: false,
      isLoading: false,
      selectedInput: "",
      selectedOwner: "",
      selectedResponsible: "",
      selectedPriority: "",
      activityLog: [],
      leadNotes: [],
      noteToEdit: {},
      selectOptions: [],
      availableAgents: [],
      selectedAgentValue: {},
      assignedAgentId: "UNASSIGNED",
      filterToggled: false,
      isProjectDetailsDrawer: false,
      isProjectMembersDrawer: false,
      isAddLeadDrawer: false,
      isEditLeadDrawer: false,
      isCalendlyDrawer: false,
      selectedNote: {},
      unreadChats: [],
      isManageFileModalOpen: false,
      manageFileMode: 'Add',
      manageFileData: {},
      deleteConfirmationType: '',
      deleteConfirmationData: {},
      isDeleteConfirmationModalOpen: false,
    };

    this.filterRef = React.createRef();
  }

  // filter dropdown
  handleOpenFilter() {
    this.setState({ filterToggled: true });
  }

  handleCloseFilter() {
    this.setState({ filterToggled: false });
  }

  handleDropdown = (value) => {
    if (!this.filterRef.current.contains(value.target)) {
      this.setState({ filterToggled: false });
    }
  };

  componentDidMount() {
    const {
      match,
      getProject,
      getAllProjectMembers,
      getLeadSummary,
      getLead,
      getActivityLog,
      getLeadNotes,
      getLeadSession,
      getLeadInterest,
      getLeadChat,
      getLeadEmail,
      getLeadFiles
    } = this.props;

    const projectId = match.params.id;
    const leadId = match.params.leadId;

    if (parseInt(projectId) === 1) {
      this.getOptions();
    }

    getProject(projectId);
    getAllProjectMembers(projectId);
    getLeadSummary(projectId);
    getLead(projectId, leadId);
    getActivityLog(projectId, leadId);
    getLeadNotes(projectId, leadId, "note");
    getActivityLog(projectId, leadId);

    getLeadSession(projectId, leadId);
    getLeadInterest(projectId, leadId);
    getLeadChat(projectId, leadId);
    getLeadEmail(projectId, leadId);
    getLeadFiles(projectId, leadId);
  }

  componentDidUpdate(prevProps) {  
    if(prevProps.getEmailLoaded != this.props.getEmailLoaded) {
      let getEmailLoaded = this.props.getEmailLoaded;
      let email = this.props.email;
      if(getEmailLoaded && email) {
        this.setState({ isActiveSideBtn: 'Email' });
      }
    }
    
  }

  async getOptions() {
    const { match } = this.props;
    const res = await axios.get(
      `https://primaryhomes.com/api/agents/available?lead=${match.params.leadId}`
    );
    // const res = await axios.get(`https://phiweb.primary.com.ph/api/agents/available?lead=${match.params.leadId}`);
    const data = res.data;

    const options = data.map((d) => ({
      value: d.agent_id,
      label: d.first_name + " " + d.last_name,
    }));

    options.unshift({
      value: "UNASSIGNED",
      label: "UNASSIGNED",
    });

    this.setState({ selectOptions: options });
    this.setState({ availableAgents: data });
  }

  handleGetLead = () => {
    const { match, getLead, getActivityLog, getLeadNotes, getLeadSession } =
      this.props;

    const projectId = match.params.id;
    const leadId = match.params.leadId;

    getLead(projectId, leadId);
    getActivityLog(projectId, leadId);

    getLeadSession(projectId, leadId);
    getLeadNotes(projectId, leadId, "note");

    this.setState({
      deleteLeadModalOpen: false,
      isSelectFieldExpanded: false,
      deleteLeadNoteModalOpen: false,
      statusToDelete: {},
      isLoading: false,
    });
  };

  handleUnreadMessagesCount = (chats, getChatsLoaded) => {
    let unreadMessages = [];
    const { match} = this.props;
  
    if (chats && (chats.lead_id == match.params.leadId)) {
      if (
        getChatsLoaded &&
        chats.chat_history.some((c) => c.status === "received")
      ) {
        const userId = chats.fb_user_id;
        const chatHistory = chats.chat_history;

        unreadMessages = chatHistory.filter((chat) => {
          return chat.sender_id === userId && chat.status === "received";
        });

        // console.log('unread messages: ', unreadMessages)
      }
    }

    return unreadMessages.length;
  };

  handleUnreadEmailCount = (email, getEmailLoaded) => {
    let unreadMessages = [];
    const { match} = this.props;

    if (email && (email.lead_id == match.params.leadId)) {
      if (
        getEmailLoaded &&
        email.email_threads.some((c) => c.status === true)
      ) {
        const userId = email.lead_email;
        const emailHistory = email.email_threads;

        unreadMessages = emailHistory.filter((thread) => {return thread.status === true;})
        .map((t) => {return t.thread_messages})
        .reduce((prev, next) => {return prev.concat(next)})
        .filter((tm) => {return tm.status === 1});
      }
    }

   // console.log('unread messages: ', unreadMessages)
    return unreadMessages.length;
  };

  handleGetChat() {
    const { match, getLeadChat } = this.props;

    const projectId = match.params.id;
    const leadId = match.params.leadId;

    getLeadChat(projectId, leadId);
  }

  handleGetEmail() {
    console.log("get updated email lead...", this.props);
    const { match, getLeadEmail } = this.props;

    const projectId = match.params.id;
    const leadId = match.params.leadId;

    getLeadEmail(projectId, leadId);
  }

  handleGetNotes = () => {
    const { match, getLead, getActivityLog, getLeadNotes } = this.props;
    const projectId = match.params.id;
    const leadId = match.params.leadId;

    getLeadNotes(projectId, leadId, "note");
    getActivityLog(projectId, leadId);

    this.setState({
      deleteLeadModalOpen: false,
      isSelectFieldExpanded: false,
      deleteLeadNoteModalOpen: false,
      statusToDelete: {},
      isLoading: false,
    });
  };

  handleTextEllipsis(text, max) {
    return text.substr(0, max) + (text.length > max ? "..." : "");
  }

  handleSideActivityButton = (name) => {
    console.log('side: ' + name)
    this.setState({ isActiveSideBtn: name });

    if (name === "Chat") {
      this.handleGetChat();
    }

    if (name === "Email") {
      this.handleGetEmail();
    }
  };

  toggleDeleteLead = () => {
    let { deleteLeadModalOpen } = this.state;
    this.setState({ deleteLeadModalOpen: !deleteLeadModalOpen });
  };

  toggleDeleteLeadNote = (note) => {
    let deleteLeadNoteModalOpen = this.state.deleteLeadNoteModalOpen;
    this.handlePassData(note);
    this.setState({
      deleteLeadNoteModalOpen: !deleteLeadNoteModalOpen,
    });
  };

  toggleAddLeadNote = () => {
    let { addLeadNoteModalOpen } = this.state;
    this.setState({ addLeadNoteModalOpen: !addLeadNoteModalOpen });
  };

  toggleEditLeadNote = () => {
    let { editLeadNoteModalOpen } = this.state;
    this.setState({
      editLeadNoteModalOpen: !editLeadNoteModalOpen,
    });
  };

  handleEditLeadNote = (leadNote) => {
    this.setState({
      noteToEdit: leadNote ? leadNote : {},
    });
  };

  handleDisplayActivity = (
    lead,
    activityLog,
    leadNotes,
    leadInterest,
    leadChats,
    getChatsLoaded,
    leadEmails,
    getEmailLoaded,
    getChatNotification,
    leadFiles,
    getFileLoaded

  ) => {
    const { match } = this.props;
    const projectId = match.params.id;

    let { isActiveSideBtn } = this.state;
    switch (isActiveSideBtn) {
      case "Activity":
        return <LeadViewRecentActivities leadData={lead} />;
      case "Source":
        return (
          <LeadViewInfoForms
            leadFormsDetails={[]}
            leadData={lead}
            projectId={projectId}
          />
        );
      case "Sessions":
        return <LeadSession />;
      case "Interest":
        return <LeadInterest />;
      case "Tasks":
        return <LeadViewInfoTask />;
      case "Notes":
        return (
          <React.Fragment>
            <LeadViewNotesNew
              toggleAddLeadNote={this.toggleAddLeadNote}
              toggleEditLeadNote={this.toggleEditLeadNote}
              toggleDeleteLeadNote={this.toggleDeleteLeadNote}
              handleEditLeadNote={this.handleEditLeadNote}
              leadNotes={leadNotes}
              handleDeleteLeadNote={this.handleDeleteLeadNote}
            />
          </React.Fragment>
        );
      case "Chat":
        return (
          <LeadChat
            leadChats={leadChats}
            chatLoaded={getChatsLoaded}
            chatNotif={getChatNotification}
            lead={lead}
          />
        );
      case "Email":
        return (
          <LeadEmail
            leadEmailDetails={leadEmails}
            emailLoaded={getEmailLoaded}
            lead={lead}
          />
        );
      case "Document":
        return (
          <LeadFile
            classN={isActiveSideBtn === 'Document' ? 'tab-content-box records-box active' : 'tab-content-box records-box'}
            leadFiles={leadFiles}
            fileLoaded={getFileLoaded}
            lead={lead}
            projectId={projectId}
            onHandleManageFileModal={(mode, data) => this.handleOpenManageFileModal(mode, data)}
            onHandleOpenConfirmationModal={(type, data) => this.handleOpenConfirmDeleteModal(type, data)}
          />
        );
      default:
        break;
    }
  };

  handleGetIdentifier(identifier, identifierType, leadData) {
    let identifierVal = "";

    if (identifierType === "Form Group") {
      leadData.map((val) => {
        if (val.group_id === identifier) {
          identifierVal = identifierVal + " " + utility.decrypt(val.value);
        }
        return null;
      });
    } else if (identifierType === "Group Field") {
      leadData.map((val) => {
        if (val.field_id === identifier) {
          identifierVal = utility.decrypt(val.value);
        }
        return null;
      });
    }

    return identifierVal;
  }

  renderAvatar = (leadDetails) => {
    let identifierValue = this.handleGetIdentifier(
      leadDetails.identifier,
      leadDetails.identifier_type,
      leadDetails.basic_info
    );
    return (
      <div className="px-0">
        <img src={avatar} alt="" className="lead-profile" />
        <p className="m-t--md m-b--0 font-size-25 font-weight-600 p--0 line-height-normal">
          {identifierValue}
        </p>
      </div>
    );
  };

  handleUpdateLeadPriority = (selectedPriority) => {
    const { match, updateLead } = this.props;

    const projectId = match.params.id;
    const leadId = match.params.leadId;

    const user = JSON.parse(sessionStorage.getItem("user"));
    const userEmail = user && user.data.email;
    updateLead(projectId, leadId, {
      priority: selectedPriority,
      sender: userEmail,
    });
  };

  renderPriority = (leadDetails) => {
    const { leadLoaded } = this.props;

    return (
      <div className="avatar-text-box">
        <div className="text">
          <label>PRIORITY</label>
          {leadLoaded && (
            <LeadPriorityDropdown
              lead={leadDetails}
              onHandleUpdateLeadPriority={this.handleUpdateLeadPriority}
            />
          )}

          {!leadLoaded && <TextLoader />}
        </div>
      </div>
    );
  };

  renderStatus = (leadDetails) => {
    const { leadLoaded } = this.props;
    return (
      <React.Fragment>
        <div className="avatar-text-box">
          <div className="text">
            <label>STATUS</label>
            {leadLoaded && (
              <LeadStatusDropdown
                lead={leadDetails}
                onHandleUpdateLeadStatus={this.handleUpdateLeadStatus}
              />
            )}
            {!leadLoaded && <TextLoader />}
          </div>
        </div>
      </React.Fragment>
    );
  };

  renderLeadScore = () => {
    const { leadLoaded } = this.props;
    return (
      <React.Fragment>
        <div className="avatar-text-box">
          <div className="text ls-text">
            <label>Lead Score</label>
            {leadLoaded && (
              <span className="ls-c ls-color-1">
                100 <i>?</i>
                <div className="ls-info">
                  Lorem ipsum dolor sit amet consectetur, adipisicing elit.
                  Animi, molestias! Fuga ducimus quo minima illum perferendis,
                  libero accusamus distinctio repellendus rerum minus dicta
                  atque consectetur quam sint quaerat neque sequi.
                </div>
              </span>
            )}
            {!leadLoaded && <TextLoader />}
          </div>
        </div>
      </React.Fragment>
    );
  };

  handleEditField = (e, fieldName) => {
    this.setState({
      selectedInput: fieldName,
      isSelectFieldExpanded: true,
    });
  };

  handleUpdateLeadOwner = (selectedOwner) => {
    const projectId = sessionStorage.getItem("projectId");
    const leadId = this.props.match.params.id;
    const user = JSON.parse(sessionStorage.getItem("user"));
    const userEmail = user && user.data.email;

    this.props.updateLead(projectId, leadId, {
      owner: selectedOwner,
      sender: userEmail,
    });

    this.handleGetLead();
  };

  handleUpdateLeadResponsible = (selectedResponsible) => {
    const { match, updateLead } = this.props;

    const projectId = match.params.id;
    const leadId = match.params.leadId;

    const user = JSON.parse(sessionStorage.getItem("user"));
    const userEmail = user && user.data.email;

    updateLead(projectId, leadId, {
      responsible: selectedResponsible,
      sender: userEmail,
    });

    // this.handleGetLead();
  };

  renderOwnerResponsible = (leadDetails) => {
    const { leadLoaded, membersLoaded } = this.props;

    return (
      <React.Fragment>
        <div className="col-box">
          <div className="avatar-text-box">
            <div className="text">
              <label>Generated From</label>
              {leadLoaded && <span>{leadDetails.origin}</span>}
              {!leadLoaded && <TextLoader />}
            </div>
          </div>
        </div>
        <div className="col-box">
          <div className="avatar-text-box">
            <div className="text">
              <label>ASSIGNED TO</label>
              {leadLoaded && membersLoaded && (
                <LeadResponsibleDropdown
                  lead={leadDetails}
                  onHandleUpdateLeadResponsible={
                    this.handleUpdateLeadResponsible
                  }
                />
              )}

              {(!leadLoaded || !membersLoaded) && <TextLoader />}
            </div>
          </div>
        </div>
      </React.Fragment>
    );
  };

  renderLastDates = (leadDetails) => {
    return (
      <MDBRow className="last-dates">
        <MDBCol md="4" className="">
          <div className="last-date">
            <p>Last Seen</p>
            <strong>
              <Moment date={leadDetails.created} fromNow ago /> ago
            </strong>
          </div>
        </MDBCol>
        <MDBCol md="12" className="p-r--0">
          <div className="last-date">
            <p>Last Modified</p>
            {leadDetails.updated ? (
              <strong>
                <Moment date={leadDetails.updated} fromNow ago /> ago
              </strong>
            ) : (
              <strong>Never</strong>
            )}
          </div>
        </MDBCol>
      </MDBRow>
    );
  };

  renderSidebar = (leadDetails) => {
    //const projectId = sessionStorage.getItem('projectId');
    return (
      <React.Fragment>
        <MDBRow>
          {this.renderOwnerResponsible(leadDetails)}
          <div className="col-box">{this.renderPriority(leadDetails)}</div>
          <div className="col-box">{this.renderStatus(leadDetails)}</div>

          {/*<div className='col-box'>*/}
          {/*    {this.renderLeadScore()}*/}
          {/*</div>*/}
        </MDBRow>
      </React.Fragment>
    );
  };

  handleUpdate = (keyValue, keyId) => {
    const projectId = sessionStorage.getItem("projectId");
    const leadId = this.props.match.params.id;
    const user = JSON.parse(sessionStorage.getItem("user"));
    const userEmail = user && user.data.email;

    this.props.updateLead(projectId, leadId, {
      form_lead: [
        {
          key: keyId,
          value: keyValue,
        },
      ],
      sender: userEmail,
    });

    this.handleGetLead();
  };

  renderAgentInfo = () => {
    return (
      <div>
        {this.state.selectedAgentValue !== undefined && (
          <div
            className="agentInformation"
            style={{
              display:
                Object.keys(this.state.selectedAgentValue).length > 0
                  ? "block"
                  : "none",
            }}
          >
            <div
              className="input-wrap p--sm"
              style={{ paddingTop: "0px !important" }}
            >
              <label className="m--0">Agent Id</label>
              <div>
                <span className="font-size-14">
                  {this.state.selectedAgentValue.agent_id}
                </span>
              </div>
            </div>
            <div className="input-wrap p--sm">
              <label className="m--0">Agent Name</label>
              <div>
                <span className="font-size-14">
                  {this.state.selectedAgentValue.first_name}{" "}
                  {this.state.selectedAgentValue.last_name}
                </span>
              </div>
            </div>
            <div className="input-wrap p--sm">
              <label className="m--0">Agent Contact</label>
              <div>
                <span className="font-size-14">
                  {this.state.selectedAgentValue.contact}
                </span>
              </div>
            </div>
            <div className="input-wrap p--sm">
              <label className="m--0">Agent Email</label>
              <div>
                <span className="font-size-14">
                  {this.state.selectedAgentValue.email}
                </span>
              </div>
            </div>
          </div>
        )}
      </div>
    );
  };

  renderFormGroups = (data) => {
    let totalFormGroups = data.length || 0;
    let hasFormGroups = totalFormGroups > 0;

    if (typeof data !== "undefined") {
      data = data.sort((a, b) => (a.form_order < b.form_order ? -1 : 1));
    }

    return (
      <>
        <div
          className="row info-group"
          onClick={(e) => {
            e.stopPropagation();
            this.setState({
              activeFormGroup: "",
              activeFormField: "",
              activeSideBar: "select",
            });
          }}
        >
          {hasFormGroups &&
            data.map((value, index) => {
              //let formGroupId = value.group_id;
              return (
                <FormGroupUpdate
                  key={index}
                  formGroupData={value}
                  formGroupId={value.group_id}
                  formGroupKey={index}
                  getEditFormGroup={this.getEditFormGroup}
                  getEditFormField={this.getEditFormField}
                  getFormGroupButtons={this.getFormGroupButtons}
                  getFormFieldButtons={this.getFormFieldButtons}
                  totalFormGroups={totalFormGroups}
                  handleUpdate={this.handleUpdate}
                  handleActiveFormGroup={this.handleActiveFormGroup}
                  handleActiveFormField={this.handleActiveFormField}
                  activeFormGroup={this.state.activeFormGroup}
                  handleCreateFormGroup={this.handleCreateFormGroup}
                  handleCreateFormGroupInBetween={
                    this.handleCreateFormGroupInBetween
                  }
                  handleFormGroupReorder={this.handleFormGroupReorder}
                  handleFormFieldReorder={this.handleFormFieldReorder}
                  activeFormField={this.state.activeFormField}
                  projectId={sessionStorage.getItem("projectId")}
                  formId={this.props.match.params.id}
                  dataToAppend={this.dataAppend}
                  handleGetForm={this.handleGetForm}
                  handleSelectedInput={this.handleSelectedInput}
                  selectedInput={this.state.selectedInput}
                />
              );
            })}
        </div>
      </>
    );
  };

  handleDeleteLead = () => {
    let leadData = this.props.leadData;
    let leadDetails = leadData && this.props.leadData.data;
    this.toggleDeleteLead();
    this.handlePassData(leadDetails);
  };

  handleDeleteLeadNote = (leadNote) => {
    // this.toggleDeleteNote();

    this.toggleDeleteLeadNote();
    this.handlePassData(leadNote);
  };

  approveModal = (statusToDelete) => {

    const { match, deleteLead, deleteLeadNote } = this.props;
    const projectId = match.params.id;
    const leadId = match.params.leadId;

    let leadData = this.props.leads;
    let leadDetails = leadData && this.props.lead.data;
    let leadNote = this.state.statusToDelete;
    const user = JSON.parse(sessionStorage.getItem("user"));
    const userEmail = user && user.data.email;
    let contentId = leadNote ? leadNote.id : 0;
    let typeName = "note";

    let leadDataId = {
      leadId: leadId,
    };

    if (parseInt(statusToDelete) === parseInt(leadDetails.id)) {
      console.log("triggered");
      deleteLead(projectId, leadId, leadDataId);
      
    } else {
      leadNote = {
        name: leadNote.name,
        sender: userEmail,
      };
      deleteLeadNote(projectId, leadId, typeName, contentId, leadNote);
      this.setState({
        deleteLeadNoteModalOpen: false,
        statusToDelete: {},
      });
    }
  };

  handlePassData = (data) => {
    this.setState({
      statusToDelete: data,
      memberToDelete: data,
    });
  };

  handleTimeConvert = (value) => {
    let time = value
      .toString()
      .match(/^([01]\d|2[0-3])(:)([0-5]\d)(:[0-5]\d)?$/) || [value];

    if (time.length > 1) {
      time = time.slice(1); // Remove full string match value
      // time = time.slice(3);

      time[5] = time[0] < 12 ? "AM" : "PM";
      time[0] = +time[0] % 12 || 12; // Adjust hours

      return time.join("");
    } else if (time[0]) {
      return time + " AM";
    } else {
      return time;
    }
  };

  cancelaAgent = () => {
    this.setState({
      selectedAgentValue: "",
      selectedInput: "",
      isSelectFieldExpanded: false,
      isAgentChanged: false,
    });

    // this.setState({
    //     isAgentChanged: false
    // })
  };

  handleOpenEmail = () => {
    // Your function to handle the click when "email" is present in the content
    // You can replace this with your actual implementation
    // console.log('Email content clicked');
  }

  // Lead Details
  renderBasicInfo = (leadData) => {
    let { leadLoaded, membersLoaded } = this.props;

    let basicInfo = [];

    let cancelViewingStatus = false;

    if (leadLoaded) {
      basicInfo = leadData.basic_info;
      basicInfo = basicInfo.sort((a, b) => (a.id > b.id ? 1 : -1));
      cancelViewingStatus = leadData.project_status.id !== 15;
    }

    let hasAgent = false;

    const projectId = sessionStorage.getItem("projectId");

    let initialAgentFilter = basicInfo.filter((filteredField) => {
      hasAgent = true;
      return filteredField.field_id === 15;
    });

    const propertyInfo = basicInfo.filter((filteredField) => {
      return filteredField.field_id > 22 && filteredField.field_id < 31;
    });

    const agentStaticInfo = basicInfo.filter((filteredField) => {
      return filteredField.field_id > 14 && filteredField.field_id < 19;
    });

    const leadInitialInfo = basicInfo.filter((filteredField) => {
      return filteredField.field_id > 0 && filteredField.field_id < 15;
    });

    const leadMessageRaw = basicInfo.filter((filteredField) => {
      return filteredField.field_id === 19;
    });

    let leadMessage = null;

    if (leadMessageRaw.length > 0) {
      leadMessage = leadMessageRaw[0];
    }

    const leadTypeInfo = basicInfo.filter((filteredField) => {
      return filteredField.field_id === 57;
    });

    let leadType = null;
    let isLabelContainingEmail = false;

    if (leadTypeInfo.length > 0) {
      leadType = leadTypeInfo[0];
   //   console.log('label: ', leadType.label)
      isLabelContainingEmail = leadType.label.toLowerCase().includes('email');
    }

    let leadInfoForm = {
      group: [],
    };

    let initialAgentSelected =
      initialAgentFilter.length > 0
        ? initialAgentFilter[0].value !== null
          ? utility.decrypt(initialAgentFilter[0].value)
          : "Unassigned"
        : "Unassigned";

    basicInfo.map((basicVal) => {
      if (leadInfoForm.group.length === 0) {
        leadInfoForm.group.push({
          group_id: basicVal.group_id,
          field: [
            {
              field_id: basicVal.field_id,
              label: basicVal.label,
              type_id: basicVal.type_id,
              value: utility.decrypt(basicVal.value),
              sub_type: basicVal.sub_type,
              verified: basicVal.verify_type === "email" && leadData.verified,
            },
          ],
        });
      } else if (leadInfoForm.group.length > 0) {
        leadInfoForm.group.map((leadVal, index) => {
          if (leadVal.group_id === basicVal.group_id) {
            leadInfoForm.group[index].field.push({
              field_id: basicVal.field_id,
              label: basicVal.label,
              type_id: basicVal.type_id,
              value:
                basicVal.value !== null ? utility.decrypt(basicVal.value) : "",
              sub_type: basicVal.sub_type,
              verified: basicVal.verify_type === "email" && leadData.verified,
            });
          }
          return null;
        });

        const found = leadInfoForm.group.some(
          (el) => el.group_id === basicVal.group_id
        );
        if (!found) {
          leadInfoForm.group.push({
            group_id: basicVal.group_id,
            field: [
              {
                field_id: basicVal.field_id,
                label: basicVal.label,
                type_id: basicVal.type_id,
                value:
                  basicVal.value !== null
                    ? utility.decrypt(basicVal.value)
                    : "",
                sub_type: basicVal.sub_type,
                verified: basicVal.verify_type === "email" && leadData.verified,
              },
            ],
          });
        }
      }
      return null;
    });
    return (
      <React.Fragment>
        <div className={projectId == 1 ? `information-box w-agent-box` : `information-box`}>
          <div className={projectId == 5 || projectId == 6 ? 'basic-info-box box-white-custom custom-info-box' : 'basic-info-box box-white-custom'}>
            <div className="title-box">
              <i className="icon-lms icon-user"></i>
              <span>
                Lead Information
              </span>
            </div>

            <div className="custom-scroll-box">
              {leadLoaded ? (
                <React.Fragment>
                  {projectId == 1 ? (
                    <div className="row info-group">
                      {leadType && (
                        <div className="col-md-6">
                          <label>{leadType.label}</label>
                          <span>
                            {leadType.value.length > 0
                              ? utility.decrypt(leadType.value)
                              : ""}
                          </span>
                        </div>
                      )}
                      {propertyInfo.map((data) => {
                        if (data.field_id === 29) {
                          return (
                            <div className="col-md-6" key={data.id}>
                              <label>{data.label}</label>
                              <span>
                                {this.handleTimeConvert(
                                  utility.decrypt(data.value)
                                )}
                              </span>
                            </div>
                          );
                        } else if (data.field_id === 28) {
                          return (
                            <div className="col-md-6" key={data.id}>
                              <label>{data.label}</label>
                              <span>
                                <Moment
                                  date={utility.decrypt(data.value)}
                                  format="LL"
                                />
                              </span>
                            </div>
                          );
                        } else {
                          return (
                            <div className="col-md-6" key={data.id}>
                              <label>{data.label}</label>
                              <span>{utility.decrypt(data.value)}</span>
                            </div>
                          );
                        }
                      })}
                      {leadMessage && (
                        <div className="col-md-6">
                          <label>{leadMessage.label}</label>
                          <span>
                            {leadMessage.value.length > 0
                              ? utility.decrypt(leadMessage.value)
                              : ""}
                          </span>
                        </div>
                      )}

                      {leadInitialInfo.map((data) => {
                        return (
                          <div className="col-md-6" key={data.id}>
                            <label>{data.label}</label>
                            <span>{utility.decrypt(data.value)}</span>
                          </div>
                        );
                      })}
                    </div>
                  ) : (
                    <React.Fragment>
                      {this.renderFormGroups(leadInfoForm.group)}
                    </React.Fragment>
                  )}
                </React.Fragment>
              ) : (
                <MainInfoLoader />
              )}
            </div>
          </div>
          {projectId == 1 && (
            <div className="agent-info-box box-white-custom">
              <React.Fragment>
                <div className="title-box">
                  <i className="icon-lms icon-agent"></i>
                  <span>
                    Agent Information
                  </span>
                </div>
                <div className="agent-infos custom-scroll-box">
                  {leadLoaded ? (
                    hasAgent && (
                    <InputSelect
                      name="selectedAgent"
                      defaultValue={
                        cancelViewingStatus
                          ? initialAgentSelected
                          : "Unassigned"
                      }
                      options={this.state.selectOptions}
                      onChange={this.handleAgentChange.bind(this)}
                    />
                    )
                  ) : (
                    <AgentInfoLoader />
                  )}

                  <div
                    className={`${
                      this.state.isAgentChanged ? "show-me" : "hide-detail"
                    } agent-information-wrapper`}
                  >
                    <div className="agent-changed">
                      {this.renderAgentInfo()}
                    </div>
                    {cancelViewingStatus && (
                      <div className="agent-static">
                        {agentStaticInfo.map((data) => {
                          return (
                            <React.Fragment key={data.id}>
                              {data.value !== null && (
                                <div
                                  key={data.id}
                                  className="input-wrap p--sm width--full"
                                >
                                  <label>{data.label}</label>
                                  <div>
                                    <span>{utility.decrypt(data.value)}</span>
                                  </div>
                                </div>
                              )}
                            </React.Fragment>
                          );
                        })}
                      </div>
                    )}
                    <div className="btns-to-change">
                        <span
                          className="cancel"
                          onClick={this.cancelaAgent}
                        >
                          Cancel
                        </span>
                        <span
                          className='assign'
                          onClick={this.handleAgentAssign.bind(true)}
                        >
                          Assign{" "}
                        </span>
                      </div>
                      <div className={`${this.state.isLoading ? "lds-ellipsis" : "none"}`}><div></div><div></div><div></div><div></div></div>
                  </div>
                </div>
              </React.Fragment>
            </div>
          )}
        </div>
      </React.Fragment>
    );
  };

  handleUpdateLeadStatus = (status) => {
    //let { leadStatusUpdated } = this.state;

    const projectId = this.props.match.params.id;
    const leadId = this.props.match.params.leadId;
    // let projectStatus = this.props.status.data;
    // let statusId = 0;
    const user = JSON.parse(sessionStorage.getItem("user"));
    const userEmail = user && user.data.email;

    // projectStatus.map((val) => {
    //     if (val.id === status) {
    //         return statusId = val.id;
    //     }
    //     return null;
    // });

    this.props.updateLead(projectId, leadId, {
      project_status_id: status,
      sender: userEmail,
    });

    this.setState({
      activeDropdown: "",
      isSelectFieldExpanded: false,
      selectedInput: "",
    });

   // this.handleGetLead();
  };

  handleConvertLead = () => {
    const projectId = sessionStorage.getItem("projectId");
    const leadId = this.props.match.params.id;
    const user = JSON.parse(sessionStorage.getItem("user"));
    const userEmail = user && user.data.email;

    this.props.updateLead(projectId, leadId, {
      converted: true,
      sender: userEmail,
    });

    this.handleGetLead();
  };

  handleLostLead = () => {
    const projectId = sessionStorage.getItem("projectId");
    const leadId = this.props.match.params.id;
    const user = JSON.parse(sessionStorage.getItem("user"));
    const userEmail = user && user.data.email;

    this.props.updateLead(projectId, leadId, {
      converted: false,
      sender: userEmail,
    });

    this.handleGetLead();
  };

  handleDropdown = (value) => {
    let activeDropdown = this.state.activeDropdown;
    const domNode = ReactDOM.findDOMNode(this);

    if (domNode || activeDropdown !== value) {
      this.setState({
        activeDropdown: value,
        selectedInput: "",
        isSelectFieldExpanded: false,
      });
    } else {
      this.setState({
        activeDropdown: "",
        selectedInput: "",
        isSelectFieldExpanded: false,
      });
    }
  };

  handleAgentChange = (e) => {
    var agent = this.state.availableAgents.filter((a) => {
      return a.agent_id === e;
    });

    this.setState({
      selectedAgentValue: agent[0],
      selectedInput: "",
      isSelectFieldExpanded: false,
      isAgentChanged: true,
    });
  };

  handleAgentAssign = async (proceed) => {
    this.setState({
      isLoading: true,
    });

    if (proceed) {
      var data = {
        agent_id:
          this.state.selectedAgentValue !== undefined &&
          utility.encrypt(this.state.selectedAgentValue.agent_id),
        agent_name:
          this.state.selectedAgentValue !== undefined &&
          utility.encrypt(
            this.state.selectedAgentValue.first_name +
              " " +
              this.state.selectedAgentValue.last_name
          ),
        agent_contact:
          this.state.selectedAgentValue !== undefined &&
          utility.encrypt(this.state.selectedAgentValue.contact),
        agent_email:
          this.state.selectedAgentValue !== undefined &&
          utility.encrypt(this.state.selectedAgentValue.email),
      };

      const { match } = this.props;
      const leadId = match.params.leadId;
      const res = await axios.put(
        `${env.apiUrl}/custom/phi/agent/1/lead/${leadId}/assign`,
        data
      );

      this.handleGetLead();
    }

    this.setState({
      isAgentChanged: false,
    });
  };

  handleProjectDetailsDrawer() {
    this.setState({
      isProjectDetailsDrawer: !this.state.isProjectDetailsDrawer,
    });
  }

  handleProjectMembersDrawer() {
    this.setState({
      isProjectMembersDrawer: !this.state.isProjectMembersDrawer,
    });
  }

  handleAddLeadDrawer() {
    this.setState({ isAddLeadDrawer: !this.state.isAddLeadDrawer });
  }

  handleEditLeadDrawer() {
    this.setState({ isEditLeadDrawer: !this.state.isEditLeadDrawer });
  }

  handleCalendlyDrawer() {
    this.setState({ isCalendlyDrawer: !this.state.isCalendlyDrawer });
  }

  handleOpenManageFileModal = (mode, data) => {
    console.log('open lead file modal.....');
    this.setState({
      isManageFileModalOpen: true,
      manageFileMode: mode,
      manageFileData: data,
    });
  };

  handleOpenConfirmDeleteModal = (type, data) => {
    this.setState({
        isDeleteConfirmationModalOpen: true,
        deleteConfirmationType: type,
        deleteConfirmationData: data
    });
  }

  handleCloseManageFileModal = () => {
    this.setState({
        isManageFileModalOpen: false,
        manageFileData: {},
        manageFileMode: 'add'
    });
  }

  handleCloseConfirmationDeleteModal = () => {
    this.setState({isDeleteConfirmationModalOpen : false});
  }

  render() {
    let { leadLoaded, membersLoaded, lead, match } = this.props;
    let leadDetails = lead && lead.data;
    let activityLog = [];
    let leadNotes = [];
    let leadInterest = [];
    let leadFiles = [];
    let { chats, getChatsLoaded, getChatNotification } = this.props;
    let { email, getEmailLoaded } = this.props;
    let {user} = this.props;
 

    let calendlyAccount = null;

    if (lead && lead.data.responsible != null && lead.data.responsible.calendly_account != null ) {
      calendlyAccount = lead.data.responsible.calendly_account;
    }

    if (lead && lead.data.responsible == null && lead.data.owner.calendly_account != null ) {
      calendlyAccount = lead.data.owner.calendly_account;
    }

 
    const projectId = match.params.id;
    const leadId = match.params.leadId;
    const chatCount = this.handleUnreadMessagesCount(chats,getChatsLoaded)
    const emailCount = this.handleUnreadEmailCount(email, getEmailLoaded)

    let identifierValue =
      leadLoaded &&
      this.handleGetIdentifier(
        leadDetails.identifier,
        leadDetails.identifier_type,
        leadDetails.basic_info
      );

    let {
      deleteLeadModalOpen,
      addLeadNoteModalOpen,
      editLeadNoteModalOpen,
      isProjectDetailsDrawer,
      isProjectMembersDrawer,
      isAddLeadDrawer,
      isEditLeadDrawer,
      isCalendlyDrawer,
      isDeleteConfirmationModalOpen,
      deleteConfirmationType,
      deleteConfirmationData,
      isManageFileModalOpen,
      manageFileMode,
      manageFileData,
    } = this.state;

    let deleteLeadNoteModalOpen = this.state.deleteLeadNoteModalOpen;

    return (
      <React.Fragment>
        <div className="topbar-menu">
          <MDBRow>
            {leadLoaded ? (
              <TopBar
                onHandleProjectDetailsDrawer={(width, project) =>
                  this.handleProjectDetailsDrawer(width, project)
                }
                onHandleProjectMembersDrawer={(width, owner, member) =>
                  this.handleProjectMembersDrawer(width, owner, member)
                }
              />
            ) : (
              <TopBarLoader />
            )}
            {leadLoaded && (
              <MDBCol className="col">
                <AddLeadNoteModal
                  toggleAddLeadNote={this.toggleAddLeadNote}
                  modalOpen={addLeadNoteModalOpen}
                  leadId={leadId}
                  // projectStatusId={leadDetails.project_status.id}
                  handleGetLead={this.handleGetLead}
                  addLocation="leadInfo"
                />
                <EditLeadNoteModal
                  toggleEditLeadNote={this.toggleEditLeadNote}
                  modalOpen={editLeadNoteModalOpen}
                  projectId={projectId}
                  leadId={leadId}
                  projectStatusId={leadDetails.project_status.id}
                  handleGetLead={this.handleGetLead}
                  leadNote={this.state.noteToEdit}
                />
                <MDBRow
                  className={`m--0 p--0 ${
                    leadDetails.converted === false ||
                    leadDetails.converted ||
                    "m-b--sm"
                  }t`}
                >
                  <MDBCol
                    className="my-auto text-left px-0"
                    style={{ display: "none" }}
                  >
                    {leadDetails.converted === false ||
                      leadDetails.converted || (
                        <div className="info-btn-breadcrumbs leads-btn-breadcrumbs">
                          <LeadInfoBreadCrumbs
                            isInfoBreadCrumbsActive={leadDetails.project_status.name.toLocaleUpperCase()}
                            handleUpdateLeadStatus={this.handleUpdateLeadStatus}
                            onHandleDropdown={this.handleDropdown}
                            activeDropdown={this.state.activeDropdown}
                            //onTest={this.state.activeDropdown}
                            // dropdownOptions={this.dropdownOption}
                          />
                        </div>
                      )}
                  </MDBCol>
                </MDBRow>
              </MDBCol>
            )}
          </MDBRow>
        </div>
        <div className="breadcrumb-menu">
          <div className="row align-items-center">
            <div className="col">
              <BreadcrumbPage />
            </div>
            <div className="col">
              {leadLoaded ? (
                <div className="button-group">
                  <div className="btn-chat-email-group">
                    {chats && (
                      <button
                        onClick={() => this.handleSideActivityButton("Chat")}
                      >
                        <i className="fas fa-comment-alt"></i>
                        <span className={chatCount == 0? "empty" : ""}>
                          {!getChatsLoaded && (
                            <div className="loader-sm">
                              <div></div>
                              <div></div>
                              <div></div>
                              <div></div>
                            </div>
                          )}
                          {getChatsLoaded && 
                          chatCount > 9 ? "9+" : chatCount }
                        </span>
                      </button>
                    )}
                    {email && (
                      <button
                        onClick={() => this.handleSideActivityButton("Email")}
                      >
                        <i className="fas fa-envelope"></i>
                        <span className={emailCount == 0? "empty" : ""}>
                          {!getEmailLoaded && (
                            <div className="loader-sm">
                              <div></div>
                              <div></div>
                              <div></div>
                              <div></div>
                            </div>
                          )}
                          {getEmailLoaded && 
                          emailCount > 9 ? "9+" : emailCount}
                        </span>
                      </button>
                    )}
                  </div>
                  <button
                    className="c-btn c-btn-light"
                    onClick={() => this.handleEditLeadDrawer()}
                  >
                    <i className="icon-lms icon-edit"></i>
                    Edit Lead
                  </button>
                  <button
                    className="c-btn c-btn-light"
                    onClick={() => this.handleDeleteLead()}
                  >
                    <i className="icon-lms icon-delete"></i>
                    Delete Lead
                  </button>
                  {projectId == 5 && (
                    <button
                      disabled = {calendlyAccount == null}
                      className={calendlyAccount == null ? "c-btn c-btn-disabled" : "c-btn"}
                      onClick={() => this.handleCalendlyDrawer()}
                    >
                      <i className="icon-lms icon-calendly"></i>
                      Send Calendly Invite
                    </button>
                  )}
                </div>
              ) : (
                <ButtonGroupLoader />
              )}
            </div>
          </div>
        </div>

        <div className="main-info-section leads-info">
          <MDBRow className="row-100">
            <MDBCol md="7">
              <React.Fragment>
                <div className="top-info-box">
                  {this.renderSidebar(leadDetails)}
                </div>
                <React.Fragment>
                  {this.renderBasicInfo(leadDetails)}
                </React.Fragment>
              </React.Fragment>
            </MDBCol>
            <MDBCol md="5">
              <div className="activity-side-box box-white-custom">
                {leadLoaded ?
                <ActivitySideButton
                  sideBtnClick={this.handleSideActivityButton}
                  isActiveSideBtn={this.state.isActiveSideBtn}
                  handleDeleteLead={this.handleDeleteLead}
                  unreadMessages={this.handleUnreadMessagesCount(
                    chats,
                    getChatsLoaded
                  )}
                  getChatsLoaded={getChatsLoaded}
                  hasLeadChat={chats}
                  unreadEmails={this.handleUnreadEmailCount(
                    email,
                    getEmailLoaded
                  )}
                  getEmailLoaded={getEmailLoaded}
                  hasLeadEmail={email}
                  projectId={projectId}
                />
                : <SideInfoButtonLoader />
                }
                <div className="custom-scroll-box">
                  {leadLoaded ? (
                    this.handleDisplayActivity(
                      leadDetails,
                      activityLog,
                      leadNotes,
                      leadInterest,
                      chats,
                      getChatsLoaded,
                      email,
                      getEmailLoaded,
                      getChatNotification,
                      leadFiles,
                      this.handleOpenManageFileModal, // Pass the function as a callback
                      this.handleOpenConfirmDeleteModal // Also pass this function similarly, if needed
                    )
                  ) : (
                    <SideInfoLoader />
                  )}
                </div>
              </div>
            </MDBCol>
          </MDBRow>
        </div>

        <ProjectDetails
          isProjectDetailsDrawer={isProjectDetailsDrawer}
          onHandleProjectDetailsDrawer={() => this.handleProjectDetailsDrawer()}
        />

        <ProjectMembers
          isProjectMembersDrawer={isProjectMembersDrawer}
          onHandleProjectMembersDrawer={() => this.handleProjectMembersDrawer()}
        />

        {/* <Calendly
          isCalendlyDrawer={isCalendlyDrawer}
          onHandleCalendlyDrawer={() => this.handleCalendlyDrawer()}
        /> */}

        <AddLeadDrawer
          projectId={projectId}
          isAddLeadDrawer={isAddLeadDrawer}
          onHandleAddLeadDrawer={() => this.handleAddLeadDrawer()}
        />

        <EditLeadDrawer
          projectId={projectId}
          isEditLeadDrawer={isEditLeadDrawer}
          onHandleEditLeadDrawer={() => this.handleEditLeadDrawer()}
        />

        <DeleteConfirmationModal
          projectId={projectId}
          leadId={leadId}
          toggle={this.toggleDeleteLead}
          modalOpen={deleteLeadModalOpen}
          statusToDelete={leadId}
          onApprove={this.approveModal}
          target={identifierValue}
          nameToDelete={identifierValue}
          deleteNum="single"
        />

        <Calendly
          projectId={projectId}
          isCalendlyDrawer={isCalendlyDrawer}
          onHandleCalendlyDrawer={() => this.handleCalendlyDrawer()}
        />

        {this.state.statusToDelete && (
          <DeleteLeadNoteModal
            toggle={this.toggleDeleteLeadNote}
            modalOpen={deleteLeadNoteModalOpen}
            statusToDelete={this.state.statusToDelete}
            onApprove={this.approveModal}
            target={this.state.statusToDelete}
            nameToDelete={this.state.statusToDelete.name}
            deleteNum="single"
          />
        )}

        {/* Add & Edit Lead File Modal */}
        <LeadFileManageModal
                isOpen={isManageFileModalOpen}
                mode={manageFileMode}
                data={manageFileData}
                leadId={leadId}
                projectId={projectId}
                handleCloseManageFileModal={(mode) => this.handleCloseManageFileModal(mode)} />

        <DeleteLeadFileModal
                isOpen={isDeleteConfirmationModalOpen}
                leadId={leadId}
                projectId={projectId}
                type={deleteConfirmationType}
                data={deleteConfirmationData}
                toggle={isDeleteConfirmationModalOpen}
                onHandleCloseConfirmationDeleteModal={() => this.handleCloseConfirmationDeleteModal()} />

      </React.Fragment>
    );
  }
}

function mapState(state) {
  const {user} = state.authentication
  const { projects, project, forms, form, leads, adding} = state;
  const { lead, leadLoaded, activityLogLoaded, notes, notesLoaded, deleting } =
    state.leads;
  const { status, statusLoaded, members, membersLoaded } = state.projects;
  const { chats, getChatsLoaded, getChatNotification } = state.chat;
  const { email, getEmailLoaded } = state.email;
  const { files, getfileLoaded } = state.file;
  return {
    deleting,
    adding,
    projects,
    project,
    forms,
    form,
    leads,
    lead,
    leadLoaded,
    status,
    statusLoaded,
    members,
    membersLoaded,
    activityLogLoaded,
    notes,
    notesLoaded,
    chats,
    getChatsLoaded,
    email,
    getEmailLoaded,
    getChatNotification,
    user,
    files,
    getfileLoaded
  };
}

const actionCreators = {
  getLead: leadActions.getLead,
  updateLead: leadActions.updateLead,
  deleteLead: leadActions.deleteLead,
  getAllProjectStatus: projectActions.getAllProjectStatus,
  getAllProjectMembers: projectActions.getAllProjectMembers,
  getActivityLog: leadActions.getActivityLog,
  getLeadNotes: leadActions.getLeadNotes,
  deleteLeadNote: leadActions.deleteLeadNote,
  getLeadSummary: leadActions.getLeadSummary,
  getProject: projectActions.getProject,
  getLeadSession: leadActions.getLeadSession,
  getLeadInterest: leadActions.getLeadInterest,
  getLeadChat: chatActions.getChats,
  getLeadEmail: emailActions.getemails,
  getLeadFiles: fileActions.getFiles
};

const connectedViewLeadInfo = connect(mapState, actionCreators)(LeadInfo);
export { connectedViewLeadInfo as LeadInfo };
