import React from 'react'
import { ButtonGroup, Button } from "blackbox-react";
import { MDBContainer, MDBRow, MDBCol, MDBModal, MDBModalHeader, MDBModalBody } from 'mdbreact'

export default class DeleteConfirmationModal extends React.Component {
	constructor(props) {
		super(props);
		this.state = {
			modalOpen: false,
			deleting: false
		}
	}

	handleOnApprove = () => {
		let statusToDelete = this.props.statusToDelete;
		//const deleteNum = this.props.deleteNum;

		setTimeout(() => {
			this.props.onApprove(statusToDelete);
			this.setState({
				deleting: false
			});
		}, 2000);
		//
		// setTimeout(() => {
		// 	this.props.toggle(deleteNum);
		// }, 1600);
	};


	render() {
		let statusToDelete = this.props.statusToDelete;
		const deleteNum = this.props.deleteNum;
		const nameToDelete = this.props.nameToDelete;
		let deleting = this.state.deleting;

		return (
			<MDBContainer className='modal-container confirmation-modal-container'>
				<MDBRow>
					<MDBCol>
						<MDBModal backdrop={false} className='confirmation-modal' toggle={this.props.toggle} isOpen={this.props.modalOpen} centered>
							<MDBModalBody>
								<MDBRow className='form-group-row'>
                    				<MDBCol className='col-12'>
										<svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none" stroke="currentColor" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" class="feather feather-alert-circle"><circle cx="12" cy="12" r="10"/><line x1="12" y1="8" x2="12" y2="12"/><line x1="12" y1="16" x2="12.01" y2="16"/></svg>
										<h3>Confirm Deletion</h3>
                      					<p>
											Are you sure you want to delete 
										  	{ deleteNum === 'bulk' ?
												<span className='bulk'> &nbsp;
													{statusToDelete.length > 1 ? 'these' : 'this'} {statusToDelete.length} {statusToDelete.length > 1 ? 'items' : 'item'}
                        						</span> :
												<span className='single'> {nameToDelete || statusToDelete.name}</span>
											}
											?  
										</p>
									</MDBCol>
								</MDBRow>
								<MDBRow>
                    				<MDBCol className='modal-btns text-center'>
										<button
											onClick={this.props.toggle}
											className='c-btn c-btn-light'
										>
											Cancel
										</button>
										<button
											onClick={() => {
												this.setState((prevState, props) => {
													return { deleting: true }
												}, () => {
														this.handleOnApprove();
												});
											}}
											className='c-btn c-btn-red'
										>
												Delete
										</button>
										{deleting &&
										<span>
												Deleting please wait...
										</span>}
									</MDBCol>
								</MDBRow>
							</MDBModalBody>
						</MDBModal>
					</MDBCol>
				</MDBRow>
			</MDBContainer>
		)
	}
}
