import { deckActions, templateActions } from "_actions";
import React, { Component, useEffect, useState, useRef } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useParams } from "react-router-dom";
import { utilitiesService } from "_services";

const MenuBarReply = ({
  editor,
  onSaveDecks,
  onSaveEditorContent,
  composeOutside,
  toggleFullScreen,
  fullScreenEditor,
}) => {
  const [isDeckOpen, setIsDeckOpen] = useState(false);
  const [isDeckChecked, setIsDeckChecked] = useState(false);
  const [deckItems, setDeckItems] = useState([]);
  const [emailTemplates, setEmailTemplates] = useState([]);
  const [selectedDecks, setSelectedDecks] = useState([]);
  const [checkedDecks, setCheckedDecks] = useState({}); // Track checked state for each deck
  const [selectedEmailTemplate, setSelectedEmailTemplate] = useState(null);

  const deckRef = useRef();

  const [isEmailTemplateOpen, setIsEmailTemplateOpen] = useState(false);
  const emailTemplateRef = useRef();

  const dispatch = useDispatch();
  const params = useParams();

  let deck = useSelector((state) => state.deck);
  let getdeckLoading = useSelector((state) => state.deck.getdeckLoading);
  let getTemplateLoaded = useSelector(
    (state) => state.template.getTemplateLoaded
  );
  let template = useSelector((state) => state.template);

  useEffect(() => {
    dispatch(deckActions.getDecks(params.id));
    dispatch(templateActions.getTemplatesDropdown(params.id));
  }, []);

  useEffect(() => {
    if (composeOutside) {
      setIsDeckOpen(false);
      handleResetDecks();
    }
  }, [composeOutside]);

  useEffect(() => {
    if (!getdeckLoading && deck.decks && deck.decks.data !== undefined) {
      setDeckItems(deck.decks.data);
    }
  }, [getdeckLoading, deck.decks]);

  useEffect(() => {
    if (getTemplateLoaded && template.dropdown) {
      const sortedTemplates = template.dropdown.sort(
        (a, b) => new Date(b.updated) - new Date(a.updated)
      );

      // Decrypt the titles and templates
      const decryptedTemplates = sortedTemplates.map((template) => {
        const decryptedTitle = utilitiesService.decrypt(template.title);
        const decryptedTemplate = utilitiesService.decrypt(template.template);

        return {
          ...template,
          title: decryptedTitle,
          template: decryptedTemplate,
        };
      });

      setEmailTemplates(decryptedTemplates);
    }
  }, [getTemplateLoaded]);

  function handleOpenDeckFilter() {
    setIsDeckOpen(!isDeckOpen);
    setIsEmailTemplateOpen(false);
  }
  function handleCloseDeckFilter() {
    setIsDeckOpen(false);
  }

  function handleOpenEmailTemplateFilter() {
    setIsEmailTemplateOpen(!isEmailTemplateOpen);
    setIsDeckOpen(false);
  }

  function handleCloseEmailTemplateFilter() {
    setIsEmailTemplateOpen(false);
  }

  const handleDeckCheckbox = (event, deckItem) => {
    const isChecked = event.target.checked;

    // Update the checked state for the specific deck
    setIsDeckChecked((prevChecked) => ({
      ...prevChecked,
      [deckItem.id]: isChecked,
    }));

    // Update the array of selected decks based on checkbox status
    setSelectedDecks((prevSelected) => {
      if (isChecked) {
        return [...prevSelected, deckItem];
      } else {
        return prevSelected.filter((item) => item.id !== deckItem.id);
      }
    });
  };

  const handleLabelClick = (deckItemId) => {
    const isChecked = !isDeckChecked[deckItemId];

    // Update the checked state for the specific deck
    setIsDeckChecked((prevChecked) => ({
      ...prevChecked,
      [deckItemId]: isChecked,
    }));

    // Update the array of selected decks based on checkbox status
    setSelectedDecks((prevSelected) => {
      if (isChecked) {
        return [
          ...prevSelected,
          deckItems.find((item) => item.id === deckItemId),
        ];
      } else {
        return prevSelected.filter((item) => item.id !== deckItemId);
      }
    });
  };

  const handleResetDecks = () => {
    // Reset the state of checked decks and selected decks
    setIsDeckChecked({});
    setSelectedDecks([]);
  };

  const handleSaveDecks = () => {
    // Communicate the selected decks to the parent component
    // Create HTML content with deck names as bold and larger text, and deck links as hyperlinks
    const deckInfoHTML = selectedDecks
      .map(
        (deck) =>
          `<p><strong style="font-size: 16px;">${deck.name}</strong><br><a href="${deck.link}" target="_blank">${deck.link}</a></br></p>`
      )
      .join("\n");

    const cursorPosition = editor.state.selection.anchor;

    // Insert the template content at the cursor position
    editor.chain().focus().insertContentAt(cursorPosition, deckInfoHTML).run();

    // Communicate the updated editor content to the parent component
    onSaveEditorContent(editor.getHTML());

    setIsDeckOpen(false);
    handleResetDecks();
  };

  const handleSelectEmailTemplate = (template) => {
    const { title, template: templateContent } = template;

    // Assuming you have an `editor` instance
    if (editor) {
      // Get the current selection position
      const cursorPosition = editor.state.selection.anchor;

      // Insert the template content at the cursor position
      editor
        .chain()
        .focus()
        .insertContentAt(cursorPosition, `${templateContent}<br>`)
        .run();

      // Communicate the updated editor content to the parent component
      onSaveEditorContent(editor.getHTML());

      setIsEmailTemplateOpen(false);
    }
  };

  useEffect(() => {
    const dekcOutsideClick = (e) => {
      if (
        isDeckOpen &&
        deckRef.current &&
        !deckRef.current.contains(e.target)
      ) {
        setIsDeckOpen(false);
      }
    };
    document.addEventListener("click", dekcOutsideClick);
    return () => {
      document.removeEventListener("click", dekcOutsideClick);
    };
  }, [isDeckOpen]);

  useEffect(() => {
    const emailTemplateOutsideClick = (e) => {
      if (
        isEmailTemplateOpen &&
        deckRef.current &&
        !emailTemplateRef.current.contains(e.target)
      ) {
        setIsEmailTemplateOpen(false);
      }
    };
    document.addEventListener("click", emailTemplateOutsideClick);
    return () => {
      document.removeEventListener("click", emailTemplateOutsideClick);
    };
  }, [isEmailTemplateOpen]);

  if (!editor) {
    return null;
  }

  // Group templates by category
  const groupedTemplates = emailTemplates.reduce((grouped, template) => {
    const category = utilitiesService.decrypt(template.category);

    // Create a category if it doesn't exist
    if (!grouped[category]) {
      grouped[category] = [];
    }

    // Add the template to the corresponding category
    grouped[category].push(template);

    return grouped;
  }, {});

  return (
    <>
      <div className="thread-editor-controls">
        <button
          onClick={() => editor.chain().focus().setParagraph().run()}
          className={editor.isActive("paragraph") ? "is-active" : ""}
        >
          <i className="fas fa-paragraph"></i>
        </button>
        <button
          onClick={() =>
            editor.chain().focus().toggleHeading({ level: 4 }).run()
          }
          className={
            editor.isActive("heading", { level: 4 }) ? "is-active" : ""
          }
        >
          <i className="fas fa-heading"></i>
        </button>
        <button
          onClick={() => editor.chain().focus().toggleBold().run()}
          disabled={!editor.can().chain().focus().toggleBold().run()}
          className={editor.isActive("bold") ? "is-active" : ""}
        >
          <i className="fas fa-bold"></i>
        </button>
        <button
          onClick={() => editor.chain().focus().toggleItalic().run()}
          disabled={!editor.can().chain().focus().toggleItalic().run()}
          className={editor.isActive("italic") ? "is-active" : ""}
        >
          <i className="fas fa-italic"></i>
        </button>
        {/* <button
          onClick={() => editor.chain().focus().toggleStrike().run()}
          disabled={!editor.can().chain().focus().toggleStrike().run()}
          className={editor.isActive("strike") ? "is-active" : ""}
        >
          <i className="fas fa-strikethrough"></i>
        </button> */}
        <button
          onClick={() => editor.chain().focus().toggleBulletList().run()}
          className={editor.isActive("bulletList") ? "is-active" : ""}
        >
          <i className="fas fa-list-ul"></i>
        </button>
        <button
          onClick={() => editor.chain().focus().toggleOrderedList().run()}
          className={editor.isActive("orderedList") ? "is-active" : ""}
        >
          <i className="fas fa-list-ol"></i>
        </button>
        <button
          onClick={() => editor.chain().focus().undo().run()}
          disabled={!editor.can().chain().focus().undo().run()}
        >
          <i className="fas fa-undo"></i>
        </button>
        <button
          onClick={() => editor.chain().focus().redo().run()}
          disabled={!editor.can().chain().focus().redo().run()}
        >
          <i className="fas fa-redo"></i>
        </button>
        <button
          className={
            isEmailTemplateOpen
              ? "email-template-btn is-active"
              : "email-template-btn"
          }
          ref={emailTemplateRef}
        >
          <span onClick={() => handleOpenEmailTemplateFilter()}>
            <i className="icon-lms icon-envelope-o"></i>
          </span>
          <i class="fas fa-caret-down"></i>
          <div
            className={
              isEmailTemplateOpen
                ? "filter-dropdown filter-dropdown-deck active"
                : "filter-dropdown filter-dropdown-deck"
            }
          >
            <div className="filter-title">
              <p>Email Template</p>
              <i
                className="icon-lms icon-close"
                onClick={() => handleCloseEmailTemplateFilter()}
              ></i>
            </div>
            <div className="filter-list">
              {emailTemplates.length === 0 && (
                <div className="empty-box">
                  <p>You don’t have any email template.</p>
                </div>
              )}
              {emailTemplates.length > 0 && (
                <React.Fragment>
                  {Object.entries(groupedTemplates).map(
                    ([category, templatesInCategory]) => (
                      <div className="filter-group" key={category}>
                       <span className="filter-name">{category}</span>
                        {templatesInCategory.map((template) => (
                          <div
                            className="filter-name-list"
                            key={template.id}
                            onClick={() => handleSelectEmailTemplate(template)}
                          >
                            <span
                              className={
                                template === selectedEmailTemplate
                                  ? "selected"
                                  : ""
                              }
                            >
                              {template.title}
                            </span>
                          </div>
                        ))}
                      </div>
                    )
                  )}
                </React.Fragment>
              )}
            </div>
          </div>
        </button>
        <button
          className={
            isDeckOpen ? "editor-deck-btn is-active" : "editor-deck-btn"
          }
          ref={deckRef}
        >
          <span onClick={() => handleOpenDeckFilter()}>
            <svg
              xmlns="http://www.w3.org/2000/svg"
              class="icon icon-tabler icon-tabler-presentation"
              width="17"
              height="17"
              viewBox="0 0 22 22"
              stroke-width="2"
              stroke="currentColor"
              fill="none"
              stroke-linecap="round"
              stroke-linejoin="round"
            >
              <path stroke="none" d="M0 0h24v24H0z" fill="none" />
              <path d="M3 4l18 0" />
              <path d="M4 4v10a2 2 0 0 0 2 2h12a2 2 0 0 0 2 -2v-10" />
              <path d="M12 16l0 4" />
              <path d="M9 20l6 0" />
              <path d="M8 12l3 -3l2 2l3 -3" />
            </svg>
          </span>
          <i class="fas fa-caret-down"></i>
          <div
            className={
              isDeckOpen
                ? "filter-dropdown filter-dropdown-deck active"
                : "filter-dropdown filter-dropdown-deck"
            }
          >
            <div className="filter-title">
              <p>Presentation Decks</p>
              <i
                className="icon-lms icon-close"
                onClick={() => handleCloseDeckFilter()}
              ></i>
            </div>
            <div className="filter-list">
              {deckItems.length == 0 && (
                <div className="empty-box">
                  <p>You don’t have any presentation decks.</p>
                </div>
              )}
              {deckItems.length > 0 && (
                <React.Fragment>
                  {deckItems.map((deckItem) => (
                    <div className="checkbox-group" key={deckItem.id}>
                      <input
                        type="checkbox"
                        id={`itemdeck${deckItem.id}`}
                        name={`itemdeck${deckItem.id}`}
                        checked={isDeckChecked[deckItem.id]}
                        onChange={(e) => handleDeckCheckbox(e, deckItem)}
                      />
                      <label
                        htmlFor={`itemdeck${deckItem.id}`}
                        onClick={() => handleLabelClick(deckItem.id)}
                      >
                        {deckItem.name}
                      </label>
                    </div>
                  ))}
                </React.Fragment>
              )}
            </div>
            {isDeckChecked && deckItems.length > 0 && (
              <div className="filter-btn-group">
                <span className="btn-reset" onClick={handleResetDecks}>
                  Reset
                </span>
                <span className="btn-apply" onClick={handleSaveDecks}>
                  Add
                </span>
              </div>
            )}
          </div>
        </button>
        <button
          onClick={toggleFullScreen}
          className={fullScreenEditor ? "resize-btn is-active" : "resize-btn"}
        >
          <i
            className={
              fullScreenEditor
                ? "fas fa-compress-arrows-alt"
                : "fas fa-expand-arrows-alt"
            }
          ></i>
        </button>
      </div>
    </>
  );
};

export default MenuBarReply;
