import React, {useRef, useState, useEffect} from "react";

const operatorIcon = {
    'EQUAL': '=',
    'NOT_EQUAL': '!=',
    'CONTAINS': '⊂',
    'NOT_CONTAINS': '!⊂',
    'LESS_THAN': '<',
    'GREATER_THAN': '>',
}

const suggestedText = [
    {
        "text": "Seo"
    },
    {
        "text": "Mobile"
    },
    {
        "text": "Web"
    },
    {
        "text": "Desgin"
    },
    {
        "text": "Analytics"
    },
];

const ParameterField = ({index, parameter, parameterRef, inputVal, onHandleParameterRemove, onHandleParameterValueChanged }) => {
    const operatorBoxRef = useRef(parameterRef);
    const [operator, setOperator] = useState(parameter.operator);
    const [operatorBox, setOperatorBox] = useState(null);
    const [suggestValue, setSuggestValue] = useState('');

    const handleParameterValueChanged = (type, value) => {
        if(type === 'OPERATOR') {
            parameter.operator = value;
            setOperator(operator);
            handleOperatorBox();
        } else if(type === 'VALUE') {
            parameter.value = value;
            inputVal = value;
        }

        onHandleParameterValueChanged(parameter);
    }

    const onSuggestedChange = (event) => {
        setSuggestValue(event.target.value);
    }

    const onSuggestedClick = (searchTerm) => {
        setSuggestValue(searchTerm);
    }

    // console.log('re-rendered');

    const handleOperatorBox = () => {
        setOperatorBox(!operatorBox);
    }


    useEffect(() => {
        const operatorOutsideClick = (e) => {
            if(operatorBox && operatorBoxRef.current && !operatorBoxRef.current.contains(e.target)) {
                setOperatorBox(false);
            }
        };
        document.addEventListener('click', operatorOutsideClick);
        return () => {
            document.removeEventListener('click', operatorOutsideClick);
        }
    }, [operatorBox]);

    return(<div className='parameters' ref={operatorBoxRef}>
        <div className='p-name'>
            <p>{parameter.label}</p>
        </div>
        <div className='p-sign' >
            <span onClick={() => handleOperatorBox()} >
                {operatorIcon[parameter.operator]}
            </span>
            <div className={operatorBox ? 'hover-signs active' : 'hover-signs'}>
                <div onClick={()=>handleParameterValueChanged("OPERATOR","EQUAL")}>
                    <span>=</span>
                    <p>Equals</p>
                </div>
                <div onClick={()=>handleParameterValueChanged("OPERATOR","NOT_EQUAL")}>
                    <span>!=</span>
                    <p>Not Equals</p>
                </div>
                <div onClick={()=>handleParameterValueChanged("OPERATOR","CONTAINS")}>
                    <span>⊂</span>
                    <p>Contains</p>
                </div>
                <div onClick={()=>handleParameterValueChanged("OPERATOR","NOT_CONTAINS")}>
                    <span>!⊂</span>
                    <p>Not Contains</p>
                </div>
                <div onClick={()=>handleParameterValueChanged("OPERATOR","LESS_THAN")}>
                    <span className='s2'>&lt;</span>
                    <p>Less Than</p>
                </div>
                <div onClick={()=>handleParameterValueChanged("OPERATOR","GREATER_THAN")}>
                    <span className='s3'>&gt;</span>
                    <p>Greater Than</p>
                </div>
            </div>
        </div>
        <div className='p-field'>
            <input id={`parameter-${index}`} key={parameter.value} type={`text`} defaultValue={parameter.value} onChange={(e)=>handleParameterValueChanged("VALUE",e.target.value)}/>
            {parameter.value === null &&  <p className='error-field'>This field is required</p>}
        </div>
        {/* <div className="p-field">
            <input type="text" value={suggestValue} onChange={onSuggestedChange} />
            <div className="input-suggested">
                {suggestedText
                    .filter((item) => {
                    const searchTerm = suggestValue.toLowerCase();
                    const text = item.text.toLowerCase();

                    return (
                        searchTerm &&
                        text.startsWith(searchTerm) &&
                        text !== searchTerm
                    );
                    })
                    .slice(0, 10)
                    .map((item) => (
                    <div
                        onClick={() => onSuggestedClick(item.text)}
                        className="suggested-item"
                        key={item.text}
                    >
                        {item.text}
                    </div>
                    ))
                }
            </div>
        </div> */}
        <div className='p-control'>
            <button onClick={() => onHandleParameterRemove()}>
                <i className="icon-lms icon-close"></i>
            </button>
        </div>
    </div>)
}

export default ParameterField;


