import React, {Component, useState} from 'react'
import { MDBContainer, MDBRow, MDBCol, MDBModal, MDBModalHeader, MDBModalBody } from 'mdbreact'
import {influencerActions} from "_actions";
import {connect, useSelector} from "react-redux";
import { EditorContent, useEditor } from "@tiptap/react";
import StarterKit from "@tiptap/starter-kit";


const MenuBar = ({ editor }) => {
    
    if (!editor) {
      return null;
    }
  
    return (
      <>
        <div className="thread-editor-controls">
        <button
              onClick={() => editor.chain().focus().setParagraph().run()}
              className={editor.isActive("paragraph") ? "is-active" : ""}
          >
              <i className="fas fa-paragraph"></i>
          </button>
          <button
              onClick={() => editor.chain().focus().toggleHeading({ level: 4 }).run()}
              className={editor.isActive("heading", { level: 4 }) ? "is-active" : ""}
          >
              <i className="fas fa-heading"></i>
          </button>
          <button
              onClick={() => editor.chain().focus().toggleBold().run()}
              disabled={!editor.can().chain().focus().toggleBold().run()}
              className={editor.isActive("bold") ? "is-active" : ""}
          >
              <i className="fas fa-bold"></i>
          </button>
          <button
              onClick={() => editor.chain().focus().toggleItalic().run()}
              disabled={!editor.can().chain().focus().toggleItalic().run()}
              className={editor.isActive("italic") ? "is-active" : ""}
          >
              <i className="fas fa-italic"></i>
          </button>
          <button
              onClick={() => editor.chain().focus().toggleStrike().run()}
              disabled={!editor.can().chain().focus().toggleStrike().run()}
              className={editor.isActive("strike") ? "is-active" : ""}
          >
              <i className="fas fa-strikethrough"></i>
          </button>
          <button
              onClick={() => editor.chain().focus().toggleBulletList().run()}
              className={editor.isActive("bulletList") ? "is-active" : ""}
          >
              <i className="fas fa-list-ul"></i>
          </button>
          <button
              onClick={() => editor.chain().focus().toggleOrderedList().run()}
              className={editor.isActive("orderedList") ? "is-active" : ""}
          >
              <i className="fas fa-list-ol"></i>
          </button>
          <button
              onClick={() => editor.chain().focus().undo().run()}
              disabled={!editor.can().chain().focus().undo().run()}
          >
              <i className="fas fa-undo"></i>
          </button>
          <button
              onClick={() => editor.chain().focus().redo().run()}
              disabled={!editor.can().chain().focus().redo().run()}
          >
              <i className="fas fa-redo"></i>
          </button>
        </div>
      </>
    );
};

const ManageRateModalNew = ( { isOpen, handleCloseManageRatesModal } ) => {

    const editorCreate = useEditor({
        extensions: [
            StarterKit
        ],
        autofocus: true,
        content: ``,
    });

    return(
        <MDBContainer className="modal-container modal-form-container modal-rate">
            <MDBRow>
                <MDBCol>
                <MDBModal isOpen={isOpen} centered tabIndex="-1">
                    <MDBModalHeader className="confirmation-modal-header text-uppercase p-2">
                    <span className="pl-1 mx-2 letter-spacing-1 font-weight-600 font-color-gray font-size-13">
                         RATE
                    </span>
                    </MDBModalHeader>
                    <MDBModalBody>
                    <MDBRow className="form-group-row">
                        <MDBCol className='col-12'>
                        <label htmlFor={"value"}>Label</label>
                        <input
                            type="text"
                            id={"value"}
                            name={"value"}
                            onChange={(e) => this.setLabel(e.target.value)}
                        />
                        </MDBCol>
                        <MDBCol className="col-12">
                            <label>Description</label>
                            <div className="custom-rich-text-editor">
                                <MenuBar editor={editorCreate} />
                                <EditorContent
                                editor={editorCreate}
                                className="thread-editor-field"
                                />
                            </div>
                        </MDBCol>
                    </MDBRow>
                    <MDBRow>
                        <MDBCol className="text-center">
                        <button
                            className="modal-btn save-btn-modal"
                            onClick={() => this.handleManageRate()}
                        >
                            Save
                        </button>
                        <button
                            className="modal-btn cancel-btn-modal"
                            onClick={() => handleCloseManageRatesModal()}
                        >
                            Cancel
                        </button>
                        </MDBCol>
                    </MDBRow>
                    </MDBModalBody>
                </MDBModal>
                </MDBCol>
            </MDBRow>
        </MDBContainer>
    );
};


function mapState(state) {
    const { influencers } = state;
    return { influencers }
}

const actionCreators = {
    addInfluencerRate : influencerActions.addInfluencerRate,
    updateInfluencerRate : influencerActions.updateInfluencerRate,
};

const connectedViewManageRateModalNew = connect(mapState, actionCreators)(ManageRateModalNew);
export { connectedViewManageRateModalNew as ManageRateModalNew };