export const formConstants = {
  CREATE_REQUEST: 'FORMS_CREATE_REQUEST',
  CREATE_SUCCESS: 'FORMS_CREATE_SUCCESS',
  CREATE_FAILURE: 'FORMS_CREATE_FAILURE',

  GET_ALL_REQUEST: 'FORMS_GET_ALL_REQUEST',
  GET_ALL_SUCCESS: 'FORMS_GET_ALL_SUCCESS',
  GET_ALL_FAILURE: 'FORMS_GET_ALL_FAILURE',

  UPDATE_REQUEST: 'FORMS_UPDATE_REQUEST',
  UPDATE_SUCCESS: 'FORMS_UPDATE_SUCCESS',
  UPDATE_FAILURE: 'FORMS_UPDATE_FAILURE',

  ADD_MEMBER_REQUEST: 'FORMS_ADD_MEMBER_REQUEST',
  ADD_MEMBER_SUCCESS: 'FORMS_ADD_MEMBER_SUCCESS',
  ADD_MEMBER_FAILURE: 'FORMS_ADD_MEMBER_FAILURE',

  DELETE_MEMBER_REQUEST: 'FORMS_DELETE_MEMBER_REQUEST',
  DELETE_MEMBER_SUCCESS: 'FORMS_DELETE_MEMBER_SUCCESS',
  DELETE_MEMBER_FAILURE: 'FORMS_DELETE_MEMBER_FAILURE',

  GET_REQUEST: 'FORMS_GET_REQUEST',
  GET_SUCCESS: 'FORMS_GET_SUCCESS',
  GET_FAILURE: 'FORMS_GET_FAILURE',

  DELETE_REQUEST: 'FORMS_DELETE_REQUEST',
  DELETE_SUCCESS: 'FORMS_DELETE_SUCCESS',
  DELETE_FAILURE: 'FORMS_DELETE_FAILURE',

  DELETE_MULTIPLE_REQUEST: 'FORMS_DELETE_MULTIPLE_REQUEST',
  DELETE_MULTIPLE_SUCCESS: 'FORMS_DELETE_MULTIPLE_SUCCESS',
  DELETE_MULTIPLE_FAILURE: 'FORMS_DELETE_MULTIPLE_FAILURE',

  UPDATE_FORM_STATUS_REQUEST: 'UPDATE_FORM_STATUS_REQUEST',
  UPDATE_FORM_STATUS_SUCCESS: 'UPDATE_FORM_STATUS_SUCCESS',
  UPDATE_FORM_STATUS_FAILURE: 'UPDATE_FORM_STATUS_FAILURE',

  CREATE_STATUS_REQUEST: 'FORMS_CREATE_STATUS_REQUEST',
  CREATE_STATUS_SUCCESS: 'FORMS_CREATE_STATUS_SUCCESS',
  CREATE_STATUS_FAILURE: 'FORMS_CREATE_STATUS_FAILURE',

  UPDATE_STATUS_REQUEST: 'FORMS_UPDATE_STATUS_REQUEST',
  UPDATE_STATUS_SUCCESS: 'FORMS_UPDATE_STATUS_SUCCESS',
  UPDATE_STATUS_FAILURE: 'FORMS_UPDATE_STATUS_FAILURE',

  DELETE_STATUS_REQUEST: 'FORMS_DELETE_STATUS_REQUEST',
  DELETE_STATUS_SUCCESS: 'FORMS_DELETE_STATUS_SUCCESS',
  DELETE_STATUS_FAILURE: 'FORMS_DELETE_STATUS_FAILURE',

  GET_MESSAGE_REQUEST: 'GET_FORM_MESSAGE_REQUEST',
  GET_MESSAGE_SUCCESS: 'GET_FORM_MESSAGE_SUCCESS',
  GET_MESSAGE_FAILURE: 'GET_FORM_MESSAGE_FAILURE',

  UPDATE_MESSAGE_REQUEST: 'UPDATE_FORM_MESSAGE_REQUEST',
  UPDATE_MESSAGE_SUCCESS: 'UPDATE_FORM_MESSAGE_SUCCESS',
  UPDATE_MESSAGE_FAILURE: 'UPDATE_FORM_MESSAGE_FAILURE',

  SEND_MESSAGE_REQUEST: 'FORM_MESSAGE_SEND_REQUEST',
  SEND_MESSAGE_SUCCESS: 'FORM_MESSAGE_SEND_SUCCESS',
  SEND_MESSAGE_FAILURE: 'FORM_MESSAGE_SEND_FAILURE',

  GET_LAYOUT_REQUEST: 'MESSAGE_LAYOUT_REQUEST',
  GET_LAYOUT_SUCCESS: 'MESSAGE_LAYOUT_SUCCESS',
  GET_LAYOUT_FAILURE: 'MESSAGE_LAYOUT_FAILURE',

  GET_ALL_GROUP_REQUEST: 'GET_ALL_FORM_GROUP_REQUEST',
  GET_ALL_GROUP_SUCCESS: 'GET_ALL_FORM_GROUP_SUCCESS',
  GET_ALL_GROUP_FAILURE: 'GET_ALL_FORM_GROUP_FAILURE',

  CREATE_FORM_GROUP_REQUEST: 'CREATE_FORM_GROUP_REQUEST',
  CREATE_FORM_GROUP_SUCCESS: 'CREATE_FORM_GROUP_SUCCESS',
  CREATE_FORM_GROUP_FAILURE: 'CREATE_FORM_GROUP_FAILURE',

  GET_GROUP_REQUEST: 'GET_FORM_GROUP_REQUEST',
  GET_GROUP_SUCCESS: 'GET_FORM_GROUP_SUCCESS',
  GET_GROUP_FAILURE: 'GET_FORM_GROUP_FAILURE',

  UPDATE_GROUP_REQUEST: 'UPDATE_FORM_GROUP_REQUEST',
  UPDATE_GROUP_SUCCESS: 'UPDATE_FORM_GROUP_SUCCESS',
  UPDATE_GROUP_FAILURE: 'UPDATE_FORM_GROUP_FAILURE',

  DELETE_GROUP_REQUEST: 'DELETE_FORM_GROUP_REQUEST',
  DELETE_GROUP_SUCCESS: 'DELETE_FORM_GROUP_SUCCESS',
  DELETE_GROUP_FAILURE: 'DELETE_FORM_GROUP_FAILURE',

  UPDATE_GROUP_STATUS_REQUEST: 'GROUP_UPDATE_STATUS_REQUEST',
  UPDATE_GROUP_STATUS_SUCCESS: 'GROUP_UPDATE_STATUS_SUCCESS',
  UPDATE_GROUP_STATUS_FAILURE: 'GROUP_UPDATE_STATUS_FAILURE',

  REORDER_GROUP_REQUEST: 'REORDER_FORM_GROUP_REQUEST',
  REORDER_GROUP_SUCCESS: 'REORDER_FORM_GROUP_SUCCESS',
  REORDER_GROUP_FAILURE: 'REORDER_FORM_GROUP_FAILURE',

  GET_ALL_FIELD_REQUEST: 'GET_ALL_GROUP_FIELD_REQUEST',
  GET_ALL_FIELD_SUCCESS: 'GET_ALL_GROUP_FIELD_SUCCESS',
  GET_ALL_FIELD_FAILURE: 'GET_ALL_GROUP_FIELD_FAILURE',

  CREATE_GROUP_FIELD_REQUEST: 'CREATE_GROUP_FIELD_REQUEST',
  CREATE_GROUP_FIELD_SUCCESS: 'CREATE_GROUP_FIELD_SUCCESS',
  CREATE_GROUP_FIELD_FAILURE: 'CREATE_GROUP_FIELD_FAILURE',

  GET_FIELD_REQUEST: 'GET_GROUP_FIELD_REQUEST',
  GET_FIELD_SUCCESS: 'GET_GROUP_FIELD_SUCCESS',
  GET_FIELD_FAILURE: 'GET_GROUP_FIELD_FAILURE',

  UPDATE_FIELD_REQUEST: 'UPDATE_GROUP_FIELD_REQUEST',
  UPDATE_FIELD_SUCCESS: 'UPDATE_GROUP_FIELD_SUCCESS',
  UPDATE_FIELD_FAILURE: 'UPDATE_GROUP_FIELD_FAILURE',

  DELETE_FIELD_REQUEST: 'DELETE_GROUP_FIELD_REQUEST',
  DELETE_FIELD_SUCCESS: 'DELETE_GROUP_FIELD_SUCCESS',
  DELETE_FIELD_FAILURE: 'DELETE_GROUP_FIELD_FAILURE',

  UPDATE_FIELD_STATUS_REQUEST: 'FIELD_UPDATE_STATUS_REQUEST',
  UPDATE_FIELD_STATUS_SUCCESS: 'FIELD_UPDATE_STATUS_SUCCESS',
  UPDATE_FIELD_STATUS_FAILURE: 'FIELD_UPDATE_STATUS_FAILURE',

  REORDER_FIELD_REQUEST: 'REORDER_FORM_FIELD_REQUEST',
  REORDER_FIELD_SUCCESS: 'REORDER_FORM_FIELD_SUCCESS',
  REORDER_FIELD_FAILURE: 'REORDER_FORM_FIELD_FAILURE',

  UPDATE_FIELD_IDENTIFIER_REQUEST: 'UPDATE_FIELD_IDENTIFIER_REQUEST',
  UPDATE_FIELD_IDENTIFIER_SUCCESS: 'UPDATE_FIELD_IDENTIFIER_SUCCESS',
  UPDATE_FIELD_IDENTIFIER_FAILURE: 'UPDATE_FIELD_IDENTIFIER_FAILURE',

  UPDATE_FIELD_SUB_IDENTIFIER_REQUEST: 'UPDATE_FIELD_SUB_IDENTIFIER_REQUEST',
  UPDATE_FIELD_SUB_IDENTIFIER_SUCCESS: 'UPDATE_FIELD_SUB_IDENTIFIER_SUCCESS',
  UPDATE_FIELD_SUB_IDENTIFIER_FAILURE: 'UPDATE_FIELD_SUB_IDENTIFIER_FAILURE',

  GET_ALL_OPTION_REQUEST: 'GET_ALL_FIELD_OPTION_REQUEST',
  GET_ALL_OPTION_SUCCESS: 'GET_ALL_FIELD_OPTION_SUCCESS',
  GET_ALL_OPTION_FAILURE: 'GET_ALL_FIELD_OPTION_FAILURE',

  CREATE_FIELD_OPTION_REQUEST: 'CREATE_FIELD_OPTION_REQUEST',
  CREATE_FIELD_OPTION_SUCCESS: 'CREATE_FIELD_OPTION_SUCCESS',
  CREATE_FIELD_OPTION_FAILURE: 'CREATE_FIELD_OPTION_FAILURE',

  GET_OPTION_REQUEST: 'GET_FIELD_OPTION_REQUEST',
  GET_OPTION_SUCCESS: 'GET_FIELD_OPTION_SUCCESS',
  GET_OPTION_FAILURE: 'GET_FIELD_OPTION_FAILURE',

  UPDATE_OPTION_REQUEST: 'UPDATE_FIELD_OPTION_REQUEST',
  UPDATE_OPTION_SUCCESS: 'UPDATE_FIELD_OPTION_SUCCESS',
  UPDATE_OPTION_FAILURE: 'UPDATE_FIELD_OPTION_FAILURE',

  DELETE_OPTION_REQUEST: 'DELETE_FIELD_OPTION_REQUEST',
  DELETE_OPTION_SUCCESS: 'DELETE_FIELD_OPTION_SUCCESS',
  DELETE_OPTION_FAILURE: 'DELETE_FIELD_OPTION_FAILURE',

  GET_ALL_PRESET_REQUEST: 'GET_ALL_PRESET_REQUEST',
  GET_ALL_PRESET_SUCCESS: 'GET_ALL_PRESET_SUCCESS',
  GET_ALL_PRESET_FAILURE: 'GET_ALL_PRESET_FAILURE',

  CREATE_PRESET_REQUEST: 'CREATE_PRESET_REQUEST',
  CREATE_PRESET_SUCCESS: 'CREATE_PRESET_SUCCESS',
  CREATE_PRESET_FAILURE: 'CREATE_PRESET_FAILURE',

  GET_PRESET_REQUEST: 'GET_PRESET_REQUEST',
  GET_PRESET_SUCCESS: 'GET_PRESET_SUCCESS',
  GET_PRESET_FAILURE: 'GET_PRESET_FAILURE',

  UPDATE_PRESET_REQUEST: 'UPDATE_PRESET_REQUEST',
  UPDATE_PRESET_SUCCESS: 'UPDATE_PRESET_SUCCESS',
  UPDATE_PRESET_FAILURE: 'UPDATE_PRESET_FAILURE',

  GET_ALL_TEMPLATE_REQUEST: 'GET_ALL_TEMPLATE_REQUEST',
  GET_ALL_TEMPLATE_SUCCESS: 'GET_ALL_TEMPLATE_SUCCESS',
  GET_ALL_TEMPLATE_FAILURE: 'GET_ALL_TEMPLATE_FAILURE',

  CREATE_TEMPLATE_REQUEST: 'CREATE_TEMPLATE_REQUEST',
  CREATE_TEMPLATE_SUCCESS: 'CREATE_TEMPLATE_SUCCESS',
  CREATE_TEMPLATE_FAILURE: 'CREATE_TEMPLATE_FAILURE',

  GET_TEMPLATE_REQUEST: 'GET_TEMPLATE_REQUEST',
  GET_TEMPLATE_SUCCESS: 'GET_TEMPLATE_SUCCESS',
  GET_TEMPLATE_FAILURE: 'GET_TEMPLATE_FAILURE',

  UPDATE_TEMPLATE_REQUEST: 'UPDATE_TEMPLATE_REQUEST',
  UPDATE_TEMPLATE_SUCCESS: 'UPDATE_TEMPLATE_SUCCESS',
  UPDATE_TEMPLATE_FAILURE: 'UPDATE_TEMPLATE_FAILURE',

  GET_ACCESS_KEY_REQUEST: 'GET_FORM_ACCESS_KEY_REQUEST',
  GET_ACCESS_KEY_SUCCESS: 'GET_FORM_ACCESS_KEY_SUCCESS',
  GET_ACCESS_KEY_FAILURE: 'GET_FORM_ACCESS_KEY_FAILURE',

  GET_INTEGRATION_REQUEST: 'GET_FORM_INTEGRATION_REQUEST',
  GET_INTEGRATION_SUCCESS: 'GET_FORM_INTEGRATION_SUCCESS',
  GET_INTEGRATION_FAILURE: 'GET_FORM_INTEGRATION_FAILURE',

  UPDATE_INTEGRATION_REQUEST: 'UPDATE_FORM_INTEGRATION_REQUEST',
  UPDATE_INTEGRATION_SUCCESS: 'UPDATE_FORM_INTEGRATION_SUCCESS',
  UPDATE_INTEGRATION_FAILURE: 'UPDATE_FORM_INTEGRATION_FAILURE',


  VALIDATE_EMBED_CODE_REQUEST: 'VALIDATE_EMBED_CODE_REQUEST',
  VALIDATE_EMBED_CODE_SUCCESS: 'VALIDATE_EMBED_CODE_SUCCESS',
  VALIDATE_EMBED_CODE_FAILURE: 'VALIDATE_EMBED_CODE_FAILURE',
};
