import React, { useEffect, useState } from "react";
import { MDBContainer, MDBCol, MDBRow } from "mdbreact";
import { Button } from "blackbox-react";
import logo from "_assets/images/lms-logo.png";
import loginBG from "_assets/images/login-bg.png";
import { useDispatch, useSelector } from "react-redux";
import { Link, useHistory, useLocation } from "react-router-dom";
import { userActions } from "_actions";
import { utilitiesService as utility } from "_services";

const TwoFactorAppAuthentication = () => {
  const [loggingIn, setLoggingIn] = useState(false);
  const [email, setEmail] = useState("");
  const [showResendButton, setShowResendButton] = useState(false);
  const [showIncorrectCode, setShowIncorrectCode] = useState(false);
  const [verificationCode, setVerificationCode] = useState(["", "", "", "", "", ""]);
  const [twoFactorError, setTwoFactorError] = useState("");
  const [redirect, setRedirect] = useState(null);
  const [sendQR, setSendQR] = useState(false);
  const [loadEmailSend, setLoadEmailSend] = useState(false);
  const [emailSent, setEmailSent] = useState(false);
 

  const [googleModalOpen, setGoogleModalOpen] = useState(false);

  const dispatch = useDispatch();
  const location = useLocation();
  const history = useHistory();

  let user = useSelector((state) => state.users);
  let authenticate = useSelector((state) => state.authentication);

  useEffect(() => {
    const handleBeforeUnload = () => {
      console.log("called here....");
      // Set isLoggedIn to 'false' in localStorage before the page unloads
      localStorage.setItem("isLoggedIn", "false");
      window.location.href = "/login"; // Replace with your actual login page URL
    };

    // Add an event listener for the beforeunload event
    window.addEventListener("beforeunload", handleBeforeUnload);

    return () => {
      // Remove the event listener when the component unmounts
      window.removeEventListener("beforeunload", handleBeforeUnload);
    };
  }, []);

  useEffect(() => {
    // Check if the user is not logged in (isLoggedIn is not 'true')
    const isLoggedIn = localStorage.getItem("isLoggedIn");

    if (isLoggedIn !== "true") {
      // Redirect to the login page
      window.location.href = "/login"; // Replace with your actual login page URL
    }
  }, [history]);

  useEffect(() => {
    // Create a new URLSearchParams object from the location search string
    const queryParams = new URLSearchParams(location.search);

    // Get the value of a specific query parameter, for example, 'redirect'
    const redirectParam = queryParams.get("redirect");

    // Now, you have the 'redirect' query parameter value
    if (redirectParam) {
      setRedirect(redirectParam);
    }
  }, [location.search]);

  useEffect(() => {
    if (authenticate.loggedIn) {
      const accountEmail = authenticate.user.data.email;
      setEmail(accountEmail);
    }
  }, [authenticate]);


  useEffect(() => {
    if (user && user.loading === false) {
      setTwoFactorError(user.error);
      setLoadEmailSend(false);
    }

    if (user && user.loading === true) {
      setLoadEmailSend(true);
    }
  }, [user]);

  const handleCodeChange = (index, value) => {
    // Create a copy of the current verification code array
    const updatedCode = [...verificationCode];
    // Update the value at the specified index
    updatedCode[index] = value;
    // Update the state with the new verification code
    setVerificationCode(updatedCode);

    if (value === "" && index > 0) {
      const prevInput = document.getElementById(
        `verification-input-${index - 1}`
      );
      if (prevInput) {
        prevInput.focus();
      }
    }
    if (value !== "" && index < verificationCode.length - 1) {
      const nextInput = document.getElementById(
        `verification-input-${index + 1}`
      );
      if (nextInput) {
        nextInput.focus();
      }
    }
  };

  const handleVerifyCode = () => {
    setTwoFactorError("");
    const twoFactorCode = verificationCode.join("");
    const request = { two_factor_code: utility.encrypt(twoFactorCode) };
    console.log("Verification Code:", request);
    dispatch(userActions.twoFactorAppVerify(request, email, redirect));
  };

  const handleAppKeySend = () => {
    setEmailSent(true);
    dispatch(userActions.appKeySend());
    setSendQR(true);
  }

  const handlePaste = (e) => {
    e.preventDefault();
    const clipboardData = e.clipboardData.getData("text");
    const pastedCode = clipboardData.slice(0, 6); // Limit to 6 characters

    if (pastedCode.length === 6) {
      const newVerificationCode = pastedCode.split("");
      setVerificationCode(newVerificationCode);
    }
  };

  const handleOpenGoogleModal = () => {
    setGoogleModalOpen(true);
  };

  const handleCloseGoogleModal = () => {
    setEmailSent(false);
    setGoogleModalOpen(false);
  };
  

  return (
    <>
      <MDBContainer fluid className="login-background">
        <div className="login-bg">
          <img src={loginBG} alt="Login Background Image" />
        </div>
        <div className="login-panel">
          <MDBRow>
            <MDBCol className="text-center">
              <img src={logo} alt="Propelrr Platform" className="login-logo" />
              <h1>Lead Management System</h1>
            </MDBCol>
          </MDBRow>
          <MDBRow>
            <MDBCol>
              <form className="verify-form">
                <MDBRow>
                  <MDBCol className="text-center">
                    <div className="form-inner-heading">
                      <h2>Verify that it's you</h2>
                      <p>
                      To login, enter the 6-digit authentication code from the <strong>Google Authenticator</strong> app
                      </p>
                      <p>
                        Didn't setup yet? <span className="instructions" onClick={() => handleOpenGoogleModal()}>See instructions for setting up.</span>
                      </p>
                    </div>
                    <div
                      className="login-verification-code"
                      onPaste={handlePaste}
                    >
                      <input
                        id={`verification-input-0`}
                        maxLength="1"
                        type="text" // Change type to "text"
                        pattern="[0-9a-zA-Z]*" // Add pattern attribute for alphanumeric input
                        value={verificationCode[0]}
                        onChange={(e) => handleCodeChange(0, e.target.value)}
                        placeholder="0"
                      />
                      <input
                        id={`verification-input-1`}
                        maxLength="1"
                        type="text" // Change type to "text"
                        pattern="[0-9a-zA-Z]*" // Add pattern attribute for alphanumeric input
                        value={verificationCode[1]}
                        onChange={(e) => handleCodeChange(1, e.target.value)}
                        placeholder="0"
                      />
                      <input
                        id={`verification-input-2`}
                        maxLength="1"
                        type="text" // Change type to "text"
                        pattern="[0-9a-zA-Z]*" // Add pattern attribute for alphanumeric input
                        value={verificationCode[2]}
                        onChange={(e) => handleCodeChange(2, e.target.value)}
                        placeholder="0"
                      />
                      <input
                        id={`verification-input-3`}
                        maxLength="1"
                        type="text" // Change type to "text"
                        pattern="[0-9a-zA-Z]*" // Add pattern attribute for alphanumeric input
                        value={verificationCode[3]}
                        onChange={(e) => handleCodeChange(3, e.target.value)}
                        placeholder="0"
                      />
                       <input
                        id={`verification-input-4`}
                        maxLength="1"
                        type="text" // Change type to "text"
                        pattern="[0-9a-zA-Z]*" // Add pattern attribute for alphanumeric input
                        value={verificationCode[4]}
                        onChange={(e) => handleCodeChange(4, e.target.value)}
                        placeholder="0"
                      />
                       <input
                        id={`verification-input-5`}
                        maxLength="1"
                        type="text" // Change type to "text"
                        pattern="[0-9a-zA-Z]*" // Add pattern attribute for alphanumeric input
                        value={verificationCode[5]}
                        onChange={(e) => handleCodeChange(5, e.target.value)}
                        placeholder="0"
                      />
                    </div>
                    <div className="resend-verification-code">
                      <div className="form-errors">
                        <p>{twoFactorError}</p>
                      </div>
                    </div>
                    <div className="form-group">
                      <Button
                        type="button"
                        onClick={handleVerifyCode}
                        className={
                          loggingIn ||
                          verificationCode.some((code) => code === "")
                            ? "c-btn c-btn-lg c-btn-disabled"
                            : "c-btn c-btn-lg c-btn-blue1"
                        }
                        disabled={
                          loggingIn ||
                          verificationCode.some((code) => code === "")
                        }
                      >
                        Verify
                      </Button>
                      {loggingIn && (
                        <div className="lds-ellipsis">
                          <div></div>
                          <div></div>
                          <div></div>
                          <div></div>
                        </div>
                      )}
                    </div>
                    <div className="form-group">
                      <div className="go-login-group">
                        <p>
                          Go back to <Link to="/login">Login</Link>
                        </p>
                      </div>
                    </div>
                  </MDBCol>
                </MDBRow>
              </form>
            </MDBCol>
          </MDBRow>
        </div>
        <div className={googleModalOpen ? "google-modal active" : "google-modal"}>
          <div className="overlay"></div>
          <div className="modal-box">
            <i className="icon-lms icon-close" onClick={() => handleCloseGoogleModal()}></i>
            <div className="modal-heading">
              <span className="icon-qmark">?</span>
              <h2>Setting up Two-Factor Authentication</h2>
            </div>
            <div className="list-details">
                <div className="detail">
                  <p>1. Download the Google Authenticator app on your mobile device</p>
                  <span className="description">
                    Get the app via the App Store or Google Play Store. Open and click the “plus” sign on the lower right corner of the screen.
                  </span>
                </div>
                <div className="detail">
                  <p>2. Scan the QR code or enter the key we sent via email</p>
                  <span className="description">
                    We’ll send you a QR code and a setup key via email. Scan the code or input the key on the authenticator app to complete the set up.
                  </span>
                  <div className="qr-sending">
                    <span className={emailSent && !loadEmailSend ? "btn-link sent" : "btn-link"} onClick={handleAppKeySend}>Send the QR code and the key to my email.</span>
                    {!loadEmailSend && emailSent && <span className="qr-sent"><i className="icon-lms icon-checkmark"></i></span>}
                   {loadEmailSend && <div class="lds-ring"><div></div><div></div><div></div><div></div></div>}
                  </div>
                </div>
                <div className="detail">
                  <p>3. Copy and enter the generated 6-digit code</p>
                  <span className="description">
                    Google Authenticator will generate a unique 6-digit code every time you log into the platform. Enter that code to successfully log into your account.
                  </span>
                </div>
            </div>
            <div className="modal-bottom">
              <button
                className="c-btn c-btn-lg"
                onClick={handleCloseGoogleModal}
              >
                Close this tab
              </button>
            </div>
          </div>
        </div>
      </MDBContainer>
    </>
  );
};

export default TwoFactorAppAuthentication;
