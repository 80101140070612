import React, {Component, useSelector} from 'react';
import {Link} from 'react-router-dom';
import {userActions, projectActions, profileActions} from '_actions'
import {history} from '_helpers';
import avatar from '_assets/images/avatar-sm.png'

import {utilitiesService as utility} from '_services';

import {MDBCollapse, MDBDropdown, MDBDropdownItem, MDBDropdownMenu, MDBDropdownToggle} from 'mdbreact';

class Logout extends Component {

    constructor(props) {
        super(props);
        this.dropdownToggleRef = React.createRef();

        this.state = {
            isOpen: true,
            isDropdownOpen: false
        };
    }

    renderLoggedInUser = () => {


        const loggedInUser = JSON.parse(sessionStorage.getItem('user'));
        let displayedLoggedInUser = '';

        if(loggedInUser){
            displayedLoggedInUser = utility.decrypt(loggedInUser.data.first_name).toUpperCase().charAt(0);
        }

        return displayedLoggedInUser;
    };

    handleLogout = () => {
        userActions.logout();
        history.push('/');
    };

    handleUpdatePassword = () => {
        userActions.updatePassword();
    }

    handleDropdownToggle() {
        this.setState({isDropdownOpen: !this.state.isDropdownOpen})
    }

    // toggleCollapse = () => {
    //     this.setState({ isOpen: !this.state.isOpen });
    // };

    handleDropdown = value => {
        if (!this.dropdownToggleRef.current.contains(value.target)) {
            this.setState({isDropdownOpen: false})
        }
    }

    componentDidMount() {
        document.addEventListener('click', this.handleDropdown, true);
    }

    componentWillUnmount() {
        document.removeEventListener('click', this.handleDropdown, true);
    }


    render() { 

        return (
            <React.Fragment>
                {/* <MDBNavbarToggler onClick={this.toggleCollapse} /> */}
                <div id="navbarCollapse3" ref={this.dropdownToggleRef}>
                    <div className={this.state.isDropdownOpen ? 'dropdown-parent show' : 'dropdown-parent'}>
                        <div>
                        <div className='avatar-box avatar-color-1'>
                            <span onClick={() => this.handleDropdownToggle()}>
                                {this.renderLoggedInUser()}
                            </span>
                        </div>
                        {/* <MDBIcon className='text-white font-size-10' icon='chevron-right' /> */}
                        </div>
                        <div className={this.state.isDropdownOpen ? 'dropdown-menu active-custom' : 'dropdown-menu'}>
                            {/* <MDBDropdownItem>
                                <Link onClick={this.handleUpdatePassword} to='/update-password'>
                                    Change Password
                                </Link>
                            </MDBDropdownItem> */}
                            <div>
                                <Link to={`/profile-settings`}>
                                    Profile Settings
                                </Link>
                            </div>
                            <div>
                                <Link onClick={this.handleLogout} to='/logout'>
                                Logout
                                </Link>
                            </div>
                        </div>
                    </div>
                </div>
            </React.Fragment>
        );
    }
}

export default Logout;