import React, {useState, useEffect, useRef} from 'react';
import {MDBCol, MDBIcon, MDBRow} from 'mdbreact';
import {useDispatch, useSelector} from "react-redux";
import {useParams} from "react-router-dom";
import {managerActions} from "_actions/manager.actions";
import ParameterField from "_pages/Projects/components/members/ParameterField";
import AutoAssignDrawerLoader from '../../../../_components/Loader/AutoAssignDrawerLoader';
import CalendlyConfigDrawer from './CalendlyConfigDrawer';

const AutoAssignConfigDrawer = ({
                                    autoConfigId,
                                    activeParams,
                                    isAddInterval,
                                    onHandleCloseAddInterval,
                                    onHandleOpenParameters,
                                    onHandleParameterAdd,
                                    isParameters,
                                    tabActive
                                }) => {

    const dispatch = useDispatch();
    const params = useParams();
    const [count, setCount] = useState(null);
    const [config, setConfig] = useState(activeParams);
    const [toggleTabState, setToggleTabState] = useState(1);

    useEffect(() => {
        if (autoConfigId !== null) {
            dispatch(managerActions.getManagerConfig(params.id, autoConfigId));
        }
    }, [autoConfigId]);

    useEffect(() => {
        if (tabActive === 1) {
            setToggleTabState(1);
        }  
        if (tabActive === 2) {
            setToggleTabState(2);
        }
    }, [tabActive]);

    const getManagersConfigLoaded = useSelector((state) => {
        const {managers} = state;
        return managers.getManagersConfigLoaded;
    });

    const autoAssign = useSelector((state) => {
        const {managers} = state;
        if (managers.getManagersConfigLoaded) {
            return managers.config;
        }
        return [];
    });

    useEffect(() => {
        setConfig(activeParams);
    },[activeParams]);

    const handleParameterRemove = (index) => {
        const selected = activeParams.filter((x, i) => i !== index);
        setConfig(selected);
        onHandleParameterAdd(selected);
    }

    const handleCloseMemberDrawer= () => {
        setToggleTabState(1);
        return onHandleCloseAddInterval();
    }

    const handleSaveConfig = () => {
        if (count !== null && count !== autoAssign.count) {
            let req = {
                status: autoAssign.status,
                count: count
            }
            dispatch(managerActions.updateManagerConfig(params.id, autoConfigId, req));
        }

        let empty = false;
        const newObjArr = activeParams.map(obj => {
                if (obj.value === '') {
                    empty = true;
                    return {...obj, value: null}
                }
                return obj
            }
        )

        if(empty) {
            onHandleParameterAdd(newObjArr);
        } else {
            dispatch(managerActions.updateManagerConfigsParam(params.id, autoConfigId, activeParams));
        }
    }

    const toggleTab = (index) => {
        setToggleTabState(index);
    }
    

    let activeClass = isAddInterval ? 'active' : '';
    let activeParameters = isParameters ? 'parameters-active' : '';

    const handleParameterValueChanged = (index, p) => {
        let list = config;
        list[index] = p;
        setConfig(list);
        onHandleParameterAdd(list);
    }

    // console.log('tabActive:', tabActive);
    // console.log('toggleTabState:', toggleTabState);

    return (
        <>
            <div className={`drawer-overlay ${activeClass}`} onClick={onHandleCloseAddInterval}></div>
            <div className={`side-drawer-main ${activeClass} ${activeParameters}`}>
                <div className='side-drawer-wrapper'>
                    <div className='side-drawer-title'>
                        <h3>Update Member</h3>
                        <span className='side-drawer-close' onClick={handleCloseMemberDrawer}>
                            <i className="icon-lms icon-close"></i>
                        </span>
                    </div>
                    <div className='side-drawer-tabs side-drawer-interval'>
                        <div className='side-drawer-tabs-wrapper'>
                            <div className='control-tabs-box'>
                                <div className={toggleTabState === 1 ? 'tabs-btn active' : 'tabs-btn'}
                                     onClick={() => toggleTab(1)}>
                                    <p>
                                        Auto-Assign Criteria
                                    </p>
                                </div>
                                {params.id == 5 && <div className={toggleTabState === 2 ? 'tabs-btn active' : 'tabs-btn'} onClick={() => toggleTab(2)}>
                                    <p>
                                        Calendly
                                    </p>
                                </div>}
                            </div>
                            <div className='content-tabs-box'>
                                <div className={toggleTabState === 1 ? 'tabs-content active' : 'tabs-content'}>
                                    <div className='interval-parameters'>

                                        {!getManagersConfigLoaded && <AutoAssignDrawerLoader/>}

                                        {getManagersConfigLoaded && <React.Fragment>
                                            <div className='ig-title'>
                                                <p>Round Robin</p>
                                            </div>
                                            <div className='parameters-group parameter-default'>
                                                <div className='parameters'>
                                                    <div className='p-name'>
                                                        <p>Heads</p>
                                                    </div>

                                                    <div className='p-sign'>
                                                        <span className='selected'>=</span>
                                                    </div>
                                                    <div className='p-field'>
                                                        <input type={`text`} defaultValue={autoAssign.count}
                                                            onChange={(e) => setCount(e.target.value)}/>
                                                    </div>
                                                    <div className='p-control'>
                                                        <button>
                                                            <i className="fas fa-times"></i>
                                                        </button>
                                                    </div>
                                                </div>
                                            </div>
                                            <div className='ig-title margin-top-20'>
                                                <p>Parameters</p>
                                                <span className='plain-btn' onClick={onHandleOpenParameters}>
                                                <i className="icon-lms icon-add"></i>Add </span>
                                            </div>
                                            <div className='parameters-group parameter-scroll'>
                                                {config.map((parameter, index) => {

                                                    return (
                                                        <ParameterField
                                                            index={index}
                                                            key={index}
                                                            parameter={parameter}
                                                            onHandleParameterValueChanged={(p) => handleParameterValueChanged(index, p)}
                                                            onHandleParameterRemove={() => handleParameterRemove(index)}
                                                            parameterRef={parameter.id}/>);
                                                })}
                                                {
                                                    config.length == 0 && 
                                                    <div className='empty-box'>
                                                        <h6 >Empty Parameters</h6>
                                                            <p>
                                                                You can add your first parameter.
                                                            </p>
                                                            <span className='plain-btn' onClick={onHandleOpenParameters}>
                                                            <i className="icon-lms icon-add"></i>Add</span>
                                                    </div>
                                                }
                                            </div>
                                        </React.Fragment>}

                                    </div>

                                    <div className='tab-form-btn-group'>
                                        <MDBRow>
                                            <MDBCol className='text-right'>
                                                <span className='drawer-btn cancel-btn' onClick={onHandleCloseAddInterval}>
                                                    Cancel
                                                </span>
                                                <span className='drawer-btn save-btn' onClick={() => handleSaveConfig()}>
                                                    Save Config
                                                </span>
                                            </MDBCol>
                                        </MDBRow>
                                    </div>

                                </div>
                                <div className={toggleTabState === 2 ? 'tabs-content active' : 'tabs-content'}>
                                   <CalendlyConfigDrawer onHandleCloseAddInterval={onHandleCloseAddInterval} autoConfigId={autoConfigId} toggleCalendly={toggleTabState}></CalendlyConfigDrawer>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </>
    )
}

export default AutoAssignConfigDrawer

