import {managerService} from "_services";
import {alertActions} from "_actions/alert.actions";
import {managerConstants} from "_constants";
import { projectActions } from "./project.actions";

export const managerActions = {
    getManagers,
    getManagerConfig,
    deleteManager,
    updateManagerConfig,
    addManagerConfigParam,
    updateManagerConfigParam,
    deleteManagerConfigParam,
    getProjectConfigParam,
    updateManagerConfigsParam,
    getProjectConfigOperator
}

function getManagers(projectId,  query = null, page = 1, size = 15) {
    return dispatch => {
        dispatch(request(projectId,  query, page, size));
        managerService.getManagers(projectId,  query, page, size)
            .then(
                managers => {
                    console.log('updated managers')
                    dispatch(success(managers));
                },
                error => {
                    const {response} = error;
                    const {data} = response;
                    dispatch(failure(data));
                    dispatch(alertActions.error(data));
                }
            );
    };

    function request(projectId,  query = null, page = 1, size = 15) {
        return {type: managerConstants.GET_ALL_MANAGERS_REQUEST, projectId, query, page, size}
    }

    function success(managers) {
        return {type: managerConstants.GET_ALL_MANAGERS_SUCCESS, managers}
    }

    function failure(error) {
        return {type: managerConstants.GET_ALL_MANAGERS_FAILURE, error}
    }
}

function getManagerConfig(projectId, managerId) {
    return dispatch => {
        dispatch(request(projectId, managerId));
        managerService.getManagerConfig(projectId, managerId)
            .then(
                config => {
                    dispatch(success(config));
                },
                error => {
                    const {response} = error;
                    const {data} = response;
                    dispatch(failure(data));
                    dispatch(alertActions.error(data));
                }
            );
    };

    function request(projectId, managerId) {
        return {type: managerConstants.GET_MANAGER_CONFIG_REQUEST, projectId, managerId}
    }

    function success(config) {
        return {type: managerConstants.GET_MANAGER_CONFIG_SUCCESS, config}
    }

    function failure(error) {
        return {type: managerConstants.GET_MANAGER_CONFIG_FAILURE, error}
    }
}

function deleteManager(projectId, managerId) {
    return dispatch => {
        dispatch(request(projectId, managerId));
        managerService.deleteManager(projectId, managerId)
            .then(
                message => {
                    dispatch(getManagers(projectId));
                    dispatch(projectActions.getProject(projectId));
                    dispatch(success(message));
                    dispatch(alertActions.success(`Successfully removed user from the project.`));
                },
                error => {
                    const {response} = error;
                    const {data} = response;
                    dispatch(failure(data));
                    dispatch(alertActions.error(data));
                }
            );
    };

    function request(projectId, managerId) {
        return {type: managerConstants.DELETE_MANAGER_REQUEST, projectId, managerId}
    }

    function success(message) {
        return {type: managerConstants.DELETE_MANAGER_SUCCESS, message}
    }

    function failure(error) {
        return {type: managerConstants.DELETE_MANAGER_FAILURE, error}
    }
}

function updateManagerConfig(projectId, managerId, body) {
    return dispatch => {
        dispatch(request(projectId, managerId, body));
        managerService.updateManagerConfig(projectId, managerId, body)
            .then(
                config => {
                    dispatch(getManagers(projectId));
                    dispatch(success(config));
                    dispatch(alertActions.success(`Successfully updated manager config.`));
                },
                error => {
                    const {response} = error;
                    const {data} = response;
                    dispatch(failure(data));
                    dispatch(alertActions.error(data));
                }
            );
    };

    function request(projectId, managerId, body) {
        return {type: managerConstants.UPDATE_MANAGER_CONFIG_REQUEST, projectId, managerId, body}
    }

    function success(config) {
        return {type: managerConstants.UPDATE_MANAGER_CONFIG_SUCCESS, config}
    }

    function failure(error) {
        return {type: managerConstants.UPDATE_MANAGER_CONFIG_FAILURE, error}
    }
}

function addManagerConfigParam(projectId, managerId, body) {
    return dispatch => {
        dispatch(request(projectId, managerId, body));
        managerService.addManagerConfigParam(projectId, managerId, body)
            .then(
                param => {
                    dispatch(success(param));
                },
                error => {
                    const {response} = error;
                    const {data} = response;
                    dispatch(failure(data));
                    dispatch(alertActions.error(data));
                }
            );
    };

    function request(projectId, managerId, body) {
        return {type: managerConstants.ADD_MANAGER_CONFIG_PARAM_REQUEST, projectId, managerId, body}
    }

    function success(param) {
        return {type: managerConstants.ADD_MANAGER_CONFIG_PARAM_SUCCESS, param}
    }

    function failure(error) {
        return {type: managerConstants.ADD_MANAGER_CONFIG_PARAM_FAILURE, error}
    }
}

function updateManagerConfigParam(projectId, managerId, paramId, body) {
    return dispatch => {
        dispatch(request(projectId, managerId, paramId, body));
        managerService.updateManagerConfigParam(projectId, managerId, paramId, body)
            .then(
                param => {
                    dispatch(success(param));
                },
                error => {
                    const {response} = error;
                    const {data} = response;
                    dispatch(failure(data));
                    dispatch(alertActions.error(data));
                }
            );
    };

    function request(projectId, managerId, paramId, body) {
        return {type: managerConstants.UPDATE_MANAGER_CONFIG_PARAM_REQUEST, projectId, managerId, paramId, body}
    }

    function success(param) {
        return {type: managerConstants.UPDATE_MANAGER_CONFIG_PARAM_SUCCESS, param}
    }

    function failure(error) {
        return {type: managerConstants.UPDATE_MANAGER_CONFIG_PARAM_FAILURE, error}
    }
}

function updateManagerConfigsParam(projectId, managerId,  body) {
    return dispatch => {
        dispatch(request(projectId, managerId,  body));
        managerService.updateManagerConfigsParam(projectId, managerId,  body)
            .then(
                param => {
                    dispatch(getManagers(projectId));
                    dispatch(success(param));
                    dispatch(alertActions.success(`Successfully updated manager config.`));
                },
                error => {
                    const {response} = error;
                    const {data} = response;
                    dispatch(failure(data));
                    dispatch(alertActions.error(data));
                }
            );
    };

    function request(projectId, managerId, paramId, body) {
        return {type: managerConstants.UPDATE_MANAGER_CONFIG_PARAM_REQUEST, projectId, managerId, body}
    }

    function success(param) {
        return {type: managerConstants.UPDATE_MANAGER_CONFIG_PARAM_SUCCESS, param}
    }

    function failure(error) {
        return {type: managerConstants.UPDATE_MANAGER_CONFIG_PARAM_FAILURE, error}
    }
}

function deleteManagerConfigParam(projectId, managerId, paramId) {
    return dispatch => {
        dispatch(request(projectId, managerId, paramId));
        managerService.deleteManagerConfigParam(projectId, managerId, paramId)
            .then(
                param => {
                    dispatch(success(param));
                },
                error => {
                    const {response} = error;
                    const {data} = response;
                    dispatch(failure(data));
                    dispatch(alertActions.error(data));
                }
            );
    };

    function request(projectId, managerId, paramId) {
        return {type: managerConstants.DELETE_MANAGER_CONFIG_PARAM_REQUEST, projectId, managerId, paramId}
    }

    function success(param) {
        return {type: managerConstants.DELETE_MANAGER_CONFIG_PARAM_SUCCESS, param}
    }

    function failure(error) {
        return {type: managerConstants.DELETE_MANAGER_CONFIG_PARAM_FAILURE, error}
    }
}

function getProjectConfigParam(projectId, managerId) {
    return dispatch => {
        dispatch(request(projectId, managerId));
        managerService.getProjectConfigParam(projectId, managerId)
            .then(
                param => {
                    dispatch(success(param));
                },
                error => {
                    const {response} = error;
                    const {data} = response;
                    dispatch(failure(data));
                    dispatch(alertActions.error(data));
                }
            );
    };

    function request(projectId, managerId) {
        return {type: managerConstants.GET_PROJECT_CONFIG_PARAM_REQUEST, projectId, managerId}
    }

    function success(param) {
        return {type: managerConstants.GET_PROJECT_CONFIG_PARAM_SUCCESS, param}
    }

    function failure(error) {
        return {type: managerConstants.GET_PROJECT_CONFIG_PARAM_FAILURE, error}
    }
}

function getProjectConfigOperator(projectId, managerId) {
    return dispatch => {
        dispatch(request(projectId, managerId));
        managerService.getProjectConfigOperator(projectId, managerId)
            .then(
                operator => {
                    dispatch(success(operator));
                },
                error => {
                    const {response} = error;
                    const {data} = response;
                    dispatch(failure(data));
                    dispatch(alertActions.error(data));
                }
            );
    };

    function request(projectId, managerId) {
        return {type: managerConstants.GET_PROJECT_CONFIG_OPERATOR_REQUEST, projectId, managerId}
    }

    function success(operator) {
        return {type: managerConstants.GET_PROJECT_CONFIG_OPERATOR_SUCCESS, operator}
    }

    function failure(error) {
        return {type: managerConstants.GET_PROJECT_CONFIG_OPERATOR_FAILURE, error}
    }
}