import { httpManagerService as httpManager } from  '_services';

export const fileService = {
    getFiles,
    addFile,
    updateFile,
    deleteFile
}


function getFiles(projectId, leadId){
    return httpManager.getHttpWithRequestingToken(`/api/v2/project/${projectId}/lead/${leadId}/file`);
}

function addFile(projectId, leadId, formData) {

    return httpManager.postFormDataOnce(`/api/v2/project/${projectId}/lead/${leadId}/file`, formData);
}

function updateFile(projectId, leadId, fileId, formData) {
    
    return httpManager.putFormDataOnce(`/api/v2/project/${projectId}/lead/${leadId}/file/${fileId}`, formData);
}

function deleteFile(projectId, leadId, fileId){
    return httpManager.deleteHTTPOnce(`/api/v2/project/${projectId}/lead/${leadId}/file/${fileId}`);
}

