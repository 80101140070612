import React from 'react'
import { MDBCol, MDBIcon, MDBRow } from 'mdbreact';

// components
import InputForm from './InputForm';

const FormGroupTitle = ({ label,}) => {
  return (
    <div className="form-group-title">
      <label>{label}</label>
    </div>
  );
}

export default FormGroupTitle