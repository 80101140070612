import CryptoJS from 'crypto-js';
import { keysConstants } from '_constants';
import Axios from  'axios-observable';

export const utilitiesService = {
  encrypt,
  decrypt,
  scrape,

    getSessionUser
};

function encrypt(text){
  return CryptoJS.AES.encrypt(text,
    CryptoJS.enc.Utf8.parse(keysConstants.SECRET_KEY),
    {
      iv: CryptoJS.enc.Utf8.parse(keysConstants.PARAMETER_SPEC),
      mode: CryptoJS.mode.CBC,
      padding : CryptoJS.pad.Pkcs7
    }

  ).toString();
}

function decrypt(text){

  if(text === null) {
    return '';
  }
  
  return CryptoJS.AES.decrypt(text,
    CryptoJS.enc.Utf8.parse(keysConstants.SECRET_KEY),
    {
      iv: CryptoJS.enc.Utf8.parse(keysConstants.PARAMETER_SPEC),
      mode: CryptoJS.mode.CBC,
      padding : CryptoJS.pad.Pkcs7
    }
  ).toString(CryptoJS.enc.Utf8);
}


function scrape(url, code){
  Axios.get(url)
    .subscribe(
      (response) => {
        let isMatched = false;
        if(response.status === 200) {
          let html = response.data;
          let codeUpdated = code;
          html = html.replace(/(\r\n|\n|\r)/gm,"").replace(" ", "");
          isMatched = html.includes(codeUpdated);
          sessionStorage.setItem('verifyEmbedCode', isMatched);
        }
        return isMatched;
      },
      (error) => console.log(error) );
}

export function getLeadIdentifier(identifier, identifierType, leadData) {
    let identifierVal = [];



    leadData.forEach((val) => {
        if (identifierType === 'Form Group') {
            if (val.group_id === identifier) {
                identifierVal.push(decrypt(val.value));
            }
        } else if (identifierType === 'Group Field') {
            if (val.field_id === identifier) {
                identifierVal.push(decrypt(val.value));
            }
        }
    });

    return identifierVal.join(" ").replace(/\s\s+/g, ' ');
}


export function getLeadSubIdentifier(identifier, identifierType, leadData) {
    let identifierVal = [];

    leadData.sort((a,b) => a.field_id - b.field_id)

    leadData.forEach((val) => {

        let value = '';

        if (identifierType === 'Form Group') {
            if (val.group_id === identifier) {
                value = decrypt(val.value);
                identifierVal.push({value: value, verify_type: val.verify_type});
            }
        } else if (identifierType === 'Group Field') {
            if (val.field_id === identifier) {
                value = decrypt(val.value);
                identifierVal.push({value: value, verify_type: val.verify_type});
            }
        }

    });

    return identifierVal;
}

export function getLeadFieldValue(lead, identifier)  {
    let identifierVal = [];
    let data = lead.basic_info;
    let field = data.filter(function (sf) {
        return sf.field_id === identifier
    });
    field.forEach(f => {
        identifierVal.push(decrypt(f.value));
    });
    return identifierVal.join(" ").replace(/\s\s+/g, ' ');
}

export function getLeadCustomValue(lead, identifier)  {
    let identifierVal = [];
    let data = lead.custom_info;
    let field = data.filter(function (sf) {
        return sf.label === identifier
    });
    field.forEach(f => {
        identifierVal.push(f.value);
    });
    return identifierVal.join(" ").replace(/\s\s+/g, ' ');
}

export function getCampaignData(lead){

    let data = {
        source: "",
        medium: "",
        campaign: "",
        qualification: "",
        channel: "",
    }

    let def = 'Awaiting data population';
    if(lead.ga_client_id === null) {
        def = 'Google Client ID Not Recorded';
    }

    data.source = def;
    data.medium = def;
    data.campaign = def;
    data.qualification = def;
    data.channel = def;

    if (lead.campaign_data != null) {

        let cd = lead.campaign_data;

        if (cd.source != null) {
            data.source = cd.source;
        }

        if (cd.medium != null) {
            data.medium = cd.medium;
        }

        if (cd.campaign != null) {
            data.campaign = cd.campaign;
        }

        if (cd.qualification != null) {
            data.qualification = cd.qualification;
        }

        if (cd.channel != null) {
            data.channel = cd.channel;
        }
    }

    return data;
}

export function getSourceData(lead){

    let data = {
        source: "",
        medium: "",
        campaign: "",
        // qualification: "",
        channel: "",
        ga_client_id: "",
        origin: ""
    }

    if (lead) {
        data.origin = lead.origin;
    }

    if (lead.campaign_data != null) {

        let cd = lead.campaign_data;

        if (cd.source != null) {
            data.source = cd.source;
        }

        if (cd.medium != null) {
            data.medium = cd.medium;
        }

        if (cd.campaign != null) {
            data.campaign = cd.campaign;
        }

        // if (cd.qualification != null) {
        //     data.qualification = cd.qualification;
        // }

        if (cd.channel != null) {
            data.channel = cd.channel;
        }


        if (cd.ga_client_id != null ){
            data.ga_client_id = cd.ga_client_id;
        }
    }

    return data;
}

export function areArrayEqual(array1, array2) {
    if (array1.length === array2.length) {
        return array1.every(element => {
            if (array2.includes(element)) {
                return true;
            }

            return false;
        });
    }

    return false;
}

export function abbreviateNumber(number){

    const SI_SYMBOL = ["", "k", "M", "B", "T", "P", "E"];

    // what tier? (determines SI symbol)
    let tier = Math.log10(Math.abs(number)) / 3 | 0;

    // if zero, we don't need a suffix
    if(tier === 0) return number;

    // get suffix and determine scale
    let suffix = SI_SYMBOL[tier];
    let scale = Math.pow(10, tier * 3);

    // scale the number
    let scaled = number / scale;

    // format number and add suffix

    let result = scaled % 1 === 0 ? scaled : scaled.toFixed(1)

    return result + suffix;
}


export function getInfluencerTypeClassIcon(type){
    switch (type) {
        case 'platform-facebook':
            return 'facebook';

        case 'platform-instagram':
            return 'instagram';

        case 'platform-twitter':
            return 'twitter';

        case 'platform-youtube':
            return 'youtube';

        case 'platform-tiktok':
            return 'tiktok';

        case 'platform-kumu':
            return 'kumu';

        default:
            return 'others';

    }
}

export function getInfluencerTypeClassColor(type){
    switch (type) {
        case 'platform-facebook':
            return 'color-icon1';

        case 'platform-instagram':
            return 'color-icon2';

        case 'platform-twitter':
            return 'color-icon3';

        case 'platform-youtube':
            return 'color-icon4';

        case 'platform-tiktok':
            return 'color-icon5';

        case 'platform-kumu':
            return 'color-icon6';

        default:
            return 'color-icon0';

    }
}


export function getInfluencerTypeLabel(type){
    switch (type) {
        case 'platform-facebook':
            return 'Likes';

        case 'platform-instagram':
            return 'Followers';

        case 'platform-twitter':
            return 'Followers';

        case 'platform-youtube':
            return 'Subscribers';

        case 'platform-tiktok':
            return 'Followers';

        case 'platform-kumu':
            return 'Followers';

        default:
            return 'Followers';

    }
}


export function idleLogout() {

    let secondsSinceLastActivity = 0;

    const maxInactivity = (/*30 * 60*/ 60 * 5);

    setInterval(function() {
        secondsSinceLastActivity++;
        if(secondsSinceLastActivity > maxInactivity) {
            sessionStorage.clear();
            window.location.href = '/login';
        }
    }, 1000);

    function activity() {
        secondsSinceLastActivity = 0;
    }

    const activityEvents = [
        'mousedown', 'mousemove', 'keydown',
        'scroll', 'touchstart', 'keypress'
    ];

    activityEvents.forEach(function (eventName) {
        document.addEventListener(eventName, activity, true);
    })

}


export function getSessionUser(){
    let user = sessionStorage.getItem('user');
    let parsed = JSON.parse(user);
    return parsed.data;
}

export const isValidUrl = (urlString) => {
    var urlPattern = new RegExp('^(https?:\\/\\/)?'+ // validate protocol
        '((([a-z\\d]([a-z\\d-]*[a-z\\d])*)\\.)+[a-z]{2,}|'+ // validate domain name
        '((\\d{1,3}\\.){3}\\d{1,3}))'+ // validate OR ip (v4) address
        '(\\:\\d+)?(\\/[-a-z\\d%_.~+]*)*'+ // validate port and path
        '(\\?[;&a-z\\d%_.~+=-]*)?'+ // validate query string
        '(\\#[-a-z\\d_]*)?$','i'); // validate fragment locator
    return !!urlPattern.test(urlString);
}