import React, { Component } from 'react';
import { MDBCol, MDBRow } from 'mdbreact';

class LeadViewInfoTask extends Component {
  state = {
    isViewCompleted: 1,
    textViewCompleted: 'View all completed tasks'
  }
  //this function is used to handle the lenght of text
  handleTextEllipsis(text, max) {
    return text.substr(0, max) + (text.length > max ? '...' : '');
  }

  handleViewAllComplete = () => {
    let { isViewCompleted } = this.state
    this.setState({ isViewCompleted: isViewCompleted === 1 ? 0 : 1, textViewCompleted: isViewCompleted === 1 ? 'Hide all completed tasks' : 'View all completed tasks' })
  }

  render() {
    let { isViewCompleted, textViewCompleted } = this.state,
      showAllStatus = isViewCompleted === 1 && 'display-none'
    return (
      <div>
        <MDBCol>
          <MDBRow>
            <MDBCol md='6' className='px-0'>
              <p className='mb-0 font-weight-600 font-size-14 pt-3'>TASKS</p>
            </MDBCol>
            <MDBCol md='6' className='text-right px-0'>
              <p className='mb-0 font-size-11 pt-3 font-weight-600 font-color-dark-blue text-cursor-pointer'>+ Add Tasks</p>
            </MDBCol>
          </MDBRow>
        </MDBCol>
        <div>
          <MDBCol className='background-color-white py-2 border-left-orange my-2'>
            <MDBRow className='d-flex'>
              <MDBCol md='3' className='px-0 my-auto'>
                <label className='checkboxcontainer ml-3'>
                  <input type='checkbox' name='check-box' />
                  <span className='checkmark'></span>
                </label>
              </MDBCol>
              <MDBCol className='px-0 line-height-10 my-auto'>
                <p className='mb-0 font-size-12 font-color-pending font-weight-600'>{this.handleTextEllipsis('Call Lead around 12nn', 27)}</p>
                <p className='mb-0'><span className='font-size-9'>Assigned to: </span><span className='font-size-9 opacity-60'>Roger Asuncion</span></p>
                <span className='font-size-9'>Due date: </span><span className='font-size-9 opacity-60'>September 26, 2019</span>
              </MDBCol>
            </MDBRow>
          </MDBCol>
          <MDBCol>
            <MDBRow>
              <MDBCol className='text-right px-0' onClick={() => { this.handleViewAllComplete() }}>
                <p className='mb-0 font-size-11 pt-2 font-weight-600 font-color-dark-blue text-cursor-pointer'>{textViewCompleted}</p>
              </MDBCol>
            </MDBRow>
          </MDBCol>
          <MDBCol className={`${showAllStatus} background-color-lead-note py-2 border-left-orange my-2`}>
            <MDBRow className='d-flex'>
              <MDBCol md='3' className='px-0 my-auto'>
                <label className='checkboxcontainer ml-3'>
                  <input type='checkbox' name='check-box-completed' defaultChecked />
                  <span className='checkmark'></span>
                </label>
              </MDBCol>
              <MDBCol className='px-0 line-height-10 my-auto'>
                <p className='mb-0 font-size-12 font-color-pending font-weight-600 text-line-through'>{this.handleTextEllipsis('Email Lead around 10am', 27)}</p>
                <p className='mb-0'><span className='font-size-9'>Assigned to: </span><span className='font-size-9 opacity-60'>Roger Asuncion</span></p>
                <span className='font-size-9'>Due date: </span><span className='font-size-9 opacity-60'>September 26, 2019</span>
              </MDBCol>
            </MDBRow>
          </MDBCol>
        </div>
      </div>
    );
  }
}

export default LeadViewInfoTask;