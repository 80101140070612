import React, { Component } from "react";
import { Link } from 'react-router-dom'
import {
  MDBContainer,
  MDBRow,
  MDBCol,
  MDBIcon, MDBBtn
} from "mdbreact";

class ProjectCardView extends Component {

  handleTextEllipsis(text, max) {
    return text.substr(0, max) + (text.length > max ? '...' : '');
  }

  renderBlankState = (filter) => {
    return (
      <div className='text-center position-center m--auto'>
        <p className='font-size-32 font-weight-500 font-family-crimson mb-0'>No projects found</p>
        { filter !== 'shared' &&
          <MDBBtn flat className='background-color-orange px-3 py-2 border-radius-5 box-shadow-none text-white' onClick={this.toggleCreateProject}><MDBIcon icon='plus' className='mr-3' />CREATE NEW PROJECT</MDBBtn>
        }
      </div>
    )
  };

  isOwner = (project) => {
    let email = sessionStorage.getItem('email');
    let owner = project.owner.account.email;

    return email === owner;
  };

  renderProjectCardView = (data,index,status) => {
    return(
	<MDBCol lg='3' xl='3' md='3' className='card__container' key={data.id}>

		<MDBContainer className='card position-relative card-container box-shadow'>
		<Link to={`/project/${data.id}/leads`} onClick={(e) => {
            sessionStorage.setItem('projectId', data.id); sessionStorage.setItem('project', JSON.stringify(data) );
        }} >
			<div className="circle--orange"> </div>
			<MDBRow className='card__header'>
				<MDBCol>
					<div className='card__title'>
						<h3>{data.info.name}</h3>
					</div>
				</MDBCol>
			</MDBRow>
			<MDBRow className='card__body'>
				<MDBCol className='py-1'>
					<div className="card__description">
						<p className=''>{data.info.description}</p>
					</div>
				</MDBCol>
			</MDBRow>
		</Link>
		<MDBRow className='card__footer'>
			{/* <MDBCol lg='4' xl='4' md='4' className='py-1'>
			<Link to={`/project/${data.id}/forms`} onClick={(e) => { sessionStorage.setItem('projectId', data.id); }} >
				<div className="project__link link-to--forms">
					<div className="icon">
						<MDBIcon far icon='clipboard' />
					</div>
					<div className="label">
						<p>Forms</p>
					</div>
				</div>
			</Link>
			</MDBCol> */}
			<MDBCol lg='4' xl='4' md='4' className='py-1'>
				<Link
					to={`/project/${data.id}/leads`}
					onClick={(e) => {
                        sessionStorage.setItem('projectId', data.id); sessionStorage.setItem('project', JSON.stringify(data) ) ;
					}}
				>
					<div className="project__link link-to--leads">
					<div className="icon">
						<MDBIcon far icon='paper-plane' />
					</div>
					<div className='label'>
						<p>Leads</p>
					</div>
					</div>
				</Link>
			</MDBCol>


            {data.influencer_enabled && <MDBCol lg='4' xl='4' md='4' className='py-1'>
                <Link to={`/project/${data.id}/influencers`} onClick={(e) => { sessionStorage.setItem('projectId', data.id);  sessionStorage.setItem('project', JSON.stringify(data) ) ; }} >
                    <div className="project__link link-to--agents">
                        <div className="icon">
                            <MDBIcon fas="true" icon='at' />
                        </div>
                        <div className="label">
                            <p>Influencer</p>
                        </div>
                    </div>
                </Link>
            </MDBCol>}

			<MDBCol lg='4' xl='4' md='4' className='py-1'>
				<Link to={`/project/${data.id}/settings`} onClick={(e) => { sessionStorage.setItem('projectId', data.id);  sessionStorage.setItem('project', JSON.stringify(data) ) }} >
					<div className="project__link link-to--agents">
					<div className="icon">
						<MDBIcon fas="true" icon='users' />
					</div>
					<div className="label">
						<p>Members</p>
					</div>
					</div>
				</Link>
			</MDBCol>


		</MDBRow>
		</MDBContainer>
	</MDBCol>
    );
  };

  sortView(data){
    let { sort } = this.props;

    switch(sort){
      case 'lastUpdated':
        data = data.sort((a, b) => a.info.updated < b.info.updated ? 1 : -1);
        return data;
      case 'newest':
        data = data.sort((a, b) => a.id < b.id ? 1 : -1);
        return data;
      case 'oldest':
        data = data.sort((a, b) => a.id > b.id ? 1 : -1);
        return data;
      case 'az':
        data = data.sort((a, b) => {
          if (a.info.name > b.info.name) { return 1 }
          if (b.info.name > a.info.name) { return -1 }
          return 0;
        });
        return data;
      case 'za':
        data = data.sort((a, b) => {
          if (a.info.name > b.info.name) { return -1 }
          if (b.info.name > a.info.name) { return 1 }
          return 0;
        });
        return data;
      default:
        return null;
    }
  }


  //this function is used to handle search
  handleSearch = (searchKeyword, data) => {

    if(data !== undefined){
      let currentList = [];
      let newList = [];

      if (searchKeyword !== "") {
        currentList = data;

        newList = currentList.filter(item => {
          const lcName = item.info.name.toLowerCase();
          const lcDescription = item.info.description.toLowerCase();
          const filter = searchKeyword.toLowerCase();
          return lcName.includes(filter) || lcDescription.includes(filter);
        });

      } else {
        newList = data;
      }

      return newList;
    }

  };

  hasResults = data => {
    return this.props.hasResults(data);
  };

  renderNoResults = () => {
    return (
      <div className='text-center width--full'>
        <div className='position-center'>
          <h3>No Results found</h3>
          <p>
            Your search did not match any forms.
            <br/> Make sure that all words are spelled correctly and try again.
          </p>
        </div>
      </div>
    );
  };

  render() {
    let { data } = this.props;
    let { filter } = this.props;
    let { searchKeyword } = this.props;

    let hasAllResults = true;
    let hasOwnedResults = true;
    let hasSharedResults = true;
    let hasOwned = false;
    let hasShared = false;
    let totalProjects = 0;

    // Determine if user has Owned and Shared Projects
    if(data.owned !== undefined)  {
      hasOwned = !!data.owned.length;
    }
    if(data.shared !== undefined)  {
      hasShared = !!data.shared.length;
    }

    // Combine Owned and Shared Projects
    let allProjects = [];
    let ownedProjects = data.owned;
    let sharedProjects = data.shared;
    let lastKey = 0;

    for (var ownedKeys in ownedProjects) {
      allProjects.hasOwnProperty(ownedKeys) || (allProjects[ownedKeys] = ownedProjects[ownedKeys]);
      lastKey++;
    }

    for (var sharedKeys in sharedProjects) {
        allProjects[lastKey] = sharedProjects[sharedKeys];
        lastKey++;
    }

    if(ownedProjects && sharedProjects !== undefined)  {
      totalProjects = ownedProjects.length + sharedProjects.length;

      if (lastKey === totalProjects){
        allProjects = this.sortView(allProjects);
        ownedProjects = this.sortView(ownedProjects);
        sharedProjects = this.sortView(sharedProjects);
      }
    }

    // If there is to search
    if(searchKeyword){
      allProjects = this.handleSearch(searchKeyword, allProjects);
      ownedProjects = this.handleSearch(searchKeyword, ownedProjects);
      sharedProjects = this.handleSearch(searchKeyword, sharedProjects);

      hasAllResults = allProjects.length;
      hasOwnedResults = ownedProjects.length;
      hasSharedResults = sharedProjects.length;

      if (allProjects.length || ownedProjects.length || sharedProjects.length){
        this.hasResults(true);
      } else {
        this.hasResults(false);
      }
    }

    return (
      <MDBRow className='projects height--full'>
        { (filter === 'all') ?
           hasAllResults ?
              allProjects.map((data, index) => {
                let status = 'all';
                return (
                  this.renderProjectCardView(data,index,status)
                )
              }) :
              this.renderNoResults()
          : null
        }

        { hasOwned && (filter === 'owned') ?
          hasOwnedResults ?
            ownedProjects.map((data, index) => {
              const status = 'owned';
              return (
                this.renderProjectCardView(data,index,status)
              )
            }) :
            this.renderNoResults()
          :
          (filter === 'owned') ?
            this.renderBlankState(filter) : null
        }

        { hasShared && (filter === 'shared') ?
          hasSharedResults ?
            sharedProjects.map((data, index) => {
              let status = 'shared';
              return (
                this.renderProjectCardView(data,index,status)
              )
            }) :
            this.renderNoResults()
          :
          (filter === 'shared') ?
            this.renderBlankState(filter) : null
        }


      </MDBRow>
    );
  }
}

export default ProjectCardView;
