import { emailConstants } from "_constants";
import { emailService } from "_services";
import { alertActions } from "_actions/alert.actions";

export const emailActions = {
  getemails,
  sendEmailReply,
  updateUnreadEmails,
  updateThreadMessages,
  sendNewThreadEmail,
  getDrafts,
  saveDraft,
  saveDraftOther,
  updateDraft,
  deleteDraft
};

function getemails(projectId, leadId, emailNotif) {
  return (dispatch) => {
    dispatch(request(projectId, leadId));
    emailService.getLeadEmailConversations(projectId, leadId).then(
      (emails) => {
        // console.log("get email response: ", emails);
        dispatch(success(emails));
      },
      (error) => {
        const { response } = error;
        const { data } = response;
        dispatch(
          failure({
            error_code: "LE001",
            error_message: "Email History not syncing",
          })
        );
        dispatch(
          alertActions.error({
            error_code: "LE001",
            error_message: "Email History not syncing",
          })
        );
      }
    );
    if (emailNotif) {
      dispatch(notification());
    }
  };

  function request(projectId, leadId) {
    return { type: emailConstants.GET_ALL_EMAIL_REQUEST, projectId, leadId };
  }

  function success(emails) {
    return { type: emailConstants.GET_ALL_EMAIL_SUCCESS, emails };
  }

  function failure(error) {
    return { type: emailConstants.GET_ALL_EMAIL_FAILURE, error };
  }

  function notification() {
    return { type: emailConstants.EMAIL_NOTIFICATION_REQUEST };
  }
}

function sendEmailReply(projectId, leadId, replyRequest) {
  return (dispatch) => {
    dispatch(request(projectId, leadId, replyRequest));
    emailService.agentEmailReply(projectId, leadId, replyRequest).then(
      (emails) => {
        // console.log("response: ", emails);
        dispatch(success(emails));
      },
      (error) => {
        const { response } = error;
        const { data } = response;
        // console.log("email data: ", data);
        dispatch(
          failure({
            error_code: "LC002",
            error_message: "Email Reply unable to send",
          })
        );
        dispatch(
          alertActions.error({
            error_code: "LC002",
            error_message: "Email Reply unable to send",
          })
        );
      }
    );
  };

  function request(projectId, leadId) {
    return {
      type: emailConstants.EMAIL_REPLY_REQUEST,
      projectId,
      leadId,
    };
  }

  function success(emails) {
    return { type: emailConstants.EMAIL_REPLY_SUCCESS, emails };
  }

  function failure(error) {
    return { type: emailConstants.EMAIL_REPLY_FAILURE, error };
  }
}

function sendNewThreadEmail(projectId, leadId, threadRequest) {
  return (dispatch) => {
    dispatch(request(projectId, leadId, threadRequest));
    emailService.agentNewThreadEmail(projectId, leadId, threadRequest).then(
      (emails) => {
        // console.log("response: ", emails);
        dispatch(success(emails));
      },
      (error) => {
        const { response } = error;
        const { data } = response;
        // console.log("email data: ", data);
        dispatch(
          failure({
            error_code: "LC002",
            error_message: "Email Reply unable to send",
          })
        );
        dispatch(
          alertActions.error({
            error_code: "LC002",
            error_message: "Email Reply unable to send",
          })
        );
      }
    );
  };

  function request(projectId, leadId) {
    return {
      type: emailConstants.EMAIL_REPLY_REQUEST,
      projectId,
      leadId,
    };
  }

  function success(emails) {
    return { type: emailConstants.EMAIL_REPLY_SUCCESS, emails };
  }

  function failure(error) {
    return { type: emailConstants.EMAIL_REPLY_FAILURE, error };
  }
}

function updateUnreadEmails(projectId, leadId, unreadRequest) {
  return (dispatch) => {
    dispatch(request(projectId, leadId));
    emailService.updateUnreadEmails(projectId, leadId, unreadRequest).then(
      (emails) => {
        // console.log('response: ', emails)
        dispatch(success(emails));
      },
      (error) => {
        const { response } = error;
        const { data } = response;
        // console.log("email error ", data);
        dispatch(
          failure({
            error_code: "LC001",
            error_message: "Email History not syncing",
          })
        );
        dispatch(
          alertActions.error({
            error_code: "LC001",
            error_message: "Email History not syncing",
          })
        );
      }
    );
  };

  function request(projectId, leadId) {
    return {
      type: emailConstants.UPDATE_EMAIL_UNREAD_REQUEST,
      projectId,
      leadId,
    };
  }

  function success(emails) {
    return { type: emailConstants.UPDATE_EMAIL_UNREAD_SUCCESS, emails };
  }

  function failure(error) {
    return { type: emailConstants.UPDATE_EMAIL_UNREAD_FAILURE, error };
  }
}

function updateThreadMessages(projectId, leadId, thread) {
  return (dispatch) => {
    dispatch(request(projectId, leadId));
    emailService.updateThreadMessages(projectId, leadId, thread).then(
      (emails) => {
        // console.log('response update thread: ', emails)
        dispatch(success(emails));
      },
      (error) => {
        const { response } = error;
        const { data } = response;
        // console.log("email error ", data);
        dispatch(
          failure({
            error_code: "LC001",
            error_message: "Email History not syncing",
          })
        );
        dispatch(
          alertActions.error({
            error_code: "LC001",
            error_message: "Email History not syncing",
          })
        );
      }
    );
  };

  function request(projectId, leadId) {
    return {
      type: emailConstants.UPDATE_EMAIL_UNREAD_REQUEST,
      projectId,
      leadId,
    };
  }

  function success(emails) {
    return { type: emailConstants.UPDATE_EMAIL_UNREAD_SUCCESS, emails };
  }

  function failure(error) {
    return { type: emailConstants.UPDATE_EMAIL_UNREAD_FAILURE, error };
  }
}

function getDrafts(projectId, leadId) {
  return (dispatch) => {
    dispatch(request(projectId, leadId));
    emailService.getEmailDrafts(projectId, leadId).then(
      (drafts) => {
        // console.log('response drafts list: ', drafts)
        dispatch(success(drafts));
      },
      (error) => {
        const { response } = error;
        const { data } = response;
        console.log("draft error ", data);
        dispatch(
          failure({
            error_code: "ED001",
            error_message: "Email Drafts not syncing",
          })
        );
        dispatch(
          alertActions.error({
            error_code: "ED001",
            error_message: "Email Drafts not syncing",
          })
        );
      }
    );
  };

  function request() {
    return {type: emailConstants.EMAIL_DRAFT_REQUEST};
  }

  function success(drafts) {
    return { type: emailConstants.EMAIL_ALL_DRAFT_SUCCESS, drafts };
  }

  function failure(error) {
    return { type: emailConstants.EMAIL_DRAFT_FAILURE, error };
  }
}

function saveDraftOther(projectId, leadId, draftRequest) {
  return (dispatch) => {
    dispatch(request());
    emailService.saveDraft(projectId, leadId, draftRequest).then(
      (drafts) => {
        console.log('response drafts list: ', drafts)
        dispatch(success(drafts));
      },
      (error) => {
        const { response } = error;
        const { data } = response;
        console.log("draft error ", data);
        dispatch(
          failure({
            error_code: "ED001",
            error_message: "Email Drafts not syncing",
          })
        );
        dispatch(
          alertActions.error({
            error_code: "ED001",
            error_message: "Email Drafts not syncing",
          })
        );
      }
    );
  };

  function request(projectId, leadId) {
    return {
      type: emailConstants.EMAIL_REPLY_REQUEST,
      projectId,
      leadId,
    };
  }

  function success(emails) {
    return { type: emailConstants.EMAIL_REPLY_SUCCESS, emails };
  }

  function failure(error) {
    return { type: emailConstants.EMAIL_REPLY_FAILURE, error };
  }
}

function saveDraft(projectId, leadId, draftRequest) {
  return (dispatch) => {
    dispatch(request());
    emailService.saveDraft(projectId, leadId, draftRequest).then(
      (drafts) => {
        console.log('response drafts list: ', drafts)
        dispatch(success(drafts));
      },
      (error) => {
        const { response } = error;
        const { data } = response;
        console.log("draft error ", data);
        dispatch(
          failure({
            error_code: "ED001",
            error_message: "Email Drafts not syncing",
          })
        );
        dispatch(
          alertActions.error({
            error_code: "ED001",
            error_message: "Email Drafts not syncing",
          })
        );
      }
    );
  };

  function request() {
    return {type: emailConstants.EMAIL_DRAFT_REQUEST};
  }

  function success(drafts) {
    return { type: emailConstants.EMAIL_DRAFT_SUCCESS, drafts};
  }

  function failure(error) {
    return { type: emailConstants.EMAIL_DRAFT_FAILURE, error };
  }
}

function updateDraft(projectId, leadId, draftRequest) {
  return (dispatch) => {
    dispatch(request());
    emailService.updateDraft(projectId, leadId, draftRequest).then(
      (drafts) => {
        console.log('response drafts list: ', drafts)
        dispatch(success(drafts));
      },
      (error) => {
        const { response } = error;
        const { data } = response;
        console.log("draft error ", data);
        dispatch(
          failure({
            error_code: "ED001",
            error_message: "Email Drafts not syncing",
          })
        );
        dispatch(
          alertActions.error({
            error_code: "ED001",
            error_message: "Email Drafts not syncing",
          })
        );
      }
    );
  };

  function request() {
    return {type: emailConstants.EMAIL_DRAFT_REQUEST};
  }

  function success(drafts) {
    return { type: emailConstants.EMAIL_DRAFT_SUCCESS, drafts };
  }

  function failure(error) {
    return { type: emailConstants.EMAIL_DRAFT_FAILURE, error };
  }
}

function deleteDraft(projectId, leadId, draftId) {
  return (dispatch) => {
    dispatch(request());
    emailService.deleteDraft(projectId, leadId, draftId).then(
      (drafts) => {
        console.log('response drafts list: ', drafts)
        dispatch(success(drafts));
      },
      (error) => {
        const { response } = error;
        const { data } = response;
        console.log("draft error ", data);
        dispatch(
          failure({
            error_code: "ED001",
            error_message: "Email Drafts not syncing",
          })
        );
        dispatch(
          alertActions.error({
            error_code: "ED001",
            error_message: "Email Drafts not syncing",
          })
        );
      }
    );
  };

  function request() {
    return {type: emailConstants.EMAIL_DRAFT_REQUEST};
  }

  function success(drafts) {
    return { type: emailConstants.EMAIL_DRAFT_SUCCESS, drafts };
  }

  function failure(error) {
    return { type: emailConstants.EMAIL_DRAFT_FAILURE, error };
  }
}
