import React, { useState, useRef } from 'react';
import { SelectPicker } from 'rsuite';
import { TagsInput } from "react-tag-input-component";
import {useDispatch, useSelector} from "react-redux";
import {utilitiesService} from "_services";

import {utmActions} from "../../../_actions";
import {Link, useParams} from "react-router-dom";
import LoadPresetDrawer from './Drawer/LoadPresetDrawer';

const UtmForm = () => {

    const params = useParams();
    const dispatch = useDispatch()

    const [url, setUrl] = useState([]);
    const [label, setLabel] = useState([]);
    const [fieldData, setFieldData] = useState({});
    const [presetName, setPresetName] = useState("");

    const codex = useSelector(state => {
        const {utm} = state;
        return utm.codex;
    });
    // error message
    const [urlErr, setUrlErr] = useState(false);
    const [utmSourceErr, setUtmSourceErr] = useState(false);
    const [utmMediumErr, setUtmMediumErr] = useState(false);
    const [campaignObjectiveErr, setCampaignObjectiveErr] = useState(false);
    const [campaignAudienceTypeErr, setCampaignAudienceTypeErr] = useState(false);
    const [campaignAudienceDescriptionErr, setCampaignAudienceDescriptionErr] = useState(false);
    const [campaignNameObjectiveErr, setCampaignNameObjectiveErr] = useState(false);
    const [campaignNameChannelErr, setCampaignNameChannelErr] = useState(false);
    const [campaignNameContentErr, setCampaignNameContentErr] = useState(false);
    const [campaignNameScheduleErr, setCampaignNameScheduleErr] = useState(false);
    const [utmContentErr, setUtmContentErr] = useState(false);
    const [adVersionErr, setAdVersionErr] = useState(false);
    const [utmTermErr, setUtmTermErr] = useState(false);
    const [groupErr, setGroupErr] = useState(false);

    // data for custom select
    const codexSource = codex.sources.map(
        item => ({ label: item, value: item })
    );
    const codexMedium = codex.medium.map(
        item => ({ label: item, value: item })
    );
    const codexCampaignObjective = codex.campaign_objective.map(
        item => ({ label: item, value: item })
    );
    const codexCampaignAudienceType = codex.campaign_audience_type.map(
        item => ({ label: item, value: item })
    );
    const codexAdFormat = codex.contents.map(
        item => ({ label: item, value: item })
    );
    const codexDevice = codex.device.map(
        item => ({ label: item, value: item })
    );
    const codexDeviceType = codex.device_type.map(
        item => ({ label: item, value: item })
    );
    const codexCountry = codex.country.map(
        item => ({ label: item, value: item })
    );
    const codexAdSize = codex.ad_size.map(
        item => ({ label: item, value: item })
    );
    const codexPlacement = codex.placement.map(
        item => ({ label: item, value: item })
    );

    const handleFieldInputChange = event => {
        const {id, value} = event.target;
        let val = value.trim();
        // console.log(val);
        setFieldData({...fieldData, [id]: val})
        //console.log(fieldData)
    }
    const handleFieldSelectChange = (value, event) => {
        const id = event.currentTarget.parentNode.id.slice(0, -8);
        let val = value.trim();
        // console.log(id);
        setFieldData({...fieldData, [id]: val})
        // console.log(fieldData)
    }

    function handleGenerateUtm() {
        let request = fieldData;

        if (url.length !== 0 && request.utm_source && request.utm_medium && request.campaign_objective && request.campaign_audience_type && request.campaign_audience_description
            && request.campaign_name_objective && request.campaign_name_channel && request.campaign_name_content && request.campaign_name_schedule && request.utm_content
            && request.ad_version && request.utm_term && request.group) 
            {
            request['destination_urls'] = url.join(',');
            request['labels'] = label.join(',');
            setUrlErr(true);
            setUtmSourceErr(false);
            setUtmMediumErr(false);
            setCampaignObjectiveErr(false);
            setCampaignAudienceTypeErr(false);
            setCampaignAudienceDescriptionErr(false);
            setCampaignNameObjectiveErr(false);
            setCampaignNameChannelErr(false);
            setCampaignNameContentErr(false);
            setCampaignNameScheduleErr(false);
            setUtmContentErr(false);
            setAdVersionErr(false);
            setUtmTermErr(false);
            setGroupErr(false);
            dispatch(utmActions.generateUtm(params.id, request));
            
        } else {

            if(url.length !== 0) {
                setUrlErr(false);
            } else {
                setUrlErr(true);
            }
            if(request.utm_source) {
                setUtmSourceErr(false);
            } else {
                setUtmSourceErr(true);
            }
            if(request.utm_medium) {
                setUtmMediumErr(false);
            } else {
                setUtmMediumErr(true);
            }
            if(request.campaign_objective) {
                setCampaignObjectiveErr(false);
            } else {
                setCampaignObjectiveErr(true);
            }
            if(request.campaign_audience_type) {
                setCampaignAudienceTypeErr(false);
            } else {
                setCampaignAudienceTypeErr(true);
            }
            if(request.campaign_audience_description) {
                setCampaignAudienceDescriptionErr(false);
            } else {
                setCampaignAudienceDescriptionErr(true);
            }
            if(request.campaign_name_objective ) {
                setCampaignNameObjectiveErr(false);
            } else {
                setCampaignNameObjectiveErr(true);
            }
            if(request.campaign_name_channel) {
                setCampaignNameChannelErr(false);
            } else {
                setCampaignNameChannelErr(true);
            }
            if(request.campaign_name_content) {
                setCampaignNameContentErr(false);
            } else {
                setCampaignNameContentErr(true);
            }
            if(request.campaign_name_schedule) {
                setCampaignNameScheduleErr(false);
            } else {
                setCampaignNameScheduleErr(true);
            }
            if(request.utm_content) {
                setUtmContentErr(false);
            } else {
                setUtmContentErr(true);
            }
            if(request.ad_version) {
                setAdVersionErr(false);
            } else {
                setAdVersionErr(true);
            }
            if(request.utm_term) {
                setUtmTermErr(false);
            } else {
                setUtmTermErr(true);
            }
            if(request.group) {
                setGroupErr(false);
            } else {
                setGroupErr(true);
            }

            console.log("Empty Fields");
        }
    }

    const [isLoadPresetDrawer, setIsLoadPresetDrawer] = useState(false);

    const handleOpenLoadPresetDrawer = () => {
        setIsLoadPresetDrawer(true);
    }
    const handleCloseLoadPresetDrawer = () => {
        setIsLoadPresetDrawer(false);
    }

    const [isSavePreset, setIsSavePreset] = useState(false);
    const handleSavePresetName = (event) => {
        if (event.target.checked) {
            setIsSavePreset(true);
        } else {
            setIsSavePreset(false);
        }
    }
    const closePresetName = () => {
        setIsSavePreset(false);
    }

    const handleLoadPresetValue = (preset) => {
        console.log(preset);
        const tempData = fieldData;
        Object.keys(preset).forEach(p => {
            tempData[p] = preset[p];
        });

        setFieldData({...fieldData, preset})

    }

    return (
        <>
            <div className='utm-form'>
            <div className='utm-items'>
                    <p className='utm-label-title'>DESTINATION URL/S</p>
                    <div className='utm-items-row'>
                        <div className='utm-col'>
                            <div className='utm-input-group'>
                                <div className='input-group-col'>
                                    <label>ULR/s <small>*</small></label>
                                </div>
                                <div className='input-group-col'>
                                    <div className='input-box rti-box'>
                                        <TagsInput
                                            value={url}
                                            onChange={setUrl}
                                            name="labels"
                                            placeHolder="Enter"
                                        />
                                        {
                                            urlErr && 
                                            <p className='error-field'>This field is required</p>
                                        }
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div className='utm-col'>

                        </div>
                    </div>
                </div>
                <div className='utm-items preset-btn-item'>
                    <div className='utm-items-row'>
                        <div className='utm-col'>
                            <div className='utm-input-group'>
                                <div className='input-group-col'>
                                    <p className='utm-label-title'>SELECT PRESET</p>
                                </div>
                                <div className='input-group-col'>
                                    <div className='btn-group'>
                                        <button className='background-btn' onClick={() => handleOpenLoadPresetDrawer()}>Load Preset</button>
                                        <p>
                                            Preset Name Loaded
                                        </p>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div className='utm-col'>

                        </div>
                    </div>
                </div>
                <div className='utm-items two-col-utm-item'>
                    <div className='utm-items-row'>
                        <div className='utm-col'>
                            <p className='utm-label-title'>UTM PARAMETERS</p>
                            <div className='utm-input-group'>
                                <div className='input-group-col'>
                                    <label>SOURCE (PLATFORM) <small>*</small></label>
                                </div>
                                <div className='input-group-col'>
                                    <div className='input-box'>
                                        {/* <select name={`utm_source`} id={`utm_source`}  onChange={handleFieldInputChangeOld}>
                                            <option selected disabled>Select Source</option>
                                            {codex.sources.map((value, index) => {
                                                return(<option value={value} key={index}>{value}</option>)
                                            })}
                                        </select> */}
                                        <SelectPicker 
                                            data={codexSource} 
                                            cleanable={false}
                                            name="utm_source" 
                                            id="utm_source"
                                            placement="bottomEnd" 
                                            placeholder="Select Source"
                                            onChange={handleFieldSelectChange}
                                        />
                                        {
                                            utmSourceErr && 
                                            <p className='error-field'>This field is required</p>
                                        }
                                    </div>
                                </div>
                            </div>
                            <div className='utm-input-group'>
                                <div className='input-group-col'>
                                    <label>MEDIUM (AD TYPE/ POST TYPE) <small>*</small></label>
                                </div>
                                <div className='input-group-col'>
                                    <div className='input-box'>
                                    {/* <select name={`utm_medium`} id={`utm_medium`}  onChange={handleFieldInputChangeOld}>
                                        <option disabled selected>Select Medium</option>
                                        {codex.medium.map((value, index) => {
                                            return(<option value={value} key={index}>{value}</option>)
                                        })}
                                    </select> */}
                                    <SelectPicker 
                                        data={codexMedium} 
                                        name="utm_medium" 
                                        id="utm_medium"
                                        placement="bottomEnd" 
                                        placeholder="Select Medium"
                                        value={fieldData.utm_medium ? fieldData.utm_medium : null }
                                        onChange={handleFieldSelectChange}
                                    />
                                    {
                                        utmMediumErr && 
                                        <p className='error-field'>This field is required</p>
                                    }
                                    </div>
                                </div>
                            </div>
                            <div className='utm-input-group'>
                                <div className='input-group-col'>
                                    <label>CAMPAIGN OBJECTIVE<small>*</small></label>
                                </div>
                                <div className='input-group-col'>
                                    <div className='input-box'>
                                        {/* <select name={`campaign_objective`} id={`campaign_objective`}  onChange={handleFieldInputChange}>
                                            <option disabled selected>Select Campaign Objective</option>
                                            {codex.campaign_objective.map((value, index) => {
                                                return(<option value={value} key={index}>{value}</option>)
                                            })}
                                        </select> */}
                                        <SelectPicker 
                                            data={codexCampaignObjective} 
                                            name="campaign_objective" 
                                            id="campaign_objective"
                                            value={fieldData.campaign_objective ? fieldData.campaign_objective : null }
                                            placement="bottomEnd"
                                            placeholder="Select Campaign Objective"
                                            onChange={handleFieldSelectChange}
                                        />
                                        {
                                            campaignObjectiveErr && 
                                            <p className='error-field'>This field is required</p>
                                        }
                                    </div>
                                </div>
                            </div>
                            <div className='utm-input-group'>
                                <div className='input-group-col'>
                                    <label>CAMPAIGN AUDIENCE TYPE <small>*</small></label>
                                </div>
                                <div className='input-group-col'>
                                    <div className='input-box'>
                                        {/* <select name={`campaign_audience_type`} id={`campaign_audience_type`}  onChange={handleFieldInputChange}>
                                            <option disabled selected>Select Campaign Audience Type</option>
                                            {codex.campaign_audience_type.map((value, index) => {
                                                return(<option value={value} key={index}>{value}</option>)
                                            })}
                                        </select> */}
                                        <SelectPicker 
                                            data={codexCampaignAudienceType} 
                                            name="campaign_audience_type" 
                                            id="campaign_audience_type"
                                            value={fieldData.campaign_audience_type ? fieldData.campaign_audience_type : null }
                                            placement="bottomEnd"
                                            placeholder="Select Campaign Audience Type"
                                            onChange={handleFieldSelectChange}
                                        />
                                        {
                                            campaignAudienceTypeErr && 
                                            <p className='error-field'>This field is required</p>
                                        }
                                    </div>
                                </div>
                            </div>
                            <div className='utm-input-group'>
                                <div className='input-group-col'>
                                    <label>CAMPAIGN AUDIENCE DESCRIPTION <small>*</small></label>
                                </div>
                                <div className='input-group-col'>
                                    <div className='input-box'>
                                        <input type="text" onChange={handleFieldInputChange} value={fieldData.campaign_audience_description ? fieldData.campaign_audience_description : '' } name={`campaign_audience_description`} id={`campaign_audience_description`}  placeholder='choose or add a campaign' />
                                        {
                                            campaignAudienceDescriptionErr && 
                                            <p className='error-field'>This field is required</p>
                                        }
                                    </div>
                                </div>
                            </div>
                            <div className='utm-input-group'>
                                <div className='input-group-col'>
                                    <label>CAMPAIGN NAME<small>*</small></label>
                                </div>
                                <div className='input-group-col'>
                                    {/*<div className='input-box'>*/}
                                    {/*    <input className='read-only' type="text" value='sample_read_only' readOnly/>*/}
                                    {/*</div>*/}
                                    <div className='input-box'>
                                        <input type="text"
                                               value={fieldData.campaign_name_objective ? fieldData.campaign_name_objective : '' }
                                               onChange={handleFieldInputChange}
                                               name={`campaign_name_objective`}
                                               id={`campaign_name_objective`}
                                               placeholder='OBJECTIVE' />
                                        {
                                            campaignNameObjectiveErr &&
                                            <p className='error-field'>This field is required</p>
                                        }
                                    </div>
                                    <div className='input-box'>
                                        <input type="text"
                                               value={fieldData.campaign_name_channel ? fieldData.campaign_name_channel : '' }
                                               onChange={handleFieldInputChange}
                                               name={`campaign_name_channel`}
                                               id={`campaign_name_channel`}
                                               placeholder='CHANNEL' />
                                        {
                                            campaignNameChannelErr &&
                                            <p className='error-field'>This field is required</p>
                                        }
                                    </div>
                                    <div className='input-box'>
                                        <input type="text"
                                               value={fieldData.campaign_name_content ? fieldData.campaign_name_content : '' }
                                               onChange={handleFieldInputChange}
                                               name={`campaign_name_content`}
                                               id={`campaign_name_content`}
                                               placeholder='CONTENT/KEYWORD TYPE' />
                                        {
                                            campaignNameContentErr &&
                                            <p className='error-field'>This field is required</p>
                                        }
                                    </div>
                                    <div className='input-box'>
                                        <input type="text"
                                               value={fieldData.campaign_name_schedule ? fieldData.campaign_name_schedule : '' }
                                               onChange={handleFieldInputChange}
                                               name={`campaign_name_schedule`}
                                               id={`campaign_name_schedule`}
                                               placeholder='PUBLISHING SCHEDULE' />
                                        {
                                            campaignNameScheduleErr &&
                                            <p className='error-field'>This field is required</p>
                                        }
                                    </div>
                                </div>
                            </div>
                            <div className='utm-input-group'>
                                <div className='input-group-col'>
                                    <label>CONTENT AD FORMAT <small>*</small></label>
                                </div>
                                <div className='input-group-col'>
                                    <div className='input-box'>
                                        {/* <select name={`utm_content`} id={`utm_content`}  onChange={handleFieldInputChange}>
                                            <option disabled selected>Select Content Ad Format</option>
                                            {codex.contents.map((value, index) => {
                                                return(<option value={value} key={index}>{value}</option>)
                                            })}
                                        </select> */}
                                        <SelectPicker
                                            value={fieldData.utm_content ? fieldData.utm_content : '' }
                                            data={codexAdFormat} 
                                            name="utm_content" 
                                            id="utm_content"
                                            placement="bottomEnd" 
                                            placeholder="Select Content Ad Format"
                                            onChange={handleFieldSelectChange}
                                        />
                                        {
                                            utmContentErr && 
                                            <p className='error-field'>This field is required</p>
                                        }
                                    </div>
                                </div>
                            </div>
                            <div className='utm-input-group'>
                                <div className='input-group-col'>
                                    <label>CONTENT AD VERSION <small>*</small></label>
                                </div>
                                <div className='input-group-col'>
                                    <div className='input-box'>
                                        <input type="text"
                                               value={fieldData.ad_version ? fieldData.ad_version : '' }
                                               onChange={handleFieldInputChange}
                                               name={`ad_version`} id={`ad_version`}
                                               placeholder='choose or add a campaign' />
                                        {
                                            adVersionErr && 
                                            <p className='error-field'>This field is required</p>
                                        }
                                    </div>
                                </div>
                            </div>
                            <div className='utm-input-group'>
                                <div className='input-group-col'>
                                    <label>TERM (MAIN KEYWORD) <small>*</small></label>
                                </div>
                                <div className='input-group-col'>
                                    <div className='input-box'>
                                        <input type="text"
                                               value={fieldData.utm_term ? fieldData.utm_term : '' }
                                               onChange={handleFieldInputChange}
                                               name={`utm_term`}
                                               id={`utm_term`}
                                               placeholder='choose or add a campaign' />
                                        {
                                            utmTermErr && 
                                            <p className='error-field'>This field is required</p>
                                        }
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div className='utm-col'>
                            <p className='utm-label-title'>EXTERNAL TAGS</p>
                            <div className='utm-input-group'>
                                <div className='input-group-col'>
                                    <label>Device</label>
                                </div>
                                <div className='input-group-col'>
                                    <div className='input-box'>
                                        {/* <select name={`device`} id={`device`}  onChange={handleFieldInputChange}>
                                            <option disabled selected>Select Device</option>
                                            <option value=""></option>
                                            {codex.device.map((value, index) => {
                                                return(<option value={value} key={index}>{value}</option>)
                                            })}
                                        </select> */}
                                        <SelectPicker 
                                            data={codexDevice}
                                            value={fieldData.device ? fieldData.device : '' }
                                            name="device" 
                                            id="device"
                                            placement="bottomEnd" 
                                            placeholder="Select Device"
                                            onChange={handleFieldSelectChange}
                                        />
                                    </div>
                                </div>
                            </div>
                            <div className='utm-input-group'>
                                <div className='input-group-col'>
                                    <label>Device Type (OS)</label>
                                </div>
                                <div className='input-group-col'>
                                    <div className='input-box'>
                                        {/* <select name={`device_type`} id={`device_type`}  onChange={handleFieldInputChange}>
                                            <option disabled selected>Select Device Type</option>
                                            <option value=""></option>
                                            {codex.device_type.map((value, index) => {
                                                return(<option value={value} key={index}>{value}</option>)
                                            })}
                                        </select> */}
                                        <SelectPicker 
                                            data={codexDeviceType}
                                            value={fieldData.device_type ? fieldData.device_type : '' }
                                            name="device_type" 
                                            id="device_type"
                                            placement="bottomEnd" 
                                            placeholder="Select Device Type"
                                            onChange={handleFieldSelectChange}
                                        />
                                    </div>
                                </div>
                            </div>
                            <div className='utm-input-group'>
                                <div className='input-group-col'>
                                    <label>Country</label>
                                </div>
                                <div className='input-group-col'>
                                    <div className='input-box'>
                                        {/* <select name={`country`} id={`country`}  onChange={handleFieldInputChange}>
                                            <option disabled selected>Select Country</option>
                                            <option value=""></option>
                                            {codex.country.map((value, index) => {
                                                return(<option value={value} key={index}>{value}</option>)
                                            })}
                                        </select> */}
                                        <SelectPicker 
                                            data={codexCountry}
                                            value={fieldData.country ? fieldData.country : '' }
                                            name="country" 
                                            id="country"
                                            placement="bottomEnd" 
                                            placeholder="Select Country"
                                            onChange={handleFieldSelectChange}
                                        />
                                    </div>
                                </div>
                            </div>
                            <div className='utm-input-group'>
                                <div className='input-group-col'>
                                    <label>Region</label>
                                </div>
                                <div className='input-group-col'>
                                    <div className='input-box'>
                                        <input type="text"
                                               value={fieldData.region ? fieldData.region : '' }
                                               onChange={handleFieldInputChange}
                                               name={`region`}
                                               id={`region`}
                                               placeholder='choose or add a campaign' />
                                    </div>
                                </div>
                            </div>
                            <div className='utm-input-group'>
                                <div className='input-group-col'>
                                    <label>City</label>
                                </div>
                                <div className='input-group-col'>
                                    <div className='input-box'>
                                        <input type="text"
                                               value={fieldData.city ? fieldData.city : '' }
                                               onChange={handleFieldInputChange}
                                               name={`city`}
                                               id={`city`}
                                               placeholder='choose or add a campaign' />
                                    </div>
                                </div>
                            </div>
                            <div className='utm-input-group'>
                                <div className='input-group-col'>
                                    <label>Ad Size</label>
                                </div>
                                <div className='input-group-col'>
                                    <div className='input-box'>
                                        {/* <select name={`ad_size`} id={`ad_size`}  onChange={handleFieldInputChange}>
                                            <option disabled selected>Select Ad Size</option>
                                            {codex.ad_size.map((value, index) => {
                                                return(<option value={value} key={index}>{value}</option>)
                                            })}
                                        </select> */}
                                        <SelectPicker

                                            data={codexAdSize}
                                            value={fieldData.ad_size ? fieldData.ad_size : '' }
                                            name="ad_size" 
                                            id="ad_size"
                                            placement="bottomEnd" 
                                            placeholder="Select Ad Size"
                                            onChange={handleFieldSelectChange}
                                        />
                                    </div>
                                </div>
                            </div>
                            <div className='utm-input-group'>
                                <div className='input-group-col'>
                                    <label>Placement</label>
                                </div>
                                <div className='input-group-col'>
                                    <div className='input-box'>
                                        {/* <select name={`placement`} id={`placement`}  onChange={handleFieldInputChange}>
                                            <option disabled selected>Select Placement</option>
                                            {codex.placement.map((value, index) => {
                                                return(<option value={value} key={index}>{value}</option>)
                                            })}
                                        </select> */}
                                        <SelectPicker 
                                            data={codexPlacement}
                                            value={fieldData.placement ? fieldData.placement : '' }
                                            name="placement" 
                                            id="placement"
                                            placement="bottomEnd" 
                                            placeholder="Select Placement"
                                            onChange={handleFieldSelectChange}
                                        />
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <div className='utm-items'>
                    <p className='utm-label-title'>MISCELLANEOUS</p>
                    <div className='utm-items-row'>
                        <div className='utm-col'>
                            <div className='utm-input-group'>
                                <div className='input-group-col'>
                                    <label>GROUP NAME <small>*</small></label>
                                </div>
                                <div className='input-group-col'>
                                    <div className='input-box'>
                                        <input type="text" onChange={handleFieldInputChange} name={`group`} id={`group`} />
                                        {
                                            groupErr && 
                                            <p className='error-field'>This field is required</p>
                                        }
                                    </div>
                                </div>
                            </div>
                            <div className='utm-input-group'>
                                <div className='input-group-col'>
                                    <label>LABELS</label>
                                </div>
                                <div className='input-group-col'>
                                    <div className='input-box rti-box'>
                                        <TagsInput
                                            value={label}
                                            onChange={setLabel}
                                            name="labels"
                                            placeHolder="Enter"
                                        />
                                    </div>
                                </div>
                            </div>
                            <div className='utm-input-group'>
                                <div className='input-group-col'>
                                    <label>NOTES</label>
                                </div>
                                <div className='input-group-col'>
                                    <div className='input-box'>
                                        <textarea onChange={handleFieldInputChange} name={`notes`} id={`notes`}  />
                                    </div>
                                </div>
                            </div>
                            {/*<div className='utm-input-group'>*/}
                            {/*    <div>*/}
                            {/*        <label>SHORT URL <small></small></label>*/}
                            {/*    </div>*/}
                            {/*    <div>*/}
                            {/*        <div className='input-box'>*/}
                            {/*            <input type="text" />*/}
                            {/*        </div>*/}
                            {/*    </div>*/}
                            {/*</div>*/}
                            <div className='utm-input-group utm-buttons'>
                                <div className='input-group-col'></div>
                                <div className='input-group-col'>
                                    <button onClick={() => handleGenerateUtm()} className='background-btn'>Save UTM</button>
                                    {/*<button className='bordered-btn'>Discard Changes</button>*/}
                                    <div className='save-preset-box'>
                                            <div className={isSavePreset ? 'checkbox-group hide' : 'checkbox-group'}>
                                                <label htmlFor="savePreset">Save as Preset</label>
                                                <input type="checkbox" name="savePreset" id="savePreset" value={isSavePreset} onChange={handleSavePresetName} checked={isSavePreset} />
                                            </div>
                                            <div className={isSavePreset ? 'input-box active' : 'input-box'}>
                                                <i className="fas fa-times" onClick={() => closePresetName()}></i>
                                                <input type="text" name="preset-name" id="preset-name" placeholder="Enter preset name" />
                                                <span>Save</span>
                                            </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div className='utm-col'>

                        </div>
                    </div>
                </div>
            </div>

            <LoadPresetDrawer 
                isLoadPresetDrawer={isLoadPresetDrawer}
                onHandleLoadPresetValue={handleLoadPresetValue}
                onHandleCloseLoadPresetDrawer={handleCloseLoadPresetDrawer}
            />
        </>
    )
}

export default UtmForm