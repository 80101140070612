import React, {Component, useState} from 'react'
import { MDBContainer, MDBRow, MDBCol, MDBModal, MDBModalHeader, MDBModalBody } from 'mdbreact'
import {influencerActions} from "_actions";
import {connect} from "react-redux";

class ManagePlatformModal extends Component {

    constructor(props) {
        super(props);

        this.state = {
            isTypeError: false,
            isLinkError: false,
            isValueError: false,
            isOtherError: false,
            isOthers: false,
            toggleTabState: 1,
        }
      
        this.typeInput = React.createRef();
        this.linkInput = React.createRef();
        this.valueInput = React.createRef();
        this.othersInput = React.createRef();
    }

    handleCloseManagePlatformModal = (mode) => {
        this.props.handleCloseManagePlatformModal(mode);
        this.setType();
        this.setLink();
        this.setValue();
        this.handleClearInput();
        this.setState({'alt': undefined});
    }

    setType = value => {
        this.setState({ isTypeError: false });
        if (value === "platform-others") {
          this.setState({ isOthers: true });
        } else {
          this.setState({ isOthers: false });
        }
    }

    handleClearInput = () => {
        // Clear the current.value of the ref
        console.log('clear called... ');
        const {mode, data} = this.props;

        if (mode === 'add') {
            this.typeInput.current.value = 'Select Platform';
            this.linkInput.current.value = '';
            this.valueInput.current.value = '';
            this.othersInput.current.value = '';
        }

        if (mode === 'edit') {
            this.linkInput.current.value = data.link;
            this.valueInput.current.value = data.value;
        }
        
      }

    setLink = value => {
        this.setState({isLinkError: false});
    }

    setValue = value => {
        this.setState({isValueError: false});
    }

    setAlt = value => {
        this.setState({isOtherError: false});
    }

    isFieldValid() {

        let isValid = true;

        if(this.typeInput.current) {
            const type = this.typeInput.current.value;

            if(type === 'Select Platform' || type === '') {
                this.setState({isTypeError: true});
                isValid = false;
              }

            if(type === 'platform-others') {
                const others = this.othersInput.current.value;
                if(others === '') {
                    this.setState({isOtherError: true});
                    isValid = false;
                }
    
            }
        }
    
      //  const type = this.typeInput.current.value;
        const link = this.linkInput.current.value;
        const value = this.valueInput.current.value;
    
        if(link === '') {
          this.setState({isLinkError: true});
          isValid = false;
        }

        if(value === '') {
            this.setState({isValueError: true});
            isValid = false;
        }
    
        return isValid;
    }

    handleManagePlatform() {

        const { mode } = this.props;
    
        let isEdit = mode !== 'add';
    
        const isValue = this.isFieldValid();
    
    
        if(isValue) {
          if(isEdit) {
            this.handleEditInfluencerPlatform();
          } else {
            this.handleAddInfluencerPlatform();
          }
        }
        // this.handleClearInput();
    }

    handleAddInfluencerPlatform () {
        const {influencerId, projectId, addInfluencerPlatform, mode} = this.props;

        const type = this.typeInput.current.value;
        const link = this.linkInput.current.value;
        const value = this.valueInput.current.value;
        const others = this.othersInput.current.value;

        let platform = {
            type: type,
            link: link,
            value: value,
            alt: ''
        }

        if(type === 'platform-others') {
            platform.alt = others;
        }

        addInfluencerPlatform(projectId, influencerId, platform);


        this.handleCloseManagePlatformModal(mode);
    }

    handleEditInfluencerPlatform () {
        const {influencerId, projectId, updateInfluencerPlatform, mode, data} = this.props;

        // const type = this.typeInput.current.value;
        const link = this.linkInput.current.value;
        const value = this.valueInput.current.value;

        const platform = {
            link: link,
            value: value
        }

        console.log('edit platform: ', platform);

        updateInfluencerPlatform(projectId, influencerId, data.id, platform);

        this.handleCloseManagePlatformModal(mode);
    }

    handleUserInput = (e) => {

    }

    handleKeyPress = (e) => {
        const charCode = e.which ? e.which : e.keyCode;
        // Prevent entering characters that are not digits and not the backspace (key code 8)
        if (charCode !== 8 && (charCode < 48 || charCode > 57)) {
          e.preventDefault();
        }
      };

    toggleTab = (index) => {
        this.setState({toggleTabState: index});
    }

    componentDidUpdate(prevProps) {
        // Check if the props or state have changed
        if (prevProps.mode !== this.props.mode) {
          // Perform the necessary update
          if (this.props.mode === 'add') {
            this.typeInput.current.value = 'Select Platform';
            this.linkInput.current.value = '';
            this.valueInput.current.value = '';
        }

        if (this.props.mode === 'edit') {
            console.log('data: ', this.props.data);
            this.linkInput.current.value = this.props.data.link;
            this.valueInput.current.value = this.props.data.value;
        }
        }
      }

    render() {
        const { influencers, isOpen, mode, data } = this.props;
        const {isTypeError, isLinkError, isValueError, isOtherError, isOthers} = this.state;

        let isEdit = mode !== 'add';
        let activeClass = isOpen ? 'active' : '';

        let types = [];

        if(influencers.getPlatFormTypeLoaded) {
            types = influencers.platformTypes;
        }

        // if(isEdit) {
        //     console.log("testedit")
        // } else {
        //     console.log("testadd")
        // }

        return (
            <React.Fragment>
                <div className={`drawer-overlay ${activeClass}`} onClick={()=> this.handleCloseManagePlatformModal() }></div>
                <div className={`side-drawer-main ${activeClass}`}>
                    <div className='side-drawer-wrapper'>
                        <div className='side-drawer-title'>
                            <h3>{mode.toUpperCase()} SOCIAL MEDIA PLATFORM</h3>
                            <span className='side-drawer-close' onClick={()=> this.handleCloseManagePlatformModal() }><i className="icon-lms icon-close"></i></span>
                        </div>
                        <div className='side-drawer-tabs'>
                            <div className='side-drawer-tabs-wrapper'>
                                <div className='control-tabs-box'>
                                    <div className={this.state.toggleTabState === 1 ? 'tabs-btn active' : 'tabs-btn'} onClick={() => this.toggleTab(1)}>
                                        <p>
                                            Platform Information
                                        </p>
                                    </div>
                                </div>
                                <div className='content-tabs-box'>
                                    <div className={this.state.toggleTabState === 1 ? 'tabs-content active' : 'tabs-content'}>
                                        <div className='forms-tab'>
                                            <MDBRow>
                                                <MDBCol className='col-12 form-group-box'>
                                                    <div className='input-group-box full-box-'>
                                                        {mode === 'edit' ? '' :
                                                        <div className='field-box'>
                                                            <label htmlFor={'platform_type'}>Social Media Platform <small>*</small></label>
                                                            <select className='c-select' ref={this.typeInput} id={'platform_type'} name={'platform_name'}  onChange={(e) => this.setType(e.target.value)}>
                                                                <option hidden>Select Platform</option>
                                                                {types.map((type,index)=> {
                                                                    return(<option value={type.code} key={index}>{type.name}</option>)
                                                                })}
                                                            </select>
                                                            {isTypeError &&<p className='error-field'>This field is required</p>}
                                                            <div className={isOthers ? 'other-platform active' : 'other-platform'}>
                                                                <label htmlFor={'alt'}>Others</label>
                                                                <input ref={this.othersInput} id={'alt'} name={'alt'} onChange={(e) => this.setAlt(e.target.value)} type='text' />
                                                                {isOtherError &&<p className='error-field'>This field is required</p>}
                                                            </div>
                                                        </div>
                                                        }
                                                        <div className={isLinkError ? `field-box error-field` : `field-box`}>
                                                            <label htmlFor={'link'}>Social Media Url <small>*</small></label>
                                                            <input ref={this.linkInput} id={'link'} name={'link'} defaultValue={isEdit ? data.link : ''} onChange={(e) => this.setLink(e.target.value)} type='text' />
                                                            {isLinkError &&<p className='error-field'>This field is required</p>}
                                                        </div>
                                                        <div className={isValueError ? `field-box error-field` : `field-box`}>
                                                            <label htmlFor={'value'}>Followers / Subscribers <small>*</small></label>
                                                            <input ref={this.valueInput} id={'value'}  name={'value'} defaultValue={isEdit ? data.value : ''}  onKeyPress={this.handleKeyPress} onChange={(e) => this.setValue(e.target.value)} type='number' />
                                                            {isValueError &&<p className='error-field'>This field is required</p>}
                                                        </div>
                                                    </div>
                                                </MDBCol>
                                            </MDBRow>
                                        </div>
                                    </div>
                                </div>
                                <div className='tab-form-btn-group'>
                                    <MDBRow>
                                        <MDBCol className='text-right'>
                                            <span className='drawer-btn cancel-btn' onClick={()=> this.handleCloseManagePlatformModal() }>
                                                Cancel
                                            </span>
                                            <span className='drawer-btn save-btn' onClick={() => this.handleManagePlatform()}>
                                                {mode === 'add' ? `Save Platform` : `Update Platform`}
                                            </span>
                                        </MDBCol>
                                    </MDBRow>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                {/* <MDBContainer className='modal-container modal-form-container'>
                    <MDBRow>
                        <MDBCol>
                            <MDBModal isOpen={isOpen} centered tabIndex='-1'>
                                <MDBModalHeader className='confirmation-modal-header text-uppercase p-2'><span className='pl-1 mx-2 letter-spacing-1 font-weight-600 font-color-gray font-size-13'>{mode.toUpperCase()} SOCIAL MEDIA PLATFORM</span></MDBModalHeader>
                                <MDBModalBody>
                                    <MDBRow className='form-group-row'>
                                        {mode === 'edit' ? '' :
                                        <MDBCol className={`col-12`}>
                                            <label htmlFor={'platform_type'}>Social Media Platform</label>
                                            <select ref={this.typeInput} id={'platform_type'} name={'platform_name'}  onChange={(e) => this.setType(e.target.value)}>
                                                <option hidden>Select Platform</option>
                                                {types.map((type,index)=> {
                                                    return(<option value={type.code} key={index}>{type.name}</option>)
                                                })}
                                            </select>
                                            {isTypeError &&<span>This field is required</span>}
                                            <div className={isOthers ? 'other-platform active' : 'other-platform'}>
                                                <label htmlFor={'alt'}>Others</label>
                                                <input id={'alt'} name={'alt'} onChange={(e) => this.setAlt(e.target.value)} type='text' />
                                            </div>
                                        </MDBCol>
                                        }
                                        <MDBCol className={isLinkError ? `col-12 has-error-input` : `col-12`}>
                                            <label htmlFor={'link'}>Social Media Url</label>
                                            <input ref={this.linkInput} id={'link'} name={'link'} defaultValue={isEdit ? data.link : ''} onChange={(e) => this.setLink(e.target.value)} type='text' />
                                            {isLinkError &&<span>This field is required</span>}
                                        </MDBCol>
                                        <MDBCol className={isValueError ? `col-12 has-error-input` : `col-12`}>
                                            <label htmlFor={'value'}>Followers / Subscribers</label>
                                            <input ref={this.valueInput} id={'value'}  name={'value'} defaultValue={isEdit ? data.value : ''} onChange={(e) => this.setValue(e.target.value)} type='number' />
                                            {isValueError &&<span>This field is required</span>}
                                        </MDBCol>
                                    </MDBRow>
                                    <MDBRow>
                                        <MDBCol className='text-center'>
                                            <button className='modal-btn save-btn-modal' onClick={() => this.handleManagePlatform()}>
                                                {mode === 'add' ? `Save Platform` : `Update Platform`}
                                            </button>
                                            <button className='modal-btn cancel-btn-modal' onClick={()=> this.handleCloseManagePlatformModal() }>
                                                Cancel
                                            </button>
                                        </MDBCol>
                                    </MDBRow>
                                </MDBModalBody>
                            </MDBModal>
                        </MDBCol>
                    </MDBRow>
                </MDBContainer> */}
            </React.Fragment>
        )
    }

}

function mapState(state) {
    const { influencers } = state;
    const {addInfluencerPlatformLoaded} = influencers;
    return { influencers, addInfluencerPlatformLoaded }
}

const actionCreators = {
    addInfluencerPlatform : influencerActions.addInfluencerPlatform,
    updateInfluencerPlatform : influencerActions.updateInfluencerPlatform,
    getPlatformTypes : influencerActions.getPlatformTypes
};

const connectedViewManagePlatformModal = connect(mapState, actionCreators)(ManagePlatformModal);
export { connectedViewManagePlatformModal as ManagePlatformModal };