import React from "react";

const TableMainLoader = () => {
  return (
    <div className="skeleton-loader skeleton-table-main">
      <div className="skeleton skeleton-box"></div>
    </div>
  );
};

export default TableMainLoader;
