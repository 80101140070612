import { httpManagerService as httpManager } from  '_services';

export const utmService = {
    getUtms,
    generateUtm,
    getCodex,
    getPresets,
}

function getUtms(projectId,  query = null, page = 1, size = 15){

    let parts = [];

    if(query !== null && query !== '') {
        parts.push(`query=${query}`);
    }

    if(page != null && page > 0) {
        parts.push(`page=${page}`);
    }

    if(size != null && size > 0) {
        parts.push(`size=${size}`);
    }

    return httpManager.getHttpWithRequestingToken(`/api/v2/project/${projectId}/utm?` + parts.join('&',));
}

function generateUtm(projectId, request){
    return httpManager.postJsonOnce(`/api/v2/project/${projectId}/utm`, request);
}

function getCodex(projectId){
    return httpManager.getHttpWithRequestingToken(`/api/v2/project/${projectId}/utm/codex`);
}

function getPresets(projectId){
    return httpManager.getHttpWithRequestingToken(`/api/v2/project/${projectId}/utm/preset`);
}



