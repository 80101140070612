import React from 'react';
import { connect } from "react-redux";
import { leadActions } from '_actions';

import { MDBModal, MDBModalBody, MDBModalHeader, MDBContainer, MDBRow, MDBCol } from 'mdbreact';
import { Button } from "blackbox-react";
import InputForm from '_components/InputForm';

import { FormValidation } from '_components/FormValidation';

import { Loader } from '_components/Loader';

class AddLeadNoteModal extends React.Component {
	constructor(props) {
		super(props);

		this.state = {
			note: {
				noteTitle: '',
				noteBody: '',
			},
			formErrors: {
				noteTitle: 'Please enter a title.',
				noteBody: 'Please enter your notes.'
			},
			noteTitleValid: false,
			noteBodyValid: false,
			formValid: false,
			submitted: false,
			modalOpen: false,
			class: 'orange'
		};

		this.handleChange = this.handleChange.bind(this);
		this.handleSubmit = this.handleSubmit.bind(this);
	}

	componentDidMount = () => {
		this.setState({
			modalOpen: this.props.modalOpen
		});
	};

	handleGetNotes = () => {
		return this.props.getLeadNotes();
	};

	handleToggle = (e) => {
		this.setState({
			note: {
				noteTitle: '',
				noteBody: '',
			},
			formErrors: {
				noteTitle: 'Please enter a title.',
				noteBody: 'Please enter your notes.'
			},
			noteTitleValid: false,
			noteBodyValid: false,
			formValid: false,
			submitted: false,
		});
	};

	validateField(fieldName, value) {
		let fieldValidationErrors = this.state.formErrors;
		let noteTitleValid = this.state.noteTitleValid;
		let noteBodyValid = this.state.noteBodyValid;

		let isAllWhitespace = (value.replace(/(?!^ +$)^.+$/gm, "") !== '');

		switch(fieldName) {
			case 'noteTitle':
				if (value === "" || isAllWhitespace) { // check if empty
					noteTitleValid = false;
					fieldValidationErrors.noteTitle = 'Please enter a title.';
				} else {
					noteTitleValid = (value.length >= 0); // check if valid
					fieldValidationErrors.noteTitle = noteTitleValid ? '': 'Please enter a title.';
				}
				break;
			case 'noteBody':
				if (value === "" ) { // check if empty
					noteBodyValid = false;
					fieldValidationErrors.noteBody = 'Please enter your notes.';
				} else {
					noteBodyValid = (value.length >= 0); // check if valid
					fieldValidationErrors.noteBody = noteBodyValid ? '': 'Please enter your notes.';
				}
				break;
			default:
				break;
		}

		this.setState({
			formErrors: fieldValidationErrors,
			noteTitleValid: noteTitleValid,
			noteBodyValid: noteBodyValid
		}, this.validateForm);
	}

	validateForm() {
		this.setState({
			formValid: this.state.noteTitleValid && this.state.noteBodyValid
		});
	}

	handleChange(event) {
		const { name, value } = event.target;
		const { note } = this.state;

		this.setState({
			note: {
				...note,
				[name]: value
			}
		}, () => {
			this.validateField(name, value);
		});
	};

	handleSubmit(event) {
		let addLocation = this.props.addLocation;
		event.preventDefault();
		const { note } = this.state;
		const projectId = sessionStorage.getItem('projectId');
		const leadId = this.props.leadId;
		const projectStatusId = this.props.projectStatusId;
		const user = JSON.parse(sessionStorage.getItem('user'));
		const userEmail = user && user.data.email;

		this.validateForm();

		this.setState({ submitted: true }, () => {
			if (this.state.formValid) {
				if(this.state.submitted){
					// event.target.style.visibility = 'hidden';

					let noteData = {
						name: note.noteTitle,
						description: note.noteBody,
						project_status_id: projectStatusId,
						sender: userEmail
					};

					let noteType = 'note';

					this.props.addLeadNote(projectId, leadId, noteType, noteData);

					let { modalOpen, toggleAddLeadNote } = this.props;
					if(modalOpen){
						toggleAddLeadNote();
					}

					this.setState({
						noteTitleValid: false,
						noteBodyValid: false,
						formValid: false,
						submitted: false,
						formErrors: {
							noteTitle: 'Please enter a title.',
							noteBody: 'Please enter your notes.'
						},
					});

					for (const prop of Object.keys(note)) {
						delete note[prop];
					}

					if(addLocation === 'leadInfo'){
						this.props.handleGetLead();
					}
					if(addLocation === 'allLeads'){
						this.props.handleGetLeads();
					}
				}
			}
		});
	};

	errorClass(error) {
		return(error.length === 0 ? 'is-passed' : 'is-failed');
	}

	render() {
		const { creating  } = this.props;
		let { modalOpen, toggleAddLeadNote } = this.props;
		const { note, submitted, formValid } = this.state;
		return (
			<MDBContainer>
				<form noValidate>
					<MDBModal isOpen={modalOpen} toggle={toggleAddLeadNote} className='create-form-modal' centered>
						<MDBModalHeader className='padding-left-18 padding-right-18 pb-3 pt-3' toggle={toggleAddLeadNote} onClick={(e) => this.handleToggle(e)}>
							<p className='mb-0 font-size-12 font-weight-600 letter-spacing-1'>ADD NOTE</p>
						</MDBModalHeader>
						<MDBModalBody className='p-t--0 padding-left-18 padding-right-18 padding-bottom-15'>
							<MDBRow>
								<MDBCol className={`form-group m--sm ${submitted && this.errorClass(this.state.formErrors.noteTitle)}`}>
									<label className='mb-1 font-size-12 text-black'>Title <span className='color-red'>*</span></label>
									<InputForm
										onChange={this.handleChange}
										type='text'
										name='noteTitle'
										value={note.noteTitle}
										id='FormName'
										hint='Enter note title here...'
									/>
									<div className={`form-validation__container ${(!formValid && submitted) ? 'display-block' : 'display-none'}`}>
										<FormValidation fieldName='noteTitle' formErrors={this.state.formErrors}/>
									</div>
								</MDBCol>
							</MDBRow>
							<MDBRow>
								<MDBCol className={`form-group m--sm ${submitted && this.errorClass(this.state.formErrors.noteBody)}`}>
									<label className='mb-1 pt-2 font-size-12 text-black'>Body <span className='color-red'>*</span></label>
									<textarea
										name='noteBody'
										value={note.noteBody}
										id='textarea-noteBody'
										className='form-control font-size-12 color-none'
										rows='7'
										placeholder='Enter notes here...'
										onChange={this.handleChange}
									/>
									<div className={`form-validation__container ${(!formValid && submitted) ? 'display-block' : 'display-none'}`}>
										<FormValidation fieldName='noteBody' formErrors={this.state.formErrors}/>
									</div>
								</MDBCol>
							</MDBRow>
							<MDBRow className='p-t--md'>
								<MDBCol className='d-flex'>
                  <span
	                  className='mb-0 color-gray my-auto text-cursor-pointer border-bottom-gray line-height-15 font-size-12'
	                  onClick={toggleAddLeadNote}
                  >
                    Cancel
									</span>
								</MDBCol>
								<MDBCol
									className='text-right'
									id='nextButton'
								>
									<Button
										flat='true'
										type='submit'
										size={-1}
										background='orange'
										onClick={this.handleSubmit}
									>Add Note {creating &&
									<Loader />
									}
									</Button>
								</MDBCol>
							</MDBRow>
						</MDBModalBody>
					</MDBModal>
				</form>
			</MDBContainer>
		);
	}
}

function mapState(state) {
	const { creating, leads } = state;
	return { creating, leads };
}

const actionCreators = {
	addLeadNote: leadActions.addLeadNote,
	//getLeadNotes: leadActions.getLeadNotes
};

const connectedAddLeadNoteModal = connect(mapState, actionCreators)(AddLeadNoteModal);
export { connectedAddLeadNoteModal as AddLeadNoteModal };
