import { httpManagerService as httpManager } from  '_services';

export const calendlyService = {
    getCalendly,
    createCalendly,
    updateCalendly,
    removeCalendly,
}


function getCalendly(projectId, memberId){
    return httpManager.getHttpWithRequestingToken(`/api/v2/project/${projectId}/member/${memberId}`);
}

function createCalendly(projectId, memberId, request){
    return httpManager.postJsonOnce(`/api/v2/project/${projectId}/member/${memberId}`, request);
}

function updateCalendly(projectId, calendlyId, request){
    return httpManager.putJsonOnce(`/api/v2/project/${projectId}/calendly/${calendlyId}`, request);
}

function removeCalendly(projectId, calendlyId){
    return httpManager.deleteJsonWithRequestingToken(`/api/v2/project/${projectId}/calendly/${calendlyId}`);
}

