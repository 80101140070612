import React, {useState, useRef, useEffect} from "react";
import TableControl from './components/Table/TableControl';
import TableList from "./components/Table/TableList";
import TableGroupMain from "./components/Table/TableGroup";
import TopBar from "_components/TopBar";
import {useDispatch} from "react-redux";
import {Link, useParams} from "react-router-dom";
import {projectActions, utmActions} from "_actions";


const UtmTable = () => {

  const dispatch = useDispatch();
  const params = useParams();

  const [isListView, setIsListView] = useState(true);
  const [isGroupView, setIsGroupView] = useState(false);

  const handleTableListView = () => {
    setIsListView(true);
    setIsGroupView(false)
  }

  const handleTableGroupView = () => {
    setIsGroupView(true);
    setIsListView(false);
  }


  useEffect(() => {
    dispatch(projectActions.getProject(params.id));
    dispatch(utmActions.getUtms(params.id));
  }, [params.id]);

  return (
    <>
      <div className="page-title-ctas">
        <div className="row align-items-center">
          <TopBar
              onHandleProjectDetailsDrawer={(width, project) => this.handleProjectDetailsDrawer(width, project)}
              onHandleProjectMembersDrawer={(width, owner, member) => this.handleProjectMembersDrawer(width, owner, member)}
          />

          <div className='col'>
            <div className="button-group">
              <Link to={`utm/create`} className="bordered-btn">
                <i className="fas fa-plus"></i>
                Build Campaign URL
              </Link>
            </div>
          </div>
        </div>
      </div>
      <div className="main-table-section">
        <div className="table-control-box">
          <TableControl 
            isListView={isListView}
            isGroupView={isGroupView}
            onHandleTableListView={() => handleTableListView()}
            onHandleTableGroupView={() => handleTableGroupView()}
          />
        </div>
        <div className="table-view table-utm">
          <div className="table-scrollable">
            {
              isListView &&
              <TableList />
            }
            {
              isGroupView &&
              <TableGroupMain />
            }
          </div>
        </div>
      </div>
    </>
  );
};

export default UtmTable;
