import React, { Component, useState } from "react";
import { Router, Route, Switch, Redirect } from "react-router-dom";
import { connect, useDispatch, useSelector } from "react-redux";
import { history } from "_helpers";
import { alertActions, chatActions, leadActions, emailActions } from "_actions";
import { PrivateRoute } from "_components/PrivateRoute";

import { Login } from "_pages/Login/Login";
import { ForgotPassword } from "_pages/Login/ForgotPassword";
import { UpdatePassword } from "_pages/Login/UpdatePassword";
import { RegisterFirstStep } from "_pages/Register/RegisterFirstStep";

import SideNav from "_components/SideNav";

import { Projects } from "_pages/Projects/Projects";
import { ProjectInfo } from "_pages/Projects/ProjectInfo";
import { ProjectSettings } from "_pages/Projects/ProjectSettings";
import ProfileSettings from "_pages/Projects/ProfileSettings";
import ManageEmailTemplates from "_pages/Projects/ManageEmailTemplates";
import { ProjectViewRecentActivities } from "_pages/Projects/ProjectViewRecentActivities";
import { ManageLeadStatus } from "_pages/Projects/ManageLeadStatus";

import { Forms } from "_pages/Forms/Forms";
import { FormBuilder } from "_pages/Forms/FormBuilder";
import { FormEmailConnections } from "_pages/Forms/FormEmailConnections";
import { FormSuccessMessage } from "_pages/Forms/FormSuccessMessage";
import { FormIntegration } from "_pages/Forms/FormIntegration";

import { Leads } from "_pages/Leads/Leads";
import { LeadInfo } from "_pages/Leads/LeadInfo";
import { AddLead } from "_pages/Leads/AddLead";
import { ManageFields } from "_pages/Leads/ManageFields";
import { ManageLead } from "_pages/Leads/ManageLead";

import { LeadImport } from "_pages/Leads/ImportLeadManagement/LeadImport";
import { LeadMapField } from "_pages/Leads/ImportLeadManagement/LeadMapField";
import { ImportResult } from "_pages/Leads/ImportLeadManagement/ImportResult";
import { ImportHistory } from "_pages/Leads/ImportLeadManagement/ImportHistory";
import Members from "_pages/Projects/Members";

import { Influencer } from "_pages/Influencer/Influencer";
import ManageUtm from "_pages/Utm/ManageUtm";
import UtmTable from "_pages/Utm/UtmTable";

// propelrr influencer
import Propelrr from "_pages/Propelrr/Propelrr";
import ViewInfluencer from "./_pages/Propelrr/ViewInfluencer";
import AddPropelrrInfluencer from "_pages/Propelrr/AddInfluencer";
import EditInfluencer from "./_pages/Propelrr/EditInfluencer";

import { InfluencerInfo } from "_pages/Influencer/InfluencerInfo";
import { Blank } from "_components/Blank";
import "_assets/_css/_misc/swiper.min.css";
import { isTokenFound, onMessageListener, retrieveToken } from "firebase";
import { httpManagerService, utilitiesService as utility } from "_services";
import PushNotification from "./_components/PushNotification";

import "_assets/_css/general.css";
import TwoFactorSelection from "./_pages/Login/TwoFactorSelection";
import TwoFactorAppAuthentication from "./_pages/Login/TwoFactorAppAuthentication";
import TwoFactorEmailAuthentication from "_pages/Login/TwoFactorEmailAuthentication";

function App() {
  const [retrievedToken, setRetrievedToken] = useState(false);
  const [pushNotificationShown, setPushNotificationShown] = useState(false);
  const [notification, setNotification] = useState({ title: "", body: "" });
  const dispatch = useDispatch();

  const projectCurrent = useSelector((state) => state.projects.project);
  const leadCurrent = useSelector((state) => state.leads.lead);

  const alert = useSelector((state) => {
    const { alert } = state;
    if (alert.message) {
      setTimeout(() => {
        handleDismissAlert();
      }, 2000);
    }

    return alert;
  });

  history.listen((location, action) => {
    // handleDismissAlert();
  });

  if (httpManagerService.isAuthenticated()) {
    let user = httpManagerService.getUser();
    if (user) {
      isTokenFound(user.id, setRetrievedToken);
    }
  }

  let handleDismissAlert = () => {
    dispatch(alertActions.clear());
  };

  onMessageListener()
    .then((payload) => {
      setPushNotificationShown(true);
      const sort = "updated";

      console.log("payload: ", payload);

      if (payload.notification.title.includes("Lead Chat")) {
        const title = payload.notification.title.split("-")[0];
        const project = payload.notification.title.split("-")[1];
        const lead = payload.notification.title.split("-")[2];

        if (leadCurrent != undefined && leadCurrent.data.id == lead) {
          dispatch(chatActions.getChats(project, lead, true));
        }

        if (projectCurrent != undefined && projectCurrent.data.id == project) {
          dispatch(
            leadActions.getLeadsPaginated(
              project,
              1,
              15,
              "",
              [],
              [],
              [],
              null,
              null,
              sort
            )
          );
        }

        setNotification({
          title: title,
          body: payload.notification.body,
        });
      } else if (payload.notification.title.includes("Lead Email")) {
        const title = payload.notification.title.split("-")[0];
        const project = payload.notification.title.split("-")[1];
        const lead = payload.notification.title.split("-")[2];

        if (leadCurrent != undefined && leadCurrent.data.id == lead) {
          dispatch(emailActions.getemails(project, lead, true));
        }

        if (projectCurrent != undefined && projectCurrent.data.id == project) {
          dispatch(
            leadActions.getLeadsPaginated(
              project,
              1,
              15,
              "",
              [],
              [],
              [],
              null,
              null,
              sort
            )
          );
        }

        setNotification({
          title: title,
          body: payload.notification.body,
        });
      } else {
        setNotification({
          title: payload.notification.title,
          body: payload.notification.body,
        });

        if (
          payload.notification.body.includes("Propelrr Careers") ||
          payload.notification.body.includes("Propelrr Services")
        ) {
          const title = payload.notification.title.split("-")[0];
          const project = payload.notification.title.split("-")[1];
          const lead = payload.notification.title.split("-")[2];
          if (
            projectCurrent != undefined &&
            projectCurrent.data.id == project
          ) {
            dispatch(
              leadActions.getLeadsPaginated(
                project,
                1,
                15,
                "",
                [],
                [],
                [],
                null,
                null,
                sort
              )
            );
          }
        }
      }
    })
    .catch((err) => console.log("failed: ", err));

  return (
    <main role="main" className="maayo-main">
      {alert.message && (
        <div className={`alert ${alert.type} alert-dismissible fade show`}>
          {alert.type === "alert-success" && (
            <i className="fas fa-check-circle"></i>
          )}
          {alert.type === "alert-danger" && (
            <i className="fas fa-exclamation-circle"></i>
          )}
          {alert.message}
          <button
            type="button"
            className="close"
            aria-label="Close"
            onClick={handleDismissAlert}
          >
            <span aria-hidden="true">&times;</span>
          </button>
        </div>
      )}

      <PushNotification
        notification={notification}
        shown={pushNotificationShown}
        onHandleCloseNotification={setPushNotificationShown}
      />

      <Router history={history}>
        <Switch>
          <Route path="/login" component={Login} />
          <Route
            path="/two-factor/email/verify"
            component={TwoFactorEmailAuthentication}
          />
          <Route
            path="/two-factor/app/verify"
            component={TwoFactorAppAuthentication}
          />
          <Route path="/two-factor/select" component={TwoFactorSelection} />
          <Route path="/register" component={RegisterFirstStep} />
          <Route path="/forgot-password" component={ForgotPassword} />
          <Redirect from="/logout" to="/login" />
          <SideNav pathName={window.location.pathname}>
            <PrivateRoute exact path="/" component={Blank} />

            <PrivateRoute exact path="/propelrr" component={Propelrr} />
            <PrivateRoute
              exact
              path="/propelrr/viewinfluencer"
              component={ViewInfluencer}
            />
            <PrivateRoute
              exact
              path="/propelrr/addinfluencer"
              component={AddPropelrrInfluencer}
            />
            <PrivateRoute
              exact
              path="/propelrr/editinfluencer"
              component={EditInfluencer}
            />

            <PrivateRoute path="/update-password" component={UpdatePassword} />
            <PrivateRoute
              exact
              path="/profile-settings"
              component={ProfileSettings}
            />

            <PrivateRoute
              exact
              path="/project/:id/manage-email-templates"
              component={ManageEmailTemplates}
            />

            <PrivateRoute exact path="/project/:id" component={ProjectInfo} />
            <PrivateRoute
              exact
              path="/project/:id/settings"
              component={ProjectSettings}
            />
            <PrivateRoute
              exact
              path="/project/:id/manage-lead-status"
              component={ManageLeadStatus}
            />
            <PrivateRoute
              exact
              path="/project/:id/activities"
              component={ProjectViewRecentActivities}
            />

            <PrivateRoute exact path="/project/:id/forms" component={Forms} />
            <PrivateRoute
              exact
              path="/project/:projectId/form/:id/form-builder"
              component={FormBuilder}
            />
            <PrivateRoute
              exact
              path="/project/:id/form/:id/email-connection"
              component={FormEmailConnections}
            />
            <PrivateRoute
              exact
              path="/email-connection/success"
              component={FormEmailConnections}
            />
            <PrivateRoute
              exact
              path="/project/:id/form/:id/success-message"
              component={FormSuccessMessage}
            />
            <PrivateRoute
              exact
              path="/project/:id/form/:id/integration"
              component={FormIntegration}
            />

            <PrivateRoute exact path="/project/:id/leads" component={Leads} />
            <PrivateRoute
              exact
              path="/project/:id/leads/:leadId"
              component={LeadInfo}
            />
            <PrivateRoute
              exact
              path="/project/:id/leads/manage-fields"
              component={ManageFields}
            />
            <PrivateRoute
              exact
              path="/project/:id/leads/add"
              component={AddLead}
            />
            <PrivateRoute
              exact
              path="/project/:id/leads/:leadId/manage-lead"
              component={ManageLead}
            />

            <PrivateRoute
              exact
              path="/project/:id/leads/import"
              component={LeadImport}
            />
            <PrivateRoute
              exact
              path="/project/:id/leads/import/map"
              component={LeadMapField}
            />
            <PrivateRoute
              exact
              path="/project/:id/leads/import/results"
              component={ImportResult}
            />
            <PrivateRoute
              exact
              path="/project/:id/leads/import/history"
              component={ImportHistory}
            />
            <PrivateRoute
              exact
              path="/project/:id/members"
              component={Members}
            />

            <PrivateRoute
              exact
              path="/project/:id/influencers"
              component={Influencer}
            />
            <PrivateRoute
              exact
              path="/project/:id/influencers/:influencerId"
              component={InfluencerInfo}
            />

            <PrivateRoute
              exact
              path="/project/:id/utm/create"
              component={ManageUtm}
            />
            <PrivateRoute exact path="/project/:id/utm" component={UtmTable} />
          </SideNav>
          <Redirect from="*" to="/" />
        </Switch>
      </Router>
    </main>
  );
}

export default App;
