export const quickPresets = [
  {
    id: 1,
    icon: 'user',
    buttonName: 'Full Name'
  },
  {
    id: 2,
    icon: 'phone',
    buttonName: 'Contact Details'
  },
  {
    id: 3,
    icon: 'home',
    buttonName: 'Address'
  },
  {
    id: 0,
    icon: 'plus',
    buttonName: 'Blank'
  }
];

