import { projectConstants } from '_constants';
import { projectService } from '_services';
import { alertActions, managerActions } from './';
import { history } from '_helpers';

export const projectActions = {
  storeProject,
  getProjects,
  getProject,
  createProject,
  updateProject,
  deleteProject,
  updateStatus,
  getAllProjectMembers,
  addProjectMember,
  deleteProjectMember,
  getAllProjectStatus,
  createLeadStatus,
  findProjectStatus,
  findProjectStatusCount,
  updateLeadStatus,
  deleteLeadStatus,
  enableProjectStatus,
  createProjectDefaultStatus,
  createProjectConversionStatus,
  getActivityLog
};

function storeProject(project) {
  projectService.storeProject(project);
}

function getProjects(){
  return dispatch => {
    dispatch(request());
    projectService.getProjects()
      .then(
        projects => {
          dispatch(success(projects));
          sessionStorage.removeItem('project');
          sessionStorage.removeItem('projectId');
          sessionStorage.removeItem('projectStatus');
        },
        error => {
          dispatch(failure(error.toString()));
          dispatch(alertActions.error(error.toString()));
        }
      );
  };

  function request() { return { type: projectConstants.GETALL_REQUEST } }
  function success(projects) {
      sessionStorage.setItem('projects', JSON.stringify(projects.data));
      // ============================= //
      // let projectsResponse = JSON.parse(sessionStorage.getItem('projects'));
      // console.log('LOCAL => projects', projectsResponse);
      // ============================= //
      return { type: projectConstants.GETALL_SUCCESS, projects }
  }
  function failure(error) { return { type: projectConstants.GETALL_FAILURE, error } }
}

function getProject(id){
  return dispatch => {
    dispatch(request(id));
    projectService.getProject(id)
      .then(
        project => {
          dispatch(success(project));
        },
        error => {
          dispatch(failure(error.toString()));
          dispatch(alertActions.error(id, error.toString()));
        }
      );
  };

  function request(id) { return { type: projectConstants.GET_REQUEST, id } }
  function success(project) {
    sessionStorage.setItem('project', JSON.stringify(project.data));
    sessionStorage.setItem('projectId', parseInt(id));
    // ============================= //
    // let projectResponse = JSON.parse(sessionStorage.getItem('project'));
    // console.log('LOCAL => project', projectResponse);
    // ============================= //
    return { type: projectConstants.GET_SUCCESS, project }
  }
  function failure(id, error) { return { type: projectConstants.GET_FAILURE, id, error } }
}

function createProject(project) {
  return dispatch => {
    dispatch(request(project));
    projectService.createProject(project)
      .then(
        project => {
          dispatch(success(project));
          dispatch(projectActions.getProjects());
          dispatch(alertActions.success('Created project successfully'));
        },
        error => {
          dispatch(failure(error.toString()));
          dispatch(alertActions.error(error.toString()));
        }
      );
  };

  function request(project) { return { type: projectConstants.CREATE_REQUEST, project } }
  function success(project) { return { type: projectConstants.CREATE_SUCCESS, project } }
  function failure(error) { return { type: projectConstants.CREATE_FAILURE, error } }
}

function updateProject(project, id) {
  return dispatch => {
    dispatch(request(project, id));
    projectService.updateProject(project, id)
      .then(
        project => {
          dispatch(success(project));
          dispatch(alertActions.success('Project updated successfully'));
        },
        error => {
          dispatch(failure(error.toString()));
          dispatch(alertActions.error(error.toString()));
        }
      );
  };

  function request(project) { return { type: projectConstants.UPDATE_REQUEST, project } }
  function success(project) { return { type: projectConstants.UPDATE_SUCCESS, project } }
  function failure(error) { return { type: projectConstants.UPDATE_FAILURE, error } }
}


function deleteProject(project, id){
  return dispatch => {
    dispatch(request(project, id));
    projectService.deleteProject(project, id)
      .then(
        project => {
          dispatch(success());
          history.push('/');
          dispatch(alertActions.success(`Project deleted`));
        },
        error => {
          dispatch(failure(error.toString()));
          dispatch(alertActions.error(error.toString()));
        }
      );
  };

  function request(project) { return { type: projectConstants.DELETE_REQUEST, project } }
  function success() { return { type: projectConstants.DELETE_SUCCESS } }
  function failure(error) { return { type: projectConstants.DELETE_FAILURE, error } }
}

function updateStatus(project_id, status){
    return dispatch => {
        dispatch(request(project_id, status));
        projectService.updateStatus(project_id, status)
        .then(
            status => {
                dispatch(success(status));
                dispatch(alertActions.success(`Successfully changed status.`))
            },
            error => {
                dispatch(failure(error.toString()));
                dispatch(alertActions.error(error.toString()))
            }
        );
    };

    function request(status) { return {type: projectConstants.UPDATE_STATUS_REQUEST, status}}
    function success(status) { return {type: projectConstants.UPDATE_STATUS_SUCCESS, status}}
    function failure(error) { return {type: projectConstants.UPDATE_STATUS_FAILURE, error}}
}

function getAllProjectMembers(project_id){
    return dispatch => {
        dispatch(request(project_id));
        projectService.getAllProjectMembers(project_id)
            .then(
              members => {
                    dispatch(success(members));
                },
                error => {
                    dispatch(failure(error.toString()));
                    dispatch(alertActions.error(project_id, error.toString()));
                }
            );
    };
    function request(project_id) { return {type: projectConstants.GET_ALL_MEMBER_REQUEST, project_id}}
    function success(members) { return {type: projectConstants.GET_ALL_MEMBER_SUCCESS, members}}
    function failure(id, error) { return {type: projectConstants.GET_ALL_MEMBER_FAILURE, id, error}}
}

function addProjectMember(member, id){
  return dispatch => {
    dispatch(request(member, id));
    projectService.addProjectMember(member, id)
      .then(
        member => {
          dispatch(success(member));
          dispatch(managerActions.getManagers(id));
          dispatch(getProject(id));
          dispatch(alertActions.success('Member added successfully'));
        },
          error => {
            const { response } = error;
            const { data } = response;

              let errorMessage = '';
              if(data.error === 'Internal Server Error'){
                  errorMessage = {
                    error_code: "PM001",
                    error_message: "Adding member failed.",
                  }
              } else {
                  errorMessage = data;
              }
              console.log('error: ', response);
              dispatch(failure(errorMessage));
              dispatch(alertActions.error(errorMessage));
          }
      );
  };

  function request(member) { return { type: projectConstants.ADD_MEMBER_REQUEST, member } }
  function success(member) { return { type: projectConstants.ADD_MEMBER_SUCCESS, member } }
  function failure(error) { return { type: projectConstants.ADD_MEMBER_FAILURE, error } }
}

function deleteProjectMember(member, project_id, member_id){
  return dispatch => {
    dispatch(request(member, project_id, member_id));
    projectService.deleteProjectMember(member, project_id, member_id)
      .then(
        member => {
          dispatch(success());
          dispatch(alertActions.success(`Member removed from project`));
        },
        error => {
          dispatch(failure(error.toString()));
          dispatch(alertActions.error(error.toString()));
        }
      );
  };

  function request(member) { return { type: projectConstants.DELETE_MEMBER_REQUEST, member } }
  function success() { return { type: projectConstants.DELETE_MEMBER_SUCCESS } }
  function failure(error) { return { type: projectConstants.DELETE_MEMBER_FAILURE, error } }
}

function getAllProjectStatus(project_id) {
    return dispatch => {
        dispatch(request(project_id));
        projectService.getAllProjectStatus(project_id)
            .then(
                status => {
                    dispatch(success(status))
                },
                error => {
                    dispatch(failure(error.toString()));
                    dispatch(alertActions.error(project_id, error.toString()))
                }
            );
    };

    function request(project_id) { return {type: projectConstants.GET_ALL_STATUS_REQUEST, project_id}}
    function success(status) {
      sessionStorage.setItem('projectStatus', JSON.stringify(status.data));
      return {type: projectConstants.GET_ALL_STATUS_SUCCESS, status}
    }
    function failure(project_id, error) {return {type: projectConstants.GET_ALL_STATUS_FAILURE, project_id, error}}
}

function createLeadStatus(status, id){
  return dispatch => {
    dispatch(request(status, id));
    projectService.createLeadStatus(status, id)
      .then(
          status => {
          dispatch(success(status));
          dispatch(alertActions.success('Lead status added successfully'));
        },
        error => {
          dispatch(failure(error.toString()));
          dispatch(alertActions.error(error.toString()));
        }
      );
  };

  function request(status) { return { type: projectConstants.CREATE_STATUS_REQUEST, status } }
  function success(status) { return { type: projectConstants.CREATE_STATUS_SUCCESS, status } }
  function failure(error) { return { type: projectConstants.CREATE_STATUS_FAILURE, error } }
}

function findProjectStatus(project_id, status_id) {
    return dispatch => {
        dispatch(request(project_id, status_id));
        projectService.findProjectStatus(project_id, status_id)
            .then(
                status => {
                    dispatch(success(status))
                },
                error => {
                    dispatch(failure(error.toString()));
                    dispatch(alertActions.error(status_id, error.toString()))
                }
            );
    };

    function request(status) {return {type: projectConstants.GET_STATUS_REQUEST, status}}
    function success(status) {return {type: projectConstants.GET_STATUS_SUCCESS, status}}
    function failure(status_id, error) {return {type: projectConstants.GET_STATUS_FAILURE, status_id, error}}
}

function findProjectStatusCount(project_id) {
    return dispatch => {
        dispatch(request(project_id));
        projectService.findProjectStatusCount(project_id)
            .then(
                status_count => {
                    dispatch(success(status_count))
                },
                error => {
                    dispatch(failure(error.toString()));
                    dispatch(alertActions.error(error.toString()))
                }
            );
    };

    function request(project_id) {return {type: projectConstants.GET_STATUS_COUNT_REQUEST, project_id}}
    function success(status_count) {
        sessionStorage.setItem('statusCount', JSON.stringify(status_count));
        return {type: projectConstants.GET_STATUS_COUNT_SUCCESS, status_count}}
    function failure(error) {return {type: projectConstants.GET_STATUS_COUNT_FAILURE, error}}
}

function updateLeadStatus(lead_status, project_id, status_id) {
  return dispatch => {
    dispatch(request(lead_status, project_id, status_id));
    projectService.updateLeadStatus(lead_status, project_id, status_id)
      .then(
          status => {
          dispatch(success());
          dispatch(alertActions.success('Project Status updated successfully'));
        },
        error => {
          dispatch(failure(error.toString()));
          dispatch(alertActions.error(error.toString()));
        }
      );
  };

  function request(status) { return { type: projectConstants.UPDATE_PROJECT_STATUS_REQUEST, status } }
  function success(status) { return { type: projectConstants.UPDATE_PROJECT_STATUS_SUCCESS, status } }
  function failure(error) { return { type: projectConstants.UPDATE_PROJECT_STATUS_FAILURE, error } }
}

function deleteLeadStatus(lead_status, project_id, status_id){
  return dispatch => {
    dispatch(request(lead_status, project_id, status_id));
    projectService.deleteLeadStatus(lead_status, project_id, status_id)
      .then(
          status => {
          dispatch(success());
          dispatch(alertActions.success(`Project status deleted from project`));
          dispatch(projectActions.getAllProjectStatus(project_id));
        },
        error => {
          dispatch(failure(error.toString()));
          dispatch(alertActions.error(error.toString()));
        }
      );
  };

  function request(status) { return { type: projectConstants.DELETE_STATUS_REQUEST, status } }
  function success(status) { return { type: projectConstants.DELETE_STATUS_SUCCESS, status } }
  function failure(error) { return { type: projectConstants.DELETE_STATUS_FAILURE, error } }
}

function enableProjectStatus(project_id, status_id, status){
    return dispatch => {
        dispatch(request(project_id, status_id, status));
        projectService.enableProjectStatus(project_id, status_id, status)
            .then(
                status => {
                    dispatch(success(status));
                },
                error => {
                    dispatch(failure(error.toString()));
                    dispatch(alertActions.error(error.toString()))
                }
            );
    };

    function request(status) {return {type: projectConstants.UPDATE_STATUS_REQUEST, status}}
    function success(status) {return {type: projectConstants.UPDATE_STATUS_SUCCESS, status}}
    function failure(error) {return {type: projectConstants.UPDATE_STATUS_FAILURE, error}}
}

function createProjectDefaultStatus(project_id, default_status_id,status) {
    return dispatch => {
        dispatch(request(project_id, default_status_id,status));
        projectService.createProjectDefaultStatus(project_id, default_status_id, status)
            .then(
                status => {
                    dispatch(success(status));
                    dispatch(alertActions.success(`Successfully updated default status`));
                },
                error => {
                    dispatch(failure(error.toString()));
                    dispatch(alertActions.error(default_status_id, error.toString()));
                }
            );
    };

    function request(status) { return {type: projectConstants.CREATE_DEFAULT_STATUS_REQUEST, status}}
    function success(status) { return {type: projectConstants.CREATE_DEFAULT_STATUS_SUCCESS, status}}
    function failure(error) { return {type: projectConstants.CREATE_DEFAULT_STATUS_FAILURE, error}}
}

function createProjectConversionStatus(project_id, default_status_id,status) {
    return dispatch => {
        dispatch(request(project_id, default_status_id,status));
        projectService.createProjectConversionStatus(project_id, default_status_id, status)
            .then(
                status => {
                    dispatch(success(status));
                    dispatch(alertActions.success(`Successfully set conversion status`));
                },
                error => {
                    dispatch(failure(error.toString()));
                    dispatch(alertActions.error(default_status_id, error.toString()));
                }
            );
    };

    function request(status) { return {type: projectConstants.CREATE_CONVERSION_STATUS_REQUEST, status}}
    function success(status) { return {type: projectConstants.CREATE_CONVERSION_STATUS_SUCCESS, status}}
    function failure(error) { return {type: projectConstants.CREATE_CONVERSION_STATUS_FAILURE, error}}
}

function getActivityLog(project_id){
    return dispatch => {
        dispatch(request());
        projectService.getActivityLog(project_id)
            .then(
                activity_log => {
                    dispatch(success(activity_log));
                },
                error => {
                    dispatch(failure(error.toString()));
                    dispatch(alertActions.error(error.toString()));
                }
            );
    };

    function request(project_id) {return {type: projectConstants.GET_LOG_REQUEST, project_id}}
    function success(activity_log) {return {type: projectConstants.GET_LOG_SUCCESS, activity_log}}
    function failure(error) { return { type: projectConstants.GET_LOG_FAILURE, error}}
}
