import { templateActions } from "_actions";
import React, { useState, useRef, useEffect } from "react";

const TablePagination = ({
  templateLoaded,
  isEmpty,
  isFirstPage,
  isLastPage,
  size,
  pageNumber,
  totalElements,
  totalPages,
  onHandleSizeChange,
  onHandlePageChange,
}) => {
  const [rows, setRows] = useState(15);
  const [currentPage, setCurrentPage] = useState(1);
  const [countText, setCountText] = useState("");
  const [nextClass, setNextClass] = useState("");
  const [prevClass, setPrevClass] = useState("");
  const [nextPage, setNextPage] = useState(0);
  const [prevPage, setPrevPage] = useState(0);

  const gotoInputRef = React.createRef();

  useEffect(() => {
    if (!isEmpty && templateLoaded) {
     // console.log("set pagination data", pageNumber);
      // setRows(size);
      // setPage(pageNumber + 1);

      let page = pageNumber + 1;
      let min = page * size - (size - 1);
      let max = page * size;

      if (page) {
        let limit = page * size;
        if (totalElements < max) {
          limit = totalElements;
        }

        setNextPage(page + 1);
        setPrevClass(page - 1);
        setPrevClass(isFirstPage ? "" : "active");
        setNextClass(isLastPage ? "" : "active");
        setCurrentPage(page);

        setCountText(`${min}-${limit} of ${totalElements}`);
      }
    }
    if (!templateLoaded) {
      setCurrentPage("");
    }
  }, [isEmpty, templateLoaded]);

  const handleSizeChanged = (e) => {
   // console.log("changing size: ", e);
    let min = (pageNumber + 1) * e - (e - 1);

    if (min <= totalElements) {
      onHandleSizeChange(e);
    }

    if (min > totalElements) {
      onHandleSizeChange(size);
    }
  };

  const handlePageChanged = (page, className) => {
    if (className === "active") {
      onHandlePageChange(page);
    }
  };

  const handleKeyDown = (event, value, className) => {
   // console.log("change page...", value);

    if (event.key === "Enter") {
      event.preventDefault(); // Prevent form submission
      if (className === "active") {
        let min = value * size - (size - 1);
       // console.log("min: " + min);
        if (min <= totalElements) {
          setCurrentPage(value);
          onHandlePageChange(value);
        }

        if (min > totalElements) {
         // console.log("greater ....");
          if((currentPage * size) > totalElements ) {
            onHandlePageChange(1);
        } else {
            onHandlePageChange(currentPage);
        }
        }
      }
    }
  };
  return (
    <div className="table-pagination-box">
      <div className="pagination-box">
        <div className="influencer-counts">
          <span>Show rows: </span>
          <select
            value={size}
            onChange={(e) => handleSizeChanged(e.target.value)}
          >
            <option value={15}>15</option>
            <option value={25}>25</option>
            <option value={50}>50</option>
            <option value={100}>100</option>
          </select>

          <div className="go-to-page">
            <span>Go to</span>
            <input
              defaultValue={currentPage}
              key={currentPage}
              type="number"
              ref={gotoInputRef}
              onKeyDown={(e) => handleKeyDown(e, e.target.value, "active")}
            />
          </div>
          <span>{countText}</span>
        </div>
        <ul>
          <a
            onClick={() => handlePageChanged(prevPage, prevClass)}
            className={prevClass}
          >
            <i className="icon-lms icon-arrow-left"></i>
          </a>
          <a
            onClick={() => handlePageChanged(nextPage, nextClass)}
            className={nextClass}
          >
            <i className="icon-lms icon-arrow-right"></i>
          </a>
        </ul>
      </div>
    </div>
  );
};

export default TablePagination;
