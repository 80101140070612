import React from "react";

const KpiLoader = () => {
  return (
    <div className="skeleton-loader kpi-loader">
        <div className="skeleton skeleton-box kpi-skeleton"></div>
        <div className="skeleton skeleton-box kpi-skeleton"></div>
        <div className="skeleton skeleton-box kpi-skeleton"></div>
        <div className="skeleton skeleton-box kpi-skeleton"></div>
        <div className="skeleton skeleton-box kpi-skeleton"></div>
        <div className="skeleton skeleton-box kpi-skeleton"></div>
        <div className="skeleton skeleton-box kpi-skeleton"></div>
    </div>
  );
};

export default KpiLoader;
