import React from 'react';
import {MDBCol, MDBContainer, MDBIcon, MDBRow} from 'mdbreact';
import 'react-datepicker/dist/react-datepicker.css';
import {Link} from 'react-router-dom';

import Slider from '_pages/Leads/components/Slider';
import {formActions, leadActions, projectActions} from '_actions';
import {connect} from "react-redux";
import CSVReader from "react-csv-reader";
import {Button, ButtonGroup, Icon} from "blackbox-react";

class LeadImport extends React.Component {
  constructor(props) {
    super(props);

    const projectId = this.props.match.params.id;
    this.props.getLeads(projectId);
    const projectData = JSON.parse(sessionStorage.getItem('project'));

    this.state = {
      projectData: projectData,
      importLead: [],
      fileName: ''
    }
  }

  handleUploadedFile = (data, name) => {
    this.setState({
      importLead: data,
      fileName: name
    });
  };

  handleDatePicker = date => {
    this.setState({
      startDate: date
    });
  };

  renderSlider = () => {
    return (
      <div className='width-135'>
        <div className='row float-right height-41 ml-1'>
          <div className='row mr-2'>
            <p className='active mt-2 mb-0'>Upload CSV</p>
            <p className='border-right-orange mb-0 pr-3'> </p>
          </div>
          <div className='mt-2'>
            <p className='mx-2 round mb-0 background-slider-color d-flex'><MDBIcon icon='cogs' className='font-size-9 font-color-dark-orange m-auto' /></p>
            <p className='mb-0 line' />
          </div>
        </div>
        <div className='row float-right height-41 ml-1'>
          <div className='row mr-2'>
            <p className='notActive mt-2 mb-0'>Map Fields</p>
            <p className='border-right-gray mb-0 pr-3'> </p>
          </div>
          <div className='mt-2'>
            <p className='mx-2 round mb-0 background-slider-color'> </p>
            <p className='mb-0 line' />
          </div>
        </div>
        <div className='row float-right height-41'>
          <div className='row mr-2'>
            <p className='notActive mt-2 mb-0'>Import</p>
            <p className='border-right-gray mb-0 pr-3'> </p>
          </div>
          <div className='mt-2'>
            <p className='mx-2 round mb-0 background-slider-color'> </p>
          </div>
        </div>
      </div>
    )
  };


  render() {
    const projectId = JSON.parse(sessionStorage.getItem('projectId'));
    const fileName = this.state.fileName;
    sessionStorage.setItem('fileName', fileName);
    const importData = this.state.importLead;

    let hasData = false;

    if(typeof importData !== 'undefined' && importData.length > 0){
      hasData = true;

      sessionStorage.setItem('importData', JSON.stringify(importData));
    }

    let iconDone = hasData ? 'check' : 'upload';
    let textLabelDone = '';

    if(hasData){
      textLabelDone = <p>
        <b>File Added!</b> <br/> Your CSV file has been added, you can start <b>mapping your fields</b> by clicking Next.
      </p>;
    } else{
      textLabelDone = <p>
        <b>Start Importing</b> <br/> Select a CSV file containing your <br/> list of Leads with the proper headers <br/>for easy mapping later on.
      </p>;
    }

    return (
      <MDBContainer fluid className='p--md lead-import'>
        <MDBRow className='m--0'>
          <MDBCol lg='3' xl='2' md='4' className='p-t--0 p-l--0 p-r--sm p-b--sm'>
            <MDBRow className='p--0 m--0'>
              <MDBCol className='p--0'>
                <p className='font-size-12 font-weight-600 font-color-gray mb-0'>LEAD MANAGEMENT</p>
                <p className='font-family-crimson font-size-30 line-height-7 color-orange line-height-6'>Import Leads</p>
              </MDBCol>
            </MDBRow>
            <MDBRow className='m-t--lg p--0'>
              <MDBCol>
                {/*{this.renderSlider()}*/}
                <Slider stageNum='1' activeFormView='uploadCsv'/>
              </MDBCol>
            </MDBRow>
            <MDBRow className='p-t--md p-l--md p-r--md p-b--0 back-button'>
              <MDBCol>
                <ButtonGroup>
                  <Link to={`/project/${projectId}/leads`} className=''>
                    <Button
                      flat='true'
                      background='transparent' foreground='black'
                      borderWidth={2} borderColor='dark' size={-1}
                      styles={{ 'width': '100%', 'opacity': '0.8' }}>
                      <Icon color='black' name='chevron' rotate={180} />
                      <span className='color-black font-weight-bold'>View All Leads</span>
                    </Button>
                  </Link>
                </ButtonGroup>
              </MDBCol>
            </MDBRow>
            {/*<Link to='/' className='px-md-3 my-0 btn btn-flat Ripple-parent background-transparent button-end font-weight-bold shadow-none py-2 px-4 br-5 font-color-gray2'><MDBIcon icon='arrow-left' className='mr-2 color-black' />View All Forms</Link>*/}
            {/*<Link to='/lead_form'>*/}
            {/*  <MDBBtn flat className='w-auto button-end background-color-transparent color-black font-size-12 border-gray font-weight-bold shadow-none py-2 px-4 br-5'><MDBIcon icon='arrow-left' className='mr-2' />BACK TO ALL LEADS</MDBBtn>*/}
            {/*</Link>*/}
          </MDBCol>
          <MDBCol lg='9' xl='10' md='8' className='px-0'>
            <MDBRow className='d-flex'>
              <MDBCol md='3' className='my-auto'>
                <MDBRow className='pl-3'>
                  <p className='mb-0 font-family-crimson font-size-22 my-auto pr-3'>Upload CSV</p>
                </MDBRow>
              </MDBCol>
              <MDBCol md='9' className='text-right'>
                <ButtonGroup>
                  {/*<Link to={`/project/${projectId}/leads/import?=download`}>*/}
                  {/*  <Button*/}
                  {/*    flat='true'*/}
                  {/*    background='transparent' foreground='dark'*/}
                  {/*    borderWidth={2} borderColor='dark' size={-2}*/}
                  {/*    onClick={(e) => {}}*/}
                  {/*  >*/}
                  {/*    <MDBIcon icon='download' className='mr-2' />*/}
                  {/*    <span className='color-dark font-weight-bold'>Download Template</span>*/}
                  {/*  </Button>*/}
                  {/*</Link>*/}
                  <Link to={`/project/${projectId}/leads/import/history`}>
                    <Button
                      flat='true'
                      background='transparent' foreground='dark'
                      borderWidth={2} borderColor='dark' size={-2}
                      onClick={(e) => {}}
                    >
                      <MDBIcon icon='columns' className='mr-2' />
                      <span className='color-dark font-weight-bold'>Import History</span>
                    </Button>
                  </Link>
                  { hasData ?
                    <Link to={`/project/${projectId}/leads/import/map`}>
                      <Button
                        flat='true'
                        background='orange' foreground='white'
                        borderWidth={2} borderColor='orange' size={-2}
                        styles={{ 'min-width': '150px'}}
                      >
                        <span className='color-white font-weight-bold'>Next</span>
                      </Button>
                    </Link>
                    :
                    <Button
                      flat='true'
                      disabled
                      background='disabled'
                      borderWidth={2} size={-2}
                      styles={{ 'background': 'rgb(227, 232, 233)', 'border-color': 'rgb(227, 232, 233)', 'min-width': '150px'}}
                      onClick={(e) => {}}>
                      <span className='color-gray font-weight-bold'>Next</span>
                    </Button>
                  }
                </ButtonGroup>
                {/*<MDBBtn flat className='box-shadow-none clear-background border-black py-2 border-radius-5 px-3 btn-hover-none'><MDBIcon icon='download' className='mr-2' />DOWNLOAD TEMPLATE</MDBBtn>*/}
                {/*<MDBBtn flat className='box-shadow-none clear-background border-black py-2 border-radius-5 px-3 btn-hover-none'><MDBIcon icon='columns' className='mr-2' />IMPORT HISTORY</MDBBtn>*/}
                {/*<Link to={`/project/${projectId}/leads/import/map`}>*/}
                {/*  <MDBBtn flat className={`${ hasData ? 'enabled' : 'disabled'} box-shadow-none background-dark-orange text-white py-2 border-radius-5 px-5`}>NEXT</MDBBtn>*/}
                {/*</Link>*/}
              </MDBCol>
            </MDBRow>
            <MDBCol className='sub-body-container background-color-white background-rectangle m-t--md'>
              <MDBRow className='sub-body-container height--full'>
                <MDBCol lg='7' xl='7' className='p--md'>
                  <MDBRow className='p-t--sm p-l--md p-r--md p-b--md'>
                    <MDBCol className='color-light-white border-radius-7'>
                      <MDBRow className='d-flex p-3'>
                        <MDBCol md='2' className='m-auto'>
                          <MDBIcon icon='exclamation-triangle' className='font-size-45 font-color-dark-orange opacity-40' />
                        </MDBCol>
                        <MDBCol className='m-auto px-0'>
                          <p className='mb-1 font-size-11 font-weight-600 font-color-dark-brown'>Make sure your CSV file has columns for all the required Lead fields.</p>
                          <p className='mb-1 font-size-11 font-color-dark-brown'>You need to create the <span className='font-weight-600 font-color-dark-brown'>lead fields</span> before you begin the import to be able to map your columns. <br/>Go to <span className='color-red'>Manage Fields</span> to create new fields. <br/>The CSV file should not be larger than <span className='font-weight-600 font-color-dark-brown'>5MB</span>.</p>
                        </MDBCol>
                      </MDBRow>
                    </MDBCol>
                  </MDBRow>
                  <MDBRow className='p-l--md p-r--md p-b--md'>
                    <MDBCol className='bacground-color-import border-radius-7 dotted-import'>
                      <MDBRow className='d-flex padding-70-0'>
                        <div className='m-auto'>
                          <div className='d-flex'>
                            <MDBIcon icon='upload' className='font-size-45 font-color-gray opacity-40' />
                            <CSVReader
                              cssClass="csv-reader-input"
                              label="Select a CSV file"
                              onFileLoaded={this.handleUploadedFile}
                              inputId={'selectedFile'}
                              inputStyle={{color: 'red'}}
                            />
                          </div>
                        </div>
                      </MDBRow>
                    </MDBCol>
                  </MDBRow>
                  <MDBRow className='p-l--md p-r--md'>
                    <MDBCol className='background-color-dark-blue border-radius-7'>
                      <MDBRow className='d-flex p-3'>
                        <MDBCol md='2' className='m-auto'>
                          <MDBIcon icon='exclamation-circle' className='font-size-45 font-color-dark-blue opacity-40' />
                        </MDBCol>
                        <MDBCol className='m-auto px-0'>
                          {/*<p className='mb-1 font-size-11 font-color-lead-selected'>*/}
                          {/*  To automatically assign leads to <span className='font-weight-600 font-color-dark-blue'>Owner</span> or <span className='font-weight-600 font-color-dark-blue'>Responsible</span>,*/}
                          {/*  create a column with the email address of the agent and*/}
                          {/*  map that column to the field Owner or Responsible.</p>*/}
                          <p className='mb-1 font-size-11 font-weight-600 font-color-dark-blue'>If you have values with single or multiple options, use the following format on the CSV file:</p>
                          <p className='mb-1 font-size-11 font-color-lead-selected'>
                            For <span className='font-weight-600 font-color-dark-blue'>radio buttons</span> and <span className='font-weight-600 font-color-dark-blue'>switch</span> fields, values must either be <span className='font-weight-600 font-color-dark-blue'>“0/1”</span> or <span className='font-weight-600 font-color-dark-blue'>“True/False”</span>.
                          </p>
                          <p className='mb-0 font-size-11 font-color-lead-selected'>
                            For <span className='font-weight-600 font-color-dark-blue'>checkboxes</span>, the values of the field should be separated by a <span className='font-weight-600 font-color-dark-blue'>semicolon(;)</span>.
                          </p>
                        </MDBCol>
                      </MDBRow>
                    </MDBCol>
                  </MDBRow>
                </MDBCol>
                <MDBCol lg='5' xl='5' className='background-color-lead right-rectangle px-0'>
                  <div className='generic-message text-center'>
                    {textLabelDone}
                    <MDBIcon icon={iconDone} className='icon--lg' />
                    <MDBIcon icon={iconDone} className='icon--md' />
                    <MDBIcon icon={iconDone} className='icon--sm' />
                  </div>
                  {/*<MDBCol className='px-0'>
                      <MDBCol>
                        <MDBRow className='py-2 px-2'>
                          <p className='mt-3 mb-0 mx-2 font-size-10 font-weight-600'>UPLOAD OPTIONS</p>
                        </MDBRow>
                      </MDBCol>
                      <hr className='my-1' />
                      <MDBCol className='px-4'>
                        <MDBRow className='py-1'>
                          <MDBRow>
                            <p className='mt-3 mb-0 mx-4 font-size-12 font-weight-600'>What do you want to do with the records in your CSV?</p>
                          </MDBRow>
                        </MDBRow>
                      </MDBCol>
                      <MDBCol className='pb-2 pt-1'>
                        <MDBRow className='d-flex'>
                          <label className='checkboxcontainer ml-3'>
                            <input type='checkbox' name='check-box' className='check-disable' defaultChecked disabled />
                            <span className='checkmark'></span>
                          </label>
                          <p className='mb-0 font-size-12'>Add as new lead records</p>
                        </MDBRow>
                      </MDBCol>
                      <MDBCol className='py-2'>
                        <MDBRow>
                          <MDBCol md='1' className='px-0'>
                            <label className='checkboxcontainer ml-3'>
                              <input type='checkbox' name='check-box' defaultChecked />
                              <span className='checkmark'></span>
                            </label>
                          </MDBCol>
                          <MDBCol className='px-3'>
                            <p className='mb-0 font-size-12'>Find duplicate records
                                  between your CSV file and
                                  existing records by:
                            </p>
                          </MDBCol>
                          <MDBCol className='pl-0'>
                            <div className='width-inherit mb-2'>
                              <select
                                id='select'
                                className='font-weight-600 height-30 background-transparent border-gray font-size-12 opacity-70 py-1 br-5 px-2'
                              >
                                <option value='' disabled hidden>Email Address</option>
                                <option value=''>Email Address</option>
                                <option value=''>Email Address</option>
                                <option value=''>Email Address</option>
                                <option value=''>Email Address</option>
                                <option value=''>Email Address</option>
                              </select>
                            </div>
                          </MDBCol>
                        </MDBRow>
                      </MDBCol>
                      <MDBCol className='py-2'>
                        <MDBRow>
                          <MDBCol md='1' className='px-0' />
                          <MDBCol className='pl-3'>
                            <p className='mb-0'>
                              <input type='radio' id='test1' name='radio-group' defaultChecked />
                              <label htmlFor='test1' className='font-size-11 mb-0'>Skip all duplicate records</label>
                            </p>
                          </MDBCol>
                        </MDBRow>
                      </MDBCol>
                      <MDBCol className='py-2'>
                        <MDBRow>
                          <MDBCol md='1' className='px-0' />
                          <MDBCol className='pl-3 pr-0'>
                            <p className='mb-0'>
                              <input type='radio' id='test2' name='radio-group' />
                              <label htmlFor='test2' className='font-size-11 mb-0'>Update all duplicate records</label>
                            </p>
                          </MDBCol>
                          <MDBCol className='pl-0'>
                            <div className='width-inherit mb-2'>
                              <select
                                id='select'
                                className='font-weight-600 height-30 background-transparent border-gray font-size-12 opacity-70 py-1 br-5 px-2'
                              >
                                <option value='' disabled hidden>without overwrite</option>
                                <option value=''>Email Address</option>
                                <option value=''>Email Address</option>
                                <option value=''>Email Address</option>
                                <option value=''>Email Address</option>
                                <option value=''>Email Address</option>
                              </select>
                            </div>
                          </MDBCol>
                        </MDBRow>
                      </MDBCol>
                      <MDBCol>
                        <MDBRow>
                          <MDBCol md='1' className='px-0' />
                          <MDBCol className='pr-0' />
                          <MDBCol className='pr-2 pl-0'>
                            <p className='font-size-9 mb-0 font-color-dark-brown'><span className='font-weight-600'>With Overwrite: </span>Updates all the fields and overwrites the values present in the fields.
                          <span className='font-weight-600'>With Overwrite: </span>Updates only fields that are empty.</p>
                          </MDBCol>
                        </MDBRow>
                      </MDBCol>
                      <MDBCol className='pt-2'>
                        <hr className='my-1' />
                      </MDBCol>
                      <MDBCol>
                        <MDBRow className='px-2'>
                          <p className='mt-3 mb-0 mx-2 font-size-12 font-weight-600'>Additional Options</p>
                        </MDBRow>
                      </MDBCol>
                      <MDBCol className='py-2'>
                        <MDBRow className='d-flex'>
                          <MDBCol className='px-3 my-auto'>
                            <p className='mb-0 font-size-12'>
                              Format of date felds in CSV file is
                          </p>
                          </MDBCol>
                          <MDBCol className='pl-0 my-auto'>
                            <MDBRow className='mb-1 justify-content-between ml-0 mr-1 border-gray-input font-size-12 br-5 height-30'>
                              <DatePicker
                                selected={this.state.startDate}
                                onChange={this.handleDatePicker}
                                className='border-0 background-transparent mt-1 px-1 font-weight-600'
                                placeholderText='MM/DD/YYYY'
                              />
                              <div className='' >
                                <MDBIcon icon='caret-down' className='mr-2 mt-2' />
                              </div>
                            </MDBRow>
                          </MDBCol>
                        </MDBRow>
                      </MDBCol>
                    </MDBCol>*/}
                </MDBCol>
              </MDBRow>
            </MDBCol>
          </MDBCol>
        </MDBRow>
      </MDBContainer>
    );
  }
}

function mapState(state) {
  const { projects, project, forms } = state;
  const { loading, projectLoaded, members, activityLogLoaded, membersLoaded, statusLoaded } = state.projects;
  const { formLoaded } = state.forms;
  const { leadLoaded } = state.leads;
  return { projects, project, members, loading, projectLoaded, activityLogLoaded, statusLoaded, forms, membersLoaded, formLoaded, leadLoaded };
}

const actionCreators = {
  getProject: projectActions.getProject,
  getForm: formActions.getForm,
  getLeads: leadActions.getLeads,
  getLead: leadActions.getLead,
  createLead: leadActions.importLead
};

const connectedViewImportLead = connect(mapState, actionCreators)(LeadImport);
export { connectedViewImportLead as LeadImport }
