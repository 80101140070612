import React from 'react';
import Moment from "react-moment";
import { useState } from "react";
import { useRef } from "react";


const LeadActivityInfo = ({ data, onToggle, active }) => {

    const [clicked, setClicked] = useState(false);
    const contentEl = useRef();


    const handleToggle = () => {
        setClicked((prev) => !prev);
    };

    let identifier = "";
    if(data.type === 'PAGEVIEW') {
        identifier = data.title;
    } else if (data.type === 'GOAL') {
        identifier = data.goals[0].name;
    } else {
        identifier = data.event.action;
    }
    let act = data;
    console.log('data: ', act)
    return (
        <li className={`accordion_item ${clicked ? "active" : ""}`}>
        <div className="col">
            <button className="accordion_button" onClick={handleToggle}>
                <div>
                    <p>[{data.type}]&nbsp;{data.channel_grouping.toUpperCase()} {data.source ? '- ' + data.source.toUpperCase(): ''}</p>
                    <span> {identifier}</span>
                    <span><Moment date={data.time} format="hh:mm:ss A"/></span>
                </div>
                <span className="control">{clicked ? "—" : "+"} </span>
            </button>
            </div>
            <div className={`accordion_content_wrapper`}
                 ref={contentEl}
                 style={
                     clicked
                         ? { height: contentEl.current.scrollHeight }
                         : { height: "0px" }
                 }>

                    { act.type === 'PAGEVIEW' &&
                        <div>
                            <p>PATH:</p>
                            <span>{act.path}</span>
                        </div>
                    }

                    { act.type === 'GOAL' &&
                        <>
                            <div>
                                <p>LOCATION:</p>
                                <span>{act.goals[0].completion_location}</span>
                            </div>

                            <div>
                                <p>PREVIOUS STEP 1:</p>
                                <span>{act.goals[0].previous_step_1}</span>
                            </div>

                            <div>
                                <p>PREVIOUS STEP 2:</p>
                                <span>{act.goals[0].previous_step_2}</span>
                            </div>

                            <div>
                                <p>PREVIOUS STEP 3:</p>
                                <span>{act.goals[0].previous_step_3}</span>
                            </div>
                        </>
                    }

                        { act.type === 'EVENT' &&
                            <>
                                <div>
                                    <p>ACTION:</p>
                                    <span>{act.event.action}</span>
                                </div>

                                <div>
                                <p>CATEGORY:</p>
                                <span>{act.event.category}</span>
                                </div>

                                <div>
                                <p>LABEL:</p>
                                <span>{act.event.label}</span>
                                </div>
                            </>
                        }

                    <div>
                    <p>CHANNEL GROUPING</p>
                    <span>{act.channel_grouping}</span>
                    </div>

                    <div>
                    <p>CAMPAIGN</p>
                    <span>{act.campaign}</span>
                    </div>

                    <div>
                    <p>SOURCE</p>
                    <span>{act.source}</span>
                    </div>

                    <div>
                    <p>MEDIUM</p>
                    <span>{act.medium}</span>
                    </div>

                    <div>
                    <p>KEYWORD</p>
                    <span>{act.keyword}</span>
                    </div>

                    <div>
                    <p>LANDING PAGE PATH</p>
                    <span>{act.landing_page_path}</span>
                    </div>

            </div>
        </li>
    );
};

export default LeadActivityInfo;