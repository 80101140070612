import React, {Component, useState} from 'react';
import {Link, useLocation} from 'react-router-dom';
import {MDBIcon} from 'mdbreact';
import logo from '_assets/images/lms-logo.png';

import {SideBar} from '_components/SideBar';
import Logout from './Logout';
import {useSelector} from "react-redux";

function SideNav({children}) {

    let location = useLocation();

    const [toggled, setToggled] = useState(false);

    const hasInfluencerModule = useSelector((state) => {
        const {projects} = state;
        const {project, projectLoaded} = projects;
        if(projectLoaded) {
            return project.data.influencer_enabled;
        } else {
            return false;
        }
    });

    const projectId = useSelector((state) => {
        const {projects} = state;
        const {project, projectLoaded} = projects;

        if(projectLoaded) {
            return project.data.id;
        } else {
            return null;
        }
    });

    const {pathname} = location;


    return (
        <div className='main-container' id='nav-container'>
            <div className={'sidebar-bg ' + (toggled ? 'show' : 'hide')}
                 onClick={() => setToggled(false)}/>

            <div className='sidebar'>
                <div className='sidebar-logo'>
                    <img src={logo} alt='Sidebar Logo' />
                </div>

                <div className='sidebar-items'>
                    <React.Fragment>
                        <div className={`sidenav-item ${pathname.includes('leads') ? 'side-nav-active' : 'side-nav-not-active'}`}>
                            <Link to={`/project/${projectId}/leads`}>
                                <svg xmlns="http://www.w3.org/2000/svg" width="26" height="26" viewBox="0 0 24 24" fill="none" stroke="currentColor" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" class="feather feather-send"><line x1="22" y1="2" x2="11" y2="13"/><polygon points="22 2 15 22 11 13 2 9 22 2"/></svg>
                                <span>
                                    Leads
                                </span>
                            </Link>
                        </div>

                        {hasInfluencerModule &&
                            <div className={`sidenav-item ${pathname.includes('influencers') ? 'side-nav-active' : 'side-nav-not-active'}`}>
                                <Link to={`/project/${projectId}/influencers`}>
                                    <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none" stroke="currentColor" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" class="feather feather-at-sign"><circle cx="12" cy="12" r="4"/><path d="M16 8v5a3 3 0 0 0 6 0v-1a10 10 0 1 0-3.92 7.94"/></svg>
                                    <span>
                                        Influencers
                                    </span>
                                </Link>
                            </div>
                        }

                        {/* <div className={`sidenav-item ${pathname.includes('utm') ? 'side-nav-active' : 'side-nav-not-active'}`}>
                            <Link to={`/project/${projectId}/utm`}>
                                <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none" stroke="currentColor" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" class="feather feather-link"><path d="M10 13a5 5 0 0 0 7.54.54l3-3a5 5 0 0 0-7.07-7.07l-1.72 1.71"/><path d="M14 11a5 5 0 0 0-7.54-.54l-3 3a5 5 0 0 0 7.07 7.07l1.71-1.71"/></svg>
                                <span>
                                    UTM
                                </span>
                            </Link>
                        </div> */}

                    </React.Fragment>
                </div>
                <div className="logout">
                    {/*<Notifications/>*/}
                    <Logout></Logout>
                </div>
            </div>
            <SideBar/>
            <div className={pathname.includes('influencers')  ? `main-content influencer-content` : `main-content lead-content`} id={projectId === 6 ? 'careers-content' : ''}>
                {children}
            </div>
        </div>
    );

}

export default SideNav;


