import React from 'react'

const KpiResultInfo = ({ total, info }) => {
  return (
    <>
        <div className='result-box'>
          <span className='result-title'>
                {info}
          </span>
          <span className='result-hover'>
                {info}
          </span>
          <span className='result-count'>{total}</span>
        </div>
    </>
  )
}

export default KpiResultInfo