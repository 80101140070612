import React from 'react'
import { connect } from "react-redux";

import { projectActions } from '_actions';

import {MDBModal, MDBModalBody, MDBContainer, MDBRow, MDBCol, MDBModalHeader} from 'mdbreact';
import { Button } from "blackbox-react";
import InputForm from "_components/InputForm";

import { FormValidation } from '_components/FormValidation';

// import close from '_assets/images/close.png'
// import {Link} from "react-router-dom";

export default class CreateLeadStatusModal extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      status: {
        statusName: '',
        description: '',
      },
      formErrors: {
        statusName: 'Please enter a lead status name.'
      },
      statusNameValid: false,
      formValid: false,
      submitted: false,
      modalOpen: false,
      startDate: '',
      dropColor: false,
      bgColor: 'circle-orange',
      desc: '',
      owner: '',
      responsible: '',
      leadStatus: {
        name: '',
        description: ''
      },
    };

    this.handleChange = this.handleChange.bind(this);
    this.handleCreateLeadStatus = this.handleCreateLeadStatus.bind(this);
  }

  componentDidMount = () => {
    this.setState({
      modalOpen: this.props.modalOpen
    });
  };

  handleToggle = (e) => {
    this.setState({
      status: {
        statusName: '',
        description: '',
      },
      formErrors: {
        statusName: 'Please enter a lead status name.'
      },
      statusNameValid: false,
      formValid: false,
      submitted: false,
    });
  };

  validateField(fieldName, value) {
    let fieldValidationErrors = this.state.formErrors;
    let statusNameValid = this.state.statusNameValid;

    let isAllWhitespace = (value.replace(/(?!^ +$)^.+$/gm, "") !== '');

    switch(fieldName) {
      case 'statusName':
        if (value === "" || isAllWhitespace) { // check if empty
          statusNameValid = false;
          fieldValidationErrors.statusName = 'Please enter a lead status name.';
        } else {
          statusNameValid = (value.length >= 0); // check if valid
          fieldValidationErrors.statusName = statusNameValid ? '': 'Please enter a lead status name.';
        }
        break;
      default:
        break;
    }

    this.setState({
      formErrors: fieldValidationErrors,
      statusNameValid: statusNameValid
    }, this.validateForm);
  }

  validateForm() {
    this.setState({
      formValid: this.state.statusNameValid
    });
  }

  handleCreateLeadStatus(event) {
    event.preventDefault();
    const { leadStatus } = this.state;
    const { projectId } = this.props;

    this.validateForm();

    this.setState({ submitted: true }, () => {
      if (this.state.formValid) {
        if(this.state.submitted){

          this.props.createLeadStatus({
            name: leadStatus.statusName,
            description: leadStatus.description
          }, projectId);

          let { modalOpen, modalToggle } = this.props;
          if(modalOpen){
            modalToggle();
          }

          for (const prop of Object.keys(leadStatus)) {
            delete leadStatus[prop];
          }

          this.handleToggle();
          this.props.handleGetProjectStatus();
        }
      }
    });
  };

  errorClass(error) {
    return(error.length === 0 ? 'is-passed' : 'is-failed');
  }

  handleCancel = () => {
    this.props.modalToggle('owner')
  };

  handleSave = () => {
    this.props.modalToggle('')
  };

  handleChange(event) {
    const { name, value } = event.target;
    const { leadStatus } = this.state;

    this.setState({
      leadStatus: {
        ...leadStatus,
        [name]: value
      }
    }, () => {
      this.validateField(name, value);
    });
  };

  handleDatePicker = date => {
    this.setState({
      startDate: date
    });
  };

  //this function is used to render Action Button
  handerShowActionBtn = () => {
    let count = document.querySelectorAll(`input[type='checkbox'][name='check-box']:checked`);
    this.setState({ hasSelected: count.length > 1 ? 'visible' : 'invisible' });

    let isCheck = document.querySelectorAll(`input[type='checkbox'][name='check-all']`);
    for (let i = 0; i < isCheck.length; i++) {
      if (isCheck[i].type === 'checkbox') {
        isCheck[i].checked = false;
      }
    }
  };

  render() {
    let { modalOpen, modalToggle } = this.props;
    //let project = {};
    const { submitted, formValid } = this.state;
    // if(this.props.projects.items !== undefined)  {
    //   project = this.props.projects.items.data;
    // }

    const { leadStatus } = this.state;
    return (
      <MDBContainer>
        <form onSubmit={this.setFormName} noValidate>
          <MDBModal isOpen={modalOpen} toggle={modalToggle} className='create-form-modal' centered>
            <MDBModalHeader className='padding-left-18 padding-right-18 pb-3 pt-3' toggle={modalToggle} onClick={(e) => this.handleToggle(e)}>
              <p className='mb-0 font-size-12 font-weight-600 letter-spacing-1'>CREATE LEAD STATUS</p>
            </MDBModalHeader>
            <MDBModalBody className='p-t--0 padding-left-18 padding-right-18 padding-bottom-15'>
              <MDBRow>
                <MDBCol className={`form-group m--sm ${submitted && this.errorClass(this.state.formErrors.statusName)}`}>
                  <label className='mb-1 font-size-12 text-black'>Lead Status Name <span className='color-red'>*</span></label>
                  <InputForm
                    onChange={this.handleChange}
                    type='text'
                    name='statusName'
                    value={leadStatus.name}
                    id='FormName'
                    hint='Enter Lead Status name'
                  />
                  <div className={`form-validation__container ${(!formValid && submitted) ? 'display-block' : 'display-none'}`}>
                    <FormValidation fieldName='statusName' formErrors={this.state.formErrors}/>
                  </div>
                  {/*<span className={this.state.dropColor ? 'color-drop-active' : 'color-drop'} onClick={() => { this.setState({ dropColor: !this.state.dropColor }) }}>*/}
                  {/*  <div className={`${this.state.bgColor} ${'circle-display'}`} />*/}
                  {/*  <MDBIcon icon='caret-down' className='float-right mt-2 font-size-10 mr-1' />*/}
                  {/*</span>*/}
                  {/*<div className={`${this.state.dropColor ? 'drop-colors' : 'display-none'} ${'row pt-1'}`}>*/}
                  {/*  <div className='circle-drops circle-light-blue my-1 mx-1' onClick={() => { this.setState({ bgColor: 'circle-light-blue', dropColor: !this.state.dropColor }) }} />*/}
                  {/*  <div className='circle-drops circle-dark-blue my-1 mx-1' onClick={() => { this.setState({ bgColor: 'circle-dark-blue', dropColor: !this.state.dropColor }) }} />*/}
                  {/*  <div className='circle-drops circle-yellow my-1 mx-1' onClick={() => { this.setState({ bgColor: 'circle-yellow', dropColor: !this.state.dropColor }) }} />*/}
                  {/*  <div className='circle-drops circle-orange my-1 mx-1' onClick={() => { this.setState({ bgColor: ' circle-orange', dropColor: !this.state.dropColor }) }} />*/}
                  {/*  <div className='circle-drops circle-red my-1 mx-1' onClick={() => { this.setState({ bgColor: 'circle-red', dropColor: !this.state.dropColor }) }} />*/}
                  {/*  <div className='circle-drops circle-yellow-green my-1 mx-1' onClick={() => { this.setState({ bgColor: 'circle-yellow-green', dropColor: !this.state.dropColor }) }} />*/}
                  {/*  <div className='circle-drops circle-green my-1 mx-1' onClick={() => { this.setState({ bgColor: 'circle-green', dropColor: !this.state.dropColor }) }} />*/}
                  {/*  <div className='circle-drops circle-gray my-1 mx-1' onClick={() => { this.setState({ bgColor: 'circle-gray', dropColor: !this.state.dropColor }) }} />*/}
                  {/*  <div className='circle-drops circle-pink my-1 mx-1' onClick={() => { this.setState({ bgColor: 'circle-pink', dropColor: !this.state.dropColor }) }} />*/}
                  {/*  <div className='circle-drops circle-violet my-1 mx-1' onClick={() => { this.setState({ bgColor: 'circle-violet', dropColor: !this.state.dropColor }) }} />*/}
                  {/*  <div className='circle-drops circle-light-orange my-1 mx-1' onClick={() => { this.setState({ bgColor: 'circle-violet', dropColor: !this.state.dropColor }) }} />*/}
                  {/*  <div className='circle-drops circle-lighter-orange my-1 mx-1' onClick={() => { this.setState({ bgColor: 'circle-lighter-orange', dropColor: !this.state.dropColor }) }} />*/}
                  {/*</div>*/}
                </MDBCol>
              </MDBRow>
              <MDBRow>
                <MDBCol>
                  <label className='mb-1 pt-2 font-size-12 text-black'>Description</label>
                  <textarea
                    name='description'
                    value={leadStatus.description}
                    id='leadDisc'
                    className='form-control font-size-12 color-none'
                    rows='5'
                    placeholder='Enter description here...'
                    onChange={this.handleChange}
                  />
                  {/*<div className='row mt-3 justify-content-between'>*/}
                    {/*<div className='row ml-1'>*/}
                    {/*  <label id='taskCheck' className='checkboxcontainer-sales ml-3 mt-1' onClick={() => { this.handerShowActionBtn() }}>*/}
                    {/*    <input */}
                    {/*      type='checkbox' */}
                    {/*      name='check-box'*/}
                    {/*      onChange={this.handleChange}*/}
                    {/*    />*/}
                    {/*    <span className='checkmark'></span>*/}
                    {/*  </label>*/}
                    {/*  <p className='mb-3 font-size-10 opacity-70 font-weight-600'>Set as Conversion to Sales</p>*/}
                    {/*</div>*/}
                    {/*<p className={`${this.state.desc === '' ? 'display-none' : 'font-size-10 opacity-70 font-weight-600 mr-3'}`}>Created on August 12, 2019 07:22 AM</p>*/}
                  {/*</div>*/}
                </MDBCol>
              </MDBRow>
              <MDBRow className='p-t--md'>
                <MDBCol className='d-flex'>
                  <span
                    className='mb-0 color-gray my-auto text-cursor-pointer border-bottom-gray line-height-15 font-size-12'
                    onClick={modalToggle}
                  >
                    Cancel
									</span>
                </MDBCol>
                <MDBCol className='text-right' id='nextButton'>
                  <Button
                    flat='true'
                    type='submit'
                    size={-1}
                    background='orange'
                    onClick={this.handleCreateLeadStatus}
                  >
                    CREATE LEAD STATUS
                  </Button>
                  {/*<MDBBtn*/}
                  {/*  flat*/}
                  {/*  type='submit'*/}
                  {/*  className='br-5 mx-0 py-2 border-dark-orange w-100 Ripple-parent background-dark-orange box-shadow-none btn btn-flat font-size-12 px-3'*/}
                  {/*  onClick={this.handleCreateLeadStatus}*/}
                  {/*  ><Link to={`/project/${project.id}/manage-lead-status`} className='text-white'>*/}
                  {/*  CREATE LEAD STATUS</Link></MDBBtn>*/}
                </MDBCol>
              </MDBRow>
            </MDBModalBody>
          </MDBModal>
        </form>
      </MDBContainer >
    )
  }
}

function mapState(state) {
  const { projects, project, creating } = state;
  // const { creating } = state.createLeadStatus;
  return { creating, projects, project };
}

const actionCreators = {
  getProject: projectActions.getProject,
  createLeadStatus: projectActions.createLeadStatus
};

const connectedViewProjects = connect(mapState, actionCreators)(CreateLeadStatusModal);
export { connectedViewProjects as CreateLeadStatusModal };
