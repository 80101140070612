import React from 'react';
import { MDBBtn, MDBIcon } from 'mdbreact'

class InputButtons extends React.Component {
  constructor(props){
    super(props);
    this.state = {
      selectedInput: ''
    };

    this.handleCreateFormField = this.handleCreateFormField.bind(this);
  }

  handleSelectedInput = (inputState) => {
    this.props.handleSelectedInput(inputState);
  };

  handleCreateFormField = (formGroupId, type, fieldIndex, fieldTotalNum) => {
    this.props.handleCreateFormField(formGroupId, type, fieldIndex, fieldTotalNum);
  };

  render() {
    let { inputs } = this.props;
    let formGroupId = this.props.formGroupId;
    let fieldIndex = this.props.fieldIndex;
    let fieldTotalNum = this.props.fieldTotalNum;

    return (
      inputs.map((value) => {
        return (
          <MDBBtn
            key={value.buttonName}
            flat
            onClick={() => {
              this.handleCreateFormField(formGroupId, value.id, fieldIndex, fieldTotalNum);
              this.props.handleSelectedInput(true);
            }}
            className='button-presets text-left color-black px-3 padding-11 font-weight-bold shadow-none font-size-9'>
            <MDBIcon icon={value.icon} className='mr-2 font-size-12' />
            {value.buttonName}
          </MDBBtn>
        )
      })
    )
  }
}

export default InputButtons;
