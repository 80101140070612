import React, { useCallback, useEffect, useRef, useState } from "react";
import { useParams } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import { MDBCol, MDBRow } from "mdbreact";
import { utilitiesService as utility, utilitiesService } from "_services";
import { emailActions, profileActions, deckActions } from "_actions";
import StarterKit from "@tiptap/starter-kit";
import { EditorContent, useEditor } from "@tiptap/react";
import Link from "@tiptap/extension-link";
import MenuBarReply from "./MenuBarReply";
import EmailModal from "./EmailModal";

const LeadComposeEmailDrawer = ({
  isComposeEmailDrawer,
  onHandleToggleComposeEmailDrawer,
  leadEmailDetails,
  other,
  openSaveDraftModal,
  saveDraftEmail,
  openDraft,
}) => {
  const [toggleTabState, setToggleTabState] = useState(1);
  const [emailSubject, setEmailSubject] = useState("");
  const [emailMessage, setEmailMessage] = useState("");
  const [editorContent, setEditorContent] = useState("");
  const [emailDraft, setEmailDraft] = useState({});

  const dispatch = useDispatch();
  const params = useParams();
  const profile = useSelector((state) => state.profile);

  let decryptedSignature = "";

  useEffect(() => {
    dispatch(profileActions.getProfile());
  }, [Object.keys(profile).length === 0]);

  const renderSignature = () => {
    if (profile && profile.data && profile.data.signature != null) {
      decryptedSignature = utility.decrypt(profile.data.signature);
      return utility.decrypt(profile.data.signature);
    }
  };

  useEffect(() => {
    // setEmailSubject("");

    if (profile.getProfileLoaded || other) {
      setEmailSubject("");
      setEmailMessage(renderSignature);

      if (profile.data != null && editor != null) {
        const signature =
          "<p><p><p><p><p><p>" + utility.decrypt(profile.data.signature);
        editor.commands.setContent(signature);
      }
    }
  }, [Object.keys(profile).length, other]);

  const editor = useEditor({
    extensions: [StarterKit, Link],
    autofocus: true,
    content: editorContent,
    onUpdate({ editor }) {
      setEditorContent(editor.getHTML());
    },
  });

  useEffect(() => {
    // this is just an example. do whatever you want to do here
    // to retrieve your editors content from somewhere
    // editor.commands.setContent(insertYourHTMLHere)
    // console.log('editor called: ', editor)
    // console.log('editor profile: ', profile)
    if (editor != null && profile.data) {
      const signature = "<p><p><p>" + utility.decrypt(profile.data.signature);
      editor.commands.setContent(signature);
    }
  }, [editor]);

  useEffect(() => {
    if (openDraft && Object.keys(openDraft).length > 0 && editor != null) {

      setEmailDraft(openDraft);
      setEmailSubject(utilitiesService.decrypt(openDraft.subject));
      editor.commands.setContent(utilitiesService.decrypt(openDraft.template));
    }
  }, [openDraft, editor]);

  // const saveDraftEmailCallback = useCallback(() => {
  //   if (editor != null && isComposeEmailDrawer) {
  //     console.log('calling....', emailDraft);
  //     const from = leadEmailDetails.project_email;
  //     const to = leadEmailDetails.lead_email;
  //     const subject = utility.encrypt(emailSubject);
  //     const message = utility.encrypt(
  //       editorContent.length === 0 ? editor.getHTML() : editorContent
  //     );

  //     const request = {
  //       to: to,
  //       from: from,
  //       subject: subject,
  //       template: message,
  //       type: "compose",
  //       id: Object.keys(emailDraft).length > 0 ? emailDraft.id : 0,
  //       other: false,
  //     };

  //     saveDraftEmail(request);
  //   }
  // }, [editorContent, emailSubject, isComposeEmailDrawer]);

  

  // useEffect(() => {
  //   saveDraftEmailCallback();
  // }, [saveDraftEmailCallback]);

  // console.log('email draft load: ', emailDraft);

  const recipient = utility.decrypt(leadEmailDetails.lead_email);

  let activeClass = isComposeEmailDrawer ? "active" : "";

  // console.log('subject: ', emailSubject);
  // console.log('editor: ', editorContent);
  // console.log('opendraft: ', openDraft);

  const handleSendEmail = () => {
    const from = leadEmailDetails.project_email;
    const to = leadEmailDetails.lead_email;
    const subject = utility.encrypt(emailSubject);
    const message =
      editorContent.length > 0
        ? utility.encrypt(editorContent)
        : utility.encrypt(editor.getHTML());

    const request = {
      to: to,
      from: from,
      subject: subject,
      message: message,
    };

    dispatch(
      emailActions.sendNewThreadEmail(params.id, params.leadId, request)
    );

    if (Object.keys(emailDraft).length > 0) {
      console.log("deleting draft in compose email");
      dispatch(
        emailActions.deleteDraft(params.id, params.leadId, emailDraft.id)
      );
    }

    isComposeEmailDrawer = "";

    saveDraftEmail({});
  };

  const handleSaveEditorContent = (editorContent) => {
    // Handle the updated editor content in the parent component
    // console.log("Updated Editor Content in parent:", editorContent);
    // You can save it in the state or dispatch an action, etc.
    setEditorContent(editorContent);
  };

  const closeComposeEmail = () => {
    const from = leadEmailDetails.project_email;
    const to = leadEmailDetails.lead_email;
    const subject = utility.encrypt(emailSubject);
    const message = utility.encrypt(
      editorContent.length == 0 ? editor.getHTML() : editorContent
    );

    const request = {
      to: to,
      from: from,
      subject: subject,
      template: message,
      type: "compose",
      id: Object.keys(emailDraft).length > 0 ? emailDraft.id : 0,
      other: false,
    };

    console.log("request: ", request);

    saveDraftEmail(request);
    openSaveDraftModal();
  };

  return (
    <React.Fragment>
      <div
        className={`drawer-overlay ${activeClass}`}
        onClick={closeComposeEmail}
      ></div>
      <div className={`side-drawer-main email-compose-drawer ${activeClass}`}>
        <div className="side-drawer-wrapper">
          <div className="side-drawer-title">
            <h3>New Message</h3>
            <span className="side-drawer-close" onClick={closeComposeEmail}>
              <i className="icon-lms icon-close"></i>
            </span>
            <div className="email-recipient">
              <p>
                <span>To:</span> {recipient}
              </p>
              <span className="label">Recipient</span>
              {decryptedSignature}
            </div>
          </div>
          <div className="side-drawer-tabs">
            <div className="side-drawer-tabs-wrapper">
              <div className="content-tabs-box">
                <div className="email-subject">
                  <input
                    type="text"
                    placeholder="Subject:"
                    value={emailSubject}
                    onChange={(e) => setEmailSubject(e.target.value)}
                  />
                </div>
                <div className="email-text">
                  <div className="custom-basic-editor email-editor">
                    <MenuBarReply
                      editor={editor}
                      onSaveEditorContent={handleSaveEditorContent}
                      composeOutside={other}
                    />
                    <EditorContent
                      editor={editor}
                      className="thread-editor-field"
                    />
                  </div>
                </div>
              </div>
              <div className="tab-form-btn-group">
                <MDBRow>
                  <MDBCol className="text-right">
                    <span
                      className="drawer-btn cancel-btn"
                      onClick={closeComposeEmail}
                    >
                      Cancel
                    </span>
                    <span
                      className="drawer-btn save-btn"
                      onClick={handleSendEmail}
                    >
                      Send Email
                    </span>
                  </MDBCol>
                </MDBRow>
              </div>
            </div>
          </div>
        </div>
      </div>
    </React.Fragment>
  );
};

export default LeadComposeEmailDrawer;
