import React from 'react';

const AutoAssignDrawerLoader = () => {
  return (
    <div className="skeleton-loader skeleton-auto-assign-drawer active">
        <div className='skeleton-groups'>
            <div className='skeleton-top'>
                <div className="skeleton skeleton-box"></div>
            </div>
            <div className='skeleton-bottom'>
                <div className='skeleton-bottom-group'>
                    <div className='skeleton skeleton-box'></div>
                    <div className='skeleton skeleton-box'></div>
                    <div className='skeleton skeleton-box'></div>
                </div>
            </div>
        </div>
        <div className='skeleton-groups'>
            <div className='skeleton-top'>
                <div className="skeleton skeleton-box"></div>
            </div>
            <div className='skeleton-bottom'>
                <div className='skeleton-bottom-group'>
                    <div className='skeleton skeleton-box'></div>
                    <div className='skeleton skeleton-box'></div>
                    <div className='skeleton skeleton-box'></div>
                </div>
            </div>
            <div className='skeleton-bottom'>
                <div className='skeleton-bottom-group'>
                    <div className='skeleton skeleton-box'></div>
                    <div className='skeleton skeleton-box'></div>
                    <div className='skeleton skeleton-box'></div>
                </div>
            </div>
            <div className='skeleton-bottom'>
                <div className='skeleton-bottom-group'>
                    <div className='skeleton skeleton-box'></div>
                    <div className='skeleton skeleton-box'></div>
                    <div className='skeleton skeleton-box'></div>
                </div>
            </div>
            <div className='skeleton-bottom'>
                <div className='skeleton-bottom-group'>
                    <div className='skeleton skeleton-box'></div>
                    <div className='skeleton skeleton-box'></div>
                    <div className='skeleton skeleton-box'></div>
                </div>
            </div>
        </div>
    </div>
  )
}

export default AutoAssignDrawerLoader