import React, { useEffect, useRef, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useParams } from "react-router-dom";
import { utilitiesService as utility } from "_services";
import ChatUserReply from "./LeadChatConversation/ChatUserReply";
import ChatAgentReply from "./LeadChatConversation/ChatAgentReply";
import { chatActions } from "_actions";
import Skeleton from "react-loading-skeleton";
import "react-loading-skeleton/dist/skeleton.css";
import { chat } from "_reducers/chat.reducer";

function LeadChat({ leadChats, chatLoaded, chatNotif, lead }) {
  const params = useParams();
  const dispatch = useDispatch();
  const messagesEndRef = useRef(null);
  const [chatConversation, setChatConversation] = useState(leadChats);
  const [searchText, setsearchText] = useState('');
  const [chatHistory, setChatHistory] = useState([]);
  const [isSearchToggle, setIsSearchToggle] = useState(false);
  const [isChatReply, setIsChatReply] = useState(false);

  const scrollToBottom = () => {
    messagesEndRef.current.scrollIntoView({ behavior: "smooth" });
  };

  const [reply, setReply] = useState("");
  //   console.log("leadchat: ", chatHistory);
  //   console.log("--leadchat loaded: ", chatLoaded);
  //   console.log("--chat reply: ", isChatReply);

  // const chatNotif = useSelector((state) => state.chat.getChatNotification);

  useEffect(() => {
    scrollToBottom();
  }, [chatLoaded, isSearchToggle]);



  useEffect(() => {
    setReply("");
    const projectId = params.id;
    const leadId = params.leadId;

    setIsSearchToggle(false);

    // console.log(
    //   "chat size: " + leadChats.chat_history.length + ", chat notif: " + chatNotif
    // );
    setChatConversation(leadChats);
    setChatHistory(leadChats.chat_history);
    const request = getUnreadMessages(leadChats, chatLoaded);

    if (request.unread_messages.length > 0) {
      dispatch(chatActions.updateUnreadChats(projectId, leadId, request));
    }

  }, [leadChats.chat_history.length]);

  const getUnreadMessages = (chatConversation) => {
    let unreadMessages = [];

    unreadMessages = chatConversation.chat_history
      .filter((chat) => chat.status === "received")
      .map((chat) => chat.message_id);

    return {
      fb_user_id: chatConversation.fb_user_id,
      unread_messages: unreadMessages,
    };
  };

  const handleReply = (value) => {
    setReply(value);
  };

  const handleSendAgentReply = () => {
    setIsChatReply(true);

    const encryptedMsg = utility.encrypt(reply);
    const request = {
      sender_id: chatConversation.page_id,
      receiver_id: chatConversation.fb_user_id,
      message: encryptedMsg,
    };
    handleReply("");
    dispatch(chatActions.agentMessageReply(params.id, params.leadId, request));
  };

  const renderChat = () => {
    if (leadChats) {
      return chatHistory.map((chat) => {
        // console.log('chat loaded: ' + chatLoaded + ', chatNotif: ' + chatNotif + ', chatreply: ' + isChatReply);
        //  console.log('msg: ', utility.decrypt(chat.message));

        if (chat.sender_id === chatConversation.page_id) {
          return (
            <ChatAgentReply
              message={chat.message}
              timestamp={chat.timestamp}
              status={chat.status}
              key={chat.message_id}
              name={chat.owner}
            />
          );
        }

        if (chat.sender_id === chatConversation.fb_user_id) {
          return (
            <ChatUserReply
              message={chat.message}
              timestamp={chat.timestamp}
              key={chat.message_id}
              name={chat.owner}
            />
          );
        }
      });
    }

    return [];
  };

  // const handleSearchChat = (search) => {
  //   console.log('search: ', search);
  //   setsearchText(search);
  //   const filteredChats = { ...chatConversation };

  //   let chatHistory = filteredChats.chat_history;

  //   let searchChats = chatHistory.filter((chat) => {
  //     return utility
  //       .decrypt(chat.message)
  //       .toLocaleLowerCase()
  //       .includes(searchText.toLocaleLowerCase());
  //   });

  //   filteredChats.chat_history = searchChats;
  //   // console.log("search chats: ", searchChats.length);
  //   // console.log("search chats: ", filteredChats);
  //   setChatHistory(filteredChats.chat_history);
  // };

  const handleSearchChat = (search) => {
    console.log('search: ', search);
    setsearchText(search);
  
    const filteredChats = { ...chatConversation };
  
    let chatHistory = leadChats.chat_history; // Use leadChats directly
  
    if (search.trim() !== '') {
      // If search text is not empty, filter the chat history
      const searchChats = chatHistory.filter((chat) => {
        return utility
          .decrypt(chat.message)
          .toLocaleLowerCase()
          .includes(search.toLocaleLowerCase());
      });
  
      filteredChats.chat_history = searchChats;
    } else {
      // If search text is empty, return the entire chat history
      filteredChats.chat_history = chatHistory;
    }
  
    console.log(filteredChats.chat_history);
  
    setChatHistory(filteredChats.chat_history);
  };
  
  

  const handleToggleSearch = () => {
    setIsSearchToggle(!isSearchToggle);
    if (isSearchToggle) {
      setsearchText('');
      setChatHistory(leadChats.chat_history);
    }
  };

  useEffect(() => {
    setIsChatReply(false);

    if (!chatNotif) {
      setIsChatReply(true);
    }
  }, [chatNotif]);

  useEffect(() => {
    setIsSearchToggle(false);
    if (!isSearchToggle) {
      setsearchText('');

      if (leadChats) {
        setChatHistory(leadChats.chat_history);
      }
    }
  }, [!chatLoaded]);

  return (
    <div className="chat-box">
      <div className="search-bar">
        <div
          className={isSearchToggle ? "input-search active" : "input-search"}
        >
          <i className="fas fa-search"></i>
          <input
            type="text"
            value={searchText}
            placeholder="Search Conversation"
            onChange={(e) => handleSearchChat(e.target.value)}
          />
        </div>
        <span className="search-toggle" onClick={handleToggleSearch}>
          {!isSearchToggle && <i className="fas fa-search"></i>}
          {isSearchToggle && <i className="fas fa-times"></i>}
        </span>
      </div>
      <div className="main-chat-box">
        {chatLoaded && !isChatReply && renderChat()}
        {(isChatReply || chatNotif) && renderChat()}
        {!chatLoaded && (isChatReply || chatNotif) && <Skeleton />}
        {!chatLoaded && (!isChatReply || !chatNotif) && (
          <div className="loading-box active">
            <div className="loader-box">
              <div className="loader-custom">
                <span></span>
                <span></span>
                <span></span>
                <span></span>
                <span></span>
              </div>
            </div>
          </div>
        )}
      </div>
      <div className="reply-input-bar">
        <input
          type="text"
          placeholder="Reply"
          value={reply}
          onChange={(e) => handleReply(e.target.value)}
          onKeyPress={(event) => {
            if (event.key === "Enter") {
              handleSendAgentReply();
            }
          }}
        />
        <button
          className="background-btn"
          onClick={handleSendAgentReply}
          disabled={!reply}
        >
          Reply
        </button>
      </div>
      <div ref={messagesEndRef} />
    </div>
  );
}

export default LeadChat;
