import React from 'react';

const AssigneeAction = ({openDropdown, handleCloseAssigneeDropdown, assignees, onSetLeadManager}) => {

    const handleStatusSelection = (selectedStatus) => {
        // Call the callback function to update the parent state
        onSetLeadManager(selectedStatus);
        // Close the dropdown or perform any other logic
        handleCloseAssigneeDropdown();
      };

      
    return (
        <div className={openDropdown === 'assignee' ? "action-dropdown active" : "action-dropdown"}>
            <div className='dropdown-title'>
                <p>
                    <i className='icon-lms icon-user-o'></i>
                    Assignee
                </p>
                <i className='icon-lms icon-close' onClick={handleCloseAssigneeDropdown}></i>
            </div>
            <div className='dropdown-list status-list'>
                <div className='filter-group'>
                    {assignees.map(user => (
                        <span key={user.accountId} onClick={() => handleStatusSelection(user.email)}>{user.fullName}</span>
                    ))}
                </div>
            </div>
            <div className='triangle'></div>
        </div>
    );    
}

export default AssigneeAction