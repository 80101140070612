import { httpManagerService as httpManager } from  '_services';

export const publicService = {
    googleConnectOauth,
    googleConnectAuthorize,
    updateConnection,
    disconnectGmail,
    generateForm,
    addLead
};

function googleConnectOauth(project_id, form_id, redirect) {
    return httpManager.getHttpWithRequestingToken(`/public/project/${project_id}/form/${form_id}/connection/oauth?redirect=${redirect}`);
}

function googleConnectAuthorize(project_id, form_id, state){
    return httpManager.getHttpWithRequestingToken(`/public/project/${project_id}/form/${form_id}/connection/authorize${state}`);
}

function disconnectGmail(project_id, form_id, connection_id){
    return httpManager.deleteJsonWithRequestingToken(`/public/project/${project_id}/form/${form_id}/connection/${connection_id}`, connection_id);
}

function updateConnection(project_id, form_id, connection){
    return httpManager.putJsonOnce(`/public/project/${project_id}/form/${form_id}/connection`, connection);
}

function generateForm(access_key){
    return httpManager.getHttpWithRequestingToken(`/public/form/generate?accesskey=${access_key}`);
}

function addLead(access_key){
    return httpManager.postJsonOnce(`/public/form/generate?accesskey=${access_key}`);
}
