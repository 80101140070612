export const templateConstants = {

    GET_TEMPLATES_REQUEST: 'GET_TEMPLATES_REQUEST',
    GET_TEMPLATES_SUCCESS: 'GET_TEMPLATES_SUCCESS',
    GET_TEMPLATES_FAILURE: 'GET_TEMPLATES_FAILURE',

    GET_SHARED_TEMPLATES_REQUEST: 'GET_SHARED_TEMPLATES_REQUEST',
    GET_SHARED_TEMPLATES_SUCCESS: 'GET_SHARED_TEMPLATES_SUCCESS',
    GET_SHARED_TEMPLATES_FAILURE: 'GET_SHARED_TEMPLATES_FAILURE',

    GET_TEMPLATES_DROPDOWN_SUCCESS: 'GET_TEMPLATES_DROPDOWN_SUCCESS',

    GET_TEMPLATES_CATEGORY_REQUEST: 'GET_TEMPLATES_CATEGORY_REQUEST',
    GET_TEMPLATES_CATEGORY_SUCCESS: 'GET_TEMPLATES_CATEGORY_SUCCESS',
    GET_TEMPLATES_CATEGORY_FAILURE: 'GET_TEMPLATES_CATEGORY_FAILURE',
}