import React from 'react';
import { connect } from "react-redux";

import { leadActions } from '_actions';

import { MDBModal, MDBModalBody, MDBModalHeader, MDBContainer, MDBRow, MDBCol } from 'mdbreact';
import { Button } from "blackbox-react";

import { FormValidation } from '_components/FormValidation';
import { Loader } from '_components/Loader';


class EditLeadNoteModal extends React.Component {
	constructor(props) {
		super(props);

		const user = JSON.parse(sessionStorage.getItem('user'));
		const userEmail = user && user.data.email;

		this.state = {
			leadNote: {
				name: this.props.leadNote.name || '',
				description: this.props.leadNote.description || '',
				sender: userEmail
			},
			formErrors: {
				name: ''
			},
			formNameValid: false,
			formValid: false,
			submitted: false,
			modalOpen: false,
			class: 'disabled'
		};

		this.handleChange = this.handleChange.bind(this);
		this.handleSubmit = this.handleSubmit.bind(this);
	}

	componentDidUpdate(prevProps: Readonly<P>, prevState: Readonly<S>, snapshot: SS) {

		const user = JSON.parse(sessionStorage.getItem('user'));
		const userEmail = user && user.data.email;

		const previousLeadValue = prevProps.leadNote;
		const {leadNote} = this.props;

		if(previousLeadValue !== leadNote) {
			this.setState({leadNote: {
					sender: userEmail,
					name: leadNote.name || '',
					description: leadNote.description || '',
				},});
		}
	}

	componentDidMount = () => {
		this.setState({
			modalOpen: this.props.modalOpen
		});
	};

	validateField(fieldName, value) {
		let fieldValidationErrors = this.state.formErrors;
		let formNameValid = this.state.formNameValid;

		let isAllWhitespace = (value.replace(/(?!^ +$)^.+$/gm, "") !== '');

		switch(fieldName) {
			case 'name':
				if (value === "" || isAllWhitespace) { // check if empty
					formNameValid = false;
					fieldValidationErrors.name = 'Please enter a form name.';
				} else {
					formNameValid = (value.length >= 0); // check if valid
					fieldValidationErrors.name = formNameValid ? '': 'Please enter a form name.';
				}
				break;
			default:
				break;
		}

		this.setState({
			formErrors: fieldValidationErrors,
			formNameValid: formNameValid
		}, this.validateForm);
	}

	validateForm() {
		this.setState({
			formValid: this.state.formNameValid
		});
	}

	handleChange(event) {
		const { name, value } = event.target;
		const { leadNote } = this.state;

		// Add current value of fields to form state
		this.setState({
			leadNote: {
				...leadNote,
				[name]: value
			},
			class: 'orange'
		}, () => {
			if(name === 'name'){
				this.validateField(name, value);
			}
			if(name === 'description'){
				this.setState({
					formValid: true
				});
			}
		});
	};

	handleSubmit(event) {
		event.preventDefault();

		const { leadNote } = this.state;
		const projectId = this.props.projectId;
		const leadId = this.props.leadId;
		const typeName = 'note';
		let contentId = this.props.leadNote.id;

		this.setState({ submitted: true }, () => {
			if (this.state.formValid) {
				if(this.state.submitted){

					this.props.updateLeadNote(projectId, leadId, typeName, contentId, leadNote);

					let { modalOpen, toggleEditLeadNote } = this.props;
					if(modalOpen){
						toggleEditLeadNote();
					}

					this.setState({
						formNameValid: false,
						formValid: false,
						submitted: false,
						formErrors: {
							name: ''
						},
						class: 'disabled'
					});

					this.props.handleGetLead();
				}
			}
		});
	};

	errorClass(error) {
		return(error.length === 0 ? 'is-passed' : 'is-failed');
	}

	render() {
		const { creating  } = this.props;
		let { modalOpen, toggleEditLeadNote } = this.props;
		const { form, submitted, formValid } = this.state;

		let leadNote = this.props.leadNote;

		return (
			<MDBContainer>
				<form noValidate>
					<MDBModal isOpen={modalOpen} toggle={toggleEditLeadNote} id='create-form-modal' centered>
						<MDBModalHeader className='padding-left-18 padding-right-18 pb-3 pt-3' toggle={toggleEditLeadNote}>
							<p className='mb-0 font-size-12 font-weight-600 letter-spacing-1'>EDIT NOTE</p>
						</MDBModalHeader>
						<MDBModalBody className='pt-2 padding-left-18 padding-right-18 padding-bottom-15'>
							<MDBRow>
								<MDBCol className={`form-group m--sm ${submitted && this.errorClass(this.state.formErrors.name)}`}>
									<label className='mb-1 font-size-12 text-black'>Title</label>
									<input
										onChange={(e) => {this.handleChange(e)}}
										name='name'
										type='text'
										autoFocus={true}
										defaultValue={leadNote ? leadNote.name : ''}
										id='FormName'
										placeholder='Enter note title here...'
									/>
									<div className={`form-validation__container ${(!formValid && submitted) ? 'display-block' : 'display-none'}`}>
										<FormValidation fieldName='name' formErrors={this.state.formErrors}/>
									</div>
								</MDBCol>
							</MDBRow>
							<MDBRow>
								<MDBCol>
									<label className='mb-1 pt-2 font-size-12 text-black'>Body</label>
									<textarea
										onChange={(e) => {this.handleChange(e)}}
										name='description'
										defaultValue={leadNote ? leadNote.description : ''}
										id='textarea-description'
										className='form-control font-size-12 color-none'
										rows='7'
										placeholder='Enter notes here...'
									/>
								</MDBCol>
							</MDBRow>
							<MDBRow className='pt-2 pb-0'>
								<MDBCol className='d-flex'>
                  <span
	                  className='mb-0 font-color-orange my-auto text-cursor-pointer border-bottom-orange line-height-15 font-size-12'
	                  onClick={toggleEditLeadNote}
                  >
                    Cancel
									</span>
								</MDBCol>
								<MDBCol
									className='text-right'
									id='nextButton'
								>
									<Button
										flat='true'
										type='submit'
										size={-1}
										background={this.state.class}
										disabled={this.state.class === 'disabled' ? 'disabled' : ''}
										onClick={(e) => {this.handleSubmit(e)}}
									>UPDATE NOTE {creating &&
									<Loader />
									}
									</Button>
								</MDBCol>
							</MDBRow>
						</MDBModalBody>
					</MDBModal>
				</form>
			</MDBContainer>
		);
	}
}

function mapState(state) {
	const { creating, forms } = state;
	return { creating, forms };
}

const actionCreators = {
	updateLeadNote: leadActions.updateLeadNote
};

const connectedEditLeadNoteModal = connect(mapState, actionCreators)(EditLeadNoteModal);
export { connectedEditLeadNoteModal as EditLeadNoteModal };
