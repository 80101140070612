import React, {Component, useState} from 'react'
import { MDBContainer, MDBRow, MDBCol, MDBModal, MDBModalHeader, MDBModalBody } from 'mdbreact'
import {influencerActions} from "_actions";
import {connect} from "react-redux";

class ManageRecordModal extends Component {

    constructor(props) {
        super(props);

        this.state = {
            isTitleError: false,
            isDescriptionError: false,
            isLinkError: false,
            toggleTabState: 1,
        }

        this.titleInput = React.createRef();
        this.descriptionInput = React.createRef();
        this.linkInput = React.createRef();
    }

    handleCloseManageRecordsModal = (mode) => {
        this.props.handleCloseManageRecordsModal(mode);
        this.setTitle();
        this.setDescription();
        this.setLink();
        this.handleClearInput();
    }

    setTitle = value => {
        this.setState({isTitleError: false});
    }

    setDescription = value => {
        this.setState({isDescriptionError: false});
    }

    setLink = value => {
        this.setState({isLinkError: false});
    }

    isFieldValid() {

        // console.log(this.titleInput.current.value);
        let isValid = true;
    
        const title = this.titleInput.current.value;
        const description = this.descriptionInput.current.value;
        const link = this.linkInput.current.value;
    
        if(title === '' ) {
          this.setState({isTitleError: true});
          isValid = false;
        }
    
        if(description === '') {
          this.setState({isDescriptionError: true});
          isValid = false;
        }
        if(link === '') {
            this.setState({isLinkError: true});
            isValid = false;
        }
    
        return isValid;
    }

    handleManageRecord() {

        const { mode } = this.props;
    
        let isEdit = mode !== 'add';
    
        const isValue = this.isFieldValid();
    
    
        if(isValue) {
          if(isEdit) {
            this.handleEditInfluencerRecord();
          } else {
            this.handleAddInfluencerRecord();
          }
        }
    }

    handleAddInfluencerRecord () {
        const {influencerId, projectId, mode, addInfluencerDocument} = this.props;

        const title = this.titleInput.current.value;
        const description = this.descriptionInput.current.value;
        const link = this.linkInput.current.value;

        const document = {
            title: title,
            description: description,
            link: link
        }

        addInfluencerDocument(projectId, influencerId, document);

        this.handleCloseManageRecordsModal(mode);
    }

    handleEditInfluencerRecord () {
        const {influencerId, projectId, mode, updateInfluencerDocument, data} = this.props;

        const title = this.titleInput.current.value;
        const description = this.descriptionInput.current.value;
        const link = this.linkInput.current.value;

        const document = {
            title: title,
            description: description,
            link: link
        }

        updateInfluencerDocument(projectId, influencerId, data.id, document);

        this.handleCloseManageRecordsModal(mode);
    }

    handleClearInput = () => {
        // Clear the current.value of the ref
        const {mode, data} = this.props;

        if (mode === 'add') {
            this.titleInput.current.value = '';
            this.descriptionInput.current.value = '';
            this.linkInput.current.value = '';
        }

        if (mode === 'edit') {
            this.titleInput.current.value = data.title;
            this.descriptionInput.current.value = data.description;
            this.linkInput.current.value = data.link;
        }
        
      }

    componentDidUpdate(prevProps) {
        // Check if the props or state have changed
        if (prevProps.mode !== this.props.mode) {
            console.log('previous.. ' + this.prevProps)
            console.log('current.. ' + this.props)
          // Perform the necessary update
            if (this.props.mode === 'add') {
                this.titleInput.current.value = '';
                this.descriptionInput.current.value = '';
                this.linkInput.current.value = '';
            }

            if (this.props.mode === 'edit') {
                    this.titleInput.current.value = this.props.data.title;
                    this.descriptionInput.current.value = this.props.data.description;
                    this.linkInput.current.value = this.props.data.link;
            }
                
         }
    }
    

    render() {
        const { isOpen, mode, data } = this.props;
        const {isTitleError, isDescriptionError, isLinkError} = this.state;

        let isEdit = mode !== 'add';
        let activeClass = isOpen ? 'active' : '';

        return (
            <React.Fragment>
                <div className={`drawer-overlay ${activeClass}`} onClick={(mode)=> this.handleCloseManageRecordsModal(mode) }></div>
                <div className={`side-drawer-main ${activeClass}`}>
                    <div className='side-drawer-wrapper'>
                        <div className='side-drawer-title'>
                            <h3>{mode.toUpperCase()} RECORD</h3>
                            <span className='side-drawer-close' onClick={(mode)=> this.handleCloseManageRecordsModal(mode) }><i className="icon-lms icon-close"></i></span>
                        </div>
                        <div className='side-drawer-tabs'>
                            <div className='side-drawer-tabs-wrapper'>
                                <div className='control-tabs-box'>
                                    <div className={this.state.toggleTabState === 1 ? 'tabs-btn active' : 'tabs-btn'} onClick={() => this.toggleTab(1)}>
                                        <p>
                                            Record Information
                                        </p>
                                    </div>
                                </div>
                                <div className='content-tabs-box'>
                                    <div className={this.state.toggleTabState === 1 ? 'tabs-content active' : 'tabs-content'}>
                                        <div className='forms-tab'>
                                            <MDBRow>
                                                <MDBCol className='col-12 form-group-box'>
                                                    <div className='input-group-box full-box-'>
                                                        <div className={isTitleError ? `field-box error-field` : `field-box`}>
                                                            <label htmlFor={'title'}>Title <small>*</small></label>
                                                            <input ref={this.titleInput} type='text' id={'title'} name={'title'} defaultValue={isEdit ? data.title : ''} onChange={(e) => this.setTitle(e.target.value)} />
                                                            {isTitleError &&<p className='error-field'>This field is required</p>}    
                                                        </div>
                                                        <div className={isDescriptionError ? `field-box error-field` : `field-box`}>
                                                            <label htmlFor={'description'}>Description <small>*</small></label>
                                                            <input ref={this.descriptionInput} type='text' id={'description'} name={'description'} defaultValue={isEdit ? data.description : ''} onChange={(e) => this.setDescription(e.target.value)} />
                                                            {isDescriptionError &&<p className='error-field'>This field is required</p>} 
                                                        </div>
                                                        <div className={isLinkError ? `field-box error-field` : `field-box`}>
                                                            <label htmlFor={'link'}>Document Link <small>*</small></label>
                                                            <input ref={this.linkInput} type='text' id={'link'} name={'link'} defaultValue={isEdit ? data.link : ''} onChange={(e) => this.setLink(e.target.value)} />
                                                            {isLinkError &&<p className='error-field'>This field is required</p>}  
                                                        </div>
                                                    </div>
                                                </MDBCol>
                                            </MDBRow>
                                        </div>
                                    </div>
                                </div>
                                <div className='tab-form-btn-group'>
                                    <MDBRow>
                                        <MDBCol className='text-right'>
                                            <span className='drawer-btn cancel-btn' onClick={(mode)=> this.handleCloseManageRecordsModal(mode) }>
                                                Cancel
                                            </span>
                                            <span className='drawer-btn save-btn' onClick={() => this.handleManageRecord()}>
                                                {mode === 'add' ? `Save Record` : `Update Record`}
                                            </span>
                                        </MDBCol>
                                    </MDBRow>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                {/* <MDBContainer className='modal-container modal-form-container'>
                    <MDBRow>
                        <MDBCol>
                            <MDBModal isOpen={isOpen} centered tabIndex='-1'>
                                <MDBModalHeader className='confirmation-modal-header text-uppercase p-2'><span className='pl-1 mx-2 letter-spacing-1 font-weight-600 font-color-gray font-size-13'>{mode.toUpperCase()} RECORDS</span></MDBModalHeader>
                                <MDBModalBody>
                                    <MDBRow className='form-group-row'>
                                        <MDBCol className={isTitleError ? `col-12 has-error-input` : `col-12`}>
                                            <label htmlFor={'title'}>Title</label>
                                            <input ref={this.titleInput} type='text' id={'title'} name={'title'} defaultValue={isEdit ? data.title : ''} onChange={(e) => this.setTitle(e.target.value)} />
                                            {isTitleError &&<span>This field is required</span>}
                                        </MDBCol>
                                        <MDBCol className={isDescriptionError ? `col-12 has-error-input` : `col-12`}>
                                            <label htmlFor={'description'}>Description</label>
                                            <input ref={this.descriptionInput} type='text' id={'description'} name={'description'} defaultValue={isEdit ? data.description : ''} onChange={(e) => this.setDescription(e.target.value)} />
                                            {isDescriptionError &&<span>This field is required</span>}
                                        </MDBCol>
                                        <MDBCol className={isLinkError ? `col-12 has-error-input` : `col-12`}>
                                            <label htmlFor={'link'}>Document Link</label>
                                            <input ref={this.linkInput} type='text' id={'link'} name={'link'} defaultValue={isEdit ? data.link : ''} onChange={(e) => this.setLink(e.target.value)} />
                                            {isLinkError &&<span>This field is required</span>}
                                        </MDBCol>
                                    </MDBRow>
                                    <MDBRow>
                                        <MDBCol className='text-center'>
                                            <MDBCol className='text-center'>
                                                <button className='modal-btn save-btn-modal' onClick={() => this.handleManageRecord()}>
                                                    {mode === 'add' ? `Save Record` : `Update Record`}
                                                </button>
                                                <button className='modal-btn cancel-btn-modal' onClick={(mode)=> this.handleCloseManageRecordsModal(mode) }>
                                                    Cancel
                                                </button>
                                            </MDBCol>
                                        </MDBCol>
                                    </MDBRow>
                                </MDBModalBody>
                            </MDBModal>
                        </MDBCol>
                    </MDBRow>
                </MDBContainer> */}
            </React.Fragment>
        )
    }

}

function mapState(state) {
    const { influencers } = state;
    return { influencers }
}

const actionCreators = {
    addInfluencerDocument : influencerActions.addInfluencerDocument,
    updateInfluencerDocument : influencerActions.updateInfluencerDocument
};

const connectedViewManageRecordModal = connect(mapState, actionCreators)(ManageRecordModal);
export { connectedViewManageRecordModal as ManageRecordModal };