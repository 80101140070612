import React, {Component} from 'react';
import {connect} from "react-redux";
import {Link, Redirect} from 'react-router-dom';
import {formActions, leadActions, projectActions} from '_actions';

import {
    MDBCol,
    MDBContainer,
    MDBDropdown,
    MDBDropdownItem,
    MDBDropdownMenu,
    MDBDropdownToggle,
    MDBIcon,
    MDBRow
} from 'mdbreact';
import {Button, ButtonGroup, Icon, InputSelect} from "blackbox-react";
//import Slider from '_pages/Forms/FormBuilder/components/Slider';
import InputButtons from '_pages/Forms/components/InputButtons';
import PresetsButton from '_pages/Forms/components/PresetsButton';

import {FormGroupManage} from '_pages/Forms/FormGroupManage';
import {quickPresets} from '_pages/Forms/constants/quickPresets';
import {inputsManage} from '_pages/Forms/constants/inputsManage';
import {EditFormModal} from '_pages/Forms/components/EditFormModal'
import {EditFormGroupUpdate} from '_pages/Forms/FormBuilder/components/EditFormGroupUpdate';
import {EditFormFieldUpdate} from '_pages/Forms/FormBuilder/components/EditFormFieldUpdate';
import CreateFormGroup from '_pages/Forms/FormBuilder/components/CreateFormGroup';

import {utilitiesService as utility} from "_services";
import avatar from "_assets/images/avatar-lg.png";
import Moment from "react-moment";
import {FormGroupView} from "_pages/Forms/FormGroupView";
import AddFieldModal from "_components/Modal/AddFieldModal";

class ManageLead extends Component {

    constructor(props) {
        super(props);

        const projectId = sessionStorage.getItem('projectId');
        const projectData = JSON.parse(sessionStorage.getItem('project'));
        this.props.getAllProjectStatus(projectId);
        this.props.getAllProjectMembers(projectId);
        const projectForms = projectData.forms;
        const leadId = this.props.match.params.id;
        const lead = JSON.parse(sessionStorage.getItem('lead'));
        //const basicInfo = projectForms.find(item => item.is_default) || {};
        const formId = lead.form_id /*|| 0*/;
        this.props.getForm(projectId, formId);
        sessionStorage.setItem('leadId', leadId);

        //this.myRefButton = React.createRef();

        var tempData = [];
        lead.basic_info.forEach(bi => {
            var temp = {};
            temp.key = bi.field_id;
            temp.value = bi.value;
            tempData.push(temp)
        });

        this.state = {
            addField: false,
            projectData: projectData,
            selectedStatus: lead.project_status.id,
            selectedPriority: lead.priority.id,
            selectedOwner: lead.owner,
            selectedResponsible:  lead.responsible !== null ? lead.responsible.email : null,
            formGroupData: {},
            formGroupId: 0,
            fieldData: {},
            leadData: tempData || [],
            lead: lead,
            leadUpdated: false,
            dropdownSelectInfo: [],
            isManageLead: true
        }


    }

    getEditFormGroup = data => {
        this.setState({
            formGroupData: data || {}
        });
    };

    getEditFormField = (data, formGroupId) => {
        this.setState({
            fieldData: data || {},
            formGroupId: formGroupId
        });
    };

    handleDropdownSelectData = (info, index) => {

        const selectLeadInfo = [];

        const basicInfo = JSON.parse(sessionStorage.getItem('lead_info'))

        basicInfo.forEach(bInfo => {
            if(index === bInfo.field_id) {
                selectLeadInfo.push = {
                    key: index,
                    value: utility.encrypt(info)
                }
                sessionStorage.setItem('selectDropdownInfo', JSON.stringify(selectLeadInfo.push))

            }
        })

    }

    handleAPIDropdownData = (info, index) => {
        const leadInfoAPI = [];

        const basicInfo = JSON.parse(sessionStorage.getItem('lead_info'))

        basicInfo.forEach(bInfo => {
            if(index === bInfo.field_id) {
                leadInfoAPI.push = {
                    key: index,
                    value: utility.encrypt(info)
                }
                sessionStorage.setItem('selectAPIDropdownInfo', JSON.stringify(leadInfoAPI.push))

            }
        })

    }

    handleUpdate = (event) => {

        let updatedKey = parseInt(event.target.name);
        let updatedValue = event.target.value;



        let leadData = this.state.leadData;
        let lead = this.state.lead;

        let objIndex = leadData.findIndex((obj => obj.key === updatedKey));
        if(objIndex === -1) {
            let temp = {}
            temp.key = updatedKey;
            temp.value = utility.encrypt(updatedValue);
            leadData.push(temp);
        } else {
            leadData[objIndex].value = utility.encrypt(updatedValue);
        }

        let basic_info = lead.basic_info;
        basic_info.find((obj => obj.field_id === updatedKey)).value = utility.encrypt(updatedValue);
        lead.basic_info = basic_info;



        this.setState({
            leadData: leadData,
            lead: lead
        });
    };

    handleUpdateLead = () => {
        const projectId = sessionStorage.getItem('projectId');
        const lead = JSON.parse(sessionStorage.getItem('lead'));
        let leadData = this.state.leadData;

        const leadId = lead.id
        let selectData = JSON.parse(sessionStorage.getItem('selectDropdownInfo'))
        let apiData = JSON.parse(sessionStorage.getItem('selectAPIDropdownInfo'))

        if(typeof selectData !== 'undefined' && selectData !== null) {
            leadData.push(selectData)
        }
        if(typeof apiData !== 'undefined' && apiData !== null) {
            leadData.push(apiData)
        }

        if (leadData.length === 0){
            leadData = null;
        }


        let projectStatus = this.props.projects.status.data;
        projectStatus = projectStatus.sort((a, b) => a.id > b.id);

        const defaultStatus = projectStatus[0].id;
        const defaultPriority = '2';
        const defaultOwner = /*'Unassigned'*/null;
        const defaultResponsible = /*'Unassigned'*/null;

        const selectedStatus = this.state.selectedStatus;
        const selectedPriority = this.state.selectedPriority;
        let selectedOwner = this.state.selectedOwner;
        let selectedResponsible = this.state.selectedResponsible;


        if (selectedOwner === defaultOwner) {
            selectedOwner = null;
        }

        if (selectedResponsible === defaultResponsible){
            selectedResponsible = null;
        }

        let leadDataToAdd = {
            form_lead: leadData,
            project_status_id: selectedStatus,
            priority: selectedPriority,
            owner: selectedOwner.email,
            responsible: selectedResponsible
        };


        this.props.updateLead(projectId, leadId, leadDataToAdd);

        setTimeout(() => {
            //this.myRefButton.click();
            this.props.history.goBack();
        }, 2000)


    };

    handleMapInputs = (data) => {
        return data.map((value, index) => {
            return (
                <div key={index} className='w-48'>
                    <p className='font-size-16 mb-0'>{value.label}<span className={value.required ? 'color-red' : 'invisible'}>*</span></p>
                    <input
                        className='border-gray mb-0 br-5 padding-3 px-2 font-size-13'
                        placeholder={value.placeholder}
                    />
                </div>
            )
        })
    };

    handleModal = () => {
        return this.setState({ addField: !this.state.addField })
    };

    renderLeadPhoto = () => {
        return (
            <div className='box-right-rectangle size-100 br-75 flex-center mx-auto mb-4'>
                <div className='text-center'>
                    {/*<MDBIcon icon='camera' className='font-size-25'/>*/}
                    <MDBIcon icon='paper-plane' className='font-size-30 color-orange'/>
                    {/*<p className='mb-0 font-size-10'>Add photo</p>*/}
                </div>
            </div>
        );
    };

    renderProjectStatus = () => {
        let projectStatus = this.props.projects.status.data;
        const lead = JSON.parse(sessionStorage.getItem('lead'));
        let leadStatus = lead.project_status;
        projectStatus = projectStatus.sort((a, b) => a.id > b.id);

        let hasOptions = projectStatus.length > 0;
        let selectOptions = [];

        if(hasOptions){
            projectStatus.map(
                (value, index) => {
                    selectOptions.push({
                        id: value.id,
                        value: value.id,
                        label: value.name
                    });
                    return selectOptions;
                })
        }

        return (
            <div>
                <label htmlFor='selectStatus' className='mb-0 font-size-14 font-weight-bold'>Status</label>
                <div className='width-inherit background-color-white'>
                    { hasOptions &&
                        <InputSelect
                            name='selectStatus'
                            defaultValue=/*{selectOptions[0].value}*/{leadStatus.id}
                            onChange={(v) => this.setState({ selectedStatus: v })}
                            options={selectOptions}
                        />
                    }
                </div>
            </div>
        );
    };

    renderLeadPriority = () => {
        const lead = JSON.parse(sessionStorage.getItem('lead'));
        const leadPriority = lead.priority;
        let selectOptions = [
            {
                id: 1,
                value: '1',
                label: 'Low'
            },
            {
                id: 2,
                value: '2',
                label: 'Medium'
            },
            {
                id: 3,
                value: '3',
                label: 'High'
            },
        ];
        return (
            <div>
                <label htmlFor='selectPriority' className='mb-0 font-size-14 font-weight-bold'>Priority</label>
                <div className='width-inherit background-color-white'>
                    <InputSelect
                        name='selectedPriority'
                        defaultValue=/*{selectOptions[1].value}*/{leadPriority.id}
                        onChange={(v) => this.setState({ selectedPriority: v })}
                        options={selectOptions}
                    />
                </div>
            </div>
        );
    };

    handleGetProjectOwnerAndMembers = () => {
        let projectData = this.state.projectData;
        let projectOwner = projectData.owner;
        let projectMembers = this.props.projects.members.data;

        projectMembers = projectMembers.sort((a, b) => a.member_id > b.member_id);

        let hasMembers = projectMembers.length > 0;
        let selectOptions = [];

        let projectOwnerName = utility.decrypt(projectOwner.account.first_name) + ' ' + utility.decrypt(projectOwner.account.last_name);

        // Add Unassigned
        selectOptions.push({
            id: 0,
            value: utility.encrypt('unassigned'),
            label: 'Unassigned'
        });

        // Add Project Owner
        selectOptions.push({
            id: projectOwner.id,
            value: projectOwner.account.email,
            label: projectOwnerName
        });

        // Add Project Members
        if(hasMembers){
            projectMembers.map(
                (value, index) => {
                    let projectMemberName = utility.decrypt(value.account.first_name) + ' ' + utility.decrypt(value.account.last_name);
                    selectOptions.push({
                        id: value.id,
                        value: value.account.email,
                        label: projectMemberName
                    });
                    return selectOptions;
                })
        }

        return selectOptions;
    };

    renderLeadOwner = () => {

        let projectData = this.state.projectData;
        let projectOwner = projectData.owner;
        const lead = JSON.parse(sessionStorage.getItem('lead'));
        const leadOwner = lead.owner;
        let selectOptions = this.handleGetProjectOwnerAndMembers();
        let hasOptions = selectOptions.length > 0;
        let selectOwner = [];

        if(projectData && leadOwner) {
            if(projectOwner.account.email === leadOwner.email){
                selectOwner.push({
                    id: projectOwner.id,
                    value: leadOwner.email,
                    label: utility.decrypt(leadOwner.first_name) + ' ' + utility.decrypt(leadOwner.last_name)
                })
            }
        }


        const defaultOwnerValue = leadOwner !== null;


        return (
            <div>
                <label htmlFor='selectOwner' className='mb-0 font-size-14 font-weight-bold'>Owner</label>
                <div className='width-inherit background-color-white'>
                    { hasOptions && defaultOwnerValue ?
                        <InputSelect
                            name='selectedOwner'
                            defaultValue={selectOwner[0].value}
                            onChange={(v) => this.setState({ selectedOwner: v ? selectOwner : v})}
                            options={selectOptions}
                        />
                        :
                        <InputSelect
                            name='selectedOwner'
                            defaultValue={selectOptions[0].value}
                            onChange={(v) => this.setState({ selectedOwner: v })}
                            options={selectOptions}
                        />
                    }
                </div>
            </div>
        );
    };

    renderLeadResponsible = () => {
        /*const lead = JSON.parse(sessionStorage.getItem('lead'));
        const leadResponsible = lead.responsible;
        const selectedResponsible = [{
            id: leadResponsible.id,
            value: leadResponsible.email,
            label: utility.decrypt(leadResponsible.first_name) + ' ' + utility.decrypt(leadResponsible.last_name)
        }
        ]*/
        let selectOptions = this.handleGetProjectOwnerAndMembers();
        let hasOptions = selectOptions.length > 0;


        return (
            <div>
                <label htmlFor='selectResponsible' className='mb-0 font-size-14 font-weight-bold'>Responsible</label>
                <div className='width-inherit background-color-white'>
                    { hasOptions &&
                        <InputSelect
                            name='selectedResponsible'
                            defaultValue={this.state.selectedResponsible}
                            onChange={(v) => this.setState({ selectedResponsible: v })}
                            options={selectOptions}
                        />
                    }
                </div>
            </div>
        );
    };

    renderSideBar = () => {
        return (
            <React.Fragment>
                <MDBRow className='render-sidebar align-items-center justify-content-start'>
                    {/* { this.renderLeadPhoto() } */}
                    <MDBCol>
                        {this.renderProjectStatus()}
                    </MDBCol>
                    <MDBCol>
                        {this.renderLeadPriority()}
                    </MDBCol>
                    <MDBCol>
                        {this.renderLeadOwner()}
                    </MDBCol>
                    <MDBCol>
                        {this.renderLeadResponsible()}
                    </MDBCol>
                </MDBRow>
            </React.Fragment>
        );
    };

    renderFormGroups = (data) => {
        let totalFormGroups = data.length || 0;
        let hasFormGroups = totalFormGroups > 0;
        //let formGroupId = this.state.formGroupId;

        if(hasFormGroups){
            data = data.sort((a, b) => a.form_order > b.form_order);
        }

        return (
            <div
                className='preview__stage'
                onClick={(e) => {
                    e.stopPropagation();
                    this.setState({
                        activeFormGroup: '',
                        activeFormField: '',
                        activeSideBar: 'select'
                    });
                }}
            >
                { hasFormGroups && this.state.lead &&
                    data.map((value, index) => {

                        return (
                            <FormGroupView
                                key={value.id}
                                formGroupData={value}
                                formGroupId={value.id}
                                formGroupKey={value.id}
                                getEditFormGroup={this.getEditFormGroup}
                                getEditFormField={this.getEditFormField}
                                getFormGroupButtons={this.getFormGroupButtons}
                                getFormFieldButtons={this.getFormFieldButtons}
                                totalFormGroups={totalFormGroups}
                                handleUpdate={this.handleUpdate}
                                handleActiveFormGroup={this.handleActiveFormGroup}
                                handleActiveFormField={this.handleActiveFormField}
                                activeFormGroup={this.state.activeFormGroup}
                                handleCreateFormGroup={this.handleCreateFormGroup}
                                handleCreateFormGroupInBetween={this.handleCreateFormGroupInBetween}
                                handleFormGroupReorder={this.handleFormGroupReorder}
                                handleFormFieldReorder={this.handleFormFieldReorder}
                                activeFormField={this.state.activeFormField}
                                projectId={sessionStorage.getItem('projectId')}
                                formId={sessionStorage.getItem('formId')}
                                leadId={this.props.match.params.id}
                                leadInfo={this.state.lead}
                                dataToAppend={this.dataAppend}
                                handleGetForm={this.handleGetForm}
                                handleSelectedInput={this.handleSelectedInput}
                                selectedInput={this.state.selectedInput}
                                dropdownSelect={this.handleDropdownSelectData}
                                isManageLead={this.state.isManageLead}
                                APIDropdownSelect={this.handleAPIDropdownData}
                            />
                        );
                    })
                }
            </div>
        );
    };

    handleBasicInfo = () => {
        let form = this.props.forms.form.data;
        return this.renderFormGroups(form.group);
    };

    renderBasicInformation = () => {
        let form = this.props.forms.form.data;
        return (
            <MDBCol xl='12' lg='12' className='box-right-rectangle p--0 p-t--sm p-b--sm m--0 height--auto'>
                <div className="basic-info p--0">
                    <h5 className='font-size-20'>
                        <MDBIcon far icon='address-card' /> &nbsp; { form.info.name }
                    </h5>
                    <div className='field-container m-t--sm p--sm border-radius-5'>
                        { this.handleBasicInfo() }
                        <span className='font-size-10 p-l--md'>Please fill out all fields.</span>
                    </div>
                </div>

                {/*<MDBBtn*/}
                {/*  flat*/}
                {/*  className='w-100 text-transform-none dotted add-field-button add-field-background m-0 mb-1 color-orange shadow-none button-presets'*/}
                {/*  onClick={*/}
                {/*    this.handleModal*/}
                {/*  }*/}
                {/*>*/}
                {/*  <img alt='' src={addIcon} className='add-icon-width-18 mr-2 mt-less-3' />Add Field*/}
                {/*</MDBBtn>*/}

                {/* <MDBCol xl='12' lg='12'>
                    <div className="float-right m-t--md" style={{ position: 'relative', zIndex: '9999'}}>
                        <Button
                            flat='true'
                            background='orange' foreground='white'
                            borderWidth={2} borderColor='orange' size={-2}
                            onClick={(e) => {
                                this.handleUpdateLead();
                            }}>
                            <span className='color-white font-weight-bold'>Update Lead</span>
                        </Button>
                    </div>
                </MDBCol> */}

            </MDBCol>

        );
    };

    handleCustomInputs = (data) => {
        return data.map((value, index) => {
            return (
                <div key={index} className='w-48'>
                    <p className='font-size-16 mb-0'>{value.label}<span className='invisible'>*</span></p>
                    <input
                        className='border-gray mb-0 br-5 padding-3 px-2 font-size-13'
                        placeholder={value.placeholder}
                    />
                </div>
            )
        })
    };

    handleCustomFields = () => {
        let data = this.state.dataToMap;
        return data.map((value, index) => {
            let inputs = value.fields.input;
            return (
                <div key={index} className='row mb-3 justify-content-between px-3'>
                    {this.handleCustomInputs(inputs)}
                </div>
            )
        })
    };

    /*renderCustomFields = () => {
        let projectId = sessionStorage.getItem('projectId');
        return (
            <MDBCol xl='4' lg='4' className='p--0 p-t--sm p-b--sm m--0'>
                <div className="custom-fields p-t--0 m-t--0">
                    {/!*<h5 className='font-size-20'>*!/}
                    {/!*  <MDBIcon icon='i-cursor' /> &nbsp; CUSTOM FIELDS*!/}
                    {/!*</h5>*!/}
                    <div className="p--md">
                        {/!*{ this.handleCustomFields() }*!/}
                        <div className='create-view text-center'>
                            <p className='font-size-25 font-weight-500 font-family-crimson m-b--sm'>
                                Want to Add More Fields?
                            </p>
                            <p className='p-l--lg p-r--lg'>
                                You can add more fields if you need to record more lead information.
                            </p>
                            <Link to={`/project/${projectId}/leads/manage-fields`} className=''>
                                <Button
                                    flat='true'
                                    background='orange' foreground='white'
                                    borderWidth={2} borderColor='orange'
                                    size={-2}
                                >
                                    <MDBIcon icon='cog' className='mr-2'/>
                                    <span className='color-white'>Manage Fields</span>
                                </Button>
                            </Link>
                        </div>
                    </div>
                </div>

                {/!*<p className='pt-4 font-size-20'>*!/}
                {/!*  <Link to='/manage_field'>*!/}
                {/!*    <span className='float-right font-size-14 font-weight-600 color-blue'>Manage Fields <span>></span></span>*!/}
                {/!*  </Link>*!/}
                {/!*</p>*!/}

                {/!*<MDBBtn*!/}
                {/!*  flat*!/}
                {/!*  className='w-100 text-transform-none dotted add-field-button add-field-background m-0 mb-1 color-orange shadow-none button-presets'*!/}
                {/!*  onClick={*!/}
                {/!*    this.handleModal*!/}
                {/!*  }*!/}
                {/!*>*!/}
                {/!*  <img alt='' src={addIcon} className='add-icon-width-18 mr-2 mt-less-3' />Add Field*!/}
                {/!*</MDBBtn>*!/}
            </MDBCol>
        );
    };*/

    render() {
        let projectId = sessionStorage.getItem('projectId');
        let leadId = this.props.match.params.id;
        const { membersLoaded, statusLoaded, formLoaded } = this.props;

        if(formLoaded){
            let formGroups = this.props.forms.form.data.group;
            let formFields = [];

            formGroups.map(group => (
                group.field.map(field => formFields.push(field.id))
            ));

            sessionStorage.setItem('formFieldKeys', JSON.stringify(formFields));
        }

        return (
            <MDBContainer fluid className='p--0'>
                <div className='page-title-ctas p-l-md p-r-md'>
                    <MDBRow className='m--0 align-items-center'>
                        <MDBCol md='auto' className='p--0'>
                            <p className='page-title font-family-kanit font-size-30 line-height-7 color-gray line-height-6'>
                                Edit Lead
                            </p>
                        </MDBCol>
                        <MDBCol>
                            <MDBRow className='float-right'>
                                <ButtonGroup>
                                    <Link to={`/project/${projectId}/lead/${leadId}`}>
                                        <Button className='bordered-btn'>
                                            <i className="fas fa-chevron-left"></i>
                                            Back to Lead Info
                                            {/* ref={this.myRefButton} */}
                                        </Button>
                                    </Link>
                                    <Button
                                        className='background-btn'
                                        onClick={(e) => {
                                            this.handleUpdateLead();
                                        }}>
                                        Update Lead
                                    </Button>
                                </ButtonGroup>
                            </MDBRow>
                        </MDBCol>
                    </MDBRow>
                </div>

                <div className="page-filter">
                    {(statusLoaded && membersLoaded && formLoaded) &&
                        <React.Fragment>
                            { (statusLoaded && membersLoaded) && this.renderSideBar() }
                        </React.Fragment>

                    }
                </div>


                <MDBContainer fluid className={`${ (statusLoaded && membersLoaded && formLoaded) || ''} p--0 main-body-container container-fluid false p--sm sub-body-container rectangle-background`}>
                    <AddFieldModal
                        modalToggle={this.handleModal}
                        modalOpen={this.state.addField}
                    />
                    { (statusLoaded && membersLoaded && formLoaded) ?
                        <MDBCol className='background-rectangle p-l--sm p-r--md edit-lead-box-'>


                            { formLoaded && this.renderBasicInformation() }
                            {/*{ this.renderCustomFields() }*/}

                        </MDBCol>
                        :
                        <MDBRow className='sub-body-container height--full'>
                            <MDBCol md='auto' lg='12' className='sub-body-container height--full'>
                                <div className='loader-container'>
                                    <div className="loader dark">Loading...</div>
                                </div>
                            </MDBCol>
                        </MDBRow>
                    }
                </MDBContainer>
            </MDBContainer>
        );
    }
}

function mapState(state) {
    const { projects, project, forms, leads, lead, adding } = state;
    const { loading, projectLoaded, members, activityLogLoaded, membersLoaded, status, statusLoaded } = state.projects;
    const { created, formLoaded, form } = state.forms;
    const { leadData, leadLoaded, /*activityLogLoaded, notes, notesLoaded,*/ deleting } = state.leads;
    return { projects, project, created, members, loading, projectLoaded, activityLogLoaded, status, statusLoaded, forms, form, leads, lead, adding, membersLoaded, formLoaded, leadData, leadLoaded, deleting };
}

const actionCreators = {
    getProject: projectActions.getProject,
    getAllProjectMembers: projectActions.getAllProjectMembers,
    getActivityLog: projectActions.getActivityLog,
    getAllProjectStatus: projectActions.getAllProjectStatus,
    getForm: formActions.getForm,
    getLead: leadActions.getLead,
    //createLead: leadActions.createLead,
    updateLead: leadActions.updateLead
};

const connectedManageLead = connect(mapState, actionCreators)(ManageLead);
export { connectedManageLead as ManageLead };