import React from "react";
import { useHistory } from "react-router-dom";
import InfluencerPlatformInfo from "_pages/Influencer/components/Table/InfluencerPlatformInfo";
import {utilitiesService as utility} from "_services";
import {Link, useParams} from "react-router-dom";
import Moment from "react-moment";


const InfluencerTableListInfo = ({influencer}) => {

    const {id} = useParams();
    const history = useHistory();


    let influencerId = influencer.id;
    let name = influencer.name;
    let email = utility.decrypt(influencer.email);
    let contact = utility.decrypt(influencer.contact);
    let handler = '';
    if(influencer.handler){
          handler = utility.decrypt(influencer.handler.name);
    }
  
    let condition = influencer.condition.name;
    let color = influencer.condition.id;
    let updated = influencer.updated;
    let created = influencer.created;
    let categories = influencer.categories
    let clients = influencer.clients
    let platforms = influencer.platforms
    let contacted = influencer.contacted

    const handleRedirectLead = (influencerId) => {
        history.push(`/project/${id}/influencers/${influencerId}`);
    }

    return (
        <tr onClick={() => handleRedirectLead(influencer.id)}>
            <td>
                <Link to={`/project/${id}/influencers/${influencerId}`}>
                    <div className={'name-info'}>
                        <div className="main-name">
                            <span className="name">{name}</span>
                        </div>
                        <div className="others-info">
                            {
                                email &&
                                <span className='others-text'>{email}</span>
                            }
                            {
                                contact &&
                                <span className='others-text'>{contact}</span>
                            }
                            {
                                handler && 
                                <span className='others-text'>{handler}</span>
                            }
                        </div>
                    </div>
                </Link>
            </td>
            <td className={`status--box g-status--color-${color}`}>
                <div>
                    <span className={`status-text`}>
                        {condition}
                    </span>
                    <span className="time-text">
                        Updated: <Moment date={updated} fromNow ago/> ago
                    </span>
                </div>
            </td>
            <td>
                <InfluencerPlatformInfo platforms={platforms}/>
            </td>
            <td>
                {
                    categories.map((c,index) => {
                        return(
                            <span key={index} className='box-text'>{c.name}</span>
                        )
                    })
                }
            </td>
            <td>
                {contacted ?
                    <span>
                        <Moment date={contacted} format='LL'/>
                    </span>
                    :
                    <span>
                        Never
                    </span>
                }
            </td>
            <td>
                {
                    clients.map((c,index) => {
                        return(
                            <span key={index} className='box-text'>{c.name}</span>
                        )
                    })
                }
            </td>


            <td>
                <span>
                    <Moment date={created} format='LLL'/>
				</span>
            </td>
        </tr>)

}

export default InfluencerTableListInfo;