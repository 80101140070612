import { calendlyConstants } from "_constants";
import { calendlyService } from "_services";
import { alertActions } from "./alert.actions";

export const calendlyActions = {
    getCalendly,
    updateCalendly,
    createCalendly,
    deleteCalendly,
  };

  function getCalendly(projectId, memberId) {

    return (dispatch) => {
      dispatch(request());
      calendlyService.getCalendly(projectId, memberId).then(
        (calendly) => {
          dispatch(success(calendly));

        },
        (error) => {
          dispatch(failure(error.toString()));
          dispatch(alertActions.error(error.toString()));
        }
      );
    };
  
    function request() {
      return { type: calendlyConstants.GET_CALENDLY_REQUEST };
    }
    function success(calendly) {
      return { type: calendlyConstants.GET_CALENDLY_SUCCESS, calendly };
    }
    function failure(error) {
      return { type: calendlyConstants.GET_CALENDLY_FAILURE, error };
    }
  }

  function createCalendly(projectId, memberId, calendlyRequest) {
    return (dispatch) => {
      dispatch(request());
      calendlyService.createCalendly(projectId, memberId, calendlyRequest).then(
        (calendly) => {
          dispatch(success(calendly));
          dispatch(
            alertActions.success("Calendly successfully added.")
          );
    
        },
        (error) => {
            // console.log('error: ', error);
          dispatch(failure(error.toString()));
          dispatch(alertActions.error(error.toString()));
        }
      );
    };
  
    function request() {
      return { type: calendlyConstants.CREATE_CALENDLY_REQUEST };
    }
    function success(calendly) {
      return { type: calendlyConstants.CREATE_CALENDLY_SUCCESS, calendly };
    }
    function failure(error) {
      return { type: calendlyConstants.CREATE_CALENDLY_FAILURE, error };
    }
  }
  
  function updateCalendly(projectId, calendlyId, calendlyRequest) {
    return (dispatch) => {
      dispatch(request());
      calendlyService.updateCalendly(projectId, calendlyId, calendlyRequest).then(
        (calendly) => {
          dispatch(success(calendly));
          dispatch(
            alertActions.success("Calendly successfully updated.")
          );
        },
        (error) => {
          dispatch(failure(error.toString()));
          dispatch(alertActions.error(error.toString()));
        }
      );
    };
  
    function request() {
      return { type: calendlyConstants.UPDATE_CALENDLY_REQUEST };
    }
    function success(calendly) {
      return { type: calendlyConstants.UPDATE_CALENDLY_SUCCESS, calendly };
    }
    function failure(error) {
      return { type: calendlyConstants.UPDATE_CALENDLY_FAILURE, error };
    }
  }


  function deleteCalendly(projectId, calendlyId) {

    return (dispatch) => {
      dispatch(request());
      calendlyService.removeCalendly(projectId, calendlyId).then(
        (calendly) => {
          dispatch(success(calendly));
          dispatch(
            alertActions.success("Calendly successfully removed")
          );
        },
        (error) => {
          dispatch(failure(error.toString()));
          dispatch(alertActions.error(error.toString()));
        }
      );
    };
  
    function request() {
      return { type: calendlyConstants.DELETE_CALENDLY_REQUEST };
    }
    function success(calendly) {
      return { type: calendlyConstants.DELETE_CALENDLY_SUCCESS, calendly };
    }
    function failure(error) {
      return { type: calendlyConstants.DELETE_CALENDLY_FAILURE, error };
    }
  }