import { utilitiesService } from '_services';
import React, { useState, useEffect, useRef } from 'react'
import { useDispatch, useSelector } from 'react-redux';
import { useParams } from 'react-router-dom';
import { influencerActions } from '_actions';

const HandleByFilter = ({responsible}) => {

  const params = useParams();
  const dispatch = useDispatch();

  const [handlers, setHandlers] = useState([]);

  const project = useSelector((state) => {
    return state;
  });

  useEffect(() => {
    // console.log('state: ', project);
    if(project.projects.loaded) {
      setHandlers(project.projects.project.data.members);
    }

  },[project.projects.project])

    const [isOpen, setIsOpen] = useState(false);
    const filterRef = useRef();

    function handleOpenFilter() {
      setIsOpen(!isOpen);
    }
    function handleCloseFilter() {
      setIsOpen(false);
    }

    function handleNameChange(responsible, name) {

        dispatch(influencerActions.updateInfluencerResponsible(params.id, params.influencerId, {
          influencer_id: params.influencerId,
          responsible_email: responsible,
          responsible_name: utilitiesService.encrypt(name)
        }));

        setIsOpen(false);
    }

    useEffect(() => {
      const filterOutsideClick = (e) => {
        if (
          isOpen &&
          filterRef.current &&
          !filterRef.current.contains(e.target)
        ) {
          setIsOpen(false);
        }
      };
      document.addEventListener("click", filterOutsideClick);
      return () => {
        document.removeEventListener("click", filterOutsideClick);
      };
    }, [isOpen]);

  return (
    <div className="filter-box filter-status" ref={filterRef}>
      <div className="filter-btn admin-btn">
        <span onClick={() => handleOpenFilter()}>{responsible}</span>
      </div>
      <div className={isOpen ? 'filter-dropdown active' : 'filter-dropdown'}>
        <div className="filter-title">
          <p>Handle By</p>
          <i className="icon-lms icon-close" onClick={() => handleCloseFilter()}></i>
        </div>
        <div className="filter-list status-list">
          {project.projects.loaded && 
            handlers.map((h, index) => {
              const fname = utilitiesService.decrypt(h.account.first_name);
              const lname = utilitiesService.decrypt(h.account.last_name);
              const handler = fname + ' ' + lname
              return <React.Fragment key={index}>
                <div className="filter-group">
                  <span className='' onClick={() => handleNameChange(h.account.email, handler)}>{handler}</span>
                </div>
              </React.Fragment>})}
          
        </div>
      </div>
    </div>
  )
}

export default HandleByFilter