import { publicConstants } from '_constants';

export function publicApi(state = {}, action) {
    switch (action.type) {

        // GET PUBLIC FORM
        case publicConstants.GET_PUBLIC_FORM_REQUEST:
            return {
                loading: true
            };
        case publicConstants.GET_PUBLIC_FORM_SUCCESS:
            return {
                items: action.generate_form
            };
        case publicConstants.GET_PUBLIC_FORM_FAILURE:
            return {
                error: action.error
            };

        // ADD LEAD
        case publicConstants.ADD_LEAD_PUBLIC_FORM_REQUEST:
            return {
                creating: true
            };
        case publicConstants.ADD_LEAD_PUBLIC_FORM_SUCCESS:
            return {};
        case publicConstants.ADD_LEAD_PUBLIC_FORM_FAILURE:
            return {};

        // GET OAUTH CONNECT
        case publicConstants.GET_OAUTH_CONNECT_REQUEST:
            return {
                loading: true
            };
        case publicConstants.GET_OAUTH_CONNECT_SUCCESS:
            return {
                items: action.connect_oauth,
                authorized: true
            };
        case publicConstants.GET_OAUTH_CONNECT_FAILURE:
            return {
                error: action.error
            };

        // GET OAUTH AUTHORIZE
        case publicConstants.GET_OAUTH_AUTHORIZE_REQUEST:
            return {
                loading: true
            };
        case publicConstants.GET_OAUTH_AUTHORIZE_SUCCESS:
            return {
                items: action.connect_oauth,
                authLoaded: true
            };
        case publicConstants.GET_OAUTH_AUTHORIZE_FAILURE:
            return {
                error: action.error
            };

      // UPDATE CONNECTION
        case publicConstants.UPDATE_CONNECTION_REQUEST:
            return {
                loading: true
            };
        case publicConstants.UPDATE_CONNECTION_SUCCESS:
            return {
                items: action.update_connection
            };
        case publicConstants.UPDATE_CONNECTION_FAILURE:
            return {
                error: action.error
            };

      // DISCONNECT
        case publicConstants.DISCONNECT_GMAIL_REQUEST:
            return {
                loading: true
            };
        case publicConstants.DISCONNECT_GMAIL_SUCCESS:
            return {
                disconnected: true
            };
        case publicConstants.DISCONNECT_GMAIL_FAILURE:
            return {
                error: action.error
            };

        default:
            return state
    }
}
