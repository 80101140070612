import React from 'react'

const RecentActivitySide = ({ classN }) => {
  return (
    <div className={classN}>
      <ul className="list-unstyled lead-recent-activities tabs-content-scrollable">
        <li>
          <div className="row height-48 m-0">
            <div className="mt-2 position-relative px-0">
              <p className="background-slider-color mb-0 mx-2 round-lead">
                <i className="far fa-user font-color-orange position-absolute font-size-11 top-7 left-16"></i>
              </p>
              <p className="mb-0 line-lead"></p>
            </div>
            <div className="col px-0 pt-1 line-height-15">
              <div>
                <span class="mb-0 font-size-11">Changed Status to </span>
                <span class="font-size-11 font-color-orange">Done</span>
              </div>
              <div class="d-flex opacity-50">
                <span class="mb-0 font-size-11 my-auto">
                  <i
                    data-test="fa"
                    class="fa fa-circle font-size-4 my-auto"
                  ></i>
                </span>
                <span class="custom-color font-size-11 ml-1 my-auto">
                  <time datetime="1658199189000" class="">
                    3 hours
                  </time>{" "}
                  ago
                </span>
              </div>
            </div>
          </div>
        </li>
        <li>
          <div className="row height-48 m-0">
            <div className="mt-2 position-relative px-0">
              <p className="background-slider-color mb-0 mx-2 round-lead">
                <i className="far fa-user font-color-orange position-absolute font-size-11 top-7 left-16"></i>
              </p>
              <p className="mb-0 line-lead"></p>
            </div>
            <div className="col px-0 pt-1 line-height-15">
              <div>
                <span class="mb-0 font-size-11">Changed Status to </span>
                <span class="font-size-11 font-color-orange">No Reply</span>
              </div>
              <div class="d-flex opacity-50">
                <span class="mb-0 font-size-11 my-auto">
                  <i
                    data-test="fa"
                    class="fa fa-circle font-size-4 my-auto"
                  ></i>
                </span>
                <span class="custom-color font-size-11 ml-1 my-auto">
                  <time datetime="1658199189000" class="">
                    6 hours
                  </time>{" "}
                  ago
                </span>
              </div>
            </div>
          </div>
        </li>
      </ul>
    </div>
  );
}

export default RecentActivitySide