import React, { useState, useEffect, useRef } from 'react'


const KpiStatusInfo = ({ title, description }) => {


  return (
    <>
        <div className='status-title'>
            <p className='main-title'>{title}</p>
            {/* <span className='status-toggle-info'>
                <i className='q-mark'>?</i>
                <div className='status-info-box'>
                    <div className='title'>
                        <p>{title}</p>
                    </div>
                    <div className='description'>
                        <p>
                            {description}
                        </p>
                    </div>
                </div>
            </span> */}
        </div>
    </>
  )
}

export default KpiStatusInfo