import {leadConstants} from "_constants";

export function reports(state = {}, action) {
    switch (action.type) {

        case leadConstants.GET_LEAD_REPORT_REQUEST:
            return {
                ...state,
                loadingReport: true
            };
        case leadConstants.GET_LEAD_REPORT_SUCCESS:
            return {
                ...state,
                loadingReport: false,
                report: action.report,
                reportLoaded: true
            };
        case leadConstants.GET_LEAD_REPORT_FAILURE:
            return {
                error: action.error
            };


        case leadConstants.GET_PATIENT_REPORT_REQUEST:
            return {
                ...state,
                loadingReport: true
            };
        case leadConstants.GET_PATIENT_REPORT_SUCCESS:
            return {
                ...state,
                loadingReport: false,
                report: action.report,
                reportLoaded: true
            };
        case leadConstants.GET_PATIENT_REPORT_FAILURE:
            return {
                error: action.error
            };

        default:
            return state

    }
}