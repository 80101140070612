import React, { Component } from "react";
import { leadActions } from "_actions";
import { connect } from "react-redux";
import { utilitiesService } from "_services";
import { connectableObservableDescriptor } from "rxjs/internal/observable/ConnectableObservable";

class FilterDropdown extends Component {
  constructor(props) {
    super(props);
    this.filterRef = React.createRef();

    const projectId = props.projectId;

    let status = sessionStorage.getItem(`lead_view_status_${projectId}`);
    let assigned = sessionStorage.getItem(`lead_view_assigned_${projectId}`);
    let origin = sessionStorage.getItem(`lead_view_origin_${projectId}`);

    if (status === "null" || status === null) {
      status = undefined;
    } else {
      status = status
        .split(",")
        .map((a) => (a === "unassigned" ? a : parseInt(a)));
    }

    if (origin === "null" || origin === null) {
      origin = undefined;
    } else {
      origin = origin.split(",");
    }

    if (assigned === "null" || assigned === null) {
      assigned = undefined;
    } else {
      assigned = assigned
        .split(",")
        .map((a) => (a === "unassigned" ? a : parseInt(a)));
    }

    this.state = {
      filterToggled: false,
      checkedItems: status ? status : [],
      checkedOrigins: origin ? origin : [],
      checkedAssigned: assigned ? assigned : [],
      items: [],
      openFilterGroupIndex: null,
      filterGroupHeight: 0,
      projectChanged: false, // New state to track changes in project data
    };

    this.handleChange = this.handleChange.bind(this);
    this.handleOriginChange = this.handleOriginChange.bind(this);
    this.handleAssignedChange = this.handleAssignedChange.bind(this);
  }

  // componentDidUpdate = () => {
  //   console.log('state: ', this.state.checkedItems);
  //   console.log('props: ', this.props.selectedStatus);
  //   if (
  //     this.state.checkedItems !== this.props.selectedStatus &&
  //     this.state.checkedItems === []
  //   ) {
  //     this.setState({ checkedItems: this.props.selectedStatus });
  //   }
  // };


componentDidUpdate(prevProps) {
    if (prevProps.project?.data?.id !== this.props.project?.data?.id) {
      // Call the handleUnSelectAllFilter function when the project prop changes
      this.handleUnSelectAllFilter();
    }
  }

  handleFilterToggle() {
    this.setState({ filterToggled: !this.state.filterToggled });
  }

  handleOpenFilter() {
    this.setState({ filterToggled: true });
  }

  handleCloseFilter() {
    this.setState({ filterToggled: false });
  }

  handleDropdown = (value) => {
    if (!this.filterRef.current.contains(value.target)) {
      this.setState({ filterToggled: false });
    }
  };

  // handleChange(e) {
  //     const {value, checked} = e.target;
  //     let checkedItems = [...this.state.checkedItems, parseInt(value)];
  //     if (!checked) {
  //         checkedItems = checkedItems.filter(x => x !== parseInt(value));
  //     }

  //     this.setState({checkedItems: checkedItems});
  // }
  handleChange(e, index) {
    const { value, checked } = e.target;
    let checkedItems = [...this.state.checkedItems];
    if (checked) {
      checkedItems.push(parseInt(value));
    } else {
      checkedItems = checkedItems.filter((x) => x !== parseInt(value));
    }

    this.setState({ checkedItems: checkedItems, openFilterGroupIndex: index });
  }

  handleAssignedChange(e) {
    const { value, checked } = e.target;
    let parsed = value;

    if (parsed !== "unassigned" || !isNaN(parsed)) {
      parsed = parseInt(parsed);
    }

    let checkedAssigned = [...this.state.checkedAssigned, parsed];
    if (!checked) {
      checkedAssigned = checkedAssigned.filter((x) => x !== parsed);
    }

    this.setState({ checkedAssigned: checkedAssigned });
  }

  handleOriginChange(e) {
    const { value, checked } = e.target;
    let checkedOrigins = [...this.state.checkedOrigins, value];
    if (!checked) {
      checkedOrigins = checkedOrigins.filter((x) => x !== value);
    }
    this.setState({ checkedOrigins: checkedOrigins });
  }

  handleApplyFilter = () => {
    const { checkedItems, checkedOrigins, checkedAssigned } = this.state;
    this.props.onApplyFilter(checkedItems, checkedOrigins, checkedAssigned);
    this.setState({ filterToggled: false });
  };

  handleSelectAllFilter = (items) => {
    let { checkedItems } = this.state;
    items.forEach((i) => {
      if (!checkedItems.includes(i.id)) {
        checkedItems.push(i.id);
      }
    });
    this.setState({ checkedItems: checkedItems });
  };

  handleUnSelectAllFilter = () => {
    this.setState({
      checkedItems: [],
      checkedOrigins: [],
      checkedAssigned: [],
    });

    this.props.onApplyFilter([], [], []);
    this.setState({ filterToggled: false });
  };

  componentDidMount() {
    document.addEventListener("click", this.handleDropdown, true);
  }

  componentWillUnmount() {
    document.removeEventListener("click", this.handleDropdown, true);
  }

  handleToggleFilterGroup = (index) => {
    this.setState((prevState) => ({
      openFilterGroupIndex:
        prevState.openFilterGroupIndex === index ? null : index,
    }));
  };


  render() {
    const {
      page,
      summaryLoaded,
      projectLoaded,
      project,
      originLoaded,
      origin,
    } = this.props;

    let {
      checkedItems,
      checkedOrigins,
      checkedAssigned,
      openFilterGroupIndex,
    } = this.state;


    let members = [];
    let owner = [];

    if (projectLoaded) {
      members = project.data.members;
      owner = [project.data.owner];
    }

    let origins = [];
    if (originLoaded) {
      origins = origin;
    }

    let data = [];
    let categories = [];
    let total = 0;
    let applyClass = "show";
    let selectClass = "hide";
    let items = [];

    if (summaryLoaded) {
      data = page.data;
      items = data.reduce(function (collection, elem) {
        let key = elem["category"];
        if (!categories.includes(key) && key !== "Custom") {
          categories.push(key);
        }

        total = total + elem["count"];
        (collection[key] = collection[key] || []).push(elem);
        return collection;
      }, []);

      applyClass =
        checkedItems.length > 0 ||
        checkedOrigins.length > 0 ||
        checkedAssigned.length > 0
          ? "show"
          : "hide";

      selectClass = checkedItems.length === data.length ? "hide" : "show";
    }

    return (
      <>
        <div className="filter-box leads-filter" ref={this.filterRef}>
          <div className="filter-btn" onClick={() => this.handleFilterToggle()}>
            <i className="icon-lms icon-filter"></i>
            Filters
            <span>
              {checkedItems.filter(item => typeof item !== 'number' || !isNaN(item)).length +
                checkedOrigins.filter(item => typeof item !== 'number' || !isNaN(item)).length +
                checkedAssigned.filter(item => typeof item !== 'number' || !isNaN(item)).length}
            </span>
          </div>
          <div
            className={
              this.state.filterToggled
                ? "filter-dropdown active"
                : "filter-dropdown"
            }
          >
            <div className="filter-title">
              <p>Filters</p>
              <i
                className="icon-lms icon-close"
                onClick={() => this.handleCloseFilter()}
              ></i>
            </div>

            <div className="filter-list">
              <div
                className={
                  openFilterGroupIndex === 0
                    ? "filter-group active"
                    : "filter-group"
                }
              >
                <div
                  className="filter-name"
                  onClick={() => this.handleToggleFilterGroup(0)}
                >
                  <span>Point of Conversion ({origins.length})</span>
                  <i className="icon-lms icon-arrow-down"></i>
                  <span
                    className={
                      checkedOrigins.length > 0 ? "count active" : "count"
                    }
                  >
                    {checkedOrigins.length}
                  </span>
                </div>
                <div
                  className="filter-check-list"
                  style={
                    openFilterGroupIndex === 0
                      ? { maxHeight: this.filterGroupHeight }
                      : { maxHeight: "0" }
                  }
                >
                  {origins.map((ori, index) => {
                    if (ori !== null) {
                      return (
                        <div key={index} className="check-col">
                          <div
                            key={`filter-dropdown-${index}`}
                            className="checkbox-group"
                          >
                            <input
                              type="checkbox"
                              checked={checkedOrigins.includes(ori)}
                              id={`origin-${index}`}
                              value={ori}
                              name={`origin-${index}`}
                              onChange={this.handleOriginChange}
                            />
                            <label htmlFor={`origin-${index}`}>{ori}</label>
                          </div>
                        </div>
                      );
                    }
                  })}
                </div>
              </div>

              {/* <div
                className={
                  openFilterGroupIndex === 1
                    ? "filter-group active"
                    : "filter-group"
                }
              >
                <div
                  className="filter-name"
                  onClick={() => this.handleToggleFilterGroup(1)}
                >
                  <span>Admin ({owner.length})</span>
                  <i className="icon-lms icon-arrow-down"></i>
                  <span
                    className={
                      checkedAssigned.some((item) =>
                        owner.some((x) => x.id === item)
                      )
                        ? "count active"
                        : "count"
                    }
                  >
                    {
                      checkedAssigned.filter((item) =>
                        owner.some((x) => x.id === item)
                      ).length
                    }
                  </span>
                </div>
                <div
                  className="filter-check-list"
                  style={
                    openFilterGroupIndex === 1
                      ? { maxHeight: this.filterGroupHeight }
                      : { maxHeight: "0" }
                  }
                >
                  {owner.map((owner, index) => {
                    let id = owner.id;

                    let fname = utilitiesService.decrypt(
                      owner.account.first_name
                    );
                    let lname = utilitiesService.decrypt(
                      owner.account.last_name
                    );

                    return (
                      <div key={index} className="check-col">
                        <div
                          key={`filter-dropdown-${index}`}
                          className="checkbox-group"
                        >
                          <input
                            type="checkbox"
                            checked={checkedAssigned.includes(id)}
                            id={`member-${id}`}
                            value={id}
                            name={`assigned`}
                            onChange={this.handleAssignedChange}
                          />
                          <label htmlFor={`member-${id}`}>
                            {fname} {lname}
                          </label>
                        </div>
                      </div>
                    );
                  })}
                </div>
              </div> */}

              <div
                className={
                  openFilterGroupIndex === 2
                    ? "filter-group active"
                    : "filter-group"
                }
              >
                <div
                  className="filter-name"
                  onClick={() => this.handleToggleFilterGroup(2)}
                >
                  <span className={`filter-name`}>
                    Lead Managers ({members.length})
                  </span>
                  <i className="icon-lms icon-arrow-down"></i>
                  <span
                    className={
                      checkedAssigned.some((item) =>
                        members.some((x) => parseInt(x.account.id) === item)
                      )
                        ? "count active"
                        : "count"
                    }
                  >
                    {
                      checkedAssigned.filter((item) =>
                        members.some((x) => parseInt(x.account.id) === item)
                      ).length
                    }
                  </span>
                </div>
                <div
                  className="filter-check-list"
                  style={
                    openFilterGroupIndex === 2
                      ? { maxHeight: this.filterGroupHeight }
                      : { maxHeight: "0" }
                  }
                >
                  {members.map((member, index) => {
                    let id = parseInt(member.account.id);

                    let fname = utilitiesService.decrypt(
                      member.account.first_name
                    );
                    let lname = utilitiesService.decrypt(
                      member.account.last_name
                    );
                    return (
                      <div key={index} className="check-col">
                        <div
                          key={`filter-dropdown-${index}`}
                          className="checkbox-group"
                        >
                          <input
                            type="checkbox"
                            checked={checkedAssigned.includes(id)}
                            id={`member-${id}`}
                            value={id}
                            name={`member-${id}`}
                            onChange={this.handleAssignedChange}
                          />
                          <label htmlFor={`member-${id}`}>
                            {fname} {lname}
                          </label>
                        </div>
                      </div>
                    );
                  })}
                </div>
              </div>

              {categories.map((value, index) => {
                let count = index + 3;
                return (
                  <div
                    className={
                      openFilterGroupIndex === count &&
                      items[value].some((item) =>
                        checkedItems.includes(item.id)
                      )
                        ? "filter-group active"
                        : "filter-group"
                    }
                    key={index}
                  >
                    <div
                      className="filter-name"
                      onClick={() => this.handleToggleFilterGroup(count)}
                    >
                      <span className={`filter-name`}>
                        {value} ({items[value].length})
                      </span>
                      <i className="icon-lms icon-arrow-down"></i>
                      <span
                        className={
                          checkedItems.length > 0 &&
                          checkedItems.some((item) =>
                            items[value].some((x) => x.id === item)
                          )
                            ? "count active"
                            : "count"
                        }
                      >
                        {
                          checkedItems.filter((item) =>
                            items[value].some((x) => x.id === item)
                          ).length
                        }
                      </span>
                    </div>
                    <div
                      className="filter-check-list"
                      style={
                        openFilterGroupIndex === count
                          ? { maxHeight: this.filterGroupHeight }
                          : { maxHeight: "0" }
                      }
                    >
                      {items[value].map((item, index) => {
                        let id = item.id;
                        return (
                          <div
                            className="check-col"
                            key={`filter-dropdown-${index}`}
                          >
                            <div className="checkbox-group">
                              <input
                                type="checkbox"
                                checked={checkedItems.includes(id)}
                                id={`status-${id}`}
                                value={item.id}
                                name={item.label}
                                onChange={(e) => this.handleChange(e, count)}
                              />
                              <label htmlFor={`status-${id}`}>
                                {item.label}
                              </label>
                            </div>
                          </div>
                        );
                      })}
                    </div>
                  </div>
                );
              })}
            </div>
            <div className="filter-control-box">
              <span
                className={`btn-reset`}
                onClick={() => this.handleUnSelectAllFilter()}
              >
                Clear Filter
              </span>
              <span
                className={`btn-apply`}
                onClick={() => this.handleApplyFilter()}
              >
                Apply Filter
              </span>
            </div>
          </div>
        </div>
      </>
    );
  }
}

function mapState(state) {
  const { projectLoaded, loaded, project } = state.projects;
  const { page, origin, originLoaded, summaryLoaded } = state.summary;
  return {
    page,
    summaryLoaded,
    projectLoaded,
    loaded,
    project,
    originLoaded,
    origin,
  };
}

const actionCreators = {
  getLeadSummary: leadActions.getLeadSummary,
  getOrigin: leadActions.getOrigin,
};

const connectedViewFilterDropdown = connect(
  mapState,
  actionCreators
)(FilterDropdown);
export { connectedViewFilterDropdown as FilterDropdown };
