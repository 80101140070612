import React, { useState, useRef, useEffect } from "react";

import { EditorContent, useEditor } from "@tiptap/react";
import StarterKit from "@tiptap/starter-kit";

import { useDispatch } from "react-redux";
import { utilitiesService } from "_services";
import { profileActions, templateActions } from "_actions";
import { useSelector } from "react-redux";
import DropDownLists from "_pages/Influencer/constants/DropDownLists";
import { useParams } from "react-router-dom";

const CreateEmailTemplateDrawer = ({
  isCreateTemplateDrawer,
  closeCreateTemplateDrawer,
  template,
  toggleDuplicateModal,
  toggleShareModal,
  toggleDeleteModal,
}) => {
  const [toggleTabState, setToggleTabState] = useState(1);
  const [editorContent, setEditorContent] = useState("");
  const [error, setError] = useState(null);
  const [category, setCategory] = useState("");
  const [title, setTitle] = useState("");
  const [message, setMessage] = useState("");
  const [templateId, setTemplateId] = useState(0);
  const [isDeckDelete, setIsDeckDelete] = useState(false);
  const [isCatOthers, setIsCatOthers] = useState(false);
  const [catOthers, setCatOthers] = useState("");
  const services = DropDownLists.services;

  const [openDropdown, setOpenDropdown] = useState(null);

  const editor = useEditor({
    extensions: [StarterKit],
    autofocus: true,
    content: editorContent,
    onUpdate({ editor }) {
      setEditorContent(editor.getHTML());
      setError(null);
    },
  });

  const dispatch = useDispatch();
  const param = useParams();

  const handleOpenDropdown = (dropdownName) => {
    setOpenDropdown((prevOpenDropdown) =>
      prevOpenDropdown === dropdownName ? null : dropdownName
    );
  };

  const handleSelectCategory = (cat) => {
    setCategory(cat);
    setIsCatOthers(false);
  };

  const handleTemplateTitle = (value) => {
    setTitle(value);
    setError(null);
  };

  const handleTemplateCatOthers = (value) => {
    setCatOthers(value);
    setError(null);
  };

  const handleOthersCategory = () => {
    setIsCatOthers(true);
    setCategory('Others');
  };

  useEffect(() => {
    setError(null);
    setIsCatOthers(false);
    setCategory("");
    setOpenDropdown(null)

    if (isCreateTemplateDrawer && template == undefined) {
      editor.commands.setContent("");
      setTemplateId(0);
      setTitle("");
    }

    if (isCreateTemplateDrawer && template !== undefined) {
      editor.commands.setContent("");
      setTitle(utilitiesService.decrypt(template.title));
      setCategory(utilitiesService.decrypt(template.category));
      editor.commands.setContent(utilitiesService.decrypt(template.template));
      if (template.id != 0) {
        setTemplateId(template.id);
      }
    }
  }, [template, isCreateTemplateDrawer]);

 // console.log('template id: ', templateId);

  const handleSaveEmailTemplate = () => {
    const currentEditorContent = editor.getHTML().replace(/<p><\/p>|\s/g, ""); // Get the current content from the editor
   // console.log("editor content: ", currentEditorContent);

   // console.log("title: ", title);

    let hasError = false;

    if (
      !title.trim() ||
      !currentEditorContent ||
      currentEditorContent === "<p></p>"
    ) {
      setError("Title and editor content cannot be empty.");
      hasError = true;
    }

   // console.log("cate: ", category);
   // console.log("iscate: ", isCatOthers);
   // console.log("cateother: ", catOthers);

    if (!isCatOthers && !category && !catOthers) {
      setError("No selected category");
      hasError = true;
    }

  

    if((isCatOthers && !catOthers.trim())) {
      setError("Category is empty");
      hasError = true;
    }

   // console.log("has error: ", hasError);

    if (!hasError) {
      const request = {
        title: utilitiesService.encrypt(title),
        template: utilitiesService.encrypt(editorContent),
        category: isCatOthers ? utilitiesService.encrypt(catOthers) : utilitiesService.encrypt(category),
      };
      dispatch(templateActions.createTemplate(5, request));
      setError(null);
      return closeCreateTemplateDrawer();
    }
  };

  const handleUpdateEmailTemplate = () => {
    const currentEditorContent = editor.getHTML().replace(/<p><\/p>|\s/g, ""); // Get the current content from the editor
   // console.log("editor content: ", currentEditorContent);

   // console.log("title: ", title);

    let hasError = false;

    if (
      !title.trim() ||
      !currentEditorContent ||
      currentEditorContent === "<p></p>"
    ) {
      setError("Title and editor content cannot be empty.");
      hasError = true;
    }

    if(isCatOthers && catOthers === "") {
      setError("Category Others is empty");
      hasError = true;
    }

   // console.log("error: ", hasError);

    if (templateId != 0 && !hasError) {
      const request = {
        id: templateId,
        category: isCatOthers ? utilitiesService.encrypt(catOthers) : utilitiesService.encrypt(category),
        title: utilitiesService.encrypt(title),
        template: utilitiesService.encrypt(editorContent),
      };
     // console.log("request", request);
      dispatch(templateActions.updateTemplate(5, templateId, request));
      setError(null);
      return closeCreateTemplateDrawer();
    }
  };

  let activeClass = isCreateTemplateDrawer ? "active" : "";

  return (
    <>
      <div
        className={`drawer-overlay ${activeClass}`}
        onClick={closeCreateTemplateDrawer}
      ></div>
      <div className={`side-drawer-main email-template-drawer ${activeClass}`}>
        <div className="side-drawer-wrapper">
          <div className="side-drawer-title">
            <div>
              {templateId == 0 && <h3>Create Email Template</h3>}
              {templateId != 0 && (
                <>
                  <h3>{title}</h3>
                  <span className="et-label">Own Template</span>
                </>
              )}
            </div>
            <span
              className="side-drawer-close"
              onClick={closeCreateTemplateDrawer}
            >
              <i className="icon-lms icon-close"></i>
            </span>
          </div>
          {templateId != 0 && <div className="side-drawer-controls">
            <span onClick={() => toggleDuplicateModal(template)}>
              <i className="icon-lms icon-copy"></i>
              Duplicate
            </span>
            <span onClick={() => toggleShareModal(template)}>
              <i className="icon-lms icon-share"></i>
              {template && template.private ? 'Share' : 'Unshare'}
            </span>
            <span onClick={() => toggleDeleteModal(template)}>
              <i className="icon-lms icon-delete"></i>
              Delete
            </span>
          </div>}
          <div className="side-drawer-tabs">
            <div className="side-drawer-tabs-wrapper">
              {
                <div className="content-tabs-box">
                  <div className={`create-email-template`}>
                    <div className="email-subject">
                      <span>Email Template Title:</span>
                      <input
                        value={title}
                        onChange={(e) => handleTemplateTitle(e.target.value)}
                        type={`text`}
                        placeholder=""
                      />
                    </div>
                    <div className="email-other-details">
                      <div className="email-category">
                        <span className="label">Email Category:</span>
                        <div
                          className={
                            openDropdown === "category"
                              ? "et-dropdown active"
                              : "et-dropdown"
                          }
                          onClick={() => handleOpenDropdown("category")}
                        >
                          <span className="title">
                            {!category
                              ? "Select One Category"
                              : category}
                            <i className="icon-lms icon-arrow-down"></i>
                          </span>
                          <div className="list">
                            {services.map((option) => (
                              <div
                                className="checkbox-group"
                                key={option}
                                onClick={() => handleSelectCategory(option)}
                              >
                                <span>{option}</span>
                              </div>
                            ))}
                            <div
                              className="checkbox-group"
                              onClick={() => handleOthersCategory()}
                            >
                              <span>Others</span>
                            </div>
                          </div>
                        </div>
                      </div>
                      {isCatOthers && (
                        <div className="email-others">
                          <span className="label">Category Name:</span>
                          <input
                            type="text"
                            onChange={(e) => handleTemplateCatOthers(e.target.value)}
                            placeholder=""
                          />
                        </div>
                      )}
                      {/* <div className="email-service">
                            <span className="label">Email Services:</span>
                            <div className={openDropdown === "service" ? "et-dropdown active" : "et-dropdown"} onClick={() => handleOpenDropdown("service")}>
                                <span className="title">Select one service <i className="icon-lms icon-arrow-down"></i></span>
                                <div className="list">
                                    <span>Service 1</span>
                                    <span>Service 2</span>
                                    <span>Service 3</span>
                                </div>
                            </div>
                        </div> */}
                    </div>
                    <div className="email-text">
                      <div className="custom-basic-editor email-editor">
                        <MenuBar editor={editor} />
                        <EditorContent
                          editor={editor}
                          className="thread-editor-field"
                        />
                      </div>
                    </div>
                  </div>
                </div>
              }
              <div className="tab-form-btn-group">
                <div className="row">
                  <div className="r-error">
                    {error && <p className="error-field">{error}</p>}
                  </div>
                  <div className="col text-right">
                    <span
                      className="drawer-btn cancel-btn"
                      onClick={closeCreateTemplateDrawer}
                    >
                      Cancel
                    </span>
                    {templateId == 0 && (
                      <span
                        className="drawer-btn save-btn"
                        onClick={handleSaveEmailTemplate}
                      >
                        Create Template
                      </span>
                    )}
                    {templateId != 0 && (
                      <span
                        className="drawer-btn save-btn"
                        onClick={handleUpdateEmailTemplate}
                      >
                        Update Template
                      </span>
                    )}
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

const MenuBar = ({ editor }) => {
  if (!editor) {
    return null;
  }

  return (
    <>
      <div className="thread-editor-controls">
        <button
          onClick={() => editor.chain().focus().setParagraph().run()}
          className={editor.isActive("paragraph") ? "is-active" : ""}
        >
          <i className="fas fa-paragraph"></i>
        </button>
        <button
          onClick={() =>
            editor.chain().focus().toggleHeading({ level: 4 }).run()
          }
          className={
            editor.isActive("heading", { level: 4 }) ? "is-active" : ""
          }
        >
          <i className="fas fa-heading"></i>
        </button>
        <button
          onClick={() => editor.chain().focus().toggleBold().run()}
          disabled={!editor.can().chain().focus().toggleBold().run()}
          className={editor.isActive("bold") ? "is-active" : ""}
        >
          <i className="fas fa-bold"></i>
        </button>
        <button
          onClick={() => editor.chain().focus().toggleItalic().run()}
          disabled={!editor.can().chain().focus().toggleItalic().run()}
          className={editor.isActive("italic") ? "is-active" : ""}
        >
          <i className="fas fa-italic"></i>
        </button>
        <button
          onClick={() => editor.chain().focus().toggleStrike().run()}
          disabled={!editor.can().chain().focus().toggleStrike().run()}
          className={editor.isActive("strike") ? "is-active" : ""}
        >
          <i className="fas fa-strikethrough"></i>
        </button>
        <button
          onClick={() => editor.chain().focus().toggleBulletList().run()}
          className={editor.isActive("bulletList") ? "is-active" : ""}
        >
          <i className="fas fa-list-ul"></i>
        </button>
        <button
          onClick={() => editor.chain().focus().toggleOrderedList().run()}
          className={editor.isActive("orderedList") ? "is-active" : ""}
        >
          <i className="fas fa-list-ol"></i>
        </button>
        <button
          onClick={() => editor.chain().focus().undo().run()}
          disabled={!editor.can().chain().focus().undo().run()}
        >
          <i className="fas fa-undo"></i>
        </button>
        <button
          onClick={() => editor.chain().focus().redo().run()}
          disabled={!editor.can().chain().focus().redo().run()}
        >
          <i className="fas fa-redo"></i>
        </button>
      </div>
    </>
  );
};

export default CreateEmailTemplateDrawer;
