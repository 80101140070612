import React, {useEffect, useState, useRef} from 'react'
import {managerActions} from "_actions/manager.actions";
import {useDispatch, useSelector} from "react-redux";
import {useParams} from "react-router-dom";
import ParameterDrawerLoader from '../../../../_components/Loader/ParameterDrawerLoader';

const ParameterDrawer = ({
                             autoConfigId,
                             activeParams,
                             isParameters,
                             onHandleCloseParameters,
                             onHandleParameterAdd,
                         }) => {



    const dispatch = useDispatch();
    const params = useParams();
    const [list,setList] = useState([]);
    const [search,setSearch] = useState('');
    

    useEffect(() => {
        if (autoConfigId !== null) {
            dispatch(managerActions.getProjectConfigParam(params.id, autoConfigId));
        }
    }, [autoConfigId]);


    const parametersLoaded = useSelector((state) => state.managers.getProjectConfigParamsLoaded);

    const parameters = useSelector((state) => {
        const {managers} = state;

        if (managers.getProjectConfigParamsLoaded) {
            return managers.parameters;
        }
        return [];
    });

    useEffect(()=> {
        if(parameters.length > 0) {
            setList(parameters);
        }
    }, [parameters]);

    const handleParameterAdd = (param) => {

        const parameter = {
            value: "",
            operator: "EQUAL",
            params: param.id,
            params_source: param.type,
            label: param.field
        }

        let selected = [...activeParams, parameter];
        onHandleParameterAdd(selected);
    }

    let activeClass = isParameters ? 'active' : '';

    function handleFilter(value) {
        const filtered = parameters.filter((a) => a.field.toLowerCase().includes(value.toLowerCase()))
       setList(filtered);
       setSearch(value);
    }

    function handleClearSearch() {
        setList(parameters);
        setSearch('');
    }

    return (
        <div className={`parameter-drawer ${activeClass}`}>
            <div className='parameter-wrapper'>
                <div className='p-drawer-title'>
                    <p>PARAMETERS</p>
                    <span onClick={onHandleCloseParameters}><i className="icon-lms icon-close"></i></span>
                </div>
                <div className='p-drawer-list'>
                    {!parametersLoaded && <ParameterDrawerLoader />}
                    {parametersLoaded && <React.Fragment>
                        <div className='p-search-box'>
                            <input type="text" placeholder='Search' value={search} onChange={(e) => handleFilter(e.target.value)} />
                            <i className="icon-lms icon-search"></i>
                            {
                                search.length > 0 &&
                                <i className="icon-lms icon-close close-btn" onClick={() => handleClearSearch()}></i>
                            }
                        </div>
                        <div className='p-input-field'>
                            {list.map((param, index) => {
                                return (<button key={index} onClick={() => handleParameterAdd(param)}>
                                    {param.field}
                                    <i className={'icon-lms icon-plus'}></i>
                                </button>);
                            })}
                        </div>
                    </React.Fragment>}
                </div>
            </div>
        </div>
    )
}

export default ParameterDrawer