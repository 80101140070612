import React, {Component} from 'react'
import {FilterDropdown} from './FilterDropdown'
import {leadActions} from "_actions";
import {connect} from "react-redux";
import {DateRangePicker} from "rsuite";
import {ranges} from '../../constants/DateRangeConfig'
import Moment from "react-moment";


class KpiControl extends Component {

    constructor(props) {
        super(props);
        const projectId = props.projectId;

        let page = sessionStorage.getItem(`lead_view_page_${projectId}`);
        let size = sessionStorage.getItem(`lead_view_size_${projectId}`);
        let query = sessionStorage.getItem(`lead_view_query_${projectId}`);
        let dates = sessionStorage.getItem(`lead_view_dates_${projectId}`);
        let sort = sessionStorage.getItem(`lead_view_sortField_${projectId}`);
        let direction = sessionStorage.getItem(`lead_view_sortDirection_${projectId}`);

        this.searchRef = React.createRef();
        this.searchInputRef = React.createRef();
        this.gotoInputRef = React.createRef();


        if(dates === 'null' || dates === null) {
            dates = null;
        } else {
            dates = dates.split(',').map(d => new Date(d));
        }


        this.state = {
            searchToggled: query ? true : false,
            currentPage: page ? page : 1,
            currentSize: size ? size : 15,
            currentQuery: query ? query : '',
            currentDate: dates ? dates : [],
            toggleKpi: true,
        }
    }

    handleToggleKpi () {
        this.setState({toggleKpi: !this.state.toggleKpi});
        this.props.onHandleKpiHeaderHeightChanged(!this.state.toggleKpi);
    }

    handleOpenSearch () {
        this.setState({searchToggled: true})
    }

    handleSearch() {
        if(this.searchInputRef.current != null && this.searchInputRef.current.value !== '') {
            this.props.onHandleSearch(this.searchInputRef.current.value.toLowerCase());
        }
    }

    handleClearSearch() {
        // if(this.searchInputRef.current.value !== '') {
        //     this.searchInputRef.current.value = '';
        //     this.props.onHandleSearch(null);
        // }
        this.searchInputRef.current.value = '';
        this.props.onHandleSearch(null);

        this.setState({searchToggled: false})
    }

    handleDateRangeChange = value => {
        this.setState({ currentDate: value }); // Update the state with the new date range
        this.props.onHandleDateChanged(value);
    }

    handleApplyFilter = (status,  origin, assigned) => {
        const projectId = this.props.projectId;
        console.log('filter status: ',  status);
        console.log('filter origin: ',  origin);
        console.log('filter assigned: ',  assigned);
        sessionStorage.setItem(`lead_view_page_${projectId}`, 1);
        this.props.onHandleStatusChanged(status, origin, assigned);
    }

    handlePageChanged = (value, className) => {
        if(className === 'active') {
            this.setState({currentPage: value});
            this.props.onHandlePageChanged(value);
        }
    }

    handleSizeChanged = e => {
        let size = parseInt(e.options[e.selectedIndex].value);
        this.props.onHandleSizeChanged(size);
    }

    handleKeyPress = e => {
        if (e.key === 'Enter') {
            this.handleSearch();
        }

        if (e.key === "Escape") {
            this.handleClearSearch();
        }
    }

    componentDidMount() {
        document.addEventListener('keypress', this.handleKeyPress, true);
    }

    componentDidUnMount() {
        document.removeEventListener('click', this.handleKeyPress, true);
    }

    componentDidUpdate(prevProps) {
        if (prevProps.projectId !== this.props.projectId) {
          // Reset date range and search when the projectId changes
          this.handleDateRangeChange([]);
          this.handleClearSearch();
        }
      }

    render() {
        const {number, first, last, size, totalElements, selectedStatus, dates, selectedCategory, selectedColor, projectId } = this.props;
        const {currentPage, currentSize, currentQuery, currentDate} = this.state;

        let page = number + 1;
        let min = (page * size) - (size - 1);
        let max = page * size;
        let count = totalElements;
        let prevClass =  first ? '' : 'active';
        let nextClass =  last ? '' : 'active';
        let nextPage = page + 1;
        let previousPage = page- 1;
        let defaultPage = parseInt(currentPage);
        let activeSize = size ? size : currentSize;
        let countText = 'Loading Leads ...';
        if(page) {
            let limit = max;
            if(count < max){
                limit = count;
            }
            countText = `${min}-${limit} of ${count}`;
        }

        return (
            <div className='overview-control-box'>
                <div className='col-left'>
                    <div className='button-group'>
                        <FilterDropdown
                            projectId={projectId}
                            selectedStatus={selectedStatus}
                            onApplyFilter={this.handleApplyFilter}
                        />

                        <div className='date-range-picker-box'>
                            <DateRangePicker
                                ranges={ranges}
                                appearance="subtle"
                                format="MMM-dd-yyyy"
                                size="lg"
                                placeholder="All Time"
                                placement="autoVerticalStart"
                                cleanable={true}
                                value={currentDate} // Use the state value as the value for the DateRangePicker
                                onChange={this.handleDateRangeChange}
                            />
                        </div>

                        <div className={this.state.searchToggled ? 'search-box active' : 'search-box'}  ref={this.searchRef}>
                            <div className='input-search'>
                                <i className="icon-lms icon-search"></i>
                                <input  key={currentQuery} defaultValue={currentQuery}  type='text' placeholder='Search Lead' ref={this.searchInputRef} onClick={() => this.handleOpenSearch()}/>
                                <div className='controls'>
                                <i className="icon-lms icon-close" onClick={() => this.handleClearSearch()}></i>
                                <i className="icon-lms icon-arrow-right" onClick={() => this.handleSearch()}></i>
                                </div>
                            </div>
                        </div>

                    </div>
                </div>
                <div className='col-right'>
                    <div className='button-toggle'>
                        <p>Data Overview</p>
                        <div className={this.state.toggleKpi ? "switch-tggle active" : "switch-tggle"} onClick={() => this.handleToggleKpi()}>
                            <span className="slider">
                            </span>
                            <span className='tggle-txt'></span>
                        </div>
                    </div>
                </div>
            </div>
        )
    }
}

function mapState(state) {
    const {leadsLoaded} = state.leads;
    if(leadsLoaded) {
        const {number, first, last, size, totalElements, totalPages } = state.leads.leads.data;
        return {number, first, last, size, totalElements, totalPages , leadsLoaded};
    }

    return {leadsLoaded};
}

const actionCreators = {
    getLeads: leadActions.getLeadsPaginated,
};

const connectedViewKpiControl = connect(mapState, actionCreators)(KpiControl);
export {connectedViewKpiControl as KpiControl};
