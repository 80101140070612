import React, { useState } from "react";
import DestinationInfo from "./DestinationInfo";

export let TableHeader = [
  {
    Header: "DESTINATION URL/S",
    className: "destination-box",
    // sticky: 'left',
    minWidth: 400,
    width: 400,
    Cell: ({ row }) => {
      let destinationInfo = row.original; 
      return (
        <DestinationInfo destinationInfo={destinationInfo} />
      );
    },
  },
  {
    Header: 'GROUP',
    className: 'normal-item',
    minWidth: 100,
    width: 100,
    Cell: ({ row }) => {

      return(
        <span >{row.original.group ? row.original.group.name :  'ungrouped'}</span>
      );
    }
  },
  {
    Header: 'STATUS',
    className: 'normal-item status-box',
    minWidth: 100,
    width: 100,
    Cell: ({ row }) => {
      "status-inner status-color-1"
      return(
        <div className={row.original.status === "used" ? "status-inner status-color-1" :  "status-inner status-color-2"}>
            <span className={`status-text`}>
              {row.original.status}
            </span>
      </div>
      );
    }
  },
  {
    Header: 'LABEL',
    className: 'normal-item',
    minWidth: 200,
    width: 200,
    Cell: ({ row }) => {
      let raw = row.original.label
      let labels = raw ? raw.split(',') : [] ;
      return(
        <div className="labels-box">
          {
            labels.map((l, index) => {
              return(
                <span key={index}>{l}</span>
              );
            })
          }
        </div>
      );
    }
  },
  {
    Header: 'CAMPAIGN',
    className: 'normal-item',
    minWidth: 200,
    width: 200,
    Cell: ({ row }) => {

      return(
        <span className="w-break">{row.original.campaign}</span>
      );
    }
  },
  {
    Header: 'MEDIUM',
    className: 'normal-item',
    minWidth: 120,
    width: 120,
    Cell: ({ row }) => {

      return(
        <span >{row.original.medium}</span>
      );
    }
  },
  {
    Header: 'SOURCE',
    className: 'normal-item',
    minWidth: 120,
    width: 120,
    Cell: ({ row }) => {

      return(
        <span >{row.original.source}</span>
      );
    }
  },
  {
    Header: 'CONTENT',
    className: 'normal-item',
    minWidth: 120,
    width: 120,
    Cell: ({ row }) => {

      return(
        <span >{row.original.content}</span>
      );
    }
  },
  {
    Header: 'TERM',
    className: 'normal-item',
    minWidth: 150,
    width: 150,
    Cell: ({ row }) => {

      return(
        <span className="w-break">{row.original.keyword}</span>
      );
    }
  },
  {
    Header: 'NOTES',
    className: 'normal-item',
    minWidth: 200,
    width: 200,
    Cell: ({ row }) => {
      return(
        <span >{row.original.notes}</span>
      );
    }
  },
];