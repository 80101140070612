import React from 'react';
import { Link } from 'react-router-dom';
import Button from '../Button';

const RecordsSide = ({ classN, recordsModalAdd, recordsModalEdit, confirmationDelete }) => {
  return (
    <div className={classN}>
        <div className='inner-title-box'>
            <p>
                Document Links
            </p>
            <Button 
                text='Add Records'
                icon='fa-plus'
                onClick={recordsModalAdd}
            />
        </div>
        <div className='link-box-list'>
            <div className='links'>
                <div className='link'>
                    <p>Link Description</p>
                    <Link>
                        https://drive.google.com/
                    </Link>
                    <button className='edit-record' onClick={recordsModalEdit}><i className="fas fa-pen"></i>Edit</button>
                    <button className='delete-record' onClick={confirmationDelete}><i className="fas fa-trash-alt"></i>Delete</button>
                </div>
            </div>
        </div>
    </div>
  )
}

export default RecordsSide