import React, { useEffect, useState } from "react";
import {
  MDBContainer,
  MDBRow,
  MDBCol,
  MDBModal,
  MDBModalHeader,
  MDBModalBody,
} from "mdbreact";
import { utilitiesService } from "_services";

const DuplicateEmailModal = ({
  isDuplicateModal,
  toggleDuplicateModal,
  template,
}) => {
  const [title, setTitle] = useState();
  const [dupTemplate, setDupTemplate] = useState(undefined);

  useEffect(() => {
    if (isDuplicateModal) {
      console.log("temp: ", template);
      setDupTemplate(template);
      setTitle(utilitiesService.decrypt(template.title));
    }
  }, [isDuplicateModal]);

  const handleUpdateTitle = (data) => {
    setTitle(data);
  };

  const handleChangeTitle = (isDuplicate) => {
    let currentTitle = utilitiesService.decrypt(dupTemplate.title);

    if (currentTitle === title && isDuplicate) {
      const newTitle = title + " (copy)";
      dupTemplate.title = utilitiesService.encrypt(newTitle);
    }

    if (currentTitle !== title && isDuplicate) {
      dupTemplate.title = utilitiesService.encrypt(title);
    }

    return toggleDuplicateModal(isDuplicate, dupTemplate);
  };

  return (
    <MDBContainer className="modal-container confirmation-modal-container modal-basic">
      <MDBRow>
        <MDBCol>
          <MDBModal
            className="confirmation-modal"
            isOpen={isDuplicateModal}
            centered
            tabIndex="-1"
          >
            <MDBModalBody>
              <MDBRow className="form-group-row">
                <MDBCol className="col-12">
                  <span className="icon-custom">
                    <i className="icon-lms icon-copy"></i>
                  </span>
                  <h3>
                    Duplicate
                    <br />
                    {dupTemplate != undefined
                      ? utilitiesService.decrypt(dupTemplate.title)
                      : ""}
                  </h3>
                  <p>
                    Duplicating an email template allows you to edit its content
                  </p>
                  <div className="form-group-box">
                    <div className="input-group-box full-box-">
                      <div className="field-box">
                        <label>Template Title</label>
                        <div>
                          <input
                            value={title}
                            onChange={(e) => handleUpdateTitle(e.target.value)}
                            type="text"
                          />
                        </div>
                      </div>
                    </div>
                  </div>
                </MDBCol>
              </MDBRow>
              <MDBRow>
                <MDBCol className="modal-btns text-center">
                  <button
                    className="c-btn c-btn-light"
                    onClick={() => handleChangeTitle(false)}
                  >
                    Cancel
                  </button>
                  <button
                    className="c-btn c-btn-blue"
                    onClick={() => handleChangeTitle(true)}
                  >
                    Duplicate
                  </button>
                </MDBCol>
              </MDBRow>
            </MDBModalBody>
          </MDBModal>
        </MDBCol>
      </MDBRow>
    </MDBContainer>
  );
};

export default DuplicateEmailModal;
