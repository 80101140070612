import {endOfDay, startOfDay, subDays} from "date-fns";

export const ranges = [
    {
        label: 'Today',
        value: [startOfDay(new Date()), endOfDay(new Date())]
    },
    {
        label: 'Last 7 Days',
        value: [startOfDay(subDays(new Date(), 6)), endOfDay(new Date())]
    },
    {
        label: 'Last 30 Days',
        value: [startOfDay(subDays(new Date(), 29)), endOfDay(new Date())]
    },
    {
        label: 'Last Year',
        value: [startOfDay(subDays(new Date(), 364)), endOfDay(new Date())]
    }
];