import React from 'react'
import { MDBContainer, MDBRow, MDBCol, MDBModal, MDBModalHeader, MDBModalBody, MDBBtn } from 'mdbreact'

const DeleteInfluencerModal = ({ open, toggle, close }) => {
  return (
    <MDBContainer className='modal-container delete-influencer-modal-container'>
        <MDBRow>
          <MDBCol>
            <MDBModal className='confirmation-modal' isOpen={open} toggle={toggle} centered tabIndex='-1'>
              <MDBModalHeader className='confirmation-modal-header text-uppercase p-2'><span className='pl-1 mx-2 letter-spacing-1 font-weight-600 font-color-gray font-size-13'>DELETE Confirmation</span></MDBModalHeader>
              <MDBModalBody>
                <MDBRow className='form-group-row'>
                  <MDBCol className='col-12'>
                    <p className='pl-1 font-size-13'>Are you sure you want to delete lead <span className='font-weight-bold'>George Paul Gonzales </span>?</p>
                  </MDBCol>
                </MDBRow>
                <MDBRow>
                  <MDBCol className='text-center'>
                    <button className='modal-btn delete-btn-modal'>
                        Delete Influencer
                    </button>
                    <button className='modal-btn cancel-btn-modal' onClick={close}>
                        Cancel
                    </button>
                  </MDBCol>
                </MDBRow>
              </MDBModalBody>
            </MDBModal>
          </MDBCol>
        </MDBRow>
      </MDBContainer>
  )
}

export default DeleteInfluencerModal