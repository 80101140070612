import React, { Component } from "react";
import { connect } from "react-redux";
import { history } from "_helpers";
import {Link} from 'react-router-dom';

import { ref, onValue } from "firebase/database";
import { db } from "firebase";

import { formActions, leadActions, projectActions } from "_actions";
import { utilitiesService as utility } from "_services";
import { MDBRow } from "mdbreact";
import { Button } from "blackbox-react";
import TopBar from "../../_components/TopBar";
import ProjectDetails from "../../_components/Drawer/ProjectDetails";
import ProjectMembers from "../../_components/Drawer/ProjectMembers";
import { KpiHeader } from "./components/Kpi/KpiHeader";
import { LeadViewList } from "./components/Table/LeadViewList";
import { KpiControl } from "./components/Kpi/KpiControl";
import AddLeadDrawer from "./components/Drawer/AddLeadDrawer";
import DefaultTableHeader from "_pages/Leads/components/Table/DefaultTableHeader";
import { TablePagination } from "_pages/Leads/components/Table/TablePagination";
import TableActions from "_pages/Leads/components/Table/TableActions";

import ManageDecksDrawer from "../Projects/components/members/ManageDecksDrawer";
import HandleDeckDrawer from "../Projects/components/members/HandleDeckDrawer";

import moment from "moment";
import ButtonGroupLoader from "../../_components/Loader/ButtonGroupLoader";
import TableMainLoader from "../../_components/Loader/TableMainLoader";
import BulkUploadDrawer from "./components/Drawer/BulkUploadDrawer";

class Leads extends Component {
  state = {
    exportData: false,
  };

  handleTD = (value) => {
    if (value.value !== null) {
      return utility.decrypt(value.value);
    }
  };

  constructor(props) {
    super(props);

    const { match } = this.props;
    const projectId = match.params.id;

    let page = sessionStorage.getItem(`lead_view_page_${projectId}`);
    let size = sessionStorage.getItem(`lead_view_size_${projectId}`);
    let query = sessionStorage.getItem(`lead_view_query_${projectId}`);
    let status = sessionStorage.getItem(`lead_view_status_${projectId}`);
    let dates = sessionStorage.getItem(`lead_view_dates_${projectId}`);
    let assigned = sessionStorage.getItem(`lead_view_assigned_${projectId}`);
    let origin = sessionStorage.getItem(`lead_view_origin_${projectId}`);
    let sort = sessionStorage.getItem(`lead_view_sortField_${projectId}`);
    let direction = sessionStorage.getItem(
      `lead_view_sortDirection_${projectId}`
    );

    if (dates === "null" || dates === null) {
      dates = null;
    } else {
      dates = dates.split(",");
    }

    if (status === "null" || status === null) {
      status = null;
    } else {
      status = status.split(",");
    }

    if (origin === "null" || origin === null) {
      origin = null;
    } else {
      origin = origin.split(",");
    }

    if (assigned === "null" || assigned === null) {
      assigned = null;
    } else {
      assigned = assigned.split(",");
    }

    this.state = {
      isTableActionBox: false,
      isManageDecksDrawer: false,
      isHandleDecksDrawer: false,
      isDeckDeleteConfirmationModal: false,
      isProjectDetailsDrawer: false,
      isProjectMembersDrawer: false,
      isKpiHeaderExpanded: true,
      isAddLeadDrawer: false,
      leadSyncStatus: "DONE",
      leadSyncData: {},
      selectedCategory: null,
      selectedColor: null,
      pageSize: size ? size : 15,
      currentPage: page ? page : 1,
      selectedStatus: status ? status : [],
      dates: dates ? dates : null,
      // sortField: sort ? sort : "updated",
      sortField: "updated",
      sortDirection: direction ? direction : "DESC",
      query: query ? query : null,
      assigned: assigned ? assigned : null,
      origin: origin ? origin : null,
      deck: {},
      selectedLeadIds: [],
      headerCheckboxChecked: false,
      isBulkUploadDrawer: false,
    };
  }

  componentDidMount() {
    const { match, getProject, getOrigin } = this.props;
    const projectId = match.params.id;

    if (parseInt(projectId) === 3) {
      const thamRef = ref(db, "THAM");
      onValue(thamRef, (snapshot) => {
        const data = snapshot.val();
        this.setState({
          leadSyncStatus: data.status,
          leadSyncData: data,
        });
      });
    }

     getProject(projectId);
     getOrigin(projectId);
     this.handleUpdateList();
  }

  componentDidUpdate(
    prevProps: Readonly<P>,
    prevState: Readonly<S>,
    snapshot: SS
  ) {
    const { match, getProject, getOrigin } = this.props;
    const oldId = prevProps.match.params.id;
    const newId = match.params.id;

    if (oldId !== newId) {
      getProject(newId);
      getOrigin(newId);
      this.handleReset();
    }

    if(prevState.headerCheckboxChecked && !this.state.headerCheckboxChecked && prevState.selectedLeadIds.length == 0) {
      this.setState({
        isTableActionBox: false
      });
    }
    // Check if there is a change in the page variable
    if (prevState.currentPage !== this.state.currentPage) {
      // Reset the header checkbox state to false, selectedLeadIds and isTableActionBox
      this.setState({
        headerCheckboxChecked: false,
        selectedLeadIds: [],
        isTableActionBox: false
      });
    }

    if (prevState.pageSize !== this.state.pageSize) {
      // Reset the header checkbox state to false, selectedLeadIds and isTableActionBox
      this.setState({
        headerCheckboxChecked: false,
        selectedLeadIds: [],
        isTableActionBox: false
      });
    }
  }

  handleGetLeads = () => {
    const { match, getProject, getLeads } = this.props;
    const projectId = match.params.id;
    getProject(projectId);
    getLeads(projectId);
  };

  handleChange = (event) => {
    this.setState({ [event.target.name]: event.target.value });
  };

  handleSortChange = (field, direction) => {
    const { match, getLeadsPaginated } = this.props;
    const { dates, selectedStatus, query, origin, assigned, pageSize } =
      this.state;
    const projectId = match.params.id;

    let dateFrom = null;
    let dateTo = null;

    if (dates != null) {
      dateFrom = moment(new Date(dates[0])).format("YYYY-MM-DD");
      dateTo = moment(new Date(dates[1])).format("YYYY-MM-DD");
    }

    sessionStorage.setItem(`lead_view_sortField_${projectId}`, field);
    sessionStorage.setItem(`lead_view_sortDirection_${projectId}`, direction);
    sessionStorage.setItem(`lead_view_page_${projectId}`, 1);

    this.setState({
      sortField: field,
      sortDirection: direction,
      currentPage: 1,
    });

    console.log('sort')

    getLeadsPaginated(
      projectId,
      1,
      pageSize,
      query,
      selectedStatus,
      origin,
      assigned,
      dateFrom,
      dateTo,
      field,
      direction
    );
  };

  handlePageChange = (pageNum) => {
    const { match, getLeadsPaginated } = this.props;
    const projectId = match.params.id;

    let {
      dates,
      selectedStatus,
      sortField,
      direction,
      query,
      pageSize,
      origin,
      assigned,
    } = this.state;
    let dateFrom = null;
    let dateTo = null;

    if (dates != null) {
      dateFrom = moment(new Date(dates[0])).format("YYYY-MM-DD");
      dateTo = moment(new Date(dates[1])).format("YYYY-MM-DD");
    }

    sessionStorage.setItem(`lead_view_page_${projectId}`, pageNum);

    this.setState({
      currentPage: pageNum,
    });

    console.log('page change')
    getLeadsPaginated(
      projectId,
      pageNum,
      pageSize,
      query,
      selectedStatus,
      origin,
      assigned,
      dateFrom,
      dateTo,
      sortField,
      direction
    );
  };

  handleDateChange = (dates) => {
    const { match, getLeadsPaginated, getLeadSummary } = this.props;
    const projectId = match.params.id;

    let {
      selectedStatus,
      sortField,
      direction,
      query,
      pageSize,
      origin,
      assigned,
    } = this.state;
    this.setState({ dates: dates });

    sessionStorage.setItem(`lead_view_page_${projectId}`, 1);
    sessionStorage.setItem(`lead_view_dates_${projectId}`, dates);

    let dateFrom = null;
    let dateTo = null;

    if (dates !== null && dates !== undefined && dates.length !== 0) {
      dateFrom = moment(new Date(dates[0])).format("YYYY-MM-DD");
      dateTo = moment(new Date(dates[1])).format("YYYY-MM-DD");
    }

    console.log('date change')
    getLeadsPaginated(
      projectId,
      1,
      pageSize,
      query,
      selectedStatus,
      origin,
      assigned,
      dateFrom,
      dateTo,
      sortField,
      direction
    );
    getLeadSummary(projectId, dateFrom, dateTo);
  };

  handleStatusChange = (statuses, origin, assigned) => {
    const { match, getLeadsPaginated } = this.props;
    const projectId = match.params.id;

    this.setState({
      selectedStatus: statuses.filter(item => typeof item !== 'number' || !isNaN(item)),
      origin: origin.filter(item => typeof item !== 'number' || !isNaN(item)),
      assigned: assigned.filter(item => typeof item !== 'number' || !isNaN(item)),
      currentPage: sessionStorage.getItem(`lead_view_page_${projectId}`),
    });

    let { dates, sortField, direction, query, pageSize, currentPage } = this.state;
    let dateFrom = null;
    let dateTo = null;

    sessionStorage.setItem(`lead_view_page_${projectId}`, 1);
    sessionStorage.setItem(`lead_view_status_${projectId}`, statuses);
    sessionStorage.setItem(`lead_view_origin_${projectId}`, origin);
    sessionStorage.setItem(`lead_view_assigned_${projectId}`, assigned);

    if (statuses.filter(item => typeof item !== 'number' || !isNaN(item)).length < 1) {
      sessionStorage.removeItem(`lead_view_status_${projectId}`);
    }

    if (origin.filter(item => typeof item !== 'number' || !isNaN(item)).length < 1) {
      sessionStorage.removeItem(`lead_view_origin_${projectId}`);
    }

    if (assigned.filter(item => typeof item !== 'number' || !isNaN(item)).length < 1) {
      sessionStorage.removeItem(`lead_view_assigned_${projectId}`);
    }


    if (dates !== null && dates !== undefined && dates.length !== 0) {
      dateFrom = moment(new Date(dates[0])).format("YYYY-MM-DD");
      dateTo = moment(new Date(dates[1])).format("YYYY-MM-DD");
    }

    currentPage = sessionStorage.getItem(`lead_view_page_${projectId}`);

    console.log('origin: ', origin);
    console.log('assigned: ', assigned);
    console.log('status change')
    getLeadsPaginated(
      projectId,
      currentPage,
      pageSize,
      query,
      statuses,
      origin,
      assigned,
      dateFrom,
      dateTo,
      sortField,
      direction
    );
  };

  handleSearch = (query) => {
    const { match, getLeadsPaginated } = this.props;
    const projectId = match.params.id;

    this.setState({
      query: query,
      currentPage: 1,
    });

    sessionStorage.setItem(`lead_view_query_${projectId}`, query);
    sessionStorage.setItem(`lead_view_page_${projectId}`, 1);

    if (query == null) {
      sessionStorage.removeItem(`lead_view_query_${projectId}`);
    }

    let {
      dates,
      sortField,
      direction,
      selectedStatus,
      origin,
      assigned,
      pageSize,
    } = this.state;
    let dateFrom = null;
    let dateTo = null;

    if (dates) {
      dateFrom = moment(new Date(dates[0])).format("YYYY-MM-DD");
      dateTo = moment(new Date(dates[1])).format("YYYY-MM-DD");
    }

    console.log('search change')
    getLeadsPaginated(
      projectId,
      1,
      pageSize,
      query,
      selectedStatus,
      origin,
      assigned,
      dateFrom,
      dateTo,
      sortField,
      direction
    );
  };

  handleSizeChange = (size) => {
    const { match, getLeadsPaginated } = this.props;
    const projectId = match.params.id;

    let {
      dates,
      selectedStatus,
      sortField,
      direction,
      query,
      origin,
      assigned,
      currentPage,
    } = this.state;
    let dateFrom = null;
    let dateTo = null;

    if (dates !== null && dates !== undefined && dates.length !== 0) {
      dateFrom = moment(new Date(dates[0])).format("YYYY-MM-DD");
      dateTo = moment(new Date(dates[1])).format("YYYY-MM-DD");
    }

    sessionStorage.setItem(`lead_view_size_${projectId}`, size);

    this.setState({
      pageSize: size,
    });

    console.log('size change')
    getLeadsPaginated(
      projectId,
      currentPage,
      size,
      query,
      selectedStatus,
      origin,
      assigned,
      dateFrom,
      dateTo,
      sortField,
      direction
    );
  };

  handleKpiHeaderFilter = (items, key, color) => {
    this.setState({ selectedCategory: key, selectedColor: color });
    const { match } = this.props;
    const projectId = match.params.id;

    let selectedStatus = [];
    items.forEach((i) => {
      selectedStatus.push(i.id);
    });

    console.log('kpi filter header called...');
    console.log('origin: ', sessionStorage.getItem(`lead_view_origin_${projectId}`));
    console.log('assigned: ', sessionStorage.getItem(`lead_view_assigned_${projectId}`));
    this.handleStatusChange(selectedStatus, [], []);
  };

  handleReset = () => {
    const { match, getLeadsPaginated, getLeadSummary } = this.props;
    const projectId = match.params.id;

    sessionStorage.removeItem(`lead_view_page_${projectId}`);
    sessionStorage.removeItem(`lead_view_size_${projectId}`);
    sessionStorage.removeItem(`lead_view_query_${projectId}`);
    sessionStorage.removeItem(`lead_view_status_${projectId}`);
    sessionStorage.removeItem(`lead_view_dates_${projectId}`);
    sessionStorage.removeItem(`lead_view_assigned_${projectId}`);
    sessionStorage.removeItem(`lead_view_origin_${projectId}`);
    sessionStorage.removeItem(`lead_view_sortField_${projectId}`);
    sessionStorage.removeItem(`lead_view_sortDirection_${projectId}`);

    console.log('reset called');

    this.setState({
      query: null,
      selectedStatus: [],
      currentPage: 1,
      pageSize: 15,
      dates: null,
      selectedCategory: null,
      sortField: null,
      sortDirection: null,
      origin: [],
      assigned: [],
    });

    console.log('reset...pagination')
    getLeadsPaginated(
      projectId,
      1,
      15,
      null,
      [],
      [],
      [],
      null,
      null,
      null,
      null
    );
    getLeadSummary(projectId, null, null);
  };

  handleKpiHeaderChanged(isExpanded) {
    this.setState({ isKpiHeaderExpanded: isExpanded });
  }

  handleProjectDetailsDrawer() {
    this.setState({
      isProjectDetailsDrawer: !this.state.isProjectDetailsDrawer,
    });
  }

  handleProjectMembersDrawer() {
    this.setState({
      isProjectMembersDrawer: !this.state.isProjectMembersDrawer,
    });
  }

  handleAddLeadDrawer() {
    this.setState({ isAddLeadDrawer: !this.state.isAddLeadDrawer });
  }

  handleBulkUploadDrawer() {
    this.setState({ isBulkUploadDrawer: !this.state.isBulkUploadDrawer });
  }

  handleManageDecksDrawer = () => {
    this.setState({ isManageDecksDrawer: !this.state.isManageDecksDrawer });
  };

  handleCloseManageDecksDrawer = () => {
    this.setState({ isManageDecksDrawer: false });
    this.setState({ isHandleDecksDrawer: false });
  };

  handleDecksDrawer = (deckItem) => {
    // // If deckItem is null or undefined, set it to an empty object
    deckItem = deckItem ?? {};

    // If the clicked deck item is the same as the current one, toggle the state
    if (deckItem.id === this.state.deck?.id) {
      this.setState((prevState) => ({
        isHandleDecksDrawer: !prevState.isHandleDecksDrawer,
        deck: prevState.deck === deckItem ? {} : deckItem,
      }));
    } else {
      // If it's a different deck item, update the state with the new item
      this.setState({ isHandleDecksDrawer: true, deck: deckItem });
    }
  };

  handleCloseDeckDrawer = () => {
    // Close the drawer by setting isHandleDecksDrawer to false
    this.setState({ isHandleDecksDrawer: false });
  };


  handleOpenTableAction = (selectedLeadIds, allLeadsSelected) => {
    this.setState({
      isTableActionBox: selectedLeadIds.length > 0 ? true : false,
      selectedLeadIds, // Update the state with selected lead IDs
      headerCheckboxChecked: allLeadsSelected,
    });  
  };

  handleMasterCheckboxChange = () => {
    this.setState((prevState) => ({
      headerCheckboxChecked: !prevState.headerCheckboxChecked,
      selectedLeadIds: [], // Unselect individual leads when master checkbox changes
    }));
  };

  handleCloseTableAction = () => {
    this.setState({ isTableActionBox: false, headerCheckboxChecked: false, selectedLeadIds: [] });
  };

  handleBatchUpdate = () => {
    this.setState({ isTableActionBox: false, headerCheckboxChecked: false });
  }

  render() {
    let {
      reports,
      project,
      projects,
      authentication,
      leads,
      leadsLoading,
      leadsLoaded,
      leadData,
      match,
      summary,
    } = this.props;

    let projectId = match.params.id;
    let list = [];

    if (leadsLoaded) {
      list = leads.leads.data.content;
    }

    let {
      sortField,
      sortDirection,
      dates,
      leadSyncData,
      leadSyncStatus,
      selectedStatus,
      selectedCategory,
      selectedColor,
      isKpiHeaderExpanded,
      isProjectDetailsDrawer,
      isProjectMembersDrawer,
      isAddLeadDrawer,
      isManageDecksDrawer,
      isHandleDecksDrawer,
      isTableActionBox,
      deck,
      headerCheckboxChecked,
      selectedLeadIds,
      pageSize,
      isBulkUploadDrawer
    } = this.state;
    let loadingReport = "";

    let activeClass = isKpiHeaderExpanded ? "active" : "";

    if (reports.loadingReport) {
      loadingReport = reports.loadingReport ? "active" : "";
    }

    if (leadSyncStatus === "PROCESSING") {
      loadingReport = leadSyncStatus === "PROCESSING" ? "active" : "";
    }

    let deckAdmin = false;

    if (project && authentication) {
      const projectOwner = project.data.owner.account.email;
      const accountEmail = authentication.user.data.email;

      deckAdmin = projectOwner === accountEmail;
    }

    return (
      <React.Fragment>
        <div className={`download-box-indicator ${loadingReport}`}>
          <MDBRow className="m--0 align-items-center">
            {leadSyncStatus === "PROCESSING" ? (
              <div className="download-data">
                <div className="download-info">
                  <span>{leadSyncData.currentStepDescription}</span>
                  {leadSyncData.totalItem && (
                    <React.Fragment>
                      (<span>{leadSyncData.processedItem} / </span>
                      <span>{leadSyncData.totalItem}</span>)
                    </React.Fragment>
                  )}
                </div>
                <div>
                  <div className="spinner"></div>
                </div>
              </div>
            ) : (
              <div className="download-data">
                <div className="download-info">
                  <span>Exporting Leads ...</span>
                </div>
                <div>
                  <div className="spinner"></div>
                </div>
              </div>
            )}
          </MDBRow>
        </div>
        <div className="topbar-menu">
          <MDBRow className="row align-items-center">
            <TopBar
              onHandleProjectDetailsDrawer={(width) =>
                this.handleProjectDetailsDrawer(width)
              }
              onHandleProjectMembersDrawer={(width) =>
                this.handleProjectMembersDrawer(width)
              }
            />

            <div className="col">
              {leadsLoading && <ButtonGroupLoader />}
              {leadsLoaded && (
                <div className="top-button-box">
                  {parseInt(projectId) === 3 &&
                    this.state.leadSyncStatus !== "PROCESSING" && (
                      <React.Fragment>
                        <Button
                          className="c-btn c-btn-light"
                          enabled={reports.loadingReport}
                          onClick={() => this.handleSyncData()}
                        >
                          <i className="icon-lms icon-sync"></i>Sync Patient
                        </Button>
                        <Button
                          className="c-btn c-btn-light"
                          enabled={reports.loadingReport}
                          onClick={() => this.handleDownloadPatientReport()}
                        >
                          <i className="icon-lms icon-download"></i>Download
                          Patient Report
                        </Button>
                      </React.Fragment>
                    )}
                  {projectId == 5 && deckAdmin && (
                    <button
                      className="c-btn c-btn-light"
                      onClick={() => this.handleManageDecksDrawer()}
                    >
                      <svg
                        xmlns="http://www.w3.org/2000/svg"
                        class="icon icon-tabler icon-tabler-presentation"
                        width="17"
                        height="17"
                        viewBox="0 0 21 21"
                        stroke-width="1.5"
                        stroke="currentColor"
                        fill="none"
                        stroke-linecap="round"
                        stroke-linejoin="round"
                      >
                        <path stroke="none" d="M0 0h24v24H0z" fill="none" />
                        <path d="M3 4l18 0" />
                        <path d="M4 4v10a2 2 0 0 0 2 2h12a2 2 0 0 0 2 -2v-10" />
                        <path d="M12 16l0 4" />
                        <path d="M9 20l6 0" />
                        <path d="M8 12l3 -3l2 2l3 -3" />
                      </svg>
                      Manage Decks
                    </button>
                  )}
                  {projectId == 6 && <Button
                    className="c-btn c-btn-light"
                    enabled={reports.loadingReport}
                    onClick={() => this.handleBulkUploadDrawer()}
                  >
                    <i className="icon-lms icon-export"></i>Bulk Upload
                  </Button> }
                  {projectId == 5 && <Link className="c-btn c-btn-light" to={`/project/${projectId}/manage-email-templates`}>
                    <i className="icon-lms icon-envelope-o"></i>Manage Email Templates
                  </Link>}
                  <Button
                    className="c-btn c-btn-light"
                    enabled={reports.loadingReport}
                    onClick={() => this.handleDownloadReport()}
                  >
                    <i className="icon-lms icon-export"></i>Export Leads
                  </Button>
                  <Button
                    className="c-btn"
                    onClick={() => {
                      this.handleAddLeadDrawer();
                    }}
                  >
                    <i className="icon-lms icon-add"></i>Add Lead
                  </Button>
                </div>
              )}
            </div>
          </MDBRow>
        </div>

        <div className="overview-menu">
          <KpiControl
            projectId={projectId}
            selectedStatus={selectedStatus}
            selectedCategory={selectedCategory}
            selectedColor={selectedColor}
            dates={dates}
            onHandlePageChanged={this.handlePageChange}
            onHandleDateChanged={this.handleDateChange}
            onHandleStatusChanged={this.handleStatusChange}
            onHandleSearch={this.handleSearch}
            onHandleSizeChanged={this.handleSizeChange}
            onHandleKpiHeaderFilter={this.handleKpiHeaderFilter}
            onHandleKpiHeaderHeightChanged={(height) =>
              this.handleKpiHeaderChanged(height)
            }
          />
        </div>

        <KpiHeader
          onHandleKpiHeaderHeightChanged={(height) =>
            this.handleKpiHeaderChanged(height)
          }
          onHandleKpiHeaderFilter={this.handleKpiHeaderFilter}
          onHandleReset={this.handleReset}
          isKpiHeaderExpanded={isKpiHeaderExpanded}
        />

        <div className="main-table-section">
          <div className="table-view table-leads">
            <div className={`table-scroll ${activeClass}`}>
              {leadsLoading && <TableMainLoader />}
              {leadsLoaded && list.length > 0 && (
                <table id="table-form">
                  <thead>
                    <DefaultTableHeader
                      projectId={projectId}
                      field={sortField}
                      direction={sortDirection}
                      onHandleSortChange={this.handleSortChange}
                      onHandleOpenTableAction={this.handleOpenTableAction}
                      headerCheckboxChecked={headerCheckboxChecked}
                      onMasterCheckboxChange={this.handleMasterCheckboxChange}
                    />
                  </thead>
                  <tbody>
                    <LeadViewList
                      projectId={projectId}
                      onHandleOpenTableAction={this.handleOpenTableAction}
                      headerCheckboxChecked={this.state.headerCheckboxChecked}
                      selectedLeadIds={selectedLeadIds}
                      isTableActionBox={isTableActionBox}
                    />
                  </tbody>
                </table>
              )}

              {leadsLoaded && list.length === 0 && (
                <div className="table-empty-box">
                  <div>
                    <h3>No Leads Found</h3>
                    <p>
                      Leads Information will appear here <br />
                      Luckily, you can add lead manually
                    </p>
                    <span onClick={() => this.handleAddLeadDrawer()}>
                      <i className="icon-lms icon-add"></i>
                      Add Lead
                    </span>
                  </div>
                </div>
              )}
            </div>
            <TableActions
              isTableActionBox={isTableActionBox}
              handleCloseTableAction={this.handleCloseTableAction}
              managers={project ? project.data.members : []}
              status={summary && summary.page ? summary.page.data : []}
              selectedLeadIds={this.state.selectedLeadIds} // Pass selected lead IDs
              pageSize={pageSize}
              batchUpdate={this.handleBatchUpdate}
            />
          </div>
          <TablePagination
            projectId={projectId}
            onHandlePageChanged={this.handlePageChange}
            onHandleSizeChanged={this.handleSizeChange}
          />
        </div>

        {/* Drawers */}
        <ProjectDetails
          isProjectDetailsDrawer={isProjectDetailsDrawer}
          data={project}
          onHandleProjectDetailsDrawer={() => this.handleProjectDetailsDrawer()}
        />

        <ProjectMembers
          isProjectMembersDrawer={isProjectMembersDrawer}
          onHandleProjectMembersDrawer={() => this.handleProjectMembersDrawer()}
        />

        <AddLeadDrawer
          projectId={projectId}
          isAddLeadDrawer={isAddLeadDrawer}
          onHandleAddLeadDrawer={() => this.handleAddLeadDrawer()}
        />

        <BulkUploadDrawer
          projectId={projectId}
          pageSize={pageSize}
          isBulkUploadDrawer={isBulkUploadDrawer}
          onHandleBulkUploadDrawer={() => this.handleBulkUploadDrawer()}
        />

        <ManageDecksDrawer
          isManageDecksDrawer={isManageDecksDrawer}
          isHandleDecksDrawer={isHandleDecksDrawer}
          onHandleManageDecksDrawer={() => this.handleManageDecksDrawer()}
          onHandleCloseManageDecksDrawer={() =>
            this.handleCloseManageDecksDrawer()
          }
          onHandleDecksDrawer={(deckItem) => this.handleDecksDrawer(deckItem)}
          deck={deck}
        />

        <HandleDeckDrawer
          isHandleDecksDrawer={isHandleDecksDrawer}
          onHandleDecksDrawer={() => this.handleDecksDrawer()}
          onHandleCloseDrawer={() => this.handleCloseDeckDrawer()}
          deck={deck}
        />
      </React.Fragment>
    );
  }

  handleDownloadReport() {
    const { match, getLeadReport } = this.props;
    const projectId = match.params.id;

    let dateFrom = null;
    let dateTo = null;

    let { dates, selectedStatus } = this.state;

    if (dates !== null && dates !== undefined && dates.length !== 0) {
      dateFrom = moment(new Date(dates[0])).format("YYYY-MM-DD");
      dateTo = moment(new Date(dates[1])).format("YYYY-MM-DD");
    }

    getLeadReport(projectId, selectedStatus, dateFrom, dateTo);
  }

  handleUploadApplicants() {
    const { match, getLeadReport } = this.props;
    const projectId = match.params.id;
  }

  handleDownloadPatientReport() {
    const { getPatientReport } = this.props;
    getPatientReport();
  }

  handleSyncData() {
    const { getLeadToPatientConversion } = this.props;
    getLeadToPatientConversion();
  }

  handleUpdateList() {
    const { match, getLeadSummary, getLeadsPaginated } = this.props;
    const projectId = match.params.id;

    let {
      currentPage,
      pageSize,
      dates,
      query,
      selectedStatus,
      assigned,
      origin,
      sortField,
      sortDirection,
    } = this.state;

    let dateFrom = null;
    let dateTo = null;

    if (dates !== null && dates !== undefined && dates.length !== 0) {
      dateFrom = moment(new Date(dates[0])).format("YYYY-MM-DD");
      dateTo = moment(new Date(dates[1])).format("YYYY-MM-DD");
    }

    //console.log('update change')
    getLeadSummary(projectId, dateFrom, dateFrom);
    getLeadsPaginated(
      projectId,
      currentPage,
      pageSize,
      query,
      selectedStatus,
      origin,
      assigned,
      dateFrom,
      dateTo,
      sortField,
      sortDirection
    );
  }
}

function mapState(state) {
  const {
    projects,
    forms,
    form,
    leads,
    adding,
    deleting,
    reports,
    authentication,
    summary,
  } = state;
  const { leadData, leadsLoaded, leadsLoading, selectedLeadIds } = state.leads;
  const { project, projectLoaded } = state.projects;
  // const { summary } = state.summary;
  if (leadData) {
    const currentPage = leadData.data.number;
    const pageSize = leadData.data.size;
    const totalLeads = leadData.data.totalElements;
    const maxPage = leadData.data.totalPages;
    return {
      deleting,
      adding,
      projects,
      project,
      forms,
      form,
      leads,
      leadData,
      leadsLoaded,
      leadsLoading,
      summary,
      currentPage,
      pageSize,
      totalLeads,
      maxPage,
      reports,
      selectedLeadIds,
    };
  }
  return {
    deleting,
    adding,
    projects,
    project,
    projectLoaded,
    forms,
    form,
    leads,
    leadData,
    leadsLoaded,
    leadsLoading,
    summary,
    reports,
    authentication,
  };
}

const actionCreators = {
  getLeads: leadActions.getLeads,
  getLeadsPaginated: leadActions.getLeadsPaginated,
  getLeadToPatientConversion: leadActions.getLeadToPatientConversion,
  getLeadReport: leadActions.getLeadReport,
  getLead: leadActions.getLead,
  deleteLead: leadActions.deleteLead,
  getForm: formActions.getForm,
  getProject: projectActions.getProject,
  getProjects: projectActions.getProjects,
  getAllProjectStatus: projectActions.getAllProjectStatus,
  findProjectStatusCount: projectActions.findProjectStatusCount,
  getLeadSummary: leadActions.getLeadSummary,
  getPatientReport: leadActions.getPatientReport,
  getOrigin: leadActions.getOrigin,
};

const connectedViewLeads = connect(mapState, actionCreators)(Leads);
export { connectedViewLeads as Leads };