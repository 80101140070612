
import {
    getLeadFieldValue,
    getLeadCustomValue,
    utilitiesService as utility,
    getCampaignData
} from '_services/utilities.service'
import React from "react";
import Moment from "react-moment";

const MaayoLeadColumns = props => {

    const { lead } = props;

    let service = getLeadFieldValue(lead, 53);
    let owner = lead.owner ? utility.decrypt(lead.owner.first_name) + ' ' + utility.decrypt(lead.owner.last_name) : 'Unassigned';

    let campaign = getCampaignData(lead);

    return (
        <React.Fragment>
            <td>
                <span>{service}</span>
            </td>

            <td>
                <span>{lead.origin}</span>
            </td>

            <td>
                <span>{campaign.source}</span>
            </td>

            <td>
                <span>{campaign.medium}</span>
            </td>

            <td>
                <span>{campaign.campaign}</span>
            </td>

        </React.Fragment>
    );

}

export default MaayoLeadColumns;