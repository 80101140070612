import React, { Component } from 'react';
import { connect } from "react-redux";
import { Link } from "react-router-dom";
import { projectActions, formActions } from '_actions';

import { MDBContainer, MDBRow, MDBCol, MDBIcon, MDBDropdown, MDBDropdownToggle, MDBDropdownMenu, MDBDropdownItem } from 'mdbreact';
import { ButtonGroup, Button, Icon } from "blackbox-react";

import { CreateFormModal } from '_pages/Forms/components/CreateFormModal';
import { LoadFormModal } from '_pages/Forms/components/LoadFormModal';
import InputForm from '_components/InputForm';
import { FormCardView } from '_pages/Forms/components/FormCardView';
import DeleteConfirmationModal from '_components/Modal/DeleteConfirmationModal';

import search from '_assets/images/icon-finder.png';
import Moment from "react-moment";

class Forms extends Component {
  constructor (props){
    super(props);

    const forms = this.props.getForms(this.props.match.params.id);

    this.state = {
      //project: project,
      projectId: this.props.match.params.id,
      forms: forms,
      activeFilter: 'all',
      activeSort: 'lastUpdated',
      isOpen: false,
      createFormModalOpen: false,
      deleteConfirmationModalOpen: false,
      loadFormModalOpen: false,
      loadAddTaskModal: false,
      isGridActive: true,
      hasSelected: 'display-none',
      statusToDelete: [],
      sortNav: [
        {
          name: 'Last Updated',
          value: 'lastUpdated'
        },
        {
          name: 'Newest',
          value: 'newest'
        },
        {
          name: 'Oldest',
          value: 'oldest'
        },
        // {
        //   name: 'Completed',
        //   value: 'completed'
        // },
        // {
        //   name: 'In Progress',
        //   value: 'inProgress'
        // },
        {
          name: 'A-Z',
          value: 'az'
        },
        {
          name: 'Z-A',
          value: 'za'
        }
      ],
      filtered: [],
      triggerSearch: false,
      selectedForms: [],
      deleteConfirmNum: ''
    };

    this.handleSearch = this.handleSearch.bind(this);
    this.approveModal = this.approveModal.bind(this);
    this.renderFormView = this.renderFormView.bind(this);
  }

  componentDidMount() {
    let blankForm = {
      form_id: 0,
      group: [],
      info: {
        name: 'Loading...',
        info_id: 0
      }
    };
    sessionStorage.setItem('form', JSON.stringify(blankForm));

    sessionStorage.removeItem('isActiveModule');
    sessionStorage.removeItem('formIntegration');
    sessionStorage.removeItem('formMessage');
    sessionStorage.removeItem('formAccessKey');
    sessionStorage.removeItem('fieldIndex');
    sessionStorage.removeItem('fieldTotalNum');
    sessionStorage.setItem('isActiveModule', 'forms');
  }

  //this function is used to trigger create form modal
  toggleCreateForm = () => {
    let { createFormModalOpen } = this.state;
    this.setState({ createFormModalOpen: !createFormModalOpen })
  };

  //this function is used to trigger delete modal confirmation
  toggleDeleteConfirmation = () => {
    let { deleteConfirmationModalOpen } = this.state;
    this.setState({ deleteConfirmationModalOpen: !deleteConfirmationModalOpen })
  };

  //this function is used to trigger load form modal
  toggleLoadFormModal = () => {
    let { loadFormModalOpen } = this.state;
    this.setState({ loadFormModalOpen: !loadFormModalOpen })
  };

  //this function is used to trigger load form modal
  toggleAddTaskModal = () => {
    let { loadAddTaskModal } = this.state;
    this.setState({ loadAddTaskModal: !loadAddTaskModal })
  };

  //this function is used to handle search
  handleSearch = event => {
    let formData = [];

    if(this.props.forms.allForms !== undefined){
      formData = this.props.forms.allForms.data;
      let currentList = [];
      let newList = [];

      if (event.target.value !== "") {
        currentList = formData;
        newList = currentList.filter(item => {
          const lcName = item.name.toLowerCase();
          const lcDescription = item.description.toLowerCase();
          const filter = event.target.value.toLowerCase();
          return lcName.includes(filter) || lcDescription.includes(filter);
        });
      } else {
        newList = formData;
      }

      this.setState({
        filtered: newList,
        triggerSearch: true
      });
    }

    let filteredData = this.state.filtered;
  };

  //this function is used to render slider
  handleSlider = () => {
    let { sortNav } = this.state;
    let sliderClass = '';
    return (
      sortNav.map((value, index) => {
        sliderClass = this.state.activeSort === value.value ? 'border-right-orange text-right' : 'border-right-gray-slider text-right';
        return (
          <p
            key={index}
            className={`${sliderClass} py-2 pr-3 mb-15 pointer-cursor`}
            onClick={() => { this.setState({ activeSort: value.value }) }}>
            {value.name}
          </p>
        )
      })
    )
  };

  //this function is used to handle text ellipsis
  handleTextEllipsis(text, max) {
    return text.substr(0, max) + (text.length > max ? '...' : '');
  };

  handleSetDeleteConfirmationNum = (num) => {
    this.setState({
      deleteConfirmNum: num
    })
  };

  //this function is used to render card view and list view
  renderFormView = (projectId, formData, filter, sort) => {
    let { isGridActive } = this.state;

    return isGridActive ?
      <FormCardView
      projectId={projectId}
      data={formData}
      filter={filter}
      sort={sort}
      //projects={projects.items.data}
      handleToggle={this.handleToggle}
      handlePassData={this.handlePassData}
      handleSetDeleteConfirmationNum={this.handleSetDeleteConfirmationNum}
      statusToDelete={this.state.statusToDelete}/> :
      this.renderFormListView(formData, projectId)
  };

  //this function is used to render Action Button
  handleCheckboxSelect = (event, value) => {
    let count = document.querySelectorAll(`input[type='checkbox'][name='check-box']:checked`);
    //let selectedFormList = selectedFormList.push('formId');
    this.setState({
      hasSelected: count.length > 0 ? 'visible' : 'display-none',
    });

    value = {
      id: value.id,
      name: value.name,
      description: value.description
    };

    if(event.target.checked){

      this.setState(prevState => ({
        selectedForms: [...prevState.selectedForms, value]
      }));

    } else {

      let removeSelected = [...this.state.selectedForms];
      var index = removeSelected.indexOf(value);
      if (index !== -1) {
        removeSelected.splice(index, 1);
        this.setState({selectedForms: removeSelected});

      }
    }


    let isCheck = document.querySelectorAll(`input[type='checkbox'][name='check-all']`);
    for (let i = 0; i < isCheck.length; i++) {
      if (isCheck[i].type === 'checkbox') {
        isCheck[i].checked = false;
      }
    }
  };

  //this function is used to handle the check all cards
  handleCheckAllSelectBox = () => {
    let checkAll = document.querySelector(`input[type='checkbox'][name='check-all']`);
    let checkBoxes = document.querySelectorAll(`input[type='checkbox'][name='check-box']`);
    let isCheck = document.querySelectorAll(`input[type='checkbox'][name='check-all']:checked`);
    checkAll.addEventListener('change', function (e) {
      for (let checkBox of checkBoxes) {
        checkBox.checked = this.checked;
      }
    });
    this.setState({ hasSelected: isCheck.length > 0 ? 'visible' : 'invisible' })
  };

  handleToggle = (deleteNum) =>{
    let { modalOpen } = this.state;
    this.setState({
      modalOpen: !modalOpen,
      deleteConfirmNum: deleteNum
    });

  };

  approveModal = (statusToDelete) => {
    let deleteRequest = {};

    if(this.state.deleteConfirmNum === 'bulk'){
      deleteRequest = {
        info_request: this.state.statusToDelete
      };
      this.props.deleteMultipleForm(deleteRequest, this.props.match.params.id);
    } else if(this.state.deleteConfirmNum ==='single'){
      deleteRequest = this.state.statusToDelete;
      this.props.deleteForm(deleteRequest, this.props.match.params.id, this.state.statusToDelete.id);
    }

    this.setState((prevState, props) => {
      return { statusToDelete: {} }
    });
  };

  handlePassData = (data) => {
    this.setState((prevState, props) => {
      return { statusToDelete: data }
    });
  };

  renderFormTableBody = (formData, projectId) => {
    return (
      formData.map((value, index) => {
        return (
          <tr key={index}>
            <td>
              <label className='checkboxcontainer ml-3'>
                <input type='checkbox' name='check-box' onClick={(event) => { this.handleCheckboxSelect(event, value) }} />
                <span className='checkmark'> </span>
              </label>
            </td>
            <td>
              <Link to={`/project/${projectId}/form/${value.form_id}/form-builder`}>
                <span className='font-size-12 font-bold-black'>{value.name}</span>
              </Link>
            </td>
            <td><p className='font-size-11 mb-0 line-height-13 opacity-80'>{this.handleTextEllipsis(`${value.description}`, 50)}</p></td>
            <td>
              <span className='font-size-10 mb-0'>
                <Moment date={value.created} format="MMMM Do YYYY" /> &nbsp;
                <Moment date={value.created} format="h:mm a" />
              </span>
            </td>
            <td>
              <span className='font-size-10 mb-0'>
                <Moment date={value.created} format="MMMM Do YYYY" /> &nbsp;
                <Moment date={value.created} format="h:mm a" />
              </span>
            </td>
            {/*<td className='padding-left-80 padding-right-80'>*/}
            {/*  <div className='position-relative progress mb-2 mt-1 background-color-light-pink border-radius-50 border-dark-orange'>*/}
            {/*    <span className='progress-value font-color-dark-orange'>10% complete</span>*/}
            {/*    <div className='progress-bar background-color-dark-orange border-radius-50 ' style={{ width: '10%' }}>*/}
            {/*    </div>*/}
            {/*  </div>*/}
            {/*</td>*/}
            <td className='d-flex'>
              <div className='m-auto'>
                <MDBRow>
                  {/*<MDBDropdown className='list-settings'>*/}
                  {/*  <MDBDropdownToggle flat className='p-0 shadow-none m-0'>*/}
                  {/*    <img src={settings} alt='' className='p-2 my-auto text-center' />*/}
                  {/*  </MDBDropdownToggle>*/}
                  {/*  <MDBDropdownMenu className='border-radius-3 border-unset box-shadow min-width-unset left-negative-65 top-negative-3 py-0'>*/}
                  {/*    <MDBDropdownItem className='font-size-11 p-2 px-3'>Form Settings</MDBDropdownItem>*/}
                  {/*    <MDBDropdownItem divider className='m-0' />*/}
                  {/*    <MDBDropdownItem className='font-size-11 p-2 px-3'>Form Builder</MDBDropdownItem>*/}
                  {/*    <MDBDropdownItem divider className='m-0' />*/}
                  {/*    <MDBDropdownItem className='font-size-11 p-2 px-3'>Integration</MDBDropdownItem>*/}
                  {/*  </MDBDropdownMenu>*/}
                  {/*</MDBDropdown>*/}
                  <MDBIcon
                    far
                    icon='trash-alt'
                    className='p-2 my-auto text-center color-red pointer-cursor'
                    onClick={(event) => {
                      this.handleToggle('single');
                      this.handlePassData(value);
                      this.setState({
                        deleteConfirmNum: 'single'})
                    }}> </MDBIcon>
                  {/*<Modal toggle={this.handleToggle} modalOpen={this.state.modalOpen} statusToDelete={this.state.statusToDelete} onApprove={this.approveModal} target={value.name}/>*/}
                </MDBRow>
              </div>
            </td>
          </tr>
        )
      })
    )
  };

  //this function is used to render the list view card 
  renderFormListView = (formData, projectId) => {
    return (
      <MDBCol className='p-t--0 p-l--0 p-r--0 p-b--md'>
        <MDBCol className='px-0 background-color-white border-radius-3'>
          <table className='table-sm bordered' id='table-form'>
            <thead>
              <tr className='background-color-white'>
                <th width='5%' className='py-3'>
                  <label className='checkboxcontainer ml-3'>
                    <input type='checkbox' name='check-all' onClick={() => { this.handleCheckAllSelectBox(true) }} />
                    <span className='checkmark'></span>
                  </label>
                </th>
                <th width='25%' className='font-size-12 font-weight-600 opacity-70 py-3'>Form Name</th>
                <th width='25%' className='font-size-12 font-weight-600 opacity-70 py-3'>Description</th>
                <th width='15%' className='font-size-12 font-weight-600 opacity-70 py-3'>Created</th>
                <th width='15%' className='font-size-12 font-weight-600 opacity-70 py-3'>Last Updated</th>
                {/*<th width='29%' className='font-size-12 text-center font-weight-600 opacity-70 py-3'>Creation Progress %</th>*/}
                <th width='10%' className='font-size-12 text-center font-weight-600 opacity-70 py-3'>Actions</th>
              </tr>
            </thead>
            <tbody>
              {this.renderFormTableBody(formData, projectId)}
            </tbody>
          </table>
        </MDBCol>
      </MDBCol>
    )
  };

  renderSearch = (formData) => {
    let { isGridActive } = this.state;
    let gridViewIconClass = isGridActive && 'active';
    let listViewIconClass = isGridActive || 'active';
    let gridIconClass = isGridActive ? 'opacity-80' : 'opacity-50';
    let listIconClass = isGridActive ? 'opacity-50' : 'opacity-80';

    if (formData.length > 0) {
      return (
        <MDBCol>
          <MDBRow className='text-left'>
            <div className='text-center col pt-1 ml-2 p-r--0'>
              <div>
                <InputForm
                  onChange={this.handleSearch}
                  type='text'
                  hint='Search'
                  name='search'
                  inputClass='padding-left-10 pr-4'
                />
              </div>
              <img src={search} alt='' className='icon-search' />
            </div>
            <div className='p--sm'>
              <div className={`${gridViewIconClass} icon-form-view`}>
                <MDBIcon icon='th' className={`${gridIconClass} px-1 text-center`} onClick={() => { this.setState({ isGridActive: true }) }} />
              </div>
              <div className={`${listViewIconClass} icon-form-view`}>
                <MDBIcon icon='list' className={`${listIconClass} px-1 text-center`} onClick={() => { this.setState({ isGridActive: false }) }} />
              </div>

            </div>
          </MDBRow>
        </MDBCol>
      )
    }
  };

  renderNoResults = () => {
    return (
      <div className='text-center position-center'>
        <h3>No Results found</h3>
        <p>Your search did not match any forms. <br/> Make sure that all words are spelled correctly and try again. </p>
      </div>
    );
  };

  handleBulkDelete = () => {

  };

  renderActionButton = () => {
    return (
      <div className={`${this.state.hasSelected} pt-2 pb-3`}>
        <div className=' d-flex'>
          <div className='m-auto'>
            <MDBDropdown>
              <MDBDropdownToggle flat
                                 className='action-list clear-background border-black border-radius-3 py-2 pl-2 pr-1 shadow-none m-0 font-size-10 font-weight-600'>
                <span className='mx-1'>ACTIONS</span>
                <MDBIcon className='mx-2 chevron action-caret color-black' icon='chevron-up'/>
              </MDBDropdownToggle>
              <MDBDropdownMenu
                className='border-radius-3 border-unset box-shadow min-width-unset top-negative-3 py-0'>
                {/*<MDBDropdownItem*/}
                {/*  className='font-size-10 p-2 px-3 font-weight-600 color-green form-publish'>Publish*/}
                {/*  Forms</MDBDropdownItem>*/}
                {/*<MDBDropdownItem divider className='m-0'/>*/}
                {/*<MDBDropdownItem className='font-size-10 p-2 px-3 font-weight-600 opacity-60'>Unpublish*/}
                {/*  Forms</MDBDropdownItem>*/}
                {/*<MDBDropdownItem divider className='m-0'/>*/}
                <MDBDropdownItem
                  className='font-size-10 p-2 px-3 font-weight-600 color-red form-deleted'
                  onClick={(event) => { event.preventDefault(); this.handleToggle(); this.handlePassData(this.state.selectedForms); this.setState({ deleteConfirmNum: 'bulk'}) }}
                >Delete Selected Forms</MDBDropdownItem>
              </MDBDropdownMenu>
              {/*<Modal toggle={this.handleToggle} modalOpen={this.state.modalOpen}
                                             statusToDelete={this.state.statusToDelete} onApprove={this.approveModal}
                                             target={value.name}/>*/}
            </MDBDropdown>
          </div>
        </div>
      </div>
    );
  };

  render() {
    let { isGridActive } = this.state;
    const { activeFilter, activeSort } = this.state;
    let { formsLoaded } = this.props;
    let formData =[];

    let hasData = false;
    let hasResults = false;
    let triggerSearch = this.state.triggerSearch;
    //let bodyContainerClass = hasData ? 'main-body-container rectangle-background height-auto' : 'main-body-container rectangle-background';
    //let projectData ={};

    if(typeof this.props.forms.allForms !== 'undefined'){
      formData = this.props.forms.allForms.data;
      formData = formData.filter(function( obj ) {
        return obj.is_default !== true;
      });
    }

    let projectId = this.props.match.params.id;

    let filteredData = [];
    //let isLoaded = (typeof formData !== 'undefined');
    //console.log('isLoaded', isLoaded);

    const deleted = this.props.deleted;
    const deleting = this.props.deleting;

    if(formsLoaded){
      //isLoaded = false;
      //projectData = this.props.projects.items.data || {};
      //projectId = projectData.id;
      filteredData = this.state.filtered;

      if(!triggerSearch){
        hasData = formData.length > 0 ;
      }

      if (filteredData.length > 0) {
        formData = filteredData;
        hasData = formData.length > 0;
        //isLoaded = true;
        if(triggerSearch){
          hasResults = true;
        }
      } else{
        hasData = false;
        if(triggerSearch){
          hasResults = false;
        }
      }

      if (!hasData){
        //formData = projectData.forms;
        if (formData){
          hasData = formData.length > 0;
          //isLoaded = true;
        }
        if(!triggerSearch && hasData){
          hasResults = true;
        }
      }
    }

    if(deleting){
      //isLoaded = false;
    } else if(deleted){
      // this.props.getProject(this.props.match.params.id);
      // projectData = JSON.parse(sessionStorage.getItem('project'));
      // formData = projectData.forms;
      //isLoaded = true;
    }

      return (
          <MDBContainer fluid className='p--md'>
            <MDBRow className='m--0'>
              <MDBCol md='auto' className='p--0 m--0'>
                <p className='font-size-12 font-bold-black mb-0'>LEAD MANAGEMENT</p>
                <p className='font-family-crimson font-size-30 line-height-7 color-orange'>Forms</p>
              </MDBCol>
              { formsLoaded ? this.renderSearch(formData) : null }
              <MDBCol>
                <MDBRow className='float-right'>
                  <ButtonGroup>
                    <Button
                      flat='true'
                      background='orange' foreground='white'
                      borderWidth={2} borderColor='orange' size={-1}
                      onClick={this.toggleLoadFormModal}>
                      <Icon name='plus' />
                      <span className='color-white'>Load Sample Form</span>
                    </Button>
                    <Button
                      flat='true'
                      background='orange' foreground='white'
                      borderWidth={2} borderColor='orange' size={-1}
                      onClick={this.toggleCreateForm}>
                      <Icon name='plus' />
                      <span className='color-white'>Create Form</span>
                    </Button>
                  </ButtonGroup>
                </MDBRow>
              </MDBCol>
            </MDBRow>
            <MDBContainer fluid className='sub-body-container rectangle-background p--0 m-t--sm'>
              <CreateFormModal
                modalToggle={this.toggleCreateForm}
                modalOpen={this.state.createFormModalOpen}
                projectId={projectId}
              />
              <LoadFormModal
                modalToggle={this.toggleLoadFormModal}
                modalOpen={this.state.loadFormModalOpen}
                projectId={projectId}
              />
              { formsLoaded ?
                <MDBRow className='sub-body-container height--full p--md m--0'>
                  { hasData &&
                    <MDBCol md='auto' className='p--0 text-right font-size-12'>
                      {!isGridActive &&
                        this.renderActionButton()
                      }
                      {this.handleSlider()}
                    </MDBCol>
                  }
                  <MDBCol className='p-t--0 p-l--md p-r--0 p-b--md'>
                    { hasData ?
                      hasResults ?
                        this.renderFormView(projectId, formData, activeFilter, activeSort)
                        : this.renderNoResults()
                      :
                      <div className='create-view text-center'>
                        <p className='font-size-32 font-weight-500 font-family-crimson m-b--sm'>Create your first form</p>
                        <Button
                          flat='true'
                          background='orange' foreground='white'
                          borderWidth={2} borderColor='orange'
                          onClick={this.toggleCreateForm}>
                          <Icon name='plus' />
                          <span className='color-white'>Create New Form</span>
                        </Button>
                      </div>
                    }
                  </MDBCol>
                </MDBRow> :
                <MDBRow className='sub-body-container height--full'>
                  <MDBCol md='auto' lg='12' className='sub-body-container height--full'>
                    <div className='loader-container'>
                      { hasData ||
                      <div className="loader dark">Loading...</div>
                      }
                    </div>
                  </MDBCol>
                </MDBRow>
              }

              <DeleteConfirmationModal
                toggle={this.handleToggle}
                modalOpen={this.state.modalOpen}
                statusToDelete={this.state.statusToDelete}
                onApprove={this.approveModal}
                deleteNum={this.state.deleteConfirmNum}
                target={this.state.selectedForms}
              />
            </MDBContainer>
          </MDBContainer>
      );
    }
}

function mapState(state) {
  const { projects, project, forms, form, adding } = state;
  const { deleted, deleting, formsLoaded, allForms } = state.forms;
  return { adding, projects, project, forms, form, deleted, deleting, formsLoaded, allForms };
}

const actionCreators = {
  getProject: projectActions.getProject,
  getForms: formActions.getForms,
  deleteForm: formActions.deleteForm,
  deleteMultipleForm: formActions.deleteMultipleForm
};

const connectedViewForms = connect(mapState, actionCreators)(Forms);
export { connectedViewForms as Forms };
