import React, { useState, useRef } from 'react';

// components
import ActivityBtn from './ActivityBtn';
import {PlatformTab} from './PlatformTab';
import {ActivitiesTab} from './ActivitiesTab';
import {RecordTab} from './RecordTab';
import {NoteTab} from './NoteTab';
import {ClientInfo} from "./ClientInfo";
import {useParams} from "react-router-dom";
import {RateTab} from "./RateTab";
import NewNote from './NewNote';
import { Swiper, SwiperSlide } from 'swiper/react';
import { FreeMode, Navigation } from "swiper";

import SideInfoLoader from '../../../../_components/Loader/SideInfoLoader';
import SideInfoButtonLoader from "../../../../_components/Loader/SideInfoButtonLoader";

const ActivitySide = ( {
    onHandleManageNotesModal,
    onHandleManageRecordsModal,
    onHandleManageRatesModal,
    onHandleManagePlatformModal,
    onHandleOpenConfirmationModal,
    influencerBasicInfoLoaded,
    influencerBasicInfoLoading} ) => {

    const [toggleTabState, setToggleTabState] = useState(1);

    const {id, influencerId} = useParams();

    const toggleTab = (index) => {
        setToggleTabState(index);
    }


    return (
        <>
            <div className='activity-side-box box-white-custom'>
                {influencerBasicInfoLoaded ? 
                <div className='activity-control-box'>
                    <Swiper
                        slidesPerView={4}
                        spaceBetween={0}
                        modules={[Navigation]}
                        className="tabSwiper"
                        navigation={{ nextEl: ".nextSlide", prevEl: ".prevSlide" }}
                    >
                        <SwiperSlide>
                            <ActivityBtn
                                ToggleTabClick={() => toggleTab(1)}
                                classN={toggleTabState === 1 ? 'activity-btns active' : 'activity-btns'}
                                name='Platform'
                                icon='fas fa-globe'
                            />
                        </SwiperSlide>
                        <SwiperSlide>
                            <ActivityBtn
                                ToggleTabClick={() => toggleTab(2)}
                                classN={toggleTabState === 2 ? 'activity-btns active' : 'activity-btns'}
                                name='Activity'
                                icon='far fa-clock'
                            />
                        </SwiperSlide>
                        <SwiperSlide>
                            <ActivityBtn
                                ToggleTabClick={() => toggleTab(3)}
                                classN={toggleTabState === 3 ? 'activity-btns active' : 'activity-btns'}
                                name='Rates'
                                icon='fas fa-money-bill'
                            />
                        </SwiperSlide>
                        <SwiperSlide>
                            <ActivityBtn
                                ToggleTabClick={() => toggleTab(4)}
                                classN={toggleTabState === 4 ? 'activity-btns active' : 'activity-btns'}
                                name='Clients'
                                icon='fas fa-money-bill'
                            />
                        </SwiperSlide>
                        <SwiperSlide>
                            <ActivityBtn
                                ToggleTabClick={() => toggleTab(5)}
                                classN={toggleTabState === 5 ? 'activity-btns active' : 'activity-btns'}
                                name='Records'
                                icon='fas fa-clipboard'
                            />
                        </SwiperSlide>
                        <SwiperSlide>
                            <ActivityBtn
                                ToggleTabClick={() => toggleTab(6)}
                                classN={toggleTabState === 6 ? 'activity-btns active' : 'activity-btns'}
                                name='Notes'
                                icon='far fa-file-alt'
                            />  
                        </SwiperSlide>
                        {/* <SwiperSlide>
                            <ActivityBtn
                                ToggleTabClick={() => toggleTab(7)}
                                classN={toggleTabState === 7 ? 'activity-btns active' : 'activity-btns'}
                                name='Notes'
                                icon='far fa-file-alt'
                            />
                        </SwiperSlide> */}
                    </Swiper>
                    <button className='swiper-custom-btn prevSlide'><i className="icon-lms icon-arrow-left"></i></button>
                    <button className='swiper-custom-btn nextSlide'><i className="icon-lms icon-arrow-right"></i></button>
                </div>
                : <SideInfoButtonLoader/> 
                }

                <div className='custom-scroll-box'>
                    {influencerBasicInfoLoaded ? 
                    <>
                        <PlatformTab
                            projectId={id}
                            influencerId={influencerId}
                            classN={toggleTabState === 1 ? 'tab-content-box platform-box active' : 'tab-content-box platform-box'}
                            onHandleManagePlatformModal={onHandleManagePlatformModal}
                            onHandleOpenConfirmationModal={onHandleOpenConfirmationModal}
                        />
                        <ActivitiesTab
                            projectId={id}
                            influencerId={influencerId}
                            classN={toggleTabState === 2 ? 'tab-content-box recent-activity-box active' : 'tab-content-box recent-activity-box'}
                        />
                        <RateTab
                            projectId={id}
                            influencerId={influencerId}
                            classN={toggleTabState === 3 ? 'tab-content-box rates-box active' : 'tab-content-box rates-box'}
                            onHandleManageRatesModal={onHandleManageRatesModal}
                            onHandleOpenConfirmationModal={onHandleOpenConfirmationModal}
                        />
                        <ClientInfo 
                            projectId={id}
                            influencerId={influencerId}
                            classN={toggleTabState === 4 ? 'tab-content-box clients-box active' : 'tab-content-box clients-box'}
                        />
                        <RecordTab
                            projectId={id}
                            influencerId={influencerId}
                            classN={toggleTabState === 5 ? 'tab-content-box records-box active' : 'tab-content-box records-box'}
                            onHandleManageRecordsModal={onHandleManageRecordsModal}
                            onHandleOpenConfirmationModal={onHandleOpenConfirmationModal}
                        />
                        <NewNote
                            projectId={id}
                            influencerId={influencerId}
                            classN={toggleTabState === 6 ? 'tab-content-box notes-box active' : 'tab-content-box notes-box'}
                            onHandleOpenConfirmationModal={onHandleOpenConfirmationModal}
                        />
                        <NoteTab
                            projectId={id}
                            influencerId={influencerId}
                            classN={toggleTabState === 7 ? 'tab-content-box notes-box active' : 'tab-content-box notes-box'}
                            onHandleManageNotesModal={onHandleManageNotesModal}
                            onHandleOpenConfirmationModal={onHandleOpenConfirmationModal}
                        />
                    </>
                    : <SideInfoLoader/> 
                    }
                </div>
            </div>
        </>
    )
}

export default ActivitySide

