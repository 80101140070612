import React, {Component} from 'react';
import {MDBCol} from 'mdbreact';
import Moment from "react-moment";
import LeadClickedInfo from "_pages/Leads/components/LeadInfo/LeadClickedInfo";


class LeadViewInfoForms extends Component {

    //this function is used to handle the lenght of text
    handleTextEllipsis(text, max) {
        return text.substr(0, max) + (text.length > max ? '...' : '');
    }

    render() {


        let leadData = this.props.leadData;
        let projectId = this.props.projectId;
        let formData = {
            id: leadData.form_id,
            name: leadData.form_name,
            description: leadData.form_desc,
            created: leadData.created
        };



        let fbForm = leadData.fb_form;

        let urls = leadData.attributions;

        let campaign = leadData.campaign_data;

        if(fbForm) {
            campaign.source_url = 'www.facebook.com'
        }

        return (

            <div className='source-box tab-content-box'>
                {campaign ?
                    <div className='source-group'>
                        <div className='inner-title-box'>
                            <p>SOURCE DATA</p>
                        </div>
                        <div className='source-list-box tab-content-list-box'>
                            <div className='source'>
                                {campaign &&
                                    <>
                                        {
                                            campaign.source_url ? 
                                            <div>
                                                <p>URL</p>
                                                <span className='mb-5px'>{campaign.source_url}</span>
                                            </div>
                                            : ""
                                        }
                                        <div>
                                            <p>Source</p>
                                            <span className='mb-5px'>
                                                {campaign.source ? campaign.source : <React.Fragment>
                                                    NOT AVAILABLE
                                                    <i className='q-mark-icon'>?
                                                        <span className='info-not-available'>
                                                                Either the data is not available on Google Analytics or the leads has no Google Client ID Data.
                                                        </span>
                                                    </i>
                                                </React.Fragment>}
                                            </span>
                                        </div>

                                        <div>
                                            <p>Medium</p>
                                            <span className="mb-5px">
                                                {campaign.medium ? campaign.medium : <React.Fragment>
                                                        NOT AVAILABLE
                                                        <i className='q-mark-icon'>?
                                                            <span className='info-not-available'>
                                                            Either the data is not available on Google Analytics or the leads has no Google Client ID Data.
                                                        </span>
                                                        </i>
                                                </React.Fragment>}
                                            </span>
                                        </div>

                                        <div>
                                            <p>Campaign</p>
                                            <span className="mb-5px">
                                                    {campaign.campaign ? campaign.campaign : <React.Fragment>
                                                        NOT AVAILABLE
                                                        <i className='q-mark-icon'>?
                                                            <span className='info-not-available'>
                                                            Either the data is not available on Google Analytics or the leads has no Google Client ID Data.
                                                        </span>
                                                        </i>
                                                </React.Fragment>}
                                            </span>
                                        </div>

                                        <div>
                                            <p>Qualification</p>
                                            <span className='mb-5px'>
                                                {campaign.qualification ? campaign.qualification : 'NOT AVAILABLE'}
                                            </span>
                                        </div>

                                        <div>
                                            <p>Acquisition Date</p>
                                            <span>
                                                <Moment date={leadData.created} format="LLL"/>
                                            </span>
                                        </div>
                                    </>
                                }
                            </div>
                        </div>
                    </div>
                    :
                    <div className='source-group'>
                        <div className='inner-title-box'>
                            <p>SOURCE DATA</p>
                        </div>
                        <div className='source-list-box tab-content-list-box'>
                            <div className='source'>
                                <div>
                                <p>URL</p>
                                <span className='mb-5px'>
                                    {leadData.source_url ?  leadData.source_url : <React.Fragment>
                                                https://lms.propelrr.io
                                                <i className='q-mark-icon'>?
                                                    <span className='info-not-available'>
                                                        This lead is created via the LMS Platform.
                                                    </span>
                                                </i>
                                    </React.Fragment>}
                                </span>
                                </div>

                                <div>
                                <p>Source</p>
                                <span className='mb-5px'>
                                    NOT AVAILABLE
                                </span>
                                </div>

                                <div>
                                <p>Medium</p>
                                <span className='mb-5px'>
                                    NOT AVAILABLE
                                </span>
                                </div>

                                <div>
                                <p>Campaign</p>
                                <span className='mb-5px'>
                                    NOT AVAILABLE
                                </span>
                                </div>

                                <div>
                                <p>Qualification</p>
                                <span className='mb-5px'>
                                    NOT AVAILABLE
                                </span>
                                </div>

                                <div>
                                <p>Acquisition Date</p>
                                <span>
                                    <Moment date={leadData.created} format="LLL"/>
                                </span>
                                </div>

                            </div>
                        </div>
                    </div>
                }
                {urls && urls.length > 0 &&
                    <div className='source-group'>
                        <div className='inner-title-box'>
                            <p>CLICKED URL</p>
                        </div>
                        <div className='source-list-box tab-content-list-box'>
                            <div className='source'>
                                <ul className='accordion'>
                                    {urls.map((url,index) => {
                                        return <LeadClickedInfo key={index} url={url}/>
                                    })}
                                </ul>
                            </div>
                        </div>
                    </div>
                }
                {fbForm &&
                    <div className='source-group'>
                        <div className='inner-title-box'>
                            <p>FACEBOOK FORM DATA</p>
                        </div>
                        <div className='source-list-box tab-content-list-box'>
                            <div className='source'>
                                <div>
                                <p>Form ID</p>
                                <span className='mb-5px'>{fbForm.form_id}</span>
                                </div>

                                <div>
                                <p>Form</p>
                                <span className='mb-5px'>{fbForm.form_name}</span>
                                </div>

                                <div>
                                <p>Campaign Name</p>
                                <span className='mb-5px'>{fbForm.campaign_name}</span>
                                </div>

                                <div>
                                <p>Ad Id</p>
                                <span className='mb-5px'>{fbForm.ad_id}</span>
                                </div>

                                <div>
                                <p>Ad Name</p>
                                <span className='mb-5px'>{fbForm.ad_name}</span>
                                </div>

                                <div>
                                <p>Ad Set</p>
                                <span className='mb-5px'>{fbForm.adset_name}</span>
                                </div>

                                <div>
                                <p>Submitted Date</p>
                                <span className='mb-5px'>
                                    <Moment date={fbForm.created} format="LLL"/>
                                </span>
                                </div>

                            </div>
                        </div>
                    </div>
                }

                {
                   campaign && campaign.length > 0 || urls && urls.length > 0  || fbForm && fbForm.length > 0 &&
                   <div className='empty-tab-content'>
                        <div className='empty-content'>
                            <h6>No Available Source</h6>
                        </div>
                    </div>
                }
            </div>);
    }
}

export default LeadViewInfoForms;
