import React, { Component, useState } from "react";
import {
  MDBContainer,
  MDBRow,
  MDBCol,
  MDBModal,
  MDBModalHeader,
  MDBModalBody,
} from "mdbreact";
import { fileActions, influencerActions } from "_actions";
import { connect } from "react-redux";

class LeadFileManageModal extends Component {
  constructor(props) {
    super(props);

    this.state = {
      isTitleError: false,
      isDescriptionError: false,
      isLinkError: false,
      isFileError: false,
      toggleTabState: 1,
      file: null, // Added file state
    };

    this.titleInput = React.createRef();
    this.descriptionInput = React.createRef();
    this.linkInput = React.createRef();
    this.fileInput = React.createRef(); // Added file input ref
  }

  handleCloseManageFileModal = (mode) => {
    this.props.handleCloseManageFileModal(mode);
    this.setTitle();
    this.setDescription();
    this.setLink();
    this.handleClearInput();
  };

  handleFileChange = (event) => {
    console.log('event: ', event.target.files[0])
    this.setState({ file: event.target.files[0], isFileError: false });

  };

  setTitle = (value) => {
    this.setState({ isTitleError: false });
  };

  setDescription = (value) => {
    this.setState({ isDescriptionError: false });
  };

  setLink = (value) => {
    this.setState({ isLinkError: false });
  };

  isFieldValid() {
    // console.log(this.titleInput.current.value);
    let isValid = true;

    const title = this.titleInput.current.value;
    const description = this.descriptionInput.current.value;
    const file = this.fileInput.current.value;
    
    if (title === "") {
      this.setState({ isTitleError: true });
      isValid = false;
    }

    if (description === "") {
      this.setState({ isDescriptionError: true });
      isValid = false;
    }

    // Check if it's in edit mode before validating the file input
    if (this.props.mode === "add" && file === "") {
        this.setState({ isFileError: true });
        isValid = false;
    }


    return isValid;

  }

  handleManageFile() {
    const { mode } = this.props;
    const { file } = this.state; // Added file state

    let isEdit = mode !== "add";

    const isValue = this.isFieldValid();

    if (isValue) {
      if (isEdit) {
        this.handleEditLeadFile(file); // Pass file data
      } else {
        this.handleAddLeadFile(file); // Pass file data
      }
    }
  }

  handleAddLeadFile(file) {
    const { leadId, projectId, mode, addLeadFile } = this.props;
    const title = this.titleInput.current.value;
    const description = this.descriptionInput.current.value;

    const formData = new FormData();
    formData.append("file", file);
    formData.append("name", title);
    formData.append("description", description);

    addLeadFile(projectId, leadId, formData); // Use formData for file upload

    this.handleCloseManageFileModal(mode);
  }

  handleEditLeadFile(file) {
    const { leadId, projectId, mode, updateLeadFile, data } = this.props; 

    const title = this.titleInput.current.value;
    const description = this.descriptionInput.current.value;
    const link = this.linkInput.current.value;

    const formData = new FormData();
    formData.append("file", file);
    formData.append("name", title);
    formData.append("link", link);
    formData.append("description", description);

    updateLeadFile(projectId, leadId, data.id, formData);

    this.handleCloseManageFileModal(mode);
  }

  handleClearInput = () => {
    // Clear the current.value of the ref
    const { mode, data } = this.props;

    if (mode === "add") {
      this.titleInput.current.value = "";
      this.descriptionInput.current.value = "";
      // this.linkInput.current.value = "";
    }

    if (mode === "edit") {
      this.titleInput.current.value = data.name;
      this.descriptionInput.current.value = data.description;
      this.linkInput.current.value = data.link;
    }
  };

  componentDidUpdate(prevProps) {
    // Check if the props or state have changed
    if (prevProps.mode !== this.props.mode) {
      console.log("previous.. " + this.prevProps);
      console.log("current.. " + this.props);
      // Perform the necessary update
      if (this.props.mode === "add") {
        this.titleInput.current.value = "";
        this.descriptionInput.current.value = "";
        this.fileInput.current.value = "";
        // this.linkInput.current.value = "";
        this.setState({ isFileError: false });
      }

      if (this.props.mode === "edit") {
        this.titleInput.current.value = this.props.data.name;
        this.descriptionInput.current.value = this.props.data.description;
        this.linkInput.current.value = this.props.data.link;
        this.setState({ isFileError: false });
      }
    }
  }

  render() {
    const { isOpen, mode, data } = this.props;
    const { isTitleError, isDescriptionError, isLinkError, isFileError } = this.state;

    let isEdit = mode !== "add";
    let activeClass = isOpen ? "active" : "";

    return (
      <React.Fragment>
        <div
          className={`drawer-overlay ${activeClass}`}
          onClick={(mode) => this.handleCloseManageFileModal(mode)}
        ></div>
        <div className={`side-drawer-main ${activeClass}`}>
          <div className="side-drawer-wrapper">
            <div className="side-drawer-title">
              <h3>{mode.toUpperCase()} FILE</h3>
              <span
                className="side-drawer-close"
                onClick={(mode) => this.handleCloseManageFileModal(mode)}
              >
                <i className="icon-lms icon-close"></i>
              </span>
            </div>
            <div className="side-drawer-tabs">
              <div className="side-drawer-tabs-wrapper">
                <div className="control-tabs-box">
                  <div
                    className={
                      this.state.toggleTabState === 1
                        ? "tabs-btn active"
                        : "tabs-btn"
                    }
                    onClick={() => this.toggleTab(1)}
                  >
                    <p>File Information</p>
                  </div>
                </div>
                <div className="content-tabs-box">
                  <div
                    className={
                      this.state.toggleTabState === 1
                        ? "tabs-content active"
                        : "tabs-content"
                    }
                  >
                    <div className="forms-tab">
                      <MDBRow>
                        <MDBCol className="col-12 form-group-box">
                          <div className="input-group-box full-box-">
                            <div
                              className={
                                isTitleError
                                  ? `field-box error-field`
                                  : `field-box`
                              }
                            >
                              <label htmlFor={"title"}>
                                Title <small>*</small>
                              </label>
                              <input
                                ref={this.titleInput}
                                type="text"
                                id={"title"}
                                name={"title"}
                                defaultValue={isEdit ? data.name : ""}
                                onChange={(e) => this.setTitle(e.target.value)}
                              />
                              {isTitleError && (
                                <p className="error-field">
                                  This field is required
                                </p>
                              )}
                            </div>
                            <div
                              className={
                                isDescriptionError
                                  ? `field-box error-field`
                                  : `field-box`
                              }
                            >
                              <label htmlFor={"description"}>
                                Description <small>*</small>
                              </label>
                              <input
                                ref={this.descriptionInput}
                                type="text"
                                id={"description"}
                                name={"description"}
                                defaultValue={isEdit ? data.description : ""}
                                onChange={(e) =>
                                  this.setDescription(e.target.value)
                                }
                              />
                              {isDescriptionError && (
                                <p className="error-field">
                                  This field is required
                                </p>
                              )}
                            </div>
                            {mode === "edit" && (
                              <div
                                className={
                                  isLinkError
                                    ? `field-box error-field`
                                    : `field-box`
                                }
                              >
                                <label htmlFor={"link"}>
                                  Document Link <small>*</small>
                                </label>
                                <input
                                  ref={this.linkInput}
                                  type="text"
                                  readOnly={true}
                                  id={"link"}
                                  name={"link"}
                                  defaultValue={isEdit ? data.link : ""}
                                  // onChange={(e) => this.setLink(e.target.value)}
                                />
                                {isLinkError && (
                                  <p className="error-field">
                                    This field is required
                                  </p>
                                )}
                              </div>
                            )}
                            <div
                                className={`field-box ${isLinkError ? "error-field" : ""}`}
                                >
                                <label htmlFor="link">File <small>*</small></label>
                                <div >
                                <div class="custom-input">
                                <input
                                    ref={this.fileInput}
                                    type="file"
                                    id="link"
                                    name="link"
                                    onChange={this.handleFileChange}
                                    />

                                </div>
                                    
                                    {isFileError && (
                                    <p className="error-field">Please select a file</p>
                                    )}
                                </div>
                            </div>

                          </div>
                        </MDBCol>
                      </MDBRow>
                    </div>
                  </div>
                </div>
                <div className="tab-form-btn-group">
                  <MDBRow>
                    <MDBCol className="text-right">
                      <span
                        className="drawer-btn cancel-btn"
                        onClick={(mode) =>
                          this.handleCloseManageFileModal(mode)
                        }
                      >
                        Cancel
                      </span>
                      <span
                        className="drawer-btn save-btn"
                        onClick={() => this.handleManageFile()}
                      >
                        {mode === "add" ? `Save Record` : `Update Record`}
                      </span>
                    </MDBCol>
                  </MDBRow>
                </div>
              </div>
            </div>
          </div>
        </div>
      </React.Fragment>
    );
  }
}

function mapState(state) {
  const { influencers } = state;
  return { influencers };
}

const actionCreators = {
  addLeadFile: fileActions.addFile,
  updateLeadFile: fileActions.updateFile,
};

const connectedViewLeadFileManageModal = connect(
  mapState,
  actionCreators
)(LeadFileManageModal);
export { connectedViewLeadFileManageModal as LeadFileManageModal };
