import { combineReducers } from 'redux';

import { authentication } from './authentication.reducer';
import { registration } from './registration.reducer';
import { users } from './users.reducer';
import { alert } from './alert.reducer';
import { projects } from './projects.reducer';
import { forms } from './forms.reducer';
import { leads } from './leads.reducer';
import { publicApi } from './public.reducer';
import { summary } from './summary.reducer';
import { reports } from './report.reducer';
import { influencers } from './influencer.reducer';
import { managers } from './manager.reducer';
import { utm } from './utm.reducer';
import { chat } from './chat.reducer'
import { email } from './email.reducer';
import { profile } from './profile.reducer';
import { deck } from './deck.reducer';
import { calendly } from './calendly.reducer';
import { template } from './template.reducer';
import { file } from './file.reducer';

const rootReducer = combineReducers({
  authentication,
  registration,
  users,
  alert,
  projects,
  forms,
  leads,
  reports,
  summary,
  publicApi,
  influencers,
  managers,
  utm,
  chat,
  email,
  profile,
  deck,
  calendly,
  template,
  file
});

export default rootReducer;
