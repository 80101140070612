import { httpManagerService as httpManager } from  '_services';
import { utilitiesService as utility } from '_services';

export const userService = {
  storeUser,
  getUser,
  register,
  login,
  twoFactorEmailVerify,
  twoFactorAppVerify,
  twoFactorResend,
  logout,
  updatePassword,
  forgotPassword,
  twoFactorEmailLogin,
  twoFactorAppLogin,
  sendAppKey,
  twoFactorEmailVerify1
};

function storeUser(user) {
  httpManager.storeUser(user)
}

function getUser() {
  return httpManager.getUser();
}

function register(user) {
  Object.keys(user).forEach(function(propsId){
    user[propsId] = utility.encrypt(user[propsId]);
  });

  return httpManager.postJsonDataWithRequestingToken('/api/register', user);
}

function login(email, password) {
  email = utility.encrypt(email);
  password = utility.encrypt(password);
  httpManager.setUserCredentials(email, password);
  return httpManager.postJsonDataWithRequestingToken('/api/login', { email, password });
}

function twoFactorEmailVerify1(request) {
  return httpManager.postJsonOnce(`/api/two-factor/verify`, request);
}

function twoFactorEmailLogin() {
  return httpManager.getHttpWithRequestingToken(`/api/login/email-2fa`);
}

function twoFactorAppLogin() {
  return httpManager.getHttpWithRequestingToken(`/api/login/app-2fa`);
}

function twoFactorEmailVerify(request) {
  return httpManager.postJsonOnce(`/api/two-factor/email/verify`, request);
}

function twoFactorAppVerify(request) {
  return httpManager.postJsonOnce(`/api/two-factor/app/verify`, request);
}

function twoFactorResend() {
  return httpManager.getHttpWithRequestingToken(`/api/two-factor/resend`);
}

function sendAppKey() {
  return httpManager.getHttpWithRequestingToken(`/api/two-factor/send/app-code`);
}


function logout() {
  httpManager.removeCredentials();
  httpManager.removeTokens();

  sessionStorage.clear();
}

function updatePassword(account) {
  return httpManager.putJsonDataWithRequestingToken('/api/profile/password', account)
}

function forgotPassword(request) {
  return httpManager.putJsonDataWithRequestingToken('/api/password/forgot', request)
}


