import React, { useState } from 'react';
import { Link } from 'react-router-dom';

// Components import
import Breadcrumbs from './components/Breadcrumbs';
import Button from './components/Button';
import KpiHeader from './components/Kpi/KpiHeader';
import KpiControl from './components/Kpi/KpiControl';
import TableViewLists from './components/Table/TableViewLists';
import AddInfluencer from './components/Modals/AddInfluencerModal';



const Propelrr = () => {

  // add influencer modal
  const [isAddInfluencer, setIsAddInfluencer] = useState(false);
  const openAddInfluencer = () => {
    setIsAddInfluencer(!isAddInfluencer);
  }
  const closeAddInfluencer = () => setIsAddInfluencer(false);

  return (
    <>
        <div className='page-title-ctas'>
            <div className='row align-items-center'>
                <div className='col'>
                    <Breadcrumbs />
                </div>
                <div className='col'>
                    <div className='button-group'>
                        <Button text='Export Influencers Data' icon='fa-arrow-down' />
                        <span className='custom-line'></span>
                        <Button text='Add Influencer' icon='fa-user-plus' onClick={() => openAddInfluencer()} />
                    </div>
                </div>
            </div>
        </div>

        {/* KPI HEADER & KPI CONTROL */}
        <KpiHeader />
        <KpiControl />


        {/* Table View Leads */}
        <TableViewLists />

        <AddInfluencer open={isAddInfluencer} toggle={setIsAddInfluencer} close={closeAddInfluencer} />
    </>
  )
}

export default Propelrr