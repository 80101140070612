import React, {useEffect, useState} from "react";
import {
    useTable,
    useFlexLayout,
    useBlockLayout,
    useResizeColumns,
    useExpanded
} from "react-table";
import { useSticky } from "react-table-sticky";
import { sampleDataGroup } from "./sampleDataGroup";
import { TableHeaderGroupMain, TableHeaderGroupInner } from "./TableHeaderGroup";
import {useDispatch, useSelector} from "react-redux";
import {useParams} from "react-router-dom";
import {alertActions} from "_actions";


const TableGroupMain = () => {

    const columns = React.useMemo(() => TableHeaderGroupMain, []);

    const dispatch = useDispatch();
    const params = useParams();
    const getUtmLoaded = useSelector((state) => state.utm.getUtmLoaded);
    const getUtmLoading = useSelector((state) => state.utm.getUtmLoading);
    const [data, setData] = useState([]);

    const tableData = useSelector((state) => {
        const {utm} = state;
        if(utm.getUtmLoaded) {
            const {utms} = utm;
            if(utms.content.length > 0){
                let content = utms.content;
                let grouped = content.reduce(function(rv, x) {

                    if(x.group) {
                        let key = x.group.name;
                        (rv[key] = rv[key] || []).push(x);
                    } else {
                        (rv['ungrouped'] = rv['ungrouped'] || []).push(x);
                    }
                    return rv;
                }, {});


                let parsed = [];
                Object.keys(grouped).forEach( (e) => {
                    parsed.push({
                        group: e,
                        data: grouped[e]
                    })
                });

                return parsed;
            }
            return [];
        }
        return [];
    });

    useEffect(() => {

        setData(tableData);
    }, [getUtmLoaded]);

    const {
        getTableProps,
        getTableBodyProps,
        headerGroups,
        rows,
        prepareRow,
        visibleColumns,
        state: { expanded },
    } = useTable(
        {
            columns,
            data
        },
        useFlexLayout,
        useExpanded,
        useResizeColumns,
        useSticky
    );


    return (
        <table {...getTableProps()} className="table-main">
            <thead>
            {headerGroups.map((headerGroup) => (
                <tr {...headerGroup.getHeaderGroupProps()}>
                    {headerGroup.headers.map((column) => (
                        <th {...column.getHeaderProps()}>
                            <span>{column.render("Header")}</span>
                            {column.canResize && (
                                <div
                                    {...column.getResizerProps()}
                                    className={`resizer ${
                                        column.isResizing ? "isResizing" : ""
                                    }`}
                                />
                            )}
                            <div className="th-w-sort">
                                <div className="sort-icons">
                                    <i className="fas fa-sort-up active"></i>
                                    <i className="fas fa-sort-down"></i>
                                </div>
                            </div>
                        </th>
                    ))}
                </tr>
            ))}
            </thead>
            <tbody {...getTableBodyProps()}>
            {rows.map((row, i) => {
                prepareRow(row);
                return (
                    <React.Fragment>
                        <tr {...row.getRowProps()}>
                            {row.cells.map((cell) => {
                                return (
                                    <td
                                        {...cell.getCellProps([
                                            { className: cell.column.className },
                                        ])}
                                    >
                                        {cell.render("Cell")}
                                    </td>
                                );
                            })}
                        </tr>
                        {row.isExpanded ? (
                            <>
                                <TableGroupInner data={row.original.data} />
                            </>
                        ) : null}
                    </React.Fragment>
                );
            })}
            </tbody>
        </table>
    );
};


const TableGroupInner = ({data}) => {

    const dispatch = useDispatch();

    const handleCopyBitly = (row) => {
        console.log(row.original.bitly);
        navigator.clipboard.writeText(row.original.bitly);
        dispatch(alertActions.success(row.original.bitly + " copied to clipboard"));
    }

    const columns = React.useMemo(() => TableHeaderGroupInner, []);

    const {
        getTableProps,
        getTableBodyProps,
        headerGroups,
        rows,
        prepareRow,
        visibleColumns,
        state: { expanded },
    } = useTable(
        {
            columns,
            data
        },
        useFlexLayout,
        useExpanded,
        useResizeColumns,
        useSticky
    );

    return(
        <table {...getTableProps()} className="table-inner">
            <thead>
            {headerGroups.map((headerGroup) => (
                <tr {...headerGroup.getHeaderGroupProps()}>
                    {headerGroup.headers.map((column) => (
                        <th {...column.getHeaderProps()}>
                            <span>{column.render("Header")}</span>
                            {column.canResize && (
                                <div
                                    {...column.getResizerProps()}
                                    className={`resizer ${
                                        column.isResizing ? "isResizing" : ""
                                    }`}
                                />
                            )}
                            <div className="th-w-sort">
                                <div className="sort-icons">
                                    <i className="fas fa-sort-up active"></i>
                                    <i className="fas fa-sort-down"></i>
                                </div>
                            </div>
                        </th>
                    ))}
                </tr>
            ))}
            </thead>
            <tbody {...getTableBodyProps()}>
            {rows.map((row, i) => {
                prepareRow(row);
                return (
                    <React.Fragment>
                        <tr {...row.getRowProps()} onClick={() => handleCopyBitly(row)}>
                            {row.cells.map((cell) => {
                                return (
                                    <td
                                        {...cell.getCellProps([
                                            { className: cell.column.className },
                                        ])}
                                    >
                                        {cell.render("Cell")}
                                    </td>
                                );
                            })}
                        </tr>
                    </React.Fragment>
                );
            })}
            </tbody>
        </table>
    );
};

export default TableGroupMain;
