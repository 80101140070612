import React, {useRef, useState} from "react";
import {
    abbreviateNumber,
    getInfluencerTypeClassColor,
    getInfluencerTypeClassIcon,
    getInfluencerTypeLabel
} from "_services";
import Moment from "react-moment";

const InfluencerPlatformInfo  = ({ platforms, onToggle, active }) => {

    const [clicked, setClicked] = useState(false);
    const contentEl = useRef();

    const handleToggle = (e) => {
        e.stopPropagation();
        setClicked((prev) => !prev);
    };
    const handleLinkClick = (e) => {
        e.stopPropagation();
    };

    let latest = new Date(Math.max(...platforms.map(e => new Date(e.updated))));

    return(
        <div className="platform-box">
            <div className='platform-summary'>

                {platforms.length > 0 && <React.Fragment>
                    <div>
                        {platforms.map((platform, index) => {

                            const type = platform.type.code;
                            const icon = getInfluencerTypeClassIcon(type);
                            const color = getInfluencerTypeClassColor(type);
                            const value = platform.value;
                            const link = platform.link;

                            return(
                                <a key={index} href={link} target={'new'} className='platform-icon' onClick={handleLinkClick}>
                                    <img src={`/${icon}.svg`} alt="logo" />
                                    <span className='info-text'>{abbreviateNumber(value)}&nbsp;{getInfluencerTypeLabel(type)}</span>
                                </a>);
                        })}
                    </div>
                    <div>
                        {clicked ? <button onClick={handleToggle}><span>Hide Info</span><i className="fas fa-chevron-up"></i></button> : <button onClick={handleToggle}><span>Updated: <br /><Moment date={latest} fromNow ago/> ago</span><i className="fas fa-chevron-down"></i></button>}
                    </div>
                </React.Fragment>
                }
            </div>
            <div
                // className={clicked ? `platform-info active` : `platform-info`}
                className={`platform-info active`}
                 ref={contentEl}
                 style={
                     clicked
                         ? { height: contentEl.current.scrollHeight }
                         : { height: "0px" }
                 }>
                {platforms.map((platform, index) => {

                    const type = platform.type.code;
                    const icon = getInfluencerTypeClassIcon(type);
                    const color = getInfluencerTypeClassColor(type);
                    const value = platform.value;
                    const link = platform.link;
                    const alt = platform.alt;
                    const name = platform.type.name;
                    const updated = platform.updated;

                    return(
                        <div className="platform-group active " key={index}>
                            <div>
                                <a href={link} target={'new'} className='platform-icon' onClick={handleLinkClick}>
                                <img src={`/${icon}.svg`} alt="logo" />
                                    <span>{name}</span>
                                </a>
                            </div>

                            <div>
                                <span className="total-number">{abbreviateNumber(value)}&nbsp;{getInfluencerTypeLabel(type)}</span>
                                <span className="time-text">Updated: <Moment date={updated} fromNow ago/> ago</span>
                            </div>

                        </div>)
                })}
            </div>
        </div>);

}

export default InfluencerPlatformInfo;