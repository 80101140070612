import React, {Component} from 'react';
import {connect} from "react-redux";
import {influencerActions, projectActions} from "_actions";
import TopBar from '../../_components/TopBar';
import ProjectDetails from '../../_components/Drawer/ProjectDetails';
import ProjectMembers from '../../_components/Drawer/ProjectMembers';
import Calendly from '../../_components/Drawer/Calendly';
import {InfluencerBreadcrumb} from "_pages/Influencer/components/General/InfluencerBreadcrumb";
import Button from "_pages/Influencer/components/General/Button";
import StatusFilter from "_pages/Propelrr/components/View/StatusFilter";
import HandleByFilter from "_pages/Influencer/components/Info/HandleByFilter";
import {utilitiesService} from "_services";
import {MDBCol, MDBRow} from "mdbreact";
import BasicInfo from "_pages/Influencer/components/Info/BasicInfo";
import {ClientInfo} from "_pages/Influencer/components/Info/ClientInfo";
import ActivitySide from "_pages/Influencer/components/Info/ActivitySide";
import {EditInfluencerModal} from "_pages/Influencer/components/Modals/EditInfluencerModal";
import {ManageNoteModal} from "_pages/Influencer/components/Modals/ManageNoteModal";
import {ManageRecordModal} from "_pages/Influencer/components/Modals/ManageRecordModal";
import {ManageRateModal} from "_pages/Influencer/components/Modals/ManageRateModal";
import {ManageRateModalNew} from "_pages/Influencer/components/Modals/ManageRateModalNew";
import {ManagePlatformModal} from "_pages/Influencer/components/Modals/ManagePlatformModal";
import {AddInfluencerDrawer} from '_pages/Influencer/components/Drawer/AddInfluencerDrawer';

import {ConfirmationDeleteModal} from "_pages/Influencer/components/Modals/ConfirmationDeleteModal";
import CategoryFilter from "_pages/Influencer/components/Info/CategoryFilter";
import TopBarLoader from '../../_components/Loader/TopBarLoader';
import ButtonGroupLoader from "../../_components/Loader/ButtonGroupLoader";
import BreadcrumbLoader from '../../_components/Loader/BreadcrumbLoader';
import TextLoader from '../../_components/Loader/TextLoader';
import EditInfluencerDrawer from '_pages/Influencer/components/Drawer/EditInfluencerDrawer';



class InfluencerInfo extends Component {

    constructor(props) {
        super(props);

        this.state = {
            isAddInfluencerDrawer: false,
            isEditInfluencerDrawer: false,
            isDeleteModalOpen: false,
            isManagePlatformModalOpen: false,
            isManageRateModalOpen: false,
            isManageRecordModalOpen: false,
            isManageNoteModalOpen: false,
            isDeleteConfirmationModalOpen: false,
            isAddFormDrawer: false,
            isProjectDetailsDrawer: false,
            isProjectMembersDrawer: false,
            isCalendlyDrawer: false,
            deleteConfirmationType: '',
            deleteConfirmationData: {},
            managePlatformData: {},
            managePlatformMode: 'Add',
            manageRateData: {},
            manageRateMode: 'Add',
            manageRecordData: {},
            manageRecordMode: 'Add',
            manageNoteData: {},
            manageNoteMode: 'Add',
        }

    }

    componentDidMount() {
        const {getProject, getInfluencer, match, getCategories} = this.props;
        const projectId = match.params.id;
        const influencerId = match.params.influencerId;
        getProject(projectId);
        getInfluencer(projectId, influencerId);
        getCategories(projectId);
    }


    handleOpenConfirmDeleteModal = (type, data) => {
        this.setState({
            isDeleteConfirmationModalOpen: true,
            deleteConfirmationType: type,
            deleteConfirmationData: data
        });
    }

    handleOpenManagePlatformModal = (mode, data) => {
        this.setState({
            isManagePlatformModalOpen: true,
            managePlatformMode : mode,
            managePlatformData: data,
        });
    }

    handleOpenManageRecordsModal = (mode, data) => {
        this.setState({
            isManageRecordsModalOpen: true,
            manageRecordMode : mode,
            manageRecordData: data,
        });
    }

    handleOpenManageNotesModal = (mode, data) => {
        this.setState({
            isManageNotesModalOpen : true,
            manageNoteMode: mode,
            manageNoteData: data
        });
    }

    handleOpenManageRatesModal = (mode, data) => {
        this.setState({
            isManageRatesModalOpen : true,
            manageRateMode: mode,
            manageRateData: data
        });
    }

    handleCloseEditInfluencerModal = () => {
        this.setState({isEditInfluencerModalOpen : false});
    }

    handleCloseManagePlatformModal = () => {
        this.setState({
            isManagePlatformModalOpen: false,
            managePlatformData: {},
            managePlatformMode: 'add'
        });
    }

    handleCloseManageRatesModal = () => {
        this.setState({
            isManageRatesModalOpen: false,
            manageRateData: {},
            manageRateMode: 'add'
        });
    }

    handleCloseManageRecordsModal = () => {
        this.setState({
            isManageRecordsModalOpen: false,
            manageRecordData: {},
            manageRecordMode: 'add'
        });
    }

    handleCloseManageNotesModal = () => {
        this.setState({
            isManageNotesModalOpen : false,
            manageNoteData: {},
            manageNoteMode: 'add'
        });
    }

    handleCloseConfirmationDeleteModal = () => {
        this.setState({isDeleteConfirmationModalOpen : false});
    }

    handleStatusChange = (status) => {
        const {match, updateInfluencerStatus } = this.props;
        const {id, influencerId} = match.params;
        updateInfluencerStatus(id, influencerId, status);
    }

    handleCategoryChange = (categories) => {
        const {match, updateInfluencerCategories } = this.props;
        const {id, influencerId} = match.params;
        updateInfluencerCategories(id, influencerId, categories)
    }

    handleProjectDetailsDrawer() {
        this.setState({isProjectDetailsDrawer: !this.state.isProjectDetailsDrawer})
    }

    handleProjectMembersDrawer() {
        this.setState({isProjectMembersDrawer: !this.state.isProjectMembersDrawer})
    }

    handleCalendlyDrawer() {
        this.setState({isCalendlyDrawer: !this.state.isCalendlyDrawer})
    }

    handleAddInfluencerDrawer() {
        this.setState({isAddInfluencerDrawer: !this.state.isAddInfluencerDrawer})
    }
    handleEditInfluencerDrawer() {
        this.setState({isEditInfluencerDrawer: !this.state.isEditInfluencerDrawer})
    }
    handleCloseEditInfluencerDrawer = () => {
        this.setState({isEditInfluencerDrawer : false});
    }

    render() {
        const {influencers, getCategoryListLoaded, influencerLoading, match, influencerLoaded} = this.props;
        const {
            isAddInfluencerDrawer,
            isEditInfluencerDrawer,
            isManageNotesModalOpen,
            isManageRecordsModalOpen,
            isManageRatesModalOpen,
            isManagePlatformModalOpen,
            managePlatformMode,
            managePlatformData,
            manageRateMode,
            manageRateData,
            manageRecordMode,
            manageRecordData,
            manageNoteMode,
            manageNoteData,
            isDeleteConfirmationModalOpen,
            deleteConfirmationType,
            deleteConfirmationData,
        } = this.state


        const {id, influencerId} = match.params;
        const projectId = match.params.id;

        let owner = '';
        let responsible = '';
        let categories = '';
        let status = '';
        let statusColor ='';
        let name = '';
        let contact = '';
        let email = '';
        let contacted = '';
        let handler = null;
        let categoryIds = [];
        let categoryList = [];

        let validIdType = null;
        let validIdNo = null;
        let address = null;

        if(getCategoryListLoaded) {
            categoryList = influencers.categoryList;
        }

        let influencer = {};
        if (influencerLoaded) {
            influencer = influencers.influencer.data;
            owner = utilitiesService.decrypt(influencer.owner.firstName) + " " + utilitiesService.decrypt(influencer.owner.lastName);
            responsible = utilitiesService.decrypt(influencer.responsible.firstName) + " " + utilitiesService.decrypt(influencer.responsible.lastName);
            status = influencer.condition.name;
            statusColor = influencer.condition.id;
            if(influencer.categories. length > 0) {
                categories = influencer.categories.sort((a,b) => a.name > b.name).map(c => c.name).join(', ');
                categoryIds = influencer.categories.sort((a,b) => a.name > b.name).map(c => c.id);
            } else {
                categories = 'Select Category';
            }
            name = influencer.name;
            contacted = influencer.contacted;
            contact = utilitiesService.decrypt(influencer.contact);
            email = utilitiesService.decrypt(influencer.email);
            handler = influencer.handler;

            if(influencer.validIdType) {
                validIdType = utilitiesService.decrypt(influencer.validIdType);

                if(validIdType === 'others-radio') {
                    if(influencer.validIdOthers){
                        validIdType = utilitiesService.decrypt(influencer.validIdOthers);
                    }
                }
            }

            if(influencer.validIdNo) {
                validIdNo = utilitiesService.decrypt(influencer.validIdNo);
            }

            if(influencer.address) {
                address =  utilitiesService.decrypt(influencer.address);
            }

        }

        return (<React.Fragment>
            <div className='topbar-menu'>
                <div className='row align-items-center'>
                    <TopBar 
                        onHandleProjectDetailsDrawer={(width) => this.handleProjectDetailsDrawer(width)}
                        onHandleProjectMembersDrawer={(width) => this.handleProjectMembersDrawer(width)}
                    />
                    <div className='col'>
                        {/* {influencerLoaded && 
                            <div className='top-button-box'>
                                <Button classN='c-btn' text='Add Influencer' icon="icon-lms icon-add" onClick={() => this.handleAddInfluencerDrawer()} />
                            </div>
                        }
                        {influencerLoading && <ButtonGroupLoader />} */}
                    </div>
                </div>
            </div>
            <div className='breadcrumb-menu'>
                <div className='row align-items-center'>
                        <div className='col'>
                            <InfluencerBreadcrumb
                            influencerBdLoaded = {influencerLoaded}
                            influencerBdLoading = {influencerLoading}/>
                        </div>
                        <div className='col'>
                            {influencerLoaded && 
                                <div className='button-group'>
                                    <Button classN='c-btn c-btn-light' text='Edit Influencer' icon='icon-lms icon-edit' onClick={() => this.handleEditInfluencerDrawer()}/>
                                    <Button classN='c-btn c-btn-light' text='Delete Influencer' icon='icon-lms icon-delete' onClick={() => this.handleOpenConfirmDeleteModal('influencer', influencer)}/>
                                </div>
                            }
                            {influencerLoading && <ButtonGroupLoader />}
                        </div>
                </div>
                <BreadcrumbLoader />
            </div>
            <div className='main-info-section influencers-info'>
                <MDBRow className="row-100">
                    <MDBCol md='7'>
                        <div className='top-info-box'>
                            <div className='row'>
                                <div className='col-box'>
                                    <div className='avatar-text-box'>
                                        <div className='text'>
                                            <label>Created By</label>
                                            <span>{owner}</span>
                                            {influencerLoading && <TextLoader/>}
                                        </div>
                                    </div>
                                </div>
                                <div className='col-box'>
                                    <div className='avatar-text-box'>
                                        <div className='text'>
                                            <label>Handle By</label>
                                            <HandleByFilter 
                                                responsible={responsible}
                                            />
                                            {influencerLoading && <TextLoader/>}
                                        </div>
                                    </div>
                                </div>
                                <div className='col-box'>
                                    <div className='avatar-text-box'>
                                        <div className='text'>
                                            <label>Category</label>
                                            <CategoryFilter
                                                categories={categories}
                                                categoryIds={categoryIds}
                                                categoryList={categoryList}
                                                onCategoryChanged={(categories) => this.handleCategoryChange(categories)}
                                            />
                                            {influencerLoading && <TextLoader/>}
                                        </div>
                                    </div>
                                </div>
                                <div className='col-box'>
                                    <div className='avatar-text-box'>
                                        <div className='text'>
                                            <label>Status</label>
                                            {!influencerLoading && 
                                            <StatusFilter
                                                status={status}
                                                statusColor={statusColor}
                                                onStatusChange={(status) => this.handleStatusChange(status)}
                                            />
                                            }
                                            {influencerLoading && <TextLoader/>}
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div className='information-box information-influencer-box'>
                            <BasicInfo
                                influencerBasicInfoLoaded = {influencerLoaded}
                                influencerBasicInfoLoading = {influencerLoading}
                                name={name}
                                contacted={contacted}
                                email={email}
                                contact={contact}
                                handler={handler}
                                validIdType = {validIdType}
                                validIdNo = {validIdNo}
                                address={address}
                            />
                        </div>
                    </MDBCol>
                    <MDBCol md='5'>
                        <ActivitySide
                            onHandleManagePlatformModal={(mode, data) => this.handleOpenManagePlatformModal(mode, data)}
                            onHandleManageRecordsModal={(mode, data) => this.handleOpenManageRecordsModal(mode, data)}
                            onHandleManageNotesModal={(mode, data) => this.handleOpenManageNotesModal(mode, data)}
                            onHandleManageRatesModal={(mode, data) => this.handleOpenManageRatesModal(mode, data)}
                            onHandleOpenConfirmationModal={(type, data) => this.handleOpenConfirmDeleteModal(type, data)}
                            influencerBasicInfoLoaded = {influencerLoaded}
                            influencerBasicInfoLoading = {influencerLoading}
                        />
                    </MDBCol>
                </MDBRow>
            </div>



            <AddInfluencerDrawer 
                projectId={projectId}
                isAddInfluencerDrawer={isAddInfluencerDrawer} 
                onHandleAddInfluencerDrawer={() => this.handleAddInfluencerDrawer()} />

            <EditInfluencerDrawer 
                isEditInfluencerDrawer={isEditInfluencerDrawer} 
                onHandleEditInfluencerDrawer={() => this.handleEditInfluencerDrawer()}
                onHandleCloseEditInfluencerDrawer={() => this.handleCloseEditInfluencerDrawer()}
                influencerId={influencerId}
                projectId={id}
                data={influencer} />

            {/* Add & Edit Social Meadia Followers */}
            <ManagePlatformModal
                isOpen={isManagePlatformModalOpen}
                mode={managePlatformMode}
                data={managePlatformData}
                influencerId={influencerId}
                projectId={id}
                handleCloseManagePlatformModal={(mode) => this.handleCloseManagePlatformModal(mode)} />
            
            {/* Add & Edit Record Modal */}
            <ManageRecordModal
                isOpen={isManageRecordsModalOpen}
                mode={manageRecordMode}
                data={manageRecordData}
                influencerId={influencerId}
                projectId={id}
                handleCloseManageRecordsModal={(mode) => this.handleCloseManageRecordsModal(mode)} />

            {/* Add & Edit Notes Modal */}
            <ManageNoteModal
                isOpen={isManageNotesModalOpen}
                mode={manageNoteMode}
                data={manageNoteData}
                influencerId={influencerId}
                projectId={id}
                handleCloseManageNotesModal={(mode) => this.handleCloseManageNotesModal(mode)} />

            {/* Add & Edit Rates Modal */}
            <ManageRateModal
                isOpen={isManageRatesModalOpen}
                mode={manageRateMode}
                data={manageRateData}
                influencerId={influencerId}
                projectId={id}
                handleCloseManageRatesModal={(mode) => this.handleCloseManageRatesModal(mode)} />


            <ConfirmationDeleteModal
                isOpen={isDeleteConfirmationModalOpen}
                influencerId={influencerId}
                projectId={id}
                type={deleteConfirmationType}
                data={deleteConfirmationData}
                toggle={isDeleteConfirmationModalOpen}
                onHandleCloseConfirmationDeleteModal={() => this.handleCloseConfirmationDeleteModal()} />
        </React.Fragment>)
    }


}

function mapState(state) {
    const {projects, influencers} = state;
    const {influencerLoading, influencerLoaded, addInfluencerPlatformLoaded, getCategoryListLoaded} = influencers;
    return {projects, influencers, influencerLoading, influencerLoaded, addInfluencerPlatformLoaded, getCategoryListLoaded}
}

const actionCreators = {
    getProject: projectActions.getProject,
    getInfluencer: influencerActions.getInfluencer,
    getCategories: influencerActions.getCategories,
    updateInfluencerStatus: influencerActions.updateInfluencerStatus,
    updateInfluencerCategories: influencerActions.updateInfluencerCategories,
};

const connectedViewInfluencerInfo = connect(mapState, actionCreators)(InfluencerInfo);
export {connectedViewInfluencerInfo as InfluencerInfo};
