export const influencerConstants = {
    SUMMARY_REQUEST: 'SUMMARY_REQUEST',
    SUMMARY_SUCCESS: 'SUMMARY_SUCCESS',
    SUMMARY_FAILURE: 'SUMMARY_FAILURE',
    GET_ALL_REQUEST: 'INFLUENCER_GET_ALL_REQUEST',
    GET_ALL_SUCCESS: 'INFLUENCER_GET_ALL_SUCCESS',
    GET_ALL_FAILURE: 'INFLUENCER_GET_ALL_FAILURE',
    CREATE_REQUEST: 'INFLUENCER_CREATE_REQUEST',
    CREATE_SUCCESS: 'INFLUENCER_CREATE_SUCCESS',
    CREATE_FAILURE: 'INFLUENCER_CREATE_FAILURE',
    GET_REQUEST: 'INFLUENCER_GET_REQUEST',
    GET_SUCCESS: 'INFLUENCER_GET_SUCCESS',
    GET_FAILURE: 'INFLUENCER_GET_FAILURE',
    UPDATE_REQUEST: 'INFLUENCER_UPDATE_REQUEST',
    UPDATE_SUCCESS: 'INFLUENCER_UPDATE_SUCCESS',
    UPDATE_FAILURE: 'INFLUENCER_UPDATE_FAILURE',
    UPDATE_STATUS_REQUEST: 'INFLUENCER_UPDATE_STATUS_REQUEST',
    UPDATE_STATUS_SUCCESS: 'INFLUENCER_UPDATE_STATUS_SUCCESS',
    UPDATE_STATUS_FAILURE: 'INFLUENCER_UPDATE_STATUS_FAILURE',
    DELETE_REQUEST: 'INFLUENCER_DELETE_REQUEST',
    DELETE_SUCCESS: 'INFLUENCER_DELETE_SUCCESS',
    DELETE_FAILURE: 'INFLUENCER_DELETE_FAILURE',
    ADD_PLATFORM_REQUEST: 'INFLUENCER_ADD_PLATFORM_REQUEST',
    ADD_PLATFORM_SUCCESS: 'INFLUENCER_ADD_PLATFORM_SUCCESS',
    ADD_PLATFORM_FAILURE: 'INFLUENCER_ADD_PLATFORM_FAILURE',
    GET_ALL_PLATFORM_REQUEST: 'INFLUENCER_GET_ALL_PLATFORM_REQUEST',
    GET_ALL_PLATFORM_SUCCESS: 'INFLUENCER_GET_ALL_PLATFORM_SUCCESS',
    GET_ALL_PLATFORM_FAILURE: 'INFLUENCER_GET_ALL_PLATFORM_FAILURE',
    UPDATE_PLATFORM_REQUEST: 'INFLUENCER_UPDATE_PLATFORM_REQUEST',
    UPDATE_PLATFORM_SUCCESS: 'INFLUENCER_UPDATE_PLATFORM_SUCCESS',
    UPDATE_PLATFORM_FAILURE: 'INFLUENCER_UPDATE_PLATFORM_FAILURE',
    DELETE_PLATFORM_REQUEST: 'INFLUENCER_DELETE_PLATFORM_REQUEST',
    DELETE_PLATFORM_SUCCESS: 'INFLUENCER_DELETE_PLATFORM_SUCCESS',
    DELETE_PLATFORM_FAILURE: 'INFLUENCER_DELETE_PLATFORM_FAILURE',
    UPDATE_CLIENT_REQUEST: 'INFLUENCER_UPDATE_CLIENT_REQUEST',
    UPDATE_CLIENT_SUCCESS: 'INFLUENCER_UPDATE_CLIENT_SUCCESS',
    UPDATE_CLIENT_FAILURE: 'INFLUENCER_UPDATE_CLIENT_FAILURE',
    GET_ALL_CLIENT_REQUEST: 'INFLUENCER_GET_ALL_CLIENT_REQUEST',
    GET_ALL_CLIENT_SUCCESS: 'INFLUENCER_GET_ALL_CLIENT_SUCCESS',
    GET_ALL_CLIENT_FAILURE: 'INFLUENCER_GET_ALL_CLIENT_FAILURE',
    UPDATE_CATEGORIES_REQUEST: 'INFLUENCER_UPDATE_CATEGORIES_REQUEST',
    UPDATE_CATEGORIES_SUCCESS: 'INFLUENCER_UPDATE_CATEGORIES_SUCCESS',
    UPDATE_CATEGORIES_FAILURE: 'INFLUENCER_UPDATE_CATEGORIES_FAILURE',
    GET_ALL_CATEGORIES_REQUEST: 'INFLUENCER_GET_ALL_CATEGORIES_REQUEST',
    GET_ALL_CATEGORIES_SUCCESS: 'INFLUENCER_GET_ALL_CATEGORIES_SUCCESS',
    GET_ALL_CATEGORIES_FAILURE: 'INFLUENCER_GET_ALL_CATEGORIES_FAILURE',
    ADD_DOCUMENT_REQUEST: 'INFLUENCER_ADD_DOCUMENT_REQUEST',
    ADD_DOCUMENT_SUCCESS: 'INFLUENCER_ADD_DOCUMENT_SUCCESS',
    ADD_DOCUMENT_FAILURE: 'INFLUENCER_ADD_DOCUMENT_FAILURE',
    GET_ALL_DOCUMENTS_REQUEST: 'INFLUENCER_GET_ALL_DOCUMENTS_REQUEST',
    GET_ALL_DOCUMENTS_SUCCESS: 'INFLUENCER_GET_ALL_DOCUMENTS_SUCCESS',
    GET_ALL_DOCUMENTS_FAILURE: 'INFLUENCER_GET_ALL_DOCUMENTS_FAILURE',
    UPDATE_DOCUMENT_REQUEST: 'INFLUENCER_UPDATE_DOCUMENT_REQUEST',
    UPDATE_DOCUMENT_SUCCESS: 'INFLUENCER_UPDATE_DOCUMENT_SUCCESS',
    UPDATE_DOCUMENT_FAILURE: 'INFLUENCER_UPDATE_DOCUMENT_FAILURE',
    DELETE_DOCUMENT_REQUEST: 'INFLUENCER_DELETE_DOCUMENT_REQUEST',
    DELETE_DOCUMENT_SUCCESS: 'INFLUENCER_DELETE_DOCUMENT_SUCCESS',
    DELETE_DOCUMENT_FAILURE: 'INFLUENCER_DELETE_DOCUMENT_FAILURE',
    ADD_RATE_REQUEST: 'INFLUENCER_ADD_RATE_REQUEST',
    ADD_RATE_SUCCESS: 'INFLUENCER_ADD_RATE_SUCCESS',
    ADD_RATE_FAILURE: 'INFLUENCER_ADD_RATE_FAILURE',
    GET_ALL_RATE_REQUEST: 'INFLUENCER_GET_ALL_RATE_REQUEST',
    GET_ALL_RATE_SUCCESS: 'INFLUENCER_GET_ALL_RATE_SUCCESS',
    GET_ALL_RATE_FAILURE: 'INFLUENCER_GET_ALL_RATE_FAILURE',
    UPDATE_RATE_REQUEST: 'INFLUENCER_UPDATE_RATE_REQUEST',
    UPDATE_RATE_SUCCESS: 'INFLUENCER_UPDATE_RATE_SUCCESS',
    UPDATE_RATE_FAILURE: 'INFLUENCER_UPDATE_RATE_FAILURE',
    DELETE_RATE_REQUEST: 'INFLUENCER_DELETE_RATE_REQUEST',
    DELETE_RATE_SUCCESS: 'INFLUENCER_DELETE_RATE_SUCCESS',
    DELETE_RATE_FAILURE: 'INFLUENCER_DELETE_RATE_FAILURE',
    ADD_NOTE_REQUEST: 'INFLUENCER_ADD_NOTE_REQUEST',
    ADD_NOTE_SUCCESS: 'INFLUENCER_ADD_NOTE_SUCCESS',
    ADD_NOTE_FAILURE: 'INFLUENCER_ADD_NOTE_FAILURE',
    GET_ALL_NOTES_REQUEST: 'INFLUENCER_GET_ALL_NOTES_REQUEST',
    GET_ALL_NOTES_SUCCESS: 'INFLUENCER_GET_ALL_NOTES_SUCCESS',
    GET_ALL_NOTES_FAILURE: 'INFLUENCER_GET_ALL_NOTES_FAILURE',
    UPDATE_NOTE_REQUEST: 'INFLUENCER_UPDATE_NOTE_REQUEST',
    UPDATE_NOTE_SUCCESS: 'INFLUENCER_UPDATE_NOTE_SUCCESS',
    UPDATE_NOTE_FAILURE: 'INFLUENCER_UPDATE_NOTE_FAILURE',
    DELETE_NOTE_REQUEST: 'INFLUENCER_DELETE_NOTE_REQUEST',
    DELETE_NOTE_SUCCESS: 'INFLUENCER_DELETE_NOTE_SUCCESS',
    DELETE_NOTE_FAILURE: 'INFLUENCER_DELETE_NOTE_FAILURE',
    GET_ALL_ACTIVITIES_REQUEST:'GET_ALL_ACTIVITIES_REQUEST',
    GET_ALL_ACTIVITIES_SUCCESS:'GET_ALL_ACTIVITIES_SUCCESS',
    GET_ALL_ACTIVITIES_FAILURE:'GET_ALL_ACTIVITIES_FAILURE',
    GET_CLIENT_LIST_REQUEST: 'INFLUENCER_GET_CLIENT_LIST_REQUEST',
    GET_CLIENT_LIST_SUCCESS: 'INFLUENCER_GET_CLIENT_LIST_SUCCESS',
    GET_CLIENT_LIST_FAILURE: 'INFLUENCER_GET_CLIENT_LIST_FAILURE',
    GET_PLATFORM_TYPE_REQUEST: 'INFLUENCER_GET_PLATFORM_TYPE_REQUEST',
    GET_PLATFORM_TYPE_SUCCESS: 'INFLUENCER_GET_PLATFORM_TYPE_SUCCESS',
    GET_PLATFORM_TYPE_FAILURE: 'INFLUENCER_GET_PLATFORM_TYPE_FAILURE',
    CATEGORY_LIST_REQUEST: 'INFLUENCER_CATEGORY_LIST_REQUEST',
    CATEGORY_LIST_SUCCESS: 'INFLUENCER_CATEGORY_LIST_SUCCESS',
    CATEGORY_LIST_FAILURE: 'INFLUENCER_CATEGORY_LIST_FAILURE',
}