import React, {Component, useState} from 'react'
import { MDBContainer, MDBRow, MDBCol, MDBModal, MDBModalHeader, MDBModalBody } from 'mdbreact'
import {influencerActions} from "_actions";
import {connect} from "react-redux";
import { EditorState } from 'draft-js';
import { convertToHTML } from 'draft-convert';
import DOMPurify from 'dompurify';

class ManageRateModal extends Component {

    constructor(props) {
        super(props);

        this.state = {
            content: '',
            editorState: EditorState.createEmpty(),
            isCurrencyError: false,
            isMinRateError: false,
            isMaxRateError: false,
            isDescriptionError: false,
            toggleTabState: 1,
        }

        this.currencyInput = React.createRef();
        this.minRateInput = React.createRef();
        this.maxRateInput = React.createRef();
        this.descriptionInput = React.createRef();
    }

    handleCloseManageRatesModal = (mode) => {
        this.props.handleCloseManageRatesModal(mode);
        this.setCurrency();
        this.setMinRate();
        this.setMaxRate();
        this.setDescription();
        this.handleClearInput();
    }

    setCurrency (value) {
        this.setState({isCurrencyError: false});
    }
    setMinRate (value) {
        this.setState({isMinRateError: false});
    }
    setMaxRate (value) {
        this.setState({isMaxRateError: false});
    }
    setDescription (value) {
        this.setState({isDescriptionError: false});
    }

    isFieldValid() {

        let isValid = true;
    
        const currency = this.currencyInput.current.value;
        const minRate = this.minRateInput.current.value;
        const maxRate = this.maxRateInput.current.value;
        const description = this.descriptionInput.current.value;
    
        if(currency === '' ) {
          this.setState({isCurrencyError: true});
          isValid = false;
        }
        if(minRate === '' ) {
          this.setState({isMinRateError: true});
          isValid = false;
        }
        if(maxRate === '' ) {
          this.setState({isMaxRateError: true});
          isValid = false;
        }
        if(description === '' ) {
          this.setState({isDescriptionError: true});
          isValid = false;
        }
    
        return isValid;
    }

    handleManageRate() {

        const { mode } = this.props;
    
        let isEdit = mode !== 'add';
    
        const isValue = this.isFieldValid();
    
    
        if(isValue) {
          if(isEdit) {
            this.handleEditInfluencerRate();
          } else {
            this.handleAddInfluencerRate();
          }
        }
    }

    handleAddInfluencerRate () {
        const {influencerId, projectId, mode, addInfluencerRate} = this.props;

        const currency = this.currencyInput.current.value;
        const minRate = this.minRateInput.current.value;
        const maxRate = this.maxRateInput.current.value;
        const description = this.descriptionInput.current.value;
        // const label = this.labelInput.current.value;

        const rate = {
            'currency': currency,
            'min_rate': minRate,
            'max_rate': maxRate,
            'description': description,
            // 'label': label
        }

        console.log('rate: ', rate)

        addInfluencerRate(projectId, influencerId, rate);
        
        this.handleCloseManageRatesModal(mode);

        this.setState({
            content: '',
        })
    }

    handleEditInfluencerRate () {
        const {influencerId, projectId, mode, updateInfluencerRate, data} = this.props;

        const currency = this.currencyInput.current.value;
        const minRate = this.minRateInput.current.value;
        const maxRate = this.maxRateInput.current.value;
        const description = this.descriptionInput.current.value;

        const rate = {
            'currency': currency,
            'min_rate': minRate,
            'max_rate': maxRate,
            'description': description
        }

        console.log('update rat: ', rate)
        updateInfluencerRate(projectId, influencerId, data.id, rate);
        
        this.handleCloseManageRatesModal(mode);

        this.setState({
            content: '',
        })
    }

    toggleTab = (index) => {
        this.setState({toggleTabState: index});
    }

    handleClearInput = () => {
        // Clear the current.value of the ref
        const {mode, data} = this.props;

        if (mode === 'add') {

            this.currencyInput.current.value = '';
            this.minRateInput.current.value = '';
            this.maxRateInput.current.value = '';
            this.descriptionInput.current.value = '';
        }

        if (mode === 'edit') {
            this.currencyInput.current.value = data.currency;
            this.minRateInput.current.value = data.minimum;
            this.maxRateInput.current.value = data.maximum;
            this.descriptionInput.current.value = data.description;
        }
        
      }

    componentDidUpdate(prevProps) {
        // Check if the props or state have changed
        if (prevProps.mode !== this.props.mode) {
            console.log('previous.. ' + this.prevProps)
            console.log('current.. ' + this.props)
          // Perform the necessary update
          if (this.props.mode === 'add') {
            this.currencyInput.current.value = '';
            this.minRateInput.current.value = '';
            this.maxRateInput.current.value = '';
            this.descriptionInput.current.value = ''; }

          if (this.props.mode === 'edit') {
                this.currencyInput.current.value = this.props.data.currency;
                this.minRateInput.current.value = this.props.data.minimum;
                this.maxRateInput.current.value = this.props.data.maximum;
                this.descriptionInput.current.value = this.props.data.description; }
                
         }
    }
    

    render() {
        const { isOpen, mode, data } = this.props;
        const { isCurrencyError, isMinRateError, isMaxRateError, isDescriptionError } = this.state;

        let isEdit = mode !== 'add';
        let activeClass = isOpen ? 'active' : '';


        return (
            <React.Fragment>
                <div className={`drawer-overlay ${activeClass}`} onClick={(mode) => this.handleCloseManageRatesModal(mode)}></div>
                <div className={`side-drawer-main ${activeClass}`}>
                    <div className='side-drawer-wrapper'>
                        <div className='side-drawer-title'>
                            <h3>{mode.toUpperCase()} RATE</h3>
                            <span className='side-drawer-close' onClick={(mode) => this.handleCloseManageRatesModal(mode)}><i className="icon-lms icon-close"></i></span>
                        </div>
                        <div className='side-drawer-tabs'>
                            <div className='side-drawer-tabs-wrapper'>
                                <div className='control-tabs-box'>
                                    <div className={this.state.toggleTabState === 1 ? 'tabs-btn active' : 'tabs-btn'} onClick={() => this.toggleTab(1)}>
                                        <p>
                                            Rate Information
                                        </p>
                                    </div>
                                </div>
                                <div className='content-tabs-box'>
                                    <div className={this.state.toggleTabState === 1 ? 'tabs-content active' : 'tabs-content'}>
                                        <div className='forms-tab'>
                                            <MDBRow>
                                                <MDBCol className='col-12 form-group-box'>
                                                    <div className='input-group-box full-box-'>
                                                        <div className={isCurrencyError ? `field-box error-field` : `field-box`}>
                                                            <label htmlFor={'value'}>Currency <small>*</small></label>
                                                            <input ref={this.currencyInput} type='text' id={'value'}  name={'value'} defaultValue={isEdit ? data.currency : ''} onChange={(e) => this.setCurrency(e.target.value)} />
                                                            {isCurrencyError &&<p className='error-field'>This field is required</p>}
                                                        </div>
                                                        <div className={isMinRateError ? `field-box error-field` : `field-box`}>
                                                            <label htmlFor={'value'}>Minimum Rate <small>*</small></label>
                                                            <input ref={this.minRateInput} type='number' id={'value'}  name={'value'} defaultValue={isEdit ? data.minRate : ''} onChange={(e) => this.setMinRate(e.target.value)} />
                                                            {isMinRateError &&<p className='error-field'>This field is required</p>}
                                                        </div>
                                                        <div className={isMaxRateError ? `field-box error-field` : `field-box`}>
                                                            <label htmlFor={'value'}>Maximum Rate <small>*</small></label>
                                                            <input ref={this.maxRateInput} type='number' id={'value'}  name={'value'} defaultValue={isEdit ? data.maxRate : ''} onChange={(e) => this.setMaxRate(e.target.value)} />
                                                            {isMaxRateError &&<p className='error-field'>This field is required</p>}
                                                        </div>
                                                        <div className={isDescriptionError ? `field-box error-field` : `field-box`}>
                                                            <label htmlFor={'value'}>Description <small>*</small></label>
                                                            <textarea ref={this.descriptionInput} id={'value'}  name={'value'} defaultValue={isEdit ? data.description : ''} onChange={(e) => this.setDescription(e.target.value)} />
                                                            {isDescriptionError &&<p className='error-field'>This field is required</p>}
                                                        </div>
                                                    </div>
                                                </MDBCol>
                                            </MDBRow>
                                        </div>
                                    </div>
                                </div>
                                <div className='tab-form-btn-group'>
                                    <MDBRow>
                                        <MDBCol className='text-right'>
                                            <span className='drawer-btn cancel-btn' onClick={(mode) => this.handleCloseManageRatesModal(mode)}>
                                                Cancel
                                            </span>
                                            <span className='drawer-btn save-btn' onClick={() => this.handleManageRate()}>
                                                {mode === 'add' ? `Add Rate` : `Update Rate`}
                                            </span>
                                        </MDBCol>
                                    </MDBRow>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                {/* <MDBContainer className='modal-container modal-form-container modal-rate'>
                    <MDBRow>
                        <MDBCol>
                            <MDBModal isOpen={isOpen} centered tabIndex='-1'>
                                <MDBModalHeader className='confirmation-modal-header text-uppercase p-2'><span className='pl-1 mx-2 letter-spacing-1 font-weight-600 font-color-gray font-size-13'>{mode.toUpperCase()} RATE</span></MDBModalHeader>
                                <MDBModalBody>
                                    <MDBRow className='form-group-row'>
                                        <MDBCol className={isCurrencyError ? `col-12 has-error-input` : `col-12`}>
                                            <label htmlFor={'value'}>Currency</label>
                                            <input ref={this.currencyInput} type='text' id={'value'}  name={'value'} defaultValue={isEdit ? data.currency : ''} onChange={(e) => this.setCurrency(e.target.value)} />
                                            {isCurrencyError &&<span>This field is required</span>}
                                        </MDBCol>
                                        <MDBCol className={isMinRateError ? `col-12 has-error-input` : `col-12`}>
                                            <label htmlFor={'value'}>Minimum Rate</label>
                                            <input ref={this.minRateInput} type='text' id={'value'}  name={'value'} defaultValue={isEdit ? data.minRate : ''} onChange={(e) => this.setMinRate(e.target.value)} />
                                            {isMinRateError &&<span>This field is required</span>}
                                        </MDBCol>
                                        <MDBCol className={isMaxRateError ? `col-12 has-error-input` : `col-12`}>
                                            <label htmlFor={'value'}>Maximum Rate</label>
                                            <input ref={this.maxRateInput} type='text' id={'value'}  name={'value'} defaultValue={isEdit ? data.maxRate : ''} onChange={(e) => this.setMaxRate(e.target.value)} />
                                            {isMaxRateError &&<span>This field is required</span>}
                                        </MDBCol>
                                        <MDBCol className={isDescriptionError ? `col-12 has-error-input` : `col-12`}>
                                            <label htmlFor={'value'}>Description</label>
                                            <textarea ref={this.descriptionInput} id={'value'}  name={'value'} defaultValue={isEdit ? data.description : ''} onChange={(e) => this.setDescription(e.target.value)} />
                                            {isDescriptionError &&<span>This field is required</span>}
                                        </MDBCol>
                                    </MDBRow>
                                    <MDBRow>
                                        <MDBCol className='text-center'>
                                            <button className='modal-btn save-btn-modal' onClick={() => this.handleManageRate()}>
                                                {mode === 'add' ? `Add Rate` : `Update Rate`}
                                            </button>
                                            <button className='modal-btn cancel-btn-modal' onClick={(mode) => this.handleCloseManageRatesModal(mode)}>
                                                Cancel
                                            </button>
                                        </MDBCol>
                                    </MDBRow>
                                </MDBModalBody>
                            </MDBModal>
                        </MDBCol>
                    </MDBRow>
                </MDBContainer> */}
            </React.Fragment>
        )
    }

}

function mapState(state) {
    const { influencers } = state;
    return { influencers }
}

const actionCreators = {
    addInfluencerRate : influencerActions.addInfluencerRate,
    updateInfluencerRate : influencerActions.updateInfluencerRate,
};

const connectedViewManageRateModal = connect(mapState, actionCreators)(ManageRateModal);
export { connectedViewManageRateModal as ManageRateModal };