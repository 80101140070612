import React from 'react';
import { connect } from "react-redux";
import { formActions } from '_actions';

import { MDBCol, MDBRow, MDBIcon, MDBBtn } from 'mdbreact';
import {Button, InputSelect} from "blackbox-react";
import { css, cx } from "emotion";

import { FormValidation } from '_components/FormValidation';
import DeleteConfirmationModal from '_components/Modal/DeleteConfirmationModal'

import addIcon from '_assets/images/plus.png';

class EditFormFieldUpdate extends React.Component {
	constructor(props) {
		super(props);

		this.state = {
			fieldData: this.props.fieldData,
			fieldOptions: this.props.fieldData.option,
			activeOption: {},
			fieldToDelete: '',

			formErrors: {
				label: ''
			},
			fieldLabelValid: true,
			formValid: true,
		};

		this.handleFieldUpdate = this.handleFieldUpdate.bind(this);
		this.handleAddFieldOptions = this.handleAddFieldOptions.bind(this);
		this.handleMapOptions = this.handleMapOptions.bind(this);
		this.handleUpdateFieldOption = this.handleUpdateFieldOption.bind(this);
	}

	handleToggle = () =>{
		let { modalOpen } = this.state;
		this.setState({modalOpen: !modalOpen})
	};

	handlePassData = (data) => {
		this.setState({
			fieldToDelete: data
		})
	};

	approveModal = (fieldToDelete) => {
		const projectId = this.props.projectId;
		const formId = this.props.formId;
		const formGroupId = this.props.formGroupId;
		let fieldId = fieldToDelete.id;

		const deleteFieldData = {
			label: fieldToDelete.label,
			placeholder: fieldToDelete.placeholder,
			typeId: fieldToDelete.typeId
		};

		this.props.deleteField(projectId, formId, formGroupId, fieldId, deleteFieldData);

		this.setState({
			formGroupData: {},
			fieldToDelete: {}
		});

		this.props.handleFormUpdate();
		this.props.getBlankSelection();
	};

	renderFieldType = (typeId) => {
		switch (typeId) {
			case 1:
				return 'Text Input';
			case 2:
				return 'Multiline Text Input';
			case 3:
				return 'Single Choice';
			case 4:
				return 'Multiple Choice';
			case 5:
				return 'Dropdown Selection';
			case 6:
				return 'Switch';
			case 7:
				return 'Slider';
			case 8:
				return 'Date and Time';
			case 9:
				return 'API Dropdown Selection';
			default:
				return null;
		}
	};

	// Toggle boolean value (true or false)
	handleToggleUpdate = (event, value) => {
		const projectId = this.props.projectId;
		const formId = this.props.formId;
		const formGroupId = this.props.formGroupId;
		let { name } = event.target;
		const { fieldData } = this.state;

		if(value === "on"){
			value = true;
		} else if (value === "off"){
			value = false;
		}

		this.setState({
			fieldData: {
				...fieldData,
				[name]: !value
			}
		}, () => {
			this.props.handleFormUpdate();
			this.props.updateField(projectId, formId, formGroupId, fieldData.id, this.state.fieldData);
		});
	};

	// Toggle boolean value (0 or 1)
	handleToggleNumUpdate = (event, value) => {
		const projectId = this.props.projectId;
		const formId = this.props.formId;
		const formGroupId = this.props.formGroupId;
		let { name } = event.target;
		const { fieldData } = this.state;

		this.setState({
			fieldData: {
				...fieldData,
				[name]: (value === 0) ? 1 : 0
			}
		}, () => {
			this.props.handleFormUpdate();
			this.props.updateField(projectId, formId, formGroupId, fieldData.id, this.state.fieldData);
		});
	};

	validateField(fieldName, value) {
		let fieldValidationErrors = this.state.formErrors;
		let fieldLabelValid = this.state.fieldLabelValid;

		let isAllWhitespace = (value.replace(/(?!^ +$)^.+$/gm, "") !== '');

		switch(fieldName) {
			case 'label':
				if (value === "" || isAllWhitespace) { // check if empty
					fieldLabelValid = false;
					fieldValidationErrors.label = 'Please enter a label for your field.';
				} else {
					fieldLabelValid = (value.length >= 0); // check if valid
					fieldValidationErrors.label = fieldLabelValid ? '': 'Please enter a label for your field.';
				}
				break;
			default:
				break;
		}

		this.setState({
			formErrors: fieldValidationErrors,
			fieldLabelValid: fieldLabelValid
		}, this.validateForm);
	}

	validateForm() {
		this.setState({
			formValid: this.state.fieldLabelValid
		});
	}

	handleFieldUpdate = (event) => {
		const projectId = this.props.projectId;
		const formId = this.props.formId;
		const formGroupId = this.props.formGroupId;
		let { name, value } = event.target;
		const { fieldData } = this.state;

		// Prevent negative values on textarea max character limit
		if(name === 'character_limit'){
			value = Math.abs(value);
			value = value.toString().replace('-','');

			event.currentTarget.value = parseInt(value);
		}

		this.setState({
			fieldData: {
				...fieldData,
				[name]: value
			}
		}, () => {
			if(name === 'label'){
				this.validateField(name, value);
			}

			if(this.state.formValid){
				this.props.handleFormUpdate();
				this.props.updateField(projectId, formId, formGroupId, fieldData.id, this.state.fieldData);
			}
		});
	};

	handleSelectUpdate = (name, value) => {
		const projectId = this.props.projectId;
		const formId = this.props.formId;
		const formGroupId = this.props.formGroupId;
		const { fieldData } = this.state;

		this.setState({
			fieldData: {
				...fieldData,
				[name]: value
			}
		}, () => {
			this.props.handleFormUpdate();
			this.props.updateField(projectId, formId, formGroupId, fieldData.id, this.state.fieldData);
		});
	};

	handleAddFieldOptions = (data) => {
		const projectId = this.props.projectId;
		const formId = this.props.formId;
		const formGroupId = this.props.formGroupId;
		const fieldData = this.state.fieldData;
		const fieldId = fieldData.id;
		const fieldOptions = fieldData.option;

		sessionStorage.removeItem('option');

		let blankFieldOption = { option: 'Option'};
		this.props.createFieldOption(projectId, formId, formGroupId, fieldId, blankFieldOption);

		//this.props.handleFormUpdate();

		setTimeout(
			function () {
				let activeOption = JSON.parse(sessionStorage.getItem('option'));

				this.setState({
					fieldData: {
						...fieldData,
						option:
							[...fieldOptions,
								activeOption
							]
					},
					activeOption: activeOption
				});
			}
				.bind(this),
			2000
		);




	};

	handleUpdateFieldOption = (event, option) => {
		const projectId = this.props.projectId;
		const formId = this.props.formId;
		const formGroupId = this.props.formGroupId;
		const fieldData = this.state.fieldData;
		const fieldId = fieldData.id;
		let value = event.target.value;

		let updatedOption = { 'option': value };
		let localOption = JSON.parse(sessionStorage.getItem('option'));
		let optionId = option ? option.id : localOption.id;

		if(optionId !== 'undefined'){
			if(typeof option !== 'undefined'){
				this.props.updateFieldOption(projectId, formId, formGroupId, fieldId, optionId, updatedOption);
			}
		}

		this.props.handleFormUpdate();

	};

	handleRemoveFieldOption = (event, option) => {
		const projectId = this.props.projectId;
		const formId = this.props.formId;
		const formGroupId = this.props.formGroupId;
		const fieldData = this.state.fieldData;
		const fieldId = fieldData.id;
		const fieldOptions = fieldData.option;
		let updatedOptions = [];

		let localOption = JSON.parse(sessionStorage.getItem('option'));

		let optionData = option ? option  : localOption;
		let optionId = option ? option.id : localOption.id;

		if(optionId !== 'undefined'){
			if(typeof option !== 'undefined'){
				this.props.deleteFieldOption(projectId, formId, formGroupId, fieldId, optionId, optionData);
				updatedOptions = fieldOptions.filter(x => {
					return x.id !== option.id;
				});



				//this.props.handleFormUpdate();
				this.setState({
					fieldData: {
						...fieldData,
						option: []
					}
				}, () => {
					this.setState({
						fieldData: {
							...fieldData,
							option: updatedOptions
						}
					}, () => {
						//this.props.handleFormUpdate();
					});
				});
			}
		}
	};

	handleSetActiveOption = (event, option) => {
		const projectId = this.props.projectId;
		const formId = this.props.formId;
		const formGroupId = this.props.formGroupId;
		const fieldData = this.state.fieldData;
		const fieldId = fieldData.id;

		this.setState({
			activeOption: option
		});

		if(option && typeof option !== 'undefined'){
			this.props.findFieldOption(projectId, formId, formGroupId, fieldId, option.id);
		}
	};

	handleMapOptions = (options) => {
		return options.map((option, index) => {
			return (
				<MDBRow key={index}>
					<MDBCol lg='11' xl='11'>
						<input
							className='border-gray mb-2 br-5 padding-3 px-2 font-size-13 font-weight-600'
							type='text'
							placeholder=''
							name={ option ? option.id || '' : ''}
							defaultValue={ option ? option.option : 'Option'}
							onClick={(event) => {
								this.handleSetActiveOption(event, option)
							}}
							onChange={(event) => {
								this.handleUpdateFieldOption(event, option)
							}}
						/>
					</MDBCol>
					<MDBCol lg='1' xl='1' className='p--0'>
						<MDBIcon
							far
							icon='times-circle'
							className='icon-remove color-red opacity-5 hand'
							onClick={(event) => {
								this.handleSetActiveOption(event, option);
								this.handleRemoveFieldOption(event, option)
							}}
						/>
					</MDBCol>
				</MDBRow>
			);
		});
	};

	handleSubmit = (data) => {
		this.props.handleFormUpdate();
		const projectId = this.props.projectId;
		const formId = this.props.formId;
		const formGroupId = this.props.formGroupId;
		this.props.updateField(projectId, formId, formGroupId, data.id, data);
	};

	renderEditFieldSettings = (fieldData) => {
		return (
			<MDBRow className='px-2'>
				<MDBCol lg='4' xl='4' className='my-auto'>
					<p className='mb-0 text-center font-size-10 font-weight-600'>Field Settings: </p>
				</MDBCol>
				<MDBCol className='px-0 my-auto'>
					<MDBRow className='my-auto font-size-11'>
						<div className='mx-2 my-auto'>
							<label className='checkboxcontainer-edit ml-0 mt-2'>
								<input
									type='checkbox'
									name='required'
									checked={fieldData.required || ''}
									onChange={(event) => { this.handleToggleUpdate(event, fieldData.required || '') }}
								/>
								<span className='checkmark'> </span>
								<span>Required</span>
							</label>
						</div>
						<div className='mx-2 my-auto'>
							<label className='checkboxcontainer-edit ml-0 mt-2'>
								<input
									type='checkbox'
									name='read_only'
									checked={fieldData.read_only || ''}
									onChange={(event) => { this.handleToggleUpdate(event, fieldData.read_only || '') }}
								/>
								<span className='checkmark'> </span>
								<span>Read Only</span>
							</label>
						</div>
						<div className='mx-2 my-auto'>
							<label className='checkboxcontainer-edit ml-0 mt-2'>
								<input
									type='checkbox'
									name='hide_label'
									checked={fieldData.hide_label || ''}
									onChange={(event) => { this.handleToggleUpdate(event, fieldData.hide_label || '') }}
								/>
								<span className='checkmark'> </span>
								<span>Hide Label</span>
							</label>
						</div>
					</MDBRow>
				</MDBCol>
			</MDBRow>
		);
	};

	renderEditFieldCommonFields = (fieldData) => {
		const { formValid } = this.state;
		//let textAreaClass = fieldData.inline_instruction ? 'visible' : 'display-none';
		return (
			<MDBRow className='m-b--0'>
				<MDBCol>
					<MDBCol className={`form-group p--0 ${this.errorClass(this.state.formErrors.label)}`}>
						<label htmlFor='label' className='mb-2 font-size-14 font-weight-600'>Label</label>
						<input
							name='label'
							type='text'
							//autoFocus={true}
							defaultValue={fieldData.label}
							className='border-gray mb-2 br-5 padding-3 px-2 font-size-13'
							placeholder='Enter label here'
							onChange={this.handleFieldUpdate}
						/>
						<div className={`form-validation__container ${(!formValid) ? 'display-block' : 'display-none'}`}>
							<FormValidation fieldName='label' formErrors={this.state.formErrors}/>
						</div>
					</MDBCol>
					{ (fieldData.type_id === 1 || fieldData.type_id === 2) &&
					<MDBCol className='p--0'>
						<label htmlFor='placeholder' className='mb-2 font-size-14 font-weight-600'>Placeholder</label>
						<input
							type='text'
							name='placeholder'
							defaultValue={fieldData.placeholder}
							className='border-gray mb-2 br-5 padding-3 px-2 font-size-13'
							placeholder='Enter placeholder here'
							onChange={this.handleFieldUpdate}
						/>
					</MDBCol>
					}

					{/*<MDBCol id='inline' className='custom-control custom-switch mb-2 mt-3'>*/}
					{/*	<MDBRow className='ml-2 mb-2'>*/}
					{/*		<div className='switch mr-2 my-auto height-20'>*/}
					{/*			<input*/}
					{/*				checked={fieldData.inline_instruction || ''}*/}
					{/*				type='checkbox'*/}
					{/*				name='inline_instruction'*/}
					{/*				className='switch-checkbox'*/}
					{/*				id='inline_instruction'*/}
					{/*				onChange={ (event) => {*/}
					{/*					this.handleToggleUpdate(event, fieldData.inline_instruction || '');*/}
					{/*				}}*/}
					{/*			/>*/}
					{/*			<label className='switch-labels' htmlFor='inline_instruction'>*/}
					{/*				<span className='switch-text' />*/}
					{/*				<span className='switch-dot' />*/}
					{/*			</label>*/}
					{/*		</div>*/}
					{/*		<label className='mb-0 mt-1 font-size-10'>Show Inline Instructions</label>*/}
					{/*	</MDBRow>*/}
					{/*	<div className={`${textAreaClass} ${'br-5 ml-1 mr-1'}`}>*/}
					{/*		<label className='mb-2 font-size-14 font-weight-600'>Inline Instructions</label>*/}
					{/*		<textarea*/}
					{/*			name='instruction'*/}
					{/*			className='py-1 px-2 font-size-12'*/}
					{/*			id='exampleFormControlTextarea1'*/}
					{/*			placeholder='Enter inline instructions here'*/}
					{/*			rows='3'*/}
					{/*			value={fieldData.instruction || 'This is a sample inline instruction about this field. Replace this with your own.'}*/}
					{/*			onChange={this.handleFieldUpdate}*/}
					{/*		/>*/}
					{/*	</div>*/}
					{/*</MDBCol>*/}
				</MDBCol>
			</MDBRow>
		);
	};

	renderEditFieldTypeFields = (fieldData) => {
		switch (fieldData.type_id) {
			case 1:
				return this.renderTextInputFields(fieldData);
			case 2:
				return this.renderTextareaFields(fieldData);
			case 3:
				return this.renderRadioButtonFields(fieldData);
			case 4:
				return this.renderCheckboxFields(fieldData);
			case 5:
				return this.renderSelectFields(fieldData);
			case 6:
				return this.renderSwitchFields(fieldData);
			case 7:
				return this.renderSliderFields(fieldData);
			case 8:
				return this.renderDatepickerFields(fieldData);
			default:
				return null;
		}
	};

	renderFieldWithOptions = (fieldData) => {
		let options = fieldData.option || '';
		let hasOptions = options.length > 0;

		return (
			<MDBCol>
				<p className='font-size-14 font-weight-600 ml-1 m-b--0'>Options</p>
				{ hasOptions &&
				<MDBCol className='pl-1 pr-3 form-field__options m-t--sm m-b--sm'>
					{this.handleMapOptions(options)}
				</MDBCol>
				}
				<MDBBtn
					onClick={(e) => {
						e.stopPropagation();
						this.handleAddFieldOptions(fieldData);
					}}
					flat
					className='p--sm dotted add-field-button add-field-background color-orange shadow-none button-presets'
				>
					<img alt='' src={addIcon} className='add-icon-width-18 mr-2'/>
					Add option
				</MDBBtn>

			</MDBCol>
		);
	};

	renderTextInputFields = (fieldData) => {
		return null;
	};

	renderTextareaFields = (fieldData) => {
		//let textAreaClass = fieldData.limited_character ? 'visible' : 'display-none';
		return (
			<MDBRow>
				<MDBCol id='inline' className='mb-2 mt-1'>
					<MDBRow className='ml-2 mb-2'>
						<div className='switch mr-2 my-auto height-20'>
							<input
								checked={fieldData.limited_character || ''}
								type='checkbox'
								name='limited_character'
								className='switch-checkbox'
								id='limited_character'
								onChange={ (event) => {
									this.handleToggleUpdate(event, fieldData.limited_character || '');
								}}
							/>
							{/*<label className='switch-labels' htmlFor='limited_character'>*/}
							{/*	<span className='switch-text' />*/}
							{/*	<span className='switch-dot' />*/}
							{/*</label>*/}
						</div>
						{/*<label className='mb-0 mt-1 ml-0 font-size-10'>Limit Number of Characters</label>*/}
					</MDBRow>
					{/*<MDBRow className={`${textAreaClass} 'ml-3 mb-2 br-5 ml-0 mt-3 mr-1'`}>*/}
					{/*	<MDBCol className='pr-3 ml-0 pl-1'>*/}
					{/*		<p className='mb-2 font-size-14 font-weight-600'>Maximum Number of Characters</p>*/}
					{/*		<input*/}
					{/*			type='number'*/}
					{/*			name='character_limit'*/}
					{/*			defaultValue={fieldData.character_limit || 0}*/}
					{/*			min={0}*/}
					{/*			className='border-gray mb-2 br-5 padding-3 px-2 font-size-13'*/}
					{/*			placeholder='Enter maximum number of characters'*/}
					{/*			onChange={this.handleFieldUpdate}*/}
					{/*		/>*/}
					{/*	</MDBCol>*/}
					{/*</MDBRow>*/}
				</MDBCol>
			</MDBRow>
		);
	};

	renderRadioButtonFields = (fieldData) => {
		return (
			<MDBRow className=''>
				{ this.renderFieldWithOptions(fieldData) }
			</MDBRow>
		);
	};

	renderCheckboxFields = (fieldData) => {
		return (
			<MDBRow className=''>
				{ this.renderFieldWithOptions(fieldData) }
			</MDBRow>
		);
	};

	renderSelectFields = (fieldData) => {
		return (
			<MDBRow className=''>
				{ this.renderFieldWithOptions(fieldData) }
			</MDBRow>
		);
	};

	renderSwitchFields = (fieldData) => {
		const switchCss = cx(
			css`
        &:before {
          content: '${fieldData.on_label || 'ON'}';
        }
           
        &:after{
          content: '${fieldData.off_label || 'OFF'}';
        }
      `
		);
		return (
			<MDBRow className='m-b--sm'>
				<MDBCol>
					<MDBCol className='pl-1 pr-3'>
						<p className='mb-2 font-size-14 font-weight-600'>ON Label</p>
						<input
							name='on_label'
							type='text'
							defaultValue={fieldData.on_label || 'ON'}
							className='border-gray mb-2 br-5 padding-3 px-2 font-size-13'
							placeholder='Enter ON label here'
							onChange={this.handleFieldUpdate}
						/>
					</MDBCol>
					<MDBCol className='pl-1 pr-3'>
						<p className='mb-2 font-size-14 font-weight-600'>OFF Label</p>
						<input
							type='text'
							name='off_label'
							defaultValue={fieldData.off_label || 'OFF'}
							className='border-gray mb-2 br-5 padding-3 px-2 font-size-13'
							placeholder='Enter placeholder here'
							onChange={this.handleFieldUpdate}
						/>
					</MDBCol>
					<MDBCol id='inline' className='pl-1 pr-3 custom-control custom-switch mb-2 mt-1'>
						<p className='mb-2 font-size-14 font-weight-600'>Default Switch</p>
						<MDBRow className='ml-2 mb-2'>
							<div className='switch mr-2 my-auto height-20'>
								<input
									checked={fieldData.default_value !== 0}
									type='checkbox'
									name='default_value'
									className='switch-checkbox'
									id='default_value'
									onChange={ (event) => {
										this.handleToggleNumUpdate(event, fieldData.default_value || 0);
									}}
								/>
								<label className='switch-labels' htmlFor='default_value'>
									<span className={`switch-text ${switchCss}`} />
									<span className='switch-dot' />
								</label>
							</div>
							<label className='mb-0 mt-1 font-size-10'>Set if ON or OFF by default</label>
						</MDBRow>
					</MDBCol>
				</MDBCol>
			</MDBRow>
		);
	};

	renderSliderFields = (fieldData) => {
		return (
			<MDBRow className='m-b--sm'>
				<MDBCol>
					<MDBCol className='pl-1 pr-3'>
						<p className='mb-2 font-size-14 font-weight-600'>Minimum Value</p>
						<input
							name='min_value'
							type='number'
							defaultValue={fieldData.min_value || 0}
							min={0}
							className='border-gray mb-2 br-5 padding-3 px-2 font-size-13'
							placeholder='Enter minimum value here'
							onChange={this.handleFieldUpdate}
						/>
					</MDBCol>
					<MDBCol className='pl-1 pr-3'>
						<p className='mb-2 font-size-14 font-weight-600'>Maximum Value</p>
						<input
							type='number'
							name='max_value'
							defaultValue={fieldData.max_value || 100}
							min={0}
							className='border-gray mb-2 br-5 padding-3 px-2 font-size-13'
							placeholder='Enter maximum value here'
							onChange={this.handleFieldUpdate}
						/>
					</MDBCol>
					<MDBCol className='pl-1 pr-3'>
						<p className='mb-2 font-size-14 font-weight-600'>Default Value</p>
						<input
							type='number'
							name='default_value'
							min={0}
							defaultValue={fieldData.default_value || 0}
							className='border-gray mb-2 br-5 padding-3 px-2 font-size-13'
							placeholder='Enter default value here'
							onChange={this.handleFieldUpdate}
						/>
					</MDBCol>
					<MDBCol className='pl-1 pr-3'>
						<p className='mb-2 font-size-14 font-weight-600'>Unit</p>
						<input
							name='on_label'
							type='text'
							defaultValue={fieldData.on_label || ''}
							className='border-gray mb-2 br-5 padding-3 px-2 font-size-13'
							placeholder='e.g. cm, kg, PHP'
							onChange={this.handleFieldUpdate}
						/>
					</MDBCol>
				</MDBCol>
			</MDBRow>
		);
	};

	renderDatepickerFields = (fieldData) => {
		let textAreaClass = fieldData.time_input ? 'visible' : 'display-none';
		let dateFormatOptions = [
			{
				id: 1,
				value: 'MM/dd/yyyy',
				label: 'MM/DD/YYYY'
			},
			{
				id: 2,
				value: 'dd/MM/yyyy',
				label: 'DD/MM/YYYY'
			},
			{
				id: 3,
				value: 'MMMM d, yyyy',
				label: 'Month DD, YYYY'
			},
			{
				id: 4,
				value: 'd MMMM yyyy',
				label: 'DD Month YYYY'
			},
		];
		let timeFormatOptions = [
			{
				id: 1,
				value: 'hh:mm aa',
				label: '12-hr'
			},
			{
				id: 2,
				value: 'HH:mm aa',
				label: '24-hr'
			}
		];
		return (
			<MDBRow className='m-b--sm'>
				<MDBCol lg='12'>
					<MDBCol className='pl-1 pr-3'>
						<p className='mb-2 font-size-14 font-weight-600'>Date Format</p>
						<InputSelect
							name='date_format'
							defaultValue={ fieldData.date_format || 'MMMM d, yyyy' }
							onChange={(value) => {this.handleSelectUpdate('date_format', value)}}
							options={dateFormatOptions}
						/>
					</MDBCol>
				</MDBCol>

				<MDBCol lg='12' id='inline' className='mb-2 mt-1'>
					<MDBRow className='ml-2 mb-2'>
						<div className='switch mr-2 my-auto height-20'>
							<input
								checked={fieldData.time_input || ''}
								type='checkbox'
								name='time_input'
								className='switch-checkbox'
								id='time_input'
								onChange={ (event) => {
									this.handleToggleUpdate(event, fieldData.time_input || '');
								}}
							/>
							<label className='switch-labels' htmlFor='time_input'>
								<span className='switch-text' />
								<span className='switch-dot' />
							</label>
						</div>
						<label className='mb-0 mt-1 ml-0 font-size-10'>Enable Time Input?</label>
					</MDBRow>
					<MDBRow className={`${textAreaClass} 'ml-3 mb-2 br-5 ml-0 mt-3 mr-1'`}>
						<MDBCol className='ml-0 pl-1 pr-3 mr-3'>
							<p className='mb-2 font-size-14 font-weight-600'>Time Format</p>
							<InputSelect
								name='time_format'
								defaultValue={ fieldData.time_format || 'hh:mm aa' }
								onChange={(value) => {this.handleSelectUpdate('time_format', value)}}
								options={timeFormatOptions}
							/>
						</MDBCol>
					</MDBRow>
				</MDBCol>
			</MDBRow>
		);
	};

	errorClass(error) {
		return(error.length === 0 ? 'is-passed' : 'is-failed');
	}

	render() {
		const fieldData = this.state.fieldData;

		const { deleted } = this.props;
		if(deleted){
			this.props.getBlankSelection();
		}

		return (
			<div>
				<MDBRow className='justify-content-between m-t--md m-l--md m-r--md'>
					<p className='font-size-12 m-b--0 font-weight-600'>EDIT FIELD</p>
					<p className='font-size-12 m-b--0'>
						TYPE: <span>{this.renderFieldType(fieldData.type_id)}</span>
					</p>
				</MDBRow>
				<hr className='m-t--md m-b--sm' />
				{ this.renderEditFieldSettings(fieldData) }
				<hr className='m-t--sm m-b--md' />
				<div className='form-field-settings p-l--md p-r--md'>
					{ this.renderEditFieldCommonFields(fieldData) }
					{ this.renderEditFieldTypeFields(fieldData) }
				</div>
				<MDBCol className='position-bottom'>
					<MDBRow className='m--0'>
						<MDBCol lg='5' xl='5' className='text-left m-l--n20 m-b--sm'>
							<Button
								flat='true'
								type="submit"
								background='transparent'
								foreground='red'
								size={-2}
								onClick={(event) => { event.preventDefault(); this.handleToggle(); this.handlePassData(fieldData) }}>
								<MDBIcon icon='trash-alt' className='mr-2' />
								<u>Delete Field</u>
							</Button>
							<DeleteConfirmationModal
								toggle={this.handleToggle}
								modalOpen={this.state.modalOpen}
								statusToDelete={this.state.fieldToDelete}
								onApprove={this.approveModal}
								target={fieldData.label || ''}
							/>
						</MDBCol>
					</MDBRow>
				</MDBCol>
			</div>
		);
	}
}

function mapState(state) {
	const { creating, forms, deleting, deleted, field_option } = state.forms;
	return { creating, forms, deleting, deleted, field_option };
}

const actionCreators = {
	createField: formActions.createField,
	updateField: formActions.updateField,
	createFieldOption: formActions.createFieldOption,
	updateFieldOption: formActions.updateFieldOption,
	getAllFields: formActions.getAllFields,
	findField: formActions.findField,
	getAllFieldOption: formActions.getAllFieldOption,
	findFieldOption: formActions.findFieldOption,
	deleteFieldOption: formActions.deleteFieldOption,
	deleteField: formActions.deleteField
};

const connectedEditFormFieldUpdate = connect(mapState, actionCreators)(EditFormFieldUpdate);
export { connectedEditFormFieldUpdate as EditFormFieldUpdate };
