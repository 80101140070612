import React, { useState } from "react";
import { Link } from "react-router-dom";

const SideBarProjectGroup = ({name, project, active, onHandleToggleSideBar}) => {

    const [projectSize, setProjectSize] = useState(project.length);
    const [openSubMenuIndex, setOpenSubMenuIndex] = useState(false);


    const handleToggleSubMenu = () => {

        setOpenSubMenuIndex(!openSubMenuIndex);
    };

    const handleToggleSideBar = (index) => {
        return onHandleToggleSideBar(index)
    };

    if (projectSize > 1) {
        return(
            <li className={openSubMenuIndex === true ? 'has-menu open' : 'has-menu'} onClick={() => handleToggleSubMenu()}>
                {name}
                <ul className={openSubMenuIndex === true ? 'sub-menu open' : 'sub-menu'}>
                    <React.Fragment>
                        {project.map((p, i) => {
                            if (p.info.name.includes('Influencer')) {
                                return <li key={i}><Link onClick={() => handleToggleSideBar(p.id)} to={`/project/${p.id}/influencers`} className={active === p.id ? `active` : ''}>{p.info.name}</Link></li>

                            }
                            return <li key={i}><Link onClick={() => handleToggleSideBar(p.id)} to={`/project/${p.id}/leads`} className={active === p.id ? `active` : ''}>{p.info.name}</Link></li>
                            })}

                    </React.Fragment>
                </ul>
            </li>
        )
    }

    return(
        <li>
            <Link onClick={() => handleToggleSideBar(project[0].id)} to={`/project/${project[0].id}/leads`} className={active === project[0].id ? `active` : ''} >{project[0].info.name}</Link>
        </li>
    )

}

export default SideBarProjectGroup;