import React from 'react';
import { connect } from "react-redux";
import { Link } from 'react-router-dom';

import { projectActions } from '_actions';
import { utilitiesService as utility } from '_services';

import { MDBContainer, MDBRow, MDBCol, MDBIcon, MDBBtn } from 'mdbreact';
import { LeadStatusList } from '_pages/Projects/LeadStatusList'
import { CreateLeadStatusModal } from '_pages/Projects/components/CreateLeadStatusModal'
import { EditLeadStatusModal } from '_pages/Projects/components/EditLeadStatusModal'
import InputForm from '_components/InputForm'

import avatar from '_assets/images/avatar-fem.png'
import {Button, ButtonGroup, Icon} from "blackbox-react";

class ManageLeadStatus extends React.Component {
  constructor(props){
    super(props);

    this.props.getProject(this.props.match.params.id);
    this.props.getAllProjectStatus(this.props.match.params.id);

    this.state = {
      isActiveSideBtn: 'ACTIVITY',
      projectDetails: [],
      isOpen: false,
      selectedOption: '',
      selectedMember: '',
      selectedOwner: '',
      addedLead: false,
      updatedProject: false,
      updateProjectChange: false,
      isViewStatusVisible: 1,
      projectMembers: {},
      isBreadCrumbActive: '',
      filteredData: '',
      createLead: false,
      editLead: false,
      editLeadStatus: [],
      leadStatusData: [],
      //project: project,
      sortTable: [
        {
          name: 'Lead Status (A-Z)',
          value: 'leadStatus_az'
        },
        {
          name: 'Lead Status (Z-A)',
          value: 'leadStatus_za'
        },
        {
          name: 'Description (A-Z)',
          value: 'description_az'
        },
        {
          name: 'Description (Z-A)',
          value: 'description_za'
        },
        {
          name: 'Date Created (Oldest)',
          value: 'dateCreated_oldest'
        },
        {
          name: 'Date Created (Latest)',
          value: 'dateCreated_latest'
        }
      ],
      activeSort: 'dateCreated_oldest',
      statusColor:  ['teal', 'blue', 'yellow', 'orange', 'red', 'green', 'dark-gray', 'lime', 'cyan', 'indigo', 'brown', 'purple']
    };
  }

  componentDidMount() {
    sessionStorage.removeItem('isActiveModule');
    sessionStorage.setItem('isActiveModule', 'projects');
  }

  // handleGetProject = () => {
  //   this.props.getProject(this.props.match.params.id);
  // };

  handleGetProjectStatus = () => {
    this.props.getAllProjectStatus(this.props.match.params.id);
  };

  //this function is used to handle the length of text
  handleTextEllipsis(text, max) {
    return text.substr(0, max) + (text.length > max ? '...' : '');
  }

  setFormName = () => {
    let data = [this.state.formName, this.state.description];
    sessionStorage.setItem('formName', JSON.stringify(data));
    sessionStorage.removeItem('form')
  };

  handleSelectedChange = selectedOption => {
    this.setState({ selectedOption });
  };

  handleViewAllStatus = () => {
    let { isViewStatusVisible } = this.state;
    this.setState({ isViewStatusVisible: isViewStatusVisible === 1 ? 0 : 1 })
  };


  handleMapInput(inputFields, column) {
    return (
      inputFields.map((value) => {
        let col = 12 / column;
        return (
          <MDBCol xl={col} lg={col} md={col} className='px-1 mb-2' key={value.label}>
            <InputForm
              hint={value.label}
              onChange={this.handleMemberChange.bind(this)}
              type={value.type}
              name={value.label}
            />
          </MDBCol>
        )
      })
    )
  };

  //this function is used to handle the check all cards
  handleCheckAllSelectBox = () => {
    let checkAll = document.querySelector(`input[type='checkbox'][name='check-all']`);
    let checkBoxes = document.querySelectorAll(`input[type='checkbox'][name='check-box']`);
    checkAll.addEventListener('change', function (e) {
      for (let checkBox of checkBoxes) {
        checkBox.checked = this.checked;
      }
    });
  };

  editStatus = (leadStatus) => {
    this.setState({
      editLead: !this.state.editLead,
      editLeadStatus: leadStatus,
    });
  };

  //this function is used to render the table header of the lead list
  renderTableLeadTitle = () => {
    let { leadData } = this.state;

    let hasData = false;
    if(this.props.projects.status !== undefined)  {
      leadData = this.props.projects.status.data;
      hasData = true;
    }

    return (
      <table className='table-sm bordered' id='table-form'>
        <thead>
        <tr className='background-color-white'>
          {/*<th width='1%' className='py-3 border-table-right'>*/}
          {/*  <label className='checkboxcontainer ml-3'>*/}
          {/*    <input type='checkbox' name='check-all' onClick={() => { this.handleCheckAllSelectBox(true) }} />*/}
          {/*    <span className='checkmark'></span>*/}
          {/*  </label>*/}
          {/*</th>*/}
          <th width='9%' className='font-size-12 font-weight-600 opacity-70 py-3 border-table-right'>
            <div className='col'>
              <p className='mb-0'>LEAD STATUS</p>
              <div className='icon__table-sort'>
                <div className='height-10' >
                  <MDBIcon icon='caret-up' className={ this.state.activeSort === 'leadStatus_az' ? `active table-caret` : `table-caret`} onClick={() => { this.setState({ activeSort: 'leadStatus_az' }) }}/>
                </div>
                <div className='height-10'>
                  <MDBIcon icon='caret-down' className={ this.state.activeSort === 'leadStatus_za' ? `active table-caret` : `table-caret`}  onClick={() => { this.setState({ activeSort: 'leadStatus_za' }) }}/>
                </div>
              </div>
            </div>
          </th>
          <th width='10%' className='font-size-12 font-weight-600 opacity-70 py-3 border-table-right'>
            <div className='col'>
              <p className='mb-0'>DESCRIPTION</p>
              <div className='icon__table-sort'>
                <div className='height-10' >
                  <MDBIcon icon='caret-up' className={ this.state.activeSort === 'description_az' ? `active table-caret` : `table-caret`}  onClick={() => { this.setState({ activeSort: 'description_az' }) }}/>
                </div>
                <div className='height-10'>
                  <MDBIcon icon='caret-down' className={ this.state.activeSort === 'description_za' ? `active table-caret` : `table-caret`} onClick={() => { this.setState({ activeSort: 'description_za' }) }}/>
                </div>
              </div>
            </div>
          </th>
          {/*<th width='7%' className='font-size-12 font-weight-600 opacity-70 py-3 border-table-right'>*/}
          {/*  <div className='col'>*/}
          {/*    <p className='mb-0'>AUTO-ASSIGN OWNER</p>*/}
          {/*    <div className='left-105 mt-less-10 position-absolute top-13'>*/}
          {/*      <div className='height-10' >*/}
          {/*        <MDBIcon icon='caret-up' className='' />*/}
          {/*      </div>*/}
          {/*      <div className='height-10'>*/}
          {/*        <MDBIcon icon='caret-down' />*/}
          {/*      </div>*/}
          {/*    </div>*/}
          {/*  </div>*/}
          {/*</th>*/}
          {/*<th width='7%' className='font-size-12 font-weight-600 opacity-70 py-3 border-table-right'>*/}
          {/*  <div className='col'>*/}
          {/*    <p className='mb-0'>AUTO-ASSIGN RESPONSIBLE</p>*/}
          {/*    <div className='left-105 mt-less-10 position-absolute top-13'>*/}
          {/*      <div className='height-10' >*/}
          {/*        <MDBIcon icon='caret-up' className='' />*/}
          {/*      </div>*/}
          {/*      <div className='height-10'>*/}
          {/*        <MDBIcon icon='caret-down' />*/}
          {/*      </div>*/}
          {/*    </div>*/}
          {/*  </div>*/}
          {/*</th>*/}
          <th width='10%' className='font-size-12 font-weight-600 opacity-70 py-3'>
            <div className='col'>
              <p className='mb-0'>DATE CREATED</p>
              <div className='icon__table-sort'>
                <div className='height-10' >
                  <MDBIcon icon='caret-up' className={ this.state.activeSort === 'dateCreated_oldest' ? `active table-caret` : `table-caret`} onClick={() => { this.setState({ activeSort: 'dateCreated_oldest' }) }}/>
                </div>
                <div className='height-10'>
                  <MDBIcon icon='caret-down' className={ this.state.activeSort === 'dateCreated_latest' ? `active table-caret` : `table-caret`} onClick={() => { this.setState({ activeSort: 'dateCreated_latest' }) }}/>
                </div>
              </div>
            </div>
          </th>
          {/*<th width='8%' className='font-size-12 font-weight-600 opacity-70 py-3 text-center'>*/}
          {/*  <div className='col'>*/}
          {/*    <p className='mb-0'>LEAD COUNT</p>*/}
          {/*    <div className='icon__table-sort'>*/}
          {/*      <div className='height-7' >*/}
          {/*        <MDBIcon icon='caret-up' className='' />*/}
          {/*      </div>*/}
          {/*      <div className=''>*/}
          {/*        <MDBIcon icon='caret-down' />*/}
          {/*      </div>*/}
          {/*    </div>*/}
          {/*  </div>*/}
          {/*</th>*/}
        </tr>
        </thead>

        { hasData ?
          <LeadStatusList
            leadData={leadData}
            projectId={this.props.match.params.id}
            editStatus={this.editStatus}
            sort={this.state.activeSort}
            // onSelect={() => { this.handerShowActionBtn() }}
            addTaskModal={this.toggleAddTaskModal}
            deleteLeadModal={this.toggleDeleteModal}
            handleGetProjectStatus={this.handleGetProjectStatus}
          /> : null
        }

      </table>
    )
  };

  toggleAdd = () => {
    this.setState({ createLead: !this.state.createLead })
  };

  toggleEdit = () => {
    this.setState({ editLead: !this.state.editLead })
  };

  render() {
    let { statusLoaded } = this.props;
    //console.log('loaded', statusLoaded);
    let project = JSON.parse(sessionStorage.getItem('project'));
    //console.log('ManageLeadStatus project', project);
    // let defaultStatusIndex = 0;
    // let conversionStatusIndex = 0;
    //const statusColor = this.state.statusColor;

    let status = {};
    //let hasData = false;
    let projectLoaded = false;


    if(this.props.projects.status !== undefined)  {
      status = this.props.projects.status.data;
    }
    if(project !== undefined)  {
      projectLoaded = true;
      if(project.owner !== undefined){
        //hasData = true;
      }
      if(statusLoaded){
        //console.log('ManageLeadStatus status', status);
        status.map((val, index) => {
          if(project.default_status && (project.default_status.id === val.id)){
            //defaultStatusIndex = index;
          }
          if(project.conversion_status && (project.conversion_status.id === val.id)){
            //conversionStatusIndex = index;
          }
          return null;
        });
      }
    }


    // let { leadStatusData } = this.state,
    // isLeadHasData = leadData.length > 0,
    // containerClass = !isLeadHasData && '';

    return (

		<MDBContainer fluid className='p--0'>
			<div className='page-title-ctas p-l-md p-r-md'>
				{ projectLoaded &&
					<MDBRow className='m--0 align-items-center'>
						<MDBCol className='p-0'>
							<p className='page-title font-family-kanit font-size-30 line-height-7 color-gray line-height-6'>Manage Lead Status</p>
						</MDBCol>
						<MDBCol>
							<MDBRow className='float-right'>
								<ButtonGroup>
									<Link to={`/project/${project.id}`}>
										<Button
											flat='true'
											background='transparent' foreground='black'
											borderWidth={2} borderColor='dark' size={-1}
											styles={{ 'width': '100%', 'opacity': '0.8' }}>
											<Icon color='black' name='chevron' rotate={180} />
											<span className='color-black font-weight-bold'>Back to Project Overview</span>
										</Button>
									</Link>
									<Link to='/'>
										<Button
											flat='true'
											background='transparent' foreground='black'
											borderWidth={2} borderColor='dark' size={-1}
											styles={{ 'width': '100%', 'opacity': '0.8' }}>
											<Icon color='black' name='chevron' rotate={180} />
											<span className='color-black font-weight-bold'>Back to All Projects</span>
										</Button>
									</Link>
								</ButtonGroup>
							</MDBRow>
						</MDBCol>
					</MDBRow>
				}
			</div>

			<div className="page-filter">
				{projectLoaded &&
					<MDBRow className='text-left render-sidebar'>
						<MDBCol className='project-info__name'>
							<label className="mb-0 font-size-13 font-weight-600 mb-1">PROJECT NAME</label>
							<p className='m-0 font-weight-600'>{project.info.name}</p>
						</MDBCol>
					
						<MDBCol className='project-info__description'>
							<label className="mb-0 font-size-13 font-weight-600 mb-1">DESCRIPTION</label>
							<p className='m-0 font-weight-600'>{project.info.description}</p>
						</MDBCol>
					
						<MDBCol>
							<div className='background-color-white'>
								<div className='px-3 text-left '>
									<label className='mb-0 font-size-13 font-weight-600 mb-1'>OWNER</label>
									<div>
										<img src={avatar} alt='' className='width-25 border-radius-50 mr-2' />
										<span className='font-weight-600 font-size-14'>{utility.decrypt(project.owner.account.first_name)} {utility.decrypt(project.owner.account.last_name)}</span>
									</div>
								</div>
							</div>
						</MDBCol>
					
						<MDBCol>
							<label className="mb-0 font-size-13 font-weight-600 mb-1">DEFAULT STATUS</label>
							<p className='m-0 font-weight-600 font-color-orange'>{project.default_status ? project.default_status.name : 'None'}</p>
						</MDBCol>
						
						<MDBCol>
							<label className="mb-0 font-size-13 font-weight-600 mb-1">CONVERSION TO SALES</label>
							<p className='m-0 font-weight-600 font-color-orange'>{project.conversion_status ? project.conversion_status.name : 'None'}</p>
						</MDBCol>
					</MDBRow>
				}
			</div>
			{ projectLoaded ?
				<MDBRow className='m--0'>
					<MDBCol lg='12' xl='12' md='12' className='main-body-container container-fluid p--sm  sub-body-container rectangle-background'>
						{/* <MDBRow>
							<MDBCol className='text-right m-b--sm'>
								
								<div className='text-transform-none background-color-light-pink border-dark-orange border-radius-50 text-center py-1 px-4 box-shadow-none btn-hover-none'></div>
									<MDBRow>
										<label className="mb-0 font-size-13 font-weight-600 mb-1">DEFAULT STATUS</label>
										{ project.default_status &&
											<div className={`lead-round bg--${statusColor[defaultStatusIndex]} mr-1`}> </div>
										}
										<span className='font-weight-600 font-size-12'>{project.default_status ? project.default_status.name : 'None'}</span>
									</MDBRow>
								<div className='text-transform-none background-color-light-pink border-dark-orange border-radius-50 text-center py-1 px-4 box-shadow-none btn-hover-none'>
									<MDBRow>
										<label className="mb-0 font-size-13 font-weight-600 mb-1">CONVERSION TO SALES</label>
										{ project.conversion_status &&
										<div className={`lead-round bg--${statusColor[conversionStatusIndex]} mr-1`}> </div>
										}
										<span className='font-weight-600 font-size-12'>{project.conversion_status ? project.conversion_status.name : 'None'}</span>
									</MDBRow>
								</div>
									<Button
									flat='true' 
									type='submit' 
									borderWidth={2} borderColor='orange' size={-1} 
									background='orange' foreground='white' 
									onClick={() => this.toggleAdd()} 
									> 
									<Icon name='plus' /> 
									<span className='color-white'>Create Lead Status</span> 
									</Button> 
								
								<MDBBtn flat className='box-shadow-none background-dark-orange text-white py-2 border-radius-5 px-3' onClick={() => this.toggleAdd()}><MDBIcon icon='plus' className='mr-3' />CREATE LEAD STATUS</MDBBtn>
							</MDBCol>
						</MDBRow> */}
						<MDBRow className='main-body-container background-color-white m--0'>
							<MDBCol>
								{ statusLoaded ?
								this.renderTableLeadTitle(project, status)
								:
								<MDBRow className='position-center'>
									<div className='text-center position-center m--auto'>
									<div className="loader dark">Loading...</div>
									</div>
								</MDBRow>
								}
							</MDBCol>
						</MDBRow>
					</MDBCol>
				</MDBRow>
				:
				<MDBCol className='rectangle-background main-body-container'>
					<MDBRow className='mx-0 height--full width--full'>
						<MDBCol lg='12' className='height--full'>
							<div className='position-center'>
								<div className='text-center position-center m--auto'>
								<div className="loader">Loading...</div>
								</div>
							</div>
						</MDBCol>
					</MDBRow>
				</MDBCol>
			}
			<CreateLeadStatusModal
			modalToggle={this.toggleAdd}
			modalOpen={this.state.createLead}
			projectId={this.props.match.params.id}
			handleGetProjectStatus={this.handleGetProjectStatus}
			/>
			<EditLeadStatusModal
			modalToggle={this.toggleEdit}
			modalOpen={this.state.editLead}
			projectId={this.props.match.params.id}
			data={this.state.editLeadStatus}
			handleGetProjectStatus={this.handleGetProjectStatus}
			/>
		</MDBContainer>
    );
  }
}

function mapState(state) {
  const { projects, creating } = state;
  const { status, statusLoaded, project, projectLoaded } = state.projects;
  // const { creating } = state.createLeadStatus;
  //const { deleting } = state.deleteLeadStatus;
  return { creating, projects, project, status, statusLoaded, projectLoaded };
}

const actionCreators = {
  getProject: projectActions.getProject,
  updateProject: projectActions.updateProject,
  getAllProjectStatus: projectActions.getAllProjectStatus
};

const connectedViewProjects = connect(mapState, actionCreators)(ManageLeadStatus);
export { connectedViewProjects as ManageLeadStatus };
