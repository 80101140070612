import React, { useState } from 'react'
import Button from '../Button'

const NotesSide = ({ classN, notesModalAdd, notesModalEdit, confirmationDelete }) => {


  return (
    <>
        <div className={classN}>
            <div className='inner-title-box'>
                <p>
                    Notes
                </p>
                <Button 
                    text='Add Note'
                    icon='fa-plus'
                    onClick={notesModalAdd}
                />
            </div>
            <div className='notes-list'>
                <div className='notes'>
                    <p className='title'>
                        Notes Title
                    </p>
                    <p className='author'>
                        Author
                    </p>
                    <p className='notes-description'>
                        This is my note sample, Lorem ipsum dolor sit amet consectetur adipisicing elit. Vero, eveniet?
                    </p>
                    <span>
                        Note added:  <strong>2 minutes ago</strong>   •  Last Updated:  <strong>Never</strong>
                    </span>
                    <button className='edit-record' onClick={notesModalEdit}><i className="fas fa-pen"></i>Edit</button>
                    <button className='delete-record' onClick={confirmationDelete}><i className="fas fa-trash-alt"></i>Delete</button>
                </div>
            </div>
        </div>
    </>
  )
}

export default NotesSide