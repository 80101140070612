import React, { useState, useRef, useEffect } from "react";
import { MDBRow } from "mdbreact";
import { Button } from "blackbox-react";
import TopBar from "../../_components/TopBar";
import { useDispatch, useSelector } from "react-redux";
import { useParams, Link } from "react-router-dom";
import { projectActions } from "_actions";
import InputForm from "_components/InputForm";
import avatar from "../../_assets/images/avatar-lg.png";
import { EditorContent, useEditor } from "@tiptap/react";
import StarterKit from "@tiptap/starter-kit";
import Placeholder from "@tiptap/extension-placeholder";
import { profileActions, userActions } from "_actions";
import { utilitiesService as utility } from "_services";
import { Thumbs } from "swiper";

import EmailTemplateDrawer from "./components/EmailTemplateDrawer";

const ProfileSettings = () => {
  const dispatch = useDispatch();
  const params = useParams();

  useEffect(() => {
    // Dispatch the action to fetch the profile data
    dispatch(profileActions.getProfile());
  }, []);

  const profile = useSelector((state) => state.profile);

  const [firstName, setFirstName] = useState("");
  const [lastName, setLastName] = useState("");
  const [contact, setContact] = useState("");
  const [email, setEmail] = useState("");
  const [username, setUsername] = useState("");
  const [password, setPassword] = useState("");
  const [newPassword, setNewPassword] = useState("");
  const [confirmPassword, setConfirmPassword] = useState("");
  const [photo, setPhoto] = useState();
  const [photoSelected, setPhotoSelected] = useState(false);
  const [editorContent, setEditorContent] = useState("");
  const emailRegex =
    /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
  const [errFirstName, setErrFirstName] = useState(false);
  const [errLastName, setErrLastName] = useState(false);
  const [errContact, setErrContact] = useState(false);
  const [errEmail, setErrEmail] = useState(false);
  const [errUsername, setErrUsername] = useState(false);
  const [errPassword, setErrPassword] = useState(false);
  const [errNewPassword, setErrNewPassword] = useState(false);
  const [errConfirmPassword, setErrConfirmPassword] = useState(false);
  const [errPhoto, setErrPhoto] = useState(false);
  const [changePassword, setChangePassword] = useState(false);
  const [hasError, setHasError] = useState(false);
  const [toggleError, setToggleError] = useState("");

  const [toggleTabState, setToggleTabState] = useState(1);

  const [isEmailTemplateDrawer, setIsEmailTemplateDrawer] = useState(false);

  useEffect(() => {
    if (profile.getProfileLoaded && profile.data != null) {
      setFirstName(utility.decrypt(profile.data.first_name));
      setLastName(utility.decrypt(profile.data.last_name));
      setEmail(utility.decrypt(profile.data.email));

      if (profile.data.contact_number != null) {
        setContact(utility.decrypt(profile.data.contact_number));
      }

      if (profile.data != null && editor != null) {
        if (profile.data.signature) {
          setEditorContent(utility.decrypt(profile.data.signature));
          editor.commands.setContent(utility.decrypt(profile.data.signature));
        }
      }

      setHasError(false);
    }
  }, [profile.data]);

  const editor = useEditor({
    extensions: [StarterKit],
    autofocus: true,
    content: editorContent,
    onUpdate({ editor }) {
      setEditorContent(editor.getHTML());
    },
  });

  useEffect(() => {
    // this is just an example. do whatever you want to do here
    // to retrieve your editors content from somewhere
    // editor.commands.setContent(insertYourHTMLHere)

    if (
      editor != null &&
      Object.keys(profile).length > 0 &&
      profile.getProfileLoaded
    ) {
      if (profile.data.signature) {
        setEditorContent(utility.decrypt(profile.data.signature));
        editor.commands.setContent(utility.decrypt(profile.data.signature));
      }
    }
  }, [editor]);

  useEffect(() => {
    // this is just an example. do whatever you want to do here
    // to retrieve your editors content from somewhere
    // editor.commands.setContent(insertYourHTMLHere)

    if (profile.getProfileLoaded && toggleTabState == 3) {
      resetProfileDetails();
      resetChangePassword();
      setHasError(false);
      editor.commands.setContent(utility.decrypt(profile.data.signature));
    }
  }, [toggleTabState]);

  useEffect(() => {
    if (profile.getProfileLoaded && toggleTabState == 1) {
      resetChangePassword();
      setHasError(false);
      let hasError = false;

      if (firstName.trim().length === 0) {
        setErrFirstName(true);
        hasError = true;
      } else {
        setErrFirstName(false);
      }

      if (lastName.trim().length === 0) {
        setErrLastName(true);
        hasError = true;
      } else {
        setErrLastName(false);
      }

      if (email.trim().length === 0 || emailRegex.test(email) === false) {
        setErrEmail(true);
        hasError = true;
      } else {
        setErrEmail(false);
      }

      setHasError(hasError);
      //   if (hasError) {
      //     setToggleError("There is an error in Profile Details Tab");
      //   }
    }
  }, [
    firstName,
    lastName,
    email,
    errFirstName,
    errLastName,
    errEmail,
    toggleTabState,
  ]);

  useEffect(() => {
    if (profile.getProfileLoaded && toggleTabState == 2) {
      resetProfileDetails();
      setHasError(false);
      let hasError = false;

      if (password.trim().length === 0) {
        hasError = true;
      }

      if (newPassword.trim().length < 5) {
        hasError = true;
      }

      if (confirmPassword.trim().length < 5) {
        hasError = true;
      }

      if (
        password.trim().length === 0 &&
        newPassword.trim().length === 0 &&
        confirmPassword.trim().length === 0
      ) {
        hasError = false;
      }

      setHasError(hasError);
      //   if (hasError) {
      //     setToggleError("Please clear your Change Password Tab");
      //   }
    }
  }, [password, newPassword, confirmPassword, toggleTabState]);

  const handlePhotoUpload = (e) => {
    setPhoto(e.target.files[0]);
    setPhotoSelected(true);
  };

  const handleChangePassword = () => {
    setChangePassword(!changePassword);
  };

  const handleSave = () => {
    // if (contact.trim().length === 0) {
    //     setErrContact(true);
    //     setHasError(true);
    // } else {
    //     setErrContact(false);
    // }

    // if (!photo) {
    //     setErrPhoto(true);
    //     setHasError(true);
    // } else {
    //     setErrPhoto(false);
    // }

    let profileError = hasError;

    // if (errFirstName || errLastName) {
    //   profileError = true;
    //   setToggleError("There is an error in Profile Details Tab");
    // }

    if (toggleTabState == 1 || toggleTabState == 3) {
      const request = {
        first_name: utility.encrypt(firstName),
        last_name: utility.encrypt(lastName),
        contact_number: utility.encrypt(contact),
        email: utility.encrypt(email),
        signature: utility.encrypt(editorContent),
      };

      // Dispatch the updateProfile action with the request data
      dispatch(
        profileActions.updateProfile(request, profileError, toggleError)
      );
    }

    if (toggleTabState == 2) {
      const request = {
        email: utility.encrypt(email),
        current_password: utility.encrypt(password),
        new_password: utility.encrypt(newPassword),
        confirm_password: utility.encrypt(confirmPassword),
      };

      if (username.trim().length === 0) {
        setErrUsername(true);
        setHasError(true);
      } else {
        setErrUsername(false);
      }

      if (password.trim().length === 0) {
        setErrPassword(true);
        setHasError(true);
      } else {
        setErrPassword(false);
      }

      if (changePassword) {
        if (newPassword.trim().length === 0) {
          setErrNewPassword(true);
          setHasError(true);
        } else {
          setErrNewPassword(false);
        }
        if (confirmPassword.trim().length === 0) {
          setErrConfirmPassword(true);
          setHasError(true);
        } else {
          setErrConfirmPassword(false);
        }
      }

      if (!hasError) {
        dispatch(
          profileActions.updatePassword(request, profileError, toggleError)
        );
      }
    }
  };

  const handleDiscardChanges = () => {
    if (toggleTabState == 1) {
      resetProfileDetails();
    }

    if (toggleTabState == 2) {
      resetChangePassword();
    }

    if (toggleTabState == 3) {
      editor.commands.setContent(utility.decrypt(profile.data.signature));
    }

    setHasError(false);
  };

  const resetProfileDetails = () => {
    setFirstName(utility.decrypt(profile.data.first_name));
    setLastName(utility.decrypt(profile.data.last_name));
    setEmail(utility.decrypt(profile.data.email));
    setContact(utility.decrypt(profile.data.contact_number));
    setErrFirstName(false);
    setErrLastName(false);
    setErrEmail(false);
  };

  const resetChangePassword = () => {
    setUsername("");
    setPassword("");
    setNewPassword("");
    setConfirmPassword("");
    setChangePassword(false);
    setErrUsername(false);
    setErrPassword(false);
  };


  const handleOpenEmailTemplateDrawer = () => {
    setIsEmailTemplateDrawer(true);
  }

  const handleCloseEmailTemplateDrawer = () => {
    setIsEmailTemplateDrawer(false);
  }

  return (
    <>
      <div className="topbar-menu">
        <MDBRow className="row align-items-center">
          <div className="col">
            <div className="top-project-info-box">
              <div className="project-name">
                <h1>Profile Settings</h1>
              </div>
            </div>
          </div>
          <div className="col">
            <div className="top-button-box">
              <button className="hidden-btn"></button>
            </div>
          </div>
        </MDBRow>
      </div>
      <div className="main-info-section leads-info">
        <div className="profile-setting-section">
          <div className="row">
            <div className="col">
              <div className="current-profile-details">
                <div className="box">
                  <div className="profile-icon">
                    <span>{firstName.toUpperCase().charAt(0)}</span>
                    <label htmlFor="upload-photo" className="upload-photo">
                      <input type="file" id="upload-photo" name="upload-photo" />
                      <div>
                      <svg xmlns="http://www.w3.org/2000/svg" height="48" viewBox="0 -960 960 960" width="48"><path d="M180-120q-24.75 0-42.375-17.625T120-180v-600q0-24.75 17.625-42.375T180-840h409v60H180v600h600v-408h60v408q0 24.75-17.625 42.375T780-120H180Zm520-498v-81h-81v-60h81v-81h60v81h81v60h-81v81h-60ZM240-282h480L576-474 449-307l-94-124-115 149Zm-60-498v600-600Z" fill="#fff"/></svg>
                      <span>Upload Photo</span>
                      </div>
                    </label>
                  </div>
                </div>
                <div className="box">
                  <div className="profile-detail">
                    <p>
                      {firstName} {lastName}
                    </p>
                    <span>{email}</span>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div className="row">
            <div className="col">
              <div className="tab-profile">
                <div className="control-tabs-box">
                  <div
                    className={
                      toggleTabState === 1 ? "tabs-btn active" : "tabs-btn"
                    }
                    onClick={() => setToggleTabState(1)}
                  >
                    <p>Profile Details</p>
                  </div>
                  <div
                    className={
                      toggleTabState === 2 ? "tabs-btn active" : "tabs-btn"
                    }
                    onClick={() => setToggleTabState(2)}
                  >
                    <p>Change Password</p>
                  </div>
                  <div
                    className={
                      toggleTabState === 3 ? "tabs-btn active" : "tabs-btn"
                    }
                    onClick={() => setToggleTabState(3)}
                  >
                    <p>Signature</p>
                  </div>
                  {/* <div className={toggleTabState === 4 ? 'tabs-btn active' : 'tabs-btn'} onClick={() => setToggleTabState(4)}>
                    <p>
                        Photo
                    </p>
                  </div> */}
                  {/* <div className={isEmailTemplateDrawer ? "tabs-btn active" : "tabs-btn"} onClick={() => handleOpenEmailTemplateDrawer()}>
                    <p>Manage Email Templates</p>
                  </div> */}
                </div>
                <div className="content-tabs-box">
                  <div
                    className={
                      toggleTabState === 1
                        ? "tabs-content active"
                        : "tabs-content"
                    }
                  >
                    <div className="forms-tab">
                      <div className="row">
                        <div className="col form-group-box">
                          <div className="form-group-title mb-0">
                            <label>Overview</label>
                          </div>
                          <div className="input-group-box half-box-">
                            <div className="field-box">
                              <label>
                                First Name <small>*</small>
                              </label>
                              <InputForm
                                onChange={(e) => setFirstName(e.target.value)}
                                id={`first_name`}
                                name={`first_name`}
                                value={firstName}
                              />
                              {errFirstName ? (
                                <p className="error-field">
                                  This field is required.
                                </p>
                              ) : (
                                <></>
                              )}
                            </div>
                            <div className="field-box">
                              <label>
                                Last Name <small>*</small>
                              </label>
                              <InputForm
                                onChange={(e) => setLastName(e.target.value)}
                                id={`last_name`}
                                name={`last_name`}
                                value={lastName}
                              />
                              {errLastName ? (
                                <p className="error-field">
                                  This field is required.
                                </p>
                              ) : (
                                <></>
                              )}
                            </div>
                            <div className="field-box">
                              <label>Contact Number </label>
                              <InputForm
                                onChange={(e) => setContact(e.target.value)}
                                id={`contact`}
                                name={`contact`}
                                value={contact}
                                onKeyPress={(event) => {
                                  if (!/[0-9]/.test(event.key)) {
                                    event.preventDefault();
                                  }
                                }}
                              />
                              {errContact ? (
                                <p className="error-field">
                                  This field is required.
                                </p>
                              ) : (
                                <></>
                              )}
                            </div>
                            <div className="field-box">
                              <label>Email Address </label>
                              <InputForm
                                onChange={(e) => setEmail(e.target.value)}
                                id={`email`}
                                name={`email`}
                                value={email}
                                readonly={true}
                                disabled={true}
                              />
                              {errEmail ? (
                                <p className="error-field">
                                  Invalid Emaild Address.
                                </p>
                              ) : (
                                <></>
                              )}
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                  <div
                    className={
                      toggleTabState === 2
                        ? "tabs-content active"
                        : "tabs-content"
                    }
                  >
                    <div className="forms-tab change-password">
                      <div className="row">
                        <div className="col form-group-box">
                          {/* <div className="form-group-title mb-0">
                                                <label>Change your password</label>
                                            </div> */}
                          <div className="input-group-box full-box- mb-0">
                            <div className="field-box">
                              <label>
                                Current Password <small>*</small>
                              </label>
                              <InputForm
                                onChange={(e) => setPassword(e.target.value)}
                                id={`current_password`}
                                name={`current_password`}
                                value={password}
                                //type='password'
                              />
                              {errPassword ? (
                                <p className="error-field">
                                  This field is required.
                                </p>
                              ) : (
                                <></>
                              )}
                            </div>
                          </div>
                          <div className="input-group-box full-box- mb-0">
                            <div className="field-box">
                              <label>
                                New Password <small>*</small>
                              </label>
                              <InputForm
                                onChange={(e) => setNewPassword(e.target.value)}
                                id={`new_password`}
                                name={`new_password`}
                                value={newPassword}
                              />
                              {errNewPassword ? (
                                <p className="error-field">
                                  This field is required.
                                </p>
                              ) : (
                                <></>
                              )}
                            </div>
                          </div>
                          <div className="input-group-box full-box- mb-0">
                            <div className="field-box">
                              <label>
                                Confirm Password <small>*</small>
                              </label>
                              <InputForm
                                onChange={(e) =>
                                  setConfirmPassword(e.target.value)
                                }
                                id={`confirm_password`}
                                name={`confirm_password`}
                                value={confirmPassword}
                              />
                              {errConfirmPassword ? (
                                <p className="error-field">
                                  This field is required.
                                </p>
                              ) : (
                                <></>
                              )}
                            </div>
                          </div>

                          <div className="input-group-box full-box-">
                            <div className="field-box mt-0">
                              {newPassword.length > 0 &&
                              confirmPassword.length > 0 &&
                              newPassword !== confirmPassword ? (
                                <p className="error-field">
                                  Password must be the same.
                                </p>
                              ) : newPassword.length > 0 &&
                                confirmPassword.length > 0 &&
                                newPassword.length < 6 &&
                                newPassword === confirmPassword ? (
                                <p className="error-field">
                                  Password must have more than 5 characters.
                                </p>
                              ) : ""}
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                  <div
                    className={
                      toggleTabState === 3
                        ? "tabs-content active"
                        : "tabs-content"
                    }
                  >
                    <div className="forms-tab">
                      <div className="row">
                        <div className="col form-group-box">
                          <div className="form-group-title">
                            <label>Create or edit your email signature</label>
                          </div>
                          <div className="custom-basic-editor profile-editor">
                            <MenuBar editor={editor} />
                            <EditorContent
                              editor={editor}
                              className="thread-editor-field"
                            />
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                  <div
                    className={
                      toggleTabState === 4
                        ? "tabs-content active"
                        : "tabs-content"
                    }
                  >
                    <div className="forms-tab">
                      <div className="row">
                        <div className="col form-group-box">
                          <div className="input-group-box full-box-">
                            <div className="field-box">
                              <label>
                                Upload <small>*</small>
                              </label>
                              <input
                                className="form-file-control"
                                type="file"
                                onChange={handlePhotoUpload}
                                id={`photo`}
                                name={`photo`}
                              />
                              {errPhoto ? (
                                <p className="error-field">
                                  This field is required.
                                </p>
                              ) : (
                                <></>
                              )}
                            </div>
                            <div className="field-box">
                              <label>
                                Current <small>*</small>
                              </label>
                              <div className="current-photo">
                                <img src={avatar} />
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div className="row btn-groups">
            <div className="col">
              {toggleTabState === 1 && (
                <>
                  <button
                    className="c-btn c-btn-light"
                    onClick={() => handleDiscardChanges()}
                  >
                    <i className="icon-lms icon-delete"></i>Cancel
                  </button>
                  <button
                    disabled={hasError}
                    className={
                      firstName.length > 0 &&
                      lastName.length > 0
                        ? "c-btn"
                        : "c-btn c-btn-disabled"
                    }
                    onClick={() => handleSave()}
                  >
                    <i className="icon-lms icon-save"></i>Save
                  </button>
                </>
              )}
              {toggleTabState === 2 && (
                <>
                  <button
                    className="c-btn c-btn-light"
                    onClick={() => handleDiscardChanges()}
                  >
                    <i className="icon-lms icon-delete"></i>Cancel
                  </button>
                  <button
                    className={
                      newPassword.length > 5 &&
                      confirmPassword.length > 5 &&
                      newPassword === confirmPassword
                        ? "c-btn"
                        : "c-btn c-btn-disabled"
                    }
                    onClick={() => handleSave()}
                  >
                    <i className="icon-lms icon-save"></i>Save
                  </button>
                </>
              )}
              {toggleTabState === 3 && (
                <>
                  <button
                    className="c-btn c-btn-light"
                    onClick={() => handleDiscardChanges()}
                  >
                    <i className="icon-lms icon-delete"></i>Cancel
                  </button>
                  <button className="c-btn" onClick={() => handleSave()}>
                    <i className="icon-lms icon-save"></i>Save
                  </button>
                </>
              )}
            </div>
          </div>
        </div>
      </div>
      {/* <EmailTemplateDrawer 
      isEmailTemplateDrawer={isEmailTemplateDrawer}
      handleCloseEmailTemplateDrawer={handleCloseEmailTemplateDrawer}
      /> */}
    </>
  );
};

const MenuBar = ({ editor }) => {
  if (!editor) {
    return null;
  }

  return (
    <>
      <div className="thread-editor-controls">
        <button
          onClick={() => editor.chain().focus().setParagraph().run()}
          className={editor.isActive("paragraph") ? "is-active" : ""}
        >
          <i className="fas fa-paragraph"></i>
        </button>
        <button
          onClick={() =>
            editor.chain().focus().toggleHeading({ level: 4 }).run()
          }
          className={
            editor.isActive("heading", { level: 4 }) ? "is-active" : ""
          }
        >
          <i className="fas fa-heading"></i>
        </button>
        <button
          onClick={() => editor.chain().focus().toggleBold().run()}
          disabled={!editor.can().chain().focus().toggleBold().run()}
          className={editor.isActive("bold") ? "is-active" : ""}
        >
          <i className="fas fa-bold"></i>
        </button>
        <button
          onClick={() => editor.chain().focus().toggleItalic().run()}
          disabled={!editor.can().chain().focus().toggleItalic().run()}
          className={editor.isActive("italic") ? "is-active" : ""}
        >
          <i className="fas fa-italic"></i>
        </button>
        <button
          onClick={() => editor.chain().focus().toggleStrike().run()}
          disabled={!editor.can().chain().focus().toggleStrike().run()}
          className={editor.isActive("strike") ? "is-active" : ""}
        >
          <i className="fas fa-strikethrough"></i>
        </button>
        <button
          onClick={() => editor.chain().focus().toggleBulletList().run()}
          className={editor.isActive("bulletList") ? "is-active" : ""}
        >
          <i className="fas fa-list-ul"></i>
        </button>
        <button
          onClick={() => editor.chain().focus().toggleOrderedList().run()}
          className={editor.isActive("orderedList") ? "is-active" : ""}
        >
          <i className="fas fa-list-ol"></i>
        </button>
        <button
          onClick={() => editor.chain().focus().undo().run()}
          disabled={!editor.can().chain().focus().undo().run()}
        >
          <i className="fas fa-undo"></i>
        </button>
        <button
          onClick={() => editor.chain().focus().redo().run()}
          disabled={!editor.can().chain().focus().redo().run()}
        >
          <i className="fas fa-redo"></i>
        </button>
      </div>
    </>
  );
};

export default ProfileSettings;
