import React, { Component } from "react";

import Moment from 'react-moment';
import { projectActions, formActions } from '_actions';
import { MDBContainer,MDBRow, MDBCol, MDBIcon, MDBDropdown, MDBDropdownToggle, MDBDropdownMenu, MDBDropdownItem } from "mdbreact";
import settings from '_assets/images/settings.png'
import {Link} from "react-router-dom";
import {connect} from "react-redux";
import {render} from "redux-logger/src/diff";

class FormCardView extends Component {
  constructor (props) {
    super(props);

    this.state = {
      statusToDelete: {},
    };
  }

  handleTextEllipsis(text, max) {
    return text.substr(0, max) + (text.length > max ? '...' : '');
  }

  handleToggle = () =>{
    let { modalOpen } = this.state;
    this.setState({modalOpen: !modalOpen});

    this.props.handleToggle();
  };

  handleSetDeleteConfirmationNum = () => {
    this.props.handleSetDeleteConfirmationNum('single');
  };

  approveModal = (statusToDelete) => {
    const id = statusToDelete.id;
    const name = statusToDelete.name;

    if(this.props.projects.items && this.props.projects.items.data.id && id && name) {
      const projectId = this.props.projects.items.data.id;

      const deleteFormData = {
        name: statusToDelete.name,
        description: statusToDelete.description
      };

      this.props.deleteForm(deleteFormData, projectId, id);

      setTimeout(
          function () {
            this.setState({position: 1}, () => {
              render()
            });
          }
              .bind(this),
          2000
      );

      this.setState({
        statusToDelete: {}
      })
    }
    this.props.approveModal(statusToDelete);
  };

  handlePassData = (data) => {

    this.setState({
      statusToDelete: data
    });

    this.props.handlePassData(data);
  };

  sortView(data){
    let { sort } = this.props;

    switch(sort){
      case 'lastUpdated':
        data = data.sort((a, b) => a.updated < b.updated);
        return data;
      case 'newest':
        data = data.sort((a, b) => a.id < b.id);
        return data;
      case 'oldest':
        data = data.sort((a, b) => a.id > b.id);
        return data;
      case 'az':
        data = data.sort((a, b) => {
          if (a.name > b.name) { return 1 }
          if (b.name > a.name) { return -1 }
          return 0;
        });
        return data;
      case 'za':
        data = data.sort((a, b) => {
          if (a.name > b.name) { return -1 }
          if (b.name > a.name) { return 1 }
          return 0;
        });
        return data;
      default:
        return null;
    }
  }

  renderFormCardView = (projectId, data, filter) => {
    data = this.sortView(data);
    return (
      data.map((value, index) => {
        return (
          <MDBCol lg='3' xl='3' md='6' className='padding-10 pt-0 pb-3 pl-3 pr-1' key={index}>
              <MDBContainer className='card card-container card--md position-relative box-shadow background-color-white border-radius-3 position-relative card-container box-shadow p--md'>
                <MDBRow className='card__header'>
                  <MDBCol className=''>
                    <Link to={`/project/${projectId}/form/${value.id}/form-builder`}>
                      <div className='card__title min-height--none'>
                        <h3>{this.handleTextEllipsis(value.name || '', 100)}</h3>
                      </div>
                    </Link>
                  </MDBCol>
                </MDBRow>
                {/*<MDBRow>*/}
                {/*  <div className="lines">*/}
                {/*    <div className="line--orange w--100"> </div>*/}
                {/*    <div className="line--orange w--100"> </div>*/}
                {/*    <div className="line--orange w--100 opacity-50"> </div>*/}
                {/*    <div className="line--orange w--70 opacity-50"> </div>*/}
                {/*    <div className="line--orange w--85 opacity-50"> </div>*/}
                {/*    <div className="line--orange w--85"> </div>*/}
                {/*    <div className="line--orange w--85"> </div>*/}
                {/*  </div>*/}
                {/*</MDBRow>*/}
                {/*<MDBRow>*/}
                {/*  <div className='rectangle-container d-flex'>*/}
                {/*    <p className='m-auto opacity-40'><MDBIcon far icon='eye' /> Preview</p>*/}
                {/*  </div>*/}
                {/*  /!*<MDBContainer className='load-background-rectangle'>*!/*/}
                {/*  /!*  <div className='px-2 py-2'>*!/*/}
                {/*  /!*    <div className='rectangle-1 margin-2-0' />*!/*/}
                {/*  /!*    <div className='rectangle-2 margin-2-0' />*!/*/}
                {/*  /!*    <div className='rectangle-3 margin-2-0' />*!/*/}
                {/*  /!*    <div className='rectangle-4 margin-2-0' />*!/*/}
                {/*  /!*    <div className='rectangle-5 margin-2-0' />*!/*/}
                {/*  /!*    <div className='rectangle-5 margin-2-0' />*!/*/}
                {/*  /!*    <div className='rectangle-6 margin-2-0' />*!/*/}
                {/*  /!*  </div>*!/*/}
                {/*  /!*</MDBContainer>*!/*/}
                {/*</MDBRow>*/}
                {/*<div className='progress mb-2 mt-1 background-color-light-pink border-radius-50 border-dark-orange'>*/}
                {/*  <span className='progress-value text-white font-weight-600'>{value.progress}</span>*/}
                {/*  <div className='progress-bar background-color-dark-orange border-radius-50 ' style={{ width: '90%' }}>*/}
                {/*  </div>*/}
                {/*</div>*/}

                <div className='p-t--sm p-b--sm'>
                  <p className='m-b--sm p-b--sm font-size-12 opacity-50 p-r--sm'>{this.handleTextEllipsis(value.description || '', 100)}</p>
                  <p className='font-size-10 mb-0 line-height-13 opacity-80'>
                    Created: &nbsp;
                    <Moment className='font-bold-black' date={value.created} format="LLL" />
                  </p>
                  <p className='font-size-10 mb-0 line-height-13 opacity-80'>
                    Last Updated: &nbsp;
                    <Moment className='font-weight-bold' date={value.updated} format="LLL" />
                  </p>
                  <div  className='position-absolute right-8 bottom-20 opacity-50 px-2'>
                    <MDBRow>
                      <MDBDropdown className='list-settings'>
                        <MDBDropdownToggle flat className='p-0 shadow-none m-0 pr-2'>
                          <img src={settings} alt='' className='p-2 my-auto text-center' />
                        </MDBDropdownToggle>
                        <MDBDropdownMenu className='border-radius-3 border-unset box-shadow min-width-unset left-negative-63 top-negative-5 py-0'>
                          {/*<MDBDropdownItem className='font-size-11 p-2 px-3'>Form Settings</MDBDropdownItem>
                            <MDBDropdownItem divider className='m-0' />
                            <MDBDropdownItem className='font-size-11 p-2 px-3'>Form Builder</MDBDropdownItem>
                            <MDBDropdownItem divider className='m-0' />
                            <MDBDropdownItem className='font-size-11 p-2 px-3'>Integration</MDBDropdownItem>*/}
                          {/*<MDBDropdownItem divider className='m-0' />*/}
                          <MDBDropdownItem
                            className='font-size-11 p-2 px-3 color-red'
                            onClick={(event) => {
                              event.preventDefault();
                              this.handleToggle();
                              this.handlePassData(value);
                              this.handleSetDeleteConfirmationNum();
                            }}
                          > <MDBIcon far icon='trash-alt' className='mr-2' /> Delete Form</MDBDropdownItem>
                        </MDBDropdownMenu>
                      </MDBDropdown>
                    </MDBRow>
                  </div>
                </div>
              </MDBContainer>
        </MDBCol>
        )
      })
    )
  };

  render() {
    let { data } = this.props;
    let { filter } = this.props;
    let { projectId } = this.props;

    sessionStorage.setItem('projectId', projectId);

    let hasData = false;
    if (data !== undefined){
      hasData = true;
      //data = this.sortView(data);
    }

    return (
      <MDBRow>
        { hasData ?
          this.renderFormCardView(projectId, data, filter)
          : null
        }
      </MDBRow>
    );
  }
}

function mapState(state) {
  const { projects, project, forms, form, adding, deleting } = state;
  return { deleting, adding, projects, project, forms, form };
}

const actionCreators = {
  getProject: projectActions.getProject,
  deleteForm: formActions.deleteForm
};


const connectedViewForms = connect(mapState, actionCreators)(FormCardView);
export { connectedViewForms as FormCardView };
