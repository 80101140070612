import React from 'react';

const ParameterDrawerLoader = () => {
  return (
    <div className="skeleton-loader skeleton-parameter-drawer active">
        <div className='skeleton-groups'>
            <div className='skeleton skeleton-box'></div>
            <div className='skeleton skeleton-box'></div>
            <div className='skeleton skeleton-box'></div>
            <div className='skeleton skeleton-box'></div>
            <div className='skeleton skeleton-box'></div>
            <div className='skeleton skeleton-box'></div>
            <div className='skeleton skeleton-box'></div>
            <div className='skeleton skeleton-box'></div>
            <div className='skeleton skeleton-box'></div>
        </div>
    </div>
  )
}

export default ParameterDrawerLoader