import React from 'react'

const ButtonGroupLoader = () => {
  return (
    <div className="skeleton-loader skeleton-button-group active">
        <div className="skeleton skeleton-box"></div>
        <div className="skeleton skeleton-box"></div>
        <div className="skeleton skeleton-box"></div>
    </div>
  )
}

export default ButtonGroupLoader