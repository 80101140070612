class DropDownLists {
    static services = [
      "Social Media Marketing",
      "Web Design And Development",
      "Influencer Marketing",
      "Conversion Rate Optimization",
      "Digital Analytics",
      "UX Design",
      "Mobile App Development",
      "Pay Per Click / Digital Ads",
      "Search Engine Optimization",
      "Content Marketing",
    ];
  
    static positions = [
      "Content Specialist",
      "Senior Pay Per Click Specialist",
      "Junior Pay Per Click Specialist",
      "Freelance Content Writer | Researcher | Home-based Writer",
      "Social Media Specialist",
      "Senior SEO Specialist",
      "Graphic Artist",
      "Media Producer",
      "Art Director",
      "Jr. Seo Specialist",
      "HR Training Officer",
      "Copywriter",
      "Digital Analyst",
      "Senior Graphic Artist",
      "Content Strategist",
      "Mobile Software Engineer",
      "Mobile Software Engineer - IOS",
      "Front-end Web Developer",
      "Technical Writer / Business Analyst",
      "Information Security Officer",
      "Project Manager (Software Development)",
      "Software QA Analyst",
      "PHP Software Developer",
      "Junior Backend Developer",
      "UI/UX Designer",
      "WordPress Developer",
      "Senior Java Backend Developer",
      "Senior Frontend Developer",
      "Intern"
    ];
  
    static primaryLeadType = [
      "Interested Buyer",
      "Homeowner",
      "Proposals",
      "Broker/Agent",
      "Applicant",
      "Interested to Rent"
    ];
  }
  
  export default DropDownLists;