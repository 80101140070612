import React, {Component} from "react";
import Logout from "_components/Logout";
import {MDBCollapse, MDBDropdown, MDBDropdownItem, MDBDropdownMenu, MDBDropdownToggle} from "mdbreact";
import {Link} from "react-router-dom";
import {projectActions} from "_actions";
import {connect} from "react-redux";

class Blank extends Component {
    constructor(props) {
        super(props);
    }

    componentDidMount() {

    }

    render() {
        return <div></div>;
    }
}


function mapState(state) {
    const { projects, project } = state;
    return { projects, project };
}

const actionCreators = {
    getProjects: projectActions.getProjects
};

const connectedViewBlank = connect(mapState, actionCreators)(Blank);
export { connectedViewBlank as Blank };