import React from 'react'

const ActivityBtn = ({ ToggleTabClick, classN, name, icon }) => {
  return (
    <>
        <div className={classN} onClick={ToggleTabClick}>
            <p>
                {/* <i className={icon}></i> */}
                <span>{name}</span>
            </p>
        </div>
    </>
  )
}

export default ActivityBtn