import { fileService } from "_services";
import { fileConstants } from "_constants";
import { alertActions } from "./alert.actions";


export const fileActions = {
    getFiles,
    addFile,
    updateFile,
    deleteFile,
  };

  function getFiles(projectId, leadId) {

    return (dispatch) => {
      dispatch(request());
      fileService.getFiles(projectId, leadId).then(
        (files) => {
          dispatch(success(files));
          // dispatch(
          //   alertActions.success("Successfully resend verification to your email")
          // );
        },
        (error) => {
          dispatch(failure(error.toString()));
          dispatch(alertActions.error(error.toString()));
        }
      );
    };
  
    function request() {
      return { type: fileConstants.GET_ALL_FILE_REQUEST };
    }
    function success(files) {
      return { type: fileConstants.GET_ALL_FILE_SUCCESS, files };
    }
    function failure(error) {
      return { type: fileConstants.GET_ALL_FILE_FAILURE, error };
    }
  }


  function addFile(projectId, leadId, formData) {
    return (dispatch) => {
      dispatch(request());
      fileService.addFile(projectId, leadId, formData).then(
        (files) => {
          dispatch(success(files));
          dispatch(
            alertActions.success("New File successfully added.")
          );
        },
        (error) => {
          console.log('error: ', error);
          dispatch(failure(error.toString()));
          dispatch(alertActions.error(error.toString()));
        }
      );
    };
  
    function request() {
      return { type: fileConstants.ADD_FILE_REQUEST };
    }
    function success(files) {
      return { type: fileConstants.ADD_FILE_SUCCESS, files };
    }
    function failure(error) {
      return { type: fileConstants.ADD_FILE_FAILURE, error };
    }
  }
  
  function updateFile(projectId, leadId, fileId, formData) {
    return (dispatch) => {
      dispatch(request());
      fileService.updateFile(projectId, leadId, fileId, formData).then(
        (files) => {
          dispatch(success(files));
          dispatch(
            alertActions.success("File successfully updated.")
          );
        },
        (error) => {
          dispatch(failure(error.toString()));
          dispatch(alertActions.error(error.toString()));
        }
      );
    };
  
    function request() {
      return { type: fileConstants.UPDATE_FILE_REQUEST };
    }
    function success(files) {
      return { type: fileConstants.UPDATE_FILE_SUCCESS, files };
    }
    function failure(error) {
      return { type: fileConstants.UPDATE_FILE_FAILURE, error };
    }
  }


  function deleteFile(projectId, leadId, fileId) {

    return (dispatch) => {
      dispatch(request());
      fileService.deleteFile(projectId, leadId, fileId).then(
        (files) => {
          dispatch(success(files));
          dispatch(
            alertActions.success("Delete File successful.")
          );
        },
        (error) => {
          dispatch(failure(error.toString()));
          dispatch(alertActions.error(error.toString()));
        }
      );
    };
  
    function request() {
      return { type: fileConstants.DELETE_FILE_REQUEST };
    }
    function success(files) {
      return { type: fileConstants.DELETE_FILE_SUCCESS, files };
    }
    function failure(error) {
      return { type: fileConstants.DELETE_FILE_FAILURE, error };
    }
  }

  