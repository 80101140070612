import React, {useEffect, useState} from "react";
import {history} from '_helpers';
import TableMainLoader from '../../../../_components/Loader/TableMainLoader';

import {
    useTable,
    useBlockLayout,
    useFlexLayout,
    useResizeColumns,
    useSortBy,
} from "react-table";
import { useSticky } from "react-table-sticky";
import { TableHeader } from "./TableHeader";
import {useDispatch, useSelector} from "react-redux";
import {useParams} from "react-router-dom";
import {alertActions} from "_actions";

const TableList = () => {

    const dispatch = useDispatch();
    const params = useParams();
    const getUtmLoaded = useSelector((state) => state.utm.getUtmLoaded);
    const getUtmLoading = useSelector((state) => state.utm.getUtmLoading);
    const [data, setData] = useState([]);


    const tableData = useSelector((state) => {
        const {utm} = state;
        if(utm.getUtmLoaded) {
            const {utms} = utm;
            return utms.content;
        }
        return [];
    });

    useEffect(() => {
        console.log(tableData);
        setData(tableData);
    }, [getUtmLoaded]);


    // const getUtmLoaded = useSelector((state) => state.utm.getUtmLoaded);

    let columns = React.useMemo(() => TableHeader, []);

    const { getTableProps, getTableBodyProps, headerGroups, rows, prepareRow } =
        useTable(
            {
                columns,
                data,
                initialState: {
                    hiddenColumns: columns
                        .filter((column) => !column?.isVisible)
                        .map((column) => column.accessor),
                },
            },
            useSortBy,
            useFlexLayout,
            useResizeColumns,
            useSticky
        );

    const handleCopyBitly = (row) => {
        console.log(row.original.bitly);
        navigator.clipboard.writeText(row.original.bitly);
        dispatch(alertActions.success(row.original.bitly + " copied to clipboard"));
    }

    return (
        <>
            {
                getUtmLoaded &&
                <table {...getTableProps()} className="table-main">
                    <thead>
                    {headerGroups.map((headerGroup) => (
                        <tr {...headerGroup.getHeaderGroupProps()}>
                            {headerGroup.headers.map((column) => (
                                <th {...column.getHeaderProps()}>
                                    <span>{column.render("Header")}</span>
                                    {column.canResize && (
                                        <div
                                            {...column.getResizerProps()}
                                            className={`resizer ${
                                                column.isResizing ? "isResizing" : ""
                                            }`}
                                        />
                                    )}
                                    <div className="th-w-sort">
                                        <div className="sort-icons">
                                            <i className="fas fa-sort-up active"></i>
                                            <i className="fas fa-sort-down"></i>
                                        </div>
                                    </div>
                                </th>
                            ))}
                        </tr>
                    ))}
                    </thead>
                    <tbody {...getTableBodyProps()}>
                    {rows.map((row, i) => {
                        prepareRow(row);
                        return (
                            <tr {...row.getRowProps()} onClick={() => handleCopyBitly(row)}>
                                {row.cells.map((cell) => {
                                    return (
                                        <td
                                            {...cell.getCellProps([
                                                { className: cell.column.className },
                                            ])}
                                        >
                                            {cell.render("Cell")}
                                        </td>
                                    );
                                })}
                            </tr>
                        );
                    })}
                    </tbody>
                </table>
            }
            {
                !getUtmLoaded &&
                <TableMainLoader />
            }
            {
                getUtmLoaded && tableData.length === 0 &&
                <div className='empty-table-box active'>
                    <h3 className='empty-text'>No UTM Found</h3>
                </div>
            }
        </>
    );
};

export default TableList;
