import React from 'react';
import Moment from "react-moment";
import { useState } from "react";
import { useRef } from "react";
import {MDBCol} from "mdbreact";


const LeadClickedInfo = ({ url, onToggle, active }) => {

    const [clicked, setClicked] = useState(false);
    const contentEl = useRef();

    const handleToggle = () => {
        setClicked((prev) => !prev);
    };

    if(url.codexData) {
        const codex = url.codexData;
        return (  <li className={`accordion_item ${clicked ? "active" : ""}`}>
            <MDBCol>
                <button className="accordion_button" onClick={handleToggle}>
                    <div>
                        <p>
                            URL:
                        </p>
                        <span className='url-txt'>
                            {url.url}
                        </span>
                    </div>
                    <div>
                        <p>
                            Clicked Date:
                        </p>
                        <span>
                            <Moment date={url.date} format="LLL"/>
                        </span>
                    </div>
                    <span className="control">{clicked ? "—" : "+"} </span>
                </button>
                <div className={`accordion_content_wrapper`}
                     ref={contentEl}
                     style={
                         clicked
                             ? { height: contentEl.current.scrollHeight }
                             : { height: "0px" }
                     }>


                <div>
                    <p>
                        UTM Source:
                    </p>
                    <span>
                        {codex.source}
                    </span>
                </div>

                <div>
                    <p>
                        UTM Medium:
                    </p>
                    <span>
                        {codex.medium}
                    </span>
                </div>

                <div>
                    <p>
                        UTM Campaign:
                    </p>
                    <span>
                        {codex.campaign_name}
                    </span>
                </div>


                <div>
                    <p>
                        UTM Term:
                    </p>
                    <span>
                        {codex.term}
                    </span>
                </div>


                <div>
                    <p>
                        Campaign Objective:
                    </p>
                    <span>
                        {codex.campaign_objective}
                    </span>
                </div>


                <div>
                    <p>
                        Ad Format:
                    </p>
                    <span>
                        {codex.ad_format}
                    </span>
                </div>

                <div>
                    <p>
                        Ad Version:
                    </p>
                    <span>
                        {codex.ad_version}
                    </span>
                </div>

                <div>
                    <p>
                        Audience Type:
                    </p>
                    <span>
                        {codex.audience_type}
                    </span>
                </div>

                <div>
                    <p>
                        Audience Description:
                    </p>
                    <span>
                        {codex.audience_description}
                    </span>
                </div>


                </div>
            </MDBCol>
        </li>)
    } else {

        let parsed = new URL(url.url);
        const params = parsed.searchParams;


        return (
            <li className={`accordion_item ${clicked ? "active" : ""}`}>
            <MDBCol className='background-color-white py-2 border-left-orange submitted-form'>
                <button className="accordion_button" onClick={handleToggle}>
                    <div>
                        <p className='mb-0 font-size-12 font-weight-600 '>
                            URL:
                            <span className="font-weight-400 opacity-60">
                            {url.url}
                            </span>
                        </p>

                        <p className='mb-0 font-size-12 font-weight-600 '>
                            Clicked Date:
                            <span className="font-weight-400 opacity-60">
                            <Moment date={url.date} format="LLL"/>
                            </span>
                        </p>

                    </div>
                    <span className="control">{clicked ? "—" : "+"} </span>
                </button>


                <div className={`accordion_content_wrapper`}
                     ref={contentEl}
                     style={
                         clicked
                             ? { height: contentEl.current.scrollHeight }
                             : { height: "0px" }
                     }>
                <p className='mb-0 font-size-12 font-weight-600 '>
                    UTM Source:
                    <span className="font-weight-400 opacity-60">
                    {params.get('utm_source')}
                    </span>
                </p>

                <p className='mb-0 font-size-12 font-weight-600 '>
                    UTM Medium:
                    <span className="font-weight-400 opacity-60">
                    {params.get('utm_medium')}
                    </span>
                </p>

                <p className='mb-0 font-size-12 font-weight-600 '>
                    UTM Campaign:
                    <span className="font-weight-400 opacity-60">
                    {params.get('utm_campaign')}
                    </span>
                </p>

                <p className='mb-0 font-size-12 font-weight-600 '>
                    UTM Term:
                    <span className="font-weight-400 opacity-60">
                    {params.get('utm_term')}
                    </span>
                </p>

                <p className='mb-0 font-size-12 font-weight-600 '>
                    UTM Content:
                    <span className="font-weight-400 opacity-60">
                    {params.get('utm_content')}
                    </span>
                </p>
                </div>

            </MDBCol>
        </li>)
    }
};

export default LeadClickedInfo;