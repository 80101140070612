import React from 'react';
import { Link } from 'react-router-dom';
import Button from '../Button';

const SocialMedia = ( { classN, socialMediaModalAdd, socialMediaModalEdit, confirmationDelete } ) => {
  return (
    <div className={classN}>
        <div className='inner-title-box'>
            <p>
                Social Media Followers
            </p>
            <Button 
                text='Add Social Media'
                icon='fa-plus'
                onClick={socialMediaModalAdd}
            />
        </div>
        <div className='followers-box-list'>
            <div className='followers'>
                <div className='follower'>
                    <Link>
                        <p><i className="fab fa-facebook-f color-icon1"></i> Facebook</p>
                    </Link>
                    <span>
                        Total: 10,000,000
                    </span>
                    <button className='edit-record' onClick={socialMediaModalEdit}><i className="fas fa-pen"></i>Edit</button>
                    <button className='delete-record' onClick={confirmationDelete}><i className="fas fa-trash-alt"></i>Delete</button>
                </div>
                <div className='follower'>
                    <Link><p><i className="fab fa-instagram color-icon2"></i> Instagram</p></Link>
                    <span>
                        Total: 10,000,000
                    </span>
                    <button className='edit-record' onClick={socialMediaModalEdit}><i className="fas fa-pen"></i>Edit</button>
                    <button className='delete-record' onClick={confirmationDelete}><i className="fas fa-trash-alt"></i>Delete</button>
                </div>
                <div className='follower'>
                    <Link><p><i className="fab fa-twitter color-icon3"></i> Twitter</p></Link>
                    <span>
                        Total: 10,000,000
                    </span>
                    <button className='edit-record' onClick={socialMediaModalEdit}><i className="fas fa-pen"></i>Edit</button>
                    <button className='delete-record' onClick={confirmationDelete}><i className="fas fa-trash-alt"></i>Delete</button>
                </div>
                <div className='follower'>
                    <Link><p><i className="fas fa-globe color-icon0"></i> Others</p></Link>
                    <span>
                        Total: 10,000,000
                    </span>
                    <button className='edit-record' onClick={socialMediaModalEdit}><i className="fas fa-pen"></i>Edit</button>
                    <button className='delete-record' onClick={confirmationDelete}><i className="fas fa-trash-alt"></i>Delete</button>
                </div>
                <div className='follower'>
                    <Link><p><i className="fab fa-youtube color-icon4"></i> Youtube</p></Link>
                    <span>
                        Total: 10,000,000
                    </span>
                    <button className='edit-record' onClick={socialMediaModalEdit}><i className="fas fa-pen"></i>Edit</button>
                    <button className='delete-record' onClick={confirmationDelete}><i className="fas fa-trash-alt"></i>Delete</button>
                </div>
                <div className='follower'>
                    <Link><p><i className="fab fa-tiktok color-icon5"></i> Tiktok</p></Link>
                    <span>
                        Total: 10,000,000
                    </span>
                    <button className='edit-record' onClick={socialMediaModalEdit}><i className="fas fa-pen"></i>Edit</button>
                    <button className='delete-record' onClick={confirmationDelete}><i className="fas fa-trash-alt"></i>Delete</button>
                </div>
                <div className='follower'>
                    <Link><p><i className="fab fa-kumu color-icon6"></i> Kumu</p></Link>
                    <span>
                        Total: 10,000,000
                    </span>
                    <button className='edit-record' onClick={socialMediaModalEdit}><i className="fas fa-pen"></i>Edit</button>
                    <button className='delete-record' onClick={confirmationDelete}><i className="fas fa-trash-alt"></i>Delete</button>
                </div>
            </div>
        </div>
    </div>
  )
}

export default SocialMedia