import React, {useRef, useState} from "react";
import DOMPurify from "dompurify";
import {utilitiesService as utility} from "_services";
import Moment from "react-moment";
import {useParams} from "react-router-dom";
import {influencerActions} from "_actions";
import {useDispatch} from "react-redux";
import ReplyItem from "_pages/Leads/components/LeadInfo/ReplyItem";
import NoteEditor from "./NoteEditor";


const NoteItem = ({ note, onHandleToggleDeleteNoteAction }) => {


    const params = useParams();
    const dispatch = useDispatch();


    let description = note.content.replace(/(?:\r\n|\r|\n)/g, '<br>');
    let content = {__html: DOMPurify.sanitize(description)}
    let author =   utility.decrypt(note.creator.firstName) + ' ' + utility.decrypt(note.creator.lastName);
    let initial =   author.charAt(0).toUpperCase();
    let created = note.updated;
    let noteId = note.id;
    let replies = [];
    // let replies = note.replies;

    let currentUser = utility.getSessionUser();
    let canManage = parseInt(note.creator.id) === parseInt(currentUser.id);

    const addReplyRef = useRef();
    const noteBtnActionRef = useRef();
    const replyRef = useRef();

    const [editNoteClicked, setEditNoteClicked] = useState(false);
    const [btnActionNoteClicked, setBtnActionNoteClicked] = useState(false);
    const [replyClicked, setReplyClicked] = useState(false);

    const handleToggleEditNoteAction = () => {
        setEditNoteClicked(!editNoteClicked);
        setBtnActionNoteClicked(!btnActionNoteClicked);
    };


    const handleCancelUpdate = () => {
        setEditNoteClicked(false);
    }

    const handleToggleReply = () => {
        setReplyClicked(!replyClicked);
    };


    const handleNoteUpdate = (content, mentions) => {

        const projectId = params.id;
        const influencerId = params.influencerId;

        let noteType = 'note';

        const note = {
            title: noteType,
            content: content,
          }

    
        dispatch(influencerActions.updateInfluencerNote(projectId, influencerId, noteId, note));
        setEditNoteClicked(false);
    }

    const handleToggleDeleteNoteAction = () => {
        onHandleToggleDeleteNoteAction(note);
    }

    const handleNoteReply = (content, mentions) =>  {

        const projectId = params.id;
        const leadId = params.leadId;

        let noteData = {
            leadId: parseInt(leadId),
            lead_id: parseInt(leadId),
            name: 'Reply',
            description: content,
            mentions: mentions
        };

        //dispatch(leadActions.addNoteReply(projectId, leadId, noteId, noteData));
        setReplyClicked(false);

    }

    const [isEditorEmpty, setEditorEmpty] = useState(true);

    return (
        <div className='threads'>
            <div className='thread-topic'>
                <div className='thread-creator'>
                    <div className='avatar-box avatar-color-1'>
                        <span>{initial}</span>
                        <p>{author}</p>
                    </div>
                    <div className='timestamp'>
                        <span><Moment date={created} fromNow ago/> ago</span>
                        {canManage && <div className='edit-delete-box' ref={noteBtnActionRef}>
                            <button className="fas fa-ellipsis-v btn-ellipsis">
                                <div className='btn-groups'>
                                    <button onClick={() => handleToggleEditNoteAction()}><i
                                        className="icon-lms icon-edit"></i> Edit
                                    </button>
                                    <button
                                        onClick={() => handleToggleDeleteNoteAction()}
                                    ><i className="icon-lms icon-delete"></i> Delete</button>
                                </div>
                            </button>
                        </div>}
                    </div>
                </div>
                <div className='thread-text'>
                    {! editNoteClicked &&
                            <div className="editor-display-text" dangerouslySetInnerHTML={content}></div>
                    }

                    {editNoteClicked &&
                        <NoteEditor
                            confirmButtonName={'Update'}
                            editorContent={description}
                            onConfirmUpdate={(content, mentions) => handleNoteUpdate(content, mentions)}
                            onCancelUpdate={() => handleCancelUpdate()}
                            setEditorEmpty={setEditorEmpty}
                            isEditorEmpty={isEditorEmpty}
                        />
                    }
                </div>
                {replies.length > 0 &&
                    <div className='thread-reply-box'>
                        {replies.map((reply,index) => {
                            return(<ReplyItem
                                reply={reply}
                                onHandleToggleDeleteNoteAction={(reply) => onHandleToggleDeleteNoteAction(reply)}
                            />);
                        })}
                    </div>
                }
                {/* 
                <div className='make-thread-reply' ref={addReplyRef}>
                    { !replyClicked &&
                    <div className='reply-action'>
                        <button className='plain-btn' onClick={() => handleToggleReply()}>
                            // <i className="icon-lms icon-reply"></i>
                            Reply
                        </button>
                    </div>}
                    { replyClicked &&
                    <NoteEditor
                        confirmButtonName={'Reply'}
                        ref={replyRef}
                        editorContent={''}
                        onConfirmUpdate={(content, mentions) => handleNoteReply(content, mentions)}
                        onCancelUpdate={() => handleToggleReply()}
                        setEditorEmpty={setEditorEmpty}
                        isEditorEmpty={isEditorEmpty}
                    />}
                </div>
                */}
            </div>
        </div>
    );
};

export default NoteItem;