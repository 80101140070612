export const projectConstants = {
  CREATE_REQUEST: 'PROJECTS_CREATE_REQUEST', //
  CREATE_SUCCESS: 'PROJECTS_CREATE_SUCCESS',
  CREATE_FAILURE: 'PROJECTS_CREATE_FAILURE',

  UPDATE_REQUEST: 'PROJECTS_UPDATE_REQUEST', //
  UPDATE_SUCCESS: 'PROJECTS_UPDATE_SUCCESS',
  UPDATE_FAILURE: 'PROJECTS_UPDATE_FAILURE',

  ADD_MEMBER_REQUEST: 'PROJECTS_ADD_MEMBER_REQUEST', //
  ADD_MEMBER_SUCCESS: 'PROJECTS_ADD_MEMBER_SUCCESS',
  ADD_MEMBER_FAILURE: 'PROJECTS_ADD_MEMBER_FAILURE',

  DELETE_MEMBER_REQUEST: 'PROJECTS_DELETE_MEMBER_REQUEST', //
  DELETE_MEMBER_SUCCESS: 'PROJECTS_DELETE_MEMBER_SUCCESS',
  DELETE_MEMBER_FAILURE: 'PROJECTS_DELETE_MEMBER_FAILURE',

  GETALL_REQUEST: 'PROJECTS_GETALL_REQUEST', //
  GETALL_SUCCESS: 'PROJECTS_GETALL_SUCCESS',
  GETALL_FAILURE: 'PROJECTS_GETALL_FAILURE',

  GET_REQUEST: 'PROJECT_GET_REQUEST', //
  GET_SUCCESS: 'PROJECT_GET_SUCCESS',
  GET_FAILURE: 'PROJECT_GET_FAILURE',

  DELETE_REQUEST: 'PROJECTS_DELETE_REQUEST', //
  DELETE_SUCCESS: 'PROJECTS_DELETE_SUCCESS',
  DELETE_FAILURE: 'PROJECTS_DELETE_FAILURE',

  CREATE_STATUS_REQUEST: 'PROJECTS_CREATE_STATUS_REQUEST', //
  CREATE_STATUS_SUCCESS: 'PROJECTS_CREATE_STATUS_SUCCESS',
  CREATE_STATUS_FAILURE: 'PROJECTS_CREATE_STATUS_FAILURE',

  UPDATE_STATUS_REQUEST: 'PROJECTS_UPDATE_STATUS_REQUEST', //
  UPDATE_STATUS_SUCCESS: 'PROJECTS_UPDATE_STATUS_SUCCESS',
  UPDATE_STATUS_FAILURE: 'PROJECTS_UPDATE_STATUS_FAILURE',

  DELETE_STATUS_REQUEST: 'PROJECTS_DELETE_STATUS_REQUEST', //
  DELETE_STATUS_SUCCESS: 'PROJECTS_DELETE_STATUS_SUCCESS',
  DELETE_STATUS_FAILURE: 'PROJECTS_DELETE_STATUS_FAILURE',

  GET_LOG_REQUEST: 'PROJECT_ACTIVITIES_LOG_REQUEST',
  GET_LOG_SUCCESS: 'PROJECT_ACTIVITIES_LOG_SUCCESS',
  GET_LOG_FAILURE: 'PROJECT_ACTIVITIES_LOG_FAILURE',

  GET_ALL_MEMBER_REQUEST: 'PROJECT_MEMBER_GETALL_REQUEST',
  GET_ALL_MEMBER_SUCCESS: 'PROJECT_MEMBER_GETALL_SUCCESS',
  GET_ALL_MEMBER_FAILURE: 'PROJECT_MEMBER_GETALL_FAILURE',

  GET_ALL_STATUS_REQUEST: 'PROJECT_STATUS_GETALL_REQUEST',
  GET_ALL_STATUS_SUCCESS: 'PROJECT_STATUS_GETALL_SUCCESS',
  GET_ALL_STATUS_FAILURE: 'PROJECT_STATUS_GETALL_FAILURE',

  GET_STATUS_REQUEST: 'PROJECT_STATUS_GET_REQUEST',
  GET_STATUS_SUCCESS: 'PROJECT_STATUS_GET_SUCCESS',
  GET_STATUS_FAILURE: 'PROJECT_STATUS_GET_FAILURE',

  GET_STATUS_COUNT_REQUEST: 'PROJECT_STATUS_COUNT_GET_REQUEST',
  GET_STATUS_COUNT_SUCCESS: 'PROJECT_STATUS_COUNT_GET_SUCCESS',
  GET_STATUS_COUNT_FAILURE: 'PROJECT_STATUS_COUNT_GET_FAILURE',

  UPDATE_PROJECT_STATUS_REQUEST: 'UPDATE_PROJECT_STATUS_REQUEST', //
  UPDATE_PROJECT_STATUS_SUCCESS: 'UPDATE_PROJECT_STATUS_SUCCESS',
  UPDATE_PROJECT_STATUS_FAILURE: 'UPDATE_PROJECT_STATUS_FAILURE',

  ENABLE_STATUS_REQUEST: 'ENABLE_PROJECT_STATUS_REQUEST', //
  ENABLE_STATUS_SUCCESS: 'ENABLE_PROJECT_STATUS_SUCCESS',
  ENABLE_STATUS_FAILURE: 'ENABLE_PROJECT_STATUS_FAILURE',

  CREATE_DEFAULT_STATUS_REQUEST: 'CREATE_DEFAULT_PROJECT_STATUS_REQUEST',
  CREATE_DEFAULT_STATUS_SUCCESS: 'CREATE_DEFAULT_PROJECT_STATUS_SUCCESS',
  CREATE_DEFAULT_STATUS_FAILURE: 'CREATE_DEFAULT_PROJECT_STATUS_FAILURE',

  CREATE_CONVERSION_STATUS_REQUEST: 'CREATE_CONVERSION_PROJECT_STATUS_REQUEST',
  CREATE_CONVERSION_STATUS_SUCCESS: 'CREATE_CONVERSION_PROJECT_STATUS_SUCCESS',
  CREATE_CONVERSION_STATUS_FAILURE: 'CREATE_CONVERSION_PROJECT_STATUS_FAILURE',
};
