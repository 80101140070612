import React, { useEffect, useState } from "react";
import {
  MDBContainer,
  MDBRow,
  MDBCol,
  MDBModal,
  MDBModalHeader,
  MDBModalBody,
} from "mdbreact";
import { utilitiesService } from "_services";

const ShareEmailModal = ({ isShareModal, toggleShareModal, template }) => {
  return (
    <MDBContainer className="modal-container confirmation-modal-container modal-basic">
      <MDBRow>
        <MDBCol>
          <MDBModal
            className="confirmation-modal"
            isOpen={isShareModal}
            centered
            tabIndex="-1"
          >
            <MDBModalBody>
              <MDBRow className="form-group-row">
                <MDBCol className="col-12">
                  <span className="icon-custom">
                    <i className="icon-lms icon-share"></i>
                  </span>
                  {template != undefined && template.private && (
                    <div>
                      <h3>
                        Sharing
                        <br />
                        {template != undefined
                          ? utilitiesService.decrypt(template.title)
                          : ""}
                      </h3>
                      <p>
                        Sharing an email template will allow others to duplicate
                        and edit its content
                      </p>
                    </div>
                  )}
                  {template != undefined && !template.private && (
                    <div>
                      <h3>
                        Removing share of
                        <br />
                        {template != undefined
                          ? utilitiesService.decrypt(template.title)
                          : ""}
                      </h3>
                      <p>
                        Removing share of email template will not allow others to
                        duplicate and edit its content
                      </p>
                    </div>
                  )}
                </MDBCol>
              </MDBRow>
              <MDBRow>
                <MDBCol className="modal-btns text-center">
                  <button
                    className="c-btn c-btn-light"
                    onClick={() => toggleShareModal(false)}
                  >
                    Cancel
                  </button>
                  <button
                    className="c-btn c-btn-blue"
                    onClick={() => toggleShareModal(true)}
                  >
                    {template && template.private ? 'Share' : 'Remove Share'}
                  </button>
                </MDBCol>
              </MDBRow>
            </MDBModalBody>
          </MDBModal>
        </MDBCol>
      </MDBRow>
    </MDBContainer>
  );
};

export default ShareEmailModal;
