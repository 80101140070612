import React, {Component} from 'react'
import { MDBContainer, MDBRow, MDBCol, MDBModal, MDBModalHeader, MDBModalBody } from 'mdbreact'
import {fileActions, influencerActions} from "_actions";
import {connect} from "react-redux";

class DeleteLeadFileModal extends Component {

  constructor(props) {
    super(props);

    this.state = {
      value: '',
      link: '',
      isOthers: false,
    }
  }

  handleCloseConfirmationDeleteModal = () => {
    this.props.onHandleCloseConfirmationDeleteModal();
  }

  setValue = value => {
    this.setState({value: value});
  }

  setLink = value => {
    this.setState({link: value});
  }

  handleDeleteDataConfirm () {
    const {
      leadId,
      projectId,
      deleteLeadFile,
      data, type} = this.props;


    switch (type) {

      case 'file':
        deleteLeadFile(projectId, leadId, data.id);
        break;

      default:
        break;
    }

    this.handleCloseConfirmationDeleteModal();
  }

  render() {

    const {isOpen, data , type} = this.props;


    let identifier = '';
    switch (type) {

      case 'file':
        identifier = `(${data.name}) - ${data.link}`;
        break;

      default:
        break;
    }

    return (
        <MDBContainer className='modal-container confirmation-modal-container'>
          <MDBRow>
            <MDBCol>
              <MDBModal className='confirmation-modal' isOpen={isOpen}  centered tabIndex='-1'>
                {/* <MDBModalHeader className='confirmation-modal-header text-uppercase p-2'><span className='pl-1 mx-2 letter-spacing-1 font-weight-600 font-color-gray font-size-13'>CONFIRM DELETE {type.toUpperCase()}</span></MDBModalHeader> */}
                <MDBModalBody>
                  <MDBRow className='form-group-row'>
                    <MDBCol className='col-12'>
                      <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none" stroke="currentColor" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" class="feather feather-alert-circle"><circle cx="12" cy="12" r="10"/><line x1="12" y1="8" x2="12" y2="12"/><line x1="12" y1="16" x2="12.01" y2="16"/></svg>
                      <h3>Confirm Deletion</h3>
                      <p>Are you sure you want to delete this entry <br></br><span>{identifier}</span> ?</p>
                    </MDBCol>
                  </MDBRow>
                  <MDBRow>
                    <MDBCol className='modal-btns text-center'>
                      <button className='c-btn c-btn-light' onClick={()=> this.handleCloseConfirmationDeleteModal()}>
                        Cancel
                      </button>
                      <button className='c-btn c-btn-red' onClick={()=> this.handleDeleteDataConfirm()}>
                        Delete
                      </button>
                    </MDBCol>
                  </MDBRow>
                </MDBModalBody>
              </MDBModal>
            </MDBCol>
          </MDBRow>
        </MDBContainer>
    )
  }

}

function mapState(state) {
  const { influencers } = state;
  return { influencers }
}

const actionCreators = {
  deleteLeadFile : fileActions.deleteFile,
};

const connectedViewDeleteLeadFileModal= connect(mapState, actionCreators)(DeleteLeadFileModal);
export { connectedViewDeleteLeadFileModal as DeleteLeadFileModal };