import React, {useState, useRef, useEffect} from 'react';
import {MDBRow} from 'mdbreact';
import TopBar from '../../_components/TopBar';
import IntervalControls from './components/members/IntervalControls';
import IntervalPagination from './components/members/IntervalPagination';
import MemberTable from './components/members/MemberTable';
import AutoAssignConfigDrawer from './components/members/AutoAssignConfigDrawer';
import ParameterDrawer from './components/members/ParameterDrawer';
import ProjectMembers from '../../_components/Drawer/ProjectMembers';
import {useDispatch, useSelector} from "react-redux";
import {projectActions} from "_actions";
import {useParams, Link} from "react-router-dom";

import {Button} from "blackbox-react";
import {managerActions} from "_actions/manager.actions";
import DeleteMemberModal from '../../_components/Modal/DeleteMemberModal';

const Members = () => {

    const dispatch = useDispatch();
    const params = useParams();
    const [isProjectMembersDrawer, setIsProjectMembersDrawer] = useState(false);
    const [isAddInterval, setIsAddInterval] = useState(false);
    const [autoConfigId, setAutoConfigId] = useState(null);
    const [activeParams, setActiveParams] = useState([]);
    const [isParameters, setIsParameters] = useState(false);
    const [deleteData, setDeleteData] = useState(false);
    const [deleteMemberModal, setIsDeleteMemberModal] = useState(false);

    useEffect(() => {
        dispatch(projectActions.getProject(params.id));
        dispatch(managerActions.getManagers(params.id));
    }, [params.id]);

    const config = useSelector((state) => {
        const {managers} = state;
        if (managers.getManagersConfigLoaded) {
            const {config} = managers;
            return config.configs;
        }
        return [];
    });

    const updateManagerConfigLoaded = useSelector((state) => state.managers.updateManagerConfigLoaded);
    const addMemberSuccess = useSelector((state) => state.projects.addMembersSuccess);
    const getManagersConfigLoaded = useSelector((state) => state.managers.getManagersConfigLoaded);
    const deleteManagerSuccess = useSelector((state) => state.managers.deleteManagerSuccess);

    useEffect(() => {
        if(getManagersConfigLoaded) {
            setActiveParams(config);
        }
    },[config]);

    useEffect( ()=> {
        if(updateManagerConfigLoaded) {
            handleCloseAddInterval();
        }
    }, [updateManagerConfigLoaded]);

    useEffect( ()=> {
        if(addMemberSuccess) {
            handleCloseProjectMembersDrawer();
        }
    }, [addMemberSuccess]);

    useEffect( ()=> {
        if(deleteManagerSuccess) {
            handleCloseDeleteModal();
        }
    }, [deleteManagerSuccess]);

    const handleProjectMembersDrawer = () => {
        setIsProjectMembersDrawer(!isProjectMembersDrawer);
    }

    const handleCloseProjectMembersDrawer = () => {
        setIsProjectMembersDrawer(false);
    }

    const handleOpenAddInterval = (id) => {
        setIsAddInterval(true);
        setAutoConfigId(id);
    }

    const handleCloseAddInterval = () => {
        setIsAddInterval(false);
        setIsParameters(false);
    }

    const handleOpenParametersDrawer = () => {
        setIsParameters(true);
    }

    const handleCloseParametersDrawer = () => {
        setIsParameters(false);
    }

    const handleActiveParams = (params) => {
        setActiveParams(params);
    }

    const handleOpenDeleteMember = (manager) => {
        setDeleteData(manager);
        setIsDeleteMemberModal(true);
    };

    function handleCloseDeleteModal() {
        setDeleteData(null);
        setIsDeleteMemberModal(false);
    }

    function handleDeleteConfirm(data) {
        dispatch(managerActions.deleteManager(params.id, data.auto_assign_config.id));
    }

    const [isTabActive, setIsTabActive] = useState(false);
    let tabActive = isTabActive ? 2 : 1;

    // console.log(tabActive);

    return (
        <>
            <div className='topbar-menu'>
                <MDBRow className='row align-items-center'>
                    <TopBar
                        onHandleProjectDetailsDrawer={(width, project) => this.handleProjectDetailsDrawer(width, project)}
                        onHandleProjectMembersDrawer={(width, owner, member) => this.handleProjectMembersDrawer(width, owner, member)}
                    />

                    <div className='col'>
                        <div className='top-button-box'>
                                <Button
                                    className="c-btn"
                                    onClick={() => handleProjectMembersDrawer()}>
                                    <i className="icon-lms icon-add-user"></i>
                                    Add Member
                                </Button>
                        </div>
                    </div>
                </MDBRow>
            </div>

            <div className='overview-menu overview-menu-w-breadcrumb'>
                <div>
                    <div className='breadcrumb-overview'>
                        <div className='breadcrumbs'>
                            {
                                params.id == 1 &&
                                <Link to={`/project/${params.id}/leads`} className="back">
                                    <span className="icon-s">
                                    <i className="icon-lms icon-arrow-left"></i>
                                    </span>
                                </Link>
                            }
                            {
                                params.id == 3 &&
                                <Link to={`/project/${params.id}/leads`} className="back">
                                    <span className="icon-s">
                                    <i className="icon-lms icon-arrow-left"></i>
                                    </span>
                                </Link>
                            }
                            {
                            params.id == 5 &&
                                <Link to={`/project/${params.id}/leads`} className="back">
                                    <span className="icon-s">
                                    <i className="icon-lms icon-arrow-left"></i>
                                    </span>
                                </Link>
                            }
                            {
                                params.id == 6 &&
                                <Link to={`/project/${params.id}/leads`} className="back">
                                    <span className="icon-s">
                                    <i className="icon-lms icon-arrow-left"></i>
                                    </span>
                                </Link>
                            }
                            {
                                params.id == 4 &&
                                <Link to={`/project/${params.id}/influencers`} className="back">
                                    <span className="icon-s">
                                    <i className="icon-lms icon-arrow-left"></i>
                                    </span>
                                </Link>
                            }
                            <span>Project Members</span>
                        </div>
                    </div>
                    <IntervalControls/>
                </div>
            </div>

            <div className='main-table-section member-table-section'>
                <MemberTable
                    onHandleOpenEditInterval={(id) => handleOpenAddInterval(id)}
                    onHandleCloseAddInterval={() => handleCloseAddInterval()}
                    onHandleDeleteMember={(manager) => handleOpenDeleteMember(manager)}
                    setIsTabActive={setIsTabActive}
                />
                {/* <IntervalPagination /> */}
            </div>

            <ProjectMembers
                isProjectMembersDrawer={isProjectMembersDrawer}
                onHandleProjectMembersDrawer={() => handleProjectMembersDrawer()}
            />

            <AutoAssignConfigDrawer
                autoConfigId={autoConfigId}
                isAddInterval={isAddInterval}
                activeParams={activeParams}
                onHandleParameterAdd={(params) => handleActiveParams(params)}
                onHandleCloseAddInterval={() => handleCloseAddInterval()}
                isParameters={isParameters}
                onHandleOpenParameters={() => handleOpenParametersDrawer()}
                tabActive={tabActive}
            />

            <ParameterDrawer
                activeParams={activeParams}
                autoConfigId={autoConfigId}
                isParameters={isParameters}
                onHandleParameterAdd={(params)=> handleActiveParams(params)}
                onHandleCloseParameters={() => handleCloseParametersDrawer()}
            />

            <DeleteMemberModal
                deleteData={deleteData}
                open={deleteMemberModal}
                onHandleDeleteMemberConfirm={(data) => handleDeleteConfirm(data)}
                onHandleCloseDeleteModal={() => handleCloseDeleteModal()}
            />
        </>
    )
}

export default Members