import React from "react";

const TextLoader = () => {
  return (
    <div className={`skeleton-loader text-loader`}>
        <div className="skeleton skeleton-box"></div>
    </div>
  );
};

export default TextLoader;
