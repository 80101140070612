import React from 'react';
import { Provider } from 'react-redux';
import {createRoot} from 'react-dom/client';

import { store } from '_helpers';

import '@fortawesome/fontawesome-free/css/all.min.css';
import 'bootstrap-css-only/css/bootstrap.min.css';
import 'mdbreact/dist/css/mdb.css';
import 'rsuite/dist/rsuite.min.css';

import App from './App'

const root = createRoot(document.getElementById("root"));

root.render(
		<Provider store={store}>
			<App />
		</Provider>
);


// TODO: Assess if we need this serviceWorker
// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA
// serviceWorker.unregister();
// reportWebVitals();