import React, { useState } from 'react';
import { MDBContainer, MDBRow, MDBCol, MDBModal, MDBModalHeader, MDBModalBody } from 'mdbreact';
import { EditorState } from 'draft-js';
// import { Editor } from 'react-draft-wysiwyg';
import { convertToHTML } from 'draft-convert';
import DOMPurify from 'dompurify';
// import 'react-draft-wysiwyg/dist/react-draft-wysiwyg.css';

const AddRateModal = ({ open, toggle, close }) => {
  
  const [editorState, setEditorState] = useState(
    () => EditorState.createEmpty(),
  );

  const  [convertedContent, setConvertedContent] = useState(null);
  const handleEditorChange = (state) => {
    setEditorState(state);
    convertContentToHTML();
  }
  const convertContentToHTML = () => {
    let currentContentAsHTML = convertToHTML(editorState.getCurrentContent());
    setConvertedContent(currentContentAsHTML);
  }
  const createMarkup = (html) => {
    return  {
      __html: DOMPurify.sanitize(html)
    }
  }

  return (
    <MDBContainer className='modal-container modal-form-container modal-rate'>
        <MDBRow>
          <MDBCol>
            <MDBModal isOpen={open} toggle={toggle} centered tabIndex='-1'>
              <MDBModalHeader className='confirmation-modal-header text-uppercase p-2'><span className='pl-1 mx-2 letter-spacing-1 font-weight-600 font-color-gray font-size-13'>ADD RATE</span></MDBModalHeader>
              <MDBModalBody>
                <MDBRow className='form-group-row'>
                  <MDBCol className='col-12'>
                        {/*<Editor */}
                        {/*  editorState={editorState}*/}
                        {/*  onEditorStateChange={handleEditorChange}*/}
                        {/*  wrapperClassName="wrapper-class"*/}
                        {/*  editorClassName="editor-class"*/}
                        {/*  toolbarClassName="toolbar-class"*/}
                        {/*/>*/}
                        <div className="preview" dangerouslySetInnerHTML={createMarkup(convertedContent)}></div>
                  </MDBCol>
                </MDBRow>
                <MDBRow>
                  <MDBCol className='text-center'>
                    <button className='modal-btn save-btn-modal'>
                        Save Rate
                    </button>
                    <button className='modal-btn cancel-btn-modal' onClick={close}>
                        Cancel
                    </button>
                  </MDBCol>
                </MDBRow>
              </MDBModalBody>
            </MDBModal>
          </MDBCol>
        </MDBRow>
      </MDBContainer>
  )
}

export default AddRateModal