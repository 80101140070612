import React, { Component } from "react";
import { leadActions, chatActions } from "_actions";
import { connect } from "react-redux";
import { getLeadSubIdentifier, utilitiesService as utility } from "_services";
import { getLeadIdentifier } from "_services/utilities.service";
import {} from "_services";
import { Link } from "react-router-dom";
import Moment from "react-moment";
import PhiColumns from "_pages/Leads/components/Table/PrimaryHomesLeadColumns";
import ThamColumns from "_pages/Leads/components/Table/MaayoLeadColumns";
import {
  defaultConfig,
  propelrrServiceConfig,
  propelrrCareerConfig,
  maayoConfig,
  phiConfig,
  dmciLeasingConfig,
} from "_pages/Leads/constants/TableHeaderConfig";
import PropelrrServiceColumns from "_pages/Leads/components/Table/PropelrrServiceLeadColumns";
import PropelrrCareersColumns from "_pages/Leads/components/Table/PropelrrCareersLeadColumns";
import { history } from "_helpers";
import DemoLeadColumns from "_pages/Leads/components/Table/DemoLeadColumns";
import DmciLeasingColumns from "./DmciLeasingColumns";

class LeadViewList extends Component {
  constructor(props) {
    super(props);
    this.state = {
      selectedLeadIds: [],
    };
  }

  handleTextEllipsis(text, max) {
    return text.substr(0, max) + (text.length > max ? "..." : "");
  }

  handleRedirectLead(leadId) {
    const { projectId } = this.props;
    history.push(`/project/${projectId}/leads/${leadId}`);
  }

  handleCheckboxChange = (leadId) => {
    this.setState(
      (prevState) => {
        const updatedLeadIds = prevState.selectedLeadIds.includes(leadId)
          ? prevState.selectedLeadIds.filter((id) => id !== leadId)
          : [...prevState.selectedLeadIds, leadId];
  
        this.props.onHandleOpenTableAction(updatedLeadIds);
  
        return { selectedLeadIds: updatedLeadIds };
      }
    );
  };
  

  // handleCheckboxChange = (leadId) => {
  //   // Use the callback function to ensure that the state is updated before performing actions
  //   this.setState(
  //     (prevState) => {
  //       const updatedLeadIds = prevState.selectedLeadIds.includes(leadId)
  //         ? prevState.selectedLeadIds.filter((id) => id !== leadId)
  //         : [...prevState.selectedLeadIds, leadId];
  
  //       return { selectedLeadIds: updatedLeadIds };
  //     },
  //     () => {
  //       // Now, the state has been updated, and you can perform additional actions
  //       const updatedLeadIds = this.state.selectedLeadIds;
  //       this.props.onHandleOpenTableAction(updatedLeadIds);
  //     }
  //   );
  // };

  // handleHeaderCheckboxChange = () => {
  //   const { leads, onHandleOpenTableAction } = this.props;
  //   const allLeadIds = leads.data.content.map((lead) => lead.id);

  //   let updatedLeadIds = [];

  //   // If all leads are selected, unselect all; otherwise, select all
  //   if (this.state.selectedLeadIds.length !== allLeadIds.length) {
  //     updatedLeadIds = [...allLeadIds];
  //   }

  //   this.setState({
  //     selectedLeadIds: updatedLeadIds,
  //   });

  //   onHandleOpenTableAction(updatedLeadIds);
  // };


  handleCheckboxChange = (leadId) => {
    this.setState(
      (prevState) => {
        const updatedLeadIds = prevState.selectedLeadIds.includes(leadId)
          ? prevState.selectedLeadIds.filter((id) => id !== leadId)
          : [...prevState.selectedLeadIds, leadId];
  
        this.props.onHandleOpenTableAction(updatedLeadIds);
  
        return { selectedLeadIds: updatedLeadIds };
      },
      () => {
        // Check if not all leads are selected and update the header checkbox state
        if (this.state.selectedLeadIds.length !== this.props.leads.data.content.length) {
          this.props.onHandleOpenTableAction(this.state.selectedLeadIds, false);
        }
      }
    );
  };
  
  handleHeaderCheckboxChange = () => {
    const { leads, onHandleOpenTableAction } = this.props;
    const allLeadIds = leads.data.content.map((lead) => lead.id);
  
    let updatedLeadIds = [];
  
    // If all leads are selected, unselect all; otherwise, select all
    if (this.state.selectedLeadIds.length !== allLeadIds.length) {
      updatedLeadIds = [...allLeadIds];
    }
  
    // Update the header checkbox state
    onHandleOpenTableAction(updatedLeadIds, true);
  
    this.setState({
      selectedLeadIds: updatedLeadIds,
    });
  };
  

  componentDidUpdate(prevProps, prevState) {
    const { headerCheckboxChecked, leads, onHandleOpenTableAction, isTableActionBox } = this.props;
    const allLeadIds = leads.data.content.map((lead) => lead.id);
  
    // Check if header checkbox is checked and was unchecked previously
    if (headerCheckboxChecked && !prevProps.headerCheckboxChecked) {
      this.handleHeaderCheckboxChange();
    } 
    
    if (!headerCheckboxChecked && prevProps.headerCheckboxChecked && (allLeadIds.length == this.state.selectedLeadIds.length)) {
      // Reset the selectedLeadIds when the header checkbox is unchecked
      this.setState({
        selectedLeadIds: [],
      });
      onHandleOpenTableAction([], false);
    }

    if(!isTableActionBox && prevProps.isTableActionBox) {
      this.setState({
        selectedLeadIds: [],
      });
      onHandleOpenTableAction([], false);
    }
  
    // Check if selectedLeadIds state has changed
    if (prevState.selectedLeadIds !== this.state.selectedLeadIds) {
  
      // Perform additional actions if needed
      // For example, update the current state if not equal
      if (prevState.selectedLeadIds !== this.state.selectedLeadIds) {
        this.setState({
          selectedLeadIds: this.state.selectedLeadIds,
        });
      }
  
      // Now you can perform additional actions
      // ...
    }
  }
  

  render() {
    const { summary, leadsLoaded, leads, projectId, headerCheckboxChecked } =
      this.props;
    const { selectedLeadIds } = this.state;

    let config;
    switch (parseInt(projectId)) {
      case 1:
        config = phiConfig;
        break;
      case 3:
        config = maayoConfig;
        break;
      case 5:
        config = propelrrServiceConfig;
        break;
      case 6:
        config = propelrrCareerConfig;
        break;
      case 8:
        config = dmciLeasingConfig;
        break;
      default:
        config = defaultConfig;
        break;
    }


    let categories = [];
    if (summary.summaryLoaded) {
      summary.page.data.forEach((elem) => {
        let key = elem["category"];
        if (!categories.includes(key)) {
          categories.push(key);
        }
      });
    }

    if (leadsLoaded && leads.data.content.length > 0) {
      let data = leads.data.content;
      return (
        <React.Fragment>
          {data.map((lead, index) => {
            let identifierValue = getLeadIdentifier(
              lead.identifier,
              lead.identifier_type,
              lead.basic_info
            );
            let subIdentifierValue = getLeadSubIdentifier(
              lead.sub_identifier,
              lead.sub_identifier_type,
              lead.basic_info
            );
            let color = categories.indexOf(lead.project_status.category) + 1;

            let owner = lead.owner
              ? utility.decrypt(lead.owner.first_name) +
                " " +
                utility.decrypt(lead.owner.last_name)
              : "Unassigned";

            let leadChat = lead.lead_chat;
            let leadEmail = lead.lead_email;

            return (
              <tr key={index} class={`${selectedLeadIds.includes(lead.id) ? 'active' : ''}`}>
                <td>
                  <div className="name-td">
                    {/* <div className={`custom-avatar avatar-${color}`}>
                                                <span>
                                                    {identifierValue.slice(0, 1)}
                                                </span>
                                            </div> */}
                    <div className="checkbox-group" key={index}>
                      <input
                        type="checkbox"
                        name={`table-checkbox-${index}`}
                        checked={
                          selectedLeadIds.includes(lead.id)
                        }
                        onChange={() => this.handleCheckboxChange(lead.id)}
                        id={`table-checkbox-${index}`}
                        // onChange={onHandleOpenTableAction}
                      />
                      <label htmlFor={`table-checkbox-${index}`}></label>
                    </div>
                    <div
                      className="name-info"
                      onClick={() => this.handleRedirectLead(lead.id)}
                    >
                      <div className="main-name">
                        <span className="name">
                          {this.handleTextEllipsis(identifierValue || "", 25)}
                        </span>
                        <div className="notif-box">
                          {leadChat && (
                            <div>
                              <i className="fas fa-comment-alt"></i>
                              <span
                                className={lead.chat_unread == 0 ? "empty" : 0}
                              >
                                {
                                  lead.chat_unread > 9 ? "9+" : lead.chat_unread
                                }
                              </span>
                            </div>
                          )}
                          {leadEmail && (
                            <div>
                              <i className="fas fa-envelope"></i>
                              <span
                                className={lead.email_unread == 0 ? "empty" : 0}
                              >
                                {
                                lead.email_unread > 9 ? "9+" : lead.email_unread
                                }
                              </span>
                            </div>
                          )}
                        </div>
                      </div>
                      <div className="others-info">
                        {subIdentifierValue.map((sub, index) => {
                          return (
                            <span key={index} className="others-text">
                              <i>
                                {this.handleTextEllipsis(sub.value || "", 30)}
                              </i>
                              {lead.verified && sub.verify_type === "email" && (
                                <i className="fas fa-check-circle"></i>
                              )}
                              {lead.phone_verified &&
                                sub.verify_type === "phone" && (
                                  <i className="fas fa-check-circle"></i>
                                )}
                            </span>
                          );
                        })}
                      </div>
                    </div>
                  </div>
                </td>

                <td className={`status--box g-status--color-${color}`}>
                  <div>
                    <span className={`status-text`}>
                      {lead.project_status ? lead.project_status.name : ""}
                    </span>
                    <span className="time-text">
                      Updated: <Moment date={lead.updated} fromNow ago /> ago
                    </span>
                  </div>
                </td>

                {parseInt(projectId) === 1 && <PhiColumns lead={lead} />}

                {parseInt(projectId) === 3 && <ThamColumns lead={lead} />}

                {parseInt(projectId) === 5 && (
                  <PropelrrServiceColumns lead={lead} />
                )}

                {parseInt(projectId) === 6 && (
                  <PropelrrCareersColumns lead={lead} />
                )}

                {parseInt(projectId) === 8 && (
                  <DmciLeasingColumns lead={lead} />
                )}

                {parseInt(projectId) === 7 && <DemoLeadColumns lead={lead} />}

                <td>
                  <span>
                    <Moment date={lead.created} format="LLL" />
                  </span>
                </td>
              </tr>
            );
          })}
        </React.Fragment>
      );
    }

    return (
      <tr>
        <td
          className="loading-notfound-box"
          colSpan={config.length}
          rowSpan={1}
        >
          {!leadsLoaded && (
            <div className="loading-box active">
              <div className="loader-box">
                <h3 className="loading-text">Loading Leads</h3>
                <div className="loader-custom">
                  <span></span>
                  <span></span>
                  <span></span>
                  <span></span>
                  <span></span>
                </div>
              </div>
            </div>
          )}
          {leadsLoaded && (
            <div className="loading-box active">
              <h3 className="loading-text">No Lead Found</h3>
            </div>
          )}
        </td>
      </tr>
    );
  }
}

function mapState(state) {
  const { leads, leadsLoaded } = state.leads;
  const { summary } = state;
  return { leads, leadsLoaded, summary };
}

const actionCreators = {
  getLeads: leadActions.getLeadsPaginated,
  getLeadSummary: leadActions.getLeadSummary,
};

const connectedViewTableLeadViewList = connect(
  mapState,
  actionCreators
)(LeadViewList);
export { connectedViewTableLeadViewList as LeadViewList };