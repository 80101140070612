import React, { useState, useRef, useEffect } from "react";
import InputForm from "_components/InputForm";
import { useDispatch, useSelector } from "react-redux";
import { useParams } from "react-router-dom";
import { deckActions, projectActions } from "_actions";
import { utilitiesService } from "_services";

import ManageDeckDeleteModal from './ManageDeckDeleteModal';

const ManageDecksDrawer = ({
  onHandleManageDecksDrawer,
  isManageDecksDrawer,
  onHandleDecksDrawer,
  onHandleCloseManageDecksDrawer,
  isHandleDecksDrawer,
}) => {
  const [toggleTabState, setToggleTabState] = useState(1);
  const [isDeckSearch, setIsDeckSearch] = useState(false);
  const [isDeckSearchValue, setIsDeckSearchValue] = useState("");
  const [isDeckChecked, setIsDeckChecked] = useState(false);
  const [deckName, setDeckName] = useState("");
  const [deckLink, setDecLink] = useState("");
  const [deckItems, setDeckItems] = useState([]);
  const [checkedDecks, setCheckedDecks] = useState([]);
  const [filteredDecks, setFilteredDecks] = useState([]);
  const [isDeckDelete, setIsDeckDelete] = useState(false);
  const [deckId, setDeckId] = useState("");

  const options = {
    year: "numeric",
    month: "numeric",
    day: "numeric",
    hour: "numeric",
    minute: "numeric",
  };

  const deckSearchRef = useRef();
  const deckSearchInputRef = useRef();
  const dispatch = useDispatch();
  const param = useParams();
  const { id } = useParams();
  const prevIdRef = useRef(null);

  let deck = useSelector((state) => state.deck);
  let getdeckLoading = useSelector((state) => state.deck.getdeckLoading);

  useEffect(() => {
    // Only trigger the API call if the id has changed
    if (id !== prevIdRef.current) {
      dispatch(deckActions.getDecks(id));

      // Update the ref with the current id
      prevIdRef.current = id;
    }
  }, [id, dispatch]);

  useEffect(() => {
    if (!getdeckLoading && deck.decks && deck.decks.data !== undefined) {
      //   console.log("deck", deck);
      setDeckItems(deck.decks.data);
    }
  }, [getdeckLoading, deck.decks]);

  useEffect(() => {
    if (deckItems.length > 0 && isDeckSearchValue.trim() !== "") {
      // Filter decks based on name or link containing the search value
      const filtered = deckItems.filter(
        (deckItem) =>
          deckItem.name
            .toLowerCase()
            .includes(isDeckSearchValue.toLowerCase()) ||
          deckItem.link.toLowerCase().includes(isDeckSearchValue.toLowerCase())
      );
      setFilteredDecks(filtered);
    } else {
      setFilteredDecks(deckItems);
    }
  }, [deckItems, isDeckSearchValue]);

  const handleChangeDeckSearch = (event) => {
    setIsDeckSearchValue(event.target.value);
  };

  const handleDeckItemClick = (deckItem) => {
    onHandleDecksDrawer(deckItem);
  };


  // console.log('side open: ', isHandleDecksDrawer);

  const handleOpenDeckSearch = () => {
    setIsDeckSearch(true);
  };

  const handleClearDeckSearch = () => {
    setIsDeckSearch(false);
    setIsDeckSearchValue("");
  };

  const handleDeckSearch = () => {
    // Optionally, you can also filter decks when the arrow-right button is clicked
    const filtered = deckItems.filter(
      (deckItem) =>
        deckItem.name.toLowerCase().includes(isDeckSearchValue.toLowerCase()) ||
        deckItem.link.toLowerCase().includes(isDeckSearchValue.toLowerCase())
    );
    setFilteredDecks(filtered);
  };

  const handleDeleteDeck = (id) => {
    dispatch(deckActions.deleteDeck(param.id, id));
  };

  const handleCreateDeck = () => {};

  const toggleTab = (index) => {
    setToggleTabState(index);
  };

  const handleDeckCheckbox = (event, deckItem) => {
    const isChecked = event.target.checked;

    // Update the array of checked decks based on checkbox status
    if (isChecked) {
      setCheckedDecks((prevChecked) => [...prevChecked, deckItem]);
    } else {
      setCheckedDecks((prevChecked) =>
        prevChecked.filter((item) => item.id !== deckItem.id)
      );
    }
  };

  const handleDeleteDecks = () => {
    // Log the list of checked decks to the console
    // console.log('Checked Decks:', checkedDecks);

    // Perform deletion logic here
    // You can access the list of checked decks from the checkedDecks state

    // Clear the list of checked decks
    const request = { decks: checkedDecks };
    // console.log('request: ', request)
    dispatch(deckActions.deleteDecks(param.id, request));

    setCheckedDecks([]);
    // onHandleDecksDrawer();
  };

  const handleCloseDecks = () => {
    setIsDeckSearch(false);
    setIsDeckSearchValue("");
    setCheckedDecks([]);
    return onHandleCloseManageDecksDrawer();
  };

  const handleOpenDeleteModal = (id = null) => {
    setIsDeckDelete(true);
    if(id) {
      setDeckId(id);
    }

    if(isDeckDelete) {
      setIsDeckDelete(false);
      if(deckId && typeof deckId !== 'object') {
        handleDeleteDeck(deckId);
        setDeckId(null);
      } else {
        handleDeleteDecks();
      }
      
    }

  
    console.log('delete: ', isDeckDelete);
    console.log('deck Id: ', deckId);
  }

  const handleCloseDeleteModal = () => {
    setIsDeckDelete(false);
  }

  let activeClass = isManageDecksDrawer ? "active" : "";
  let activeDecks = isHandleDecksDrawer ? "decks-active" : "";

  return (
    <>
      <div
        className={`drawer-overlay ${activeClass}`}
        onClick={handleCloseDecks}
      ></div>
      <div
        className={`side-drawer-main manage-deck-drawer ${activeClass} ${activeDecks}`}
      >
        <div className="side-drawer-wrapper">
          <div className="side-drawer-title">
            <h3>Manage Presentation Decks</h3>
            <span className="side-drawer-close" onClick={handleCloseDecks}>
              <i className="icon-lms icon-close"></i>
            </span>
          </div>
          <div className="side-drawer-tabs">
            <div className="side-drawer-tabs-wrapper">
              <div className="control-tabs-box">
                <div
                  className={
                    toggleTabState === 1 ? "tabs-btn active" : "tabs-btn"
                  }
                  onClick={() => toggleTab(1)}
                >
                  <p>All Decks</p>
                </div>
              </div>
              <div className="content-tabs-box">
                {!getdeckLoading && (
                  <React.Fragment>
                    <div
                      className={
                        toggleTabState === 1
                          ? "tabs-content active"
                          : "tabs-content"
                      }
                    >
                      <div className="manage-deck-tab">
                        {deckItems.length == 0 && (
                          <div className="empty-box">
                            <h6>Your Presentation Deck Collection is Empty</h6>
                            <p>
                              It looks like you haven’t added any presentation
                              decks yet.
                            </p>
                            <span
                              className="plain-btn"
                              onClick={onHandleDecksDrawer}
                            >
                              <i className="icon-lms icon-add"></i>Add Deck
                            </span>
                          </div>
                        )}

                        <div>
                          <div className="top-part">
                            <div>
                              <div
                                className={
                                  isDeckSearch
                                    ? "search-box active"
                                    : "search-box"
                                }
                                ref={deckSearchRef}
                              >
                                <div className="input-search">
                                  <i className="icon-lms icon-search"></i>
                                  <input
                                    type="text"
                                    placeholder="Search Deck"
                                    ref={deckSearchInputRef}
                                    value={isDeckSearchValue}
                                    onChange={handleChangeDeckSearch}
                                    onClick={() => handleOpenDeckSearch()}
                                  />
                                  <div className="controls">
                                    <i
                                      className="icon-lms icon-close"
                                      onClick={() => handleClearDeckSearch()}
                                    ></i>
                                    {/* <i
                                        className="icon-lms icon-arrow-right"
                                        onClick={() => handleDeckSearch()}
                                      ></i> */}
                                  </div>
                                </div>
                              </div>
                            </div>
                            <div>
                              {deckItems.length > 0 && (
                                <span
                                  className="plain-btn"
                                  onClick={onHandleDecksDrawer}
                                >
                                  <i className="icon-lms icon-add"></i>Add Deck
                                </span>
                              )}
                            </div>
                          </div>
                          <div className="bottom-part">
                            {filteredDecks &&
                              filteredDecks.map((deckItem, index) => (
                                <div className="deck-item" key={index}>
                                  <div className="d1">
                                    <div className="checkbox-group">
                                      <input
                                        type="checkbox"
                                        name={`deckCheckbox-${index}`}
                                        id={`deckCheckbox-${index}`}
                                        checked={checkedDecks.some(
                                          (item) => item.id === deckItem.id
                                        )}
                                        onChange={(e) =>
                                          handleDeckCheckbox(e, deckItem)
                                        }
                                        disabled={isHandleDecksDrawer}  // Set the disabled attribute
                                      />
                                    </div>
                                  </div>
                                  <div className="d2">
                                    <div
                                      onClick={() =>
                                        handleDeckItemClick(deckItem)
                                      }
                                    >
                                      <p className="deck-name">
                                        {deckItem.name}
                                      </p>
                                      <span className="deck-update">
                                        Last updated:{" "}
                                        {new Date(
                                          deckItem.updated
                                        ).toLocaleDateString("en-US", options)}
                                      </span>
                                    </div>
                                    <div>
                                      <span className="deck-link">
                                        {deckItem.link}
                                      </span>
                                      <a
                                        className="toast-text"
                                        href={deckItem.link}
                                        target="_blank"
                                      >
                                        Open Link
                                      </a>
                                    </div>
                                  </div>

                                  <div className="d3">
                                    <button
                                      className="c-btn c-btn-light"
                                      onClick={() =>
                                        handleOpenDeleteModal(deckItem.id)
                                      } // Adjust this function to handle deletion
                                    >
                                      <i className="icon-lms icon-delete"></i>
                                    </button>
                                    <button
                                      className="c-btn c-btn-light"
                                      onClick={() =>
                                        handleDeckItemClick(deckItem)
                                      }
                                    >
                                      <i className="icon-lms icon-edit"></i>
                                    </button>
                                  </div>
                                </div>
                              ))}
                          </div>
                        </div>
                      </div>
                    </div>
                  </React.Fragment>
                )}
                {getdeckLoading && (
                  <div className="loading-box">
                    <div class="lds-ellipsis">
                      <div></div>
                      <div></div>
                      <div></div>
                      <div></div>
                    </div>
                  </div>
                )}
              </div>
              <div className="tab-form-btn-group">
                <div className="row">
                  <div className="col text-right">
                    <span
                      className="drawer-btn cancel-btn"
                      onClick={handleCloseDecks}
                    >
                      Close
                    </span>
                    {checkedDecks.length > 0 && (
                      <span
                        className="drawer-btn delete-btn"
                        onClick={handleOpenDeleteModal}
                      >
                        Delete Deck
                      </span>
                    )}
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <ManageDeckDeleteModal
        isOpen={isDeckDelete}
        handleOpenDeleteModal={() => handleOpenDeleteModal()}
        handleCloseDeleteModal={() => handleCloseDeleteModal()}
       // handleDeleteDeck={() => handleDeleteDeck()}
      />
    </>
  );
};

export default ManageDecksDrawer;
