import React, {Component} from 'react';
import {connect} from "react-redux";
import {influencerActions} from "_actions/influencer.actions";

class TablePagination extends Component {

    constructor(props) {
        super(props);

        this.searchRef = React.createRef();
        this.filterRef = React.createRef();
        this.searchInputRef = React.createRef();

        this.state = {
            searchToggled: false,
            filteredToggled: false,
            currentSize: props.size ? props.size : 15,
        }
    }

    static getDerivedStateFromProps(nextProps, prevState) {
        // Update the state only if the size prop from props is different from the current currentSize state
        if (nextProps.size !== prevState.currentSize) {
            return { currentSize: nextProps.size };
        }
        return null;
    }
    
    handlePageChanged = (value, className) => {
        if(className === 'active') {
            this.setState({ currentPage: value });
            this.props.onHandlePageChanged(value);
        }
    }

    handleSizeChanged = (e) => {
        const { number, totalElements, size } = this.props;
        let newSize = parseInt(e.options[e.selectedIndex].value);
    
        let min = (number + 1) * newSize - (newSize - 1);
    
        if (min <= totalElements) {
          //  this.setState({ currentSize: size }); // Update the currentSize state
          this.props.onHandleSizeChanged(newSize); // Notify the parent component about the size change
        }
    
        if (min > totalElements) {
          this.props.onHandleSizeChanged(size);
        }
      };

    handleKeyDown = (event, value, className) => {
        const { currentSize, currentPage } = this.state;
        const { totalElements, size } = this.props;
    
        console.log('current page: ', currentPage);
        console.log('total el: ', totalElements);
        console.log('size: ', size);
    
        console.log('change page...', value);
    
        if (event.key === "Enter") {
          event.preventDefault(); // Prevent form submission
          if (className === "active") {
           
            let min = value * size - (size - 1);
            console.log('min: ' + min)
            if (min <= totalElements) {
              this.setState({ currentPage: value });
              this.props.onHandlePageChanged(value);
            }
    
            if (min > totalElements) {
                if((currentPage * size) > totalElements ) {
                    this.props.onHandlePageChanged(1);
                } else {
                    this.props.onHandlePageChanged(currentPage);
                }
              
            }
          }
        }
      };
    

    render() {

        const {number,first,last,size,totalElements} = this.props;
        const {currentPage, currentSize, currentQuery} = this.state;
    
        let page = number + 1;
        let min = (page * size) - (size - 1);
        let max = page * size;
        let count = totalElements;
        let prevClass =  first ? '' : 'active';
        let nextClass =  last ? '' : 'active';
        let nextPage = page + 1;
        let previousPage = page- 1;
        let defaultPage = parseInt(page);
        let activeSize = currentSize ? currentSize : size;
    
        let countText = 'Loading Influencers ...';
        if(page) {
            let limit = max;
            if(count < max){
                limit = count;
            }
            countText = `${min}-${limit} of ${count}`;
        }

        return (
            <div className='table-pagination-box'>
                    <div className='pagination-box'>
                        <div className='influencer-counts'>
                            <span>Show rows: </span>
                            <select value={activeSize} onChange={(e)=> this.handleSizeChanged(e.target)}>
                                <option value={15}>15</option>
                                <option value={25}>25</option>
                                <option value={50}>50</option>
                                <option value={100}>100</option>
                            </select>

                            <div className='go-to-page'>
                                <span>Go to</span>
                                <input type="number" defaultValue={defaultPage} key={defaultPage} ref={this.gotoInputRef}
                                onKeyDown={(e) => this.handleKeyDown(e, e.target.value, 'active')}  />
                            </div>

                            <span>{countText}</span>

                        </div>
                        <ul>
                            <a
                                onClick={() => this.handlePageChanged(previousPage, prevClass)}
                                className={prevClass}>
                                <i className="icon-lms icon-arrow-left"></i>
                            </a>
                            <a
                                onClick={() => this.handlePageChanged(nextPage, nextClass)}
                                className={nextClass}>
                                <i className="icon-lms icon-arrow-right"></i>
                            </a>
                        </ul>
                    </div>
            </div>
        );
    }
}

function mapState(state) {
    const { influencerListLoaded } = state.influencers

    if(influencerListLoaded) {
        const {influencers} = state;
        const {list} = influencers;
        const {number, first, last, size, totalElements, totalPages } = list.data;
        return {number, first, last, size, totalElements, totalPages , influencerListLoaded};
    }

    return { influencerListLoaded }
}

const actionCreators = {
    getInfluencerList: influencerActions.getInfluencerList
};

const connectedViewTablePagination = connect(mapState, actionCreators)(TablePagination);
export { connectedViewTablePagination as TablePagination };
