import React, {Component} from 'react';
import {MDBCol, MDBIcon} from 'mdbreact';
import {utilitiesService as utility} from '_services';
import Moment from "react-moment";
import {Button} from "blackbox-react";
import {leadActions} from "_actions";
import {connect} from "react-redux";
import DOMPurify from "dompurify";

class LeadViewNotes extends Component {


    renderLeadNotes = (leadNote, index) => {

    let isUpdated = leadNote.created !== leadNote.updated;
    let description = leadNote.description.replace(/(?:\r\n|\r|\n)/g, '<br>');

    let content = {__html: DOMPurify.sanitize(description)}

        return (
            <MDBCol key={index} className='background-color-white py-2 border-left-orange submitted-form my-2'>
                <p className='mb-0 font-size-12 font-color-pending font-weight-600'>
                    {leadNote.name}
                </p>
                <p className='mb-1 font-size-11'>
                    {leadNote.owner ?
                        <span>
                  {utility.decrypt(leadNote.owner.first_name)} {utility.decrypt(leadNote.owner.last_name)} &nbsp;
                </span>
                        : ''
                    }
                </p>
                <p className='mb-0 font-size-11 opacity-60 pr-4' dangerouslySetInnerHTML={content}/>
                <div className='opacity-50'>
            <span className='font-size-9'>
              Note added: &nbsp;
                <Moment className='font-bold-black' date={leadNote.created} fromNow ago/> <span
                className='font-bold-black'>ago</span> &nbsp; &bull; &nbsp;
            </span>
                    <span className='font-size-9'>
              Last Updated: &nbsp;
                        {isUpdated ?
                            <span className='font-bold-black'><Moment date={leadNote.updated} fromNow ago/> ago</span> :
                            <span className='font-bold-black'>Never</span>
                        }
            </span>
                </div>
                <p
                    onClick={(e) => {
                        this.props.toggleEditLeadNote();
                        this.props.handleEditLeadNote(leadNote);
                    }}
                    className='mb-0 font-size-13 font-color-dark-blue position-absolute top-8 right-15 display-view pointer-cursor'>Edit</p>
                <div className='position-absolute right-15 bottom-8 display-view'>
                    <MDBIcon
                        far
                        icon='trash-alt'
                        className='color-red font-size-11 pointer-cursor'
                        onClick={(e) => {
                            e.preventDefault();
                            this.props.toggleDeleteLeadNote();
                            this.props.handleDeleteLeadNote(leadNote);
                        }}
                    />
                </div>
            </MDBCol>
        );
    };

    render() {
        const {leads, notes, notesLoaded} = this.props
        let leadNotes = [];

        if(notesLoaded) {
            leadNotes = notes.data;
        }

        return (
            <div className='notes-box'>
                <div className='inner-title-box'>
                    <p>Notes</p>
                    <button onClick={this.props.toggleAddLeadNote}><i className="fas fa-plus"></i>Add Note</button>
                </div>
                <div className='notes-list'>
                    {leadNotes.length > 0 ?
                        leadNotes.map((value, index) => {
                            return this.renderLeadNotes(value, index);
                        })
                        :
                        <div className='create-view text-center'>
                            <p className='font-size-25 font-weight-500'>
                                Add your first Note
                            </p>
                            <p className='p-l--lg p-r--lg'>
                                Update your project members about the lead's standing by adding a note.
                            </p>
                            <Button
                                className="bordered-btn"
                                onClick={this.props.toggleAddLeadNote}
                                style={{marginTop: '10px'}}>
                                <MDBIcon icon='plus'/>
                                Add Note
                            </Button>
                        </div>
                    }
                </div>
            </div>
        );
    }
}

function mapState(state) {
    const {leads} = state;
    const {notes, notesLoaded} = leads;
    return {leads, notes, notesLoaded};
}

const actionCreators = {
    getLeadNotes: leadActions.getLeadNotes
};

const connectedViewLeadViewNotes = connect(mapState, actionCreators)(LeadViewNotes);
export {connectedViewLeadViewNotes as LeadViewNotes};

