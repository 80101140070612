import { httpManagerService as httpManager } from  '_services';

export const deckService = {
    getDecks,
    createDeck,
    updateDeck,
    removeDeck,
    removeDecks,
    removeDeck

}


function getDecks(projectId){
    return httpManager.getHttpWithRequestingToken(`/api/v2/project/${projectId}/deck`);
}

function createDeck(projectId, request){
    return httpManager.postJsonOnce(`/api/v2/project/${projectId}/deck`, request);
}

function updateDeck(projectId, request){
    return httpManager.putJsonOnce(`/api/v2/project/${projectId}/deck`, request);
}

function removeDeck(projectId, deckId){
    return httpManager.deleteJsonWithRequestingToken(`/api/v2/project/${projectId}/deck/${deckId}`);
}

function removeDecks(projectId, request){
    return httpManager.putJsonOnce(`/api/v2/project/${projectId}/deck/batch`, request);
}