import React, { Component } from "react";
import { projectActions } from "_actions";
import { connect } from "react-redux";
import { utilitiesService as utility } from "_services";

class LeadResponsibleDropdown extends Component {
  constructor(props) {
    super(props);
    this.filterRef = React.createRef();

    this.state = {
      filterToggled: false,
    };
  }

  componentDidUpdate = () => {};

  handleOpenFilter() {
    this.setState({ filterToggled: true });
  }

  handleCloseFilter() {
    this.setState({ filterToggled: false });
  }

  handleDropdown = (value) => {
    if (!this.filterRef.current.contains(value.target)) {
      this.setState({ filterToggled: false });
    }
  };

  handleDisableAssigned = (project, user, responsible) => {
    let admin = false;
    if (
      user &&
      user.data &&
      user.data.projects &&
      user.data.projects.owned &&
      Array.isArray(user.data.projects.owned) &&
      user.data.projects.owned.length > 0 &&
      project &&
      project.data &&
      project.data.id
    ) {

      admin = user.data.projects.owned.some(
        (e) => e.id === parseInt(project.data.id)
      );
    }

    if(!admin) {
      admin = user?.data?.id === responsible?.id;
    }

    return admin;
  };

  handleUpdateLeadResponsible = (value) => {
    const { onHandleUpdateLeadResponsible } = this.props;
    onHandleUpdateLeadResponsible(value);
    this.setState({ filterToggled: false });
  };

  componentDidMount() {
    document.addEventListener("click", this.handleDropdown, true);
  }

  componentWillUnmount() {
    document.removeEventListener("click", this.handleDropdown, true);
  }

  render() {
    const { project, members, membersLoaded, projectLoaded, lead, user } =
      this.props;

    let leadResponsible = lead.responsible
      ? utility.decrypt(lead.responsible.first_name) +
        " " +
        utility.decrypt(lead.responsible.last_name)
      : "Unassigned";

    let items = [];

    if (projectLoaded) {
      items.push(project.data.owner);
    }

    if (membersLoaded) {
      members.data.forEach((mem) => {
        items.push(mem);
      });

      items = items.filter(
        (a) =>
          a.account.email !== "QvTirbIfH10hjrP47GzWJr66oMqT+XYLY62ZEV6KaAE="
      );
    }

    let admin = this.handleDisableAssigned(project, user, lead.responsible);

    return (
      <div className="filter-box" ref={this.filterRef}>
        <div className={admin ? "filter-btn admin-btn" : "filter-box"}>
          {admin && (
            <span onClick={() => this.handleOpenFilter()}>
              {leadResponsible}
            </span>
          )}
          {!admin && <span>{leadResponsible}</span>}
        </div>

        <div
          className={
            this.state.filterToggled
              ? "filter-dropdown active"
              : "filter-dropdown"
          }
        >
          <div className="filter-title">
            <p>Assign</p>
            <i
              className="icon-lms icon-close"
              onClick={() => this.handleCloseFilter()}
            ></i>
          </div>
          <div className="filter-list">
            <div className="filter-group">
              {items.map((item, index) => {
                let name =
                  utility.decrypt(item.account.first_name) +
                  " " +
                  utility.decrypt(item.account.last_name);
                return (
                  <span
                    key={`filter-dropdown-${index}`}
                    className="filter-name"
                    onClick={() =>
                      this.handleUpdateLeadResponsible(item.account.email)
                    }
                  >
                    {name}
                  </span>
                );
              })}
            </div>
          </div>
        </div>
      </div>
    );
  }
}

function mapState(state) {
  const { user } = state.authentication;
  const { project, members, membersLoaded, projectLoaded } = state.projects;
  return { project, members, membersLoaded, projectLoaded, user };
}

const actionCreators = {
  getProject: projectActions.getProject,
};

const connectedViewLeadResponsibleDropdown = connect(
  mapState,
  actionCreators
)(LeadResponsibleDropdown);
export { connectedViewLeadResponsibleDropdown as LeadResponsibleDropdown };
