import { calendlyConstants } from "_constants";

export function calendly(state = {}, action) {
  switch (action.type) {
    case calendlyConstants.GET_CALENDLY_REQUEST:
      return {
        ...state,
        getcalendlyLoading: true,
        getcalendlyLoaded: false,
      };
    case calendlyConstants.GET_CALENDLY_SUCCESS:
      return {
        ...state,
        getcalendlyLoading: false,
        getcalendlyLoaded: true,
        calendly: action.calendly.data,
        error: null
      };
    case calendlyConstants.GET_CALENDLY_FAILURE:
      return {
        ...state,
        getcalendlyLoading: false,
        getcalendlyLoaded: true,
        error: action.error,
      };

    case calendlyConstants.UPDATE_CALENDLY_REQUEST:
      return {
        ...state,
        getcalendlyLoading: true,
        getcalendlyLoaded: false,
      };

    case calendlyConstants.UPDATE_CALENDLY_SUCCESS:
      return {
        ...state,
        getcalendlyLoading: false,
        getcalendlyLoaded: true,
        calendly: action.calendly.data,
        error: null
      };
    case calendlyConstants.UPDATE_CALENDLY_FAILURE:
      return {
        ...state,
        getcalendlyLoading: false,
        getcalendlyLoaded: true,
        error: action.error,
      };
    case calendlyConstants.CREATE_CALENDLY_REQUEST:
      return {
        ...state,
        getcalendlyLoading: true,
        getcalendlyLoaded: false,
      };

    case calendlyConstants.CREATE_CALENDLY_SUCCESS:
      return {
        ...state,
        getcalendlyLoading: false,
        getcalendlyLoaded: true,
        calendly: action.calendly.data,
        error: null
      };
    case calendlyConstants.CREATE_CALENDLY_FAILURE:
      return {
        ...state,
        getcalendlyLoading: false,
        getcalendlyLoaded: true,
        error: action.error,
      };
    case calendlyConstants.DELETE_CALENDLY_REQUEST:
      return {
        ...state,
        getcalendlyLoading: true,
        getcalendlyLoaded: false,
      };
    case calendlyConstants.DELETE_CALENDLY_SUCCESS:
      return {
        ...state,
        getcalendlyLoading: false,
        getcalendlyLoaded: true,
        calendly: action.calendly,
        error: null
      };
    case calendlyConstants.DELETE_CALENDLY_FAILURE:
      return {
        ...state,
        getcalendlyLoading: false,
        getcalendlyLoaded: true,
        error: action.error,
      };
    default:
      return {
        ...state,
      };
  }
}
