import React, { useEffect, useMemo, useRef, useState } from "react";
import { useParams } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import { MDBCol, MDBRow } from "mdbreact";
import FormGroupTitle from "_pages/Propelrr/components/Form/FormGroupTitle";
import InputForm from "_components/InputForm";
import { formActions, leadActions, projectActions } from "_actions";
import {
  getSourceData,
  utilitiesService as utility,
  utilitiesService,
} from "_services";
import { leadData } from "_pages/Leads/constants/LeadData";
import { set } from "firebase/database";
import DropDownLists from "_pages/Influencer/constants/DropDownLists";

const EditLeadDrawer = ({ isEditLeadDrawer, onHandleEditLeadDrawer }) => {
  const params = useParams();
  const dispatch = useDispatch();

  const multiselectRef = useRef();
  const customselectRef = useRef();

  const [toggleTabState, setToggleTabState] = useState(1);
  const [fieldData, setFieldData] = useState({});
  const [statusData, setStatusData] = useState({});
  const [sourceData, setSourceData] = useState({});
  const [fieldErrors, setFieldErrors] = useState({});
  const [isMultiselectOpen, setIsMultiselectOpen] = useState(false);
  const [isCustomselectOpen, setIsCustomselectOpen] = useState(false);
  const [customselectSelectedValue, setCustomselectSelectedValue] =
    useState("");
  const emailRegex =
    /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;

  const [initialLoad, setInitialLoad] = useState(true);
  const services = DropDownLists.services;
  const positions = DropDownLists.positions;
  const primaryLeadType = DropDownLists.primaryLeadType;

  useEffect(() => {
    const projectId = params.id;
    const leadId = params.leadId;
    dispatch(formActions.getDefaultForm(projectId));
    dispatch(projectActions.getAllProjectStatus(projectId));
    dispatch(leadActions.getLead(projectId, leadId));
    setFieldData({});
    setSourceData({});
    setInitialLoad(true);
  }, [params.leadId]);

  const lead = useSelector((state) => {
    const { leads } = state;
    const { lead, leadLoaded, updateLeadLoaded } = leads;
    if (leadLoaded) {
      return lead.data;
    } else {
      return {};
    }
  });

  const updateLeadLoaded = useSelector((state) => {
    const { leads } = state;
    const { updateLeadLoaded } = leads;

    return updateLeadLoaded;
  });

  useEffect(() => {
    if (updateLeadLoaded && isEditLeadDrawer) {
      onHandleEditLeadDrawer();
      setFieldData({});
      setSourceData({});
      setFieldErrors({});
    }
  }, [updateLeadLoaded]);

  let activeClass = isEditLeadDrawer ? "active" : "";

  const formLoaded = useSelector((state) => {
    const { forms } = state;
    const { formLoaded } = forms;
    return formLoaded ? formLoaded : false;
  });

  const form = useSelector((state) => {
    const { forms } = state;
    const { formLoaded, form } = forms;

    if (formLoaded) {
      return form.data;
    }

    return {};
  });

  const group = useSelector((state) => {
    const { forms } = state;
    const { formLoaded, form } = forms;

    if (formLoaded) {
      const { group } = form.data;
      return group
        .filter((g) => g.status)
        .sort((a, b) => a.form_order < b.form_order);
    }
    return [];
  });

  const statusList = useSelector((state) => {
    const { projects } = state;
    const { statusLoaded, status } = projects;

    if (statusLoaded) {
      const list = status.data;
      return list.filter((g) => g.status);
    }

    return [];
  });

  const memberList = useSelector((state) => {
    const { projects } = state;
    const { project, projectLoaded } = projects;

    if (projectLoaded) {
      project.data.owner.account.id = project.data.owner.id.toString();
      let owner = project.data.owner.account;
      owner.id = project.data.owner.id.toString();

      let list = [owner];

      project.data.members.forEach((m) => list.push(m.account));

      return list.map((m) => {
        return {
          id: parseInt(m.id),
          label:
            utility.decrypt(m.first_name) + " " + utility.decrypt(m.last_name),
        };
      });
    } else {
      return [];
    }
  });

  const updateLeadLoading = useSelector((state) => {
    const { leads } = state;
    const { updateLeadLoading } = leads;

    if (updateLeadLoading) {
      return updateLeadLoading;
    }
    return false;
  });

  const leadLoaded = useSelector((state) => {
    const { leads } = state;
    const { leadLoaded } = leads;

    if (leadLoaded) {
      return leadLoaded;
    }
    return false;
  });

  const fields = useSelector((state) => {
    const { leads } = state;
    const { lead, leadLoaded } = leads;
    if (leadLoaded) {
      let map = [];
      let basicInfo = lead.data.basic_info;
      let campaignData = lead.data.campaign_data;

      if (basicInfo) {
        basicInfo.forEach((i) => {
          map[i.field_id] = {
            value: utilitiesService.decrypt(i.value),
            type: "field",
          }; // { value: field.value, type: field.type }
        });
      }

      map["origin"] = { value: lead.data.origin, type: "source" };

      if (campaignData) {
        Object.keys(campaignData).forEach((key) => {
          map[key] = { value: campaignData[key], type: "source" };
        });
      }

      return map;
    } else {
      return [];
    }
  });

  const memoizedFields = useMemo(() => fields, [fields]);

  useEffect(() => {
    if (
      Object.keys(memoizedFields).length > 0 &&
      Object.keys(fieldData).length === 0 &&
      !initialLoad
    ) {
      let updatedFieldData = { ...fieldData };
      let updatedSourceData = { ...sourceData };
      let keys = Object.keys(memoizedFields);

      keys.forEach((k) => {
        if (memoizedFields[k].type === "field") {
          updatedFieldData[k] = utilitiesService.encrypt(
            memoizedFields[k].value
          );
        }

        if (memoizedFields[k].type === "source") {
          updatedSourceData[k] = memoizedFields[k].value;
        }
      });

      setFieldData(updatedFieldData);
      setSourceData(updatedSourceData);
    }

    // Set initialLoad to false after the first useEffect call
    if (initialLoad) {
      setInitialLoad(false);
    }
  }, [memoizedFields, fieldData, sourceData, initialLoad]);

  useEffect(() => {
    const customselectOutsideClick = (e) => {
      if (
        isMultiselectOpen &&
        multiselectRef.current &&
        !multiselectRef.current.contains(e.target)
      ) {
        setIsMultiselectOpen(false);
      }
    };
    document.addEventListener("click", customselectOutsideClick);
    return () => {
      document.removeEventListener("click", customselectOutsideClick);
    };
  }, [isMultiselectOpen]);

  useEffect(() => {
    const customselectOutsideClick = (e) => {
      if (
        isCustomselectOpen &&
        customselectRef.current &&
        !customselectRef.current.contains(e.target)
      ) {
        setIsCustomselectOpen(false);
      }
    };
    document.addEventListener("click", customselectOutsideClick);
    return () => {
      document.removeEventListener("click", customselectOutsideClick);
    };
  }, [isCustomselectOpen]);

  function handleOpenMultiselect() {
    setIsMultiselectOpen(!isMultiselectOpen);
  }

  const handleMultiselectCheckboxChange = (id, value, checked) => {
    setFieldData((prevFieldData) => {
      const updatedFieldData = { ...prevFieldData };

      let decryptedValue = [];

      // Check if updatedFieldData[id] is empty or undefined
      if (!updatedFieldData[id]) {
        decryptedValue = [];
      } else {
        decryptedValue = utilitiesService.decrypt(updatedFieldData[id]);
        decryptedValue = decryptedValue.split(',').map(item => item.trim());

      }

      if (checked) {
        // Add the value to the array
        if (!decryptedValue.includes(value)) {
          decryptedValue.push(value.trim());
        }
      } else {
        // Remove the value from the array
        const index = decryptedValue.indexOf(value.trim());
        if (index !== -1) {
          decryptedValue.splice(index, 1);
        }
      }

      // Encrypt the updated array and assign it back to updatedFieldData[id]
      
      const encryptedValue = decryptedValue.filter(item => item !== '').join(',');
      updatedFieldData[id] = utilitiesService.encrypt(encryptedValue);

      return updatedFieldData;
    });
  };

  function handleOpenCustomselect() {
    setIsCustomselectOpen(!isCustomselectOpen);
  }

  const handleCustomselectSelectValue = (id, value) => {
    setIsCustomselectOpen(false);

    let val = utilitiesService.encrypt(value);
    setFieldData((prevFieldData) => ({
      ...prevFieldData,
      [id]: val,
    }));
  };

  const renderGroupField = (group) => {
    let fields = group.field
      .filter((g) => g.status)
      .sort((a, b) => a.form_order < b.form_order);
    return (
      <React.Fragment>
        <FormGroupTitle label={group.name} />

        {fields.map((f, index) => {
          return <React.Fragment key={index}>{renderField(f)}</React.Fragment>;
        })}
      </React.Fragment>
    );
  };

  const renderStatusInfo = () => {
    return (
      <React.Fragment>
        <div className="input-group-box full-box-">
          <div className="field-box">
            <label htmlFor={`responsible`}>Assigned To</label>
            <select
              className="c-select"
              name={`responsible`}
              id={`responsible`}
              onChange={handleStatusChange}
            >
              <option value={null}>Unassigned</option>
              {memberList.map((m, i) => {
                return (
                  <option key={i} value={m.email}>
                    {m.label}
                  </option>
                );
              })}
            </select>
          </div>
        </div>
        <div className="input-group-box full-box-">
          <div className="field-box">
            <label htmlFor={`priority`}>Priority</label>
            <select
              className="c-select"
              name={`priority`}
              id={`priority`}
              onChange={handleStatusChange}
            >
              <option value={3}>High Priority</option>
              <option value={2}>Medium Priority</option>
              <option value={1}>Low Priority</option>
            </select>
          </div>
        </div>
        <div className="input-group-box full-box-">
          <div className="field-box">
            <label htmlFor={`status`}>Status</label>
            <select
              className="c-select"
              name={`status`}
              id={`status`}
              onChange={handleStatusChange}
            >
              {statusList.map((s, i) => {
                return (
                  <option key={i} value={s.id}>
                    {s.name}
                  </option>
                );
              })}
            </select>
          </div>
        </div>
      </React.Fragment>
    );
  };

  const renderSourceInfo = () => {
    return (
      <React.Fragment>
        <div className="input-group-box full-box-">
          <div className="field-box">
            <label htmlFor={`origin`}>Point Of Conversion</label>
            <InputForm
              onChange={handleSourceChange}
              id={`origin`}
              name={`origin`}
              disabled={true}
              value={sourceData.origin ? sourceData.origin : ""}
            />
          </div>
        </div>
        <div className="input-group-box full-box-">
          <div className="field-box">
            <label htmlFor={`source`}>Source</label>
            <InputForm
              onChange={handleSourceChange}
              id={`source`}
              name={`source`}
              value={sourceData.source ? sourceData.source : ""}
            />
          </div>
        </div>
        <div className="input-group-box full-box-">
          <div className="field-box">
            <label htmlFor={`medium`}>Medium</label>
            <InputForm
              onChange={handleSourceChange}
              id={`medium`}
              name={`medium`}
              value={sourceData.medium ? sourceData.medium : ""}
            />
          </div>
        </div>
        <div className="input-group-box full-box-">
          <div className="field-box">
            <label htmlFor={`channel`}>Channel</label>
            <InputForm
              onChange={handleSourceChange}
              id={`channel`}
              name={`channel`}
              value={sourceData.channel ? sourceData.channel : ""}
            />
          </div>
        </div>
        <div className="input-group-box full-box-">
          <div className="field-box">
            <label htmlFor={`campaign`}>Campaign</label>
            <InputForm
              onChange={handleSourceChange}
              id={`campaign`}
              name={`campaign`}
              value={sourceData.campaign ? sourceData.campaign : ""}
            />
          </div>
        </div>
        <div className="input-group-box full-box-">
          <div className="field-box">
            <label htmlFor={`clientId`}>Google Client Id</label>
            <InputForm
              onChange={handleSourceChange}
              id={`clientId`}
              name={`clientId`}
              value={sourceData.ga_client_id ? sourceData.ga_client_id : ""}
            />
          </div>
        </div>
      </React.Fragment>
    );
  };

  const renderField = (field) => {
    const { type_id, hide_label, id, label, required } = field;
    let input = null;
    //  console.log('field: ', field);

    switch (type_id) {
      case 1:
        input = renderTextInput(field);
        break;

      case 2:
        input = renderTextField(field);
        break;

      case 5:
        input = renderMultiSelect(field);
        break;

      case 10:
        input = renderSingleSelect(field);
        break;

      default:
        break;
    }

    return (
      <div className="input-group-box full-box-">
        <div className="field-box">
          <label htmlFor={id}>
            {label}
            {required && <small className="color-red">*</small>}
          </label>
          {input}
          {fieldErrors[id] && (
            <p className="error-field">{fieldErrors[id].message}</p>
          )}
        </div>
      </div>
    );
  };

  const renderTextInput = (field) => {
    const { id, placeholder, required, label } = field;

    let def = "";
    if (fieldData[id] && leadLoaded) {
      def = utilitiesService.decrypt(fieldData[id]);
    }

    return (
      <React.Fragment>
        {label !== "Lead Type" && (
          <InputForm
            value={def}
            onChange={handleFieldInputChange}
            hint={placeholder}
            id={id}
            name={id}
            label={label}
          />
        )}
        {label === "Lead Type" && (
          <InputForm
            onChange={handleFieldInputChange}
            hint={placeholder}
            id={id}
            name={id}
            label={label}
            value={def}
            disabled={true}
          />
        )}
      </React.Fragment>
    );
  };

  const renderTextField = (field) => {
    const { id, placeholder, required } = field;
    let def = "";

    if (fieldData[id] || fields[id] && leadLoaded) {
      if(fieldData[id]) {
        def = utilitiesService.decrypt(fieldData[id]);
      }
    }

    const handleInitialHeightCalculation = (textarea) => {
      textarea.style.height = 'auto';
      textarea.style.height = `${textarea.scrollHeight}px`;
    };

    const handleChange = (e) => {
      e.target.style.height = `${e.target.scrollHeight}px`;
      handleFieldInputChange(e);
    };
    

    return (
      <textarea
        ref={(textarea) => {
          if (textarea) {
            handleInitialHeightCalculation(textarea);
          }
        }}
        onChange={handleChange}
        required={required}
        name={id}
        id={id}
        //placeholder={placeholder}
        style={{ height: 'auto' }}
        value={def}
      />
    );
  };

  const renderMultiSelect = (field) => {
    const { id } = field;
  
    let def = "";
    if (fieldData[id] && leadLoaded) {
      def = utilitiesService.decrypt(fieldData[id]);
      const multiVal = utilitiesService.decrypt(fieldData[id]);
      def = multiVal.length === 0 ? [] : multiVal.split(',').filter(item => item !== "");
    }
  
    return (
        <div className="custom-dropdown-multiselect" ref={multiselectRef}>
          <div
            className="multiselect-btn"
            onClick={() => handleOpenMultiselect()}
          >
            <span className="multiselect-text">
              {def ? def.join(',') : null}
            </span>
            <i className={isMultiselectOpen ? "active" : ""}></i>
          </div>
          <div
            className={
              isMultiselectOpen
                ? "multiselect-dropdown active"
                : "multiselect-dropdown"
            }
          >
            <div className="multiselect-list">
              {services.map((service) => (
                <div className="checkbox-group" key={service}>
                  <input
                    checked={Array.isArray(def) &&
                      def.map(item => item.trim().toLowerCase()).includes(service.trim().toLowerCase())}
                  //  checked={def?.includes(service) || false}
                    type="checkbox"
                    name={service}
                    id={`${service}-edit`}
                    value={service}
                    onChange={(e) =>
                      handleMultiselectCheckboxChange(
                        id,
                        service,
                        e.target.checked
                      )
                    }
                  />
                  <label htmlFor={`${service}-edit`}>{service}</label>
                </div>
              ))}
            </div>
          </div>
        </div>
    );
  };
  

  const renderSingleSelect = (field) => {
    const { id } = field;
    const singLeSelectList = params.id == 1 ? primaryLeadType : positions;

    let def = "";
    if (fieldData[id] && leadLoaded) {
      def = utilitiesService.decrypt(fieldData[id]);
    }

    return (
        <div className="custom-dropdown-multiselect" ref={customselectRef}>
          <div
            className="multiselect-btn"
            onClick={() => handleOpenCustomselect()}
          >
            <span className="multiselect-text">
              {def}
            </span>
            <i className={isCustomselectOpen ? "active" : ""}></i>
          </div>
          <div
            className={
              isCustomselectOpen
                ? "multiselect-dropdown active"
                : "multiselect-dropdown"
            }
          >
            <div className="multiselect-list">
              {singLeSelectList.map((option) => (
                <div
                  className="checkbox-group"
                  key={option}
                  onClick={() => handleCustomselectSelectValue(id, option)}
                >
                  <span>{option}</span>
                </div>
              ))}
            </div>
          </div>
        </div>
    );
  };

  const handleFieldInputChange = (event) => {
    setFieldErrors({});
    const { id, value } = event.target;
    let val = value.trim();

    if (val) {
      val = utilitiesService.encrypt(value);
    }

    setFieldData({ ...fieldData, [id]: val });
  };

  const handleSourceChange = (event) => {
    const { id, value } = event.target;
    setSourceData({ ...sourceData, [id]: value });
  };

  const handleStatusChange = (event) => {
    const { id, value } = event.target;
    setStatusData({ ...statusData, [id]: value });
  };

  const handleEditLeadDrawer = () => {
    setFieldData({});
    setSourceData({});
    setFieldErrors({});
    onHandleEditLeadDrawer();
    setIsCustomselectOpen(false);
    setIsMultiselectOpen(false);
  };

  const handleUpdateLead = () => {
    setIsCustomselectOpen(false);
    setIsMultiselectOpen(false);

    let requiredFields = group
      .map((g) =>
        g.field.map((f) =>
          f.required && f.status == 1
            ? { id: f.id, label: f.label, required: f.required }
            : null
        )
      )
      .flat()
      .filter((r) => r !== null);

    const projectId = params.id;
    const leadId = params.leadId;

    const emailLabels = ["Email", "Email Address"];
    const contactLabels = ["Contact", "Mobile Number"];

    let hasError = false;
    let errors = {};

    let emailValue = null;
    let contactValue = null;

    requiredFields.forEach((field) => {
      if (!fieldData[field.id] && field.required) {
        hasError = true;
        errors[field.id] = { error: true, message: "This field is required." };
      }

      if (fieldData[field.id] && field.required) {
        console.log(
          field.label + ": ",
          utilitiesService.decrypt(fieldData[field.id])
        );

        if (emailLabels.includes(field.label)) {
          emailValue = utilitiesService.decrypt(fieldData[field.id]);
          if (!emailRegex.test(emailValue)) {
            hasError = true;
            errors[field.id] = {
              error: true,
              message: "Invalid email format.",
            };
          }
        } else if (contactLabels.includes(field.label)) {
          contactValue = utilitiesService.decrypt(fieldData[field.id]);
        }
      }
    });

    const invalidEmail = Object.values(errors).some(
      (error) => error.message === "Invalid email format."
    );

    // Check for the conditions and update hasError accordingly
    if (!emailValue && !contactValue) {
      hasError = true; // Condition 1
    }

    if (emailValue && !errors[emailValue] && !contactValue) {
      hasError = false; // Condition 4
    }

    if (!emailValue && contactValue) {
      hasError = false; // Condition 5
    }

    if (invalidEmail && !contactValue) {
      hasError = true;
    }

    setFieldErrors(errors);

    // console.log("errors: ", fieldErrors);

    let requestData = [];
    let keys = Object.keys(fieldData);
    keys.forEach((k) => {
      if (utilitiesService.decrypt(fieldData[k]).length > 0) {
        requestData.push({ key: k, value: fieldData[k] });
      } else {
        requestData.push({ key: k, value: null });
      }
    });

    let request = {
      form_lead: requestData,
      email: emailValue ? utilitiesService.encrypt(emailValue) : null,
      project_status_id: lead.project_status.id,
      priority: statusData.priority || 2,
      responsible: statusData.responsible || null,
      traffic_source: sourceData.source || null,
      traffic_medium: sourceData.medium || null,
      traffic_channel: sourceData.channel || null,
      traffic_campaign: sourceData.campaign || null,
      origin: "LMS Form",
      ga_client_id: sourceData.clientId || null,
    };


    dispatch(leadActions.updateLead(projectId, leadId, request, hasError));
  };

  return (
    <React.Fragment>
      <div
        className={`drawer-overlay ${activeClass}`}
        onClick={handleEditLeadDrawer}
      ></div>
      <div className={`side-drawer-main ${activeClass}`}>
        <div className="side-drawer-wrapper">
          <div className="side-drawer-title">
            <h3>Update Lead</h3>
            <span className="side-drawer-close" onClick={handleEditLeadDrawer}>
              <i className="icon-lms icon-close"></i>
            </span>
          </div>
          <div className="side-drawer-tabs">
            <div className="side-drawer-tabs-wrapper">
              <div className="control-tabs-box">
                <div
                  className={
                    toggleTabState === 1 ? "tabs-btn active" : "tabs-btn"
                  }
                  onClick={() => setToggleTabState(1)}
                >
                  <p>Lead Information</p>
                </div>
                <div
                  className={
                    toggleTabState === 2 ? "tabs-btn active" : "tabs-btn"
                  }
                  onClick={() => setToggleTabState(2)}
                >
                  <p>Source Information</p>
                </div>
              </div>
              <div className="content-tabs-box">
                {!updateLeadLoading && (
                  <React.Fragment>
                    <div
                      className={
                        toggleTabState === 1
                          ? "tabs-content active"
                          : "tabs-content"
                      }
                    >
                      <div className="forms-tab">
                        <MDBRow>
                          <MDBCol xl="12" lg="12" className="form-group-box">
                            {group.map((g, index) => {
                              return (
                                <React.Fragment key={index}>
                                  {renderGroupField(g)}{" "}
                                </React.Fragment>
                              );
                            })}
                          </MDBCol>
                        </MDBRow>
                      </div>
                    </div>
                    <div
                      className={
                        toggleTabState === 2
                          ? "tabs-content active"
                          : "tabs-content"
                      }
                    >
                      <div className="forms-tab">
                        <MDBRow>
                          <MDBCol xl="12" lg="12" className="form-group-box">
                            {/* <div className="form-group-title">
                                                        <label>Source Information</label>
                                                    </div> */}
                            {renderSourceInfo()}
                          </MDBCol>
                        </MDBRow>
                      </div>
                    </div>
                  </React.Fragment>
                )}
                {updateLeadLoading && (
                  <div className="loading-box">
                    <div class="lds-ellipsis">
                      <div></div>
                      <div></div>
                      <div></div>
                      <div></div>
                    </div>
                  </div>
                )}
              </div>
              <div className="tab-form-btn-group">
                <MDBRow>
                  <MDBCol className="text-right">
                    <span
                      className="drawer-btn cancel-btn"
                      onClick={handleEditLeadDrawer}
                    >
                      Cancel
                    </span>
                    {!updateLeadLoading && (
                      <span
                        className="drawer-btn save-btn"
                        onClick={() => handleUpdateLead()}
                      >
                        Update Lead
                      </span>
                    )}
                    {updateLeadLoading && (
                      <span
                        className="drawer-btn cancel-btn c-btn-disabled"
                        disabled={true}
                        onClick={() => handleUpdateLead()}
                      >
                        Updating Lead ...
                      </span>
                    )}
                  </MDBCol>
                </MDBRow>
              </div>
            </div>
          </div>
        </div>
      </div>
    </React.Fragment>
  );
};

export default EditLeadDrawer;
