import React from 'react';
import { FormValidation } from './FormValidation';

class InputForm extends React.Component {
  state = {
    value: '',
    error: '',
    showError: false,
  };

  handleChange = (e) => {
    const { label, value } = this.props;

    this.setState({ value: e.target.value });
    this.props.onChange(e);

    // this.validateField();

  
  };

  componentDidMount() {
    this.setState({ value: this.props.value });
  }

  componentDidUpdate(prevProps) {
    if (prevProps.value !== this.props.value) {
      this.setState({ value: this.props.value });
    }
  }

  validateField = () => {
    
    const { label, value } = this.props;
    const { error, showError } = this.state;
    console.log('validate label: ', label)
    if ((label === 'Email' || label === 'Email Address') && (value == undefined || value.trim() === '' )) {
      this.setState({ error: 'Please enter a valid email', showError: true });
      console.log('validate error: ' + error + 'value: ', value);
    } 
    
    if (label === 'Full Name' && (value == undefined || value.trim() === '' )) {
      this.setState({ error: 'Please enter full name', showError: true });
      console.log('validate error: ' + error + 'value: ', value);
    } 

    return true;
    
  };

  render() {
    let { id, required, name, type, hint, inputClass, disabled, label, readonly, onKeyPress } = this.props;
    
    const { value, error, showError } = this.state;
    // console.log('render label: ' +  label + ', value: ' + value + ', show error: '+ showError);
    

    return (
      <div>
        <input
          disabled={disabled}
          id={id}
          required={required}
          name={name}
          onChange={this.handleChange}
          type={type}
          className={`${inputClass} c-input`}
          placeholder={hint}
          value={value}
          readOnly={readonly}
          onKeyPress={onKeyPress}
        />
       {/* <div className={`form-validation__container ${(!formValid && submitted) ? 'display-block' : 'display-none'}`}>
                        <FormValidation fieldName='email' formErrors={fieldValidationErrors} />
        </div> */}
        {/* {error && <div className={`form-validation__container  display-block`}>
          <div className='form-errors'>{error}</div>
        </div>} */}
      </div>
    );
  }
}

export default InputForm;