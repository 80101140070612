import React, { useState } from 'react'
import { MDBContainer, MDBRow, MDBCol, MDBModal, MDBModalHeader, MDBModalBody } from 'mdbreact'

const AddSocialMediaModal = ({ open, toggle, close }) => {

  const [isPlatform, setIsPlatform] = useState('');
  const [isOthers, setIsOthers] = useState(false);

  const platformSelectHandler = (platform) => {
    setIsPlatform(platform);
    
    if(platform === "Others") {
      setIsOthers(true);
    } else {
      setIsOthers(false);
    }
  }

  return (
    <MDBContainer className='modal-container modal-form-container'>
        <MDBRow>
          <MDBCol>
            <MDBModal isOpen={open} toggle={toggle} centered tabIndex='-1'>
              <MDBModalHeader className='confirmation-modal-header text-uppercase p-2'><span className='pl-1 mx-2 letter-spacing-1 font-weight-600 font-color-gray font-size-13'>ADD SOCIAL MEDIA FLATFORM</span></MDBModalHeader>
              <MDBModalBody>
                <MDBRow className='form-group-row'>
                  <MDBCol className='col-12'>
                        <label>Social Media Flatform</label>
                        <select onChange={(e) => platformSelectHandler(e.target.value)} value={isPlatform}>
                          <option hidden>Select Platform</option>
                          <option>Facebook</option>
                          <option>Instagram</option>
                          <option>Twitter</option>
                          <option>Youtube</option>
                          <option>Tiktok</option>
                          <option>Others</option>
                        </select>
                        <div className={isOthers ? 'other-platform active' : 'other-platform'}>
                          <label>Others</label>
                          <input type='text' />
                        </div>
                  </MDBCol>
                  <MDBCol className='col-12'>
                        <label>Social Media Url</label>
                        <input type='text' />
                  </MDBCol>
                  <MDBCol className='col-12'>
                        <label>Followers / Subscribers</label>
                        <input type='number' />
                  </MDBCol>
                </MDBRow>
                <MDBRow>
                  <MDBCol className='text-center'>
                    <button className='modal-btn save-btn-modal'>
                        Save Social Media
                    </button>
                    <button className='modal-btn cancel-btn-modal' onClick={close}>
                        Cancel
                    </button>
                  </MDBCol>
                </MDBRow>
              </MDBModalBody>
            </MDBModal>
          </MDBCol>
        </MDBRow>
      </MDBContainer>
  )
}

export default AddSocialMediaModal