import { fileConstants } from "_constants";

export function file(state = {}, action) {
  switch (action.type) {
    case fileConstants.GET_ALL_FILE_REQUEST:
    case fileConstants.UPDATE_FILE_REQUEST:
    case fileConstants.ADD_FILE_REQUEST:
    case fileConstants.DELETE_FILE_REQUEST:
      return {
        ...state,
        getFileLoading: true,
        getFileLoaded: false,
      };

    case fileConstants.GET_ALL_FILE_SUCCESS:
    case fileConstants.UPDATE_FILE_SUCCESS:
    case fileConstants.ADD_FILE_SUCCESS:
    case fileConstants.DELETE_FILE_SUCCESS:
      return {
        ...state,
        getFileLoading: false,
        getFileLoaded: true,
        files: action.files,
      };

    case fileConstants.GET_ALL_FILE_FAILURE:
    case fileConstants.UPDATE_FILE_FAILURE:
    case fileConstants.ADD_FILE_FAILURE:
    case fileConstants.DELETE_FILE_FAILURE:
      return {
        ...state,
        getFileLoading: false,
        getFileLoaded: true,
        error: action.error,
      };

    default:
      return {
        ...state,
      };
  }
}
