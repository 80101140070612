import React from 'react'

const Button = ({ classN, text, icon, onClick }) => {
  return (
    <>
      <button className={classN} type='button' onClick={onClick}>
        <i className={`${icon}`}></i>
        {text}
      </button>
    </>
  )
}

export default Button