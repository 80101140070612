import React, {Component} from 'react';
import {influencerActions} from "_actions";
import {connect} from "react-redux";
import {utilitiesService} from "_services";
import Moment from "react-moment";

class ActivitiesTab extends Component {

  componentDidMount() {
    const {projectId, influencerId, getInfluencerActivities} = this.props;
    getInfluencerActivities(projectId, influencerId);
  }

  render() {

    const {classN, getInfluencerActivitiesLoaded, getInfluencerActivitiesLoading, activities} = this.props;

    let activityList = [];

    if (getInfluencerActivitiesLoaded) {
      activityList = activities;
    }

    return (
        <div className={classN}>

          {getInfluencerActivitiesLoaded && 
          <ul className="list-unstyled">
            {activityList.map((activity, index) => {
              let action = activity.action;
              action = action.replace('%value', `<span class="highlight-text">${activity.value}</span>`)
              action = `<span>${action}</span>`;
              const actionHtml = {__html: action};
              let actor = `${utilitiesService.decrypt(activity.creator.firstName)} ${utilitiesService.decrypt(activity.creator.lastName)}`;

              return (<li key={index}>
                <div className="activity-box">
                  <div className='activity-icon-group'>
                    <div className='icon-box'>
                      <i className="far fa-user"></i>
                    </div>
                    <div className='line-box'></div>
                  </div>
                  <div className="activity-detail-group">
                    <div className='main-details' dangerouslySetInnerHTML={actionHtml}/>
                    <div className="sub-details">
                      <span>
                        <span>{actor}</span>
                        <i data-test="fa" className="fa fa-circle"></i>
                      </span>
                      <span>
                        <Moment date={activity.created} fromNow ago/> ago
                      </span>
                    </div>
                  </div>
                </div>
              </li>);
            })}
          </ul>
          }

          {getInfluencerActivitiesLoading && <div className="loading-box">
                    <div className='loader-box'>
                        <div className="loader-custom">
                            <span></span>
                            <span></span>
                            <span></span>
                            <span></span>
                            <span></span>
                        </div>
                    </div>
          </div>}

        </div>)
  }


}


function mapState(state) {
  const {influencers} = state;
  const {activities, getInfluencerActivitiesLoaded, getInfluencerActivitiesLoading} = influencers;
  return {influencers, activities, getInfluencerActivitiesLoaded, getInfluencerActivitiesLoading};
}

const actionCreators = {
  getInfluencerActivities: influencerActions.getInfluencerActivities,
};

const connectedViewActivitiesTab = connect(mapState, actionCreators)(ActivitiesTab);
export {connectedViewActivitiesTab as ActivitiesTab};