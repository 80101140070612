import React, { Component } from 'react';
import { MDBContainer, MDBRow, MDBCol, MDBIcon } from 'mdbreact';
import { Link } from 'react-router-dom'
import {connect} from "react-redux";
import { utilitiesService as utility } from '_services';
import Moment from "react-moment";

import { projectActions, formActions, leadActions } from '_actions';
import {Button, ButtonGroup, Icon} from "blackbox-react";

class ImportHistory extends Component {
  constructor(props) {
    super(props);

    let leadImports = this.props.getAllImportData(this.props.match.params.id);

    this.state = {
      leadImports: leadImports
    }
  }
  // state = {
  //   tableData: [
  //     {
  //       fileName: 'Lead_Import_Sample.csv',
  //       importedon: `September 30, 2019 10:19 PM`,
  //       created: '10',
  //       updated: '2',
  //       skipped: '0',
  //       errored: '0',
  //       importedBy: 'Sophie O.',
  //       status: 'Completed'
  //     },
  //     {
  //       fileName: 'Lead_Import_Sample.csv',
  //       importedon: `September 30, 2019 10:19 PM`,
  //       created: '10',
  //       updated: '2',
  //       skipped: '0',
  //       errored: '0',
  //       importedBy: 'Sophie O.',
  //       status: 'Completed'
  //     },
  //     {
  //       fileName: 'Lead_Import_Sample.csv',
  //       importedon: `September 30, 2019 10:19 PM`,
  //       created: '10',
  //       updated: '2',
  //       skipped: '0',
  //       errored: '0',
  //       importedBy: 'Sophie O.',
  //       status: 'Completed'
  //     },
  //     {
  //       fileName: 'Lead_Import_Sample.csv',
  //       importedon: `September 30, 2019 10:19 PM`,
  //       created: '10',
  //       updated: '2',
  //       skipped: '0',
  //       errored: '0',
  //       importedBy: 'Sophie O.',
  //       status: 'Completed'
  //     },
  //     {
  //       fileName: 'Lead_Import_Sample.csv',
  //       importedon: `September 30, 2019 10:19 PM`,
  //       created: '10',
  //       updated: '2',
  //       skipped: '0',
  //       errored: '0',
  //       importedBy: 'Sophie O.',
  //       status: 'Completed'
  //     },
  //     {
  //       fileName: 'Lead_Import_Sample.csv',
  //       importedon: `September 30, 2019 10:19 PM`,
  //       created: '10',
  //       updated: '2',
  //       skipped: '0',
  //       errored: '0',
  //       importedBy: 'Sophie O.',
  //       status: 'Completed'
  //     },
  //     {
  //       fileName: 'Lead_Import_Sample.csv',
  //       importedon: `September 30, 2019 10:19 PM`,
  //       created: '10',
  //       updated: '2',
  //       skipped: '0',
  //       errored: '0',
  //       importedBy: 'Sophie O.',
  //       status: 'Completed'
  //     },
  //   ]
  // };

  render() {
    let leadImports = this.state.leadImports;
    //let leadImports = JSON.parse(sessionStorage.getItem('AllLeadImport'));

    let projectId = sessionStorage.getItem('projectId');
    //let hasImports = false;
    let leadImportsLoaded = this.props.leadImportsLoaded;

    if(typeof leadImports !== 'undefined' && leadImports !== null){
      //hasImports = !!leadImports.length;
    } else{
      leadImports = JSON.parse(sessionStorage.getItem('AllLeadImport'));
      //hasImports = !!leadImports.length;
    }


    return (
      <MDBContainer fluid className='p--md lead-import'>
        <MDBRow className='m--0'>
          <MDBCol lg='3' xl='2' md='4' className='p-t--0 p-l--0 p-r--sm p-b--sm'>
            <MDBRow className='p--0 m--0'>
              <MDBCol className='p-0 mt-2'>
                <p className='font-size-12 font-weight-600 font-color-gray mb-0'>LEAD MANAGEMENT</p>
                <p className='font-family-crimson font-size-30 line-height-7 color-orange'>Import Leads</p>
              </MDBCol>
            </MDBRow>
            <MDBRow className='m-t--lg p--0'>
              <MDBCol xl='11' lg='9' className='text-center font-size-14 pl-0 mt-5'>
              </MDBCol>
            </MDBRow>
            <MDBRow className='p-t--md p-l--md p-r--md p-b--0 back-button'>
              <MDBCol>
                <ButtonGroup>
                  <Link to={`/project/${projectId}/leads`} className=''>
                    <Button
                      flat='true'
                      background='transparent' foreground='black'
                      borderWidth={2} borderColor='dark' size={-1}
                      styles={{ 'width': '100%', 'opacity': '0.8' }}>
                      <Icon color='black' name='chevron' rotate={180} />
                      <span className='color-black font-weight-bold'>View All Leads</span>
                    </Button>
                  </Link>
                </ButtonGroup>
              </MDBCol>
            </MDBRow>
          </MDBCol>
          <MDBCol lg='9' xl='10' md='8' className='px-0'>
            <MDBRow className='d-flex'>
              <MDBCol lg='3' className='my-auto'>
                <MDBRow className='pl-3'>
                  <p className='mb-0 font-family-crimson font-size-22 my-auto pr-3'>Import History</p>
                </MDBRow>
              </MDBCol>
              <MDBCol className='text-right'>
                <ButtonGroup>
                  <Link to={`/project/${projectId}/leads/import`}>
                    <Button
                      flat='true'
                      background='orange' foreground='white'
                      borderWidth={2} borderColor='orange' size={-2}
                      styles={{ 'min-width': '150px'}}
                    >
                      <MDBIcon icon='upload' className='mr-2' />
                      <span className='color-white font-weight-bold'>Import Leads</span>
                    </Button>
                  </Link>
                </ButtonGroup>
              </MDBCol>
            </MDBRow>
            <MDBCol className='sub-body-container background-color-white background-rectangle m-t--md'>
              { (leadImportsLoaded) ?
                  leadImports.length ?
                    <MDBRow className='p--md'>
                      <MDBCol className='px-0 background-color-white border-radius-3'>
                        <table className='table-sm bordered' id='table-form'>
                          <thead>
                          <tr className='background-color-white'>
                            <th width='13%%' className='font-size-12 font-weight-600 opacity-70 py-3 border-table-right'><div className='col'>FILE NAME</div></th>
                            <th width='8%' className='font-size-12 font-weight-600 opacity-70 py-3 border-table-right'><div className='col'>IMPORTED ON</div></th>
                            <th width='1%' className='font-size-12 font-weight-600 opacity-70 py-3 border-table-right'><div className='col'>CREATED</div></th>
                            {/*<th width='1%' className='font-size-12 font-weight-600 opacity-70 py-3 border-table-right'><div className='col'>UPDATED</div></th>
                        <th width='1%' className='font-size-12 font-weight-600 opacity-70 py-3 border-table-right'><div className='col'>SKIPPED</div></th>
                        <th width='1%' className='font-size-12 font-weight-600 opacity-70 py-3 border-table-right'><div className='col'>ERRORED</div></th>*/}
                            <th width='13%' className='font-size-12 font-weight-600 opacity-70 py-3 border-table-right px-0'><div className='col'>IMPORTED BY</div></th>
                            <th width='5%' className='font-size-12 font-weight-600 opacity-70 py-3 border-table-right'><div className='col'>STATUS</div></th>
                            <th width='7%' className='font-size-12 font-weight-600 opacity-70 py-3'><div className='col'> </div></th>
                          </tr>
                          </thead>
                          <tbody>
                          { leadImports &&
                          leadImports.map((value, index) => {
                            //this.state.tableData.map((value, index) => {
                            return (
                              <tr key={index}>
                                <td className='border-table-right'>
                                  <div className='col'>
                                    <div className={`col px-0`}>
                                      <span className='font-weight-600 font-size-12'>{value.file_name}</span>
                                    </div>
                                  </div>
                                </td>
                                <td className='border-table-right'>
                                  <div className='col'>
                                    <div className={`col px-0`}>
                                      <span className='font-weight-600 font-size-12'><Moment date={value.imported} format="LLL"/> </span>
                                    </div>
                                  </div>
                                </td>
                                <td className='border-table-right'>
                                  <div className='col'>
                                    <div className={`col px-0`}>
                                      <span className='font-size-12'>{value.created}</span>
                                    </div>
                                  </div>
                                </td>
                                {/*<td className='border-table-right'>
                                <div className='col'>
                                  <div className={`col px-0`}>
                                    <span className='font-size-12'>{value.updated}</span>
                                  </div>
                                </div>
                              </td>
                              <td className='border-table-right'>
                                <div className='col'>
                                  <div className={`col px-0`}>
                                    <span className='font-size-12'>{value.skipped}</span>
                                  </div>
                                </div>
                              </td>
                              <td className='border-table-right'>
                                <div className='col'>
                                  <div className={`col px-0`}>
                                    <span className='font-size-12'>{value.errored}</span>
                                  </div>
                                </div>
                              </td>*/}
                                <td className='border-table-right'>
                                  <div className='col'>
                                    <div className={`col px-0`}>
                                      <span className='font-weight-600 font-size-12'>{utility.decrypt(value.imported_by.first_name) + ' ' + utility.decrypt(value.imported_by.last_name)}</span>
                                    </div>
                                  </div>
                                </td>
                                <td className='border-table-right'>
                                  <div className='col'>
                                    <div className={`col px-0`}>
                                      <span className={`font-weight-600 font-size-12 ${value.is_completed ? 'font-color-qualified' : 'font-color-red'}`}>{value.is_completed ? 'Completed' : 'Not Completed'}</span>
                                    </div>
                                  </div>
                                </td>
                                <td>
                                  {/*<div className='col pr-0'>*/}
                                  {/*  <div className={`col px-0`}>*/}
                                  {/*    <span className='font-size-12 font-color-dark-blue'><Link to={`/project/${projectId}/leads/import/${value.id}`} className=''>view ></Link></span>*/}
                                  {/*  </div>*/}
                                  {/*</div>*/}
                                </td>
                              </tr>
                            )
                          })
                          }
                          </tbody>
                        </table>
                      </MDBCol>
                    </MDBRow>
                    :
                    <div className='create-view text-center'>
                      <p className='font-size-32 font-weight-500 font-family-crimson m-b--0'>No Imports found</p>
                      <p className='p-l--lg p-r--lg m-b--md'>
                        You can bulk add leads <br/>by importing a CSV file.
                      </p>
                      <Link to={`/project/${projectId}/leads/import`}>
                        <Button
                          flat='true'
                          background='orange' foreground='white'
                          borderWidth={2} borderColor='orange'
                          onClick={(e) => {}}>
                          <Icon name='plus' />
                          <span className='color-white m-l--sm'>Import Leads</span>
                        </Button>
                      </Link>
                    </div>
                :
                <MDBRow className='sub-body-container height--full'>
                  <MDBCol md='auto' lg='12' className='sub-body-container height--full'>
                    <div className='loader-container'>
                      <div className="loader dark">Loading...</div>
                    </div>
                  </MDBCol>
                </MDBRow>
              }

            </MDBCol>
          </MDBCol>
        </MDBRow>
      </MDBContainer>
    );
  }
}

function mapState(state) {
  const { projects, project, forms } = state;
  const { loading, projectLoaded, members, activityLogLoaded, membersLoaded, statusLoaded } = state.projects;
  const { formLoaded } = state.forms;
  const { leadLoaded, leadImportsLoaded } = state.leads;
  return { projects, project, members, loading, projectLoaded, activityLogLoaded, statusLoaded, forms, membersLoaded, formLoaded, leadLoaded, leadImportsLoaded };
}

const actionCreators = {
  getProject: projectActions.getProject,
  getForm: formActions.getForm,
  getLeads: leadActions.getLeads,
  getLead: leadActions.getLead,
  createLead: leadActions.importLead,
  getAllImportData: leadActions.getAllImportData,
  findImportData: leadActions.findImportData
};

const connectedViewImportHistory = connect(mapState, actionCreators)(ImportHistory);
export { connectedViewImportHistory as ImportHistory }
//export default ImportHistory;
