import React, {Component, useState, useCallback, useEffect, useRef} from "react";
import {influencerActions, projectActions} from "_actions";
import {connect} from "react-redux";
import InfluencerTableHeader from "_pages/Influencer/components/Table/InfluencerTableHeader";
import InfluencerTableListInfo from "_pages/Influencer/components/Table/InfluencerTableListInfo";
import {influencerConfig} from "_pages/Influencer/components/Table/InfluencerTableHeaderConfig";
import TableMainLoader from '../../../../_components/Loader/TableMainLoader';


const InfluencerTableList = ({influencers, isKpiHeaderExpanded, onHandleAddInfluencerDrawer}) => {
    

    const {influencerListLoaded, influencerListLoading, list } = influencers;

    let data = [];

    if(list != null) {
        data = list.data.content;
    }

    let activeClass = isKpiHeaderExpanded ? 'active' : '';

    return (
        <>
            <div className="table-view table-influencers">
                <div className={`table-scroll ${activeClass}`}>
                    {influencerListLoading &&
                        <TableMainLoader />
                    }
                    <table className="resizeable-table">
                        <thead>
                            <tr>
                            {influencerConfig.map((config, index) => (
                                <th key={index} width={`${config.width}`}>
                                    <span>{config.title}</span>
                                </th>
                            ))}
                            </tr>
                        </thead>
                        <tbody>
                        {influencerListLoaded && data.length > 0 && data.map((influencer, index) => {
                            return (<InfluencerTableListInfo key={index} influencer={influencer}/>)
                        })}
                        </tbody>
                    </table>
                {influencerListLoaded && data.length === 0 &&  
                        <div className='table-empty-box'>
                            <div>
                                <h3>No Influencers Found</h3>
                                <p>
                                    Influencers Information will appear here <br/>Luckily, you can add influencer manually
                                </p>
                                <span onClick={() => onHandleAddInfluencerDrawer()}>
                                    <i className='icon-lms icon-add'></i>
                                    Add Influencer
                                </span>
                            </div>
                        </div>
                }
                </div>
            </div>
        </>
    );
}

function mapState(state) {
    const {projects, influencers} = state;
    return {projects, influencers};
}

const actionCreators = {
    getProject: projectActions.getProject,
    getInfluencerList: influencerActions.getInfluencerList(),
};

const connectedViewInfluencerTableList = connect(mapState, actionCreators)(InfluencerTableList);
export {connectedViewInfluencerTableList as InfluencerTableList};