export const managerConstants = {
    GET_ALL_MANAGERS_REQUEST: 'GET_ALL_MANAGERS_REQUEST',
    GET_ALL_MANAGERS_SUCCESS: 'GET_ALL_MANAGERS_SUCCESS',
    GET_ALL_MANAGERS_FAILURE: 'GET_ALL_MANAGERS_FAILURE',

    GET_MANAGER_CONFIG_REQUEST: 'GET_MANAGER_CONFIG_REQUEST',
    GET_MANAGER_CONFIG_SUCCESS: 'GET_MANAGER_CONFIG_SUCCESS',
    GET_MANAGER_CONFIG_FAILURE: 'GET_MANAGER_CONFIG_FAILURE',

    DELETE_MANAGER_REQUEST: 'DELETE_MANAGER_REQUEST',
    DELETE_MANAGER_SUCCESS: 'DELETE_MANAGER_SUCCESS',
    DELETE_MANAGER_FAILURE: 'DELETE_MANAGER_FAILURE',

    UPDATE_MANAGER_CONFIG_REQUEST: 'UPDATE_MANAGER_CONFIG_REQUEST',
    UPDATE_MANAGER_CONFIG_SUCCESS: 'UPDATE_MANAGER_CONFIG_SUCCESS',
    UPDATE_MANAGER_CONFIG_FAILURE: 'UPDATE_MANAGER_CONFIG_FAILURE',

    ADD_MANAGER_CONFIG_PARAM_REQUEST: 'ADD_MANAGER_CONFIG_PARAM_REQUEST',
    ADD_MANAGER_CONFIG_PARAM_SUCCESS: 'ADD_MANAGER_CONFIG_PARAM_SUCCESS',
    ADD_MANAGER_CONFIG_PARAM_FAILURE: 'ADD_MANAGER_CONFIG_PARAM_FAILURE',

    UPDATE_MANAGER_CONFIG_PARAM_REQUEST: 'UPDATE_MANAGER_CONFIG_PARAM_REQUEST',
    UPDATE_MANAGER_CONFIG_PARAM_SUCCESS: 'UPDATE_MANAGER_CONFIG_PARAM_SUCCESS',
    UPDATE_MANAGER_CONFIG_PARAM_FAILURE: 'UPDATE_MANAGER_CONFIG_PARAM_FAILURE',

    DELETE_MANAGER_CONFIG_PARAM_REQUEST: 'DELETE_MANAGER_CONFIG_PARAM_REQUEST',
    DELETE_MANAGER_CONFIG_PARAM_SUCCESS: 'DELETE_MANAGER_CONFIG_PARAM_SUCCESS',
    DELETE_MANAGER_CONFIG_PARAM_FAILURE: 'DELETE_MANAGER_CONFIG_PARAM_FAILURE',


    GET_PROJECT_CONFIG_PARAM_REQUEST: 'GET_PROJECT_CONFIG_PARAM_REQUEST',
    GET_PROJECT_CONFIG_PARAM_SUCCESS: 'GET_PROJECT_CONFIG_PARAM_SUCCESS',
    GET_PROJECT_CONFIG_PARAM_FAILURE: 'GET_PROJECT_CONFIG_PARAM_FAILURE',

    GET_PROJECT_CONFIG_OPERATOR_REQUEST: 'GET_PROJECT_CONFIG_OPERATOR_REQUEST',
    GET_PROJECT_CONFIG_OPERATOR_SUCCESS: 'GET_PROJECT_CONFIG_OPERATOR_SUCCESS',
    GET_PROJECT_CONFIG_OPERATOR_FAILURE: 'GET_PROJECT_CONFIG_OPERATOR_FAILURE',
}