
export const breadCrumbsBtn = [
  {

    name: 'GENERAL INQUIRY',
    value: 'GENERAL INQUIRY',
    defaultClass: 'mx-2 font-color-light-blue border-light-blue background-color-light-blue',
    newClass: 'mx-2 text-white background-color-blue-light border-light-blue',
    spanDefaultClass: 'border-light-blue background-color-blue-light',
    spanNewClass: 'border-white background-color-blue-light',
    dropdownOption: [
      {
        id: 1,
        name: 'New Inquiry',
        value: 'NEW Inquiry',
        defaultClass: 'mx-0 font-color-light-blue border-light-blue background-color-light-blue',
        newClass: 'mx-0 text-white background-color-blue-light border-light-blue',
        spanDefaultClass: 'border-light-blue background-color-blue-light',
        spanNewClass: 'border-white background-color-blue-light',
      },
      {
        id: 2,
        name: 'Open Inquiry',
        value: 'OPEN Inquiry',
        defaultClass: 'mx-0 font-color-light-blue border-light-blue background-color-light-blue',
        newClass: 'mx-0 text-white background-color-blue-light border-light-blue',
        spanDefaultClass: 'border-light-blue background-color-blue-light',
        spanNewClass: 'border-white background-color-blue-light',
      },
      {
        id: 36,
        name: 'Verification in Progress',
        value: 'Verification in Progress',
        defaultClass: 'mx-0 font-color-light-blue border-light-blue background-color-light-blue',
        newClass: 'mx-0 text-white background-color-blue-light border-light-blue',
        spanDefaultClass: 'border-light-blue background-color-blue-light',
        spanNewClass: 'border-white background-color-blue-light',
      }
    ],
  },
  {
    name: 'QUOTATION REQUEST',
    value: 'QUOTATION REQUEST',
    defaultClass: 'mx-2 font-color-dark-blue border-dark-blue background-color-dark-blue',
    newClass: 'mx-2 text-white background-color-blue-dark border-dark-blue ',
    spanDefaultClass: 'background-color-blue-dark border-dark-blue',
    spanNewClass: 'border-white background-color-blue-dark',
    dropdownOption: [
      {
        id: 4,
        name: 'Quotation Request',
        value: 'QUOTATION REQUEST',
        defaultClass: 'mx-1 font-color-dark-blue border-dark-blue background-color-dark-blue',
        newClass: 'mx-1 text-white background-color-blue-dark border-dark-blue ',
        spanDefaultClass: 'background-color-blue-dark border-dark-blue',
        spanNewClass: 'border-white background-color-blue-dark',
      },
      // {
      //    id: 5,
      //   name: 'Quotation Assigned to Agent',
      //   value: 'QUOTATION ASSIGNED TO AGENT',
      //   defaultClass: 'mx-1 font-color-dark-blue border-dark-blue background-color-dark-blue',
      //   newClass: 'mx-1 text-white background-color-blue-dark border-dark-blue ',
      //   spanDefaultClass: 'background-color-blue-dark border-dark-blue',
      //   spanNewClass: 'border-white background-color-blue-dark',
      // },
      // {
      //    id: 6,
      //   name: 'Quotation In Progress',
      //   value: 'QUOTATION IN PROGRESS',
      //   defaultClass: 'mx-1 font-color-dark-blue border-dark-blue background-color-dark-blue',
      //   newClass: 'mx-1 text-white background-color-blue-dark border-dark-blue ',
      //   spanDefaultClass: 'background-color-blue-dark border-dark-blue',
      //   spanNewClass: 'border-white background-color-blue-dark',
      // },
      // {
      //   id: 7,
      //   name: 'Quotation Declined',
      //   value: 'QUOTATION DECLINED',
      //   defaultClass: 'mx-1 font-color-dark-blue border-dark-blue background-color-dark-blue',
      //   newClass: 'mx-1 text-white background-color-blue-dark border-dark-blue ',
      //   spanDefaultClass: 'background-color-blue-dark border-dark-blue',
      //   spanNewClass: 'border-white background-color-blue-dark',
      // },
      // {
      //   id: 8,
      //   name: 'Quotation No Response',
      //   value: 'QUOTATION NO RESPONSE',
      //   defaultClass: 'mx-1 font-color-dark-blue border-dark-blue background-color-dark-blue',
      //   newClass: 'mx-1 text-white background-color-blue-dark border-dark-blue ',
      //   spanDefaultClass: 'background-color-blue-dark border-dark-blue',
      //   spanNewClass: 'border-white background-color-blue-dark',
      // },
      {
        id: 9,
        name: 'Quotation Sent',
        value: 'QUOTATION SENT',
        defaultClass: 'mx-1 font-color-dark-blue border-dark-blue background-color-dark-blue',
        newClass: 'mx-1 text-white background-color-blue-dark border-dark-blue ',
        spanDefaultClass: 'background-color-blue-dark border-dark-blue',
        spanNewClass: 'border-white background-color-blue-dark',
      }
    ],
  },
  {
    name: 'UNIT VIEWING',
    value: 'UNIT VIEWING',
    defaultClass: 'mx-2 font-color-pending border-dark-pending background-color-light-pending background-color-pending',
    newClass: 'mx-2 text-white background-color-light-pending border-dark-pending',
    spanDefaultClass: 'background-color-light-pending border-dark-pending',
    spanNewClass: 'border-white background-color-light-pending',
    dropdownOption: [
      {
        id: 10,
        name: 'Unit Viewing Request',
        value: 'VIEWING REQUEST',
        defaultClass: 'mx-0 font-color-pending border-dark-pending background-color-light-pending background-color-pending',
        newClass: 'mx-0 text-white background-color-light-pending border-dark-pending',
        spanDefaultClass: 'background-color-light-pending border-dark-pending',
        spanNewClass: 'border-white background-color-light-pending',
      },
      // {
      //   id: 11,
      //   name: 'Unit Viewing Assigned',
      //   value: 'VIEWING ASSIGNED',
      //   defaultClass: 'mx-0 font-color-pending border-dark-pending background-color-light-pending background-color-pending',
      //   newClass: 'mx-0 text-white background-color-light-pending border-dark-pending',
      //   spanDefaultClass: 'background-color-light-pending border-dark-pending',
      //   spanNewClass: 'border-white background-color-light-pending',
      // },
      {
        id: 12,
        name: 'Unit Viewing Confirmed',
        value: 'VIEWING CONFIRMED',
        defaultClass: 'mx-0 font-color-pending border-dark-pending background-color-light-pending background-color-pending',
        newClass: 'mx-0 text-white background-color-light-pending border-dark-pending',
        spanDefaultClass: 'background-color-light-pending border-dark-pending',
        spanNewClass: 'border-white background-color-light-pending',
      },
      // {
      //   id: 13,
      //   name: 'Unit Viewing Declined',
      //   value: 'VIEWING DECLINED',
      //   defaultClass: 'mx-0 font-color-pending border-dark-pending background-color-light-pending background-color-pending',
      //   newClass: 'mx-0 text-white background-color-light-pending border-dark-pending',
      //   spanDefaultClass: 'background-color-light-pending border-dark-pending',
      //   spanNewClass: 'border-white background-color-light-pending',
      // },
      // {
      //   id: 14,
      //   name: 'Unit Viewing No Response',
      //   value: 'VIEWING NO RESPONSE',
      //   defaultClass: 'mx-0 font-color-pending border-dark-pending background-color-light-pending background-color-pending',
      //   newClass: 'mx-0 text-white background-color-light-pending border-dark-pending',
      //   spanDefaultClass: 'background-color-light-pending border-dark-pending',
      //   spanNewClass: 'border-white background-color-light-pending',
      // },
      {
        id: 15,
        name: 'Unit Viewing Cancelled',
        value: 'VIEWING CANCELLED',
        defaultClass: 'mx-0 font-color-pending border-dark-pending background-color-light-pending background-color-pending',
        newClass: 'mx-0 text-white background-color-light-pending border-dark-pending',
        spanDefaultClass: 'background-color-light-pending border-dark-pending',
        spanNewClass: 'border-white background-color-light-pending',
      },
      // {
      //   id: 16,
      //   name: 'Unit Viewing Completed',
      //   value: 'VIEWING COMPLETED',
      //   defaultClass: 'mx-0 font-color-pending border-dark-pending background-color-light-pending background-color-pending',
      //   newClass: 'mx-0 text-white background-color-light-pending border-dark-pending',
      //   spanDefaultClass: 'background-color-light-pending border-dark-pending',
      //   spanNewClass: 'border-white background-color-light-pending',
      // },
    ],
  },
  {
    name: 'FOR COMPLETION',
    value: 'FOR COMPLETION',
    defaultClass: 'mx-2 font-color-progress border-dark-progress background-color-light-progress background-color-progress',
    newClass: 'mx-2 text-white background-color-light-progress border-dark-progress',
    spanDefaultClass: 'background-color-light-progress border-dark-progress',
    spanNewClass: 'border-white background-color-light-pending',
    dropdownOption: [
      // {
      //   id: 17,
      //   name: 'Completed CIS Form',
      //   value: 'Completed CIS Form',
      //   defaultClass: 'mx-1 font-color-progress border-dark-progress background-color-light-progress background-color-progress',
      //   newClass: 'mx-1 text-white background-color-light-progress border-dark-progress',
      //   spanDefaultClass: 'background-color-light-progress border-dark-progress',
      //   spanNewClass: 'border-white background-color-light-pending',
      // },
      {
        id: 18,
        name: 'Negotiations',
        value: 'Negotiations',
        defaultClass: 'mx-1 font-color-progress border-dark-progress background-color-light-progress background-color-progress',
        newClass: 'mx-1 text-white background-color-light-progress border-dark-progress',
        spanDefaultClass: 'background-color-light-progress border-dark-progress',
        spanNewClass: 'border-white background-color-light-pending',
      },
      // {
      //   id: 19,
      //   name: 'Documents Completed',
      //   value: 'Documents Completed',
      //   defaultClass: 'mx-1 font-color-progress border-dark-progress background-color-light-progress background-color-progress',
      //   newClass: 'mx-1 text-white background-color-light-progress border-dark-progress',
      //   spanDefaultClass: 'background-color-light-progress border-dark-progress',
      //   spanNewClass: 'border-white background-color-light-pending',
      // },
      {
        id: 41,
        name: 'Reservation Processed',
        value: 'Reservation Processed',
        defaultClass: 'mx-1 font-color-progress border-dark-progress background-color-light-progress background-color-progress',
        newClass: 'mx-1 text-white background-color-light-progress border-dark-progress',
        spanDefaultClass: 'background-color-light-progress border-dark-progress',
        spanNewClass: 'border-white background-color-light-pending',
      }
    ],
  },
  {
    name: 'CLOSED INQUIRY',
    value: 'CLOSED INQUIRY',
    defaultClass: 'mx-2 font-color-progress border-dark-progress background-color-light-progress background-color-progress',
    newClass: 'mx-2 text-white background-color-light-progress border-dark-progress',
    spanDefaultClass: 'background-color-light-progress border-dark-progress',
    spanNewClass: 'border-white background-color-light-pending',
    dropdownOption: [
      {
        id: 3,
        name: 'Closed Inquiry',
        value: 'Closed Inquiry',
        defaultClass: 'mx-1 font-color-progress border-dark-progress background-color-light-progress background-color-progress',
        newClass: 'mx-1 text-white background-color-light-progress border-dark-progress',
        spanDefaultClass: 'background-color-light-progress border-dark-progress',
        spanNewClass: 'border-white background-color-light-pending',
      },
      {
        id: 37,
        name: 'Cancelled',
        value: 'Cancelled',
        defaultClass: 'mx-1 font-color-progress border-dark-progress background-color-light-progress background-color-progress',
        newClass: 'mx-1 text-white background-color-light-progress border-dark-progress',
        spanDefaultClass: 'background-color-light-progress border-dark-progress',
        spanNewClass: 'border-white background-color-light-pending',
      }
    ],
  },
  {
    name: 'Facebook Form Leads',
    value: 'Facebook Form Leads',
    defaultClass: 'mx-2 font-color-progress border-dark-progress background-color-light-progress background-color-progress',
    newClass: 'mx-2 text-white background-color-light-progress border-dark-progress',
    spanDefaultClass: 'background-color-light-progress border-dark-progress',
    spanNewClass: 'border-white background-color-light-pending',
    dropdownOption: [
      {
        id: 43,
        name: 'Facebook Form General Inquiry',
        value: 'Facebook Form General Inquiry',
        defaultClass: 'mx-1 font-color-progress border-dark-progress background-color-light-progress background-color-progress',
        newClass: 'mx-1 text-white background-color-light-progress border-dark-progress',
        spanDefaultClass: 'background-color-light-progress border-dark-progress',
        spanNewClass: 'border-white background-color-light-pending',
      },
      {
        id: 44,
        name: 'Facebook Form Quotation Inquiry',
        value: 'Facebook Form Quotation Inquiry',
        defaultClass: 'mx-1 font-color-progress border-dark-progress background-color-light-progress background-color-progress',
        newClass: 'mx-1 text-white background-color-light-progress border-dark-progress',
        spanDefaultClass: 'background-color-light-progress border-dark-progress',
        spanNewClass: 'border-white background-color-light-pending',
      }
    ],
  },
  {
    name: 'OTHERS',
    value: 'OTHERS',
    defaultClass: 'mx-2 font-color-progress border-dark-progress background-color-light-progress background-color-progress',
    newClass: 'mx-2 text-white background-color-light-progress border-dark-progress',
    spanDefaultClass: 'background-color-light-progress border-dark-progress',
    spanNewClass: 'border-white background-color-light-pending',
    dropdownOption: [
      {
        id: 38,
        name: 'Homeowner Inquiry',
        value: 'Homeowner Inquiry',
        defaultClass: 'mx-1 font-color-progress border-dark-progress background-color-light-progress background-color-progress',
        newClass: 'mx-1 text-white background-color-light-progress border-dark-progress',
        spanDefaultClass: 'background-color-light-progress border-dark-progress',
        spanNewClass: 'border-white background-color-light-pending',
      },
      {
        id: 39,
        name: 'Interested Applicant',
        value: 'Interested Applicant',
        defaultClass: 'mx-1 font-color-progress border-dark-progress background-color-light-progress background-color-progress',
        newClass: 'mx-1 text-white background-color-light-progress border-dark-progress',
        spanDefaultClass: 'background-color-light-progress border-dark-progress',
        spanNewClass: 'border-white background-color-light-pending',
      },
      {
        id: 40,
        name: 'Proposals',
        value: 'Proposals',
        defaultClass: 'mx-1 font-color-progress border-dark-progress background-color-light-progress background-color-progress',
        newClass: 'mx-1 text-white background-color-light-progress border-dark-progress',
        spanDefaultClass: 'background-color-light-progress border-dark-progress',
        spanNewClass: 'border-white background-color-light-pending',
      }
    ],
  },
  // {
  //   name: 'NEGOTIATION',
  //   value: 'NEGOTIATION',
  //   defaultClass: 'mx-0 font-color-pre-qualified border-dark-pre-qualified background-codarklor-light-pre-qualified background-color-pre-qualified',
  //   newClass: 'mx-0 text-white background-color-light-pre-qualified border-dark-pre-qualified',
  //   spanDefaultClass: 'background-color-light-pre-qualified border-dark-pre-qualified',
  //   spanNewClass: 'border-white background-color-light-pre-qualified'
  // },
  // {
  //   name: 'DOCUMENT COMPLETED',
  //   value: 'DOCUMENT COMPLETED',
  //   defaultClass: 'mx-1 font-color-qualified border-dark-qualified background-color-light-qualified background-color-qualified',
  //   newClass: 'mx-1 text-white background-color-light-qualified border-dark-qualified',
  //   spanDefaultClass: 'background-color-light-qualified border-dark-qualified',
  //   spanNewClass: 'border-white background-color-light-qualified'
  // },
];

export const priorityColor = {
  'High': 'font-color-high',
  'Medium': 'font-color-medium',
  'Low': 'font-color-low'
};

export const priorityIcon = {
  'High': 'angle-double-up',
  'Medium': 'equals',
  'Low': 'angle-double-down'
};

export const priorityText = {
  'High': 'High Priority',
  'Medium': 'Medium Priority',
  'Low': 'Low Priority'
};

export const colorRoundStatus = {
  'NEW' : 'background-color-blue-light',
  'OPEN' : 'background-color-blue-dark',
  'PROPOSAL CREATION' : 'background-color-light-pending',
  'UNDER REVIEW' : 'background-color-light-progress',
  'NEGOTIATION' : 'background-color-light-pre-qualified',
  'WON LEAD' : 'background-color-light-qualified',
  'LOST LEAD' : 'background-color-not-qualified'
}

export const statusBackgroundColor = {
  'NEW': 'background-color-blue-light text-white',
  'OPEN': 'background-color-blue-dark text-white',
  'PROPOSAL CREATION': 'background-color-light-pending text-white',
  'UNDER REVIEW': 'background-color-light-progress text-white',
  'NEGOTIATION': 'background-color-light-pre-qualified text-white',
  'WON LEAD': 'background-color-light-qualified text-white',
  'LOST LEAD': 'background-color-not-qualified text-white'
};

export const ownerColor = {
  'Unassigned': 'opacity-50'
};

export const responsibleColor = {
  'Unassigned': 'opacity-50'
};

export const lastContactColor = {
  'Never': 'opacity-50'
};

export const leadActionBtn = [
  {
    text: 'UPDATE STATUS',
    icon: 'cogs',
    name: 'status'
  },
  {
    text: 'ASSIGN OWNER',
    icon: 'user-alt',
    name: 'owner'
  },
  {
    text: 'ASSIGN RESPONSIBLE',
    icon: 'user-alt',
    name: 'responsible'
  },
  {
    text: 'MERGE',
    icon: 'object-group',
    name: 'merge'
  },
  {
    text: 'UPDATE FIELD',
    icon: 'i-cursor',
    name: 'field'
  },
  {
    text: 'ADD TASK',
    icon: 'edit',
    name: 'task'
  },
  {
    text: 'ADD NOTE',
    icon: 'file',
    name: 'note'
  },
  {
    text: 'DELETE',
    icon: 'trash-alt',
    name: 'delete'
  },
];

export const leadInfoBtn = [
  {
    name: 'NEW',
    value: 'NEW',
    defaultClass: 'mx-2 font-color-light-blue border-light-blue background-color-light-blue',
    newClass: 'mx-2 text-white background-color-blue-light border-light-blue',
    spanDefaultClass: 'border-light-blue background-color-blue-light',
    spanNewClass: 'border-white background-color-blue-light'
  },
  {
    name: 'OPEN',
    value: 'OPEN',
    defaultClass: 'mx-2 font-color-dark-blue border-dark-blue background-color-dark-blue',
    newClass: 'mx-2 text-white background-color-blue-dark border-dark-blue ',
    spanDefaultClass: 'background-color-blue-dark border-dark-blue',
    spanNewClass: 'border-white background-color-blue-dark'
  },
  {
    name: 'PROPOSAL CREATION',
    value: 'PROPOSAL CREATION',
    defaultClass: 'mx-2 font-color-pending border-dark-pending background-color-light-pending background-color-pending',
    newClass: 'mx-0 text-white background-color-light-pending border-dark-pending',
    spanDefaultClass: 'background-color-light-pending border-dark-pending',
    spanNewClass: 'border-white background-color-light-pending'
  },
  {
    name: 'UNDER REVIEW',
    value: 'UNDER REVIEW',
    defaultClass: 'mx-2 font-color-progress border-dark-progress background-color-light-progress background-color-progress',
    newClass: 'mx-2 text-white background-color-light-progress border-dark-progress',
    spanDefaultClass: 'background-color-light-progress border-dark-progress',
    spanNewClass: 'border-white background-color-light-penprogressding'
  },
  {
    name: 'NEGOTIATION',
    value: 'NEGOTIATION',
    defaultClass: 'mx-2 font-color-pre-qualified border-dark-pre-qualified background-codarklor-light-pre-qualified background-color-pre-qualified',
    newClass: 'mx-2 text-white background-color-light-pre-qualified border-dark-pre-qualified',
    spanDefaultClass: 'background-color-light-pre-qualified border-dark-pre-qualified',
    spanNewClass: 'border-white background-color-light-pre-qualified'
  },
  {
    name: 'WON LEAD',
    value: 'WON LEAD',
    defaultClass: 'mx-1 font-color-qualified border-dark-qualified background-color-light-qualified background-color-qualified',
    newClass: 'mx-1 text-white background-color-light-qualified border-dark-qualified',
    spanDefaultClass: 'background-color-light-qualified border-dark-qualified',
    spanNewClass: 'border-white background-color-light-qualified'
  },
];

export const activitySideButton = [
  {
    name: 'Email',
    icon: 'envelope'
  },
  {
    name: 'Chat',
    icon: 'comment-alt'
  },
  {
    name: 'Notes',
    icon: 'file-alt'
  },
  {
    name: 'Document',
    icon: 'file-alt'
  },
  {
    name: 'Activity',
    icon: 'clock',
  },
  {
    name: 'Interest',
    icon: 'clock',
  },
  {
    name: 'Sessions',
    icon: 'list-alt'
  },
  {
    name: 'Source',
    icon: 'clipboard'
  }
];

export const statusColor = {
  'New Leads': 'background-color-blue-light text-white',
  'Open': 'background-color-blue-dark text-white',
  'Proposal Creation': 'background-color-light-pending text-white',
  'Under Review': 'background-color-light-progress text-white',
  'Negotiation': 'background-color-light-pre-qualified text-white',
  'Won Lead': 'background-color-light-qualified text-white',
  'Lost Lead': 'bacKground-color-not-qualified text-white'
};