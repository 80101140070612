import React, { Component } from 'react';
import { connect } from "react-redux";

import { projectActions } from '_actions';

import { MDBIcon, MDBDropdown, MDBDropdownToggle, MDBDropdownMenu, MDBDropdownItem } from 'mdbreact';
import { priorityColor, statusBackgroundColor, responsibleColor, lastContactColor, priorityIcon } from '_pages/Leads/constants/BreadCrumbs'
import Moment from "react-moment";
//import { statusColor } from '_pages/Leads/constants/BreadCrumbs'
import DeleteConfirmationModal from '_components/Modal/DeleteConfirmationModal'
//import {Link} from "react-router-dom";

export default class LeadStatusList extends Component {

  constructor(props) {
    super(props);

    this.state = {
      filtered: [],
      priorityColor,
      statusBackgroundColor,
      responsibleColor,
      lastContactColor,
      priorityIcon,
      modalOpen: false,
      statusToDelete: {},
      deleteNum: '',
      status: {},
      statusColor:  ['teal', 'blue', 'yellow', 'orange', 'red', 'green', 'dark-gray', 'lime', 'cyan', 'indigo', 'brown', 'purple']
    };

  }

  handleDefaultStatus = (status_id, status, index) => {
    let { leadData } = this.props;
    if (leadData) {

      let project_id = this.props.projectId;
      // console.log('project_id default status', project_id);
      // console.log('status_id default status', status_id);
      // console.log('status default status', status);

      let defaultStatus = {
        status: status
      };

      this.props.createProjectDefaultStatus(project_id, status_id, defaultStatus);

      setTimeout(
        function () {
          this.setState({position: 1}, window.history.go());
        }
          .bind(this),
        2000
      );
    }
    this.renderFilteredTableBody();
  };

  handleConversionSales = (status_id, sales, index) => {
    let { leadData } = this.props;
    if (leadData) {
      //console.log('conversion status', leadData);
      //leadData.map((value, index) => {

        let project_id = this.props.projectId;
        // console.log('project_id conversion status', project_id);
        // console.log('status_id conversion status', status_id);
        // console.log('status conversion status', sales);

        let conversionSales = {
          sales: sales
        };

        this.props.createProjectConversionStatus(project_id, status_id, conversionSales);

        setTimeout(
            function () {
              this.setState({position: 1}, window.history.go());
            }
                .bind(this),
            2000
        );

      //})
    }
    this.renderFilteredTableBody();
  };

  handleToggle = () =>{
    let { modalOpen } = this.state;
    this.setState({
      modalOpen: !modalOpen,
      deleteNum: 'single'
    })
  };

  approveModal = (statusToDelete) => {
    let project = JSON.parse(sessionStorage.getItem('project'));
    let projectId = project.id;
    //console.log("approveModal statusToDelete", statusToDelete);
    const statusId = statusToDelete.id;
    const statusName = statusToDelete.name;

    if(statusId && statusName){
      // console.log("statusId", statusId);
      // console.log("statusToDelete", statusToDelete);
      // console.log("projectId", projectId);
      this.props.deleteLeadStatus(statusToDelete, projectId, statusId);

      // setTimeout(
      //     function () {
      //       this.setState({position: 1}, window.history.go());
      //     }
      //         .bind(this),
      //     2000
      // );

      this.setState({
        statusToDelete: {}
      }, () => {
        this.props.handleGetProjectStatus();
      })
    }

    // this.handleDeleteLeadStatus(statusName, statusId)
  };

  sortView(data){
    let { sort } = this.props;

    //data = Object.assign({}, data);
    //data = data.leadData;

    // console.log("sort data", data);
    // console.log("sort", sort);

    // return data;

    switch(sort){
      case 'leadStatus_az':
        data = data.sort((a, b) => {
          if (a.name > b.name) { return 1 }
          if (b.name > a.name) { return -1 }
          return 0;
        });
        return data;
      case 'leadStatus_za':
        data = data.sort((a, b) => {
          if (a.name > b.name) { return -1 }
          if (b.name > a.name) { return 1 }
          return 0;
        });
        return data;
      case 'description_az':
        data = data.sort((a, b) => {
          if (a.description > b.description) { return 1 }
          if (b.description > a.description) { return -1 }
          return 0;
        });
        return data;
      case 'description_za':
        data = data.sort((a, b) => {
          if (a.description > b.description) { return -1 }
          if (b.description > a.description) { return 1 }
          return 0;
        });
        return data;
      case 'dateCreated_latest':
        data = data.sort((a, b) => a.id < b.id ? 1 : -1);
        return data;
      case 'dateCreated_oldest':
        data = data.sort((a, b) => a.id > b.id ? 1 : -1);
        return data;
      default:
        return null;
    }
  }

 // handleDeleteLeadStatus(name, status_id) {

    // let {leadData} = this.props;
    //
    // if (leadData) {
    //
    //   leadData.map((value, index) => {
    //     if (name === value.name) {
    //       let matchedStatus = {
    //         'name': name
    //       };
    //       let project_id = this.props.projectId;
    //
    //       console.log('matchedStatus', matchedStatus);
    //       console.log('project_id', project_id);
    //       this.props.deleteLeadStatus(matchedStatus, project_id, status_id);
    //     }
    //     return null;
    //   })
    // }
   // this.renderFilteredTableBody();
  //};

  handlePassData = (data) => {
    //console.log("data", data);

    this.setState({
      statusToDelete: data
    })

  };

  //this function is used to render the table body of the lead list form
  renderFilteredTableBody = () => {
/*    let project = {};
    let hasData = false;
    if(this.props.projects.items !== undefined)  {
      project = this.props.projects.items.data;
      if(project.info !==undefined){
        hasData = true;
      }*/

    let { leadData } = this.props;
    //let { leadData } = this.props;

    if(leadData){
      leadData = this.sortView(leadData);
    }

    const statusColor = this.state.statusColor;

    return (
      leadData.map((value, index) => {
        return (
          <tr key={index}>
            {/*<td className='border-table-right'>*/}
            {/*  <label className='checkboxcontainer ml-3'>*/}
            {/*    <input type='checkbox' name='check-box' onClick={this.props.onSelect} />*/}
            {/*    <span className='checkmark'></span>*/}
            {/*  </label>*/}
            {/*</td>*/}
            <td className='border-table-right'>
              <div className='row d-flex pl-4 pr-3'>
                <div>

                </div>
                <div className='col text-left my-auto pr-0'>
                  <div className='row'>
                    <div className={`bg--${statusColor[index]} lead-round mr-2`}> </div>
                    <span className='font-bold-black'>{value.name}</span>
                  </div>
                </div>
                <div className='col my-auto text-right px-0'>
                  <MDBDropdown>
                    <MDBDropdownToggle flat className='p-0 shadow-none m-0 lead-menu'>
                      <MDBIcon icon='ellipsis-v' className='opacity-40 px-3 py-2 font-color-black' />
                    </MDBDropdownToggle>
                    <MDBDropdownMenu className='border-radius-3 border-unset box-shadow min-width-unset top-negative-3 py-0'>
                      {/*<MDBDropdownItem divider className='m-0' />*/}
                      {/*<MDBDropdownItem*/}
                      {/*  className='font-size-11 p-2 px-3'*/}
                      {/*  onClick={() => this.props.editStatus(value)}>*/}
                      {/*  <MDBIcon far icon='edit' className='mr-2' />Edit Status*/}
                      {/*</MDBDropdownItem>*/}
                      {/*<MDBDropdownItem divider className='m-0' />*/}
                      <MDBDropdownItem
                        className='font-size-11 p-2 px-3'
                        onClick={(event) => {
                          event.preventDefault();
                          this.handleDefaultStatus(value.id, value.id);
                        }}>
                        <MDBIcon far icon='paper-plane' className='mr-2'/>
                        Set as Default Status
                      </MDBDropdownItem>
                      <MDBDropdownItem divider className='m-0' />
                      <MDBDropdownItem
                        className='font-size-11 p-2 px-3'
                        onClick={(event) => {
                          event.preventDefault();
                          this.handleConversionSales(value.id, value.id);
                        }}>
                        <MDBIcon far icon='paper-plane' className='mr-2' />
                        Set as Conversion to Sales
                      </MDBDropdownItem>
                      {/*<MDBDropdownItem divider className='m-0' />*/}
                      {/*<MDBDropdownItem className='font-size-11 p-2 px-3'><MDBIcon icon='paint-brush' className='mr-2' />Change Color</MDBDropdownItem>*/}
                      {/*<MDBDropdownItem divider className='m-0' />*/}
                      {/*<MDBDropdownItem*/}
                      {/*  className='font-size-11 p-2 px-3 color-red'*/}
                      {/*  onClick={(event) => { event.preventDefault(); this.handleToggle(); this.handlePassData(value)}}*/}
                      {/*><MDBIcon far icon='trash-alt' className='mr-2' />*/}
                      {/*  Delete Status*/}
                      {/*</MDBDropdownItem>*/}
                      </MDBDropdownMenu>
                  </MDBDropdown>
                </div>
              </div>
            </td>
            <td className='border-table-right'>
              <div className='col'>
                <div className={`col px-0 font-size-11`}>
                  {value.description}
                </div>
              </div>
            </td>
            {/*<td className='border-table-right'>*/}
            {/*  <div className='row d-flex pl-4 pr-3'>*/}
            {/*    <div>*/}
            {/*      <img src={imageIcon} alt='' className='mr-2 status-lead-avatar' />*/}
            {/*    </div>*/}
            {/*    <div className='col text-left my-auto'>*/}
            {/*      <div className='row'>*/}
            {/*        <span className='font-size-12 font-bold-black'>{this.handleTextEllipsis(value.autoAssignOwner, 22)}</span>*/}
            {/*      </div>*/}
            {/*    </div>*/}
            {/*  </div>*/}
            {/*</td>*/}
            {/*<td className='border-table-right'>*/}
            {/*  <div className='row d-flex pl-4 pr-3'>*/}
            {/*    <div>*/}
            {/*      <img src={imageIcon} alt='' className='mr-2 status-lead-avatar' />*/}
            {/*    </div>*/}
            {/*    <div className='col text-left my-auto'>*/}
            {/*      <div className='row'>*/}
            {/*        <span className='font-size-12 font-bold-black'>{this.handleTextEllipsis(value.autoAssignResponsible, 22)}</span>*/}
            {/*      </div>*/}
            {/*    </div>*/}
            {/*  </div>*/}
            {/*</td>*/}
            <td className='' >
              <div className='col px-3'>
                <div className=''>
                  <p className='font-size-12 opacity-60 mb-0'>
                    <Moment date={value.created} format="MM/DD/YYYY" />
                  </p>
                </div>
              </div>
            </td>
            {/*<td>*/}
            {/*  <div className='col'>*/}
            {/*    <div className={`col px-0 text-center`}>*/}
            {/*      <span className='font-weight-600 font-size-12'>{value.leadCount}</span>*/}
            {/*    </div>*/}
            {/*  </div>*/}
            {/*</td>*/}
          </tr>
        )
      })
    )
  };

  render() {
    let statusToDelete = this.state.statusToDelete;
    return (
      <tbody>
        { this.renderFilteredTableBody() }
        <tr>
          <td colSpan='3'>
            <DeleteConfirmationModal toggle={this.handleToggle} modalOpen={this.state.modalOpen} statusToDelete={statusToDelete} onApprove={this.approveModal} target={statusToDelete && statusToDelete.name}/>
          </td>
        </tr>
      </tbody>

    );
  }
}

function mapState(state) {
  const { projects, project, deleting } = state;
  return { deleting, projects, project };
}

const actionCreators = {
  deleteLeadStatus: projectActions.deleteLeadStatus,
  createProjectDefaultStatus: projectActions.createProjectDefaultStatus,
  enableProjectStatus: projectActions.enableProjectStatus,
  createProjectConversionStatus: projectActions.createProjectConversionStatus
};

const connectedViewProjects = connect(mapState, actionCreators)(LeadStatusList);
export { connectedViewProjects as LeadStatusList };
