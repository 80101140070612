import React from 'react';
import { Link } from 'react-router-dom'
import { connect } from 'react-redux';
import { userActions } from '_actions';

import { MDBContainer, MDBBtn, MDBRow, MDBCol } from 'mdbreact';
import { FormValidation } from '_components/FormValidation'

import logo from '_assets/images/logo.png';

class RegisterFirstStep extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      user: {
        firstName: '',
        lastName: '',
        email: '',
        password: '',
        matchPassword: ''
      },
      submitted: false,
      class: true,
      formErrors: {
        firstName: 'Please enter your first name.',
        lastName: 'Please enter your last name.',
        email: 'Please enter your email.',
        password: 'Please enter a password.',
        matchPassword: 'Please confirm your password.'
      },
      firstNameValid: false,
      lastNameValid: false,
      emailValid: false,
      passwordValid: false,
      matchPasswordValid: false,
      formValid: false
    };

    this.handleChange = this.handleChange.bind(this);
    this.handleSubmit = this.handleSubmit.bind(this);
  }

  validateField(fieldName, value) {
    let fieldValidationErrors = this.state.formErrors;
    let firstNameValid = this.state.firstNameValid;
    let lastNameValid = this.state.lastNameValid;
    let emailValid = this.state.emailValid;
    let passwordValid = this.state.passwordValid;
    let matchPasswordValid = this.state.matchPasswordValid;

    let isAllWhitespace = (value.replace(/(?!^ +$)^.+$/gm, "") !== '');

    switch(fieldName) {
      case 'email':
        if (value === "" || isAllWhitespace){ // check if empty
          emailValid = false;
          fieldValidationErrors.email = 'Please enter your email.';
        } else { // check if valid
          emailValid = (value.match(/^([\w.%+-]+)@([\w-]+\.)+([\w]{2,})$/i));
          fieldValidationErrors.email = emailValid ? '' : 'Please enter your email address in valid format: sample@domain.com';
        }
        break;
      case 'password':
        if (value === "" || isAllWhitespace) { // check if empty
          passwordValid = false;
          fieldValidationErrors.password = 'Please enter your password.';
        } else {
          passwordValid = (value.length >= 6); // check if valid
          fieldValidationErrors.password = passwordValid ? '': 'Please enter at least 6 characters.';
        }
        break;
      case 'matchPassword':
        if (value === "" || isAllWhitespace) { // check if empty
          matchPasswordValid = false;
          fieldValidationErrors.matchPassword = 'Please confirm your password.';
        } else {
          matchPasswordValid = (this.state.user.password === this.state.user.matchPassword); // check if matches
          fieldValidationErrors.matchPassword = matchPasswordValid ? '': 'Your passwords do not match, please enter the same password you entered before.';
        }
        break;
      case 'firstName':
        if (value === "" || isAllWhitespace) { // check if empty
          firstNameValid = false;
          fieldValidationErrors.firstName = 'Please enter your first name.';
        } else {
          firstNameValid = (value.length >= 2); // check if valid
          fieldValidationErrors.firstName = firstNameValid ? '': 'Please enter your first name.';
        }
        break;
      case 'lastName':
        if (value === "" || isAllWhitespace) { // check if empty
          lastNameValid = false;
          fieldValidationErrors.lastName = 'Please enter your last name.';
        } else {
          lastNameValid = (value.length >= 2); // check if valid
          fieldValidationErrors.lastName = lastNameValid ? '': 'Please enter your last name.';
        }
        break;
      default:
        break;
    }


    // console.log('fieldValidationErrors', this.state.formErrors);
    // console.log('firstNameValid', this.state.firstNameValid);
    // console.log('lastNameValid', this.state.lastNameValid);
    // console.log('emailValid', this.state.emailValid);
    // console.log('passwordValid', this.state.passwordValid);
    // console.log('matchPasswordValid', this.state.matchPasswordValid);

    this.setState({
      formErrors: fieldValidationErrors,
      firstNameValid: firstNameValid,
      lastNameValid: lastNameValid,
      emailValid: emailValid,
      passwordValid: passwordValid,
      matchPasswordValid: matchPasswordValid,
    }, this.validateForm);
  }

  validateForm() {
    this.setState({
      formValid: this.state.emailValid && this.state.passwordValid && this.state.firstNameValid && this.state.lastNameValid && this.state.matchPasswordValid
    }, () => {
      //console.log('formValid', this.state.formValid);
    });
  }

  handleChange(e) {
    const { name, value } = e.target;
    const { user } = this.state;
    let valueToSubmit = value;

    if(name === 'firstName' || name === 'lastName'){
      valueToSubmit = value.toLowerCase().split(' ').map(word => word.charAt(0).toUpperCase() + word.slice(1)).join(' ');
    }

    this.setState({
      user: {
        ...user,
        [name]: valueToSubmit
      }
    }, () => {
      this.validateField(name, valueToSubmit);
    });
  }

  handleSubmit(e) {
    e.preventDefault();
    const { user } = this.state;

    this.validateForm();
    this.setState({ submitted: true }, () => {
      if (this.state.formValid) {
        if(this.state.submitted){
          this.props.register({
            firstName: user.firstName,
            lastName: user.lastName,
            email: user.email,
            password: user.password,
          });
        }
      }
    });
  }

  errorClass(error) {
    return(error.length === 0 ? 'is-passed' : 'is-failed');
  }

  render() {
    const { registering  } = this.props;
    const { user, submitted, formValid } = this.state;

    return (
      <MDBContainer fluid className='background-image-register-first'>
        <div className='pt-4 pl-0 pr-0'>
          <form onSubmit={this.handleSubmit}>
            <MDBRow className='m-0'>
              <MDBCol className='mt-2'>
                <MDBCol className='margin-auto width-400 m-t--lg'>
                  <img src={logo} alt='' className='width-190' />
                  <p className='text-left font-size-27 font-weight-600 mt-3 font-family-crimson font-color-gray letter-spacing-1'>Create custom Forms</p>
                  <p className='font-size-12 width-300'>
                    Get customer feedback, accept job applications, reserve bookings, setup a registration page, &mdash; the applications are endless.
                  </p>
                  <p className='text-left font-size-27 font-weight-600 mt-3 font-family-crimson font-color-gray letter-spacing-1'>Manage your Leads</p>
                  <p className='font-size-12 width-300'>
                    Generate leads from forms, add new leads, or import your existing leads. Set status and priority, assign leads to agents, add notes &mdash; we'll help you convert your leads with ease.
                  </p>
                  <p className='text-left font-size-27 font-weight-600 mt-3 font-family-crimson font-color-gray letter-spacing-1'>Stay in the Loop</p>
                  <p className='font-size-12 width-300'>
                    Oversee your forms and leads with activity logs &mdash; from lead generation to conversion, who updated what, and how your agents handle your projects.
                  </p>
                  <p className='font-bold-black'>
                    Simple and intuitive, flexible and scalable.
                  </p>
                </MDBCol>
              </MDBCol>
              <MDBCol className='mt-2 px-0'>
                <MDBCol className='ml-lg-5 ml-xl-5 mx-md-2  width-300 m-t--lg'>
                  <p className='font-size-27 font-weight-600 mb-1 text-center font-family-crimson font-color-gray letter-spacing-1'>Sign up now, it's free.</p>
                  <p className='font-size-12 light-black text-center'>
                    Enter your details below to get started. <br/> We’ll take care of the rest.
                  </p>

                  <div
                    className={`form-group ${submitted && this.errorClass(this.state.formErrors.firstName)}`}
                  >
                    <input
                      type='text'
                      name='firstName'
                      placeholder='First Name'
                      value={user.firstName}
                      onChange={this.handleChange}
                    />
                    <div className={`form-validation__container ${(!formValid && submitted) ? 'display-block' : 'display-none'}`}>
                      <FormValidation fieldName='firstName' formErrors={this.state.formErrors}/>
                    </div>
                  </div>

                  <div className={`form-group ${submitted && this.errorClass(this.state.formErrors.lastName)}`} >
                    <input
                      type="text"
                      name="lastName"
                      placeholder='Last Name'
                      value={user.lastName}
                      onChange={this.handleChange}
                    />
                    <div className={`form-validation__container ${(!formValid && submitted) ? 'display-block' : 'display-none'}`}>
                      <FormValidation fieldName='lastName' formErrors={this.state.formErrors}/>
                    </div>
                  </div>

                  <div className={`form-group ${submitted && this.errorClass(this.state.formErrors.email)}`}>
                    <input
                      type="email"
                      placeholder='Email'
                      name="email"
                      value={user.email}
                      onChange={this.handleChange}
                    />
                    <div className={`form-validation__container ${(!formValid && submitted) ? 'display-block' : 'display-none'}`}>
                      <FormValidation fieldName='email' formErrors={this.state.formErrors}/>
                    </div>
                  </div>

                  <div className={`form-group ${submitted && this.errorClass(this.state.formErrors.password)}`}>
                    <input
                      type="password"
                      placeholder='Password'
                      name="password"
                      value={user.password}
                      onChange={this.handleChange}
                    />
                    <div className={`form-validation__container ${(!formValid && submitted) ? 'display-block' : 'display-none'}`}>
                      <FormValidation fieldName='password' formErrors={this.state.formErrors}/>
                    </div>
                  </div>
                  <div
                    className={`form-group ${submitted && this.errorClass(this.state.formErrors.matchPassword)}`}
                  >
                    <input
                      type="password"
                      placeholder='Confirm Password'
                      name="matchPassword"
                      value={user.matchPassword}
                      onChange={this.handleChange}
                    />
                    <div className={`form-validation__container ${(!formValid && submitted) ? 'display-block' : 'display-none'}`}>
                      <FormValidation fieldName='matchPassword' formErrors={this.state.formErrors}/>
                    </div>
                  </div>

                  <div className="form-group">
                    <p className={this.state.class ? 'mt-3 font-size-10 text-center px-3 font-weight-normal light-black' : 'display-hidden'}>
                      By creating a Platform account, you’re agreeing to accept the <span className='color-orange'>Propelrr Platform Terms of Service</span>
                    </p>
                    <MDBBtn flat type='submit' className={this.state.class ? 'col m-0 mb-4 bg-orange br-2 color-white' : 'display-hidden'}>REGISTER ACCOUNT {registering &&
                    <img src="data:image/gif;base64,R0lGODlhEAAQAPIAAP///wAAAMLCwkJCQgAAAGJiYoKCgpKSkiH/C05FVFNDQVBFMi4wAwEAAAAh/hpDcmVhdGVkIHdpdGggYWpheGxvYWQuaW5mbwAh+QQJCgAAACwAAAAAEAAQAAADMwi63P4wyklrE2MIOggZnAdOmGYJRbExwroUmcG2LmDEwnHQLVsYOd2mBzkYDAdKa+dIAAAh+QQJCgAAACwAAAAAEAAQAAADNAi63P5OjCEgG4QMu7DmikRxQlFUYDEZIGBMRVsaqHwctXXf7WEYB4Ag1xjihkMZsiUkKhIAIfkECQoAAAAsAAAAABAAEAAAAzYIujIjK8pByJDMlFYvBoVjHA70GU7xSUJhmKtwHPAKzLO9HMaoKwJZ7Rf8AYPDDzKpZBqfvwQAIfkECQoAAAAsAAAAABAAEAAAAzMIumIlK8oyhpHsnFZfhYumCYUhDAQxRIdhHBGqRoKw0R8DYlJd8z0fMDgsGo/IpHI5TAAAIfkECQoAAAAsAAAAABAAEAAAAzIIunInK0rnZBTwGPNMgQwmdsNgXGJUlIWEuR5oWUIpz8pAEAMe6TwfwyYsGo/IpFKSAAAh+QQJCgAAACwAAAAAEAAQAAADMwi6IMKQORfjdOe82p4wGccc4CEuQradylesojEMBgsUc2G7sDX3lQGBMLAJibufbSlKAAAh+QQJCgAAACwAAAAAEAAQAAADMgi63P7wCRHZnFVdmgHu2nFwlWCI3WGc3TSWhUFGxTAUkGCbtgENBMJAEJsxgMLWzpEAACH5BAkKAAAALAAAAAAQABAAAAMyCLrc/jDKSatlQtScKdceCAjDII7HcQ4EMTCpyrCuUBjCYRgHVtqlAiB1YhiCnlsRkAAAOwAAAAAAAAAAAA==" alt="loader"/>
                    }
                    </MDBBtn>
                    <hr />
                    <p className='font-size-10 text-center'>
                      Already have an account? &nbsp;
                      <Link to='/login'><span className='color-orange'>Login</span></Link>
                    </p>
                  </div>
                </MDBCol>
              </MDBCol>
            </MDBRow>
          </form>
        </div>
      </MDBContainer>
    );
  }
}

function mapState(state) {
  const { registering } = state.registration;
  return { registering };
}

const actionCreators = {
  register: userActions.register
}

const connectedRegisterPage = connect(mapState, actionCreators)(RegisterFirstStep);
export { connectedRegisterPage as RegisterFirstStep };
