import React, {Component, useState} from 'react'
import { MDBContainer, MDBRow, MDBCol, MDBModal, MDBModalHeader, MDBModalBody } from 'mdbreact'
import {influencerActions} from "_actions";
import {connect} from "react-redux";

class ManageNoteModal extends Component {

  constructor(props) {
    super(props);

    this.state = {
      isTitleError: false,
      isContentError: false,
      toggleTabState: 1,
    }


    this.titleInput = React.createRef();
    this.contentInput = React.createRef();


  }

  handleCloseManageNotesModal = (mode) => {
    this.props.handleCloseManageNotesModal(mode);
    this.setTitle();
    this.setContent();

    this.handleClearInput();
  }

  setTitle = value => {
    this.setState({isTitleError: false});
  }

  setContent = value => {
    this.setState({isContentError: false});
  }


  isFieldValid() {

    // console.log(this.titleInput.current.value);
    let isValid = true;

    const title = this.titleInput.current.value;
    const content = this.contentInput.current.value;

    if(title === '' ) {
      this.setState({isTitleError: true});
      isValid = false;
    }

    if(content === '') {
      this.setState({isContentError: true});
      isValid = false;
    }

    return isValid;
  }

  handleManageNote() {

    const {  mode } = this.props;

    let isEdit = mode !== 'add';

    const isValue = this.isFieldValid();


    if(isValue) {
      if(isEdit) {
        this.handleEditInfluencerNote();
      } else {
        this.handleAddInfluencerNote();
      }
    }
  }

  handleAddInfluencerNote () {
    const {influencerId, projectId, mode, addInfluencerNote} = this.props;


    const title = this.titleInput.current.value;
    const content = this.contentInput.current.value;

    const note = {
      title: title,
      content: content,
    }

    addInfluencerNote(projectId, influencerId, note);

    this.handleCloseManageNotesModal(mode);

  }

  handleEditInfluencerNote () {
    const {influencerId, projectId, mode, updateInfluencerNote, data} = this.props;


    const title = this.titleInput.current.value;
    const content = this.contentInput.current.value;

    const note = {
      title: title,
      content: content,
    }

    updateInfluencerNote(projectId, influencerId, data.id, note);

    this.handleCloseManageNotesModal(mode);

  }

  handleClearInput = () => {
    // Clear the current.value of the ref
    const {mode, data} = this.props;

    if (mode === 'add') {
        this.titleInput.current.value = '';
        this.contentInput.current.value = '';
    }

    if (mode === 'edit') {
        this.titleInput.current.value = data.title;
        this.contentInput.current.value = data.content;
    }
    
  }

  componentDidUpdate(prevProps) {
    // Check if the props or state have changed
    if (prevProps.mode !== this.props.mode) {
        console.log('previous.. ' + this.prevProps)
        console.log('current.. ' + this.props)
      // Perform the necessary update
        if (this.props.mode === 'add') {
            this.titleInput.current.value = '';
            this.contentInput.current.value = '';
  
        }

        if (this.props.mode === 'edit') {
                this.titleInput.current.value = this.props.data.title;
                this.contentInput.current.value = this.props.data.content;
          
        }
            
     }
}

  render() {
    const { isOpen, mode, data } = this.props;
    const {isTitleError, isContentError} = this.state;

    console.log('data:', data);

    let isEdit = mode !== 'add';
    let activeClass = isOpen ? 'active' : '';


    return (
        <React.Fragment>
          <div className={`drawer-overlay ${activeClass}`} onClick={(mode) => this.handleCloseManageNotesModal(mode)}></div>
          <div className={`side-drawer-main ${activeClass}`}>
              <div className='side-drawer-wrapper'>
                  <div className='side-drawer-title'>
                      <h3>{mode.toUpperCase()} NOTE</h3>
                      <span className='side-drawer-close' onClick={(mode) => this.handleCloseManageNotesModal(mode)}><i className="icon-lms icon-close"></i></span>
                  </div>
                  <div className='side-drawer-tabs'>
                      <div className='side-drawer-tabs-wrapper'>
                          <div className='control-tabs-box'>
                              <div className={this.state.toggleTabState === 1 ? 'tabs-btn active' : 'tabs-btn'} onClick={() => this.toggleTab(1)}>
                                  <p>
                                      Note Information
                                  </p>
                              </div>
                          </div>
                          <div className='content-tabs-box'>
                              <div className={this.state.toggleTabState === 1 ? 'tabs-content active' : 'tabs-content'}>
                                  <div className='forms-tab'>
                                      <MDBRow>
                                          <MDBCol className='col-12 form-group-box'>
                                              <div className='input-group-box full-box-'>
                                                  <div className={isTitleError ? `field-box error-field` : `field-box`}>
                                                    <label htmlFor={'title'}>Title</label>
                                                    <input ref={this.titleInput} type='text' id={'title'} name={'title'} defaultValue={isEdit ? data.title : ''} onChange={(e) => this.setTitle(e.target.value)} />
                                                    {isTitleError &&<span>This field is required</span>}
                                                  </div>
                                                  <div className={isContentError ? `field-box error-field` : `field-box`}>
                                                    <label htmlFor={'content'}>Content</label>
                                                    <input ref={this.contentInput}  type='text' id={'content'} name={'content'}  defaultValue={isEdit ? data.content : ''} onChange={(e) => this.setContent(e.target.value)} />
                                                    {isContentError &&<span>This field is required</span>}
                                                  </div>
                                              </div>
                                          </MDBCol>
                                      </MDBRow>
                                  </div>
                              </div>
                          </div>
                          <div className='tab-form-btn-group'>
                              <MDBRow>
                                  <MDBCol className='text-right'>
                                    <span className='drawer-btn cancel-btn' onClick={(mode) => this.handleCloseManageNotesModal(mode)}>
                                      Cancel
                                    </span>
                                    <span className='drawer-btn save-btn' onClick={() => this.handleManageNote()}>
                                      {mode === 'add' ? `Save Note` : `Update Note`}
                                    </span>
                                  </MDBCol>
                              </MDBRow>
                          </div>
                      </div>
                  </div>
              </div>
          </div>
          {/* <MDBContainer className='modal-container modal-form-container'>
            <MDBRow>
              <MDBCol>
                <MDBModal isOpen={isOpen} centered tabIndex='-1'>
                  <MDBModalHeader className='confirmation-modal-header text-uppercase p-2'><span className='pl-1 mx-2 letter-spacing-1 font-weight-600 font-color-gray font-size-13'>{mode.toUpperCase()} NOTE</span></MDBModalHeader>
                  <MDBModalBody>
                    <MDBRow className='form-group-row'>
                      <MDBCol className={isTitleError ? `col-12 has-error-input` : `col-12`}>
                        <label htmlFor={'title'}>Title</label>
                        <input ref={this.titleInput} type='text' id={'title'} name={'title'} defaultValue={isEdit ? data.title : ''} onChange={(e) => this.setTitle(e.target.value)} />
                        {isTitleError &&<span>This field is required</span>}
                      </MDBCol>
                      <MDBCol className={isContentError ? `col-12 has-error-input` : `col-12`}>
                        <label htmlFor={'content'}>Content</label>
                        <input ref={this.contentInput}  type='text' id={'content'} name={'content'}  defaultValue={isEdit ? data.content : ''} onChange={(e) => this.setContent(e.target.value)} />
                        {isContentError &&<span>This field is required</span>}
                      </MDBCol>
                    </MDBRow>
                    <MDBRow>
                      <MDBCol className='text-center'>
                        <button className='modal-btn save-btn-modal' onClick={() => this.handleManageNote()}>
                          {mode === 'add' ? `Save Note` : `Update Note`}
                        </button>
                        <button className='modal-btn cancel-btn-modal' onClick={(mode) => this.handleCloseManageNotesModal(mode)}>
                          Cancel
                        </button>
                      </MDBCol>
                    </MDBRow>
                  </MDBModalBody>
                </MDBModal>
              </MDBCol>
            </MDBRow>
          </MDBContainer> */}
        </React.Fragment>
    )
  }

}

function mapState(state) {
  const { influencers } = state;
  return { influencers }
}

const actionCreators = {
  addInfluencerNote : influencerActions.addInfluencerNote,
  updateInfluencerNote : influencerActions.updateInfluencerNote,
};

const connectedViewManageNoteModal = connect(mapState, actionCreators)(ManageNoteModal);
export { connectedViewManageNoteModal as ManageNoteModal };