import React from 'react';
import { Route, Redirect } from 'react-router-dom';
import { httpManagerService as httpManager } from  '_services';

export const PrivateRoute = ({ component: Component, ...rest }) => (
    <Route {...rest}
           render = { props => httpManager.isLoginWithUserCredentials()
            ? <Component {...props} />
            : <Redirect to={{ pathname: '/login', state: { from: props.location } }} />
    } />
)
