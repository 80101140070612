import React, { useState, useEffect } from "react";
import { useSelector, useDispatch } from "react-redux";
import { MDBRow, MDBCol } from "mdbreact";
import { utilitiesService } from "_services";
import FormGroupTitle from "_pages/Propelrr/components/Form/FormGroupTitle";
import InputForm from "_pages/Propelrr/components/Form/InputForm";
import { influencerActions, projectActions } from "_actions";

const EditInfluencerDrawer = (props) => {
  const { isOpen, data, isEditInfluencerDrawer, onHandleEditInfluencerDrawer } =
    props;

  const [state, setState] = useState({
    name: "",
    email: "",
    contact: "",
    contacted: "",
    handlerName: "",
    handlerEmail: "",
    handlerContact: "",
    toggleTabState: 1,
    validIdType: "",
    validIdNo: "",
    address: "",
    nameError: "",
    emailError: "",
  });

  // Use useSelector to access the Redux store state
  const influencers = useSelector((state) => state.influencers);
  const influencerLoaded = useSelector((state) => state.influencerLoaded);

  useEffect(() => {
    if (influencers && influencers.influencer) {
      const {
        name,
        email,
        contact,
        contacted,
        handler,
        validIdType,
        validIdNo,
        address,
      } = influencers.influencer.data;

      // Decrypt the encrypted data using utilitiesService.decrypt
      const decryptedEmail = utilitiesService.decrypt(email);
      const decryptedContact = utilitiesService.decrypt(contact);
      const decryptedHandlerName = handler
        ? utilitiesService.decrypt(handler.name)
        : "";
      const decryptedHandlerEmail = handler
        ? utilitiesService.decrypt(handler.email)
        : "";
      const decryptedHandlerContact = handler
        ? utilitiesService.decrypt(handler.contact)
        : "";
      const decryptedValidIdType = utilitiesService.decrypt(validIdType);
      const decryptedValidIdNo = utilitiesService.decrypt(validIdNo);
      const decryptedAddress = utilitiesService.decrypt(address);

      setState((prevState) => ({
        ...prevState,
        name,
        email: decryptedEmail,
        contact: decryptedContact,
        contacted,
        handlerName: decryptedHandlerName,
        handlerEmail: decryptedHandlerEmail,
        handlerContact: decryptedHandlerContact,
        validIdType: decryptedValidIdType,
        validIdNo: decryptedValidIdNo,
        address: decryptedAddress,
      }));
    }
  }, [influencers]);

  useEffect(() => {
    if (isEditInfluencerDrawer && data) {
      setState((prevState) => ({
        ...prevState,
        name: data.name || "",
        email: data.email ? utilitiesService.decrypt(data.email) : "",
        contact: data.contact ? utilitiesService.decrypt(data.contact) : "",
        contacted: data.contacted || "",
        handlerName: data.handler
          ? utilitiesService.decrypt(data.handler.name)
          : "",
        handlerEmail: data.handler
          ? utilitiesService.decrypt(data.handler.email)
          : "",
        handlerContact: data.handler
          ? utilitiesService.decrypt(data.handler.contact)
          : "",
        validIdType: data.validIdType
          ? utilitiesService.decrypt(data.validIdType)
          : "",
        validIdNo: data.validIdNo
          ? utilitiesService.decrypt(data.validIdNo)
          : "",
        address: data.address ? utilitiesService.decrypt(data.address) : "",
        nameError: "",
        emailError: "",
      }));
    }

    console.log("isEdit: state after", state);
  }, [isEditInfluencerDrawer, data]);

  // Define state update functions
  const setName = (nameValue) => {
    setState((prevState) => ({
      ...prevState,
      name: nameValue,
    }));
  };

  const setEmail = (emailValue) => {
    setState((prevState) => ({
      ...prevState,
      email: emailValue,
    }));
  };

  const setContact = (contactValue) => {
    setState((prevState) => ({
      ...prevState,
      contact: contactValue,
    }));
  };

  const setContacted = (contactedValue) => {
    setState((prevState) => ({
      ...prevState,
      contacted: contactedValue,
    }));
  };

  const setHandlerValidIdType = (validIdTypeValue) => {
    setState((prevState) => ({
      ...prevState,
      validIdType: validIdTypeValue,
    }));
  };

  const setHandlerValidIdNo = (validIdNoValue) => {
    setState((prevState) => ({
      ...prevState,
      validIdNo: validIdNoValue,
    }));
  };

  const setAddress = (addressValue) => {
    setState((prevState) => ({
      ...prevState,
      address: addressValue,
    }));
  };

  const setHandlerName = (handlerNameValue) => {
    setState((prevState) => ({
      ...prevState,
      handlerName: handlerNameValue,
    }));
  };

  const setHandlerEmail = (handlerEmailValue) => {
    setState((prevState) => ({
      ...prevState,
      handlerEmail: handlerEmailValue,
    }));
  };

  const setHandlerContact = (handlerContactValue) => {
    setState((prevState) => ({
      ...prevState,
      handlerContact: handlerContactValue,
    }));
  };

  // Use useDispatch to get the dispatch function to dispatch actions
  const dispatch = useDispatch();

  const handleValidEmail = (email) => {
    const emailRegex =
      /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
    return emailRegex.test(email) === false;
  };

  const handleResetState = () => {
    setState((prevState) => ({
      ...prevState,
      name: data.name,
      email: utilitiesService.decrypt(data.email),
      contact: utilitiesService.decrypt(data.contact),
      contacted: data.contacted,
      handlerName: data.handler
        ? utilitiesService.decrypt(data.handler.name)
        : "",
      handlerContact: data.handler
        ? utilitiesService.decrypt(data.handler.contact)
        : "",
      handlerEmail: data.handler
        ? utilitiesService.decrypt(data.handler.email)
        : "",
      validIdType: utilitiesService.decrypt(data.validIdType),
      validIdNo: utilitiesService.decrypt(data.validIdNo),
      address: utilitiesService.decrypt(data.address),
      nameError: "",
      emailError: "",
    }));
  };

  const handleCloseEditInfluencerDrawer = () => {
    handleResetState();
    const { onHandleCloseEditInfluencerDrawer } = props;
    onHandleCloseEditInfluencerDrawer();
  };

  const handleUpdateInfluencer = () => {
    const { projectId, influencerId, updateInfluencer, data } = props;
    const {
      name,
      email,
      contact,
      contacted,
      handlerName,
      handlerContact,
      handlerEmail,
      validIdType,
      validIdNo,
      address,
      nameError,
      emailError,
    } = state;

    let hasError = false;
    console.log("initial has error: ", hasError);

    // Validate name
    if (name.trim() === "") {
      setState((prevState) => ({
        ...prevState,
        nameError: "Name is required",
      }));
      hasError = true; // Stop further processing if validation fails
    } else {
      setState((prevState) => ({ ...prevState, nameError: "" }));
    }

    // Validate email
    if (email.trim() === "") {
      setState((prevState) => ({
        ...prevState,
        emailError: "Email is required.",
      }));
      hasError = true;
    } else {
      setState((prevState) => ({ ...prevState, emailError: "" }));
    }

    if (email.trim().length > 0 && handleValidEmail(email.trim())) {
      setState((prevState) => ({
        ...prevState,
        emailError: "Invalid email format.",
      }));
      hasError = true;
    }

    if (email.trim().length > 0 && !handleValidEmail(email.trim())) {
      console.log("reset email ");
      setState((prevState) => ({ ...prevState, emailError: "" }));
    }

    console.log("has error :", hasError);

    if (!hasError) {
      console.log(address);
      const influencer = {
        name: name,
        email: utilitiesService.encrypt(email),
        contact: utilitiesService.encrypt(contact),
        handler_name: utilitiesService.encrypt(handlerName),
        handler_contact: utilitiesService.encrypt(handlerContact),
        handler_email: utilitiesService.encrypt(handlerEmail),
        valid_id_type: utilitiesService.encrypt(validIdType),
        valid_id_number: utilitiesService.encrypt(validIdNo),
        address: utilitiesService.encrypt(address),
        contacted: getDefaultDate(contacted),
      };

      console.log("has info: ", influencer);

      dispatch(influencerActions.updateInfluencer(projectId, influencerId, influencer));

      handleCloseEditInfluencerDrawer();
    }
  };

  const toggleTab = (index) => {
    setState((prevState) => ({
      ...prevState,
      toggleTabState: index,
    }));
  };

  // Function to get default date in "YYYY-MM-DD" format
  const getDefaultDate = (dateString) => {
    return dateString
      ? new Date(dateString).toISOString().substring(0, 10)
      : "";
  };

  const activeClass = isEditInfluencerDrawer ? "active" : "";

  return (
    <React.Fragment>
      <div
        className={`drawer-overlay ${activeClass}`}
        onClick={handleCloseEditInfluencerDrawer}
      ></div>
      <div className={`side-drawer-main ${activeClass}`}>
        <div className="side-drawer-wrapper">
          <div className="side-drawer-title">
            <h3>Edit Influencer</h3>
            <span
              className="side-drawer-close"
              onClick={handleCloseEditInfluencerDrawer}
            >
              <i className="icon-lms icon-close"></i>
            </span>
          </div>
          <div className="side-drawer-tabs">
            <div className="side-drawer-tabs-wrapper">
              <div className="control-tabs-box">
                <div
                  className={
                    state.toggleTabState === 1 ? "tabs-btn active" : "tabs-btn"
                  }
                  onClick={() => toggleTab(1)}
                >
                  <p>Influencer Information</p>
                </div>
              </div>
              <div className="content-tabs-box">
                <div
                  className={
                    state.toggleTabState === 1
                      ? "tabs-content active"
                      : "tabs-content"
                  }
                >
                  <div className="forms-tab">
                    <MDBRow>
                      <MDBCol xl="12" lg="12" className="form-group-box">
                        <FormGroupTitle label="Influencer" />
                        <div className="input-group-box full-box-">
                          <InputForm
                            defaultValue={state.name}
                            label="Name"
                            type="text"
                            id="name"
                            name="name"
                            required={true}
                            onChange={(e) => setName(e.target.value)}
                            error={state.nameError}
                          />
                        </div>
                        <FormGroupTitle label="Contact Details" />
                        <div className="input-group-box full-box-">
                          <InputForm
                            defaultValue={state.email}
                            label="Email Address"
                            type="email"
                            id="email"
                            name="email"
                            required={true}
                            onChange={(e) => setEmail(e.target.value)}
                            error={state.emailError}
                          />
                          <InputForm
                            defaultValue={state.contact}
                            label="Phone Number"
                            type="tel"
                            id="contact"
                            name="contact"
                            onChange={(e) => setContact(e.target.value)}
                          />
                          <InputForm
                            defaultValue={getDefaultDate(state.contacted)}
                            label="Date Contacted"
                            type="date"
                            id="contacted"
                            name="contacted"
                            onChange={(e) => setContacted(e.target.value)}
                          />
                        </div>

                        <FormGroupTitle label="ID Information" />
                        <div className="input-group-box full-box-">
                          <InputForm
                            defaultValue={state.validIdType}
                            label="ID Type"
                            type="text"
                            id="valid_id_type"
                            name="valid_id_type"
                            onChange={(e) =>
                              setHandlerValidIdType(e.target.value)
                            }
                          />

                          <InputForm
                            defaultValue={state.validIdNo}
                            label="Id Number"
                            type="text"
                            id="valid_id_no"
                            name="valid_id_no"
                            onChange={(e) =>
                              setHandlerValidIdNo(e.target.value)
                            }
                          />
                          <InputForm
                            defaultValue={state.address}
                            label="Address"
                            type="text"
                            id="address"
                            name="address"
                            onChange={(e) => setAddress(e.target.value)}
                          />
                        </div>

                        <FormGroupTitle label="Handler Details" />
                        <div className="input-group-box full-box-">
                          <InputForm
                            defaultValue={state.handlerName}
                            label="Handler Name"
                            type="text"
                            id="handler_name"
                            name="handler_name"
                            onChange={(e) => setHandlerName(e.target.value)}
                          />
                          <InputForm
                            defaultValue={state.handlerEmail}
                            label="Email Address"
                            type="email"
                            id="handler_email"
                            name="handler_email"
                            onChange={(e) => setHandlerEmail(e.target.value)}
                          />
                          <InputForm
                            defaultValue={state.handlerContact}
                            label="Phone Number"
                            type="tel"
                            id="handler_contact"
                            name="handler_contact"
                            onChange={(e) => setHandlerContact(e.target.value)}
                          />
                        </div>
                      </MDBCol>
                    </MDBRow>
                  </div>
                </div>
              </div>
              <div className="tab-form-btn-group">
                <MDBRow>
                  <MDBCol className="text-right">
                    <span
                      className="drawer-btn cancel-btn"
                      onClick={handleCloseEditInfluencerDrawer}
                    >
                      Cancel
                    </span>
                    <span
                      className="drawer-btn save-btn"
                      onClick={() => handleUpdateInfluencer()}
                    >
                      Update Influencer
                    </span>
                  </MDBCol>
                </MDBRow>
              </div>
            </div>
          </div>
        </div>
      </div>
    </React.Fragment>
  );
};

export default EditInfluencerDrawer;
