import React from 'react'
import { connect } from "react-redux";

import { projectActions } from '_actions';

import {MDBModal, MDBModalBody, MDBContainer, MDBRow, MDBCol, MDBModalHeader} from 'mdbreact';
import { Button } from "blackbox-react";
import InputForm from "_components/InputForm";

// import close from '_assets/images/close.png'
import {Link} from "react-router-dom";

export default class EditLeadStatusModal extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      modalOpen: false,
      startDate: '',
      dropColor: false,
      bgColor: 'circle-light-blue',
      updatedLeadStatus: false,
      leadStatusUpdated: this.props.data
    };

    this.handleStatusChange = this.handleStatusChange.bind(this);
  }

  componentDidMount = () => {
    const leadStatus = this.props.data;

    this.setState({
      modalOpen: this.props.modalOpen,
      leadStatusUpdated: leadStatus
    });
  };
  handleCancel = () => {
    this.props.modalToggle()
  };

  handleSave = () => {
    this.props.modalToggle()
  };


  handleChange = event => {
    this.setState({ [event.target.name]: event.target.value, class: true });
  };

  handleDatePicker = date => {
    this.setState({
      startDate: date
    });
  };

  //this function is used to render Action Button
  handerShowActionBtn = () => {
    let count = document.querySelectorAll(`input[type='checkbox'][name='check-box']:checked`)
    this.setState({ hasSelected: count.length > 1 ? 'visible' : 'invisible' })

    let isCheck = document.querySelectorAll(`input[type='checkbox'][name='check-all']`);
    for (let i = 0; i < isCheck.length; i++) {
      if (isCheck[i].type === 'checkbox') {
        isCheck[i].checked = false;
      }
    }
  };


  handleStatusChange(event) {
    let { name, value } = event.target;
    const { leadStatusUpdated } = this.state;

    if (leadStatusUpdated !== undefined) {

      this.setState({
        leadStatusUpdated: {
          ...leadStatusUpdated,
          [name]: value
        }
      });
    }

    this.setState({
      updateStatusChange: true
    });

  };

  handleUpdateLeadStatus = (event) => {
    event.preventDefault();

    let { leadStatusUpdated } = this.state;
    let projectId = this.props.projectId;
    let leadStatus = {
      name: this.props.data.name,
      description: this.props.data.description
    };
    let leadStatusId = this.props.data.id;

    if (leadStatusUpdated !== undefined) {
      leadStatusUpdated = Object.assign({}, leadStatus, leadStatusUpdated);

      this.props.updateLeadStatus(leadStatusUpdated, projectId, leadStatusId);

      let { modalOpen, modalToggle } = this.props;
      if(modalOpen){
        modalToggle();
      }

      for (const prop of Object.keys(leadStatusUpdated)) {
        delete leadStatusUpdated[prop];
      }
      this.setState({ updatedLeadStatus: false });
    }

    //this.props.handleGetProject();
    this.props.handleGetProjectStatus();
  };

  render() {
    const leadStatus = this.props.data;
    let { modalOpen, modalToggle } = this.props;
    const { leadStatusUpdated } = this.state;

    let project = {};
    let hasData = false;
    if(this.props.projects.items !== undefined)  {
      project = this.props.projects.items.data;
      if(project.info !==undefined){
        hasData = true;
      }
    }

    //let hasData = false;
    if(leadStatus !== undefined)  {
      hasData = true;
    }

    return (
      <MDBContainer>
        <form onSubmit={this.setFormName} noValidate>
        <MDBModal isOpen={modalOpen} toggle={modalToggle} className='create-form-modal' centered>
          <MDBModalHeader className='padding-left-18 padding-right-18 pb-3 pt-3' toggle={modalToggle}>
            <p className='mb-0 font-size-12 font-weight-600 letter-spacing-1'>EDIT LEAD STATUS</p>
          </MDBModalHeader>
          <MDBModalBody className='p-t--0 padding-left-18 padding-right-18 padding-bottom-15'>
            { hasData ?
              <div>
                <MDBRow>
                  <MDBCol className='form-group m--sm'>
                    <label className='mb-1 font-size-12 text-black'>Lead Status Name <span className='color-red'>*</span></label>
                    <InputForm
                      onChange={this.handleStatusChange}
                      type='text'
                      name='name'
                      text={leadStatus.name}
                      value={leadStatusUpdated.name}
                      hint='Enter Lead Status name'
                    />
                    {/*<input*/}
                    {/*  className='height-30 width-inherit border-gray-input width-92 mb-2 br-5 padding-3 font-size-12'*/}
                    {/*  placeholder='Enter Lead Status name'*/}
                    {/*  value={this.state.leadName}*/}
                    {/*  onChange={(e) => this.setState({ leadName: e.target.value })}*/}
                    {/*/>*/}
                {/*    <span className={this.state.dropColor ? 'color-drop-active' : 'color-drop'} onClick={() => { this.setState({ dropColor: !this.state.dropColor }) }}>*/}
                {/*  <div className={`${this.state.bgColor} ${'circle-display'}`} />*/}
                {/*  <MDBIcon icon='caret-down' className='float-right mt-2 font-size-10 mr-1' />*/}
                {/*</span>*/}
                    {/*<div className={`${this.state.dropColor ? 'drop-colors' : 'display-none'} ${'row pt-1'}`}>*/}
                    {/*  <div className='circle-drops circle-light-blue my-1 mx-1' onClick={() => { this.setState({ bgColor: 'circle-light-blue', dropColor: !this.state.dropColor }) }} />*/}
                    {/*  <div className='circle-drops circle-dark-blue my-1 mx-1' onClick={() => { this.setState({ bgColor: 'circle-dark-blue', dropColor: !this.state.dropColor }) }} />*/}
                    {/*  <div className='circle-drops circle-yellow my-1 mx-1' onClick={() => { this.setState({ bgColor: 'circle-yellow', dropColor: !this.state.dropColor }) }} />*/}
                    {/*  <div className='circle-drops circle-orange my-1 mx-1' onClick={() => { this.setState({ bgColor: ' circle-orange', dropColor: !this.state.dropColor }) }} />*/}
                    {/*  <div className='circle-drops circle-red my-1 mx-1' onClick={() => { this.setState({ bgColor: 'circle-red', dropColor: !this.state.dropColor }) }} />*/}
                    {/*  <div className='circle-drops circle-yellow-green my-1 mx-1' onClick={() => { this.setState({ bgColor: 'circle-yellow-green', dropColor: !this.state.dropColor }) }} />*/}
                    {/*  <div className='circle-drops circle-green my-1 mx-1' onClick={() => { this.setState({ bgColor: 'circle-green', dropColor: !this.state.dropColor }) }} />*/}
                    {/*  <div className='circle-drops circle-gray my-1 mx-1' onClick={() => { this.setState({ bgColor: 'circle-gray', dropColor: !this.state.dropColor }) }} />*/}
                    {/*  <div className='circle-drops circle-pink my-1 mx-1' onClick={() => { this.setState({ bgColor: 'circle-pink', dropColor: !this.state.dropColor }) }} />*/}
                    {/*  <div className='circle-drops circle-violet my-1 mx-1' onClick={() => { this.setState({ bgColor: 'circle-violet', dropColor: !this.state.dropColor }) }} />*/}
                    {/*  <div className='circle-drops circle-light-orange my-1 mx-1' onClick={() => { this.setState({ bgColor: 'circle-violet', dropColor: !this.state.dropColor }) }} />*/}
                    {/*  <div className='circle-drops circle-lighter-orange my-1 mx-1' onClick={() => { this.setState({ bgColor: 'circle-lighter-orange', dropColor: !this.state.dropColor }) }} />*/}
                    {/*</div>*/}
                  </MDBCol>
                </MDBRow>
                <MDBRow>
                  <MDBCol>
                    <label className='mb-1 pt-2 font-size-12 text-black'>Description</label>
                    <textarea
                      onChange={this.handleStatusChange}
                      id='leadDesc'
                      name='description'
                      className='form-control font-size-12 color-none'
                      value={leadStatusUpdated.description}
                      defaultValue={leadStatus.description}
                      placeholder='Enter description here...'
                      rows='5'
                    />
                    {/*<textarea*/}
                    {/*  id='leadDisc'*/}
                    {/*  className='form-control font-size-12 color-none font-weight-600'*/}
                    {/*  rows='5'*/}
                    {/*  placeholder='Describe the status here'*/}
                    {/*  onChange={(text) => { this.setState({ desc: text.target.value }) }}*/}
                    {/*/>*/}
                    {/*<div className='row mt-3 justify-content-between'>*/}
                    {/*  <div className='row ml-1'>*/}
                    {/*    <label id='taskCheck' className='checkboxcontainer-sales ml-3 mt-1' onClick={() => { this.handerShowActionBtn() }}>*/}
                    {/*      <input type='checkbox' name='check-box' />*/}
                    {/*      <span className='checkmark'></span>*/}
                    {/*    </label>*/}
                    {/*    <p className='mb-3 font-size-10 opacity-70 font-weight-600'>Set as Conversion to Salles</p>*/}
                    {/*  </div>*/}
                    {/*  <p className={`${this.state.desc === '' ? 'display-none' : 'font-size-10 opacity-70 font-weight-600 mr-3'}`}>Created on August 12, 2019 07:22 AM</p>*/}
                    {/*</div>*/}
                  </MDBCol>
                </MDBRow>
                <MDBRow className='p-t--md'>
                  <MDBCol className='d-flex'>
                    <span
                      className='mb-0 color-gray my-auto text-cursor-pointer border-bottom-gray line-height-15 font-size-12'
                      onClick={modalToggle}
                    >
                      Cancel
                    </span>
                  </MDBCol>
                  <MDBCol className='text-right' id='nextButton'>
                    <Button
                      flat='true'
                      type='submit'
                      size={-1}
                      background='orange'
                      onClick={this.handleUpdateLeadStatus}
                    ><Link to={`/project/${project.id}/manage-lead-status`} className='text-white'>
                      UPDATE LEAD STATUS</Link>
                    </Button>
                  </MDBCol>
                </MDBRow>
              </div>
              : null
              }
          </MDBModalBody>
        </MDBModal>
        </form>
      </MDBContainer >
    )
  }
}

function mapState(state) {
  const { projects, project, updating } = state;
  // const { updating } = state.updateLeadStatus;
  return { updating, projects, project };
}

const actionCreators = {
  getProject: projectActions.getProject,
  updateLeadStatus: projectActions.updateLeadStatus
};

const connectedViewProjects = connect(mapState, actionCreators)(EditLeadStatusModal);
export { connectedViewProjects as EditLeadStatusModal };
