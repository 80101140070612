import React from 'react';
import { Link } from 'react-router-dom';
import { connect } from 'react-redux';
import { userActions } from '_actions';

import { MDBContainer, MDBCol, MDBRow } from 'mdbreact';
import { Button } from "blackbox-react";
import { FormValidation } from '_components/FormValidation';

import logo from '_assets/images/lms-logo.png';
import loginBG from '_assets/images/login-bg.png';

class Login extends React.Component {
  constructor(props) {
    super(props);

    this.props.logout();

    this.state = {
      email: '',
      password: '',
      submitted: false,
      class: true,
      formErrors: {
        email: 'Please enter your login email.',
        password: 'Please enter your login password.',
        login: ''
      },
      emailValid: false,
      passwordValid: false,
      formValid: false,
      passwordShown: false,
      redirectTo: null,
      twoFactorAuthenticate: false
    };

    this.handleChange = this.handleChange.bind(this);
    this.handleLogin = this.handleLogin.bind(this);
  }


  validateField(fieldName, value) {
    let fieldValidationErrors = this.state.formErrors;
    let emailValid = this.state.emailValid;
    let passwordValid = this.state.passwordValid;

    let isAllWhitespace = (value.replace(/(?!^ +$)^.+$/gm, "") !== '');

    switch (fieldName) {
      case 'email':
        if (value === "" || isAllWhitespace) { // check if empty
          emailValid = false;
          fieldValidationErrors.email = 'Please enter your login email.';
        } else { // check if valid
          emailValid = value.match(/^([\w.%+-]+)@([\w-]+\.)+([\w]{2,})$/i);
          fieldValidationErrors.email = emailValid ? '' : 'Please enter your email address in valid format: sample@domain.com';
        }
        break;
      case 'password':
        if (value === "" || isAllWhitespace || value.length < 6) { // check if empty
          passwordValid = false;
          fieldValidationErrors.password = 'Please enter your login password.';
        } else {
          passwordValid = value.length >= 6; // check if valid
          fieldValidationErrors.password = passwordValid ? '' : '';
        }
        break;
      default:
        break;
    }
    this.setState({
      formErrors: fieldValidationErrors,
      emailValid: emailValid,
      passwordValid: passwordValid
    }, this.validateForm);
  }

  validateForm() {
    this.setState({
      formValid: this.state.emailValid && this.state.passwordValid
    });
  }

  handleChange(e) {
    const { name, value } = e.target;

    this.setState({
      [name]: value,
    }, () => {
      this.validateField(name, value);
    });
  }

  handleLogin(e) {
    e.preventDefault();
    this.validateForm();
    // this.setState({twoFactorAuthenticate: true});
    this.setState({ submitted: true }, () => {
      if (this.state.formValid) {
        if (this.state.submitted) {
          localStorage.setItem('isLoggedIn', 'true');
          this.props.login(this.state.email, this.state.password, this.state.redirectTo);
        }
      }
    });
  }

  togglePassword = () => {
    this.setState(prevState => ({
      passwordShown: !prevState.passwordShown
    }));
  };

  errorClass(error) {
    return (error.length === 0 ? 'is-passed' : 'is-failed');
  }

  handleForgotPassword = () => {
    this.props.clearError(); 
  };

  componentDidMount() {
    // Extract the redirect query parameter from the URL
    const params = new URLSearchParams(window.location.search);
    const redirectParam = params.get('redirect');
    localStorage.setItem('isLoggedIn', 'false');

    // Set the redirectTo state to the extracted dynamic destination or a fallback URL
    this.setState({ redirectTo: redirectParam || null }); // Replace '/default' with your desired fallback URL
  }

  render() {

    const { loggingIn } = this.props;
    let { error } = this.props;
    const { email, password, submitted, formValid, passwordShown, formErrors, twoFactorAuthenticate } = this.state;
    
    formErrors.login = '';
    if(formErrors.password || formErrors.email) {
      this.props.clearError(); 
    }


    formErrors.login = error ? error : '';
  

    return (
      <MDBContainer fluid className='login-background'>
        <div className='login-bg'>
          <img src={loginBG} alt='Login Background Image' />
        </div>
        <div className='login-panel'>
        <MDBRow>
            <MDBCol className='text-center'>
            <img src={logo} alt='Propelrr Platform' className='login-logo' />
              <h1>Lead Management System</h1>
            </MDBCol>
          </MDBRow>
          <MDBRow>
            <MDBCol>
              <form className='login-form' onSubmit={this.handleLogin} noValidate>
                <MDBRow>
                  <MDBCol className="text-center">
                    <div className='form-inner-heading'>
                      <h2>Login with your given email</h2>
                    </div>
                  </MDBCol>
                </MDBRow>
                <MDBRow>
                  <MDBCol>
                    <div className={`form-group ${submitted && this.errorClass(this.state.formErrors.email)}`}>
                      <label className='l-main'>Email</label>
                      <input
                        className={this.state.class ? 'email-input' : 'input-error-placeholder'}
                        name='email'
                        placeholder='yourgivenemail@company.com'
                        type='email'
                        required={true}
                        value={email}
                        autoFocus={true}
                        onChange={this.handleChange}
                      />
                      <div className={`form-validation__container ${(!formValid && submitted) ? 'display-block' : 'display-none'}`}>
                        <FormValidation fieldName='email' formErrors={this.state.formErrors} />
                      </div>
                    </div>
                    <div className={`form-group ${submitted && this.errorClass(this.state.formErrors.password)}`}>
                      <div className='label-f-password'>
                        <label className='l-main'>Password</label>
                        <Link to='/forgot-password' onClick={this.handleForgotPassword}><span className='text-login-here-blue'> Forgot Password?</span></Link>
                      </div>
                      <div className='password-group'>
                        <input
                          className={this.state.class ? 'password-input-login' : 'input-error-placeholder'}
                          name='password'
                          placeholder='Enter your password'
                          type={passwordShown ? "text" : "password"}
                          required={true}
                          value={password}
                          onChange={this.handleChange}
                        />
                        <i onClick={this.togglePassword} className={passwordShown ? "icon-lms icon-visibility-on" : "icon-lms icon-visibility-off"}></i>
                      </div>
                      <div className={`form-validation__container ${(!formValid && submitted) ? 'display-block' : 'display-none'}`}>
                        <FormValidation fieldName='password' formErrors={this.state.formErrors} />
                      </div>
                      <div className={`form-validation__container ${error ? 'display-block' : 'display-none'}`}>
                        <FormValidation fieldName='login' formErrors={formErrors} />
                      </div>
                    </div>
                    <div className="form-group">
                      <Button
                        type='submit'
                        className={loggingIn ? 'c-btn c-btn-lg c-btn-disabled' : 'c-btn c-btn-lg c-btn-blue1'}
                      >
                        Login 
                        
                      </Button>
                      {loggingIn &&
                        <div class="lds-ellipsis"><div></div><div></div><div></div><div></div></div>
                      }
                    </div>
                  </MDBCol>
                </MDBRow>
              </form>
            </MDBCol>
          </MDBRow>
        </div>
      </MDBContainer>
    )
  }
}

function mapState(state) {
  const  { loggingIn }  = state.authentication;
  const  { error }  = state.users
 
  return { loggingIn, error };
}


const actionCreators = {
  login: userActions.login,
  logout: userActions.logout,
  clearError: userActions.clearError
};

const connectedLoginPage = connect(mapState, actionCreators)(Login);
export { connectedLoginPage as Login };

