import React from 'react'

// components
import TableHeader from './TableHeader'
import TableBody from './TableBody'

const TableViewLists = () => {
  return (
    <div className='table-view-influencer'>
        <table className='table-sm bordered propelrr-table'>
            <TableHeader />
            <TableBody />
        </table>
    </div>
  )
}

export default TableViewLists