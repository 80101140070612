import { emailConstants } from "_constants";

export function email(state = {}, action) {
  switch (action.type) {
    case emailConstants.GET_ALL_EMAIL_REQUEST:
      return {
        ...state,
        getEmailLoading: true,
        getEmailLoaded: false,
        getEmailReply: false,
        getUnreadLoaded: false,
        getEmailNotification: false,
      };
    case emailConstants.GET_ALL_EMAIL_SUCCESS:
      return {
        ...state,
        getEmailLoading: false,
        getEmailLoaded: true,
        getThreadLoaded: true,
        getEmailReply: false,
        email: action.emails.data,
      };
    case emailConstants.GET_ALL_EMAIL_FAILURE:
      return {
        ...state,
        getEmailLoading: false,
        getEmailLoaded: true,
        error: action.error,
      };

    case emailConstants.EMAIL_NOTIFICATION_REQUEST:
      return {
        ...state,
        getEmailNotification: true,
      };

    case emailConstants.UPDATE_EMAIL_UNREAD_REQUEST:
      return {
        ...state,
        getEmailLoading: true,
        getEmailLoaded: false,
        getEmailReply: false,
        getUnreadLoaded: false,
      };
    case emailConstants.UPDATE_EMAIL_UNREAD_SUCCESS:
      return {
        ...state,
        getEmailLoading: false,
        getEmailLoaded: true,
        getThreadLoaded: true,
        getEmailReply: false,
        getUnreadLoaded: true,
        email: action.emails.data,
      };
    case emailConstants.UPDATE_EMAIL_UNREAD_FAILURE:
      return {
        ...state,
        getEmailLoading: false,
        getEmailLoaded: true,
        getThreadLoaded: true,
        getEmailReply: false,
        error: action.error,
      };

    case emailConstants.EMAIL_REPLY_REQUEST:
      return {
        ...state,
        getEmailLoading: true,
        getEmailLoaded: false,
        getEmailReply: false,
      };
    case emailConstants.EMAIL_REPLY_SUCCESS:
      return {
        ...state,
        getEmailLoading: false,
        getEmailLoaded: true,
        getThreadLoaded: false,
        getEmailReply: true,
        email: action.emails.data,
      };
    case emailConstants.EMAIL_REPLY_FAILURE:
      return {
        ...state,
        getEmailLoading: false,
        getEmailLoaded: true,
        error: action.error,
      };
    case emailConstants.EMAIL_LOAD_MESSAGES:
      return {
        ...state,
        thread: action.thread,
      };

    // email drafts
    case emailConstants.EMAIL_ALL_DRAFT_REQUEST:
    case emailConstants.EMAIL_DRAFT_REQUEST:
      return {
        ...state,
        getDraftLoading: true,
        getDraftLoaded: false,
      };
    case emailConstants.EMAIL_ALL_DRAFT_SUCCESS:
      return {
        ...state,
        getDraftLoading: false,
        getDraftLoaded: true,
        drafts: action.drafts.data,
      };
    case emailConstants.EMAIL_DRAFT_SUCCESS:
      return {
        ...state,
        getDraftLoading: false,
        getDraftLoaded: true,
        drafts: action.drafts.data,
      };
    case emailConstants.EMAIL_ALL_DRAFT_FAILURE:
    case emailConstants.EMAIL_DRAFT_FAILURE:
      return {
        ...state,
        getEmailLoading: false,
        getEmailLoaded: true,
        error: action.error,
      };
    default:
      return {
        ...state,
      };
  }
}
